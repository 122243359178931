import PropTypes from 'prop-types';
import { useContext, useEffect, useRef } from 'react';
// form
import { FormProvider as Form } from 'react-hook-form';
import { FormContext } from 'src/layouts/dashboard/DashboardLayout';

// ----------------------------------------------------------------------

FormProvider.propTypes = {
  children: PropTypes.node,
  methods: PropTypes.object,
  onSubmit: PropTypes.func,
};

export default function FormProvider({ children, onSubmit, methods }) {


  return (
    <Form {...methods} >
      {children}
    </Form>
  );
}
