import { Button, Card, Grid, Paper, TextField } from "@mui/material";
import React from "react";
import Iconify from '../components/iconify';


// const useStyles = makeStyles({
//   root: {
//     margin: "10px 0px",
//     padding: "20px"
//   },
//   resultField: {
//     width: "100%"
//   },
//   keypad: {
//     width: "100%"
//   }
// });

export default function Calculator(props) {
  //const classes = useStyles();
  return (
    
    <Card sx={{padding:2,pl:1,mr:1}} >

      
        <Grid  spacing={2} container>
          <Grid item xs={3}>
            <Button
            sx={{color:'black'}}
            fullWidth={true}
              onClick={() => {
                
              }}
            >
             1
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Button
            sx={{color:'black'}}

              fullWidth={true}
              onClick={() => {
                
              }}
            >
             2
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Button
            sx={{color:'black'}}

              fullWidth={true}
              onClick={() => {
               
              }}
            >
             3
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Button
            sx={{color:'black'}}

              fullWidth={true}
              onClick={() => {
                
              }}
            >
             Qty
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Button
            sx={{color:'black'}}

              fullWidth={true}
              onClick={() => {
                
              }}
            >
              4
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Button
            sx={{color:'black'}}

              fullWidth={true}
              onClick={() => {
              
              }}
            >
              5
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Button
            sx={{color:'black'}}

              fullWidth={true}
              onClick={() => {
                
              }}
            >
              6
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Button
            sx={{color:'black'}}

              fullWidth={true}
              onClick={() => {
                
              }}
            >
              % Disc
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Button
            sx={{color:'black'}}

              fullWidth={true}
              onClick={() => {
               
              }}
            >
             7
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Button
            sx={{color:'black'}}

              fullWidth={true}
              onClick={() => {
               
              }}
            >
             8
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Button
            sx={{color:'black'}}

              fullWidth={true}
              onClick={() => {
              
              }}
            >
             9
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Button
            sx={{color:'black'}}

              fullWidth={true}
              onClick={() => {
                
              }}
            >
              Price
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Button
            sx={{color:'black'}}

              fullWidth={true}
              onClick={() => {
                
              }}
            >
             +/-
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Button
            sx={{color:'black'}}

              fullWidth={true}
              onClick={() => {
                
              }}
            >
              0
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Button
            sx={{color:'black'}}

              fullWidth={true}
              onClick={() => {
              
              }}
            >
             .
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Button
            sx={{color:'black'}}

              fullWidth={true}
              onClick={() => {
                
              }}
            >
              <Iconify icon="cil:delete"  />
             
            </Button>
          </Grid>
    
        </Grid>
      </Card>

  );
}
