// Helper function to call MS Graph API endpoint

import { HttpRequest } from 'src/data/scripts';

// using authorization bearer token scheme
export const callMSGraph = (endpoint, token, callback) => {
  const headers = new Headers();
  const bearer = `Bearer ${token}`;

  headers.append('Authorization', bearer);

  const options = {
    method: 'GET',
    headers: headers,
  };

  //console.log('request made to Graph API at: ' + new Date().toString());

  fetch(endpoint, options)
    .then((response) => response.json())
    .then(async (json) => {
      {
        let contact;
        if (json['@odata.nextLink']) {
          // console.log(json["@odata.nextLink"],token,json.value);
          contact = await getAllContactsOutlook(json['@odata.nextLink'], token, json.value);
        } else {
          contact = json.value;
        }
        console.log(contact);
        const contacts = contact.map(function (connection) {
          // console.log(connection);
          return {
            ProviderID: connection.id,
            Provider: 'Microsoft',
            //OutlookID: connection["@odata.etag"],
            Title: connection.title ? connection.title.replaceAll("'", '`') : '',
            FirstName: connection.givenName ? connection.givenName.replaceAll("'", '`') : '',
            MiddleName: connection.middleName ? connection.middleName.replaceAll("'", '`') : '',
            LastName: connection.surname ? connection.surname.replaceAll("'", '`') : '',
            NickName: connection.nickName
              ? connection.nickName.replaceAll("'", '`')
              : connection.displayName
                ? connection.displayName.replaceAll("'", '`')
                : '',
            Organization: connection.companyName ? connection.companyName.replaceAll("'", '`') : '',
            JobTitle: connection.jobTitle ? connection.jobTitle.replaceAll("'", '`') : '',
            Phone: connection.mobilePhone ? connection.mobilePhone.replaceAll("'", '`') : '',
            Phone2:
              connection.homePhones && connection.homePhones.length != 0
                ? connection.homePhones[0].replaceAll("'", '`')
                : '',
            Office:
              connection.businessPhones && connection.businessPhones.length != 0
                ? connection.businessPhones[0].replaceAll("'", '`')
                : '',
            Email:
              connection.emailAddresses &&
              connection.emailAddresses.length != 0 &&
              connection.emailAddresses[0].address
                ? connection.emailAddresses[0].address.replaceAll("'", '`')
                : '',

            Street1:
              connection.businessAddress &&
              connection.businessAddress != '{}' &&
              connection.businessAddress.street
                ? connection.businessAddress.street.replaceAll("'", '`')
                : connection.homeAddress &&
                    connection.businessAddress.length != '{}' &&
                    connection.homeAddress.street
                  ? connection.homeAddress.street.replaceAll("'", '`')
                  : connection.otherAddress &&
                      connection.businessAddress.length != '{}' &&
                      connection.otherAddress.street
                    ? connection.otherAddress.street.replaceAll("'", '`')
                    : '',

            City:
              connection.businessAddress &&
              connection.businessAddress != '{}' &&
              connection.businessAddress.city
                ? connection.businessAddress.city.replaceAll("'", '`')
                : connection.homeAddress &&
                    connection.homeAddress != '{}' &&
                    connection.homeAddress.city
                  ? connection.homeAddress.city.replaceAll("'", '`')
                  : connection.otherAddress &&
                      connection.otherAddress != '{}' &&
                      connection.otherAddress.city
                    ? connection.otherAddress.city.replaceAll("'", '`')
                    : '',

            PostalCode:
              connection.businessAddress &&
              connection.businessAddress != '{}' &&
              connection.businessAddress.postalCode
                ? connection.businessAddress.postalCode.replaceAll("'", '`')
                : connection.homeAddress &&
                    connection.homeAddress != '{}' &&
                    connection.homeAddress.postalCode
                  ? connection.homeAddress.postalCode.replaceAll("'", '`')
                  : connection.otherAddress &&
                      connection.otherAddress != '{}' &&
                      connection.otherAddress.postalCode
                    ? connection.otherAddress.postalCode.replaceAll("'", '`')
                    : '',

            Country:
              connection.businessAddress &&
              connection.businessAddress != '{}' &&
              connection.businessAddress.countryOrRegion
                ? connection.businessAddress.countryOrRegion.replaceAll("'", '`')
                : connection.homeAddress &&
                    connection.homeAddress != '{}' &&
                    connection.homeAddress.countryOrRegion
                  ? connection.homeAddress.countryOrRegion.replaceAll("'", '`')
                  : connection.otherAddress &&
                      connection.otherAddress != '{}' &&
                      connection.otherAddress.countryOrRegion
                    ? connection.otherAddress.countryOrRegion.replaceAll("'", '`')
                    : '',
          };
        });

        Promise.all(
          contacts.map(
            (contact, index) =>
              new Promise((resolve) => {
                setTimeout(async () => {
                  await HttpRequest(
                    `getItems/googlecontactsdetails/Name/${localStorage.getItem(
                      'username'
                    )}/${localStorage.getItem('client')}/${contact.ProviderID}`
                  )
                    .then((response) => response)
                    .then((json) => {
                      if (json.ID == '0') {
                        console.log(contact);
                        return HttpRequest(
                          `contacts/${localStorage.getItem('client')}/${localStorage.getItem(
                            'username'
                          )}`,
                          'POST',
                          contact
                        );
                      } else {
                        return HttpRequest(
                          `contacts/${json.ID}/${localStorage.getItem('username')}`,
                          'PUT',
                          contact
                        );
                      }
                    })
                    .then(resolve)
                    .catch(console.error);
                }, index * 10); // delay by 1 second per contact index
              })
          )
        )
          .then(async (responses) => {})
          .catch(console.error);
      }
    })
    // .then(response => callback(response, endpoint))
    .catch((error) => console.log(error));
};

export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft.com/beta//me',
  graphMailEndpoint: 'https://graph.microsoft.com/beta/me/contacts?$count=true',
  graphCalendarsEndpoint: 'https://graph.microsoft.com/beta/me/events?$count=true',
  //  graphMeEndpoint: "https://graph.microsoft.com/v1.0//me",
  // graphMailEndpoint: "https://graph.microsoft.com/v1.0/me/contacts?%24skip=100"

  // graphMeEndpoint: "https://graph.microsoft.com/v1.0//me",
  // graphMailEndpoint: "https://graph.microsoft.com/v1.0/me/contacts?$count=true"
};

export const updateUI = (data, endpoint) => {
  // console.log('Graph API responded at: ' + new Date().toString());
  //console.log(data);
  // let a = '@odata.context';
  //console.log(data['@odata.context']);
  //console.log(data['@odata.count']);
  //console.log(data['@odata.nextLink']);
};

async function getAllContactsOutlook(endpoint, token, allContacts = []) {
  const headers = new Headers();
  const bearer = `Bearer ${token}`;
  headers.append('Authorization', bearer);
  const options = {
    method: 'GET',
    headers: headers,
  };

  let response = await fetch(endpoint, options).then((response) => response.json());

  // console.log(response);
  const value = response.value;
  const contacts = [...allContacts, ...value];
  //console.log(contacts);

  if (response['@odata.nextLink']) {
    endpoint = response['@odata.nextLink'];
    // console.log(endpoint,token,contacts);
    return await getAllContactsOutlook(endpoint, token, contacts);
  } else {
    return contacts;
  }
}

export const callCalendarsGraph = (endpoint, token, callback) => {
  const headers = new Headers();
  const bearer = `Bearer ${token}`;

  headers.append('Authorization', bearer);

  const options = {
    method: 'GET',
    headers: headers,
  };

  console.log('request made to Graph API at: ' + new Date().toString());

  fetch(endpoint, options)
    .then((response) => response.json())
    .then(async (json) => {
      {
        // console.log(json);
        let responseMeetings;
        if (json['@odata.nextLink']) {
          // console.log(json["@odata.nextLink"],token,json.value);
          responseMeetings = await getAllMeetingsOutlook(
            json['@odata.nextLink'],
            token,
            json.value
          );
        } else {
          responseMeetings = json.value;
        }

        const Meetings = responseMeetings.map(function (Meeting) {
          return {
            //Name
            ProviderID: Meeting.uid && Meeting.uid,
            Provider: 'Microsoft', // connection["@odata.etag"],
            title: Meeting.subject ? Meeting.subject.replaceAll("'", '`') : '',
            location:
              Meeting.location && Meeting.location.displayName
                ? Meeting.location.displayName.replaceAll("'", '`')
                : '',
            startDateTime:
              Meeting.start && Meeting.start.dateTime
                ? new Date(Meeting.start.dateTime).toISOString()
                : '',
            endDateTime:
              Meeting.end && Meeting.end.dateTime
                ? new Date(Meeting.end.dateTime).toISOString()
                : '',
            description: Meeting.bodyPreview ? Meeting.bodyPreview.replaceAll("'", '`') : '',
          };
        });
        //  console.log(Meetings);

        Promise.all(
          Meetings.map(
            (Meeting, index) =>
              new Promise((resolve) => {
                setTimeout(() => {
                  HttpRequest(
                    `getItems/eventsdetails/Name/${localStorage.getItem(
                      'username'
                    )}/${localStorage.getItem('client')}/${Meeting.ProviderID}`
                  )
                    .then((response) => response)
                    .then((json) => {
                      if (json.ID == '0') {
                        return HttpRequest(
                          `events/${localStorage.getItem('client')}/${localStorage.getItem(
                            'username'
                          )}`,
                          'POST',
                          Meeting
                        );
                      } else {
                        return HttpRequest(
                          `events/${json.ID}/${localStorage.getItem('username')}`,
                          'PUT',
                          Meeting
                        );
                      }
                    })
                    .then(resolve)
                    .catch(console.error);
                }, index * 10); // delay by 1 second per contact index
              })
          )
        )
          .then((responses) => {
            responses.forEach(async (i) => {
              let data = await i.json();
              //    console.log(data)
            });
            // console.log(responses);
          })
          .catch(console.error);
      }
    })
    // .then(response => callback(response, endpoint))
    .catch((error) => console.log(error));
};

async function getAllMeetingsOutlook(endpoint, token, allMeetings = []) {
  const headers = new Headers();
  const bearer = `Bearer ${token}`;
  headers.append('Authorization', bearer);
  const options = {
    method: 'GET',
    headers: headers,
  };

  let response = await fetch(endpoint, options).then((response) => response.json());

  // console.log(response);
  const value = response.value;
  const meetings = [...allMeetings, ...value];
  //  console.log(contacts);

  if (response['@odata.nextLink']) {
    endpoint = response['@odata.nextLink'];
    // console.log(endpoint,token,contacts);
    return await getAllContactsOutlook(endpoint, token, meetings);
  } else {
    return meetings;
  }
}
