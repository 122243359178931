import {
  Box,
  Card,
  Table,
  Button,
  Divider,
  MenuItem,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  CardHeader,
  IconButton,
  TableContainer,
  Grid,
  Stack,
  Typography,
  Link,
  CircularProgress,
  TableFooter,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import LoadingScreen from 'src/components/loading-screen/LoadingScreen';
import LogoWhite from 'src/components/logo/LogoWhite';

import Scrollbar from 'src/components/scrollbar';
import { numberCols } from 'src/data/data';
import { HttpRequest, formatdecimals } from 'src/data/scripts';
import { FormContext } from 'src/layouts/dashboard/DashboardLayout';
import PrintTenantInvoice from 'src/pages/properties/printtenantinvoice';
import { mDateTime } from 'src/utils/formatTime';

export function TableGREYERP({
  table,
  firstDate,
  lastDate,
  interval,
  name,
  setItem,
  reTrigger,
  apiExternalUrl,
  height,
  pageFont,
  pageFontSize,
  externalData,
  sx,
  externalHeader,
  backgroundColorHeader = true,
  rightAlignment,
}) {
  const [data, setData] = useState();
  const [columns, setColumns] = useState();
  const defaultinterval = interval ? interval : 0;
  const { clientId, setRecordId, setOpenPrint, openPrint } = useContext(FormContext);
  const [showTenantInvoicePrint, setShowTenantInvoicePrint] = useState(false);

  useEffect(() => {
    if (!externalData) {
      const apiDefaultUrl = `getrecords/${table}/${localStorage.getItem(
        'username'
      )}/${localStorage.getItem('client')}/${firstDate}/${lastDate}/${defaultinterval}`;
      const apiUrl = apiExternalUrl ? apiExternalUrl : apiDefaultUrl;

      HttpRequest(apiUrl).then((json) => {
        // console.log(json, name);
        setData(json);
        if (json?.length > 0) {
          if (name == 'Rent Outstandings') {
            let total = json
              .map((i) => i.Amount)
              .reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
            setItem(total.toFixed(3));
          } else if (
            name == 'Grievances' ||
            name == 'Employees on Leave Today' ||
            name == 'Upcomming Holidays' ||
            name == 'Job Post' ||
            name == 'Job Applications' ||
            name == 'Contract Expiring' ||
            name == 'Pending Maintenances' ||
            name == 'Empty Units' ||
            name == 'Events' ||
            name == 'Calls' ||
            name == 'Leads' ||
            name == 'Tasks' ||
            name == 'Quotations' ||
            name == 'Events' ||
            name == 'Emp Doc Expiry' ||
            name == 'TrialBalance'
          ) {
            setItem(json.length);
          } else if (name == 'Items Inventory') {
            const inventorydata = json.reduce(
              (acc, item) => {
                acc.Qty_In += item.Qty_In;
                acc.Qty_Out += item.Qty_Out;
                acc.On_Hand += item.On_Hand;
                acc.Reserved += item.Reserved;
                acc.InPurchase += item.InPurchase;
                acc.Available += item.Available;
                // acc.Qty_In_Items += item.Reserved ? 1 : 0;
                acc.Qty_Out_Items += item.Qty_In ? 1 : 0;
                // acc.On_Hand_Items += item.Reserved ? 1 : 0;
                acc.Reserved_Items += item.Reserved ? 1 : 0;
                // acc.InPurchase_Items += item.Reserved ? 1 : 0;
                // acc.Available_Items += item.Reserved ? 1 : 0;
                acc.Total_Items += 1;
                acc.Price += item.Price;
                acc.Cost += item.Cost;
                return acc;
              },
              {
                Qty_In: 0,
                Qty_Out: 0,
                On_Hand: 0,
                Reserved: 0,
                InPurchase: 0,
                Available: 0,
                Qty_Out_Items: 0,
                Reserved_Items: 0,
                Total_Items: 0,
                Price: 0,
                Cost: 0,
              }
            );
            setItem(inventorydata);
          } else if (name == 'Bank Accounts' || name == 'VAT In' || name == 'VAT Out') {
            let total = json
              .map((i) => i.Amount)
              .map((i) => i.replaceAll(',', ''))
              .reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
            setItem(formatdecimals(total));
          } else if (name == 'Recent Payments') {
            let total = json.map((i) => i.Total).reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
            setItem(formatdecimals(total));
            const data = json.map((item) => ({
              ...item,
              Sub_Total: formatdecimals(parseFloat(item.Sub_Total)),
              Tax: formatdecimals(parseFloat(item.Tax)),
              Total: formatdecimals(parseFloat(item.Total)),
            }));
            setData(data);
          } else if (name == 'Recent Receipts') {
            let total = json.map((i) => i.Total).reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
            setItem(formatdecimals(total));
            const data = json.map((item) => ({
              ...item,
              Sub_Total: formatdecimals(parseFloat(item.Sub_Total)),
              Tax: formatdecimals(parseFloat(item.Tax)),
              Total: formatdecimals(parseFloat(item.Total)),
            }));
            setData(data);
          } else if (name == 'Attendance') {
            let empPresent = 0;
            const data = json.map((item) => {
              if (item.Punch_In) {
                empPresent += 1;
              }
              return item;
            });
            setData(data);
            setItem(empPresent);
          } else if (name == 'AccountStatement') {
            const data = json.map((item) => ({
              ...item,
              Debit: formatdecimals(parseFloat(item.Debit)),
              VAT: formatdecimals(parseFloat(item.VAT)),
              Credit: formatdecimals(parseFloat(item.Credit)),
              Narration:
                item?.Narration && item.Narration.includes('Rent Charged') ? (
                  <Link
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      setRecordId({
                        ID: item.ID,
                      });
                      setShowTenantInvoicePrint(true);
                    }}
                  >
                    {item.Narration}
                  </Link>
                ) : (
                  <span>{item?.Narration}</span>
                ),
            }));
            // setData(data);
            setData(data.map(({ ID, ...rest }) => rest));
          }

          let columns = [];
          for (let key in json[0]) {
            if (name == 'AccountStatement' && key == 'ID') {
            } else {
              columns.push(key);
            }
          }
          setColumns(columns);
        } else {
          setItem && setItem(0);
        }
      });
    } else {
      setData(externalData);
      setColumns(externalHeader);
    }
  }, [reTrigger, clientId, externalData]);

  let accVouchersUrl = new Map([
    ['EXPENSE', '/accounts/expense'],
    ['PAYMENT', '/accounts/payments'],
    ['TRANSFER', '/accounts/expense'],
    ['INVOICE', '/properties/invoices'],
    ['RECEIPT', '/accounts/receipts'],
    ['JOURNALENTRY', '/accounts/journal-entries'],
    ['BANKACCOUNT', '/accounts/bank-account'],
    ['TENANT', `/sales/invoices`],
    ['MAINTENANCE', '/properties/maintenances'],
  ]);

  return (
    <>
      {!data && !externalHeader ? (
        <Box sx={{ height: height ?? '375px', width: '100%' }}>
          <Stack
            sx={{ height: '100%', width: '100%' }}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <CircularProgress />
          </Stack>
        </Box>
      ) : (
        <>
          <TableContainer sx={{ height: height ?? '375px', width: '100%', my: 0, py: 0, mb: 1 }}>
            <Scrollbar>
              <Table size="small" stickyHeader>
                <TableHead
                  sx={{
                    '& .MuiTableCell-head': {
                      backgroundImage: !backgroundColorHeader && 'none',
                      color: !backgroundColorHeader && 'black',
                    },
                    width: '50%',
                  }}
                >
                  <TableRow key="0" hover={true}>
                    {columns?.length > 0 &&
                      columns.map((row, index) => (
                        <TableCell
                          sx={{
                            fontSize: pageFontSize,
                            fontFamily: pageFont,
                            textAlign: !isNaN(row?.toString().replaceAll(',', '')) && 'right',
                            ...sx,
                          }}
                        >
                          {!isNaN(row) || row == 0 ? formatdecimals(row || 0) : row}
                        </TableCell>
                      ))}
                  </TableRow>
                </TableHead>
                <TableBody sx={{ width: '50%' }}>
                  {data?.length > 0 &&
                    data.map((row, index) => {
                      let rowArray = [];
                      let colArray = [];
                      for (const cell in row) {
                        if (
                          row[cell]?.toString()?.includes('T') &&
                          row[cell]?.toString()?.includes('Z') &&
                          name != 'Attendance'
                        ) {
                          let createdDate = new Date(row[cell])
                            .toDateString()
                            .replace(/^\S+\s/, '')
                            .split(' ');
                          createdDate?.splice(2, 0, ',');
                          createdDate = createdDate?.join(' ');
                          rowArray.push(createdDate);
                          colArray.push(cell);
                        } else if (
                          row[cell]?.toString()?.includes('T') &&
                          row[cell]?.toString()?.includes('Z') &&
                          name == 'Attendance'
                        ) {
                          rowArray.push(mDateTime(row[cell]));
                        } else if (cell?.toUpperCase() == 'NUMBER') {
                          accVouchersUrl.forEach((value, key) => {
                            if (key == row['Voucher']?.toUpperCase()) {
                              let link = (
                                <Link
                                  href={`${value}/${row['ROW']}`}
                                  target="_blank"
                                  rel="noreferrer"
                                  style={{ cursor: 'pointer' }}
                                >
                                  {row[cell]}
                                </Link>
                              );
                              rowArray.push(link);
                              colArray.push(cell);
                            }
                          });
                        } else {
                          rowArray.push(row[cell]);
                          colArray.push(cell);
                        }
                      }
                      return (
                        <TableRow key={index} hover={true}>
                          {rowArray.map((cell, index2) => (
                            <TableCell
                              sx={{
                                textAlign: numberCols.includes(colArray[index2]) && 'right',

                                fontSize: pageFontSize ?? '13px !important',
                                fontFamily: pageFont,
                                width: '80%',
                                ...sx,
                              }}
                              key={index2}
                            >
                              {numberCols.includes(colArray[index2]) ? formatdecimals(cell) : cell}
                            </TableCell>
                          ))}
                        </TableRow>
                      );
                    })}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    {!externalHeader &&
                      columns?.length > 0 &&
                      columns.map((cell, index) => (
                        <>
                          <TableCell
                            sx={{
                              fontSize: pageFontSize ?? '13px !important',
                              fontFamily: pageFont,
                              width: '80%',
                              borderTop: '.3px  solid rgb(224, 224, 224)',
                              borderBottom: '.3px  solid rgb(224, 224, 224)',
                              fontWeight: 'bold',
                              color: 'black',

                              textAlign: numberCols.includes(cell) && 'right',
                            }}
                            key={index}
                          >
                            {numberCols.includes(cell) &&
                              formatdecimals(
                                data
                                  .map((row) => row[cell] ?? 0)
                                  .reduce((acc, curr) => (acc += parseFloat(curr || 0)), 0)
                              )}
                          </TableCell>
                        </>
                      ))}
                  </TableRow>
                </TableFooter>
              </Table>
            </Scrollbar>
          </TableContainer>
        </>
      )}

      {showTenantInvoicePrint && <PrintTenantInvoice />}
    </>
  );
}
