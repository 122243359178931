import { FormControl, FormControlLabel, Grid, TextField, Switch } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormData } from 'src/hooks/useQueryHooks';
import { FormContext } from 'src/layouts/dashboard/DashboardLayout';

export function SwithGREYERP({
  label,
  name,
  span,
  onChange,

  showLabel = true,
  setItem,
  enterEdit = true,
  value,
}) {
  const { setOpenPrint, popupEditId } = useContext(FormContext);
  const { t } = useTranslation();

  let form = window.location.pathname?.split('/')[2]?.replaceAll('-', '');
  let ID = window.location.pathname?.split('/')[3]?.replaceAll('-', '') ?? popupEditId;

  const { setFormData: setObject, formData: object } = useFormData();

  const { data: objectEdit } = useQuery({
    queryKey: [form, ID],
    select: (res) => res[0],
    enabled: Boolean(form),
  });
  const [defaultCheckVal, setdefaultCheckVal] = useState(value);
  const registerDataChange = (e) => {
    if (!showLabel) {
      let formName = name?.toLowerCase();
      let rights = '';
      setItem((prev) => {
        rights = e.target.checked
          ? prev.concat(formName, ',')
          : prev.replaceAll(`${formName},`, '');

        return rights;
      });

      setdefaultCheckVal(e.target.checked);
      return;
    } else {
      if (name != 'Print') {
        setObject({ [e.target.name]: e.target.checked });
        setdefaultCheckVal(e.target.checked);
      } else {
        setOpenPrint(e.target.checked);
        setdefaultCheckVal(e.target.checked);
      }
    }
  };

  useEffect(() => {
    if (!showLabel && setItem) {
      let rights = '';
      setItem((prev) => {
        rights = value
          ? prev.concat(name?.toLowerCase(), ',')
          : prev.replace(`${name?.toLowerCase()},`, '');
        return rights;
      });
      setdefaultCheckVal(value || false);
    }
  }, [value]);

  useEffect(() => {
    if (objectEdit && Object.keys(objectEdit)?.length > 0 && enterEdit) {
      const objectPropertyNames = Object.keys(objectEdit);
      const valueArray = objectPropertyNames.filter((item, index, object) => item == name);
      const checked = objectEdit[valueArray[0]];
      if (!showLabel) {
        const hasAccess = objectEdit['Rights'].includes(name.toLowerCase());
        setdefaultCheckVal(hasAccess);
        return;
      }
      if (checked) {
        checked && setdefaultCheckVal(checked);
        if (name != 'Print') checked && setObject({ [name]: checked });
      } else {
        if (name != 'Print') setObject({ [name]: false });
      }
    }
  }, [objectEdit]);

  return (
    <Grid item md={span ?? 1}>
      <FormControlLabel
        value={showLabel && name}
        control={
          <Switch
            name={name}
            checked={defaultCheckVal}
            onChange={onChange ? onChange : registerDataChange}
          />
        }
        key={value || defaultCheckVal}
        label={showLabel && label ? t(label) : t(name?.match(/[A-Z][a-z]+|[0-9]+/g)?.join(' '))}
        labelPlacement="top"
      />
    </Grid>
  );
}
