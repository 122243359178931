import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Link } from '@mui/material';
// eslint-disable-next-line import/no-unresolved
import greyErpLogo from'../../assets/logo-white.png';

// ----------------------------------------------------------------------

const LogoWhite = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {
  return (
    <Link component={RouterLink} to="/" sx={{ display: 'contents'}}   >
      <img src={greyErpLogo} style={{marginRight:"15px",marginBottom:'auto',marginTop:'auto',height:'85%'}} alt="Grey Logo"  />
    </Link>
  );
});

LogoWhite.propTypes = {
  sx: PropTypes.object,
  disabledLink: PropTypes.bool,
};

export default LogoWhite;
