import React from 'react';
import { useContext, useEffect, useState } from 'react';
import { CardPanel } from 'src/controls/cardpanel';
import { FormContext } from 'src/layouts/dashboard/DashboardLayout';
import { TextEditorGrey } from 'src/controls/texteditorgrey';
import { Button, Stack, keyframes } from '@mui/material';
import Iconify from 'src/components/iconify/Iconify';
import { useFormData } from 'src/hooks/useQueryHooks';
import { useQuery } from '@tanstack/react-query';
import { HttpRequest } from 'src/data/scripts';

export default function SwapSections({ List, Fields }) {
  const {
    swappedSections,
    setSwappedSections,
    popupEditId,
    // objectEdit,
    listSections,
    setListSections,
  } = useContext(FormContext);

  let form = window.location.pathname?.split('/')[2]?.replaceAll('-', '');
  let ID = window.location.pathname?.split('/')[3]?.replaceAll('-', '') ?? popupEditId;

  const { setFormData: setObject, formData: object } = useFormData();

  const { data: objectEdit } = useQuery({
    queryKey: [form, ID],
    select: (res) => res[0],
    enabled: Boolean(form),
  });

  const [editorCount, seteditorCount] = useState(0);

  const [dragItem, setDragItem] = useState();
  const [editorSectionsNum, seteditorSectionsNum] = useState({});

  const handleDragStart = (index) => {
    // e.target.style.opacity = 0.4;
    setDragItem(index);
    setSwappedSections(true);
    setTimeout(() => {
      setSwappedSections(false);
    }, 3000);
  };

  const handleDragEnter = (e, index) => {
    // console.log(index,dragItem)

    //e.target.style.opacity = 1;
    const newList = [...listSections];
    var draggedItem = newList[dragItem];
    var destinationItem = newList[index];
    newList[dragItem] = destinationItem;
    newList[index] = draggedItem;
    // const item = newList[dragItem];
    // newList.splice(dragItem, 1);
    // newList.splice(index, 0, item);
    setDragItem(index);
    setListSections(newList);
  };

  const handleDragLeave = (e) => {
    // e.target.style.opacity = 1;
  };

  const handleDrop = (e) => {
    // e.target.style.opacity = 1;
  };

  useEffect(() => {
    if (objectEdit && Object.keys(objectEdit).length > 0) {
      HttpRequest(
        `getRecordById/${window.location.pathname
          .split('/')[2]
          .replaceAll('-', '')
          .slice(0, -1)}sections/${objectEdit.ID}`
      )
        .then((response) => response)
        .then((json) => {
          let sections = json;

          let editorCountEdit = 0;
          sections?.sort((a, b) => (a.ID > b.ID ? 1 : -1));
          let listOrder = [];
          let editorSectionsNum = {};

          objectEdit?.SectionsOrder?.split(',').map((i, index) => {
            if (i?.toUpperCase().includes('FIELD') || i?.toUpperCase().includes('UPDATEPANEL')) {
              listOrder.push(List[0]);
            } else if (i?.toUpperCase()?.includes('ITEM')) {
              let item = i.includes('1') ? List[1] : List[2];
              listOrder.push(item);
            } else if (i?.toUpperCase()?.includes('SECTION')) {
              editorSectionsNum[`divSection${editorCountEdit}`] = editorCountEdit;
              editorCountEdit += 1;
              seteditorCount((e) => editorCountEdit + 1);
              let section = (
                <Section
                  editorCount={editorCountEdit}
                  editorSectionsNum={editorSectionsNum}
                  sections={sections[0]?.Content}
                  handleDelete={handleDelete}
                />
              );

              listOrder.push(section);
              sections.shift();
            }
          });
          seteditorSectionsNum(editorSectionsNum);

          if (listOrder.length < 3) {
            setListSections(List);
            return;
          }
          setListSections(listOrder);
        });
    } else if (window.location.pathname.split('/')[3]?.toUpperCase() == 'NEW') {
      setListSections(List);
    }
  }, [objectEdit]);

  const handleDelete = (id) => {
    document.getElementsByClassName(id)[0].parentNode.remove();
  };

  return (
    <>
      <div id="sectionorder">
        {listSections &&
          listSections.map((item, index) => (
            <div
              // draggable
              key={index}
              // onDragStart={() => handleDragStart(index)}
              // onDragEnter={(e) => handleDragEnter(e, index)}
              // onDragLeave={(e) => handleDragLeave(e)}
              // onDrop={(e) => handleDrop(e)}
              // onDragOver={(e) => e.preventDefault()}
            >
              {item}
            </div>
          ))}
      </div>
      <Stack direction={{ md: 'row' }}>
        <Button
          size="small"
          startIcon={<Iconify icon="eva:plus-fill" />}
          onClick={(e) => {
            seteditorCount((e) => e + 1);

            let section = (
              <Section
                editorCount={editorCount}
                editorSectionsNum={editorSectionsNum}
                handleDelete={handleDelete}
              />
            );
            seteditorSectionsNum((prev) => {
              return { ...prev, [`divSection${editorCount}`]: editorCount };
            });
            setListSections((prev) => [...prev, section]);
          }}
          sx={{ flexShrink: 0, mt: 1, ml: 4, my: 4 }}
        >
          Add Section
        </Button>
      </Stack>
    </>
  );
}

function Section({ editorCount, editorSectionsNum, handleDelete, sections }) {
  return (
    <div
      id={`divSection`}
      className={`divSection${editorCount}`}
      style={{ display: 'flex', alignItems: 'center' }}
    >
      <CardPanel
        heading=""
        sx={{
          cursor: 'grab',
        }}
        flexGrow={2}
        cardItem={
          <>
            <TextEditorGrey
              name={`divSection${editorCount}`}
              placeholder="Content"
              section={'section'}
              defaultValue={sections && sections}
            />
          </>
        }
        headerChild={
          <Stack direction="row" justifyContent={'flex-end'} sx={{ px: 3, pt: 1 }}>
            <Button
              sx={{
                minWidth: 'initial',
                '& .css-cstir9-MuiButton-startIcon': {
                  margin: 0,
                },
              }}
              size="small"
              color="error"
              startIcon={
                <Iconify aria-label={`divSection${editorCount}`} icon="eva:trash-2-outline" />
              }
              onClick={(e) => handleDelete(`divSection${editorCount}`)}
            ></Button>
          </Stack>
        }
      />
    </div>
  );
}
