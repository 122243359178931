import { DropDownGREYERP } from 'src/controls/dropdown';
import { TextBoxGREYERP } from 'src/controls/textbox';
import PopUpPanel from 'src/controls/popuppanel';
import UploadGREY from 'src/controls/uploadgrey';
import { SwithGREYERP } from 'src/controls/swithgrey';

export default function Banner() {
  return (
    <>
      <PopUpPanel
        heading={'Add'}
        child={
          <>
            <UploadGREY required={true} span={2} />
            <TextBoxGREYERP name="Title" placeholder="Banner Title" MultiLine={true} focus={true} />
            <TextBoxGREYERP name="TitleArabic" placeholder="عنوان" MultiLine={true} dir="rtl" />
            <TextBoxGREYERP name="SubTitle" placeholder="Banner Sub Title" MultiLine={true} />
            <TextBoxGREYERP name="SubTitleArabic" placeholder="الترجمة" MultiLine={true} dir="rtl" />
            <TextBoxGREYERP name="Description" span={2} multiline={true} placeholder="Banner Description" MultiLine={true} />
            <TextBoxGREYERP name="DescriptionArabic" placeholder="وصف" MultiLine={true} dir="rtl" span={2} />
            <DropDownGREYERP firstItem="0,Main" items={`bannercategories/name`} name="Category" required={true} />
            <TextBoxGREYERP name="Sort" placeholder="Sort" type="Number" required={true} span={0.5} />
            <SwithGREYERP name="Status" span={0.5} />
            <TextBoxGREYERP name="Hyperlink" placeholder="Hyperlink" type="Url" />
            <TextBoxGREYERP name="HyperlinkArabic" placeholder="Hyperlink Arabic" type="Url" />
          </>
        }
      />
    </>
  );
}
