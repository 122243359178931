import { CardPanel } from 'src/controls/cardpanel';
import { DropDownGREYERP } from 'src/controls/dropdown';
import { TextBoxGREYERP } from 'src/controls/textbox';
import NavHorizontal from 'src/layouts/dashboard/nav/NavHorizontal';
import { LeadSource, Titles, CompanyTypes, Access } from 'src/data/data';
import { DropDownENUM } from 'src/controls/dropdownenum';
import UploadGREY from 'src/controls/uploadgrey';
import { Box, Grid } from '@mui/material';

export default function Corporate({ showFilePicker = true }) {
  return (
    <>
      <CardPanel
        heading="Corporate Details"
        cardItem={
          <>
            <UploadGREY />

            <Grid
              container
              md={3}
              sx={{ maxWidth: '80% !important', flexBasis: '80% !important' }}
              spacing={2}
              rowSpacing={1}
              columns={{ xs: 3, sm: 3, md: 4, lg: 4 }}
            >
              <TextBoxGREYERP
                placeholder="Company Name"
                name="Company"
                required={true}
                focus={true}
              />
              <DropDownENUM items={Titles} name="Title" required={true} />
              <TextBoxGREYERP placeholder="John" name="FirstName" required={true} />
              <TextBoxGREYERP placeholder="Doe" name="LastName" />
              <TextBoxGREYERP placeholder="Customer Reference" name="Reference" />
              <TextBoxGREYERP placeholder="VAT Number" name="TaxNumber" label="VAT / Tax Number" />

              <DropDownGREYERP
                items="leadsource/name"
                firstItem="0,None"
                name="LeadSource"
                label="Source"
              />
              <DropDownENUM items={Access} name="Access" />
              <TextBoxGREYERP
                placeholder="Comments (Optional)"
                multiline={true}
                span={2}
                name="Comments"
              />
            </Grid>
          </>
        }
      />
      <CardPanel
        heading="Contact Details"
        cardItem={
          <>
            <TextBoxGREYERP placeholder="2467XXXX" name="Office" />
            <TextBoxGREYERP placeholder="12" name="Extension" />
            <TextBoxGREYERP placeholder="09686372XXX" name="Phone" />
            <TextBoxGREYERP placeholder="09686372XXX" name="Phone2" />
            <TextBoxGREYERP placeholder="someone@something.com" name="Email" />
            <TextBoxGREYERP placeholder="www.something.com" name="Website" />
            <TextBoxGREYERP placeholder="Ghala" name="Location" />
            <TextBoxGREYERP
              placeholder="Way# XXX, Ghala, Muscat..."
              name="Address"
              multiline={true}
              span={2}
            />
          </>
        }
      />
    </>
  );
}
