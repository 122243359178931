import * as React from 'react';
// import TreeView from '@mui/lab/TreeView';
import { TreeView } from '@mui/x-tree-view';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import FolderZipIcon from '@mui/icons-material/FolderZip';
import { Grid } from '@mui/material';
import SourceIcon from '@mui/icons-material/Source';
import FolderCopyIcon from '@mui/icons-material/FolderCopy';
import FolderIcon from '@mui/icons-material/Folder';
import ChartOfAccountsPopup from './chartofaccountspopup';
import { FormContext } from 'src/layouts/dashboard/DashboardLayout';
import Scrollbar from 'src/components/scrollbar/Scrollbar';
import { StyledTreeItemRoot, StyledTreeItem } from 'src/data/data';
import { CardPanel } from 'src/controls/cardpanel';
import { HttpRequest } from 'src/data/scripts';

// color: '#212B36',

let externalDataExist = false;
export default function ChartOfAccounts({ externalData, heading }) {
  const [dataTbl, setDataTbl] = React.useState([]);
  const [ids, setids] = React.useState(['0']);
  const { dataFetched, setDataFetched, openDrawer } = React.useContext(FormContext);

  function addingDepth(items, depth) {
    items.map((i) => {
      i['depth'] = depth;
      i.subRows && addingDepth(i.subRows, depth + 1);
    });
  }

  React.useEffect(() => {
    GetData();
  }, [externalData, dataFetched]);

  async function GetData() {
    externalDataExist = !!externalData;
    let data = externalData
      ? externalData
      : !(window.location.pathname.split('/')[2]?.toLowerCase() == 'dashboard')
        ? await HttpRequest(
            `getrecords/accounts/${localStorage.getItem('username')}/${localStorage.getItem(
              'client'
            )}/${new Date(new Date()).toISOString()}/${new Date(new Date()).toISOString()}`
          )
        : '';

    const idMapping = data.reduce((acc, el, i) => {
      acc[el.ID] = i;
      return acc;
    }, {});

    let root;
    let header = [];
    let dataTbl1 = [];
    let ids = [];

    data.forEach((el) => {
      // Handle the root element
      if (el.ParentId == '0') {
        header.push(el.ID);
        return;
      }
    });

    data.forEach((el) => {
      if (el.ParentId == '0') {
        root = el;
        ids.push(`${el.ParentId}`);
        return;
      }
      ids.push(`${el.ParentId}`);
      const parentEl = data[idMapping[el.ParentId]];
      parentEl && (parentEl.subRows = [...(parentEl.subRows || []), el]);
      if (parentEl && header.includes(parentEl.ID)) {
        dataTbl1.push(parentEl);
      }
    });

    dataTbl1 = [...new Set(dataTbl1)];

    addingDepth(dataTbl1, 0);
    setDataTbl(dataTbl1);
    setids([...new Set(ids)]);
    setDataFetched(true);
  }

  return (
    <React.Fragment >
      <CardPanel
        heading={heading}
        cardItem={
          <Grid item md={12}>
            <TreeView
              defaultExpanded={ids}
              key={ids}
              defaultCollapseIcon={<ArrowDropDownIcon />}
              defaultExpandIcon={<ArrowRightIcon />}
              defaultEndIcon={<div style={{ width: 18 }} />}
            >
              <Scrollbar>{<TreeItemData data={dataTbl} />}</Scrollbar>
            </TreeView>
          </Grid>
        }
      ></CardPanel>
      {openDrawer && <ChartOfAccountsPopup />}
    </React.Fragment>
  );
}

function TreeItemData({ data = [] }) {
  return (
    <React.Fragment >
      {data.length > 0 &&
        data.map((item, index) => {
          let itemDepth = item.depth;
          return (
            <React.Fragment key={item.ID}>
              <StyledTreeItem
                nodeId={`${item.Name}`}
                labelText={item.Name}
                color={
                  !externalDataExist
                    ? itemDepth == 0
                      ? '#ffc107'
                      : itemDepth == 1
                        ? '#0091ea'
                        : itemDepth == 2
                          ? '#64dd17'
                          : itemDepth == 3 && '#212121'
                    : ''
                }
                labelIcon={
                  !externalDataExist
                    ? itemDepth == 0
                      ? FolderZipIcon
                      : itemDepth == 1
                        ? FolderCopyIcon
                        : itemDepth == 2
                          ? FolderIcon
                          : itemDepth == 3 && SourceIcon
                    : null
                }
                key={item.ID}
              >
                {item.subRows && <TreeItemData data={item.subRows} />}
              </StyledTreeItem>
            </React.Fragment>
          );
        })}
    </React.Fragment>
  );
}
