import { DropDownENUM } from 'src/controls/dropdownenum';
import { TextBoxGREYERP } from 'src/controls/textbox';
import { alpha, styled } from '@mui/material/styles';
import { useContext, useEffect } from 'react';
import {
  Box,
  Grid,
  Table,
  TableRow,
  TableBody,
  TableHead,
  TableCell,
  Typography,
  TableContainer,
} from '@mui/material';
import { CardPanel } from 'src/controls/cardpanel';
import { TextEditorGrey } from 'src/controls/texteditorgrey';
import { FontName, docLayoutForms } from 'src/data/data';
import { useState } from 'react';
import { useFormData } from 'src/hooks/useQueryHooks';
import { useQuery } from '@tanstack/react-query';

const StyledRowResult = styled(TableRow)(({ theme }) => ({
  '& td': {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));

export default function DocumentLayout() {
  let formID = window.location.pathname?.split('/')[2]?.replaceAll('-', '');
  let ID = window.location.pathname?.split('/')[3]?.replaceAll('-', '');
  const { setFormData: setObject } = useFormData();
  const { data: objectEdit } = useQuery({
    queryKey: [formID, ID],
    select: (res) => res[0],
    enabled: Boolean(formID) && !!ID,
  });
  const [docColor, setdocColor] = useState();
  const [docFont, setdocFont] = useState();
  const [docFontSize, setdocFontSize] = useState();

  function setDocumentColor(e) {
    var elements = document.getElementsByClassName('document-color');
    for (var i = 0; i < elements.length; i++) {
      elements[i].style.color = e.target.value;
    }

    var bgelements = document.getElementsByClassName('document-bg');

    for (var i = 0; i < bgelements.length; i++) {
      bgelements[i].style.backgroundColor = e.target.value;
    }
    setdocColor(e.target.value);
    setObject((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  }
  function setDocumentFont(e, name, font) {
    if (font) {
      var elements = document.getElementsByClassName('document-font');

      for (var i = 0; i < elements.length; i++) {
        elements[i].style.fontFamily = font.ID;
      }
      setdocFont(font.ID);
      setObject((prev) => {
        return { ...prev, [name]: font.ID };
      });
    }
  }
  function setDocumentFontSize(e) {
    var elements = document.getElementsByClassName('document-fontSize');
    for (var i = 0; i < elements.length; i++) {
      elements[i].style.fontSize = e.target.value + 'px';
    }
    setdocFontSize(e.target.value);
    setObject((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  }

  useEffect(() => {
    if (isNaN(window.location.pathname.split('/')[3])) {
      setdocColor('#00000');
      setObject((prev) => {
        return { ...prev, ['Color']: '#00000' };
      });
    }
  }, []);

  return (
    <>
      <CardPanel
        heading="Layout Details"
        cardItem={
          <>
            <TextBoxGREYERP name="Name" placeholder="Layout Name" required={true} focus={true} />
            <TextBoxGREYERP
              name="Color"
              onChange={setDocumentColor}
              value={docColor}
              placeholder="Choose Color"
              type="color"
              span={0.3}
            />
            <DropDownENUM
              items={FontName}
              onChange={(e, newValue) => setDocumentFont(e, 'Font', newValue)}
              postBackSelectedValue={docFont}
              name="Font"
              required={true}
              span={1.3}
            />
            <TextBoxGREYERP
              name="FontSize"
              onChange={setDocumentFontSize}
              value={docFontSize}
              span={0.5}
              placeholder="14"
              type="Number"
              required={true}
            />
            <DropDownENUM
              name="Documents"
              // multiple={true}
              items={docLayoutForms}
              placeholder={'Select Documents'}
              span={1.9}
            />
            <TextBoxGREYERP
              name="Comments"
              multiline={true}
              placeholder="Comments"
              MultiLine={true}
              span={2}
            />
          </>
        }
      />

      <CardPanel
        heading="Header"
        cardItem={
          <>
            <TextEditorGrey name="Header" placeholder="Document Header" />
            <TextEditorGrey
              name="Heading"
              className="document-color document-font document-fontSize"
              placeholder="Document Name"
            />

            <Grid
              container
              sx={{
                pt: 3,
                pl: 3,
                '& p, & th': {
                  fontSize: docFontSize ?? objectEdit?.FontSize,
                  fontFamily: docFont ?? objectEdit?.Font,
                },
              }}
            >
              <Grid item xs={12} sm={6} sx={{}}>
                <Typography
                  className="document-font document-fontSize"
                  variant="body2"
                  sx={{ fontWeight: 'bold' }}
                >
                  Document Date: <Typography variant="span" sx={{ fontWeight: '200' }}></Typography>
                </Typography>

                <Typography
                  className="document-font document-fontSize"
                  variant="body2"
                  sx={{ fontWeight: 'bold' }}
                >
                  Document # <Typography variant="span" sx={{ fontWeight: '200' }}></Typography>
                </Typography>

                <Typography
                  className="document-font document-fontSize"
                  variant="body2"
                  sx={{ fontWeight: 'bold' }}
                >
                  Valid Through: <Typography variant="span" sx={{ fontWeight: '200' }}></Typography>
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6} sx={{ mb: 5 }}>
                <Typography
                  className="document-font document-fontSize"
                  variant="body2"
                  sx={{ fontWeight: 'bold' }}
                >
                  Document To: <Typography variant="span" sx={{ fontWeight: '200' }}></Typography>
                </Typography>

                <Typography
                  className="document-font document-fontSize"
                  variant="body2"
                  sx={{ fontWeight: 'bold' }}
                >
                  Address: <Typography variant="span" sx={{ fontWeight: '200' }}></Typography>
                </Typography>
                <Typography
                  className="document-font document-fontSize"
                  variant="body2"
                  sx={{ fontWeight: 'bold' }}
                >
                  Contact Person:{' '}
                  <Typography variant="span" sx={{ fontWeight: '200' }}></Typography>
                </Typography>
              </Grid>
            </Grid>

            <TableContainer
              sx={{
                overflow: 'unset',
                pt: 5,
                pl: 3,
                '& p, & th': {
                  fontSize: docFontSize ?? objectEdit?.FontSize,
                  fontFamily: docFont ?? objectEdit?.Font,
                },
              }}
            >
              {/* <Scrollbar> */}
              <Table sx={{ width: '100%' }}>
                <TableHead
                  sx={{
                    borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
                    '& th': {
                      backgroundColor: docColor ?? objectEdit?.Color ?? 'black',
                      color: 'white',
                    },
                  }}
                >
                  <TableRow>
                    {/* {columns &&
                    columns.map((row, index) => ( */}
                    <TableCell
                      className="document-bg document-font document-fontSize"
                      align="left"
                      sx={{ pt: 0, pb: 0 }}
                    >
                      Item
                    </TableCell>
                    <TableCell
                      className="document-bg document-font document-fontSize"
                      align="right"
                      sx={{ pt: 0, pb: 0 }}
                    >
                      Price
                    </TableCell>
                    <TableCell
                      className="document-bg document-font document-fontSize"
                      align="right"
                      sx={{ pt: 0, pb: 0 }}
                    >
                      Qty
                    </TableCell>
                    <TableCell
                      className="document-bg document-font document-fontSize"
                      align="right"
                      sx={{ pt: 0, pb: 0 }}
                    >
                      Discount
                    </TableCell>
                    <TableCell
                      className="document-bg document-font document-fontSize"
                      align="right"
                      sx={{ pt: 0, pb: 0 }}
                    >
                      Tax
                    </TableCell>
                    <TableCell
                      className="document-bg document-font document-fontSize"
                      align="right"
                      sx={{ pt: 0, pb: 0 }}
                    >
                      Total
                    </TableCell>
                    {/* ))} */}
                  </TableRow>
                </TableHead>
                {
                  <TableBody>
                    <StyledRowResult>
                      <TableCell
                        className="document-font document-fontSize"
                        align="left"
                        sx={{ border: '1px solid rgb(224, 224, 224)' }}
                      >
                        <Typography>Item 1</Typography>
                      </TableCell>
                      <TableCell
                        align="right"
                        className="document-font document-fontSize"
                        sx={{ border: '1px solid rgb(224, 224, 224)' }}
                      >
                        0.000
                      </TableCell>
                      <TableCell
                        className="document-font document-fontSize"
                        align="right"
                        sx={{ border: '1px solid rgb(224, 224, 224)' }}
                      >
                        0.000
                      </TableCell>

                      <TableCell
                        className="document-font document-fontSize"
                        align="right"
                        sx={{ border: '1px solid rgb(224, 224, 224)' }}
                      >
                        0.000
                      </TableCell>
                      <TableCell
                        className="document-font document-fontSize"
                        align="right"
                        sx={{ border: '1px solid rgb(224, 224, 224)' }}
                      >
                        0.000
                      </TableCell>
                      <TableCell
                        className="document-font document-fontSize"
                        align="right"
                        sx={{ border: '1px solid rgb(224, 224, 224)' }}
                      >
                        0.000
                      </TableCell>
                    </StyledRowResult>
                    <StyledRowResult>
                      <TableCell
                        className="document-font document-fontSize"
                        align="left"
                        sx={{ border: '1px solid rgb(224, 224, 224)' }}
                      >
                        <Typography>Item 2</Typography>
                      </TableCell>
                      <TableCell
                        className="document-font document-fontSize"
                        align="right"
                        sx={{ border: '1px solid rgb(224, 224, 224)' }}
                      >
                        0.000
                      </TableCell>
                      <TableCell
                        className="document-font document-fontSize"
                        align="right"
                        sx={{ border: '1px solid rgb(224, 224, 224)' }}
                      >
                        0.000
                      </TableCell>

                      <TableCell
                        className="document-font document-fontSize"
                        align="right"
                        sx={{ border: '1px solid rgb(224, 224, 224)' }}
                      >
                        0.000
                      </TableCell>
                      <TableCell
                        className="document-font document-fontSize"
                        align="right"
                        sx={{ border: '1px solid rgb(224, 224, 224)' }}
                      >
                        0.000
                      </TableCell>
                      <TableCell
                        className="document-font document-fontSize"
                        align="right"
                        sx={{ border: '1px solid rgb(224, 224, 224)' }}
                      >
                        0.000
                      </TableCell>
                    </StyledRowResult>
                    <StyledRowResult>
                      <TableCell
                        className="document-font document-fontSize"
                        align="left"
                        sx={{ border: '1px solid rgb(224, 224, 224)' }}
                      >
                        <Typography>Item 3</Typography>
                      </TableCell>
                      <TableCell
                        className="document-font document-fontSize"
                        align="right"
                        sx={{ border: '1px solid rgb(224, 224, 224)' }}
                      >
                        0.000
                      </TableCell>
                      <TableCell
                        className="document-font document-fontSize"
                        align="right"
                        sx={{ border: '1px solid rgb(224, 224, 224)' }}
                      >
                        0.000
                      </TableCell>

                      <TableCell
                        className="document-font document-fontSize"
                        align="right"
                        sx={{ border: '1px solid rgb(224, 224, 224)' }}
                      >
                        0.000
                      </TableCell>
                      <TableCell
                        className="document-font document-fontSize"
                        align="right"
                        sx={{ border: '1px solid rgb(224, 224, 224)' }}
                      >
                        0.000
                      </TableCell>
                      <TableCell
                        className="document-font document-fontSize"
                        align="right"
                        sx={{ border: '1px solid rgb(224, 224, 224)' }}
                      >
                        0.000
                      </TableCell>
                    </StyledRowResult>
                    <StyledRowResult>
                      <TableCell
                        className="document-color document-font document-fontSize"
                        colSpan={2}
                        sx={{ fontWeight: 'bold', pb: 0 }}
                      >
                        Total Items: 3
                      </TableCell>
                      <TableCell
                        className="document-color document-font document-fontSize"
                        colSpan={2}
                        sx={{ fontWeight: 'bold', pb: 0 }}
                      >
                        Total Qty: 3
                      </TableCell>
                      <TableCell
                        className="document-font document-fontSize"
                        align="right"
                        sx={{ typography: 'body1' }}
                      >
                        <Box sx={{ mt: 2 }} />

                        <Typography variant="body2" sx={{ fontWeight: 'bold', pb: 0 }}>
                          Sub Total
                        </Typography>
                      </TableCell>

                      <TableCell
                        className="document-font document-fontSize"
                        align="right"
                        width={100}
                        sx={{ typography: 'body1' }}
                      >
                        <Box />
                        <Typography variant="body2" sx={{ fontWeight: 'bold', pb: 0 }}>
                          0.000
                        </Typography>
                      </TableCell>
                    </StyledRowResult>

                    <StyledRowResult>
                      <TableCell colSpan={2} />
                      <TableCell colSpan={2}></TableCell>
                      <TableCell
                        className="document-font document-fontSize"
                        align="right"
                        sx={{ typography: 'body1' }}
                      >
                        <Typography variant="body2" sx={{ fontWeight: 'bold', pb: 0 }}>
                          Total Discount
                        </Typography>
                      </TableCell>

                      <TableCell
                        className="document-font document-fontSize"
                        align="right"
                        width={120}
                        sx={{ typography: 'body1' }}
                      >
                        <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                          0.000
                        </Typography>
                      </TableCell>
                    </StyledRowResult>

                    <StyledRowResult>
                      <TableCell colSpan={2} />
                      <TableCell colSpan={2}></TableCell>
                      <TableCell
                        className="document-font document-fontSize"
                        align="right"
                        sx={{ typography: 'body1' }}
                      >
                        <Typography variant="body2" sx={{ fontWeight: 'bold', pb: 0 }}>
                          Total VAT
                        </Typography>
                      </TableCell>

                      <TableCell
                        className="document-font document-fontSize"
                        align="right"
                        width={120}
                        sx={{ typography: 'body1' }}
                      >
                        <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                          0.000
                        </Typography>
                      </TableCell>
                    </StyledRowResult>

                    <StyledRowResult>
                      <TableCell colSpan={2} className="document-font document-fontSize">
                        <Typography variant="body2" sx={{ fontWeight: 'bold' }}></Typography>
                      </TableCell>
                      <TableCell colSpan={2}></TableCell>
                      <TableCell
                        className="document-color document-font document-fontSize"
                        align="right"
                        sx={{ typography: 'h6' }}
                      >
                        <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                          Grand Total
                        </Typography>
                      </TableCell>

                      <TableCell
                        className="document-color document-font document-fontSize"
                        align="right"
                        width={100}
                        sx={{ typography: 'h6' }}
                      >
                        <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                          RO 0.000
                        </Typography>
                      </TableCell>
                    </StyledRowResult>
                  </TableBody>
                }
              </Table>
              {/* </Scrollbar> */}
            </TableContainer>
          </>
        }
      />

      <CardPanel
        heading="Footer"
        cardItem={
          <>
            <TextEditorGrey name="Bottom" placeholder="Document Signature" />
            <TextEditorGrey name="Footer" placeholder="Document Footer" />
          </>
        }
      />
    </>
  );
}
