import { Box, Checkbox, FormControlLabel, FormGroup, Grid, InputAdornment, Paper, Typography } from "@mui/material";
import { useContext, useEffect, useState } from 'react';


// eslint-disable-next-line import/no-unresolved
import { CardPanel } from "src/controls/cardpanel";
import { DropDownGREYERP } from "src/controls/dropdown";
import { TextBoxGREYERP } from "src/controls/textbox";
import NavHorizontal from "src/layouts/dashboard/nav/NavHorizontal";

import { DropzoneGREY } from "src/controls/dropzone";
import UppyFilePicker from 'src/controls/uppyFilePicker';
import { formatdecimals } from "src/data/scripts";
import { FormContext } from 'src/layouts/dashboard/DashboardLayout';
export default function Loan()
{ 
  const [amount, setAmount] = useState(0);
  const [period, setPeriod] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const { object, setObject, objectEdit,setObjectEdit } = useContext(FormContext);

  function onChangeAmount(e) {

    setAmount(e.target.value)
    let totalinstallmentAmount = 0;
     if(e.target.value != '0' && period !='0')
     {
      totalinstallmentAmount = (parseFloat(e.target.value) / parseInt(period)).toFixed(3);
      setTotalAmount(formatdecimals(totalinstallmentAmount));
      setObject((prev) => { return { ...prev, ['Amount']: formatdecimals(parseFloat(e.target.value))}});
      setObject((prev) => { return { ...prev, ['Installment']: formatdecimals(totalinstallmentAmount) }});
     }
     else
    {
      setObject((prev) => { return { ...prev, ['Amount']: formatdecimals(parseFloat(e.target.value))}});
    }

  
    } 

    function onChangePeriod(e) {

      setPeriod(e.target.value)
      let totalinstallmentAmount = 0;
       if(e.target.value != '0' && amount !='0')
       {
        totalinstallmentAmount = (parseFloat(amount) / parseInt(e.target.value)).toFixed(3);
        setTotalAmount(formatdecimals(totalinstallmentAmount));
        setObject((prev) => { return { ...prev, ['Period']: parseInt(e.target.value)}});
        setObject((prev) => { return { ...prev, ['Installment']: formatdecimals(totalinstallmentAmount) }});
       }
       else
       {
        setObject((prev) => { return { ...prev, ['Period']: parseInt(e.target.value)}});
       }
    
      } 
      useEffect(() => {
        if (Object.keys(objectEdit).length > 0) {
          setAmount(`${objectEdit['Amount'] ?? ''}`)
          setPeriod(`${objectEdit['Period'] ?? ''}`)
          setTotalAmount(`${objectEdit['Installment'] ?? ''}`)
        }
      }, [objectEdit]);

    return(
        <>

      <CardPanel heading="Loan Details" cardItem={<>
      
<Picker type='date' name="Date" required={true} focus={true}></Picker>

<TextBoxGREYERP name="Name" placeholder="Personal Loan" required={true} />                        
<DropDownGREYERP items={`employees/name`} name="Employee" required={true} />


<TextBoxGREYERP placeholder="Loan Amount" name="Amount" required={true} type="number" step="any" onChange={onChangeAmount}/>
<TextBoxGREYERP placeholder="Months" name="Period" required={true} type="number"  onChange={onChangePeriod}/>
<TextBoxGREYERP placeholder="EMI" name="Installment" required={true} type="number" step="any" disabled defaultValue={totalAmount} />
<TextBoxGREYERP placeholder="Internal Notes" name="Comments" multiline={true} MultiLine={true} />                 

</>} />
<UppyFilePicker/>
   </>


  )

 
}
