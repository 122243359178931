import PropTypes from 'prop-types';
import { useState } from 'react';
// @mui
import {
  Box,
  Paper,
  Stack,
  Tooltip,
  Checkbox,
  IconButton,
  InputBase,
  ClickAwayListener,
} from '@mui/material';



// ----------------------------------------------------------------------

const defaultTask = {
  attachments: [],
  comments: [],
  description: '',
  due: [null, null],
  assignee: [],
};

KanbanTaskAdd.propTypes = {
  onAddTask: PropTypes.func,
  onCloseAddTask: PropTypes.func,
};

export default function KanbanTaskAdd({ onAddTask, onCloseAddTask, setRefresh }) {
  const [name, setName] = useState('');

  const [completed, setCompleted] = useState(false);

  const [openContacts, setOpenContacts] = useState(false);

  // const {
  //   startDate,
  //   endDate,
  //   onChangeStartDate,
  //   onChangeEndDate,
  //   open: openPicker,
  //   onOpen: onOpenPicker,
  //   onClose: onClosePicker,
  //   isSelected: isSelectedValuePicker,
  //   isError,
  //   shortLabel,
  // } = useDateRangePicker(new Date(), new Date());

  const [open, setOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState();

  const handleOpenContacts = () => {
    setOpenContacts(true);
  };

  const handleCloseContacts = () => {
    setOpenContacts(false);
  };

  const handleKeyUpAddTask = (event) => {
    event.key === 'Enter' && event.preventDefault();
    if (event.key === 'Enter') {
      if (name.trim() !== '') {
        onAddTask({
          ...defaultTask,
          id: 0,
          name,
          due: [selectedDate],
          setName
        });
        //onCloseAddTask();
        //setRefresh(true);
      }
    }
  };

  const handleClickAddTask = () => {
    if (name) {
      onAddTask({
        ...defaultTask,
        id:0 ,
        name,
        due: [selectedDate],
      });
    } else {
      onCloseAddTask();
    }
  };

  const handleChangeCompleted = (event) => {
    setCompleted(event.target.checked);
  };

  const onAccept = (e) => {
    setSelectedDate(e);
  };
  const onOpen = () => {
    setOpen((prev) => !prev);
  };

  const onClose = () => {
    setOpen(false);
  };
  return (
    <ClickAwayListener onClickAway={handleClickAddTask}>
      <div>
        <Paper variant="outlined" >
          <InputBase
            multiline
            fullWidth
            placeholder="Task name"
            value={name}
            autoFocus={true}
            onChange={(event) => setName(event.target.value)}
            onKeyDown={handleKeyUpAddTask}
            sx={{fontWeight:'400 !important',lineHeight: '40px',
           pr: 1,
            pl: 3,fontSize:'0.875rem',
            whiteSpace: 'break-spaces !important', }}
          />

          <Stack direction="row" justifyContent="space-between" sx={{ pl: 1, pr: 1.5, pb: 2 }}>
            {/* <Tooltip title="Mark complete">
              <Checkbox
                disableRipple
                checked={completed}
                onChange={handleChangeCompleted}
                icon={<Iconify icon="eva:radio-button-off-outline" />}
                checkedIcon={<Iconify icon="eva:checkmark-circle-2-outline" />}
              />
            </Tooltip> */}

            {/* <Stack direction="row" spacing={1.5} alignItems="center">
              <Tooltip title="Assign" onClick={handleOpenContacts}>
                <IconButton size="small">
                  <Iconify icon="eva:people-fill" />
                </IconButton>
              </Tooltip>

              {false ? (
                <Box
                  onClick={onOpen}
                  sx={{
                    cursor: 'pointer',
                    typography: 'caption',
                    '&:hover': { opacity: 0.72 },
                  }}
                >
                  {/* {shortLabel} */}
            {/* </Box>
              ) : (
                <Tooltip title="Due date">
                  <IconButton size="small" onClick={onOpen}>
                    <Iconify icon="eva:calendar-fill" />
                  </IconButton>
                </Tooltip>
              )}
            </Stack> */}
          </Stack>
        </Paper>

        {/* <KanbanContactsDialog open={openContacts} onClose={handleCloseContacts} />
        {open && <Picker type='date' open={open} onAccept={onAccept} onOpen={onOpen} name="Date" placeholder="Choose due date" ></Picker>}
      */}
        {/* <DateRangePicker
          variant="calendar"
          title="Choose due date"
          startDate={startDate}
          endDate={endDate}
          onChangeStartDate={onChangeStartDate}
          onChangeEndDate={onChangeEndDate}
          open={openPicker}
          onClose={onClosePicker}
          isSelected={isSelectedValuePicker}
          isError={isError}
        /> */}
      </div>
    </ClickAwayListener>
  );
}
