import { Outlet } from 'react-router-dom';
// @mui
import { Stack, Container } from '@mui/material';
// hooks
// config
import { HEADER } from '../../config-global';

// ----------------------------------------------------------------------

export default function CompactLayout() {


  return (
    <>
  

      <Container component="main">
        <Stack
          sx={{
            
        
            maxWidth: '100vw',
            minHeight: '100vh',
          
            justifyContent: 'center',
            alignItems:'center'
          }}
        >
          <Outlet />
        </Stack>
      </Container>
    </>
  );
}
