// @mui
import { useTheme, styled } from '@mui/material/styles';
// components
import { useContext, useEffect, useState } from 'react';
import { CardPanel } from 'src/controls/cardpanel';
import { TableGREYERP } from 'src/controls/table';
import ChartGREYERP from 'src/controls/chart';
import { CustomAvatar } from 'src/components/custom-avatar';
import TenantsImage from '../../assets/avatar_12.jpg';
import ResidentsImage from '../../assets/avatar_9.jpg';
import VacantImage from '../../assets/vacant.png';
import MaintenanceImage from '../../assets/maintenance.png';
import ExpiryImage from '../../assets/expiry.png';
import { Box, Card, Table, Button, Grid, Stack, Typography, Checkbox } from '@mui/material';
import Picker from 'src/controls/picker';
import { CheckBoxGREYERP } from 'src/controls/checkbox';
import { FormContext } from 'src/layouts/dashboard/DashboardLayout';
import { HttpRequest, formatdecimals } from 'src/data/scripts';
import { useTranslation } from 'react-i18next';
import useResponsive from 'src/hooks/useResponsive';

export default function PropertiesDashboard() {
  const { t } = useTranslation();
  const [buildings, setBuildings] = useState([]);
  const [totalBuildings, setTotalBuildings] = useState(0);
  const [checkedbuildings, setCheckedBuildings] = useState();
  const [firstDate, setfirstDate] = useState('');
  const [lastDate, setLastDate] = useState('');
  const [buildingsUnit, setbuildingsUnit] = useState(0);
  const [totalResidents, setTotalResidents] = useState(0);
  const [totalRevenue, setTotalRevenue] = useState(0);
  const [totalOutstanding, setTotalOutstanding] = useState(0);
  const [dashboardRent, setDashboardRent] = useState();
  const [dashboardAssociations, setDashboardAssociations] = useState();
  const [unitType, setUnitType] = useState([]);
  const [unitOccupancy, setUnitOccupancy] = useState([]);

  const [contractMaintenances, setContractMaintenances] = useState();
  const [contractCancellation, setContractCancellation] = useState([]);
  const [rentRevenue, setRentRevenue] = useState([]);
  const isDesktop = useResponsive('up', 'lg');
  //
  const [totalContractExpiring, setTotalContractExpiring] = useState(0);
  const [totalRentOutstanding, setTotalRentOutstanding] = useState(0);
  const [totalPendingMaintenances, setTotalPendingMaintenances] = useState(0);
  const [totalEmptyUnits, setTotalEmptyUnits] = useState(0);
  const [generateTrigger, setGenerateTrigger] = useState(false);
  const { clientId, setObject } = useContext(FormContext);

  function generateReport() {
    let building = buildings
      .map((i) => {
        if (i.checked) {
          return i.ID;
        }
      })
      .filter((item) => item !== undefined);

    //  console.log(building);
    setCheckedBuildings(building.join());
    DashboardStats(building.join(), firstDate, lastDate);
    setGenerateTrigger(true);
  }

  function onChangeFirstDate(e) {
    if (e) {
      setfirstDate(e);
      setObject((prev) => {
        return { firstDate: e };
      });
    }
  }

  function onChangelastDate(e) {
    if (e) {
      setLastDate(e);
      setObject((prev) => {
        return { lastDate: e };
      });
    }
  }

  async function DashboardStats(blgstring, Fromdate, lastDate) {
    let JsonUnits = await HttpRequest(
      `getRecords/dashboardunits/${localStorage.getItem('username')}/${localStorage.getItem(
        'client'
      )}/${Fromdate}/${lastDate}/${blgstring}`
    );

    if (JsonUnits) {
      setbuildingsUnit(JsonUnits.Units);
    }
    /////////Residents
    let JsonResidents = await HttpRequest(
      `getRecords/dashboardresidents/${localStorage.getItem('username')}/${localStorage.getItem(
        'client'
      )}/${Fromdate}/${lastDate}/${blgstring}`
    );
    //  console.log(JsonResidents);
    if (JsonResidents) {
      setTotalResidents(JsonResidents.count);
    }

    /////////Unit Type
    let objectUnitType = await HttpRequest(
      `getRecords/dashboardunittypes/${localStorage.getItem('username')}/${localStorage.getItem(
        'client'
      )}/${Fromdate}/${lastDate}/${blgstring}`
    );

    /////////Dashboard Associations
    let objectAssociations = await HttpRequest(
      `getRecords/dashboardassociations/${localStorage.getItem('username')}/${localStorage.getItem(
        'client'
      )}/${Fromdate}/${lastDate}/${blgstring}`
    );
    if (objectAssociations?.length > 0) {
      setDashboardAssociations(objectAssociations[0]);
    }

    setUnitType(objectUnitType);

    /////////Unit Occupancy
    let objectUnitOccupancy = await HttpRequest(
      `getRecords/dashboardunitoccupancy/${localStorage.getItem('username')}/${localStorage.getItem(
        'client'
      )}/${Fromdate}/${lastDate}/${blgstring}`
    );
    if (objectUnitOccupancy?.length > 0) {
      //  console.log(objectUnitOccupancy, 'unitocc');
      setUnitOccupancy(objectUnitOccupancy);
    }

    let objectCancellation = await HttpRequest(
      `getRecords/dashboardcontractcancellation/${localStorage.getItem(
        'username'
      )}/${localStorage.getItem('client')}/${Fromdate}/${lastDate}/${blgstring}`
    );
    if (objectCancellation?.length > 0) {
      setContractCancellation(objectCancellation);
    }

    // let objectMaintenance = await HttpRequest(
    //   `getRecords/dashboardmaintenancedatewisesummary/${localStorage.getItem(
    //     'username'
    //   )}/${localStorage.getItem(
    //     'client'
    //   )}/${Fromdate}/${lastDate}/${blgstring}`
    // );
    // if (objectMaintenance?.length > 0) {
    //   setContractMaintenances(objectMaintenance);
    // }

    /////////Dashboard Rent
    let JsonRents = await HttpRequest(
      `getRecords/dashboardrent/${localStorage.getItem('username')}/${localStorage.getItem(
        'client'
      )}/${Fromdate}/${lastDate}/${blgstring}`
    );
    if (JsonRents?.length > 0) {
      // console.log(JsonRents,'dashboardrent')
      setTotalOutstanding(JsonRents[0]?.Due);
      setTotalRevenue(JsonRents[0]?.Received);
      setDashboardRent(JsonRents[0]);
    }

    let objectExpense = await HttpRequest(
      `getRecords/dashboardrentexpensesummary/${localStorage.getItem(
        'username'
      )}/${localStorage.getItem('client')}/${Fromdate}/${lastDate}/${blgstring}`
    );
    if (objectExpense?.length > 0) {
      setRentRevenue(objectExpense);
    }

    setGenerateTrigger(true);
    setTimeout(() => {
      setGenerateTrigger(false);
    }, 5000);
  }
  const handleCheckBuildingEvent = (e) => {
    let buildings1 = buildings;
    let updatedList = buildings1.map((item) => {
      if (parseInt(item.ID) == parseInt(e.target.name)) {
        return { ...item, checked: e.target.checked };
      }
      return item;
    });

    setBuildings(updatedList);
    //setObject({ ...object, [e.target.name]: e.target.checked });
  };

  useEffect(() => {
    if (buildings.length > 0) {
      generateReport();
    }
  }, [clientId]);

  useEffect(() => {
    ////Building////////////
    LoadBuilding();
  }, []);

  async function LoadBuilding() {
    /////////Building
    let objectBuildings = await HttpRequest(
      `getItems/buildings/Name/${localStorage.getItem('username')}/${localStorage.getItem(
        'client'
      )}`
    );
    if (objectBuildings?.length > 0) {
      objectBuildings.forEach((i) => {
        i['checked'] = true;
      });
      setBuildings(objectBuildings);
      setTotalBuildings(objectBuildings.length);
      const selectedColumnArray = objectBuildings.map((obj) => obj.ID);
      const blgstring = selectedColumnArray.join(',');

      setfirstDate(localStorage.getItem('firstdate'));
      setLastDate(localStorage.getItem('lastdate'));
      setCheckedBuildings(blgstring);
      DashboardStats(
        blgstring,
        localStorage.getItem('firstdate'),
        localStorage.getItem('lastdate')
      );
    }
  }

  return (
    <>
      <Grid container direction="row" sx={{ marginTop: '0px', mb: 2 }} spacing={2}>
        <Grid item md={12}>
          <CardPanel
            justifyContent="center"
            cardItem={
              <>
                <Picker
                  type="date"
                  onChange={onChangeFirstDate}
                  name="FirstDate"
                  postBackDate={firstDate}
                ></Picker>
                <Picker
                  type="date"
                  onChange={onChangelastDate}
                  name="LastDate"
                  postBackDate={lastDate}
                ></Picker>

                <Button
                  variant="contained"
                  sx={{ displayPrint: 'none', ml: 2 }}
                  style={{ marginTop: '10px' }}
                  // onClick={generateReport}
                  name="generatereport"
                  onClick={generateReport}
                >
                  {t('Generate')}
                </Button>
              </>
            }
          />
        </Grid>
        <Grid item md={3} xs={!isDesktop && 12}>
          <CardPanel
            heading={t('Buildings')}
            subHeading={t('Total') + ' ' + totalBuildings}
            cardItem={
              <Stack
                sx={{
                  ml: 2,
                  maxHeight: '400px',
                  overflow: 'auto',
                  overflowX: 'hidden',
                  width: '100%',
                }}
              >
                {buildings &&
                  buildings.map((row, index) => (
                    <CheckBoxGREYERP
                      span="12"
                      name={row.ID}
                      label={row.Name}
                      checked={row.checked}
                      onChange={handleCheckBuildingEvent}
                    ></CheckBoxGREYERP>
                  ))}
              </Stack>
            }
          />
        </Grid>
        <Grid item md={3} xs={!isDesktop && 12}>
          <CardPanel
            heading={t('Units')}
            subHeading={t('Total') + ' ' + buildingsUnit}
            cardItem={
              <>
                <Grid
                  container
                  direction="row"
                  sx={{ mb: 2, px: 2, mt: 2 }}
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Stack direction="row" alignItems="center" sx={{ ml: 2 }} spacing={2}>
                    <CustomAvatar src={TenantsImage} name="Tenants" />
                    <Typography variant="subtitle2">{t('Tenants')}</Typography>
                  </Stack>
                  <Typography variant="h6">
                    {buildingsUnit && buildingsUnit - totalEmptyUnits}
                  </Typography>
                </Grid>
                <Grid
                  container
                  sx={{ mb: 2, px: 2 }}
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Stack direction="row" alignItems="center" sx={{ ml: 2 }} spacing={2}>
                    <CustomAvatar src={ResidentsImage} name="Residents" />
                    <Typography variant="subtitle2">{t('Residents')}</Typography>
                  </Stack>
                  <Typography variant="h6">{totalResidents}</Typography>
                </Grid>
                <Grid
                  sx={{ mb: 2, px: 2 }}
                  container
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Stack direction="row" alignItems="center" sx={{ ml: 2 }} spacing={2}>
                    <CustomAvatar src={VacantImage} name="Vacant" />
                    <Typography variant="subtitle2">{t('Vacant')}</Typography>
                  </Stack>
                  <Typography variant="h6">{totalEmptyUnits}</Typography>
                </Grid>
                <Grid
                  sx={{ mb: 2, px: 2 }}
                  container
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Stack direction="row" alignItems="center" sx={{ ml: 2 }} spacing={2}>
                    <CustomAvatar src={ExpiryImage} name="Expiry" />
                    <Typography variant="subtitle2">{t('Expiry')}</Typography>
                  </Stack>
                  <Typography variant="h6">{totalContractExpiring}</Typography>
                </Grid>
                <Grid
                  sx={{ mb: 2, px: 2 }}
                  container
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Stack direction="row" alignItems="center" sx={{ ml: 2 }} spacing={2}>
                    <CustomAvatar src={MaintenanceImage} name="Under Maintenance" />
                    <Typography variant="subtitle2">{t('Maintenance')}</Typography>
                  </Stack>
                  <Typography variant="h6">{totalPendingMaintenances}</Typography>
                </Grid>
              </>
            }
          />
        </Grid>
        <Grid item md={3}>
          <CardPanel
            heading={t('Rents')}
            subHeading={`${t('Till Date')} ${
              dashboardRent
                ? Object.values(dashboardRent)
                    .reduce((a, b) => a + b, 0)
                    .toFixed(3)
                : 0
            }`}
            cardItem={
              <>
                {/* dashboardrent */}
                <Grid item md={12}>
                  {dashboardRent && <ChartGREYERP data={dashboardRent} type="bar" name="Rents" />}
                </Grid>
              </>
            }
          />
        </Grid>
        <Grid item md={3}>
          <CardPanel
            heading={t('Associations')}
            subHeading={`${t('Till Date')} ${
              dashboardAssociations
                ? Object.values(dashboardAssociations)
                    .reduce((a, b) => a + b, 0)
                    .toFixed(3)
                : 0
            }`}
            cardItem={
              <>
                {/* dashboardassociations */}
                <Grid item md={12}>
                  {dashboardAssociations && (
                    <ChartGREYERP data={dashboardAssociations} type="bar" name="Associations" />
                  )}
                </Grid>
              </>
            }
          />
        </Grid>

        <Grid item md={8} xs={!isDesktop && 12}>
          <CardPanel
            heading={t('Rent Outstandings')}
            subHeading={`${t('Total')} ${totalRentOutstanding}`}
            cardItem={
              <>
                {checkedbuildings && (
                  <TableGREYERP
                    table="dashboardrentoutstanding"
                    firstDate={firstDate && firstDate}
                    lastDate={lastDate && lastDate}
                    interval={checkedbuildings}
                    name="Rent Outstandings"
                    setItem={setTotalRentOutstanding}
                    reTrigger={generateTrigger}
                  />
                )}
              </>
            }
          />
        </Grid>

        <Grid item md={4} xs={!isDesktop && 12}>
          <CardPanel
            heading={t('Unit Types')}
            subHeading={`${t('Total')} ${
              unitType.length > 0 ? unitType.map((i) => i.Count).reduce((a, b) => a + b, 0) : 0
            }`}
            cardItem={
              <>
                <Grid item md={12}>
                  {/* dashboardunittypes */}
                  {unitType.length > 0 && <ChartGREYERP data={unitType} type="pie" />}
                </Grid>
              </>
            }
          />
        </Grid>
        <Grid item md={12} xs={!isDesktop && 12}>
          <CardPanel
            heading={t('Pending Maintenances')}
            subHeading={`${t('Total')} ${totalPendingMaintenances}`}
            cardItem={
              <>
                <Grid container>
                  {checkedbuildings && (
                    <TableGREYERP
                      table="dashboardmaintenancesdata"
                      firstDate={firstDate && firstDate}
                      lastDate={lastDate && lastDate}
                      interval={checkedbuildings}
                      name="Pending Maintenances"
                      setItem={setTotalPendingMaintenances}
                      reTrigger={generateTrigger}
                    />
                  )}
                </Grid>
              </>
            }
          />
        </Grid>
        <Grid item md={4} xs={!isDesktop && 12}>
          <CardPanel
            heading={t('Unit Occupancy')}
            subHeading={`${t('Till Date')} ${
              [
                { Type: 'Occupied', count: buildingsUnit - totalEmptyUnits },
                { Type: 'Vacant', count: totalEmptyUnits },
              ]
                .map((i) => i.count)
                .reduce((a, b) => a + b, 0) ?? 0
            }`}
            cardItem={
              <>
                <Grid item md={12}>
                  {/* dashboardunitoccupancy */}
                  <ChartGREYERP
                    name={'Unit Occupancy'}
                    data={[
                      { Type: 'Occupied', count: buildingsUnit - totalEmptyUnits },
                      { Type: 'Vacant', count: totalEmptyUnits },
                    ]}
                    type="donut"
                  />
                </Grid>
              </>
            }
          />
        </Grid>
        <Grid item md={8} xs={!isDesktop && 12}>
          <CardPanel
            heading={t('Contract Expiry')}
            subHeading={`${t('Total')} ${totalContractExpiring}`}
            cardItem={
              <>
                {checkedbuildings && (
                  <TableGREYERP
                    table="dashboardcontractsexpiring"
                    firstDate={firstDate && firstDate}
                    lastDate={lastDate && lastDate}
                    interval={checkedbuildings}
                    name="Contract Expiring"
                    setItem={setTotalContractExpiring}
                    reTrigger={generateTrigger}
                  />
                )}
              </>
            }
          />
        </Grid>

        {/* <Grid item md={4} xs={!isDesktop && 12}>
          <CardPanel
            heading="Maintenances"
            subHeading={`${t('Total')} ${contractMaintenances?.length ?? 0} (${
              contractMaintenances?.length > 0
                ? formatdecimals(
                    contractMaintenances
                      .map((i) => i.Total)
                      .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
                  )
                : 0
            })`}
            cardItem={
              <>
                <Grid item md={12}>
                  <ChartGREYERP
                    type="line"
                    data={contractMaintenances}
                    name="Contract Maintenances"
                  />
                </Grid>
              </>
            }
          />
        </Grid> */}

        <Grid item md={4} xs={!isDesktop && 12}>
          <CardPanel
            heading={t('Contract Cancellation')}
            subHeading={`${t('Total')} ${contractCancellation[0]?.Total ?? 0}`}
            cardItem={
              <>
                {/* dashboardunittypes */}
                {contractCancellation.length > 0 && (
                  <Grid item md={12}>
                    <ChartGREYERP
                      type="line"
                      data={contractCancellation}
                      name="Contract Cancellation"
                    />
                  </Grid>
                )}
              </>
            }
          />
        </Grid>
        <Grid item md={8} xs={!isDesktop && 12}>
          <CardPanel
            heading={t('Vacant Units')}
            subHeading={`${t('Total')} ${totalEmptyUnits}`}
            cardItem={
              <>
                {checkedbuildings && (
                  <TableGREYERP
                    table="dashboardemptyunits"
                    firstDate={firstDate && firstDate}
                    lastDate={lastDate && lastDate}
                    interval={checkedbuildings}
                    name="Empty Units"
                    setItem={setTotalEmptyUnits}
                    reTrigger={generateTrigger}
                  />
                )}
              </>
            }
          />
        </Grid>

        <Grid item md={12} xs={!isDesktop && 12}>
          <CardPanel
            heading={t('Rent Revenue / Expense')}
            subHeading={`${t('Total')} ${
              rentRevenue.length > 0
                ? formatdecimals(
                    rentRevenue
                      .map((i) => i.Rent)
                      .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
                  )
                : 0
            } / ${
              rentRevenue.length > 0
                ? formatdecimals(
                    rentRevenue
                      .map((i) => i.Expense)
                      .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
                  )
                : 0
            }`}
            cardItem={
              <>
                <Grid item md={12}>
                  {/* dashboardrentrevenue */}
                  {rentRevenue.length > 0 && (
                    <ChartGREYERP type="bar" data={rentRevenue} name="Rent Revenue" type2={true} />
                  )}
                </Grid>
              </>
            }
          />
        </Grid>
      </Grid>
    </>
  );
}
