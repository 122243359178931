import { CardPanel } from 'src/controls/cardpanel';
import { DropDownGREYERP } from 'src/controls/dropdown';
import { DropDownENUM } from 'src/controls/dropdownenum';
import { TextBoxGREYERP } from 'src/controls/textbox';
import { DepreciationMethod } from 'src/data/data';

export default function AssetType() {
  
  return (
    <>
      <CardPanel
        heading="Type Details"
        cardItem={
          <>
              <TextBoxGREYERP name="Name" placeholder="Plant & Machinery" required={true} />
              <TextBoxGREYERP label="Reference" name="Reference" />
              <DropDownENUM items={DepreciationMethod} name="Method" required={true} />
              <TextBoxGREYERP name="Duration" placeholder="60" type="number" required={true} sufix='Months' />
              <TextBoxGREYERP  placeholder="Model Description" name="Description" span={2} multiline={true} />
            <TextBoxGREYERP  placeholder="Internal Notes" multiline={true}  span={2} name="Comments" />
          </>
        }
      />
      <CardPanel
        heading="Accounting Details"
        cardItem={
          <>
             <DropDownGREYERP items={`expenseaccounts/name`}  name="DepreciationAccount" required={true}/>
             <DropDownGREYERP items={`expenseaccounts/name`}  name="ExpenseAccount" required={true}/>

          </>
        }
      />
    </>
  );
}
