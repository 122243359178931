import { useContext, useEffect, useState } from 'react';
import { CardPanel } from 'src/controls/cardpanel';
import { DropDownGREYERP } from 'src/controls/dropdown';
import { TextBoxGREYERP } from 'src/controls/textbox';
import { ItemsComponent } from 'src/controls/items/ItemsComponent';
import Picker from 'src/controls/picker';
import { FormContext } from 'src/layouts/dashboard/DashboardLayout';
import { GetAndSetList, GetAndSetValue, GetVoucherNumber, HandleOnChangeValue, getClientName } from 'src/data/scripts';
import { useQuery } from '@tanstack/react-query';
import { useFormData } from 'src/hooks/useQueryHooks';
import { useLocation } from 'react-router';

export default function ProductionPlan() {
let formID = window.location.pathname?.split('/')[2]?.replaceAll('-', '');
let ID = window.location.pathname?.split('/')[3]?.replaceAll('-', '') ?? popupEditId;
const {setFormData:setObject, formData:object} = useFormData();
const {data:objectEdit}= useQuery({ queryKey:[formID, ID], select:(res)=>res[0], enabled: (Boolean(formID) && !!ID) })

const {  setExistingItemsData, setFormObject,   formObject } = useContext(FormContext);
const { Series = '', Number = '',  Unit,Item,Source } = object || {};
const { Series: EditSeries = '', Number: EditNumber = '' } = objectEdit || {};
const { SourceList } = formObject || {};
const { state } = useLocation();

useEffect(() => {
  if (state) {
    HandleOnChangeValue(
      {
        ...state,
      },
      setObject
    );
  }
  // if (objectEdit && Object.keys(objectEdit).length > 0) {
  //   GetAndSetList(
  //     `getItems/productionplansourcelist/name/${localStorage.getItem('username')}/${localStorage.getItem('client')}/${ objectEdit['DocumentTo'] }/${window.location.pathname.split('/')[3]}`, setFormObject, 'SourceList'
  //   )
  // }

}, [state]);

useEffect(() => {
  if (isNaN(window.location.pathname.split('/')[3])) {
    GetVoucherNumber(getClientName(), setObject)
  }
}, []);



  return (
    <>

<div id="divFields" className="divFields">
      <CardPanel
        heading='Document Details'
        cardItem={
          <>
            <Picker type='date' name="Date" focus={true} date={new Date()} required={true}></Picker>
            
            <DropDownGREYERP span={2} items={`opensalesorders/name`} name="Source" label="Source Document" postBackitems={SourceList} postBackSelectedValue={Source} onChange={(e, selectedValue) =>
                HandleOnChangeValue({ Source: selectedValue?.ID }, setObject, [
                  GetAndSetList(
                    `getCustomPostBackItem/${'productionplanitems'}/${localStorage.getItem('username')}/${localStorage.getItem( 'client' )}/${parseInt(selectedValue?.ID)}`,
                    setExistingItemsData,
                    null
                  ),
                ])
              }
            />
            <TextBoxGREYERP defaultValue={Series ? Series + Number : EditSeries + EditNumber} disabled required={true} name="Number" placeholder="Number" />
            <TextBoxGREYERP name="Reference" placeholder="Reference" />
            <TextBoxGREYERP name="Description" multiline={true} placeholder="Description" span={2} />
            <TextBoxGREYERP name="Comments" multiline={true} placeholder="Internal Notes" span={2} />
          </>
        }
      />
    </div>
    <div id="divItems" className="divItems">
        <ItemsComponent
          heading={'Items To Manufacture'}
          formType={'saleable'}
          tablename={'productionplandetails'}
          disableCalculation={true}
          disableQuantity={true}
          columnsToShow={{
            Item: 0,
            BillOfMaterial: 0,
            Quantity: 1,
            Warehouse: 0,
            StartDate: '',
          }}
          columnsToAppend={{
            Item: 0,
            BillOfMaterial: 0,
            Quantity: 1,
            Warehouse: 0,
            StartDate: '',
          }}
        />
      </div>
   

       
    </>
  );
}
