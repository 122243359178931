import PropTypes from 'prop-types';
import { useContext, useEffect, useRef, useState } from 'react';
import { Draggable } from '@hello-pangea/dnd';
// @mui
import {
  Paper,
  Typography,
  Box,
  Checkbox,
  Tooltip,
  IconButton,
  ClickAwayListener,
  InputBase,
} from '@mui/material';
// components
import Image from '../../../components/image';
import Iconify from '../../../components/iconify';

import { FormContext } from 'src/layouts/dashboard/DashboardLayout';
import { Stack } from '@mui/system';
import { PRIORITIZES_OPTIONS } from './details/KanbanDetailsPrioritizes';
import { mDateTime } from 'src/utils/formatTime';
import SubjectIcon from '@mui/icons-material/Subject';
// ----------------------------------------------------------------------

KanbanTaskCard.propTypes = {
  card: PropTypes.object,
  index: PropTypes.number,
  onDeleteTask: PropTypes.func,
};

export default function KanbanTaskCard({
  card,
  onDeleteTask,
  index,
  setRefresh,
  setCardsState,
  cardsState,
}) {
  const { name, attachments } = card;
  useEffect(() => {
    setNameEdit(name);
  }, [name]);

  const inputRef = useRef();
  const [completed, setCompleted] = useState(card.completed);

  const [openDetails, setOpenDetails] = useState(false);

  const [openEditInput, setOpenEditInput] = useState(false);
  const [nameEdit, setNameEdit] = useState(name);

  const [color, setColor] = useState('#FFFFF');

  const {
    setOpenDrawer,
    setPopupEditId,
    projectTasks,
    setOpenTaskPopup,
    setProjectTasks,
    setTaskData,
  } = useContext(FormContext);

  const handleOpenDetails = () => {
    if (window.location.pathname.split('/')[2].toUpperCase() == 'TASKS') {
      setOpenDrawer(true);
      setPopupEditId(card.id);
    } else {
      setOpenTaskPopup(true);
      setPopupEditId(card);
    }

    //setOpenDetails(true);
  };

  const handleCloseDetails = () => {
    setOpenDetails(false);
  };

  const handleChangeComplete = (event) => {
    setCompleted(event.target.checked);
  };

  const handleClickAddTask = () => {
    setOpenEditInput(false);
  };

  const handleKeyUpAddTask = (event) => {
    event.key === 'Enter' && event.preventDefault();
    if (event.key === 'Enter') {
      if (window.location.pathname.split('/')[2].toUpperCase() == 'PROJECTS') {
        let cardState = { ...cardsState };

        let newTaskName = {};
        Object.values(cardState).forEach((item) => {
          if (item.id == card.id) {
            item.name = nameEdit;
            newTaskName = item;
          }
        });
        setCardsState((prev) => {
          return { ...prev, [card.id]: newTaskName };
        });
        setOpenEditInput(false);
      } else {
        HttpRequest(`tasks/${card.id}/${localStorage.getItem('username')}`, 'PUT', {
          name: nameEdit,
        })
          .then((response) => response)
          .then((id) => {
            setTaskData((prev) => {
              let cards = prev.board.cards;

              for (const key in cards) {
                if (key == id) {
                  cards[key].name = nameEdit;
                }
              }

              return {
                ...prev,
                ['board']: {
                  ['cards']: cards,
                  ['columnOrder']: prev.board.columnOrder,
                  ['columns']: prev.board.columns,
                },
              };
            });
            setOpenEditInput(false);
          });
      }
    }
  };

  return (
    <>
      <Draggable draggableId={`${card.id}`} index={index}>
        {(provided) => (
          <Paper
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
            sx={{
              width: '100%',
              borderRadius: 1,
              overflow: 'hidden',
              position: 'relative',
              boxShadow: (theme) => theme.customShadows.z1,
              '&:hover': {
                boxShadow: (theme) => theme.customShadows.z20,
                '& svg': {
                  color: '#C4CDD5',
                },
              },
            }}
          >
            {openEditInput ? (
              <Stack direction={'row'} justifyContent="space-between" alignItems={'center'}>
                <ClickAwayListener onClickAway={handleClickAddTask}>
                  <div>
                    <Paper variant="outlined">
                      <InputBase
                        multiline
                        fullWidth
                        placeholder="Task name"
                        value={nameEdit}
                        inputRef={inputRef}
                        onChange={(event) => setNameEdit(event.target.value)}
                        onKeyDown={handleKeyUpAddTask}
                        sx={{
                          fontSize: '0.875rem',
                          fontWeight: '400 !important',
                          whiteSpace: 'break-spaces !important',
                          fontFamily: 'Public Sans,sans-serif !important',
                          pl: 1,
                          width: '95%',
                          lineHeight: '25px !important',
                        }}
                      />
                    </Paper>
                  </div>
                </ClickAwayListener>
              </Stack>
            ) : (
              <Stack direction={'row'} justifyContent="space-between" alignItems={'center'}>
                <Box sx={{ cursor: 'pointer', flex: 1 }} onClick={handleOpenDetails}>
                  {!!attachments.length && (
                    <Image
                      disabledEffect
                      alt={attachments[0]}
                      src={attachments[0]}
                      ratio="4/3"
                      sx={{
                        transition: (theme) =>
                          theme.transitions.create('opacity', {
                            duration: theme.transitions.duration.shortest,
                          }),
                        ...(completed && {
                          opacity: 0.48,
                        }),
                      }}
                    />
                  )}
                  <Stack
                    sx={{
                      borderLeft: (theme) =>
                        `solid 3px ${
                          card?.priority?.toUpperCase() == 'URGENT'
                            ? 'black'
                            : theme.palette[
                                PRIORITIZES_OPTIONS.filter(
                                  (i) => i.value?.toUpperCase() == card.priority?.toUpperCase()
                                )[0]?.color
                              ]?.main
                        }`,
                    }}
                  >
                    <Typography
                      noWrap
                      variant="subtitle2"
                      sx={{
                        pl: 2,
                        pb: 2,
                        pt: 2,
                        lineHeight: '25px',
                        fontWeight: '400 !important',
                        whiteSpace: 'break-spaces !important',
                        transition: (theme) =>
                          theme.transitions.create('opacity', {
                            duration: theme.transitions.duration.shortest,
                          }),
                        // ...(completed && {
                        //   opacity: 0.48,
                        // }),
                      }}
                    >
                      {nameEdit}
                    </Typography>
                    {card?.due[0] &&
                      card?.due[0] != '1900-01-01T00:00:00.000Z' &&
                      card?.description && (
                        <Stack direction={'row'} sx={{ pl: 2, pb: 2 }}>
                          {card?.description && <SubjectIcon sx={{ color: 'text.secondary' }} />}
                          {card?.due[0] && card?.due[0] != '1900-01-01T00:00:00.000Z' && (
                            <Stack alignItems="center" direction={'row'} sx={{ ml: 1 }}>
                              <Iconify
                                icon="ic:baseline-access-time"
                                width="13"
                                sx={{ color: 'text.secondary', mb: '2px' }}
                              />
                              <Typography
                                variant="caption"
                                sx={{ color: 'text.secondary', ml: '3px' }}
                              >
                                {mDateTime(card.due[0])}
                              </Typography>
                            </Stack>
                          )}
                        </Stack>
                      )}
                  </Stack>
                </Box>
                {/* sx={{visibility:'hidden',':hover':{
                      visibility:'visible',
                    }}} */}

                <IconButton
                  sx={{ my: '5px', mr: '3px', width: '40px', height: '40px' }}
                  onClick={() => {
                    setOpenEditInput(true);
                    setTimeout(() => {
                      inputRef?.current && inputRef.current.select();
                    }, 100);
                  }}
                >
                  <Iconify icon="mdi:pencil" sx={{ color: 'white' }} />
                </IconButton>
              </Stack>
            )}

            {/* <Checkbox
              disableRipple
              checked={completed}
              icon={<Iconify icon="eva:radio-button-off-outline" />}
              checkedIcon={<Iconify icon="eva:checkmark-circle-2-outline" />}
              onChange={handleChangeComplete}
              sx={{ position: 'absolute', bottom: 16, left: 8 }}
            /> */}
          </Paper>
        )}
      </Draggable>

      {/* <KanbanDetails
        task={card}
        openDetails={openDetails}
        onCloseDetails={handleCloseDetails}
        onDeleteTask={() => onDeleteTask(card.id)}
      /> */}
    </>
  );
}
