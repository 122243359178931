import React from 'react';
import { alpha, styled } from '@mui/material/styles';
import {
  Box,
  Card,
  Grid,
  Table,
  Divider,
  TableRow,
  TableBody,
  TableHead,
  TableCell,
  Typography,
  TableContainer,
  Button,
  Popover,
  Stack,
  Tooltip,
  IconButton,
  Popper,
  Grow,
  ClickAwayListener,
  MenuList,
  MenuItem,
  ListItemIcon,
  Menu,
  Checkbox,
} from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { useContext, useEffect, useRef, useState } from 'react';

import { FormContext } from 'src/layouts/dashboard/DashboardLayout';
import Iconify from 'src/components/iconify/Iconify';
import {
  toTitleCase,
  amountInWords,
  getBase64,
  formatdecimals,
  HttpRequest,
} from 'src/data/scripts';

import { QRCodeSVG } from 'qrcode.react';

import MoreVertIcon from '@mui/icons-material/MoreVert';

import { useReactToPrint } from 'react-to-print';
import { CheckBoxGREYERP } from 'src/controls/checkbox';
import cryptoJs from 'crypto-js';
import { secret } from 'src/data/data';

function FormPrint() {
  const [data, setData] = useState({});
  const [datasection, setDataSection] = useState([]);
  const [invoiceItems, setInvoiceItems] = useState();
  const [leadData, setleadData] = useState({});
  const [columns, setColumns] = useState();
  const [pageHeader, setPageHeader] = useState();
  const [pageFooter, setPageFooter] = useState();
  const [pageHeading, setPageHeading] = useState();
  const [pageHeadingArabic, setPageHeadingArabic] = useState();
  const [pageColor, setPageColor] = useState();
  const [pageFont, setPageFont] = useState();
  const [pageFontSize, setpageFontSize] = useState();
  //////Document Setting //////////////
  const [Reference, setReference] = useState(false);
  const [DueDate, setDueDate] = useState(false);
  const [QuotationNumber, setQuotationNumber] = useState(false);
  const [QuotationDate, setQuotationDate] = useState(false);
  const [PurchaseOrderNumber, setPurchaseOrderNumber] = useState(false);
  const [PurchaseOrderDate, setPurchaseOrderDate] = useState(false);
  const [DeliveryNoteNumber, setDeliveryNoteNumber] = useState(false);
  const [DeliveryNoteDate, setDeliveryNoteDate] = useState(false);
  const [ShowQRCode, setShowQRCode] = useState(false);
  const [QRCodeValue, setQRCodeValue] = useState('');
  const [ItemReference, setItemReference] = useState(false);
  const [Unit, setUnit] = useState(false);
  const [Quantity, setQuantity] = useState(false);
  const [TotalQuantity, setTotalQuantity] = useState(false);
  const [LineTotal, setLineTotal] = useState(false);
  const [TotalItems, setTotalItems] = useState(false);
  const [checkedColumn, setCheckedColumn] = useState();
  const [comanyVATIN, setComanyVATIN] = useState();
  const [pageItems, setPageItems] = useState([]);
  const [pageTitle, setPageTitle] = useState('');
  const [decryptedString, setDecryptedString] = useState('');

  const [invoicePdf, setInvoicePdf] = useState({});

  useEffect(() => {
    // const decryptedStringNew = decrypt(`#${window.location.pathname.split("/")[2]}#`, secret);
    // setDecryptedString(decryptedStringNew)
    let decryptedString = window.location.pathname.split('/')[2].replaceAll('@', '/');
    const bytes = cryptoJs.AES.decrypt(`${decryptedString}`, secret);
    const decryptedStringNew = JSON.parse(bytes.toString(cryptoJs.enc.Utf8));
    setDecryptedString(decryptedStringNew);
  }, []);

  const StyledRowResult = styled(TableRow)(({ theme }) => ({
    '& td': {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
  }));
  //   const { object, setObject } = useContext(FormContext);
  //   const { openPrint, setOpenPrint, recordId, setRecordId } = useContext(FormContext);

  const handleVisibleEvent = (e) => {
    setObject({ ...object, [e.target.name]: e.target.checked });
    //setDueDate(e.target.checked);
    if (e.target.name == 'Reference') {
      setReference(e.target.checked);
    } else if (e.target.name == 'DueDate') {
      setDueDate(e.target.checked);
    } else if (e.target.name == 'QuotationNumber') {
      setQuotationNumber(e.target.checked);
    } else if (e.target.name == 'QuotationDate') {
      setQuotationDate(e.target.checked);
    } else if (e.target.name == 'PurchaseOrderNumber') {
      setPurchaseOrderNumber(e.target.checked);
    } else if (e.target.name == 'PurchaseOrderDate') {
      setPurchaseOrderDate(e.target.checked);
    } else if (e.target.name == 'DeliveryNoteNumber') {
      setDeliveryNoteNumber(e.target.checked);
    } else if (e.target.name == 'DeliveryNoteDate') {
      setDeliveryNoteDate(e.target.checked);
    } else if (e.target.name == 'ShowQRCode') {
      setShowQRCode(e.target.checked);
    }
    // else if(e.target.name =="ItemReference")
    // {
    //   setItemReference(e.target.checked);
    // }
    // else if(e.target.name =="Unit")
    // {
    //   setUnit(e.target.checked);
    // }
    else if (e.target.name == 'Quantity') {
      setQuantity(e.target.checked);
    } else if (e.target.name == 'TotalQuantity') {
      setTotalQuantity(e.target.checked);
    } else if (e.target.name == 'LineTotal') {
      setLineTotal(e.target.checked);
    } else if (e.target.name == 'TotalItems') {
      setTotalItems(e.target.checked);
    }

    onChangeDocumentColumns();
  };
  function CheckColumn() {
    let Column = '';
    if (Reference == true) {
      Column = Column + 'ShowReference,';
    }
    if (DueDate == true) {
      Column = Column + 'ShowDueDate,';
    }
    if (QuotationNumber == true) {
      Column = Column + 'ShowQuotationNumber,';
    }
    if (QuotationDate == true) {
      Column = Column + 'ShowQuotationDate,';
    }
    if (PurchaseOrderNumber == true) {
      Column = Column + 'ShowPurchaseOrderNumber,';
    }
    if (PurchaseOrderDate == true) {
      Column = Column + 'ShowPurchaseOrderDate,';
    }
    if (DeliveryNoteNumber == true) {
      Column = Column + 'ShowDeliveryNoteNumber,';
    }
    if (DeliveryNoteDate == true) {
      Column = Column + 'ShowDeliveryNoteDate,';
    }
    if (ShowQRCode == true) {
      Column = Column + 'ShowQRCode,';
    }
    if (Quantity == true) {
      Column = Column + 'Quantity,';
    }
    if (TotalQuantity == true) {
      Column = Column + 'TotalQuantity,';
    }
    if (LineTotal == true) {
      Column = Column + 'LineTotal,';
    }
    if (TotalItems == true) {
      Column = Column + 'TotalItems,';
    }

    setCheckedColumn(Column);
  }
  let decimalArray = ['Price', 'Tax', 'Total'];
  async function onChangeDocumentColumns() {
    CheckColumn();
    await HttpRequest(
      `deletedocumentsettings/${form}/${localStorage.getItem('username')}`,
      'DELETE'
    );

    if (checkedColumn != '') {
      var fields = {};
      fields['Columns'] = checkedColumn;
      fields['Module'] = `${window.location.pathname.split('/')[2].slice(0, -1)}`;
      fields['Username'] = `${localStorage.getItem('username')}`;

      await HttpRequest(
        `documentsettings/${localStorage.getItem('client')}/${localStorage.getItem('username')}`,
        'POST',
        JSON.stringify(fields)
      )
        .then((response) => response)
        .then((json) => {
          // console.log(json[0]);
        });
    }
  }

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  let linkPage, form, formPrint, user, client, recordIdUser;
  linkPage = window.location.pathname.split('/')[1].toUpperCase() == 'LINK';
  let decryptArray = decryptedString?.split('/');
  formPrint = decryptArray[0].slice(0, -1);
  form = decryptArray[0];
  user = decryptArray[1];
  client = decryptArray[2];
  recordIdUser = decryptArray[3];
  useEffect(() => {
    // let form = window.location.pathname.split('/')[2].replaceAll('-', '');
    //let formPrint=''
    // let user = localStorage.getItem('username');
    // let client = localStorage.getItem('client');
    // let recordIdUser = 0;
    if (linkPage && decryptedString) {
      //   let decryptArray=decryptedString?.split("/")
      //  formPrint = decryptArray[0].slice(0,-1);
      //   form = decryptArray[0];
      //   user = decryptArray[1];
      //   client = decryptArray[2];
      //   recordIdUser = decryptArray[3];

      let Name = '';
      let VATIN = '';

      //  let a = getBase64("Huda Abdel Fattah Mohammad Debwan EST.","310146449800003","2023-03-12 17:19:23","6325.00","825.00");
      //  console.log(a);
      // setQRCodeValue(a);

      HttpRequest(`getItems/clientdetails/name/${user}/${localStorage.getItem('client')}`)
        .then((response) => response)
        .then((json) => {
          // console.log(json[0]);
          Name = json.Name;
          VATIN = json.VATIN;
          setComanyVATIN(VATIN);
        });

      HttpRequest(`getRecordById/${form}/${recordIdUser}`)
        .then((response) => response)
        .then((json) => {
          setData(json[0]);
          setPageTitle(json[0].Series + json[0].Number);
          const invoicedate = new Date(json[0].Date);
          const formattedDate = invoicedate.toLocaleString('en-US', {
            timeZone: 'UTC',
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
          });

          let a = getBase64(Name, VATIN, formattedDate, json[0].GrandTotal, json[0].TotalTax);
          setQRCodeValue(a);

          HttpRequest(`getRecordById/leads/${json[0].Lead}`)
            .then((response) => response)
            .then((json) => {
              setleadData(json[0]);
              setInvoicePdf((prev) => {
                return {
                  ...prev,
                  ['Address']: json[0].Address,
                  ['ContactPerson']: json[0].FirstName,
                  ['Phone']: json[0].Phone,
                  ['VATIN']: json[0].VATIN,
                  ['TaxNumber']: json[0].TaxNumber,
                  ['Name']: json[0].Company,
                };
              });
            });
        });

      HttpRequest(`getRecordById/${formPrint}print/${recordIdUser}`)
        .then((response) => response)
        .then((json) => {
          //console.log(json);
          setInvoiceItems(json);
          let columns = [];
          columns = json?.length > 0 && Object.keys(json[0]);
          let itemIndex = columns.indexOf('Description');
          columns[itemIndex] = `Item الوصف`;
          let taxIndex = columns.indexOf('Tax');
          columns[taxIndex] = `VAT ضريبة`;
          let totalIndex = columns.indexOf('Total');
          columns[totalIndex] = `Amount المبلغ`;
          let qtyIndex = columns.indexOf('Quantity');
          columns[qtyIndex] = `Qty الكمية`;
          let PriceIndex = columns.indexOf('Price');
          columns[PriceIndex] = `Price معدل`;
          let RefIndex = columns.indexOf('Reference');
          columns[RefIndex] = `Reference المرجعي`;
          delete columns.Discount;
          delete columns.TaxPercentage;
          delete columns.Unit;
          let discIndex = columns.indexOf('Discount');
          let taxPercentageIndex = columns.indexOf('TaxPercentage');
          let discPerIndex = columns.indexOf('DiscountPercentage');
          columns.splice(discIndex, 1);
          columns.splice(3, 1);
          columns.splice(4, 1);
          columns.splice(5, 1);

          setColumns(columns);
          // for (let key in json[0]) {
          //   let result = displaycolumns.includes(key);
          //   if (result == true) {
          //     if (key.toUpperCase() != 'ID' && key.toUpperCase() != 'ITEM') {
          //       if (key.toUpperCase() === 'DESCRIPTION') columns.push('Item');
          //       else if (key.toUpperCase() === 'DISCOUNT') columns.push('Disc');
          //       else if (key.toUpperCase() === 'DISCOUNTPERCENTAGE') columns.push('Disc %');
          //       else if (key.toUpperCase() === 'QUANTITY') columns.push('Qty');
          //       else columns.push(key);
          //     }
          //   }
          //   setColumns(columns);
          // }
        });

      HttpRequest(`getRecordById/documentlayoutsclientid/${client}`)
        .then((response) => response)
        .then((json) => {
          setPageHeader(json.Header);
          setPageFooter(json.Footer);
          setPageHeading(json.Heading);
          setPageHeadingArabic(json.Heading.split('<br>')[1]);
          setPageColor(json.color);
          setPageFont(json.font);
          setpageFontSize(json.fontSize);
          setInvoicePdf((prev) => {
            return {
              ...prev,
              ['pageHeader']:
                json.Header && json.Header == 'DOCUMENT NAME'
                  ? form?.toUpperCase()
                  : json.Header.toUpperCase(),
              ['pageFooter']: json.Footer,
            };
          });
        });

      HttpRequest(`getItems/DocumentSettings/${formPrint}/${user}/${client}`)
        .then((response) => response)
        .then((json) => {
          if (json.columns) {
            let Column = '';
            const columns = json?.columns?.split(',');
            for (var i = 0; i < columns.length; i++) {
              if (columns[i] == 'ShowReference') {
                setReference(true);
                Column = Column + 'ShowReference,';
              } else if (columns[i] == 'ShowDueDate') {
                setDueDate(true);
                Column = Column + 'ShowDueDate,';
              } else if (columns[i] == 'ShowQuotationNumber') {
                setQuotationNumber(true);
                Column = Column + 'ShowQuotationNumber,';
              } else if (columns[i] == 'ShowQuotationDate') {
                setQuotationDate(true);
                Column = Column + 'ShowQuotationDate,';
              } else if (columns[i] == 'ShowPurchaseOrderNumber') {
                setPurchaseOrderNumber(true);
                Column = Column + 'ShowPurchaseOrderNumber,';
              } else if (columns[i] == 'ShowPurchaseOrderDate') {
                setPurchaseOrderDate(true);
                Column = Column + 'ShowPurchaseOrderDate,';
              } else if (columns[i] == 'ShowDeliveryNoteNumber') {
                setDeliveryNoteNumber(true);
                Column = Column + 'ShowDeliveryNoteNumber,';
              } else if (columns[i] == 'ShowDeliveryNoteDate') {
                setDeliveryNoteDate(true);
                Column = Column + 'ShowDeliveryNoteDate,';
              } else if (columns[i].toUpperCase() == 'ShowQRCode'.toUpperCase()) {
                setShowQRCode(true);
                Column = Column + 'ShowQRCode,';
              } else if (columns[i] == 'ItemReference') {
                setItemReference(true);
                Column = Column + 'ItemReference,';
              } else if (columns[i] == 'Unit') {
                setUnit(true);
                Column = Column + 'Unit,';
              } else if (columns[i] == 'Quantity') {
                setQuantity(true);
                Column = Column + 'Quantity,';
              } else if (columns[i] == 'TotalQuantity') {
                setTotalQuantity(true);
                Column = Column + 'TotalQuantity,';
              } else if (columns[i] == 'LineTotal') {
                setLineTotal(true);
                Column = Column + 'LineTotal,';
              } else if (columns[i] == 'TotalItems') {
                setTotalItems(true);
                Column = Column + 'TotalItems,';
              }
            }

            setCheckedColumn(Column);
          }
        });

      HttpRequest(`getRecordById/${formPrint}sections/${recordIdUser}`)
        .then((response) => response)
        .then((json) => {
          setDataSection(json);
        });
    }
  }, [decryptedString]);

  useEffect(() => {
    if (
      // datasection?.length > 0 &&
      Object.keys(data)?.length > 0 &&
      Object.keys(leadData)?.length > 0
    ) {
      let invoiceInfo = (
        <Grid container>
          <Grid item xs={6} sx={{}}>
            <Typography
              sx={{
                color: pageColor,
                fontFamily: pageFont,
                fontSize: pageFontSize,
                fontWeight: 'bold',
              }}
              // sx={{ fontWeight: 'bold', color: '#0774B6', fontSize: '14px' }}
            >
              {toTitleCase(formPrint)} # {data && data.Series}
              {data && data.Number}
            </Typography>
            <Typography
              variant="span"
              sx={{ fontWeight: 'bold', fontFamily: pageFont, fontSize: pageFontSize }}
            >
              {toTitleCase(formPrint)} Date:
            </Typography>
            <Typography
              variant="span"
              sx={{ fontWeight: 'bold', fontFamily: pageFont, fontSize: pageFontSize }}
            >
              {data &&
                ` ${new Date(data.Date)
                  .toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: 'short',
                    day: '2-digit',
                  })
                  .replace(/\//g, '-')}`}
            </Typography>

            {DueDate && (
              <>
                <Typography variant="body1">
                  <Typography
                    variant="span"
                    sx={{ fontWeight: 'bold', fontFamily: pageFont, fontSize: pageFontSize }}
                  >
                    Due Date:
                  </Typography>
                  <Typography variant="span" sx={{ fontFamily: pageFont, fontSize: pageFontSize }}>
                    {data &&
                      new Date(data.DueDate)
                        .toLocaleDateString('en-US', {
                          year: 'numeric',
                          month: 'short',
                          day: '2-digit',
                        })
                        .replace(/\//g, '-')}
                  </Typography>
                </Typography>
              </>
            )}
            {DeliveryNoteNumber && (
              <Typography variant="body1">
                <Typography
                  variant="span"
                  sx={{ fontWeight: 'bold', fontFamily: pageFont, fontSize: pageFontSize }}
                >
                  Delivery Note #{' '}
                </Typography>
                <Typography
                  variant="span"
                  sx={{
                    color: pageColor,
                    fontWeight: '400',
                    fontFamily: pageFont,
                    fontSize: pageFontSize,
                  }}
                >
                  {data && data.Number}
                </Typography>{' '}
              </Typography>
            )}
            {Reference && (
              <Typography variant="body1">
                <Typography
                  variant="span"
                  sx={{ fontWeight: 'bold', fontFamily: pageFont, fontSize: pageFontSize }}
                >
                  Reference :{' '}
                </Typography>
                <Typography
                  variant="span"
                  sx={{
                    color: pageColor,
                    fontWeight: '400',
                    fontFamily: pageFont,
                    fontSize: pageFontSize,
                  }}
                >
                  {data && data.Reference}
                </Typography>{' '}
              </Typography>
            )}
            <Typography variant="body1">
              <Typography
                variant="span"
                sx={{ fontWeight: 'bold', fontFamily: pageFont, fontSize: pageFontSize }}
              >
                VATIN #{' '}
              </Typography>
              <Typography variant="span" sx={{ fontFamily: pageFont, fontSize: pageFontSize }}>
                {comanyVATIN}
              </Typography>
            </Typography>
          </Grid>

          <Grid item xs={6} sx={{ mb: 5 }}>
            <Typography
              variant="subtitle1"
              sx={{
                color: pageColor,
                fontWeight: 'bold',
                fontFamily: pageFont,
                fontSize: pageFontSize,
              }}
            >
              {toTitleCase(form)} To
            </Typography>

            <Typography variant="body1">
              <Typography
                variant="span"
                sx={{ fontWeight: 'bold', fontFamily: pageFont, fontSize: pageFontSize }}
              >
                Name:{' '}
              </Typography>
              <Typography
                variant="span"
                sx={{
                  // color: pageColor,
                  fontWeight: '400',
                  fontFamily: pageFont,
                  fontSize: pageFontSize,
                }}
              >
                {leadData && leadData.Company}
              </Typography>
            </Typography>
            {leadData && leadData.Address && (
              <Typography variant="body1">
                <Typography
                  variant="span"
                  sx={{ fontWeight: 'bold', fontFamily: pageFont, fontSize: pageFontSize }}
                >
                  Address:{' '}
                  <Typography
                    variant="span"
                    sx={{
                      // color: pageColor,
                      fontWeight: '400',
                      fontFamily: pageFont,
                      fontSize: pageFontSize,
                    }}
                  >
                    {leadData && leadData.Address}
                  </Typography>{' '}
                </Typography>
              </Typography>
            )}
            {leadData && leadData.FirstName && (
              <Typography variant="body1">
                <Typography
                  variant="span"
                  sx={{ fontWeight: 'bold', fontFamily: pageFont, fontSize: pageFontSize }}
                >
                  Contact Person:{' '}
                </Typography>
                <Typography
                  variant="span"
                  sx={{
                    // color: pageColor,
                    fontWeight: '400',
                    fontFamily: pageFont,
                    fontSize: pageFontSize,
                  }}
                >
                  {leadData && leadData.FirstName}
                </Typography>
              </Typography>
            )}
            {leadData && leadData.Phone && leadData.Phone != '0' && (
              <Typography variant="body1">
                <Typography
                  variant="span"
                  sx={{ fontWeight: 'bold', fontFamily: pageFont, fontSize: pageFontSize }}
                >
                  Phone:{' '}
                </Typography>
                <Typography
                  variant="span"
                  sx={{
                    // color: pageColor,
                    fontWeight: '400',
                    fontFamily: pageFont,
                    fontSize: pageFontSize,
                  }}
                >
                  {' '}
                  {leadData && leadData.Phone && leadData.Phone != '0' && leadData.Phone}
                </Typography>{' '}
              </Typography>
            )}
            {leadData && leadData.TaxNumber && (
              <Typography variant="body1">
                <Typography
                  variant="span"
                  sx={{ fontWeight: 'bold', fontFamily: pageFont, fontSize: pageFontSize }}
                >
                  VAT Number:{' '}
                </Typography>
                <Typography
                  variant="span"
                  sx={{
                    // color: pageColor,
                    fontWeight: '400',
                    fontFamily: pageFont,
                    fontSize: pageFontSize,
                  }}
                >
                  {leadData && leadData.TaxNumber}{' '}
                </Typography>{' '}
              </Typography>
            )}
            {PurchaseOrderNumber && data && data.PurchaseOrderNumber && (
              <>
                <Typography variant="body1">
                  <Typography
                    variant="span"
                    sx={{ fontWeight: 'bold', fontFamily: pageFont, fontSize: pageFontSize }}
                  >
                    Purchase Order #{' '}
                  </Typography>
                  <Typography
                    variant="span"
                    sx={{
                      // color: pageColor,
                      fontWeight: '400',
                      fontFamily: pageFont,
                      fontSize: pageFontSize,
                    }}
                  >
                    {data && data.PurchaseOrderNumber}
                  </Typography>
                </Typography>
              </>
            )}
            {PurchaseOrderDate &&
              data &&
              data.PurchaseOrderDate !== '1900-01-01T00:00:00.000Z' &&
              data.PurchaseOrderDate !== '' && (
                <Typography variant="body1">
                  <Typography
                    variant="span"
                    sx={{ fontWeight: 'bold', fontFamily: pageFont, fontSize: pageFontSize }}
                  >
                    Purchase Order Date{' '}
                  </Typography>
                  <Typography
                    variant="span"
                    sx={{
                      fontWeight: '400',
                      fontFamily: pageFont,
                      fontSize: pageFontSize,
                    }}
                  >
                    {data &&
                      data.PurchaseOrderDate !== '1900-01-01T00:00:00.000Z' &&
                      data.PurchaseOrderDate !== '' && (
                        <div>
                          {new Date(data.PurchaseOrderDate)
                            .toLocaleDateString('en-US', {
                              year: 'numeric',
                              month: 'short',
                              day: '2-digit',
                            })
                            .replace(/\//g, '-')}
                        </div>
                      )}
                  </Typography>
                </Typography>
              )}
          </Grid>
        </Grid>
      );

      let invoiceTable = (
        <TableContainer sx={{ overflow: 'unset' }}>
          {/* <Scrollbar> */}
          <Table sx={{ width: '100%' }}>
            <TableHead
              sx={{
                borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
                '& th': {
                  backgroundColor: pageColor,
                  color: 'white',
                  fontFamily: pageFont,
                  fontSize: pageFontSize,
                },
              }}
            >
              <TableRow>
                {columns &&
                  columns.map((row, index) => (
                    <TableCell
                      sx={{
                        fontFamily: pageFont,
                        fontSize: pageFontSize,
                        p: '5px 8px !important',
                      }}
                    >
                      <Typography variant="subtitle2">{row.split(' ')[0]}</Typography>
                      <Typography variant="subtitle2">{row.split(' ')[1]}</Typography>
                    </TableCell>
                  ))}
              </TableRow>
            </TableHead>

            {
              <TableBody>
                {invoiceItems &&
                  invoiceItems.map((row, index) => {
                    let rowArray = [];
                    let taxarray = [];
                    for (const cell in row) {
                      if (
                        cell.toUpperCase() == 'ID' ||
                        cell.toUpperCase() == 'ITEM' ||
                        cell.toUpperCase() == 'DISCOUNT' ||
                        cell.toUpperCase() == 'DISCOUNTPERCENTAGE' ||
                        cell.toUpperCase() == 'TAXPERCENTAGE' ||
                        cell.toUpperCase() == 'UNIT'
                      ) {
                      } else if (cell == 'Quantity') {
                        // let taxPercentage = (parseFloat(row[cell])/row['Total']) * 100;
                        rowArray.push(row[cell]);
                        taxarray.push(`${row['Unit']}`);
                      } else if (cell == 'Tax') {
                        // let taxPercentage = (parseFloat(row[cell])/row['Total']) * 100;
                        rowArray.push(formatdecimals(row[cell]));
                        taxarray.push(`${formatdecimals(row['TaxPercentage'])}%`);
                      } else if (decimalArray.includes(cell)) {
                        taxarray.push('');
                        rowArray.push(formatdecimals(row[cell]));
                      } else {
                        taxarray.push('');
                        rowArray.push(row[cell]);
                      }
                    }

                    return (
                      <TableRow
                        key={index}
                        sx={{
                          borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
                        }}
                      >
                        {rowArray.map((cell, index) => (
                          <TableCell
                            sx={{
                              fontFamily: pageFont,
                              fontSize: pageFontSize,
                              border: '1px solid rgb(224, 224, 224)',
                              p: '5px 8px !important',
                            }}
                          >
                            {cell}
                            <p style={{ margin: 0, padding: 0 }}>{taxarray[index]}</p>
                          </TableCell>
                        ))}
                      </TableRow>
                    );
                  })}

                <StyledRowResult>
                  <TableCell
                    sx={{ py: '0px !important', pt: '20px !important', pl: '0' }}
                    colSpan={columns && columns.length - 2}
                  >
                    {TotalQuantity && data && data.TotalQuantity && (
                      <>
                        {' '}
                        <Typography
                          variant="span"
                          sx={{
                            fontWeight: 'bold',
                            fontFamily: pageFont,
                            fontSize: pageFontSize,
                          }}
                        >
                          Total Quantity:{' '}
                          <Typography
                            variant="span"
                            sx={{
                              fontWeight: '600',
                              fontFamily: pageFont,
                              fontSize: pageFontSize,
                            }}
                          >
                            {data && data.TotalQuantity}
                          </Typography>
                        </Typography>
                      </>
                    )}
                  </TableCell>
                  <TableCell sx={{ py: '0px !important', pt: '20px !important' }} align="right">
                    <Typography
                      sx={{ fontWeight: 'bold', fontFamily: pageFont, fontSize: pageFontSize }}
                    >
                      Sub Total الإجمالي
                    </Typography>
                  </TableCell>

                  <TableCell
                    sx={{ py: '0px !important', pt: '20px !important' }}
                    align="right"
                    width={100}
                  >
                    <Typography
                      sx={{ fontWeight: '600', fontFamily: pageFont, fontSize: pageFontSize }}
                    >
                      {data && formatdecimals(data.SubTotal)}
                    </Typography>
                  </TableCell>
                </StyledRowResult>
                {data && data.TotalDiscount != '0' && (
                  <StyledRowResult>
                    <TableCell
                      sx={{ py: '0px !important', pl: '0' }}
                      colSpan={columns && columns.length - 2}
                    />

                    <TableCell sx={{ py: '0px !important', typography: 'body1' }} align="right">
                      <Typography
                        variant="body2"
                        sx={{ fontWeight: 'bold', fontFamily: pageFont, fontSize: pageFontSize }}
                      >
                        Total Discount الخصم
                      </Typography>
                    </TableCell>

                    <TableCell sx={{ py: '0px !important', typography: 'body1' }} align="right">
                      <Typography
                        variant="body2"
                        sx={{ fontWeight: '600', fontFamily: pageFont, fontSize: pageFontSize }}
                      >
                        {data && formatdecimals(data.TotalDiscount)}
                      </Typography>
                    </TableCell>
                  </StyledRowResult>
                )}
                {/* {data  && data.TotalTax !='0' && ( */}
                <StyledRowResult>
                  <TableCell
                    sx={{ py: '0px !important', pl: '0' }}
                    colSpan={columns && columns.length - 2}
                  />

                  <TableCell sx={{ py: '0px !important', typography: 'body1' }} align="right">
                    <Typography
                      variant="body2"
                      sx={{ fontWeight: 'bold', fontFamily: pageFont, fontSize: pageFontSize }}
                    >
                      Total VAT الضريبة
                    </Typography>
                  </TableCell>

                  <TableCell
                    sx={{ py: '0px !important', typography: 'body1' }}
                    align="right"
                    width={120}
                  >
                    <Typography
                      variant="body2"
                      sx={{ fontWeight: '600', fontFamily: pageFont, fontSize: pageFontSize }}
                    >
                      {data && formatdecimals(data.TotalTax)}
                    </Typography>
                  </TableCell>
                </StyledRowResult>
                {/* )} */}
                <StyledRowResult>
                  <TableCell
                    sx={{ py: '0px !important', pl: '0' }}
                    colSpan={columns && columns.length - 2}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        fontWeight: 'bold',
                        fontFamily: pageFont,
                        fontSize: pageFontSize,
                        pl: '0',
                      }}
                    >
                      In Words: {data && amountInWords(data.GrandTotal)} Only
                    </Typography>
                  </TableCell>

                  <TableCell sx={{ py: '0px !important', typography: 'h6' }} align="right">
                    <Typography
                      variant="body2"
                      sx={{
                        fontWeight: 'bold',
                        color: pageColor,
                        fontFamily: pageFont,
                        fontSize: pageFontSize,
                      }}
                    >
                      Net Amount المبلغ الصافي
                    </Typography>
                  </TableCell>

                  <TableCell
                    sx={{ py: '0px !important', typography: 'h6' }}
                    align="right"
                    width={100}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        fontWeight: '600',
                        color: pageColor,
                        fontFamily: pageFont,
                        fontSize: pageFontSize,
                      }}
                    >
                      {data && formatdecimals(data.GrandTotal)}
                    </Typography>
                  </TableCell>
                </StyledRowResult>
              </TableBody>
            }
          </Table>
          {/* </Scrollbar> */}
        </TableContainer>
      );

      let sections = datasection;
      let sectionsOrder = data.SectionsOrder;
      let itemsArray = [];
      sections?.sort((a, b) => (a.Id > b.Id ? 1 : -1));
      if (sectionsOrder) {
        sectionsOrder?.split(',').map((i) => {
          if (i?.toUpperCase().includes('FIELD') || i?.toUpperCase().includes('UPDATEPANEL')) {
            itemsArray.push(invoiceInfo);
          } else if (i?.toUpperCase().includes('ITEM')) {
            itemsArray.push(invoiceTable);
          } else if (i?.toUpperCase().includes('SECTION')) {
            let sec = (
              <div dangerouslySetInnerHTML={{ __html: sections[sections.length - 1].Content }} />
            );

            itemsArray.push(sec);
            sections.pop();
          }
        });
      } else {
        itemsArray.push(invoiceInfo);
        itemsArray.push(invoiceTable);
      }

      setPageItems(itemsArray);
    }
  }, [datasection, data, leadData]);

  return (
    <>
      <Card ref={componentRef} id="printablediv" sx={{ pt: 5, px: 5, width: '8.3in', mt: 3 }}>
        <Grid container alignItems={'center'}>
          {!linkPage && (
            <>
              <Grid sx={{ displayPrint: 'none' }} item>
                <IconButton
                  aria-label="more"
                  id="long-button"
                  aria-controls={open ? 'long-menu' : undefined}
                  aria-expanded={open ? 'true' : undefined}
                  aria-haspopup="true"
                  onClick={handleClick}
                >
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  id="long-menu"
                  MenuListProps={{
                    'aria-labelledby': 'long-button',
                  }}
                  anchorEl={anchorEl}
                  open={open}
                  sx={{ '& .MuiMenuItem-root': { p: '0px 10px' } }}
                  onClose={handleClose}
                  PaperProps={{
                    style: {
                      maxHeight: 100 * 4.5,
                      width: '20ch',
                    },
                  }}
                >
                  <MenuItem>
                    <CheckBoxGREYERP
                      name="Reference"
                      span="1.5"
                      checked={Reference}
                      onChange={handleVisibleEvent}
                    />
                  </MenuItem>
                  <MenuItem>
                    <CheckBoxGREYERP
                      name="DueDate"
                      label="Due Date"
                      span="1.5"
                      checked={DueDate}
                      onChange={handleVisibleEvent}
                    />
                  </MenuItem>
                  <MenuItem>
                    <CheckBoxGREYERP
                      name="QuotationNumber"
                      label="Quotation No."
                      span={2}
                      checked={QuotationNumber}
                      onChange={handleVisibleEvent}
                    />
                  </MenuItem>
                  <MenuItem>
                    <CheckBoxGREYERP
                      name="QuotationDate"
                      label="Quotation Date"
                      span={2}
                      checked={QuotationDate}
                      onChange={handleVisibleEvent}
                    />
                  </MenuItem>
                  <MenuItem>
                    <CheckBoxGREYERP
                      name="PurchaseOrderNumber"
                      label="PO No."
                      span={2}
                      checked={PurchaseOrderNumber}
                      onChange={handleVisibleEvent}
                    />
                  </MenuItem>
                  <MenuItem>
                    <CheckBoxGREYERP
                      name="PurchaseOrderDate"
                      label="PO Date"
                      span="1.5"
                      checked={PurchaseOrderDate}
                      onChange={handleVisibleEvent}
                    />
                  </MenuItem>
                  <MenuItem>
                    <CheckBoxGREYERP
                      name="DeliveryNoteNumber"
                      label="DN No."
                      span="1.5"
                      checked={DeliveryNoteNumber}
                      onChange={handleVisibleEvent}
                    />
                  </MenuItem>
                  <MenuItem>
                    <CheckBoxGREYERP
                      name="DeliveryNoteDate"
                      label="DN Date"
                      span="1.5"
                      checked={DeliveryNoteDate}
                      onChange={handleVisibleEvent}
                    />
                  </MenuItem>
                  <MenuItem>
                    <CheckBoxGREYERP
                      name="ShowQRCode"
                      label="QR Code"
                      span={2}
                      checked={ShowQRCode}
                      onChange={handleVisibleEvent}
                    />
                  </MenuItem>
                  <MenuItem>
                    <CheckBoxGREYERP
                      name="Quantity"
                      label="Quantity"
                      span={2}
                      checked={Quantity}
                      onChange={handleVisibleEvent}
                    />
                  </MenuItem>
                  <MenuItem>
                    <CheckBoxGREYERP
                      name="TotalQuantity"
                      label="Total Quantity"
                      span={2}
                      checked={TotalQuantity}
                      onChange={handleVisibleEvent}
                    />
                  </MenuItem>
                  <MenuItem>
                    <CheckBoxGREYERP
                      name="LineTotal"
                      label="Line Total"
                      span="1.5"
                      checked={LineTotal}
                      onChange={handleVisibleEvent}
                    />
                  </MenuItem>
                </Menu>
              </Grid>
            </>
          )}

          <Grid item sx={{ ml: 'auto', displayPrint: 'none' }}>
            {!linkPage && (
              <>
                <Tooltip title="Copy Link">
                  <IconButton onClick={(e) => setOpenPrint(false)}>
                    <Iconify icon="material-symbols:link-rounded" />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Share on Whatsapp">
                  <IconButton
                    sx={{
                      '& .MuiSvgIcon-root': {
                        width: '22px !important',
                      },
                    }}
                    onClick={(e) => setOpenPrint(false)}
                  >
                    <WhatsAppIcon color="#25d366 !important" />
                  </IconButton>
                </Tooltip>

                <Tooltip title="Close">
                  <IconButton onClick={(e) => setOpenPrint(false)}>
                    <Iconify icon="material-symbols:close" />
                  </IconButton>
                </Tooltip>
              </>
            )}
            <Tooltip title="Print">
              <IconButton onClick={handlePrint}>
                <Iconify icon="eva:printer-fill" />
              </IconButton>
            </Tooltip>
          </Grid>

          <Grid container sx={{ '& img': { width: '100%' } }}>
            <div
              className="ql-editor"
              style={{ width: '100%' }}
              dangerouslySetInnerHTML={{ __html: pageHeader }}
            />
          </Grid>
          <Grid container direction={'column'} justifyContent={'center'} alignItems={'center'}>
            <Typography variant="h4" sx={{ color: pageColor, fontFamily: pageFont }}>
              {data && data.Subject != '' ? (
                data.Subject
              ) : pageHeading && pageHeading == 'DOCUMENT NAME' ? (
                form?.toUpperCase()
              ) : (
                <div
                  className="ql-editor"
                  style={{ width: '100%' }}
                  dangerouslySetInnerHTML={{ __html: pageHeading }}
                />
              )}
            </Typography>

            {pageHeadingArabic && (
              <Typography variant="h4" sx={{ color: pageColor, fontFamily: pageFont }}>
                {pageHeadingArabic?.toUpperCase()}
              </Typography>
            )}
          </Grid>

          <Grid container sx={{ mt: 0 }} justifyContent={'flex-start'}>
            <Grid
              item
              xs={6}
              sm={3}
              sx={{
                '& svg': {
                  height: '70px',
                  width: '120px',
                },
              }}
            >
              {ShowQRCode && (
                <div style={{ background: 'white', mb: 4 }}>
                  <QRCodeSVG value={QRCodeValue} />
                </div>
              )}
            </Grid>
          </Grid>
        </Grid>

        {pageItems && pageItems.map((item) => item)}

        <Divider sx={{ mt: 5 }} />
        <Grid container sx={{ '& img': { width: '100%' } }}>
          <div
            className="ql-editor"
            style={{ width: '100%' }}
            dangerouslySetInnerHTML={{ __html: pageFooter }}
          />
        </Grid>
      </Card>
    </>
  );
}

export default FormPrint;

function PopoverDynamic({ child }) {
  return (
    <Popover
      sx={{ backgroundColor: alpha('#212B36', 0.4) }}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={openPrint}
    >
      {child}
    </Popover>
  );
}
