/* eslint-disable react/prop-types */
import styled from '@emotion/styled';
import {
  Autocomplete,
  autocompleteClasses,
  createFilterOptions,
  FormControl,
  Grid,
  MenuItem,
  Popper,
  Select,
  TextField,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { sectionForms } from 'src/data/data';
import { addMinutesToDate, updateCell, addDateToMinutes } from 'src/data/scripts';
import { useFormData } from 'src/hooks/useQueryHooks';
import useResponsive from 'src/hooks/useResponsive';
import { FormContext } from 'src/layouts/dashboard/DashboardLayout';

export function DropDownENUM({
  name,
  onChange,
  width,
  items,
  disabledItems,
  required,
  firstItem,
  selectedItemIndex,
  label,
  span,
  disabled,
  placeholder,
  postBackSelectedValue,
  firstItemSelect = true,
  enterEdit = true,
  focus = false,
  multiple = false,
}) {
  const {
    // object,
    // setObject,
    resetFields,
    popupEditId,
    // objectEdit
    setObjectEdit,
    setResetFields,
    openDrawer,
    editOnCell,
    tbldata,
    setTbldata,
    swappedSections,
  } = useContext(FormContext);
  const { t } = useTranslation();

  const [dropDownItems, setDropDownItems] = useState(items ?? []);
  const [defaultValue, setDefaultValue] = useState();
  const [selectedValue, setSelectedValue] = useState(null);
  const [focused, setFocused] = useState(focus);
  const [openDropdown, setOpenDropdown] = useState(false);
  const isDesktop = useResponsive('up', 'lg');

  let form = window.location.pathname?.split('/')[2]?.replaceAll('-', '');
  let ID = window.location.pathname?.split('/')[3]?.replaceAll('-', '') ?? popupEditId;

  const { setFormData: setObject, formData: object } = useFormData();

  const { data: objectEdit } = useQuery({
    queryKey: [form, ID],
    select: (res) => res[0],
    enabled: Boolean(form),
  });
  const registerDataChange = (e, newValue) => {
    if (newValue) {
      if (multiple) {
        let string = '';
        newValue.forEach((item) => {
          if (item) {
            string +=
              item instanceof String || typeof item === 'string' ? `${item},` : `${item?.ID},`;
          }
        });
        // setObject({
        //   ...object,
        //   [name]: string.slice(0, -1),
        // });
        setObject({ [name]: string.slice(0, -1) });
        setDefaultValue(newValue);
      } else if (editOnCell) {
        if (required && !newValue) {
          mainFormRef.current.reportValidity();
        } else {
          updateCell(name, newValue?.ID, popupEditId, setObjectEdit, tbldata, setTbldata);
        }
      } else {
        if (name == 'endDateTime') {
          let prevStartDate = object['startDateTime'] ?? objectEdit['startDateTime'];
          let now = addMinutesToDate(newValue.ID, prevStartDate);

          // setObject((prev) => {
          //   return { ...prev, ['endDateTime']: now?.toISOString() };
          // });
          setObject({ ['endDateTime']: now?.toISOString() });
        } else {
          setSelectedValue(newValue.ID);

          // setObject((prev) => {
          //   return { ...prev, [name]: newValue.ID };
          // });
          setObject({ [name]: newValue.ID });
        }
        setDefaultValue(newValue);
        setFocused(true);
        setOpenDropdown(false);
      }
    } else {
      setDefaultValue(newValue);
      // setObject((prev) => {
      //   return {
      //     ...prev,
      //     [name]: newValue,
      //   };
      // });
      setObject({ [name]: newValue });
    }
  };

  const registerOnBlur = (e, newValue) => {
    setFocused(false);
    setOpenDropdown(false);
  };

  useEffect(() => {
    if (resetFields) {
      setDefaultValue(null);
      setResetFields(false);
      setFocused(focus);
    }
  }, [resetFields]);

  useEffect(() => {
    if (objectEdit && objectEdit[name] && enterEdit) {
      // const objectPropertyName = Object.keys(objectEdit);
      // const valueArray = objectPropertyName.filter((item, index, object) => item == name);
      // const value = objectEdit[valueArray[0]];
      if (name == 'endDateTime') {
        let duration = addDateToMinutes(objectEdit['startDateTime'], objectEdit['endDateTime']);
        let item = items.filter((date) => date.ID == duration)[0];
        setDefaultValue(item);
        return;
      }
      if (objectEdit[name] && multiple) {
        let value = objectEdit[name];
        let defaultValueList = [];
        if (value) {
          let valueNew = value?.includes(',') ? value?.split(',') : [value];
          valueNew.forEach((val) => {
            let filteredObj = dropDownItems.filter((item) => item.ID == val);
            defaultValueList.push(filteredObj[0]);
          });
          defaultValueList.length > 0 && setDefaultValue(defaultValueList);
        } else {
          setDefaultValue([]);
        }
      } else {
        let defaultValue = dropDownItems.filter((item) => item.ID == objectEdit[name]);
        setDefaultValue(defaultValue[0]);
      }

      return;
    }

    if (firstItem) {
      const dropdownItem = dropDownItems;
      dropdownItem.splice(0, 0, { ID: firstItem.split(',')[0], Name: firstItem.split(',')[1] });
      setDropDownItems(dropdownItem);
      setDefaultValue({ ID: firstItem.split(',')[0], Name: firstItem.split(',')[1] });

      // setObject((prev) => {
      //   return { ...prev, [name]: firstItem.split(',')[0] };
      // });
      setObject({ [name]: firstItem.split(',')[0] });

      setSelectedValue(firstItem.split(',')[0]);
    } else {
      if (selectedItemIndex == 0 || selectedItemIndex) {
        // setObject((prev) => {
        //   return { ...prev, [name]: items[selectedItemIndex]['ID'] };
        // });
        setObject({ [name]: items[selectedItemIndex]['ID'] });

        setDefaultValue(items[selectedItemIndex]);
      } else {
        if (firstItemSelect && !multiple) {
          setDefaultValue(items[0]);
          if (window.location.pathname.split('/')[3]?.toUpperCase() == 'NEW' || openDrawer) {
            // setObject((prev) => {
            //   return { ...prev, [name]: items[0]['ID'] };
            // });
            setObject({ [name]: items[0]['ID'] });
          }
        }
      }
    }
  }, [name, objectEdit, resetFields]);

  useEffect(() => {
    if (postBackSelectedValue || postBackSelectedValue == 0) {
      let defaultValue;
      defaultValue = dropDownItems.filter((item) => item.ID == postBackSelectedValue);
      defaultValue && setDefaultValue(defaultValue[0]);
      setFocused(true);
      setOpenDropdown(false);
    }
  }, [postBackSelectedValue, dropDownItems]);

  useEffect(() => {
    if (
      sectionForms.includes(window.location.pathname.split('/')[2]?.toUpperCase()) &&
      dropDownItems.length > 0 &&
      swappedSections
    ) {
      setDefaultValue(dropDownItems.filter((i) => i.ID == object[name])[0]);
    }
  }, [dropDownItems]);

  const filterOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option) => option.Name,
  });

  const StyledPopper = styled(Popper)({
    [`& .${autocompleteClasses.listbox}`]: {},
    '& ul li': {
      fontSize: 'small',
    },
  });

  let selectFirstValOnNew = multiple ? [] : { ID: 0, Name: '' };

  return (
    //xs={12} sm={6} md={3}
    <Grid md={span ?? 1} xs={!isDesktop && 12} item>
      <FormControl>
        <Autocomplete
          size="small"
          onChange={onChange ? onChange : registerDataChange}
          defaultValue={defaultValue ? defaultValue : selectFirstValOnNew}
          selectedValue={defaultValue ? defaultValue.ID : selectFirstValOnNew.ID}
          value={defaultValue ? defaultValue : selectFirstValOnNew}
          multiple={multiple}
          placeholder={placeholder}
          //open={openDropdown}
          disabled={disabled}
          // ID={selectedValue ? selectedValue : postBackSelectedValue}
          filterOptions={filterOptions}
          getOptionLabel={(option) => option.Name}
          options={dropDownItems && dropDownItems}
          getOptionDisabled={(option) =>
            disabledItems && !!disabledItems.find((element) => element === option.Name)
          }
          //key={defaultValue}
          PopperComponent={StyledPopper}
          renderInput={(params) => (
            <TextField
              autoFocus={focus}
              placeholder={placeholder}
              focused={true}
              onFocus={(e) => {
                setFocused(true);
                setOpenDropdown(true);
              }}
              onKeyDown={(e) => {
                if (e.key == 'Enter') {
                  setOpenDropdown(true);
                } else if (e.key == 'Tab') {
                  setOpenDropdown(false);
                }
              }}
              fullWidth
              onBlur={registerOnBlur}
              color="secondary"
              name={name}
              required={required ?? false}
              size="small"
              margin="normal"
              {...params}
              label={label ? t(label) : t(name?.match(/[A-Z][a-z]+|[0-9]+/g)?.join(' '))}
            />
          )}
        ></Autocomplete>
      </FormControl>
    </Grid>
  );
}
