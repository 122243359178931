import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { FormContext } from 'src/layouts/dashboard/DashboardLayout';
import { Grid } from '@mui/material';
import { useFormData } from 'src/hooks/useQueryHooks';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

export function GroupedSelectAll({ data, name, label, width, field, required, focus }) {
  const { t } = useTranslation();
  const {
    // setObject,
    // object,
    setResetFields,
    popupEditId,
    resetFields,
  } = React.useContext(FormContext);

  let form = window.location.pathname?.split('/')[2]?.replaceAll('-', '');
  let ID = window.location.pathname?.split('/')[3]?.replaceAll('-', '') ?? popupEditId;

  const { setFormData: setObject, formData: object } = useFormData();

  const { data: objectEdit } = useQuery({
    queryKey: [form, ID],
    select: (res) => res[0],
    enabled: Boolean(form),
  });

  const [value, setValue] = React.useState('');
  const handleChange = (e) => {
    setValue(e.target.value);
    setObject({ [name]: e.target.value });
    // setObject({ ...object, [name]: e.target.value });
    if (field) {
      return field.onChange(e.target.value);
    }
  };

  const [menuItems, setmenuItems] = React.useState([]);

  React.useEffect(() => {
    GroupRecursive(data);
  }, [data]);

  // React.useEffect(() => {
  //   setValue('');
  //   setResetFields(false);
  // }, [resetFields]);

  function GroupRecursive(data) {
    return (
      <>
        {data &&
          data.map((item, index) => {
            let itemDepth = item.depth;
            let menuITEM = (
              <MenuItem
                sx={{
                  pl:
                    itemDepth == 0
                      ? 2
                      : itemDepth == 1
                      ? 3
                      : itemDepth == 2
                      ? 4
                      : itemDepth == 3
                      ? 5
                      : itemDepth == 4 && 6,

                  fontWeight: itemDepth == 0 && 'bold',
                  borderBottom: '1px solid rgb(224, 224, 224)',
                }}
                // fontWeight: (itemDepth == 0 || itemDepth == 1 || itemDepth == 2) && 'bold',
                className={itemDepth}
                value={item.ID}
              >
                {t(item.Name)}
              </MenuItem>
            );
            setmenuItems((prev) => [...prev, menuITEM]);
            item.subRows && GroupRecursive(item.subRows);
          })}
      </>
    );
  }

  return (
    <Grid item md={2}>
      <FormControl>
        <InputLabel size="small" margin="dense" htmlFor="grouped-select">
          {t(label)}
        </InputLabel>
        <Select
          autoFocus={focus}
          required={required}
          value={value}
          id="grouped-select"
          label={label}
          placeholder={label}
          {...field}
          onChange={handleChange}
          margin="dense"
          size="small"
        >
          {menuItems && menuItems}
        </Select>
      </FormControl>
    </Grid>
  );
}
