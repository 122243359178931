import { useContext, useEffect, useState } from 'react';
import { CardPanel } from 'src/controls/cardpanel';
import { DropDownGREYERP } from 'src/controls/dropdown';
import { TextBoxGREYERP } from 'src/controls/textbox';
import { SwithGREYERP } from 'src/controls/swithgrey';
import Picker from 'src/controls/picker';
import React from 'react';
import { FormContext } from 'src/layouts/dashboard/DashboardLayout';
import SwapSections from 'src/controls/SwapSections';
import { ItemsComponent } from 'src/controls/items/ItemsComponent';
import { GetVoucherNumber,GetAndSetList, HandleOnChangeValue  } from 'src/data/scripts';

export default function Return() {
  return (
    <>
      <SwapSections
        List={[
          <Fields />,
          <div id="divItems" className="divItems">
            <ItemsComponent
            disableAddItem={true}
              formType={window.location.pathname.split('/')[1]?.toUpperCase() == 'SALES' ? 'saleable' : 'purchasable'}
              columnsToShow={{
                Item: 0,
                Description: '',
                Quantity: 1,
                Unit: 0,
                Price: 0,
                DiscountPercentage: 0,
                Discount: 0.0,
                Tax: 3,
                TaxAmount: 0,
                Total: 0.0,
              }}
              columnsToAppend={{
                Item: 0,
                Description: '',
                Quantity: 1,
                Unit: 0,
                Price: 0,
                DiscountPercentage: 0,
                Discount: 0.0,
                Tax: 3,
                TaxAmount: 0,
                Total: 0.0,
              }}
            />
          </div>,
        ]}
      />
    </>
  );
}

function Fields() {
  const { object, setObject, objectEdit,setExistingItemsData, setFormObject, formObject } = useContext(FormContext);
  const { Series = '', Number = '', DocumentTo, Source } = object || {};
  const { Series: EditSeries = '', Number: EditNumber = '' } = objectEdit || {};
  const { SourceList } = formObject || {};
  const [Sections, setSections] = useState('');

  let sales = window.location.pathname.split('/')[1]?.toUpperCase() == 'SALES';

  useEffect(() => {
    if (objectEdit && Object.keys(objectEdit).length > 0) {
      GetAndSetList(`getItems/${sales ? 'purchasereturnsourcelistedit':'purchasereturnsourcelistedit'}/name/${localStorage.getItem( 'username' )}/${localStorage.getItem('client')}/${objectEdit['DocumentTo']}/${window.location.pathname.split('/')[3]}`, setFormObject, 'SourceList')
    }
  }, []);


  return (
    <div id="divFields" className="divFields">
      <CardPanel
        heading="Document Details"
        cardItem={
          <>
            <Picker type='date' name="Date" date={new Date()} focus={true}></Picker>
            <DropDownGREYERP
              onChange={(e, selectedValue) =>
                HandleOnChangeValue(
                  {
                    DocumentTo: selectedValue?.ID,
                  },
                  setObject,
                  [
                    GetVoucherNumber(selectedValue?.Name, setObject, null, EditNumber),
                    GetAndSetList( `getItems/${sales?'salesreturnsourcelist':'purchasereturnsourcelist'}/name/${localStorage.getItem( 'username' )}/${localStorage.getItem('client')}/${selectedValue?.ID}`, setFormObject, 'SourceList' ),
                  ]
 ) }
              postBackSelectedValue={DocumentTo} items={`${sales?'clients':'vendors'}/name`} name="DocumentTo" required={true} span={2}
            />
            <DropDownGREYERP name="Source" label="Source Document" postBackitems={SourceList} postBackSelectedValue={Source} onChange={(e, selectedValue) =>
                HandleOnChangeValue({ Source: selectedValue?.ID }, setObject, [
                  GetAndSetList(
                    `getCustomPostBackItem/${sales?'salesinvoicedetails':'purchaseinvoicedetails'}/${localStorage.getItem('username')}/${localStorage.getItem('client')}/${parseInt(selectedValue?.ID)}`,
                    setExistingItemsData,
                    null
                  ),
                ])
              }
            />
            <TextBoxGREYERP placeholder="Return Reason" name="Reason" />
            <TextBoxGREYERP placeholder="Number" disabled name="Number" defaultValue={Series ? Series + Number : EditSeries + EditNumber} required={true} />
            <DropDownGREYERP items={`warehouses/name`} name="Warehouse" />
            <DropDownGREYERP postBackitems={Sections} name="Section" />
            <TextBoxGREYERP placeholder="Document Subject" name="Subject" />
            <TextBoxGREYERP placeholder="Document Reference" name="Reference" />
            <TextBoxGREYERP placeholder="Internal Notes" span={2} name="Comments" multiline={true} />
            <SwithGREYERP name="Print"  />
          </>
        }
      />
    </div>
  );
}
