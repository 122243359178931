import { useQuery, useQueryClient } from '@tanstack/react-query';
import { HttpRequest } from 'src/data/scripts';

export const useGetGridData = (
  apiExternalUrl,
  tableName,
  username,
  clientName,
  pageIndex,
  pageSize,
  search = null
) => {
  const apiDefaultUrl = `getRecords/${tableName}/${username}/${clientName}/${pageIndex}/${pageSize}/0/${search}`;
  const apiUrl = apiExternalUrl ? apiExternalUrl : apiDefaultUrl;
  return useQuery({
    queryKey: [tableName, username, clientName, pageIndex, pageSize, apiUrl, search],
    queryFn: async () => {
      if (tableName) {
        const res = await HttpRequest(`${apiUrl}`);
        return Object.keys(res).length === 0 ? [] : res;
      } else {
        return [];
      }
    },
    keepPreviousData: true,
    refetchOnMount: true,
    refetchOnWindowFocus: true,
    enabled: !!tableName,
  });
};

export const useFormData = () => {
  const queryClient = useQueryClient();

  const setFormData = (newData) => {
    queryClient.setQueryData('form', (oldData) => {
      return { ...oldData, ...newData };
    });
  };

  const updateWholeForm = (newData) => {
    queryClient.setQueryData('form', () => {
      return { ...newData };
    });
  };

  const resetFormData = () => {
    queryClient.setQueryData('form', {});
  };

  const formData = queryClient.getQueryData('form') ?? {};

  const updatedFormData = () => {
    return queryClient.getQueryData('form');
  };

  return { formData, setFormData, resetFormData, updateWholeForm, updatedFormData };
};
