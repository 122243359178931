
import { CardPanel } from 'src/controls/cardpanel';
import Picker from 'src/controls/picker';
import { DropDownGREYERP } from 'src/controls/dropdown';
import { DropDownENUM } from 'src/controls/dropdownenum';
import { TextBoxGREYERP } from 'src/controls/textbox';
import { TextEditorGrey } from 'src/controls/texteditorgrey';
import {  Access, Priorities, ProjectStatus } from 'src/data/data';
import NavHorizontal from 'src/layouts/dashboard/nav/NavHorizontal';
import UppyFilePicker from 'src/controls/uppyFilePicker';
import { FormContext } from 'src/layouts/dashboard/DashboardLayout';
import { useContext } from 'react';
import TasksForm from './tasksform';
import { CheckBoxGREYERP } from 'src/controls/checkbox';

export default function Project() {
  const { setOpenDrawer, openDrawer,openTaskPopup } = useContext(FormContext);
  return (
    <>

      <CardPanel
        heading="Project Details"
        cardItem={
          <>
            <TextBoxGREYERP placeholder="Project Name" name="Name" required={true} span={2} focus={true} />
            <Picker type='date' required={true} name="StartDate" type="date" />
            <Picker type='date' name="EndDate" type="date" />
            <TextBoxGREYERP name="Cost" placeholder="Cost" type="Number" step="any" />
            <DropDownGREYERP items={'departments/name'} name="Department" />
            <DropDownGREYERP items={'clientusers/name'} name="Assignee" multiple={true} />
            <DropDownGREYERP items={'clientusers/name'} name="Manager" />
            <DropDownGREYERP items={'projecttypes/name'} name="Type" />
            <DropDownGREYERP items={'projectstatus/name'} name="Status" />
            <DropDownENUM items={Priorities} name="Priority" />
            <DropDownGREYERP items={`costcenters/name`}  name="CostCenter" />
            <DropDownENUM items={Access} name="Access" />
            <TextBoxGREYERP placeholder="Internal Notes" multiline={true} span={2} name="Comments" />
            <DropDownGREYERP name="RelatedToID" label="Related To" items={`relatedto/name`} />
            <CheckBoxGREYERP name='Active' span={0.4}  />
            <CheckBoxGREYERP name='Completed' span={0.4}  />

          </>
        }
      />
      <CardPanel
        heading="Project Description"
        cardItem={
          <>
            <TextEditorGrey name="Description"  multiline={true} placeholder="Project Description" />
          </>
        }
      />
    </>
  );
}
