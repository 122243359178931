import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
// @mui
import { Box, Stack, Drawer } from '@mui/material';

// config
import { NAV } from '../../../config-global';
// components
import Logo from '../../../components/logo/LogoVertical';
import Scrollbar from '../../../components/scrollbar';
import { NavSectionVertical } from '../../../components/nav-section';

import NavToggleButton from './NavToggleButton';
import useResponsive from 'src/hooks/useResponsive';
import { useEffect } from 'react';

// ----------------------------------------------------------------------

NavVertical.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
};

export default function NavVertical({ openNav, onCloseNav }) {
  const isDesktop = useResponsive('up', 'lg');
  const navConfigERP =
    localStorage.getItem('navConfigERP') && JSON.parse(localStorage.getItem('navConfigERP'));
  // useEffect(() => {
  //   if (openNav) {
  //     onCloseNav();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      <Stack
        spacing={3}
        sx={{
          pt: 3,
          pb: 2,
          px: 2.5,
          flexShrink: 0,
        }}
      >
        {/* <Link component={RouterLink} to="/" sx={{ display: 'contents' }}>
          <img
            src={logoblack}
            style={{ margin: 'auto', marginBottom: 'auto', marginTop: 'auto' }}
            height={100}
            width={100}
            alt="Grey Logo"
          />
        </Link> */}
        <Logo />

        {/* <NavAccount /> */}
      </Stack>

      <NavSectionVertical data={navConfigERP} onCloseNav={onCloseNav} />

      <Box sx={{ flexGrow: 1 }} />

      {/* <NavDocs /> */}
    </Scrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV.W_DASHBOARD },
      }}
    >
      {/* <NavToggleButton /> */}

      {isDesktop ? (
        <></>
        // <Drawer
        //   open={false}
        //   PaperProps={{
        //     sx: {
        //       zIndex: 0,
        //       width: NAV.W_DASHBOARD,
        //       bgcolor: 'transparent',
        //       borderRightStyle: 'dashed',
        //     },
        //   }}
        // >
        //   {renderContent}
        // </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          // ModalProps={{
          //   keepMounted: true,
          // }}
          PaperProps={{
            sx: {
              width: NAV.W_DASHBOARD,
            },
          }}
        >
         
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}
