import { alpha, styled } from '@mui/material/styles';
import { Card, Grid, Typography, Popover, Tooltip, IconButton, Box } from '@mui/material';

import Iconify from 'src/components/iconify/Iconify';
import PrintJournalVoucher from 'src/pages/accounts/printjournalvoucher';
import { TableGREYERP } from './table';
import PrintReceipt from 'src/pages/printreceipt';
import PrintExpense from 'src/pages/accounts/printexpense';
import PrintSalarySlip from 'src/pages/payroll/printsalaryslip';
import PrintDocument from 'src/pages/printdocument';
import { useContext } from 'react';
import { FormContext } from 'src/layouts/dashboard/DashboardLayout';
import { default as DataGrid } from '../pages/Grid';
import LoadingScreen from 'src/components/loading-screen/LoadingScreen';
import PrintTenantAgreement from 'src/pages/properties/printtenantagreement';

export default function PopupPrint() {
  let invoicePages = [
    'purchase-invoices',
    'sales-invoices',
    'quotations',
    'sales-orders',
    'purchase-orders',
  ];

  const { openPrint } = useContext(FormContext);

  let FormName = window.location.pathname.split('/')[2].toLowerCase();
  const formPrint = [
    {
      form: 'payments',
      component: <PrintReceipt />,
    },
    {
      form: 'receipts',
      component: <PrintReceipt />,
    },
    {
      form: 'expenses',
      component: <PrintExpense />,
    },
    {
      form: 'journal-vouchers',
      component: <PrintJournalVoucher />,
    },
    {
      form: 'salaryslips',
      component: <PrintSalarySlip />,
    },
    {
      form: 'trial-balance-report',
      component: <JournalPopup />,
    },
    {
      form: 'profit-and-loss-report',
      component: <JournalPopup />,
    },
    {
      form: 'tenants',
      component: <PrintTenantAgreement />,
    },
  ];

  return (
    <>
      {formPrint.map((printComponent) => {
        return <>{printComponent.form == FormName ? printComponent.component : null}</>;
      })}

      {invoicePages.includes(FormName) && <PrintDocument />}
    </>
  );
}

function JournalPopup() {
  const { openPrint, setOpenPrint, recordId, dataFetched } = useContext(FormContext);

  return (
    <Popover
      sx={{ backgroundColor: alpha('#212B36', 0.4), mt: 7 }}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={openPrint}
    >
      <Card sx={{ pt: 2, px: 3, width: '80vw' }}>
        <Grid container sx={{ mb: 3 }} alignItems={'center'} justifyContent="space-between">
          <Grid item></Grid>

          <Grid item>
            {' '}
            <Tooltip title="Close">
              <IconButton onClick={(e) => setOpenPrint(false)}>
                <Iconify icon="material-symbols:close" />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>

        <DataGrid
          showPagination={false}
          openPrintTable={false}
          TopToolbarCustomActions={
            <Grid
              container
              sx={{ m: 0, p: 0, mt: 1, mb: 1, ml: 1, width: '40%', displayPrint: 'none' }}
            >
              <Typography variant="h5">{recordId.name}</Typography>
            </Grid>
          }
          apiExternalUrl={`getLedger/${recordId?.id}/${localStorage.getItem('client')}/${localStorage.getItem('username')}/${recordId?.firstDate}/${recordId?.lastDate}`}
        />
      </Card>
    </Popover>
  );
}
