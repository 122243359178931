import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Link } from '@mui/material';
// eslint-disable-next-line import/no-unresolved
import greyErpLogo from'../../assets/logo-black.png';

// ----------------------------------------------------------------------

const LogoBlack = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {
  return (
    <Box
    ref={ref}
    component="div"
    sx={{
      width: 80,
      height: 80,
      ml:2,
      display: 'inline-flex',
      ...sx,
    }}
    {...other}
  >
        <img src={greyErpLogo} style={{marginRight:"15px",marginBottom:'auto',marginTop:'auto',height:'85%'}} alt="Grey Logo"  />

  </Box>
  );
});

LogoBlack.propTypes = {
  sx: PropTypes.object,
  disabledLink: PropTypes.bool,
};

export default LogoBlack;
