import { DropDownGREYERP } from 'src/controls/dropdown';
import { TextBoxGREYERP } from 'src/controls/textbox';
import PopUpPanel from 'src/controls/popuppanel';
import UploadGREY from 'src/controls/uploadgrey';
import { SwithGREYERP } from 'src/controls/swithgrey';
export default function Category() {
  const urlList = window.location.pathname.split('/');
  const CategoryName = (urlList[2].charAt(0).toUpperCase() + urlList[2].slice(1)).replaceAll( '-', '' );
  return (
    <>
      <PopUpPanel
        heading={'Add'}
        child={
          <>
            <UploadGREY span={2} />
            <TextBoxGREYERP name="Name" placeholder="Category Name" required={true} focus={true} />
            <TextBoxGREYERP name="NameArabic" placeholder="اسم التصنيف" dir="rtl" />
            <TextBoxGREYERP name="Description" span={2} multiline={true} placeholder="Description" MultiLine={true} />
            <TextBoxGREYERP name="DescriptionArabic" placeholder="وصف التصنيف" MultiLine={true} dir="rtl" />
            <DropDownGREYERP firstItem="0,Main" items={`${CategoryName}/name`} name="ParentCategory" required={true} />
            <TextBoxGREYERP name="Hyperlink" placeholder="Hyperlink" type="Url" />
            <TextBoxGREYERP name="Sort" placeholder="Sort" type="Number" span={0.5} />
            <SwithGREYERP name="Status" span={0.5} />
            <TextBoxGREYERP rows={3} name="Comments" multiline={true} placeholder="Internal Notes" span={2} />
          </>
        }
      />
  </>
  );
}
