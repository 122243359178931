
import { roundToNearestMinutes } from 'date-fns';
import Picker from 'src/controls/picker';
import { DropDownGREYERP } from 'src/controls/dropdown';
import { TextBoxGREYERP } from 'src/controls/textbox';
import PopUpPanel from 'src/controls/popuppanel';
import { EventDuration } from 'src/data/data';
import { DropDownENUM } from 'src/controls/dropdownenum';
import { CheckBoxGREYERP } from 'src/controls/checkbox';

export default function MeetingForm() {
 
  return (
    <>
      <PopUpPanel heading={'Add'} child={
          <>
            <TextBoxGREYERP span={2} placeholder="Meeting Agenda" name="Title" focus={true} required={true} />
            <DropDownGREYERP span={2} name="RelatedTo" items={`relatedto/name`} />
            <Picker type='datetime' date={roundToNearestMinutes(new Date(), { nearestTo: 30, roundingMethod: 'ceil' })} name="startDateTime" required={true} label={"Date"} span={1.3} ></Picker>
            <DropDownENUM span={0.7} items={EventDuration} name="endDateTime" required={true} firstItemSelect={false} label={"Duration"}/>
            <TextBoxGREYERP name="Location" span={2} />
            <TextBoxGREYERP name="Description" span={2} multiline={true}  rows={3} />
            <CheckBoxGREYERP span={2} label="Mark as Completed" name="isCompleted" />
          </>
        }
      />
    </>
  );
}
