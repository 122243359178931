import { DropDownENUM } from 'src/controls/dropdownenum';
import { TextBoxGREYERP } from 'src/controls/textbox';

import PopUpPanel from 'src/controls/popuppanel';
import { Areas, Banks } from 'src/data/data';

export default function Bankaccount() {
  return (
    <>
      <PopUpPanel
        heading={'Add'}
        child={
          <>
            <TextBoxGREYERP
              span={2}
              required
              focus={true}
              name="BankAccountNumber"
              placeholder="100250698720"
              type="Number"
              min="0"
            />
            <TextBoxGREYERP span={2} required={true} name="Name" placeholder="Rawat AL Makan LLC" />

            <TextBoxGREYERP
              name="OpeningBalance"
              placeholder="00.000"
              type="Number"
              step="any"
              required
            />

            <DropDownENUM items={Banks} name="Bank" required={true} />

            <TextBoxGREYERP
              label="Contact Person Name"
              name="ContactPerson"
              placeholder="Bank Manager Name"
            />
            <TextBoxGREYERP name="Branch" placeholder="Azaiba" required={true} />

            <TextBoxGREYERP placeholder="09686372XXX" name="Phone" type="Number" maxLen={10} />

            <TextBoxGREYERP placeholder="someone@something.com" name="Email" />

            <TextBoxGREYERP
              placeholder="Way# XXX, Ghala, Muscat..."
              name="Address"
              multiline={true}
              span={2}
            />
            <TextBoxGREYERP
              placeholder="Internal Notes"
              name="Comments"
              multiline={true}
              span={2}
            />
          </>
        }
      />
    </>
  );
}
