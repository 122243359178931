import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import ListSubheader from '@mui/material/ListSubheader';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { FormContext } from 'src/layouts/dashboard/DashboardLayout';
import { Grid } from '@mui/material';
import { useFormData } from 'src/hooks/useQueryHooks';
import { useQuery } from '@tanstack/react-query';

export default function GroupedSelectChild({
  data,
  name,
  label,
  width,
  field,
  setReTrigger,
  values,
  index,
  dataWithoutFilteration,
}) {
  const { 
    // setObject, 
    // object ,
    popupEditId,
    resetFields} = React.useContext(FormContext);

    let form = window.location.pathname?.split('/')[2]?.replaceAll('-', '');
    let ID = window.location.pathname?.split('/')[3]?.replaceAll('-', '') ?? popupEditId;

  const {setFormData:setObject, formData:object} = useFormData();

  const {data:objectEdit}= useQuery({
     queryKey:[form, ID],
     select:(res)=>res[0],
     enabled: Boolean(form)
     })

  const [value, setValue] = React.useState(null);

  const JVForm =
    window.location.pathname.split('/')[2].replace('-', '').toUpperCase() == 'JOURNALVOUCHERS';
  const handleChange = (e) => {
    setValue(e.target.value);
    if (!JVForm) {
      setObject({[name.split('.')[1]]: e.target.value })
      // setObject((prev) => {
      //   return { ...prev, [name.split('.')[1]]: e.target.value };
      // });
    }

    setReTrigger(true);
    if (field) {
      return field.onChange(e.target.value);
    }
  };

  const [menuItems, setmenuItems] = React.useState([]);
  let actualVal;

  React.useEffect(() => {
    GroupRecursive(data);
  }, [data]);

  React.useEffect(() => {
    if (dataWithoutFilteration?.length > 0) {
      values.items.map((item, index) => {
        for (const key in item) {
          if (
            key == name.split('.')[1] &&
            field.name == `items[${index}].${name.split('.')[1]}` &&
            item[key]
          ) {
            actualVal = dataWithoutFilteration.filter(
              (item1) => parseInt(item1.ID) == parseInt(item[key])
            );

            actualVal.length > 0 &&
              setValue({
                ID: actualVal[0].ID,
                Name: actualVal[0].Name,
              });
            actualVal[0] && field.onChange(actualVal[0].ID);
          }
        }
      });
    }
  }, [values.items[index], dataWithoutFilteration]);

 
  function GroupRecursive(data) {
    return (
      <>
        {data &&
          data.map((item, index) => {
            let itemDepth = item.depth;
            if ((item.subRows && item.subRows.length > 0) || itemDepth == 0) {
              let menuItem = (
                <ListSubheader
                  key={`${index}${item.ID}`}
                  sx={{
                  
                    pl:
                      itemDepth == 0
                        ? 2
                        : itemDepth == 1
                        ? 3
                        : itemDepth == 2
                        ? 4
                        : itemDepth == 3
                        ? 5
                        : itemDepth == 4 && 6,

                    fontWeight: itemDepth == 0 && 'bold',
                    
                  }}
                >
                  {item.Name}
                </ListSubheader>
              );
              setmenuItems((prev) => [...prev, menuItem]);
            } else {
              let menuITEM = (
                <MenuItem
                  key={`${index}${item.ID}`}
                  sx={{
                    pl:
                      itemDepth == 0
                        ? 2
                        : itemDepth == 1
                        ? 3
                        : itemDepth == 2
                        ? 4
                        : itemDepth == 3
                        ? 5
                        : itemDepth == 4 && 6,

                    fontWeight: itemDepth == 0 && 'bold',
                   
                  }}
                  className={itemDepth.toString()}
                  value={item.ID}
                >
                  {item.Name}
                </MenuItem>
              );
              setmenuItems((prev) => [...prev, menuITEM]);
            }

            item.subRows && GroupRecursive(item.subRows);
          })}
      </>
    );
  }


  

  return (
    <Grid
      item
    
    >
      <FormControl>
        <InputLabel size="small" margin="dense" htmlFor="grouped-select">
          {label ?? ''}
        </InputLabel>
        <Select
          value={value}
          id="grouped-select"
          label={label}
          {...field}
          onChange={handleChange}
          margin="dense"
          size="small"
        >
          {menuItems && menuItems}
        </Select>
      </FormControl>
    </Grid>
  );
}
