import { Box, Checkbox, FormControlLabel, FormGroup, Grid, InputAdornment, Paper, Typography } from "@mui/material";
import { func } from "prop-types";
import { useContext } from "react";


 import { FormContext } from "src/App";
import { CardPanel } from "src/controls/cardpanel";
import { DropDownGREYERP } from "src/controls/dropdown";
import { DropDownENUM } from "src/controls/dropdownenum";
import { TextBoxGREYERP } from "src/controls/textbox";
import UploadGREY from "src/controls/uploadgrey";
import { Against, CompanyTypes, GrievanceStatus, PaymentTerms } from "src/data/data";
import NavHorizontal from "src/layouts/dashboard/nav/NavHorizontal";
import Editor from 'src/components/editor';
import 'react-quill/dist/quill.snow.css';
import { DropzoneGREY } from "src/controls/dropzone";
import UppyFilePicker from 'src/controls/uppyFilePicker';


export default function Grievance()
{



    return(
        <>

      <CardPanel heading="Grievance Details" cardItem={<>


 <Picker type='date' name="Date" placeholder="Date"  required={true}></Picker>
<TextBoxGREYERP placeholder="Subject" name="Subject" required={true} />
<DropDownGREYERP items={`employees/name`} name="Employee" placeholder="Select Employee" required={true} />


 <DropDownGREYERP items={`grievancetypes/name`} name="Type" required={true} />
<DropDownENUM items={GrievanceStatus} name="Status"  required={true} />
<DropDownENUM items={Against} name="Against"  required={true} />
<DropDownGREYERP items={`employees/name`} name="AgainstValue"  required={true} /> 

<TextBoxGREYERP placeholder="Description" name="Description" span={2} multiline={true} MultiLine={true} />
<TextBoxGREYERP placeholder="Internal Notes" name="Comments" multiline={true} MultiLine={true} span={2}/> 


</>} />

<CardPanel heading="Resolution Details" cardItem={<>


     <Picker type='date' name="ResolutionDate" placeholder="Resolution Date"  ></Picker>
    <TextBoxGREYERP placeholder="Description" name="ResolutionDescription" MultiLine={true} span={2}/>
     <DropDownGREYERP items={`employees/name`} name="ResolvedBy" placeholder="Resolved By" required={true} />  

    </>} />



   </>


  )


}
