import { CardPanel } from 'src/controls/cardpanel';
import Picker from 'src/controls/picker';
import { DropDownGREYERP } from 'src/controls/dropdown';
import { DropDownENUM } from 'src/controls/dropdownenum';
import ImageContainer from 'src/controls/imagecontainer';
import { TextBoxGREYERP } from 'src/controls/textbox';
import UploadGREY from 'src/controls/uploadgrey';
import { DepreciationMethod } from 'src/data/data';

export default function Asset() {
  
  return (
    <>
      <CardPanel
        heading="Asset Details"
        cardItem={
          <>
            <UploadGREY />
            <ImageContainer>
              <TextBoxGREYERP name="Name" span={2} placeholder="Asset Name" required={true} />
              <TextBoxGREYERP name="Reference" placeholder="Asset Reference" />
              <TextBoxGREYERP name="Brand" placeholder="Asset Reference" />
              <TextBoxGREYERP name="Model" placeholder="Asset Model" />
              <TextBoxGREYERP name="SerialNumber" placeholder="Asset Model" />
              <DropDownGREYERP items="assets/name" name="Parent" label="Parent Asset" />
              <DropDownGREYERP items="assettypes/name" required={true} name="Type" />
              <DropDownGREYERP items="locations/name" required={true} name="Location" />
              <TextBoxGREYERP name="Latitude" placeholder="23.5755891" type="Number" step="any" />
              <TextBoxGREYERP name="Longitude" placeholder="58.3602822" type="Number" step="any"  />
              <DropDownGREYERP items="assetstatus/name" name="Status" />
              <DropDownGREYERP items="assetconditions/name" name="Condition" />
              <TextBoxGREYERP name="Description" multiline={true} placeholder="Asset Description" span={3} />
              <TextBoxGREYERP name="Comments" multiline={true} placeholder="Internal Notes" span={4} />
            </ImageContainer>
          </>
        }
      />
     
      <CardPanel
        heading="Acquisition Details"
        cardItem={
          <>
              <Picker type='date' name="Date" required={true}></Picker>
            <TextBoxGREYERP name="Value" required={true} placeholder="6000.000" type="Number" step="any" />
            <DropDownGREYERP items="purchaseinvoices/name" name="PurchaseInvoice" />
          </>
        }
      />
         <CardPanel
        heading="Depreciation Details"
        cardItem={
          <>
           <DropDownENUM items={DepreciationMethod} name="Method" required={true} />
              <TextBoxGREYERP name="Duration" placeholder="60" type="number" required={true} sufix='Months' />
              <TextBoxGREYERP name="NotDepreciableValue" required={true} placeholder="500.000" type="number" step="any" />

          </>
        }
      />
        <CardPanel
        heading="Accounting Details"
        cardItem={
          <>
           <DropDownGREYERP items={`expenseaccounts/name`}  name="DepreciationAccount" required={true}/>
             <DropDownGREYERP items={`expenseaccounts/name`}  name="ExpenseAccount" required={true}/>
             <DropDownGREYERP items={`costcenters/name`}  name="CostCenter" />
          </>
        }
      />
       <CardPanel
        heading="Expiry Details"
        cardItem={
          <>
            <TextBoxGREYERP  placeholder="Insurance Policy No."  name="InsuranceNumber" />
            <Picker type='date' name="InsuranceExpiry"></Picker>
           <Picker type='date' name="MaintenanceExpiry"></Picker>
           <Picker type='date' name="WarrantyExpiry"></Picker>
          </>
        }
      />
          <CardPanel
        heading="Allocation Details"
        cardItem={
          <>
             <DropDownGREYERP items={`departments/name`} name="Department"/>
            <DropDownGREYERP items={`employees/name`} name="Employee" />
            <DropDownGREYERP items={`Projects/name`}  name="Project" />
          </>
        }
      />
    </>
  );
}
