import { DropDownGREYERP } from 'src/controls/dropdown';
import { DropDownENUM } from 'src/controls/dropdownenum';
import { TextBoxGREYERP } from 'src/controls/textbox';
import PopUpPanel from 'src/controls/popuppanel';
import { SwithGREYERP } from 'src/controls/swithgrey';
import { CouponTypes } from 'src/data/data';
import Picker from 'src/controls/picker';


export default function Coupons() {
  return (
    <>
      <PopUpPanel
        heading={'Add'}
        child={
          <>
            <TextBoxGREYERP name="Code" placeholder="Coupon Code" required={true} span={1} focus={true} />
            <DropDownENUM  items={CouponTypes} name="Type" required={true} span={1} />
            <TextBoxGREYERP name="Value" placeholder="Value" type="Number" step="any" required={true} span={1} />
            <DropDownGREYERP items={`products/name`} name="Product" required={true} span={1} />
            <Picker type='date' name="StartDate" placeholder="StartDate" required={true} span={1}></Picker>
            <Picker type='date' name="EndDate" placeholder="EndDate" required={true} span={1}></Picker>
            <SwithGREYERP name="UseOnce" span={.5} />
            <SwithGREYERP name="Status" span={.5} />
            <TextBoxGREYERP rows={3} placeholder="Internal Notes" name="Comments" multiline={true}  span={2}  />
          </>
        }
      />
    </>
  );
}