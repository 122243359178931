import {
  Autocomplete,
  createFilterOptions,
  FormControl,
  Grid,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Iconify from 'src/components/iconify/Iconify';
import { sectionForms } from 'src/data/data';
import { HttpRequest, updateCell } from 'src/data/scripts';
import { useFormData } from 'src/hooks/useQueryHooks';
import useResponsive from 'src/hooks/useResponsive';
import { FormContext } from 'src/layouts/dashboard/DashboardLayout';

export function DropDownGREYERP({
  name,
  label,
  focus = false,
  onChange,
  items,
  firstItem,
  required,
  width,
  postBackitems,
  postBackSelectedValue,
  enterEdit = true,
  span,
  disabled,
  reTrigger = 0,
  open,
  multiple = false,
  additionalItems,
  ListName,
}) {
  const {
    resetFields,
    popupEditId,
    editOnCell,
    setObjectEdit,
    tbldata,
    setTbldata,
    dropDownPopUp,
    setOpenDrawer,
    openDrawer,
    swappedSections,
  } = useContext(FormContext);
  const { t } = useTranslation();

  const [dropDownItems, setDropDownItems] = useState([]);
  const [dataFetched, setDataFetched] = useState(false);
  const [defaultValue, setDefaultValue] = useState(null);
  const [selectedValue, setSelectedValue] = useState(null);
  const [focused, setFocused] = useState(focus);
  const [popupDropDown, setpopupDropDown] = useState();

  const [openDropdown, setOpenDropdown] = useState(open);
  const isDesktop = useResponsive('up', 'lg');

  let form = window.location.pathname?.split('/')[2]?.replaceAll('-', '');
  let ID = window.location.pathname?.split('/')[3]?.replaceAll('-', '') ?? popupEditId;

  const { setFormData: setObject, formData: object } = useFormData();

  const { data: objectEdit } = useQuery({
    queryKey: [form, ID],
    select: (res) => res[0],
    enabled: Boolean(form),
  });

  const local_username = localStorage.getItem('username');
  const local_client = localStorage.getItem('client');

  const { data: dataAsJson, error } = useQuery({
    queryKey: [items, local_username, local_client],
    queryFn: async () => await HttpRequest(`getItems/${items}/${local_username}/${local_client}`),
  });

  function addNewDropdownOpen() {
    if (Object.keys(dropDownPopUp).includes(name.toLowerCase())) {
      let MyComponent = dropDownPopUp[name.toLowerCase()];
      setOpenDrawer(true);
      setpopupDropDown(MyComponent);
    }
  }
  const registerDataChange = (e, newValue) => {
    setSelectedValue(newValue?.ID);

    if (newValue != null) {
      if (newValue?.ID == 'Create') {
        addNewDropdownOpen();
        return;
      }

      if (multiple) {
        let string = '';
        newValue.forEach((item) => {
          if (item) {
            string +=
              item instanceof String || typeof item === 'string' ? `${item},` : `${item?.ID},`;
          }
        });

        if (editOnCell) {
          if (required && !newValue) {
            mainFormRef.current.reportValidity();
          } else {
            updateCell(name, string.slice(0, -1), popupEditId, setObjectEdit, tbldata, setTbldata);
          }
        } else {
          setObject({ [name]: string.slice(0, -1) });
        }
      } else {
        if (editOnCell) {
          if (required && !newValue) {
            mainFormRef.current.reportValidity();
          } else {
            updateCell(
              name,
              newValue?.ID,
              popupEditId,
              setObjectEdit,
              tbldata,
              setTbldata,
              true,
              newValue?.Name
            );
          }
        } else {
          setObject({ [name]: newValue.ID });
        }
      }

      setDefaultValue(newValue);
    } else {
      setDefaultValue(newValue);
      setObject({ [name]: newValue });
    }
    setFocused(true);
    setOpenDropdown(false);
  };

  const registerOnBlur = (e, newValue) => {
    setFocused(true);
    setOpenDropdown(false);
  };

  const setValues = async () => {
    const json = await dataAsJson;
    if (json) {
      firstItem &&
        Array.isArray(json) &&
        json?.splice(0, 0, { ID: firstItem.split(',')[0], Name: firstItem.split(',')[1] });
      additionalItems && (json = [...json, ...additionalItems]);

      setDropDownItems(json);
      if (
        !(objectEdit && Object.keys(objectEdit).length > 0) &&
        !popupEditId &&
        isNaN(window.location.pathname.split('/')[3])
      ) {
        if (multiple && firstItem) {
          setDefaultValue([json[0]]);
          setObject({ [name]: `${firstItem.split(',')[0]}` });
        } else if (json[1] && 'IsDefault' in json[1]) {
          let isDef = false;
          json.forEach((row) => {
            if (row?.IsDefault) {
              isDef = true;
              setDefaultValue(row);
              setObject({ [name]: row.ID });
            }
          });

          if (!isDef) {
            if (firstItem || (firstItem && objectEdit[name])) {
              setDefaultValue(json[0]);
              setObject({ [name]: firstItem.split(',')[0] });
            }
          }
        } else if (firstItem || (firstItem && objectEdit[name])) {
          setDefaultValue(json[0]);
          setObject({ [name]: firstItem.split(',')[0] });
        }

        reTrigger && setDefaultValue();
      }

      setDataFetched(true);
    }
  };

  useEffect(() => {
    setDataFetched(false);
    if ((!dataFetched && !postBackitems) || reTrigger || (resetFields && items)) {
      setValues();
    }
  }, [objectEdit, reTrigger, resetFields, dataAsJson]);

  useEffect(() => {
    if (postBackSelectedValue || postBackSelectedValue == 0) {
      let defaultValue;
      if (postBackitems && postBackitems.length > 0) {
        if (multiple) {
          let defaultValueList = [];
          postBackSelectedValue.forEach((val) => {
            defaultValue = postBackitems.filter((item) => item.ID == val.ID);
            defaultValueList.push(defaultValue[0]);
          });
          defaultValueList.length > 0 && setDefaultValue(defaultValueList);
          return;
        } else {
          defaultValue = postBackitems.filter(
            (item) => parseInt(item.ID) == parseInt(postBackSelectedValue)
          );
        }
      } else if (dropDownItems && dropDownItems.length > 0) {
        defaultValue = dropDownItems.filter((item) => item.ID == postBackSelectedValue);
      }
      defaultValue && setDefaultValue(defaultValue[0]);
      setOpenDropdown(false);
    } else {
      setDefaultValue(null);
    }
  }, [postBackSelectedValue, dropDownItems, dataAsJson]);

  useEffect(() => {
    if (
      sectionForms.includes(window.location.pathname.split('/')[2].toUpperCase()) &&
      dropDownItems.length > 0 &&
      swappedSections
    ) {
      let items = dropDownItems.filter((i) => i.ID == object[name]);
      if (items.length > 0) {
        setDefaultValue(items[0]);
      }
    }
  }, [dropDownItems, dataAsJson]);

  useEffect(() => {
    if (postBackitems?.length > 0 && isNaN(window.location.pathname.split('/')[3])) {
      setDefaultValue(null);
    }
  }, [postBackitems, dataAsJson]);

  useEffect(() => {
    if (objectEdit && Object.keys(objectEdit).length > 0 && enterEdit) {
      const objectPropertyName = Object.keys(objectEdit);
      const valueArray = objectPropertyName.filter(
        (item, index, object) => item?.toUpperCase() == name?.toUpperCase()
      );
      const value = objectEdit[valueArray[0]];

      let defaultValue;
      if (postBackitems && postBackitems.length > 0) {
        if (multiple) {
          let defaultValueList = [];
          if (value) {
            let valueNew = value?.includes(',') ? value?.split(',') : [value];
            valueNew.forEach((val) => {
              defaultValue = postBackitems.filter((item) => item.ID == val);
              defaultValueList.push(defaultValue[0]);
            });

            defaultValueList.length > 0 && setDefaultValue(defaultValueList);
          } else {
            setDefaultValue([]);
          }
        } else {
          defaultValue = postBackitems.filter((item) => parseInt(item.ID) == parseInt(value));
          firstItem &&
            postBackitems.splice(0, 0, {
              id: firstItem.split(',')[0],
              Name: firstItem.split(',')[1],
            });

          defaultValue && setDefaultValue(defaultValue[0]);
        }
      } else if (dropDownItems && dropDownItems.length > 0) {
        if (multiple) {
          let defaultValueList = [];
          if (value) {
            let valueNew = value?.includes(',') ? value?.split(',') : [value];
            valueNew.forEach((val) => {
              defaultValue = dropDownItems.filter((item) => item.ID == val);
              defaultValueList.push(defaultValue[0]);
            });

            defaultValueList.length > 0 && setDefaultValue(defaultValueList);
          } else {
            setDefaultValue([]);
          }
        } else {
          defaultValue = dropDownItems.filter((item) => item.ID == value);

          defaultValue && setDefaultValue(defaultValue[0]);
        }
      } else {
      }
    }
  }, [objectEdit, postBackitems, dropDownItems]);

  const filterOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option) => {
      let optionArray = Object.keys(option);
      let name = optionArray.filter((item) => item != 'ID');
      return option[name[0]];
    },
  });

  let selectFirstValOnNew = multiple ? [] : { ID: 0, Name: '' };

  let requiredDropDown = required;

  if (multiple && required) {
    if (firstItem) {
      requiredDropDown = false;
    } else {
      requiredDropDown = true;
    }
  }

  return (
    <Grid md={span ?? 1} xs={!isDesktop && 12} item>
      <FormControl>
        <Autocomplete
          size="small"
          multiple={multiple}
          selectedValue={defaultValue ? defaultValue.ID : selectFirstValOnNew.ID}
          defaultValue={defaultValue ? defaultValue : selectFirstValOnNew}
          value={defaultValue ? defaultValue : selectFirstValOnNew}
          disabled={disabled}
          filterOptions={filterOptions}
          onChange={onChange ? onChange : registerDataChange}
          getOptionLabel={(option) => {
            if (option) {
              let addRecord = option.ID == 'Create';
              let optionArray = Object.keys(option);
              let nameKey = optionArray.filter(
                (item) => item != 'id' && item != 'ID' && item != 'Id'
              );
              if (!addRecord) {
                return option[nameKey[0]];
              } else {
                return '';
              }
            }
          }}
          options={
            postBackitems && postBackitems.length > 0
              ? postBackitems
              : dropDownItems && dropDownItems.length > 0
                ? dropDownItems
                : []
          }
          key={defaultValue}
          renderOption={(props, option, { selected }) => {
            let addRecord = option.ID == 'Create';
            return (
              <li {...props} style={{ color: 'black' }}>
                {addRecord ? <AddNewBtnDropDown /> : props.key}
              </li>
            );
          }}
          renderInput={(params) => (
            <TextField
              autoFocus={focus}
              focused={true}
              placeholder="Select Item"
              name={name}
              onBlur={registerOnBlur}
              onFocus={(e) => {
                setFocused(true);
                setOpenDropdown(true);
              }}
              onKeyDown={(e) => {
                if (e.key == 'Enter') {
                  setOpenDropdown(true);
                } else if (e.key == 'Tab') {
                  setOpenDropdown(false);
                }
              }}
              required={requiredDropDown}
              size="small"
              {...params}
              label={label ? t(label) : t(name?.match(/[A-Z][a-z]+|[0-9]+/g)?.join(' '))}
            />
          )}
        ></Autocomplete>
      </FormControl>
      {dropDownPopUp && openDrawer && popupDropDown}
    </Grid>
  );
}

function AddNewBtnDropDown() {
  return (
    <Stack
      alignItems={'center'}
      sx={{
        '&': {
          color: '#078dee',
          fontWeight: '600',
        },
        '& svg': {
          width: '17px',
          height: '17px',
        },
      }}
      direction={'row'}
    >
      <Iconify icon="eva:plus-fill" />
      <Typography sx={{ fontSize: '0.875rem', ml: 1 }}>Add New</Typography>
    </Stack>
  );
}
