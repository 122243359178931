import { CardPanel } from 'src/controls/cardpanel';
import { TextBoxGREYERP } from 'src/controls/textbox';
import { CheckBoxGREYERP } from 'src/controls/checkbox';
export default function LeaveType() {
  return (
    <>
      <CardPanel
        heading="Leave Type"
        cardItem={
          <>
            <TextBoxGREYERP name="Name" placeholder="Sick Leave" required={true} focus={true} />
            <TextBoxGREYERP name="Description" span={2} multiline={true} placeholder="Description" />
            <TextBoxGREYERP name="Percentage" placeholder="Pay Percentage" sufix="%" min={0} max={100} type="Number" />
            <TextBoxGREYERP label="Maximum" name="Maximum" placeholder="Maximum" sufix="Days" required={true} type="Number" />
            <TextBoxGREYERP name="MaximumConsecutive" placeholder="Maximum Consecutive" sufix="Days" required={true} type="Number" />
            <TextBoxGREYERP name="ApplicableAfter" placeholder="Working Days" sufix="Days" type="Number" />
            <CheckBoxGREYERP name="IsCarryForwarded" required={true} />
            <CheckBoxGREYERP name="AllowEncashment" required={true} />
            <TextBoxGREYERP  name="Comments" multiline={true} placeholder="Internal Notes" span={2} />
          </>
        }
      />
    </>
  );
}
