// @mui
import { GlobalStyles as MUIGlobalStyles } from '@mui/material';

// ----------------------------------------------------------------------

export default function GlobalStyles() {
  const inputGlobalStyles = (
    <MUIGlobalStyles
      styles={{
        '*': {
          boxSizing: 'border-box',
        },

        html: {
          margin: 0,
          padding: 0,
          width: '100%',
          height: '100%',
          WebkitOverflowScrolling: 'touch',
        },
        body: {
          margin: 0,
          padding: 0,
          width: '100%',
          height: '100%',
        },
        '#root': {
          width: '100%',
          height: '100%',
        },
        width: '100%',
        '#itemsbox fieldset': {
          border: 0,
        },
        '#itemsbox .MuiInputLabel-root': {
          display: 'none',
        },

        '.MuiTableCell-root': {
          fontSize: '13px',
        },
        '& .MuiButtonBase-root': {
          displayPrint: 'none',
        },
        ///Standard Card
        '.MuiCard-root': {
          height: '100%',
          flexGrow: 1,
        },
        //For Grid item to take full space (Flex:1)
        '& .MuiFormControl-root': {
          width: '100%',
          flex: 1,
          marginTop: '2px',
          marginBottom: '2px',
        },

        //Disabled Field
        '& .MuiFormLabel-root-MuiInputLabel-root.Mui-disabled': {
          opacity: 1,
        },
        //Normal Field
        '& input, [data-shrink="false"], .MuiInputAdornment-root>p': {
          fontSize: '13px !important',
          height: '23px !important',
          width: '100%',
        },
        '& textarea, .MuiSelect-select': {
          fontSize: '13px !important',
          height: '1.4375em ',
        },

        '.MuiMenuItem-root,.MuiListSubheader-root': {
          fontSize: '13px',
          padding: '5px 10px',
          lineHeight: '24px',
          color: '#212B36',
        },
        '.MuiTreeItem-label .MuiTypography-root': {
          color: '#212B36',
        },
        '& fieldset,rowform': {
          border: '1px solid #E5E8EB !important',
        },

        // '.MuiLink-root:hover':{

        //   backgroundColor:'White',
        //   borderRadius: '8px',
        // },
        // '.MuiLink-root:hover  .MuiListItemText-root':{
        //   color:'black',
        // },

        // '& [data-shrink="true"]': {
        //   fontSize: '16px',
        //   color:'#212B36 '
        // },

        //Field Label
        '& .phone-input-1': {
          borderRadius: '8px',
          border: '1px solid #E5E8EB ',
          margin: '0px 0px 5px 0px !important',
          padding: '0px 3px 3px 3px !important',
        },
        '&  .phone-input-1 legend': {
          fontSize: '12px',
          marginLeft: '8px',
        },
        ///Phone Field (Custom)
        '& .flag-dropdown': {
          backgroundColor: 'white !important',
          borderWidth: '0px !important',
        },
        '& .react-tel-input input': {
          borderWidth: '0px !important',
          height: '25px !important',
        },
        '& .react-tel-input .form-control': {
          width: '100%',
        },
        '& .country-list': {
          maxHeight: '400px !important',
        },
        '& .fc-media-screen': {
          height: 'calc(100vh - 270px) !important',
        },
      }}
    />
  );

  return inputGlobalStyles;
}
