import { DropDownGREYERP } from 'src/controls/dropdown';
import { TextBoxGREYERP } from 'src/controls/textbox';
import PopUpPanel from 'src/controls/popuppanel';
import Picker from 'src/controls/picker';
export default function Timesheet() {
  const urlList = window.location.pathname.split('/');
  return (
    <>
      <PopUpPanel
        heading={'Add'}
        child={
          <>
            <DropDownGREYERP span={2} name="Project" items={`projects/name`}  />
            <DropDownGREYERP items={'tasks/name'} name="Task" />
            <DropDownGREYERP name="Assignee" items={`clientusers/name`} />
            <TextBoxGREYERP rows={3} name="Description" span={2} multiline={true} placeholder="Description" MultiLine={true} />
            <Picker type='dateTime' label="Start" required={2} name="StartDateTime" />
            <Picker type='dateTime' label="End" name="EndDateTime" />
            <TextBoxGREYERP  name="Comments" multiline={true} placeholder="Internal Notes" span={2} />
          </>
        }
      />
    </>
  );
}
