
import AccordionGrid from 'src/controls/accordionGrid';
import PrintDocumentLayout from 'src/controls/printdocumentlayout';


export default function BalanceSheet() {
  // let BalanceSheet ={
  //   head1:'Liabilities',
  //     head2:'Assets',
  //     showFooter:false,
  //   firstColumn:[
  //    {
  //     heading: 'Capital Account',
  //     parent:'Equity',
  //     child:'Capital'
  //    },
  //    {
  //     heading: 'Current Liabilities',
  //     parent:'Liabilities',
  //     child:'Current Liabilities'
  //    },
      
  //   ],
  //   secondColumn:[
  //     {
  //       heading: 'Fixed Assets',
  //       parent:'Assets',
  //       child:'Fixed Assets'
  //      },
  //      {
  //       heading: 'Investments',
  //       parent:'Assets',
  //       child:'Investments'
  //      },
  //      {
  //       heading: 'Current Assets',
  //       parent:'Assets',
  //       child:'Current Assets'
  //      },
  //     //  {
  //     //   heading: 'Profit & Loss A/c',
  //     //   parent:'',
  //     //   child:''
  //     //  },
        
     
  //   ]
  // }
  let BalanceSheet ={
    hasDiffCols:false,
    showFooter:true
  }
  
  return (
    <>
  <PrintDocumentLayout>
       <AccordionGrid printLayout={BalanceSheet}/>
    </PrintDocumentLayout>
    </>
  );
}
