import { useContext, useEffect, useState } from 'react';
import { CardPanel } from 'src/controls/cardpanel';
import { DropDownGREYERP } from 'src/controls/dropdown';
import { TextBoxGREYERP } from 'src/controls/textbox';
import { ItemsComponent } from 'src/controls/items/ItemsComponent';
import { SwithGREYERP } from 'src/controls/swithgrey';
import { FormContext } from 'src/layouts/dashboard/DashboardLayout';
import { GetAndSetList, GetVoucherNumber, HandleOnChangeValue } from 'src/data/scripts';
import SwapSections from 'src/controls/SwapSections';
import { useLocation } from 'react-router';
import Picker from "src/controls/picker"

export default function Booking() {
  return (
    <>
    <SwapSections
        List={[
          <Fields />,
          <div id="divItems" className="divItems">
            <ItemsComponent
              formType={'bookable'}
              columnsToShow={{
                Item: 0,
                Description: '',
                // Quantity: 1,
                // Unit: 0,
                Price: 0,
                DiscountPercentage: 0,
                Discount: 0.0,
                Tax: 0,
                TaxAmount: 0,
                Total: 0.0,
              }}
              columnsToAppend={{
                Item: 0,
                Description: '',
                // Quantity: 1,
                // Unit: 0,
                Price: 0,
                DiscountPercentage: 0,
                Discount: 0.0,
                Tax: 0,
                TaxAmount: 0,
                Total: 0.0,
              }}
            />
          </div>,
        ]}
      />
    </>
  );
}

function Fields() {
  const { setObject, objectEdit, setExistingItemsData, setFormObject, object, formObject } =
    useContext(FormContext);
  const { Series = '', Number = '', Reference, DocumentTo, PriceList, DueDate, Source, } = object || {};
  const { Series: EditSeries = '', Number: EditNumber = '' } = objectEdit || {};
  const { SourceList } = formObject || {};
  const { state } = useLocation();
  const [Sections, setSections] = useState('');

  useEffect(() => {
    if (state) {
      HandleOnChangeValue(
        {
          ...state,
        },
        setObject
      );
    }
    if (PriceList) {
      GetAndSetList(`getRecordById/salespricelistdetails}/${PriceList}`, setFormObject, 'FullPriceList');
    }
  }, [state, PriceList]);

  return (
    <div id="divFields" className="divFields">
      <CardPanel
        heading='Document Details'
        cardItem={
          <>
            <Picker type="date" name="Date" focus={true} date={new Date()} required={true} />
            <Picker type="dateTime"  required={true} name="PickUpDateTime"  />
            <Picker type="dateTime"  required={true} name="ReturnDateTime"  />

            <DropDownGREYERP required={true} items={`locations/name`} name="PickupLocation" />
            <DropDownGREYERP required={true} items={`locations/name`} name="ReturnLocation" />
            <DropDownGREYERP
              required
              postBackSelectedValue={DocumentTo}
              onChange={(e, selectedValue) =>
                HandleOnChangeValue({ DocumentTo: selectedValue?.ID }, setObject, [
                  GetVoucherNumber(selectedValue?.Name, setObject, null, EditNumber),
                  GetAndSetList(
                    `getItems/pricelistsclient/name/${localStorage.getItem('username')}/${localStorage.getItem('client')}/${selectedValue?.ID}`, setObject, 'PriceList'
                  ),
                ])
              }
              items={`sundrydebtors/Name`} 
              name="DocumentTo" span={2} />
            <TextBoxGREYERP defaultValue={Series ? Series + Number : EditSeries + EditNumber} disabled required={true} name="Number" placeholder="Number" />
            <DropDownGREYERP postBackSelectedValue={PriceList} items={'salespricelists/name'} name="PriceList" onChange={(e, selectedValue) =>
                HandleOnChangeValue({ PriceList: selectedValue?.ID }, setObject)
              }
            />
            <SwithGREYERP name={'Print'}  />
            <TextBoxGREYERP name="Subject" placeholder="Document Subject" />
            <TextBoxGREYERP name="Reference" placeholder="Document Reference" />
            <TextBoxGREYERP name="Comments" multiline={true} placeholder="Internal Notes" span={2} />
          </>
        }
      />
    </div>
  );
}
