import { CardPanel } from 'src/controls/cardpanel';
import { DropDownENUM } from 'src/controls/dropdownenum';
import { StockInDocuments, StockOutDocuments } from 'src/data/data';
import { useEffect, useState } from 'react';
import { HttpRequest } from 'src/data/scripts';

export default function UserSettings({ userInfo }) {
  const [stock, setStock] = useState({
    StockInDocument: '',
    StockOutDocument: '',
  });

  useEffect(() => {
    if (userInfo) {
      setStock((prev) => {
        return {
          ...prev,
          StockInDocument: userInfo['StockInDocument'] ?? '',
          StockOutDocument: userInfo['StockOutDocument'] ?? '',
        };
      });
    }
  }, [userInfo]);

  async function OnChange(name, selectedVal) {
    await HttpRequest(
      `clientsdoc/${localStorage.getItem('client')}/${localStorage.getItem('username')}`,
      'PUT',
      {
        [name]: selectedVal.ID,
      }
    );

    setStock((prev) => {
      return { ...prev, [name]: selectedVal.ID };
    });
  }

  return (
    <>
      <CardPanel
        heading="Settings"
        cardItem={
          <>
            <DropDownENUM
              onChange={(e, selectedVal) => OnChange('StockInDocument', selectedVal)}
              postBackSelectedValue={stock['StockInDocument']}
              items={StockInDocuments}
              name="StockInDocument"
              span={1}
              firstItemSelect={false}
            />
            <DropDownENUM
              onChange={(e, selectedVal) => OnChange('StockOutDocument', selectedVal)}
              postBackSelectedValue={stock['StockOutDocument']}
              items={StockOutDocuments}
              name="StockOutDocument"
              span={1}
              firstItemSelect={false}
            />
          </>
        }
      />
    </>
  );
}
