import { useContext, useEffect, useState } from 'react';
import { CardPanel } from 'src/controls/cardpanel';
import { DropDownGREYERP } from 'src/controls/dropdown';
import { TextBoxGREYERP } from 'src/controls/textbox';
import { ItemsComponent } from 'src/controls/items/ItemsComponent';
import Picker from 'src/controls/picker';
import { FormContext } from 'src/layouts/dashboard/DashboardLayout';
import { GetAndSetList, GetAndSetValue, GetVoucherNumber, HandleOnChangeValue, getClientName } from 'src/data/scripts';
import { useQuery } from '@tanstack/react-query';
import { useFormData } from 'src/hooks/useQueryHooks';
import { useLocation } from 'react-router';

export default function WorkOrder() {
  const {  setExistingItemsData, setFormObject,   formObject ,popupEditId} = useContext(FormContext);
  let formID = window.location.pathname?.split('/')[2]?.replaceAll('-', '');
let ID = window.location.pathname?.split('/')[3]?.replaceAll('-', '') ?? popupEditId;
const {setFormData:setObject} = useFormData();
const {data:objectEdit}= useQuery({ queryKey:[formID, ID], select:(res)=>res[0], enabled: (Boolean(formID) && !!ID) })
const {data:object}= useQuery({ queryKey:'form', })
const { Series = '', Number = '',  BillOfMaterial:BOM,Item } = object || {};
const { Series: EditSeries = '', Number: EditNumber = '' } = objectEdit || {};
const { ItemList = [],BOMList } = formObject || {};
const { state } = useLocation();

useEffect(() => {
  if (state) {
    HandleOnChangeValue(
      {
        ...state,
      },
      setObject
    );
  }
}, [state]);
useEffect(() => {
  if (isNaN(window.location.pathname.split('/')[3])) {
    GetVoucherNumber(getClientName(), setObject)
  }
}, []);



  return (
    <>

<div id="divFields" className="divFields">
      <CardPanel
        heading='Document Details'
        cardItem={
          <>
            <Picker type='date' name="Date" focus={true} date={new Date()} required={true}></Picker>
            <Picker type='date' name="EndDate"  ></Picker>
            <DropDownGREYERP span={2} required={true} items={'items/name'}  postBackitems={ItemList} postBackSelectedValue={Item}
              onChange={(e, selectedValue) =>
                HandleOnChangeValue({ Item: selectedValue?.ID }, setObject, [
                  GetAndSetList( `getItems/itembillofmaterials/name/${localStorage.getItem('username')}/${localStorage.getItem('client')}/${ selectedValue?.ID }`, setFormObject, 'BOMList' ),
              GetAndSetList( `getCustomPostBackItem/${'productionplanitems'}/${localStorage.getItem('username')}/${localStorage.getItem( 'client' )}/${parseInt(selectedValue?.ID)}`, setExistingItemsData, null )
            ]) }
              name="Item" label="Item To Manufacture"
            />
            <TextBoxGREYERP name="Quantity" type="Number" required={true} placeholder="1"  />
            <TextBoxGREYERP defaultValue={Series ? Series + Number : EditSeries + EditNumber} disabled required={true} name="Number" placeholder="Number" />
            <TextBoxGREYERP name="Reference" placeholder="Reference" />
            <DropDownGREYERP postBackitems={BOMList} postBackSelectedValue={BOM} name="BillOfMaterial" required={true} onChange={(e, selectedValue) =>
                HandleOnChangeValue({ BillOfMaterial: selectedValue?.ID }, setObject, [
                  GetAndSetList( `getCustomPostBackItem/workorderrawmaterials/${localStorage.getItem('username')}/${localStorage.getItem( 'client' )}/${parseInt(selectedValue?.ID)}`, setExistingItemsData, null ),
                ])
              }  />
            <DropDownGREYERP items={`productionplans/name`} name="ProductionPlan"    />
            <DropDownGREYERP items={`opensalesorders/name`} name="SalesOrder"    />
            <DropDownGREYERP items={`projects/name`}  name="Project" />
            <DropDownGREYERP items={`warehouses/name`}  name="TargetWarehouse" />
            <TextBoxGREYERP name="Description" multiline={true} placeholder="Description" span={2} />
            <TextBoxGREYERP name="Comments" multiline={true} placeholder="Internal Notes" span={2} />
          </>
        }
      />
    </div>
          <div id="divRawMaterials" className="divItems">
            <ItemsComponent
              heading={'Raw Materials'}
              tablename={'workorderrawmaterials'}
              formType={'purchasable'}
              disableDiscount={true}
              disableSubTotal={true}
              disableTax={true}
              appendCalculation={false}
              columnsToShow={{
                Item: 0,
                Description: '',
                Warehouse: 0,
                Price: 0,
                Quantity: 1,
                Total: 0.0,
              }}
              columnsToAppend={{
                Item: 0,
                Description: '',
                Warehouse: 0,
                Price: 0,
                Quantity: 1,
                Total: 0.0,
              }}
            />
          </div>
        <div id="divOperations" className="divItems">
        <ItemsComponent
          heading={'Operations'}
          tablename={'workorderoperations'}
          disableCalculation={true}
          disableQuantity={true}
          columnsToShow={{
            Operation: 0,
            Description: '',
            Duration: 1,
            WorkCenter: 0,
          }}
          columnsToAppend={{
            Operation: 0,
            Description: '',
            Duration: 1,
            WorkCenter: 0,
          }}
        />
      </div>
       
    </>
  );
}
