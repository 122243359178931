import { CardPanel } from 'src/controls/cardpanel';
import Picker from 'src/controls/picker';
import { DropDownGREYERP } from 'src/controls/dropdown';
import { TextBoxGREYERP } from 'src/controls/textbox';

export default function AssetMaintenanceLog() {
  
  return (
    <>
    <CardPanel
      heading="Asset Details"
      cardItem={
        <>
          <DropDownGREYERP items={`assets/name`} span={2} name="Asset" disabled={true} required={true} />
        </>
      }
    />
      <CardPanel
      heading="Maintenance Details"
      cardItem={
        <>
            <TextBoxGREYERP placeholder="Task Name" name="Name" disabled={true} multiline={true} />
            <TextBoxGREYERP placeholder="Description" name="Description" disabled={true} span={2} multiline={true} />
            <DropDownGREYERP items={'employees/name'}  placeholder="Employee Name" name="Employee" disabled={true} />
            <DropDownGREYERP items={'intervals/name'}  name="Interval" disabled={true} />
            <Picker type='date' name="DueDate"></Picker>
        </>
      }
    />
 <CardPanel
      heading="Completion Details"
      cardItem={
        <>
          <Picker type='date' name="CompletionDate" focus={true} date={new Date()} required={true}></Picker>
          <TextBoxGREYERP  placeholder="Description" multiline={true}  span={2} name="CompletionDescription" />
          <TextBoxGREYERP  placeholder="Internal Notes" multiline={true}  span={2} name="Comments" />
        </>
      }
    />
   </>
  );
}
