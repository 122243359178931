import { Card } from '@mui/material';
import { useForm } from 'react-hook-form';
import FormProvider from 'src/components/hook-form/FormProvider';
import Items from './Items';
import { useContext } from 'react';
import { FormContext } from 'src/layouts/dashboard/DashboardLayout';

export function ItemsComponent({
  formType,
  columnsToAppend,
  existingItemsData,
  columnsToShow,
  appendCTA,
  disableQuantity,
  disableDiscount,
  disableTax,
  disableSubTotal,
  disableCalculation,
  disableAddItem,
  heading,
  externalOnChange,
  appendCalculation,
  disableItem,
  displayFooterCalc,
  dataTbl,
  dataWithoutFilteration,displaySpecificCalc,
  tablename,disabledColumns,editModeOnNew,enterOnChangeFunc,postBackCalcFunc
}) {
  const methods = useForm();
  

  return (
    <>
      <Card sx={{mb:3}}>
        <FormProvider methods={methods} >
          <Items
            tablename = {tablename}
            methods={methods}
            formType={formType}
            columnsToShow={columnsToShow}
            existingItemsDataExternal={existingItemsData}
            columnsToAppend={columnsToAppend}
            appendCTA={appendCTA}
            disableQuantity={disableQuantity}
            disableCalculation={disableCalculation}
            disableAddItem={disableAddItem}
            disableDiscount={disableDiscount}
            disableSubTotal={disableSubTotal}
            disableTax={disableTax}
            appendCalculation={appendCalculation}
            heading={heading}
            dataTbl={dataTbl}
            externalOnChange={externalOnChange}
            disableItem={disableItem}
            displayFooterCalc={displayFooterCalc}
            dataWithoutFilteration={dataWithoutFilteration}
            disabledColumnsExternal={disabledColumns}
            editModeOnNew={editModeOnNew}
            enterOnChangeFunc={enterOnChangeFunc}
            displaySpecificCalc={displaySpecificCalc}
            postBackCalcFunc={postBackCalcFunc}
          />
        </FormProvider>
      </Card>
    </>
  );
}
