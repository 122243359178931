import { Button, Card, Divider, Drawer, Grid, Typography } from '@mui/material';
import { UploadIllustration } from 'src/assets/illustrations';

export default function Fine() {
 
  return (
              
                   <Grid container sx={{mb:5,mt:5}} alignItems="center" justifyContent="center">
                      <Grid item>
                        <UploadIllustration sx={{ width: 220 }} />
                      </Grid>
                      <Grid
                        item
                        sx={{ ml: 5 }}
                        onDragOver={(e) => {
                          e.preventDefault();
                        }}
                        // onDrop={onDropFile}
                      >
                        <input
                          multiple=""
                          // onChange={onSelectFile}
                          type="file"
                          tabindex="-1"
                          id="getFile1"
                          style={{ display: 'none' }}
                        />

                        <Typography gutterBottom variant="h5">
                          Drop or Select file
                        </Typography>

                        <Typography for="getFile1" variant="body2" sx={{ color: 'text.secondary' }}>
                          Drop files here or click
                          <Typography
                            variant="body2"
                            component="span"
                            sx={{
                              mx: 0.5,
                              color: 'primary.main',
                              textDecoration: 'underline',
                            }}
                          >
                            browse
                          </Typography>
                          thorough your machine
                        </Typography>
                      </Grid>
                    </Grid>
  );
}
