import { useContext, useEffect, useState } from 'react';

import { CardPanel } from 'src/controls/cardpanel';
import { DropDownGREYERP } from 'src/controls/dropdown';
import { DropDownENUM } from 'src/controls/dropdownenum';
import { TextBoxGREYERP } from 'src/controls/textbox';
import { HttpRequestGet, PaymentMode } from 'src/data/data';
import { SwithGREYERP } from 'src/controls/swithgrey';
import React from 'react';
import { ItemsComponent } from 'src/controls/items/ItemsComponent';
import {
  GetVoucherNumber,
  HandleOnChangeValue,
  HttpRequest,
  SelectPaymentMode,
  getClientName,
} from 'src/data/scripts';
import { FormContext } from 'src/layouts/dashboard/DashboardLayout';
import ChequeDetails from 'src/controls/chequedetail';
import Picker from 'src/controls/picker';
import { useQuery } from '@tanstack/react-query';
import { useFormData } from 'src/hooks/useQueryHooks';

export default function Expense() {
  let formID = window.location.pathname?.split('/')[2]?.replaceAll('-', '');
  let ID = window.location.pathname?.split('/')[3]?.replaceAll('-', '');
  const { setFormData: setObject } = useFormData();
  const { data: object } = useQuery({ queryKey: 'form' });
  const { data: objectEdit } = useQuery({
    queryKey: [formID, ID],
    select: (res) => res[0],
    enabled: Boolean(formID) && !!ID,
  });

  const { formObject, setFormObject, resetFields } = useContext(FormContext);
  const { Series = '', Number = '', Mode, Amount, Tax, TaxAmount, Total } = object || {};
  const { Series: EditSeries = '', Number: EditNumber = '' } = objectEdit || {};
  const { showAccount, showChequeDetails, expenseAcc } = formObject || {};
  const [relatedto, setRelatedto] = useState(0);
  const [relatedToID, setRelatedToID] = useState(0);

  useEffect(() => {
    if (isNaN(window.location.pathname.split('/')[3])) {
      HandleOnChangeValue(
        {
          FromAccount: '1101',
        },
        setObject
      );
      GetVoucherNumber(getClientName(), setObject);
    }
  }, [resetFields]);

  useEffect(() => {
    GetExpenseData();
  }, [resetFields]);

  async function GetExpenseData() {
    let data = await HttpRequest(
      `getItems/expenseaccounts/name/${localStorage.getItem('username')}/${localStorage.getItem(
        'client'
      )}`
    );

    const idMapping = data.reduce((acc, el, i) => {
      acc[el.ID] = i;
      return acc;
    }, {});

    let root;
    let header = [];
    let dataTbl1 = [];

    data.forEach((el) => {
      //Handle the root element
      if (el.ParentID == '5') {
        header.push(el.ID);
        return;
      }
    });

    //  console.log(idMapping,'id',header,'header')

    data.forEach((el) => {
      // if (el.ParentID == '517') {
      //   root = el;
      //   return;
      // }
      const parentEl = data[idMapping[el.ParentID]];
      parentEl && (parentEl.subRows = [...(parentEl.subRows || []), el]);
      if (parentEl && header.includes(parentEl.ID)) {
        dataTbl1.push(parentEl);
      } else if (el.ParentID == '5') {
        dataTbl1.push(data[idMapping[el.ID]]);
      }
    });

    dataTbl1 = [...new Set(dataTbl1)];
    addingDepth(dataTbl1, 0);
    // console.log(dataTbl1,'kkk')
    setFormObject((prev) => {
      return { ...prev, expenseAcc: dataTbl1 };
    });
  }

  function addingDepth(items, depth) {
    items.map((i) => {
      i['depth'] = depth;
      i?.subRows && addingDepth(i.subRows, depth + 1);
    });
  }

  return (
    <>
      <CardPanel
        heading="Expense Details"
        cardItem={
          <>
            <Picker type="date" required={true} name="Date" focus={true} date={new Date()}></Picker>
            <DropDownGREYERP
              items={'expenseaccounts/Name'}
              name="ToAccount"
              label="Category"
              required={true}
            />
            <TextBoxGREYERP
              label="Narration"
              required={true}
              span={2}
              placeholder="Narration"
              name="Description"
              multiline={true}
            />

            <TextBoxGREYERP
              placeholder="Amount"
              name="Amount"
              type="Number"
              required={true}
              defaultValue={Amount}
              onChange={(e) =>
                HandleOnChangeValue(
                  {
                    Total:
                      Tax == 2
                        ? (
                            parseFloat(e.target.value) +
                            parseFloat(e.target.value) * parseFloat(0.05)
                          ).toFixed(3)
                        : parseFloat(e.target.value).toFixed(3),
                    TaxAmount:
                      Tax == 2 ? (parseFloat(e.target.value) * parseFloat(0.05)).toFixed(3) : 0,
                  },

                  setObject
                )
              }
              step={'Any'}
            />
            <DropDownGREYERP
              items={`taxCategories/name`}
              name="Tax"
              required={true}
              onChange={(e, selectedValue) =>
                HandleOnChangeValue(
                  {
                    Tax: selectedValue?.ID,
                    TaxAmount: Tax == 2 ? (parseFloat(amount) * parseFloat(0.05)).toFixed(3) : 0,
                    Total:
                      Tax == 2
                        ? (parseFloat(amount) + parseFloat(amount) * parseFloat(0.05)).toFixed(3)
                        : Amount,
                  },
                  setObject
                )
              }
              postBackSelectedValue={Tax}
            />
            <TextBoxGREYERP
              name="TaxAmount"
              type="Number"
              disabled={true}
              defaultValue={TaxAmount}
            />
            <TextBoxGREYERP
              name="Total"
              type="Number"
              disabled={true}
              required={true}
              defaultValue={Total}
            />
            <TextBoxGREYERP
              placeholder="Expense Number"
              disabled
              name="Number"
              defaultValue={Series ? Series + Number : EditSeries + EditNumber}
            />
            <TextBoxGREYERP placeholder="Reference" name="Reference" />

            <DropDownGREYERP items={`costcenters/name`} name="CostCenter" />

            <DropDownGREYERP items={`Projects/name`} name="Project" />
            <DropDownENUM
              name="Mode"
              required
              items={PaymentMode}
              onChange={(e, selectedValue) => {
                SelectPaymentMode(selectedValue?.ID, setFormObject);
                HandleOnChangeValue(
                  {
                    Mode: selectedValue?.ID,
                    ...(selectedValue?.ID == 'Cash' && {
                      FromAccount: '1101',
                    }),
                  },
                  setObject
                );
              }}
              postBackSelectedValue={Mode}
            />
            <DropDownGREYERP
              name="RelatedToID"
              onChange={(e, selectedValue) => {
                setRelatedToID(selectedValue?.ID);
                setRelatedto(
                  selectedValue.Name.substring(selectedValue.Name.lastIndexOf('(') + 1).slice(0, -1)
                );
              }}
              items={`relatedto/name`}
              span={2}
              postBackSelectedValue={relatedToID}
            />
            <input
              name="RelatedTo"
              id="RelatedTo"
              value={relatedto}
              style={{ display: 'none' }}
            ></input>

            <TextBoxGREYERP
              span={2}
              placeholder="Internal Notes"
              name="Comments"
              multiline={true}
            />
            <SwithGREYERP name="Print" />
            {showAccount && (
              <DropDownGREYERP
                items={`bankaccounts/name`}
                name="FromAccount"
                span={2}
                required={true}
              />
            )}
          </>
        }
      />

      {showChequeDetails && <ChequeDetails />}
    </>
  );
}
