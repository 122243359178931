// @mui
import { Stack, Typography, Link, Box } from '@mui/material';
// auth

// layouts
import LoginLayout from '../../layouts/login';
//
import AuthLoginForm from './AuthLoginForm';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

export default function Login() {
  const { t } = useTranslation();
  return (
    <LoginLayout>
      <Stack spacing={2} sx={{ mb: 5, position: 'relative' }}>
        <Typography variant="h4">{t('Sign In')}</Typography>

        <Stack direction="row" spacing={0.5}>
          <Typography variant="body2">{t('New user')}?</Typography>

          <Link variant="subtitle2">{t('Create an account')}</Link>
        </Stack>
      </Stack>
      <AuthLoginForm />
    </LoginLayout>
  );
}
