import Picker from 'src/controls/picker';
import { DropDownGREYERP } from 'src/controls/dropdown';
import { TextBoxGREYERP } from 'src/controls/textbox';
import PopUpPanel from 'src/controls/popuppanel';

export default function Transfer() {
  return (
    <>
      <PopUpPanel
        heading={'Add'}
        child={
          <>
            <Picker type='date'
              name="Date" 
              placeholder="Date"
              date={new Date()}
              focus={true}
              required
            ></Picker>
            <TextBoxGREYERP
              name="Amount"
              placeholder="Amount"
              type="Number"
              required
            />

            <DropDownGREYERP span={2} name="FromAccount" items={`bankaccountsandcash/name`} required={true} />
            <DropDownGREYERP span={2} name="ToAccount" items={`bankaccountsandcash/name`} required={true} />

            <TextBoxGREYERP placeholder="Narration" name="Narration" span={2} required={true} />
          </>
        }
      />
    </>
  );
}
