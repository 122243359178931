import { useContext, useEffect, useState } from 'react';
import { CardPanel } from 'src/controls/cardpanel';
import { DropDownGREYERP } from 'src/controls/dropdown';
import { TextBoxGREYERP } from 'src/controls/textbox';
import { ItemsComponent } from 'src/controls/items/ItemsComponent';
import { SwithGREYERP } from 'src/controls/swithgrey';
import { FormContext } from 'src/layouts/dashboard/DashboardLayout';
import {
  GetAndSetList,
  GetVoucherNumber,
  HandleOnChangeValue,
  HttpRequest,
  arabic,
} from 'src/data/scripts';
import SwapSections from 'src/controls/SwapSections';

import { useLocation } from 'react-router';
import { useQuery } from '@tanstack/react-query';
import { useFormData } from 'src/hooks/useQueryHooks';
import Picker from 'src/controls/picker';

export default function Invoice() {
  return (
    <>
      <SwapSections
        List={[
          <Fields />,
          <div id="divItems" className="divItems">
            <ItemsComponent
              formType={
                window.location.pathname.split('/')[2]?.toLowerCase() === 'purchase-invoices'
                  ? 'purchasable'
                  : 'saleable'
              }
              columnsToShow={{
                Item: 0,
                Description: '',
                Quantity: 1,
                Unit: 0,
                Price: 0,
                DiscountPercentage: 0,
                Discount: 0.0,
                Tax: 0,
                TaxAmount: 0,
                Total: 0.0,
              }}
              columnsToAppend={{
                Item: 0,
                Description: '',
                Quantity: 1,
                Unit: 0,
                Price: 0,
                DiscountPercentage: 0,
                Discount: 0.0,
                Tax: 0,
                TaxAmount: 0,
                Total: 0.0,
              }}
            />
          </div>,
          // <div id="divCharges" className="divCharges">
          //   {window.location.pathname.split('/')[2]?.toLowerCase() == 'purchase-invoices' && (
          //     <ItemsComponent
          //       formType={'chargable'}
          //       disableDiscount={true}
          //       disableSubTotal={true}
          //       disableTax={true}
          //       appendCalculation={false}
          //       tablename="purchaseinvoicecharges"
          //       heading={'Charges'}
          //       columnsToShow={{
          //         Item: 0,
          //         Description: '',
          //         Method: '',
          //         Price: 0,
          //       }}
          //       columnsToAppend={{
          //         Item: 0,
          //         Description: '',
          //         Method: '',
          //         Price: 0,
          //       }}
          //     />
          //   )}
          // </div>,
        ]}
      />
    </>
  );
}

function Fields() {
  const { setExistingItemsData, setFormObject, popupEditId, formObject } = useContext(FormContext);

  let formID = window.location.pathname?.split('/')[2]?.replaceAll('-', '');
  let ID = window.location.pathname?.split('/')[3]?.replaceAll('-', '') ?? popupEditId;
  const { setFormData: setObject } = useFormData();
  const { data: object } = useQuery({ queryKey: 'form' });
  const { data: objectEdit } = useQuery({
    queryKey: [formID, ID],
    select: (res) => res[0],
    enabled: Boolean(formID) && !!ID,
  });

  const {
    Series = '',
    Number = '',
    Reference,
    DocumentTo,
    PriceList,
    DueDate,
    Source,
  } = object || {};
  const { Series: EditSeries = '', Number: EditNumber = '' } = objectEdit || {};
  const { SourceList } = formObject || {};
  const { state } = useLocation();
  const [Sections, setSections] = useState('');
  let form = window.location.pathname.split('/')[2]?.toLowerCase();

  useEffect(() => {
    if (state) {
      HandleOnChangeValue(
        {
          ...state,
        },
        setObject
      );
    }
    if (PriceList) {
      GetAndSetList(
        `getRecordById/${
          form === 'purchase-invoices' ? 'purchasepricelistdetails' : 'salespricelistdetails'
        }/${PriceList}`,
        setFormObject,
        'FullPriceList'
      );
    }

    if (objectEdit && Object.keys(objectEdit).length > 0) {
      form != 'quotations' &&
        GetAndSetList(
          `getItems/${
            form === 'sales-invoices' ? 'invoicesourcelistedit' : 'purchaseinvoicesourcelistedit'
          }/name/${localStorage.getItem('username')}/${localStorage.getItem('client')}/${
            objectEdit['DocumentTo']
          }/${window.location.pathname.split('/')[3]}`,
          setFormObject,
          'SourceList'
        );
    }
  }, [state, PriceList]);

  return (
    <div id="divFields" className="divFields">
      <CardPanel
        heading="Document Details"
        cardItem={
          <>
            <Picker type="date" name="Date" focus={true} date={new Date()} required={true}></Picker>
            <DropDownGREYERP
              required
              postBackSelectedValue={DocumentTo}
              onChange={(e, selectedValue) =>
                HandleOnChangeValue({ DocumentTo: selectedValue?.ID }, setObject, [
                  GetVoucherNumber(
                    arabic.test(selectedValue?.Name.split('(')[0])
                      ? 'A R B'
                      : selectedValue?.Name.split('(')[0],
                    setObject,
                    null,
                    EditNumber
                  ),
                  form != 'quotations' &&
                    GetAndSetList(
                      `getItems/${
                        form === 'sales-invoices'
                          ? 'invoicesourcelist'
                          : 'purchaseinvoicesourcelist'
                      }/name/${localStorage.getItem('username')}/${localStorage.getItem(
                        'client'
                      )}/${selectedValue?.ID}`,
                      setFormObject,
                      'SourceList'
                    ),

                  GetAndSetList(
                    `getItems/${
                      form === 'purchase-invoices' ? 'pricelistsvendor' : 'pricelistsclient'
                    }/name/${localStorage.getItem('username')}/${localStorage.getItem('client')}/${
                      selectedValue?.ID
                    }`,
                    setObject,
                    'PriceList'
                  ),

                  form != 'quotations' &&
                    (async function GetPaymentDate() {
                      let DocumentTo = await HttpRequest(
                        `getCustomPostBackItem/${
                          form === 'purchase-invoices' ? 'vendors' : 'leads'
                        }/${localStorage.getItem('username')}/${localStorage.getItem('client')}/${
                          selectedValue?.ID
                        }`
                      );

                      if (DocumentTo?.length > 0) {
                        let dueDate = new Date();
                        dueDate.setDate(
                          dueDate.getDate() + parseInt(DocumentTo[0]?.Paymentterms ?? 0)
                        );
                        HandleOnChangeValue(
                          {
                            DueDate: dueDate?.toISOString(),
                          },
                          setObject
                        );
                      }
                    })(),
                ])
              }
              items={
                form === 'quotations'
                  ? `leads/Name`
                  : form === 'sales-invoices'
                    ? `companies/Name`
                    : `vendors/Name`
              }
              name="DocumentTo"
              span={2}
            />
            {form != 'quotations' && (
              <DropDownGREYERP
                name="Source"
                label="Source Document"
                postBackitems={SourceList}
                postBackSelectedValue={Source}
                onChange={(e, selectedValue) =>
                  HandleOnChangeValue({ Source: selectedValue?.ID }, setObject, [
                    GetAndSetList(
                      `getCustomPostBackItem/${
                        form === 'sales-invoices'
                          ? selectedValue?.Name?.startsWith('QUO')
                            ? 'quotationdetails'
                            : 'salesorderdetails'
                          : 'purchaseorderdetails'
                      }/${localStorage.getItem('username')}/${localStorage.getItem(
                        'client'
                      )}/${parseInt(selectedValue?.ID)}`,
                      setExistingItemsData,
                      null
                    ),
                  ])
                }
              />
            )}
            <DropDownGREYERP
              postBackSelectedValue={PriceList}
              items={
                form === 'purchase-invoices' ? 'purchasepricelists/name' : 'salespricelists/name'
              }
              name="PriceList"
              onChange={(e, selectedValue) =>
                HandleOnChangeValue({ PriceList: selectedValue?.ID }, setObject)
              }
            />
            <TextBoxGREYERP
              defaultValue={Series ? Series + Number : EditSeries + EditNumber}
              disabled
              required={true}
              name="Number"
              placeholder="Number"
            />
            <Picker
              type="date"
              name={form === 'quotations' ? 'ValidThrough' : 'DueDate'}
              postBackDate={DueDate}
            />
            {form != 'quotations' && (
              <>
                <DropDownGREYERP items={`warehouses/name`} name="Warehouse" />
                <DropDownGREYERP postBackitems={Sections} name="Section" />
                <DropDownGREYERP items={`costcenters/name`} name="CostCenter" />
                <DropDownGREYERP items={`Projects/name`} name="Milestone" />
                <DropDownGREYERP name="RelatedToID" label="Related To" items={`relatedto/name`} />
                {form == 'sales-invoices' && (
                  <>
                    <TextBoxGREYERP
                      name="PurchaseOrderNumber"
                      label="Purchase Order"
                      placeholder="Client PO Number"
                    />
                    <Picker type="date" name="PurchaseOrderDate" />
                  </>
                )}
              </>
            )}
            <TextBoxGREYERP name="Subject" placeholder="Document Subject" />
            <TextBoxGREYERP name="Reference" placeholder="Document Reference" />
            <TextBoxGREYERP
              name="Comments"
              multiline={true}
              placeholder="Internal Notes"
              span={2}
            />
            <SwithGREYERP name={'Print'} />
          </>
        }
      />
    </div>
  );
}
