import { useEffect } from 'react';
import { CardPanel } from 'src/controls/cardpanel';
import { DropDownGREYERP } from 'src/controls/dropdown';
import { TextBoxGREYERP } from 'src/controls/textbox';
import { ItemsComponent } from 'src/controls/items/ItemsComponent';
import Picker from 'src/controls/picker';
import { useContext } from 'react';
import { FormContext } from 'src/layouts/dashboard/DashboardLayout';
import { GetAndSetList, GetVoucherNumber, getClientName } from 'src/data/scripts';

export default function StockTransfer() {
  
  const { object,setObject, objectEdit, setExistingItemsData } = useContext(FormContext);
  const { Series = '', Number = '' } = object || {};
  const { Series: EditSeries = '', Number: EditNumber = '' } = objectEdit || {};


  useEffect(() => {
    GetAndSetList(
      `getCustomPostBackItem/adjustments/${localStorage.getItem(
        'username'
      )}/${localStorage.getItem('client')}`,
      setExistingItemsData,
      null
    )
    if (isNaN(window.location.pathname.split('/')[3])) {
      GetVoucherNumber(getClientName(), setObject)
    }
  }, []);

  return (
    <>
      <CardPanel
        heading="Fields"
        cardItem={
          <>
            <Picker type='date'
              required={true}
              date={new Date()}
              name="Date"
              type="date"
              focus={true}
            />
            <TextBoxGREYERP required={true} name="Subject" placeholder="Stock Transfer Subject" />

            <TextBoxGREYERP
              required={true}
              name="Reference"
              placeholder="Stock Transfer Reference"
            />

            <DropDownGREYERP required={true} items={`warehouses/name`} name="Source" />

            <TextBoxGREYERP
             defaultValue={Series ? Series + Number : EditSeries + EditNumber}
              disabled
              required={true}
              name="Number"
              placeholder="Stock Transfer Number"
            />

            <DropDownGREYERP items={`warehouses/name`} required={true} name="Destination" />

            <TextBoxGREYERP name="Comments" multiline={true} placeholder="Internal Notes" />
          </>
        }
      />

      <ItemsComponent
        formType="saleable"
        disableCalculation={true}
        disableQuantity={false}
        appendCTA={true}
        columnsToAppend={{ Item: 0, Quantity: 0, Note: '' }}
        columnsToShow={{ Item: 0, Quantity: 0, Unit: 0, Cost: 0, Price: 0, Note: '' }}
        disabledColumns={['COST', 'PRICE', 'UNIT']}
      />

    </>
  );
}
