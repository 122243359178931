import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { useSettingsContext } from '../../components/settings';
import { FormContext } from './DashboardLayout';
import { useContext } from 'react';
import useResponsive from 'src/hooks/useResponsive';

Main.propTypes = {
  sx: PropTypes.object,
  children: PropTypes.node,
};

export default function Main({ children, sx, id, ...other }) {
  const { themeLayout } = useSettingsContext();
  const { displayPrint } = useContext(FormContext);
  const isNavHorizontal = themeLayout === 'horizontal';
  const isDesktop = useResponsive('up', 'lg');
  const isNavMini = themeLayout === 'mini';

  // if (isNavHorizontal) {
    
  return (
    <Box
      component="main"
      sx={{
        px: 2,
        pt: !displayPrint && isDesktop ? '155px' : '130px',
        pb: '0px',
      }}
    >
      {children}
    </Box>
  );
 
}
