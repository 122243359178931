import { CardPanel } from 'src/controls/cardpanel';
import { DropDownGREYERP } from 'src/controls/dropdown';
import { ItemsComponent } from 'src/controls/items/ItemsComponent';
import { TextBoxGREYERP } from 'src/controls/textbox';

export default function AssetMaintenance() {
  return (
    <>
      <CardPanel
        heading="Asset Details"
        cardItem={
          <>
            <DropDownGREYERP items={`assets/name`}  name="Asset" required={true}/>
              <TextBoxGREYERP  placeholder="Description" name="Description" span={2} multiline={true} />
            <TextBoxGREYERP  placeholder="Internal Notes" multiline={true}  span={2} name="Comments" />
          </>
        }
      />
       <div id="divAssetmaintenancedetails" className="divAssetmaintenancedetails">
       <ItemsComponent
       heading="Asset Maintenances"
       tablename="assetmaintenancedetails"
         disableCalculation={true}
         disableQuantity={true}
         columnsToShow={{
           Name:'',
           Description:'',
           Employee:0,
           Interval:0,
           NextDueDate:'',
        
         }}
       />
     </div>
     </>
  );
}
