import { DropDownGREYERP } from 'src/controls/dropdown';
import { TextBoxGREYERP } from 'src/controls/textbox';
import PopUpPanel from 'src/controls/popuppanel';
export default function Operation() {
    return (
        <>
         <PopUpPanel heading={'Add'}
        child={
          <>
            <TextBoxGREYERP  name="Name"  span={2} placeholder="Operation Name" required={true} focus={true}/>
            <TextBoxGREYERP  name="Duration" sufix='mins'  type="Number" step="any" />
            <DropDownGREYERP name="WorkCenter" items={`workcenters/name`}  />
            <TextBoxGREYERP  name="Description" multiline={true} span={2}  MultiLine={true}/>
            <TextBoxGREYERP  name="Comments" multiline={true} span={2} placeholder="Comments (Optional)"  MultiLine={true}/>
            </>
   }
   />
 </>
);
}