import { DropDownGREYERP } from 'src/controls/dropdown';
import { TextBoxGREYERP } from 'src/controls/textbox';
import PopUpPanel from 'src/controls/popuppanel';
import { SwithGREYERP } from 'src/controls/swithgrey';

export default function CostCenters() {
  return (
    <>
      <PopUpPanel
        heading={'Add'}
        child={
          <>
            <TextBoxGREYERP span={2} name="Name" placeholder="Name" required={true} />
            <DropDownGREYERP
              items={`costcenters/name`}
              firstItem={'Main,0'}
              span={1.5}
              name="Parent"
              required={true}
            />
            <SwithGREYERP name="Status" span={0.5} />
            <TextBoxGREYERP
              span={2}
              name="Comments"
              multiline={true}
              placeholder="Comments (Optional)"
              type="Internal Notes"
            />
          </>
        }
      />
    </>
  );
}
