import { DropDownGREYERP } from 'src/controls/dropdown';
import { TextBoxGREYERP } from 'src/controls/textbox';
import PopUpPanel from 'src/controls/popuppanel';
export default function WorkCenter() {
    return (
        <>
         <PopUpPanel heading={'Add'}
        child={
          <>
            <TextBoxGREYERP  name="Name"  span={2} placeholder="Work Center Name" required={true} focus={true}/>
            <TextBoxGREYERP  name="ShortName" placeholder="Short Name" maxLen="3" Case="uppercase" required={true} />
            <DropDownGREYERP name="Alternate" placeholder="Alternative Work Center" items={`workcenters/name`}  />
            <TextBoxGREYERP  name="Capacity" type="Number" step="any" />
            <TextBoxGREYERP  name="Efficiency" type="Number" step="any" />
            <DropDownGREYERP name="WorkingHours" required={true} items={`workinghours/name`}  />
            <TextBoxGREYERP name="CostPerHour" type="Number" step="any" />
            <TextBoxGREYERP  name="Description" multiline={true} span={2}  MultiLine={true}/>
            <TextBoxGREYERP  name="Comments" multiline={true} span={2} placeholder="Comments (Optional)"  MultiLine={true}/>
            </>
   }
   />
 </>
);
}