/* eslint-disable */
import FullCalendar from '@fullcalendar/react'; // => request placed at the top
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import timelinePlugin from '@fullcalendar/timeline';
//
import { useState, useRef, useEffect, useCallback, useContext } from 'react';

// @mui
import {
  Card,
  Button,
  Container,
  DialogTitle,
  Dialog,
  Checkbox,
  Typography,
  Stack,
} from '@mui/material';
// redux

import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/CustomBreadcrumbs';
import StyledCalendar from './styles';
import CalendarToolbar from './CalendarToolbar';
import CalendarForm from './CalendarForm';
import CalendarFilterDrawer from './CalendarFilterDrawer';
import { fTimestamp } from 'src/utils/formatTime';
import { useSnackbar } from 'notistack';
import Iconify from 'src/components/iconify/Iconify';
import { FormContext } from 'src/layouts/dashboard/DashboardLayout';
import useResponsive from 'src/hooks/useResponsive';
import { HttpRequest } from 'src/data/scripts';
// components

// ----------------------------------------------------------------------

const COLOR_OPTIONS = [
  '#00AB55', // theme.palette.primary.main,
  '#1890FF', // theme.palette.info.main,
  '#54D62C', // theme.palette.success.main,
  '#FFC107', // theme.palette.warning.main,
  '#FF4842', // theme.palette.error.main
  '#04297A', // theme.palette.info.darker
  '#7A0C2E', // theme.palette.error.darker
];

// ----------------------------------------------------------------------

export default function CalendarPage({ PopupComponent }) {
  const {
    setPopupEditId,
    setOpenDrawer,
    openDrawer,
    dataFetched,
    setDataFetched,
    calenderDate,
    setObjectEdit,
  } = useContext(FormContext);

  const toggleDrawer = (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
  };
  const urlList = window.location.pathname.split('/');
  const { enqueueSnackbar } = useSnackbar();

  const calendarRef = useRef(null);

  // const events = useGetEvents();

  const [events, setEvents] = useState([]);

  const [openForm, setOpenForm] = useState(false);

  const [selectedEventId, setSelectedEventId] = useState(null);

  const [selectedRange, setSelectedRange] = useState(null);

  const isDesktop = useResponsive('up', 'lg');
  const { popupItems, setPopupItems } = useContext(FormContext);

  const [date, setDate] = useState(new Date());

  const [openFilter, setOpenFilter] = useState(false);

  const [filterEventColor, setFilterEventColor] = useState([]);

  const [view, setView] = useState(isDesktop ? 'timeGridWeek' : 'listWeek');

  // useEffect(() => {
  //   if(calenderDate){
  //     setDate(calenderDate);
  //   }

  // }, [calenderDate]);

  useEffect(() => {
    HttpRequest(`getRecords/events/${localStorage.getItem('username')}`)
      .then((response) => response)
      .then((json) => {
        setDataFetched(true);
        setEvents(json);
      });
  }, [dataFetched]);

  useEffect(() => {
    const calendarEl = calendarRef.current;
    if (calendarEl) {
      const calendarApi = calendarEl.getApi();

      let calview = localStorage.getItem('calenderview') ?? 'timeGridWeek';
      const newView = isDesktop ? calview : 'listWeek';
      calendarApi.changeView(newView);
      setView(newView);
    }
  }, [isDesktop, popupItems]);

  const handleOpenModal = () => {
    setOpenForm(true);
  };

  const handleCloseModal = () => {
    setOpenForm(false);
    setSelectedRange(null);
    setSelectedEventId(null);
  };

  const handleClickToday = () => {
    const calendarEl = calendarRef.current;
    if (calendarEl) {
      const calendarApi = calendarEl.getApi();

      calendarApi.today();
      setDate(calendarApi.getDate());
    }
  };

  const handleChangeView = (newView) => {
    const calendarEl = calendarRef.current;
    if (calendarEl) {
      const calendarApi = calendarEl.getApi();

      localStorage.setItem('calenderview', newView);
      calendarApi.changeView(newView);
      setView(newView);
    }
  };

  const handleClickDatePrev = () => {
    const calendarEl = calendarRef.current;
    if (calendarEl) {
      const calendarApi = calendarEl.getApi();

      calendarApi.prev();
      setDate(calendarApi.getDate());
    }
  };

  const handleClickDateNext = () => {
    const calendarEl = calendarRef.current;
    if (calendarEl) {
      const calendarApi = calendarEl.getApi();

      calendarApi.next();
      setDate(calendarApi.getDate());
    }
  };

  const handleSelectRange = (arg) => {
    setObjectEdit({
      startDateTime: arg.start,
      endDateTime: arg.end,
    });
    setOpenDrawer(true);
    //handleOpenModal();

    setSelectedRange({
      start: arg.start,
      end: arg.end,
    });
  };

  const handleSelectEvent = (arg) => {
    setOpenDrawer(true);
    setPopupEditId(arg.event._def.extendedProps.ID);
    // handleOpenModal();
    setSelectedEventId(arg.event._def.extendedProps.ID);
  };

  const handleResizeEvent = ({ event }) => {
    try {
      dispatch(
        updateEvent(event.id, {
          allDay: event.allDay,
          start: event.start,
          end: event.end,
        })
      );
    } catch (error) {
      console.error(error);
    }
  };

  const handleDropEvent = ({ event }) => {
    try {
      dispatch(
        updateEvent(event.id, {
          allDay: event.allDay,
          start: event.start,
          end: event.end,
        })
      );
    } catch (error) {
      console.error(error);
    }
  };

  const handleCreateUpdateEvent = (newEvent) => {
    if (selectedEventId) {
      dispatch(updateEvent(selectedEventId, newEvent));
      enqueueSnackbar('Update success!');
    } else {
      dispatch(createEvent(newEvent));
      enqueueSnackbar('Create success!');
    }
  };

  const handleDeleteEvent = () => {
    try {
      if (selectedEventId) {
        handleCloseModal();
        dispatch(deleteEvent(selectedEventId));
        enqueueSnackbar('Delete success!');
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleFilterEventColor = (eventColor) => {
    const checked = filterEventColor.includes(eventColor)
      ? filterEventColor.filter((value) => value !== eventColor)
      : [...filterEventColor, eventColor];

    setFilterEventColor(checked);
  };

  const handleResetFilter = () => {
    const { setStartDate, setEndDate } = picker;

    if (setStartDate && setEndDate) {
      setStartDate(null);
      setEndDate(null);
    }

    setFilterEventColor([]);
  };

  const dataFiltered = applyFilter({
    inputData: events,
    filterEventColor,
    // filterStartDate: picker.startDate,
    // filterEndDate: picker.endDate,
    // isError: !!picker.isError,
  });

  const validRange = () => {
    return {
      start: calenderDate,
    };
  };

  return (
    <>
      {/* handleOpenModal */}
      <Card
        sx={{
          '.fc-event-main': {
            color: '#1890FF !important',
          },
          '.fc .fc-daygrid-day.fc-day-today': {
            backgroundColor: 'white !important',
          },
          '.fc .fc-timegrid-col.fc-day-today': {
            backgroundColor: 'white !important',
          },
          '.fc-scroller-harness-liquid': {
            // height:'200px !important'
          },
          height: 'calc(100vh - 202px)',
        }}
      >
        <StyledCalendar>
          <CalendarToolbar
            date={date}
            view={view}
            onNextDate={handleClickDateNext}
            onPrevDate={handleClickDatePrev}
            onToday={handleClickToday}
            onChangeView={handleChangeView}
            onOpenFilter={() => setOpenFilter(true)}
          />

          <FullCalendar
            weekends
            editable
            selectable
            rerenderDelay={10}
            allDayMaintainDuration
            eventResizableFromStart
            ref={calendarRef}
            initialDate={date}
            initialView={view}
            dayMaxEventRows={3}
            eventDisplay="block"
            events={dataFiltered}
            headerToolbar={false}
            //validRange={calenderDate && validRange()}
            //initialEvents={events}

            select={handleSelectRange}
            eventClick={handleSelectEvent}
            height={isDesktop ? 540 : 'auto'}
            plugins={[listPlugin, dayGridPlugin, timelinePlugin, timeGridPlugin, interactionPlugin]}
          />
        </StyledCalendar>
      </Card>

      {/* <Dialog fullWidth maxWidth="xs" open={openForm} onClose={handleCloseModal}>
        <DialogTitle>{selectedEvent ? 'Edit Event' : 'Add Event'}</DialogTitle>

        <CalendarForm
          event={selectedEvent}
          range={selectedRange}
          onCancel={handleCloseModal}
          onCreateUpdateEvent={handleCreateUpdateEvent}
          onDeleteEvent={handleDeleteEvent}
          colorOptions={COLOR_OPTIONS}
        />
      </Dialog> */}
      {/* 
      <CalendarFilterDrawer
        events={events}
        //picker={picker}
        openFilter={openFilter}
        colorOptions={COLOR_OPTIONS}
        onResetFilter={handleResetFilter}
        filterEventColor={filterEventColor}
        onCloseFilter={() => setOpenFilter(false)}
        onFilterEventColor={handleFilterEventColor}
        onSelectEvent={(eventId) => {
          if (eventId) {
            handleOpenModal();
            setSelectedEventId(eventId);
          }
        }}
      /> */}

      {openDrawer && PopupComponent}
    </>
  );
}

// ----------------------------------------------------------------------

const useGetEvents = () => {
  const getAllEvents = useCallback(() => {
    dispatch(getEvents());
  }, [dispatch]);

  useEffect(() => {
    getAllEvents();
  }, [getAllEvents]);

  const events = data.map((event) => ({
    ...event,
    textColor: event.color,
  }));

  return events;
};

// ----------------------------------------------------------------------

function applyFilter({ inputData, filterEventColor, filterStartDate, filterEndDate, isError }) {
  const stabilizedThis = inputData?.map((el, index) => [el, index]);

  inputData = stabilizedThis?.map((el) => el[0]);

  if (filterEventColor?.length) {
    inputData = inputData?.filter((event) => filterEventColor?.includes(event?.color));
  }

  if (filterStartDate && filterEndDate && !isError) {
    inputData = inputData?.filter(
      (event) =>
        fTimestamp(event?.start) >= fTimestamp(filterStartDate) &&
        fTimestamp(event?.end) <= fTimestamp(filterEndDate)
    );
  }

  return inputData;
}

export function CustomView(props) {
  return (
    <Stack direction="row" alignItems={'center'}>
      <Checkbox
        disableRipple
        checked={props.event._def.extendedProps.isCompleted ? true : false}
        icon={<Iconify icon="eva:radio-button-off-outline" />}
        checkedIcon={<Iconify icon="eva:checkmark-circle-2-outline" />}
        onChange={(i) => console.log(i)}
        //sx={{ position: 'absolute', bottom: 16, left: 8 }}
      />
      <Typography sx={{ color: 'black' }}>{props.event._def.title}</Typography>
    </Stack>
  );
}
