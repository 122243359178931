import { CardPanel } from 'src/controls/cardpanel';
import { DropDownGREYERP } from 'src/controls/dropdown';
import { DropDownENUM } from 'src/controls/dropdownenum';
import ImageContainer from 'src/controls/imagecontainer';
import { TextBoxGREYERP } from 'src/controls/textbox';
import UploadGREY from 'src/controls/uploadgrey';
import { CompanyTypes, PaymentTerms } from 'src/data/data';

export default function Vendor() {
  return (
    <>
      <CardPanel
        heading="Vendor Details"
        cardItem={
          <>
            <UploadGREY />
            <ImageContainer>
              <DropDownENUM items={CompanyTypes} required={true} name="Type" focus={true} />
              <TextBoxGREYERP name="Name" span={2} placeholder="Vendor Name" required={true} />
              <DropDownGREYERP items="vendorgroups/name" firstItem="0,None" name="VendorGroup" label="Group" />
              <TextBoxGREYERP name="Reference" placeholder="Vendor Reference" />
              <DropDownGREYERP items={`currencies/name`} name="Currency" required={true} />
              <TextBoxGREYERP label="Current Balance" name="Balance" placeholder="00.000" type="Number" step="any" required={true} />
              <DropDownGREYERP items={`purchasepricelists/name`}  name="PriceList" />
              <DropDownENUM items={PaymentTerms} name="PaymentTerms" required={true} />
              <TextBoxGREYERP name="TaxNumber" placeholder="VAT Number" />
              <TextBoxGREYERP name="Comments" multiline={true} placeholder="Internal Notes" span={2} />
            </ImageContainer>
          </>
        }
      />
      <CardPanel
        heading="Contact Details"
        cardItem={
          <>
            <TextBoxGREYERP label="Person Name" required={true} name="Person" placeholder="Smith" />
            <TextBoxGREYERP name="Office" placeholder="009682465XXXX" type="Number" />
            <TextBoxGREYERP label="Ex" name="Extension" placeholder="ex" type="Number" />
            <TextBoxGREYERP required={true} name="Phone" placeholder="009689461XXXX" type="Number" title="Contact GSM Number"/>
            <TextBoxGREYERP name="Phone2" placeholder="009689461XXXX" type="Number" title="Alternate GSM Number" />
            <TextBoxGREYERP name="Email" placeholder="smith@abc.com" type="Email" />
            <TextBoxGREYERP name="Website" placeholder="www.google.com" type="Url" />
            <TextBoxGREYERP name="Location" placeholder="Ghala" />
            <TextBoxGREYERP name="Address" placeholder="Rusayl Industrial Estate, Road No... " multiline={true} span={2} />
          </>
        }
      />
      <CardPanel
        heading="Bank Details"
        cardItem={
          <>
            <TextBoxGREYERP name="BankAccountNumber" placeholder="100250698720" />
            <TextBoxGREYERP name="BankAccountName" placeholder="Rawat AL Makan LLC" />
            <TextBoxGREYERP name="SwiftCode" placeholder="Swift Code" />
            <TextBoxGREYERP name="SortCode" placeholder="SORT Code" />
            <TextBoxGREYERP name="Iban" placeholder="IBAN" />
            <TextBoxGREYERP name="Bank" placeholder="Bank Muscat" />
            <TextBoxGREYERP name="Branch" placeholder="Ruwi" />
            <TextBoxGREYERP name="BankAddress" placeholder="Way# XXX, Ghala, Muscat..." multiline={true} span={2} />
          </>
        }
      />
    </>
  );
}
