import { DropDownGREYERP } from 'src/controls/dropdown';
import { TextBoxGREYERP } from 'src/controls/textbox';
import UploadGREY from 'src/controls/uploadgrey';
import { SwithGREYERP } from 'src/controls/swithgrey';
import { CardPanel } from 'src/controls/cardpanel';
import { TextEditorGrey } from 'src/controls/texteditorgrey';
import Picker from 'src/controls/picker';

export default function Blog() {
  return (
    <>
      <CardPanel
        heading="Blog Image"
        cardItem={
          <>
            <UploadGREY width="100%" height="300px" borderRadius="0" span={5} name="Cover" />
          </>
        }
      />
      <CardPanel
        heading="Blog Details"
        cardItem={
          <>
            <TextBoxGREYERP
              name="Title"
              label="Blog Title"
              placeholder="Blog Title"
              MultiLine={true}
              required={true}
              span={2.5}
              focus={true}
            />
            <TextBoxGREYERP
              label="Blog Title Arabic"
              span={2.5}
              name="TitleArabic"
              placeholder="عنوان"
              MultiLine={true}
              dir="rtl"
            />
            <TextBoxGREYERP
              name="SubTitle"
              label={'Blog Sub Title'}
              span={2.5}
              placeholder="Blog Sub Title"
              MultiLine={true}
            />
            <TextBoxGREYERP
              name="SubTitleArabic"
              label={'Blog Sub Title Arabic'}
              span={2.5}
              placeholder="الترجمة"
              MultiLine={true}
              dir="rtl"
            />
            <TextBoxGREYERP
              name="Description"
              span={2.5}
              multiline={true}
              placeholder="Blog Description"
              MultiLine={true}
            />
            <TextBoxGREYERP
              name="DescriptionArabic"
              placeholder="وصف"
              MultiLine={true}
              dir="rtl"
              span={2.5}
            />
          </>
        }
      />
      <CardPanel
        heading="Content"
        cardItem={
          <>
            <TextEditorGrey name="Content" placeholder="Content" />
            <TextEditorGrey name="ContentArabic" placeholder="Content Arabic" dir="right" />
          </>
        }
      />
      <CardPanel
        heading="SEO Details"
        cardItem={
          <>
            <TextBoxGREYERP
              name="PageTitle"
              label={'Meta Title'}
              span={2.5}
              placeholder="Title Tag"
            />
            <TextBoxGREYERP
              name="PageTitleArabic"
              label={'Meta Title Arabic'}
              span={2.5}
              placeholder="علامة العنوان"
              dir="rtl"
            />
            <TextBoxGREYERP name="PageURL" label={'Page URL'} span={2.5} placeholder="Page URL" />
            <TextBoxGREYERP
              name="PageURLArabic"
              label={'Page URL Arabic'}
              span={2.5}
              placeholder="رابط الصفحة"
              dir="rtl"
            />
            <TextBoxGREYERP name="MetaKeywords" placeholder="Meta Keywords" span={2.5} />
            <TextBoxGREYERP
              name="MetaKeywordsArabic"
              placeholder="كلمات دلالية"
              dir="rtl"
              span={2.5}
            />
            <TextBoxGREYERP
              name="MetaDescription"
              placeholder="Meta Description"
              MultiLine={true}
              span={5}
            />
            <TextBoxGREYERP
              name="MetaDescriptionArabic"
              placeholder="ميتا الوصف"
              MultiLine={true}
              span={5}
              dir="rtl"
            />
          </>
        }
      />
      <CardPanel
        heading="Other Details"
        cardItem={
          <>
            <Picker type="date" name="Date" placeholder="Posted Date"></Picker>
            <DropDownGREYERP
              firstItem="0,Main"
              items={`blogcategories/name`}
              name="Category"
              required={true}
            />
            <TextBoxGREYERP name="Author" placeholder="Blog Author" />
            <TextBoxGREYERP name="CallToAction" placeholder="Call To Action" />
            <TextBoxGREYERP
              name="Sort"
              placeholder="Sort"
              type="Number"
              required={true}
              span={0.5}
            />
            <SwithGREYERP name="Status" span={0.5} />
          </>
        }
      />
    </>
  );
}
