import React from 'react';
import { useTranslation } from 'react-i18next';

const Test = () => {
  const { t } = useTranslation();

  return (
    <div>
      <h1>hello</h1>
      <p>{t('key', { count: 0 })}</p>
      <p>{t('key', { count: 1 })}</p>
      <p>{t('key', { count: 99 })}</p>
    </div>
  );
};

export default Test;
