import { DropDownGREYERP } from 'src/controls/dropdown';
import { TextBoxGREYERP } from 'src/controls/textbox';
import UploadGREY from 'src/controls/uploadgrey';
import { SwithGREYERP } from 'src/controls/swithgrey';
import { CardPanel } from 'src/controls/cardpanel';
import { Grid } from '@mui/material';
import { TextEditorGrey } from 'src/controls/texteditorgrey';

export default function Service() {
  const urlList = window.location.pathname.split('/');
  return (
    <>
            <CardPanel
        heading="Service Details"
        cardItem={
          <>
            <UploadGREY />
            <Grid container md={3} sx={{maxWidth:"80% !important",flexBasis:"80% !important"}} spacing={2} rowSpacing={1} columns={{ xs: 3, sm:3 , md: 4, lg:4 }}>
            <TextBoxGREYERP name="Name" placeholder="Service Name" required={true} focus={true}/>
            <TextBoxGREYERP name="NameArabic" placeholder="اسم المنتج"  dir="rtl"/>
            <TextBoxGREYERP name="Price" placeholder="10.300" type="Number"  step="any" required={true} />
            <TextBoxGREYERP  name="Sort" placeholder="Sort" type="Number" required={true} span={.5}/>
            <SwithGREYERP  name="Status" span={.5} />
            </Grid>
          </>
        }
      />
      <CardPanel
        heading="Other Details"
        cardItem={
          <>
         <TextBoxGREYERP  name="Icon" placeholder="Service Icon"/>
         <DropDownGREYERP firstItem="0,Main" items={`servicecategories/name`} name="Category"  required={true} />
         <TextBoxGREYERP  name="Hyperlink" placeholder="Redirection Link" type="Url"/>
          <TextBoxGREYERP  name="HyperlinkArabic" placeholder="Redirection Link Arabic" type="Url"/>
         <TextBoxGREYERP  name="Description" span={2} multiline={true} placeholder="Service Description"  MultiLine={true}  />
         <TextBoxGREYERP  name="DescriptionArabic" placeholder="تفاصيل المنتج"  MultiLine={true} dir="rtl"  span={2}/>
          <TextBoxGREYERP  placeholder="Internal Notes" name="Comments" multiline={true}   MultiLine={true}  span={2}/>
          </>
        }
      />    

<CardPanel
        heading="Content"
        cardItem={
          <>
            <TextEditorGrey name="Content" placeholder="Content"/>
            <TextEditorGrey name="ContentArabic" placeholder="المحتوى" dir="right"   />
          </>
        }
      />      
</>
);
}