import {
  Button,
  Card,
  DialogActions,
  Divider,
  Drawer,
  Grid,
  IconButton,
  Popover,
  Tooltip,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { alpha } from '@mui/material/styles';
import palette from 'src/theme/palette';
import { useContext, useEffect, useRef, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import Iconify from 'src/components/iconify/Iconify';
import { FormContext } from 'src/layouts/dashboard/DashboardLayout';
import { useNavigate } from 'react-router';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import { id } from 'date-fns/locale';
import useResponsive from 'src/hooks/useResponsive';
import { useFormData } from 'src/hooks/useQueryHooks';
import { useQuery } from '@tanstack/react-query';
import { HttpRequest } from 'src/data/scripts';
import { useTranslation } from 'react-i18next';

export default function PopUpPanel({ heading, child, openPopup = false }) {
  const { enqueueSnackbar } = useSnackbar();

  const {
    submitForm,
    popupEditId,
    openDrawer,
    setOpenDrawer,
    setPopupEditId,
    dataFetched,
    setDataFetched,
    taskData,
    setTaskData,
    loading,
    loadingNew,
  } = useContext(FormContext);
  const { t } = useTranslation();

  let form = window.location.pathname?.split('/')[2]?.replaceAll('-', '');

  const { data: objectEdit } = useQuery({
    queryKey: [form, popupEditId],
    queryFn: async () => {
      if (popupEditId) {
        return await HttpRequest(`getRecordById/${form}/${popupEditId}`);
      } else {
        return {};
      }
    },
    enabled: !!popupEditId,
    select: (res) => res[0],
  });

  let tasksForm = window.location.pathname.split('/')[2].toLowerCase() == 'tasks';
  //const [openDrawer, setOpenDrawer] = useState(true);
  const toggleDrawer = (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setPopupEditId(null);
    setOpenDrawer(false);
  };

  const { closeSnackbar } = useSnackbar();
  const handleAfterDelete = () => {
    closeSnackbar();
    setOpenDrawer(false);
    setDataFetched(false);
    if (tasksForm) {
      setTaskData((prev) => {
        let columns = prev.board.columns;
        for (const key in columns) {
          let cardIdsNewList = columns[key].cardIds.filter((id) => id != popupEditId);
          columns[key].cardIds = cardIdsNewList;
        }
        let user = localStorage.getItem('username');
        let colOrderinLS = JSON.parse(localStorage.getItem('tasksort'));
        colOrderinLS[user] = columns;
        localStorage.setItem('tasksort', JSON.stringify(colOrderinLS));
        return {
          ...prev,
          ['board']: {
            ['cards']: prev.board.cards,
            ['columnOrder']: prev.board.columnOrder,
            ['columns']: columns,
          },
        };
      });
    }
    enqueueSnackbar(t('Record Deleted'));
  };
  const navigate = useNavigate();
  const [Module, setModule] = useState();
  const [Form, setForm] = useState();

  useEffect(() => {
    const urlList = window.location.pathname.split('/');
    setModule(urlList[1].charAt(0).toUpperCase() + urlList[1].slice(1));
    setForm(urlList[2].charAt(0).toUpperCase() + urlList[2].slice(1));
    if (urlList[3] && urlList[3].toUpperCase() == 'NEW') {
      setOpenDrawer(true);
      return;
    }
  }, [
    window.location.pathname.split('/')[1],
    window.location.pathname.split('/')[2],
    popupEditId,
    objectEdit,
  ]);
  const formRefPopup = useRef();
  const isDesktop = useResponsive('up', 'lg');

  return (
    <>
      <Popover
        sx={{
          backgroundColor: alpha('#212B36', 0.4),
          '& .MuiPaper-root': {
            width: isDesktop ? '40%' : '100%',
            mt: 7,
          },
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={openDrawer || openPopup}
        onClose={toggleDrawer}
        // sx={{
        //   '.MuiPaper-root': { height: 'auto', top: '70px', right: '10px', borderRadius: '8px' },
        // }}
      >
        <Box sx={{}} component={'form'} ref={formRefPopup}>
          <Grid container direction="column">
            <Box sx={{ pt: 4, pl: 4, pr: 2 }}>
              <Grid container justifyContent={'space-between'}>
                <Grid item>
                  <Typography variant="h6" sx={{ textTransform: 'capitalize' }} paragraph>
                    {popupEditId
                      ? `Edit ${window.location.pathname
                          .split('/')[2]
                          .slice(0, -1)
                          .replaceAll('-', ' ')}
     `
                      : `Add ${window.location.pathname
                          .split('/')[2]
                          .slice(0, -1)
                          .replaceAll('-', ' ')}`}
                  </Typography>
                </Grid>
                {/* <Grid item>
                <CloseIcon onClick={toggleDrawer} />
              </Grid> */}
              </Grid>
            </Box>
            <Grid
              container
              direction={'row'}
              sx={{ pl: 3, pr: 3 }}
              spacing={2}
              rowSpacing={1}
              columns={{ xs: 2, sm: 2, md: 2, lg: 2 }}
            >
              {child}
            </Grid>

            <Grid container justifyContent={'space-between'} sx={{ px: 2, pb: 4, pt: 5.5 }}>
              <Grid item md={1}>
                {popupEditId && (
                  <Tooltip title="Delete Record">
                    <IconButton
                      onClick={() => {
                        enqueueSnackbar('Do you want to proceed?', {
                          variant: 'warning',
                          autoHideDuration: 25000,
                          action: (key) => (
                            <>
                              <Button
                                color="secondary"
                                size="small"
                                onClick={async () => {
                                  await HttpRequest(
                                    `${window.location.pathname
                                      .split('/')[2]
                                      ?.replaceAll('-', '')}/${popupEditId}`,
                                    'DELETE'
                                  ).then(handleAfterDelete);
                                }}
                              >
                                {t('Yes')}
                              </Button>
                              <Button
                                color="inherit"
                                size="small"
                                onClick={() => {
                                  closeSnackbar(key);
                                }}
                              >
                                {t('No')}
                              </Button>
                            </>
                          ),
                        });
                      }}
                    >
                      <Iconify icon="eva:trash-2-outline" />
                    </IconButton>
                  </Tooltip>
                )}
              </Grid>

              <Grid container spacing={1} direction={'row'} justifyContent={'flex-end'} md={11}>
                <Grid item>
                  <LoadingButton
                    onClick={(e) => {
                      e.preventDefault();
                      if (formRefPopup.current.reportValidity()) {
                        submitForm(e);
                      }
                    }}
                    disabled={loadingNew}
                    accessKey="s"
                    loading={loading}
                    sx={{
                      mr: 1,
                      textTransform: 'none',
                      textUnderlineOffset: 2.5,
                      display: { xs: 'none', md: 'block' },
                    }}
                    variant="contained"
                  >
                    {t('Save')}
                  </LoadingButton>
                </Grid>
                <Grid item>
                  <LoadingButton
                    accessKey="a"
                    onClick={(e) => {
                      e.preventDefault();
                      if (formRefPopup.current.reportValidity()) {
                        submitForm(e);
                      }
                    }}
                    loading={loadingNew}
                    disabled={loading}
                    variant="contained"
                    sx={{
                      mr: 1,
                      textTransform: 'none',
                      textUnderlineOffset: 2.5,
                      display: { xs: 'none', md: 'block' },
                    }}
                  >
                    {t('Save & Add')}
                  </LoadingButton>
                </Grid>
                <Grid item>
                  <Button
                    accessKey="c"
                    onClick={toggleDrawer}
                    variant="contained"
                    sx={{
                      mr: 1,
                      textTransform: 'none',
                      textUnderlineOffset: 2.5,
                      display: { xs: 'none', md: 'block' },
                    }}
                    color="inherit"
                  >
                    {t('Cancel')}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Popover>
    </>
  );
}
