import { DropDownGREYERP } from 'src/controls/dropdown';
import { TextBoxGREYERP } from 'src/controls/textbox';
import PopUpPanel from 'src/controls/popuppanel';
export default function Warehouse() {
    return (
        <>
         <PopUpPanel heading={'Add'}
        child={
          <>
            <TextBoxGREYERP  name="Name"  span={2} placeholder="Warehouse Name" required={true} focus={true}/>
            <TextBoxGREYERP  name="ShortName" placeholder="Short Name" maxLen="3" Case="uppercase" required={true} />
            <DropDownGREYERP  name="Person" label="In Charge" items={`clientusers/name`}  width="6" />
            <TextBoxGREYERP name="Street1" span={2} placeholder="Street Name 1"  />
            <TextBoxGREYERP name="Street2" span={2} placeholder="Street Name 2"  />
            <TextBoxGREYERP  name="City" placeholder="City Name" />
            <TextBoxGREYERP  name="State" placeholder="State Name" />
            <TextBoxGREYERP  name="PostalCode" placeholder="Postal Code" />
            <TextBoxGREYERP  name="POBox" placeholder="PO Box Number" />
            <TextBoxGREYERP  name="Comments" multiline={true} span={2} placeholder="Comments (Optional)"  MultiLine={true}/>
            </>
   }
   />
 </>
);
}