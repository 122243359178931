import { alpha, styled } from '@mui/material/styles';
import {
  Box,
  Card,
  Grid,
  Table,
  Divider,
  TableRow,
  TableBody,
  TableHead,
  TableCell,
  Typography,
  TableContainer,
  Button,
  Popover,
  Stack,
  Tooltip,
  IconButton,
  Popper,
  Grow,
  ClickAwayListener,
  MenuList,
  MenuItem,
  ListItemIcon,
  Menu,
} from '@mui/material';

import { useContext, useEffect, useStatem, useRef, useState } from 'react';
import React, { Component } from 'react';

import { useTheme } from '@mui/material/styles';
import { FormContext } from 'src/layouts/dashboard/DashboardLayout';
import { amountInWords, formatdecimals, getTaxCategory, getVoucherNumber } from 'src/data/scripts';
import { useReactToPrint } from 'react-to-print';
import Iconify from 'src/components/iconify/Iconify';
import { TableGREYERP } from 'src/controls/table';

const StyledRowResult = styled(TableRow)(({ theme }) => ({
  '& td': {
    paddingTop: 0,
    paddingBottom: 0,
  },
}));
export default function PrintSalarySlip() {
  const theme = useTheme();
  const [data, setData] = useState();
  const [pageHeader, setPageHeader] = useState();
  const [pageFooter, setPageFooter] = useState();
  const [pageHeading, setPageHeading] = useState();
  const [pageBottom, setPageBottom] = useState();
  const [pageColor, setPageColor] = useState();
  const [apiUrlEarnings, setapiUrlEarnings] = useState();
  const [apiUrlDeductions, setapiUrlDeductions] = useState();
  const [pageFont, setPageFont] = useState();
  const [pageFontSize, setPageFontSize] = useState();

  const [startDate, setstartDate] = useState();
  const [endDate, setendDate] = useState();
  const { object, setObject, objectEdit } = useContext(FormContext);
  const { openPrint, setOpenPrint, recordId, setRecordId } = useContext(FormContext);

  useEffect(() => {
    HttpRequest(`getRecordById/salaryslipprint/${recordId}`)
      .then((response) => response)
      .then((json) => {
        //console.log(json);
        setData(json[0]);
        setstartDate(json[0].StartDate);
        setendDate(json[0].EndDate);
      });

    let documentlayoutsid = 0;

    HttpRequest(`getRecordById/clientdetails/${localStorage.getItem('client')}`)
      .then((response) => response)
      .then((json) => {
        documentlayoutsid = json[0].ReceiptLayout;

        HttpRequest(`getRecordById/documentlayouts/${documentlayoutsid}`)
          .then((response) => response)
          .then((json) => {
            //  console.log(json, 'head');
            setPageHeader(json[0].Header);
            setPageFooter(json[0].Footer);
            setPageHeading(json[0].Heading);
            setPageBottom(json[0].Bottom);
            setPageColor(json[0].color);
            setPageFont(json[0].font);
            setPageFontSize(json[0].fontSize);
          });
      });
    setapiUrlEarnings(`getRecordById/salaryslipearnings/${recordId}`);
    setapiUrlDeductions(`getRecordById/salaryslipdeductions/${recordId}`);
  }, []);

  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <>
      <Popover
        sx={{ backgroundColor: alpha('#212B36', 0.4) }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={openPrint}
        // sx={{
        //   '.MuiPaper-root': { height: 'auto', top: '70px', right: '10px', borderRadius: '8px' },
        // }}
      >
        <Card ref={componentRef} sx={{ pt: 5, px: 3, width: '850px', height: '800px' }}>
          <Grid container alignItems={'center'}>
            <Grid item sx={{ ml: 'auto', displayPrint: 'none' }}>
              <Tooltip title="Print">
                <IconButton onClick={handlePrint}>
                  <Iconify icon="eva:printer-fill" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Close">
                <IconButton onClick={(e) => setOpenPrint(false)}>
                  <Iconify icon="material-symbols:close" />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
          <Grid container>
            <div
              className="ql-editor"
              style={{ width: '100%' }}
              dangerouslySetInnerHTML={{ __html: pageHeader }}
            />
          </Grid>

          <Grid container justifyContent={'center'} alignContent="initial" sx={{ my: 3 }}>
            <Typography variant="h4">Salary Slip for {data?.PayPeriod}</Typography>
          </Grid>

          <Grid container width={'100%'} sx={{ mx: 2 }}>
            <Grid item flexBasis={'50%'}>
              <Stack direction={'row'} justifyContent={'space-around'}>
                <Typography
                  variant="body2"
                  sx={{
                    fontWeight: 'bold',
                    fontSize: pageFontSize,
                    fontFamily: pageFont,
                    textAlign: 'left',
                    width: '50%',
                  }}
                >
                  Employee ID
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: pageFontSize,
                    fontFamily: pageFont,
                    textAlign: 'left',
                    width: '50%',
                  }}
                >
                  : {data && data.EMPID}
                </Typography>
              </Stack>
            </Grid>
            <Grid item flexBasis={'50%'}>
              <Stack direction={'row'} justifyContent={'space-around'}>
                <Typography
                  variant="body2"
                  sx={{
                    fontWeight: 'bold',
                    fontSize: pageFontSize,
                    fontFamily: pageFont,
                    textAlign: 'left',
                    width: '50%',
                  }}
                >
                  Name
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: pageFontSize,
                    fontFamily: pageFont,
                    textAlign: 'left',
                    width: '50%',
                  }}
                >
                  : {data && data.Employee}
                </Typography>
              </Stack>
            </Grid>
            <Grid item flexBasis={'50%'}>
              <Stack direction={'row'} justifyContent={'space-around'}>
                <Typography
                  variant="body2"
                  sx={{
                    fontWeight: 'bold',
                    fontSize: pageFontSize,
                    fontFamily: pageFont,
                    textAlign: 'left',
                    width: '50%',
                  }}
                >
                  Period
                </Typography>
                {console.log(startDate)}
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: pageFontSize,
                    fontFamily: pageFont,
                    textAlign: 'left',
                    width: '50%',
                  }}
                >
                  : {startDate && new Date(startDate).toLocaleDateString()} -{' '}
                  {endDate && new Date(endDate).toLocaleDateString()}
                </Typography>
              </Stack>
            </Grid>
            <Grid item flexBasis={'50%'}>
              <Stack direction={'row'} justifyContent={'space-around'}>
                <Typography
                  variant="body2"
                  sx={{ fontWeight: 'bold', fontSize: '13px', textAlign: 'left', width: '50%' }}
                >
                  Designation
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: pageFontSize,
                    fontFamily: pageFont,
                    textAlign: 'left',
                    width: '50%',
                  }}
                >
                  : {data && data.Designation}
                </Typography>
              </Stack>
            </Grid>
            <Grid item flexBasis={'50%'}>
              <Stack direction={'row'} justifyContent={'space-around'}>
                <Typography
                  variant="body2"
                  sx={{
                    fontWeight: 'bold',
                    fontSize: pageFontSize,
                    fontFamily: pageFont,
                    textAlign: 'left',
                    width: '50%',
                  }}
                >
                  Working Days
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: pageFontSize,
                    fontFamily: pageFont,
                    textAlign: 'left',
                    width: '50%',
                  }}
                >
                  : {data && data.WorkingDays}
                </Typography>
              </Stack>
            </Grid>

            <Grid item flexBasis={'50%'}>
              <Stack direction={'row'} justifyContent={'space-around'}>
                <Typography
                  variant="body2"
                  sx={{
                    fontWeight: 'bold',
                    fontSize: pageFontSize,
                    fontFamily: pageFont,
                    textAlign: 'left',
                    width: '50%',
                  }}
                >
                  Department
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: pageFontSize,
                    fontFamily: pageFont,
                    textAlign: 'left',
                    width: '50%',
                  }}
                >
                  : {data && data.Department}
                </Typography>
              </Stack>
            </Grid>

            <Grid item flexBasis={'50%'}>
              <Stack direction={'row'} justifyContent={'space-around'}>
                <Typography
                  variant="body2"
                  sx={{
                    fontWeight: 'bold',
                    fontSize: pageFontSize,
                    fontFamily: pageFont,
                    textAlign: 'left',
                    width: '50%',
                  }}
                >
                  A/C No.
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: pageFontSize,
                    fontFamily: pageFont,
                    textAlign: 'left',
                    width: '50%',
                  }}
                >
                  : {data && data.AccountNumber}
                </Typography>
              </Stack>
            </Grid>
            <Grid item flexBasis={'50%'}>
              <Stack direction={'row'} justifyContent={'space-around'}>
                <Typography
                  variant="body2"
                  sx={{
                    fontWeight: 'bold',
                    fontSize: pageFontSize,
                    fontFamily: pageFont,
                    textAlign: 'left',
                    width: '50%',
                  }}
                >
                  Bank Name
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: pageFontSize,
                    fontFamily: pageFont,
                    textAlign: 'left',
                    width: '50%',
                  }}
                >
                  : {data && data.Bank}
                </Typography>
              </Stack>
            </Grid>
          </Grid>

          <Grid container sx={{ pt: 3 }}>
            <Grid item flexBasis={'50%'}>
              {apiUrlEarnings && (
                <TableGREYERP
                  apiExternalUrl={apiUrlEarnings}
                  table="salaryslipearnings"
                  name="Earnings"
                  height="auto"
                  pageFont={pageFont}
                  pageFontSize={pageFontSize}
                />
              )}
            </Grid>
            <Grid item flexBasis={'50%'}>
              {apiUrlDeductions && (
                <TableGREYERP
                  apiExternalUrl={apiUrlDeductions}
                  table="salaryslipdeductions"
                  name="Deductions"
                  height="auto"
                  pageFont={pageFont}
                  pageFontSize={pageFontSize}
                />
              )}
            </Grid>
          </Grid>

          <TableContainer sx={{ overflow: 'unset', mt: 4 }}>
            {/* <Scrollbar> */}
            <Table>
              {
                <TableBody>
                  <StyledRowResult>
                    <TableCell colSpan={2}></TableCell>
                    <TableCell>
                      <Typography
                        sx={{ fontWeight: 'bold', fontSize: pageFontSize, fontFamily: pageFont }}
                      >
                        Gross Salary
                      </Typography>
                    </TableCell>

                    <TableCell align="right">
                      <Box />
                      <Typography
                        sx={{ fontWeight: 'bold', fontSize: pageFontSize, fontFamily: pageFont }}
                      >
                        {data && parseFloat(data.Gross).toFixed(3)}
                      </Typography>
                    </TableCell>
                  </StyledRowResult>
                  <StyledRowResult>
                    <TableCell colSpan={2}></TableCell>
                    <TableCell>
                      <Typography
                        sx={{ fontWeight: 'bold', fontSize: pageFontSize, fontFamily: pageFont }}
                      >
                        Total Deductions
                      </Typography>
                    </TableCell>

                    <TableCell align="right">
                      <Box />
                      <Typography
                        sx={{
                          fontWeight: 'bold',
                          align: 'left',
                          fontSize: pageFontSize,
                          fontFamily: pageFont,
                        }}
                      >
                        {data && parseFloat(data.Deductions).toFixed(3)}
                      </Typography>
                    </TableCell>
                  </StyledRowResult>
                  <StyledRowResult
                    sx={{ borderTop: '.1px solid black', height: '30px !important', mt: 10 }}
                  >
                    <TableCell
                      colSpan={2}
                      align="left"
                      sx={{ fontWeight: 'bold', fontSize: pageFontSize, fontFamily: pageFont }}
                    >
                      Amount In Words: {data && amountInWords(data.NetPay)} Only
                    </TableCell>
                    <TableCell>
                      <Typography
                        sx={{ fontWeight: 'bold', fontSize: '14px', fontFamily: pageFont }}
                      >
                        Net Pay
                      </Typography>
                    </TableCell>

                    <TableCell align="right">
                      <Box />
                      <Typography
                        sx={{ fontWeight: 'bold', fontSize: '14px', fontFamily: pageFont }}
                      >
                        {data && parseFloat(data.NetPay).toFixed(3)}
                      </Typography>
                    </TableCell>
                  </StyledRowResult>
                </TableBody>
              }
            </Table>
            {/* </Scrollbar> */}
          </TableContainer>

          {/* <div className="ql-editor"  style={{ width: '100%' }} dangerouslySetInnerHTML={{ __html: pageBottom }} /> */}
          <div
            className="ql-editor"
            style={{ width: '100%' }}
            dangerouslySetInnerHTML={{ __html: pageFooter }}
          />
        </Card>
      </Popover>
    </>
  );
}
