import { CardPanel } from 'src/controls/cardpanel';
import { TextBoxGREYERP } from 'src/controls/textbox';
import UploadGREY from 'src/controls/uploadgrey';
import ImageContainer from 'src/controls/imagecontainer';

export default function Contact({ showFilePicker = true }) {
  return (
    <>
      <CardPanel
        heading="Contact infomration"
        cardItem={
          <>
            <UploadGREY />

            <ImageContainer>
              <TextBoxGREYERP placeholder="Mr" name="Title" focus={true} />
              <TextBoxGREYERP placeholder="John" name="FirstName" required={true} />
              <TextBoxGREYERP placeholder="Doe" name="MiddleName" />
              <TextBoxGREYERP placeholder="Wick" name="LastName" />
              <TextBoxGREYERP placeholder="John" name="NickName" />
              <TextBoxGREYERP placeholder="Organization" name="Organization" span={2} />
              <TextBoxGREYERP placeholder="Manager" name="JobTitle" />
              <TextBoxGREYERP placeholder="Comments" multiline span={2} name="Comments" />
            </ImageContainer>
          </>
        }
      />
      <CardPanel
        heading="Contact Details"
        cardItem={
          <>
            <TextBoxGREYERP placeholder="09686372XXX" name="Phone" />
            <TextBoxGREYERP placeholder="09686372XXX" name="Phone2" />
            <TextBoxGREYERP placeholder="2467XXXX" name="Office" />
            <TextBoxGREYERP placeholder="12" name="Extension" />
            <TextBoxGREYERP placeholder="someone@something.com" name="Email" />
            <TextBoxGREYERP placeholder="www.something.com" name="Website" />
          </>
        }
      />
      <CardPanel
        heading="Address Details"
        cardItem={
          <>
            <TextBoxGREYERP placeholder="Way# XXX," name="Street1" multiline={true} span={2} />
            <TextBoxGREYERP
              placeholder="Ghala, Muscat..."
              name="Street2"
              multiline={true}
              span={2}
            />
            <TextBoxGREYERP placeholder="Muscat" name="City" />
            <TextBoxGREYERP placeholder="112" name="PostalCode" />
            <TextBoxGREYERP placeholder="Oman" name="Country" />
          </>
        }
      />
    </>
  );
}
