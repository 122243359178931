
import { roundToNearestMinutes } from 'date-fns';
import Picker from 'src/controls/picker';
import { DropDownGREYERP } from 'src/controls/dropdown';
import { TextBoxGREYERP } from 'src/controls/textbox';
import PopUpPanel from 'src/controls/popuppanel';
import { CheckBoxGREYERP } from 'src/controls/checkbox';

export default function Call() {
  const PRIORITIZES_OPTIONS = [{ value: 'true', label: 'Mark as Completed', color: 'info' }];

  return (
    <>
      <PopUpPanel
        heading={'Add'}
        child={
          <>
             <TextBoxGREYERP span={2} name="Purpose" placeholder="Call Purpose" focus={true} required={true}/>
             <TextBoxGREYERP  span={2}name="Description"  multiline={true} placeholder="Call Description"  />
             <DropDownGREYERP span={2} name="RelatedTo" items={`relatedto/name`} />
             <Picker type='dateTime'  date={ roundToNearestMinutes(new Date(), { nearestTo: 30, roundingMethod: 'ceil' })}  name="DateTime" required={true} />
             <DropDownGREYERP  name="Status" items={`callstatus/name`} />
             <TextBoxGREYERP span={2} multiline rows={3} label="Comments" placeholder="Internal Notes" name="Comments" /> 
             <CheckBoxGREYERP span={2} label="Mark as Completed" name="isCompleted" />
          </>
        }
      />
    </>
  );
}
