import React from 'react';
import { CardPanel } from 'src/controls/cardpanel';
import { CheckBoxGREYERP } from 'src/controls/checkbox';
import { DropDownENUM } from 'src/controls/dropdownenum';
import { ItemsComponent } from 'src/controls/items/ItemsComponent';
import { TextBoxGREYERP } from 'src/controls/textbox';
import { approvalForms } from 'src/data/data';

export default function ApprovalType() {
  let sales = window.location.pathname.split('/')[1]?.toUpperCase() == 'SALES';
  return (
    <>
      <CardPanel
        heading="Approval Details"
        cardItem={
          <>
            <TextBoxGREYERP placeholder={'Order Approval'} name="Name" required={true} focus={true} />
            <DropDownENUM name="Documents" multiple={true} items={approvalForms} placeholder={'Select Documents'} span={2} />
            <TextBoxGREYERP name="MinimumApprovals" placeholder={'1'} type="number" required={true} />
            <CheckBoxGREYERP name='Status' span={0.4} label='Active' />
            <CheckBoxGREYERP name='Sequence' span={0.6} label='In Sequence' />
            <TextBoxGREYERP placeholder="Description" name="Description" multiline={true} span={2} />
            <TextBoxGREYERP placeholder="Internal Notes" name="Comments" multiline={true} span={2} />
          </>
        }
      />

      <ItemsComponent
        tablename={'approvaltypedetails'}
        heading={'Approvers'}
        disableCalculation={true}
        disableQuantity={true}
        enterOnChangeFunc={false}
        columnsToShow={{
          Username: '',
          Required: 0,
        }}
        columnsToAppend={{
          Username: '',
          Required: 0,
        }}
      
      />
    </>
  );
      }

