import { Container, Grid } from '@mui/material';
import React from 'react';
import { FileGeneralWidget } from 'src/sections/file';

export default function MarketPlace() {
  const marketPlaceItems = [
    {
      title: 'Google Contacts',
      description: 'contacts.svg',
      icon: 'google-contacts-icon.svg',
    },
    {
      title: 'Outlook Contacts',
      description: '',
      icon: 'contacts.PNG',
    },
    {
      title: 'Apple Contacts',
      description: '',
      icon: 'apple-contacts.svg',
    },
    {
      title: 'Gmail',
      description: '',
      icon: 'gmail.svg',
    },
    {
      title: 'Outlook',
      description: '',
      icon: 'microsoft-outlook.svg',
    },
    {
      title: 'Google Calender',
      description: '',
      icon: 'icons8-google-calendar.svg',
    },
    {
      title: 'Outlook Calender',
      description: '',
      icon: 'icons8-outlook-calendar.svg',
    },
  ];
  return (
    <>
   
        <Grid container spacing={2}>
          {marketPlaceItems.map((provider) => (
            <Grid item xs={12} sm={4} md={3}>
              <FileGeneralWidget
                title={provider.title}
                description={'Syncrhonize your Google contacts with Grey ERP contacts in real time'}
                icon={provider.icon}
              />
            </Grid>
          ))}
        </Grid>
    
    </>
  );
}
