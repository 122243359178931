import PropTypes from 'prop-types';
//
import Image from '../../image';

// ----------------------------------------------------------------------

AvatarPreview.propTypes = {
  file: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default function AvatarPreview({ file,editFileUrl,type='' }) {
  // if (!file) {
  //   return null;
  // }

  let editUrl='';

  if(editFileUrl && type=='uppy'){
    editUrl = `https://uploads.greyerp.com/${window.location.pathname.split('/')[2]?.replaceAll('-', '')?.toUpperCase()}_${window.location.pathname.split('/')[3]}_${localStorage.getItem('username')}_${editFileUrl}`;
  }else if(!file){
    editUrl = `https://uploads.greyerp.com/${editFileUrl}`;
  }

  const imgUrl = typeof file === 'string' ? file : file.preview;
  return (
    <Image
      alt="avatar"
      src={editUrl ? editUrl : imgUrl}
      sx={{
        zIndex: 8,
        overflow: 'hidden',
        borderRadius: '50%',
        position: 'absolute',
        width: `calc(100% - 16px)`,
        height: `calc(100% - 16px)`,
      }}
    />
  );
}
