
import { useContext, useEffect, useRef, useState } from 'react';

// eslint-disable-next-line import/no-unresolved
import { CardPanel } from "src/controls/cardpanel";
import { DropDownGREYERP } from "src/controls/dropdown";
import { TextBoxGREYERP } from "src/controls/textbox";

import UppyFilePicker from 'src/controls/uppyFilePicker';
import { datediff, datediffMonths, datediffYears } from "src/data/scripts";
import { FormContext } from 'src/layouts/dashboard/DashboardLayout';
export default function Gratuity()
{ 
  const [joiningDate, setJoiningDate] = useState('');
  const [leavingDate, setLeavingDate] = useState('');
  const [basic, setbasic] = useState(0);
  const [noofDays, setNoofDays] = useState(0);
  const [noofYears, setNoofYears] = useState(0);
  const [payableAmount, setPayableAmount] = useState(0);
  const { object, setObject, objectEdit, setObjectEdit } = useContext(FormContext);
  function onChangeJoiningDate(e) {
    
    setJoiningDate(e);
    setObject({ ...object, ['JoiningDate']: e });
    if (basic != '' && leavingDate != '') {

      let daysNo  = datediff(e, leavingDate);
      let yearsNo  = datediffYears(e, leavingDate);

      setNoofDays(daysNo);
      setNoofYears(yearsNo);

      if(parseInt(yearsNo)> 3)
      {
        var FullYears = parseInt(yearsNo) - 3;
        let paymentAmount = Math.round((((parseFloat(basic) / 2) * 3) + (parseFloat(basic) * FullYears)), 3);
        setPayableAmount(paymentAmount)
      }
      else
      {
        let yearsNo  = datediffYears(joiningDate, e);
        let paymentAmount = Math.round((parseFloat(basic) / 2) * parseInt(yearsNo),3);
        setPayableAmount(paymentAmount)
      }

      // setObject({
      //   ...object,
      //   ['Months']: diffMonths,
      //   ['ContractFrom']: e.$d,
      //   ['TotalRent']: unitRent * diffMonths,
      // });
    }
    // console.log(e);
  }
  function onChangeLeavingDate(e) {
    setLeavingDate(e);
    setObject({ ...object, ['LeavingDate']: e });
     if (joiningDate != '') {
      let daysNo  = datediff(joiningDate, e);
      let yearsNo  = datediffYears(joiningDate, e);

      setNoofDays(daysNo);
      setNoofYears(yearsNo);

      if(parseInt(yearsNo)> 3)
      {
        var FullYears = parseInt(yearsNo) - 3;
        let paymentAmount = Math.round((((parseFloat(basic) / 2) * 3) + (parseFloat(basic) * FullYears)), 3);
        setPayableAmount(paymentAmount);
        setObject({
          ...object,
          ['LeavingDate']: e ,
          ['ServicePeriodDays']: daysNo,
          ['ServicePeriodYears']: yearsNo,
          ['PayableAmount']: paymentAmount,

        });
      }
      else
      {
        let paymentAmount = Math.round((parseFloat(basic) / 2) * parseInt(yearsNo),3);
        setPayableAmount(paymentAmount);
        setObject({
              ...object,
              ['LeavingDate']: e ,
              ['ServicePeriodDays']: daysNo,
              ['ServicePeriodYears']: yearsNo,
              ['PayableAmount']: paymentAmount,

            });
      }
     }

  }
  function onChangeBasic(e) {
    setbasic(e.target.value);
    setObject({ ...object, ['Basic']: e.target.value });

    if (joiningDate != '' && leavingDate != '') {
      let daysNo  = datediff(joiningDate, leavingDate);
      let yearsNo  = datediffYears(joiningDate, leavingDate);

      setNoofDays(daysNo);
      setNoofYears(yearsNo);

      if(parseInt(yearsNo)> 3)
      {
        var FullYears = parseInt(yearsNo) - 3;
        let paymentAmount = Math.round((((parseFloat(e.target.value) / 2) * 3) + (parseFloat(e.target.value) * FullYears)), 3);
        setPayableAmount(paymentAmount);
        setObject({
          ...object,
          ['Basic']: e.target.value ,
          ['ServicePeriodDays']: daysNo,
          ['ServicePeriodYears']: yearsNo,
          ['PayableAmount']: paymentAmount,

        });
      }
      else
      {
        let paymentAmount = Math.round((parseFloat(e.target.value) / 2) * parseInt(yearsNo),3);
        setPayableAmount(paymentAmount);
        setObject({
          ...object,
          ['Basic']: e.target.value ,
          ['ServicePeriodDays']: daysNo,
          ['ServicePeriodYears']: yearsNo,
          ['PayableAmount']: paymentAmount,

        });
      }
     }
  }
  useEffect(() => {
    if (Object.keys(objectEdit).length > 0) {
      if (`${objectEdit['JoiningDate']}` != '') {
        let a = new Date(`${objectEdit['JoiningDate']}`);
        setJoiningDate(a);
      }
      if (`${objectEdit['LeavingDate']}` != '') {
        let a = new Date(`${objectEdit['LeavingDate']}`);
        setLeavingDate(a);
      }

      setbasic(`${objectEdit['Basic'] ?? ''}`)
      setNoofDays(`${objectEdit['ServicePeriodDays'] ?? ''}`)
      setNoofYears(`${objectEdit['ServicePeriodYears'] ?? ''}`)
      setPayableAmount(`${objectEdit['PayableAmount'] ?? ''}`)
    }
  }, [objectEdit]);


    return(
        <>

      <CardPanel heading="Gratuity Details" cardItem={<>
      
<Picker type='date' name="Date" placeholder="Gratuity Date" required={true} focus={true}></Picker>
                        
<DropDownGREYERP items={`employees/name`} name="Employee"  required={true} />
<TextBoxGREYERP placeholder="500.000"   name="Basic" required={true} type="number" step="any" defaultValue={basic}  onChange={onChangeBasic}  />


<Picker type='date' name="JoiningDate" placeholder="Joining Date"  postBackDate={joiningDate} onChange={onChangeJoiningDate} required={true} ></Picker>
<Picker type='date' name="LeavingDate" placeholder="Leaving Date" postBackDate={leavingDate} onChange={onChangeLeavingDate} required={true} ></Picker>

<TextBoxGREYERP name="ServicePeriodDays" required={true} type="number" prefix="Days" defaultValue={noofDays}></TextBoxGREYERP>
 <TextBoxGREYERP name="ServicePeriodYears" required={true} type="number" prefix="Years" defaultValue={noofYears}></TextBoxGREYERP>

<TextBoxGREYERP placeholder="Total Graruity" name="PayableAmount" required={true} type="number" step="any"  defaultValue={payableAmount} />
<TextBoxGREYERP placeholder="Internal Notes" name="Comments" multiline={true} MultiLine={true} span={2}/>                 

</>} />
<UppyFilePicker/>
   </>


  )

 
}
