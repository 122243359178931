import { Box, Checkbox, FormControlLabel, FormGroup, Grid, InputAdornment, Paper, Typography } from "@mui/material";
import { func } from "prop-types";
import { useContext, useEffect, useState } from 'react';


 import { FormContext } from "src/App";
import { CardPanel } from "src/controls/cardpanel";
import { DropDownGREYERP } from "src/controls/dropdown";
import { DropDownENUM } from "src/controls/dropdownenum";
import { TextBoxGREYERP } from "src/controls/textbox";
import UploadGREY from "src/controls/uploadgrey";
import { CompanyTypes, PaymentTerms } from "src/data/data";
import NavHorizontal from "src/layouts/dashboard/nav/NavHorizontal";
import Editor from 'src/components/editor';
import 'react-quill/dist/quill.snow.css';
import { DropzoneGREY } from "src/controls/dropzone";
import UppyFilePicker from 'src/controls/uppyFilePicker';

import { TextEditorGrey } from "src/controls/texteditorgrey";
import { ItemsComponent } from "src/controls/items/ItemsComponent";

export default function WorkingHours()
{   
    return(
        <>

      <CardPanel heading="Working Hour Details" cardItem={<>
    
      <TextBoxGREYERP  name="Name" placeholder="Working Hours Name" required={true} focus={true} />
      <TextBoxGREYERP label="Comments" placeholder="Remarks (Optional)" name="Comments" multiline={true}  MultiLine={true} span={2} />


</>} />

<ItemsComponent tablename="workinghourdetails"  heading={'Working Hours'}
                disableCalculation={true}
                disableQuantity={true} columnsToAppend={false}
      columnsToShow={{  Day: '', StartTime: '', EndTime: ''}}/>
 
   </>


  )

 
}
