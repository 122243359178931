/* eslint-disable react/prop-types */
import PropTypes from 'prop-types';
import React, { useEffect, useCallback } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import {
  Stack,
  AppBar,
  Toolbar,
  IconButton,
  ClickAwayListener,
  Typography,
  Link,
  Tooltip,
  Button,
} from '@mui/material';
// utils
// eslint-disable-next-line import/no-unresolved
import NavList from 'src/components/nav-section/mini/NavList';
import { bgBlur } from '../../../utils/cssStyles';
// config
import { HEADER, NAV } from '../../../config-global';
// components
import Logo from '../../../components/logo/LogoWhite';
import Iconify from '../../../components/iconify';
import { useSettingsContext } from '../../../components/settings';
import AccountPopover from './AccountPopover';
import CompanyPopover from './CompanyPopover';
// import CalendarPopover from './CalendarPopover';
import MailsPopover from './MailsPopover';
import { FormContext } from '../DashboardLayout';
import { useContext } from 'react';
import useResponsive from 'src/hooks/useResponsive';
import StorefrontIcon from '@mui/icons-material/Storefront';
import { Navigate, Link as RouterLink, useNavigate } from 'react-router-dom';
import { IconButtonAnimate } from 'src/components/animate';
import Searchbar from './Searchbar';
import LanguagePopover from './LanguagePopover';
// ----------------------------------------------------------------------

const Header = React.memo(({ onOpenNav, open }) => {
  const theme = useTheme();

  const { themeLayout } = useSettingsContext();

  const isNavHorizontal = themeLayout === 'horizontal';

  const isNavMini = themeLayout === 'mini';

  const isDesktop = useResponsive('up', 'lg');

  const { setOpenHeader, setAltMode } = useContext(FormContext);
  const navigate = useNavigate();

  const handleOpenNav = useCallback(() => {
    onOpenNav();
  }, [onOpenNav]);

  // useEffect(() => {
  //   const addScript = document.createElement('script');
  //   addScript.setAttribute(
  //     'src',
  //     '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit'
  //   );
  //   document.body.appendChild(addScript);
  //   window.googleTranslateElementInit = googleTranslateElementInit;
  // }, []);

  // let duplicate_google_translate_counter = 0;
  // const googleTranslateElementInit = () => {
  //   if (duplicate_google_translate_counter == 0) {
  //     new window.google.translate.TranslateElement(
  //       {
  //         pageLanguage: 'en',
  //         includedLanguages: 'en,ar,hi', // include this for selected languages
  //         layout: google.translate.TranslateElement.InlineLayout.SIMPLE,
  //         // destinationLanguage: lang,
  //       },
  //       'google_translate_element'
  //     );
  //     duplicate_google_translate_counter++;
  //   }
  // };
  const navConfigERP =
    localStorage.getItem('navConfigERP') && JSON.parse(localStorage.getItem('navConfigERP'));
  const renderContent = (
    <>
      <style>
        {`
          .VIpgJd-ZVi9od-ORHb-OEVmcd {
            display:none
          }
        `}
      </style>
      {isDesktop && isNavHorizontal && <Logo sx={{ mr: 5 }} />}

      {!isDesktop && (
        <IconButton onClick={onOpenNav} sx={{ mr: 1, color: 'white' }}>
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>
      )}

      {!open &&
        isDesktop &&
        navConfigERP.map((group) => <Items key={group.subheader} items={group.items} />)}

      <Stack flexGrow={1} direction="row" alignItems="center" justifyContent="flex-end">
        {/* <Searchbar /> */}
        <Stack sx={{ mr: 3 }} direction={'row'}>
          <Searchbar navConfigERP={navConfigERP} />
          {/* <MailsPopover /> */}
          {/* <CalendarPopover /> */}
          <IconButtonAnimate
            onClick={() => navigate('/utility/marketplace')}
            sx={{ width: 40, height: 40 }}
          >
            <Tooltip title="Marketplace">
              <Iconify sx={{ color: 'white' }} icon="bx:store"></Iconify>
            </Tooltip>
          </IconButtonAnimate>
          <LanguagePopover />
        </Stack>
        <CompanyPopover />
        <AccountPopover />
        {/*        
        <div id="google_translate_element" 
          // style={{ display: 'none' }}
          > </div> */}
      </Stack>
    </>
  );

  return (
    <AppBar
      sx={{
        boxShadow: 'none',
        displayPrint: 'none',
        height: HEADER.H_MOBILE,
        zIndex: 6,
        //zIndex: theme.zIndex.appBar + 1,
        ...bgBlur({
          color: theme.palette.background.default,
        }),
        transition: theme.transitions.create(['height'], {
          duration: theme.transitions.duration.shorter,
        }),
        ...(isDesktop && {
          width: `calc(100% - ${NAV.W_DASHBOARD + 1}px)`,
          height: HEADER.H_DASHBOARD_DESKTOP,

          ...(isNavHorizontal && {
            width: 1,
            bgcolor: 'background.default',
            height: HEADER.H_DASHBOARD_DESKTOP_OFFSET,
            borderBottom: `dashed 1px ${theme.palette.divider}`,
          }),
          ...(isNavMini && {
            width: `calc(100% - ${NAV.W_DASHBOARD_MINI + 1}px)`,
          }),
        }),
      }}
    >
      <ClickAwayListener
        onClickAway={(e) => {
          setOpenHeader(false);
        }}
      >
        <Toolbar
          sx={{
            height: 1,
            backgroundColor: '#201F2B',
            px: { lg: 2 },
          }}
        >
          {renderContent}
        </Toolbar>
      </ClickAwayListener>
    </AppBar>
  );
});

Header.propTypes = {
  onOpenNav: PropTypes.func,
};

function Items({ items }) {
  return (
    <>
      {items.map((list) => (
        <NavList key={list.title + list.path} data={list} depth={1} hasChild={!!list.children} />
      ))}
    </>
  );
}
export default Header;
