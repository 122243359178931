import { Box, Modal, Typography, Grid, Tooltip, IconButton } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { FormContext } from 'src/layouts/dashboard/DashboardLayout';
import Iconify from 'src/components/iconify/Iconify';
import { msalConfig, loginRequest, tokenRequest } from 'src/outlook/authConfig';
import { callCalendarsGraph, callMSGraph, updateUI, graphConfig } from 'src/outlook/graph';
import { getTokenPopup } from 'src/outlook/authPopup';
export function SyncExternalProviderDataMeeting() {
  const gapi = window.gapi;
  const google = window.google;

  const CLIENT_ID = '13757048669-23fktos8qng7qblvfe34p0e5nomprf9a.apps.googleusercontent.com';
  const API_KEY = 'AIzaSyBb8rWC3qZ5rY-RAWe7hox-vZ5Ti7wNP2w';
  const DISCOVERY_DOC = 'https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest';
  const SCOPES = 'https://www.googleapis.com/auth/calendar';

  const accessToken = null;
  const expiresIn = null;

  let gapiInited = false,
    gisInited = false,
    tokenClient;

  useEffect(() => {
    gapiLoaded();
    gisLoaded();
  }, []);

  function gapiLoaded() {
    gapi.load('client', initializeGapiClient);
  }

  async function initializeGapiClient() {
    await gapi.client.init({
      apiKey: API_KEY,
      discoveryDocs: [DISCOVERY_DOC],
    });
    gapiInited = true;

    if (accessToken && expiresIn) {
      gapi.client.setToken({
        access_token: accessToken,
        expires_in: expiresIn,
      });
      fetchContacts();
    }
  }

  function gisLoaded() {
    tokenClient = google.accounts.oauth2.initTokenClient({
      client_id: CLIENT_ID,
      scope: SCOPES,
      callback: '', // defined later
    });

    gisInited = true;
  }

  //Enables user interaction after all libraries are loaded.

  async function handleGoogleAuthClick(e) {
    e.preventDefault();
    tokenClient.callback = async (resp) => {
      if (resp.error) {
        throw resp;
      }
      //  console.log('reached')
      await listUpcomingEvents();
      await addNewUpcomingEvents();
      const { access_token, expires_in } = gapi.client.getToken();
      localStorage.setItem('access_token', access_token);
      localStorage.setItem('expires_in', expires_in);
      handleClose();
    };

    if (!(accessToken && expiresIn)) {
      // Prompt the user to select a Google Account and ask for consent to share their data
      // when establishing a new session.
      tokenClient.requestAccessToken({ prompt: 'consent' });
    } else {
      // Skip display of account chooser and consent dialog for an existing session.
      tokenClient.requestAccessToken({ prompt: '' });
    }
  }

  const [contacts, setContacts] = useState([]);

  async function listUpcomingEvents() {
    let response;
    try {
      const request = {
        calendarId: 'primary',
        //  'timeMin': (new Date()).toISOString(),
        showDeleted: false,
        singleEvents: true,
        maxResults: 1000,
        orderBy: 'startTime',
      };
      response = await gapi.client.calendar.events.list(request);
      var items = response.result.items;
      //console.log(items);
      const Meetings = items.map(function (Meeting) {
        return {
          //Name
          GoogleID: Meeting.id,
          title: Meeting.summary ? Meeting.summary.replaceAll("'", '`') : '',
          location: Meeting.location ? Meeting.location.replaceAll("'", '`') : '',
          startDateTime:
            Meeting.start && Meeting.start.dateTime
              ? new Date(Meeting.start.dateTime).toISOString()
              : '',
          endDateTime:
            Meeting.end && Meeting.end.dateTime ? new Date(Meeting.end.dateTime).toISOString() : '',
          description: Meeting.description ? Meeting.description.replaceAll("'", '`') : '',
        };
      });

      //  console.log(Meetings);
      Promise.all(
        Meetings.map(
          (Meeting, index) =>
            new Promise((resolve) => {
              setTimeout(() => {
                HttpRequest(
                  `getItems/googlemeetingsdetails/Name/${localStorage.getItem(
                    'username'
                  )}/${localStorage.getItem('client')}/${Meeting.GoogleID}`
                )
                  .then((response) => response)
                  .then((json) => {
                    if (json.ID == '0') {
                      return HttpRequest(
                        `events/${localStorage.getItem('client')}/${localStorage.getItem(
                          'username'
                        )}`,
                        'POST',
                        Meeting
                      );
                    } else {
                      return HttpRequest(
                        `events/${json.ID}/${localStorage.getItem('username')}`,
                        'PUT',
                        Meeting
                      );
                    }
                  })
                  .then(resolve)
                  .catch(console.error);
              }, index * 10); // delay by 1 second per contact index
            })
        )
      )
        .then((responses) => {
          //  console.log(responses);
        })
        .catch(console.error);
    } catch (err) {
      return;
    }
  }

  async function addNewUpcomingEvents() {
    await HttpRequest(
      `getItems/syncgooglemeetingsdetails/name/${localStorage.getItem(
        'username'
      )}/${localStorage.getItem('client')}`
    )
      .then((response) => response)
      .then((json) => {
        //console.log(json)
        json.map((meeting) => {
          // console.log(meeting);

          var event = {
            kind: 'calendar#event',
            summary: meeting.title,
            location: meeting.location,
            description: meeting.description,
            start: {
              dateTime: meeting.startDateTime,
              timeZone: 'UTC',
            },
            end: {
              dateTime: meeting.endDateTime,
              timeZone: 'UTC',
            },
            recurrence: ['RRULE:FREQ=DAILY;COUNT=1'],
            reminders: {
              useDefault: true,
            },
          };

          var request = gapi.client.calendar.events.insert({
            calendarId: 'primary',
            resource: event,
            sendUpdates: 'all',
          });
          request.execute(
            (event) => {
              //  console.log(event)
              let googleid = event.id;
              //  console.log(googleid);

              var fields = {};
              fields['GoogleID'] = googleid;
              HttpRequest(
                `events/${meeting.ID}/${localStorage.getItem('username')}`,
                'PUT',
                JSON.stringify(fields)
              );

              //window.open(event.htmlLink)
            },
            (error) => {
              // console.error(error);
            }
          );
        });
      });
  }

  const { openPrint, setOpenPrint, openExternalProviderPopup, setOpenExternalProviderPopup } =
    useContext(FormContext);
  const handleClose = () => {
    setOpenExternalProviderPopup(false);
  };

  // configuration parameters are located at authConfig.js
  const myMSALObj = new Msal.UserAgentApplication(msalConfig);

  async function handleOutlookAuthClick(e) {
    e.preventDefault();

    myMSALObj
      .loginPopup(loginRequest)
      .then((loginResponse) => {
        // console.log("id_token acquired at: " + new Date().toString());
        // console.log(loginResponse);

        if (myMSALObj.getAccount()) {
          getTokenPopup(tokenRequest)
            .then((response) => {
              callCalendarsGraph(
                graphConfig.graphCalendarsEndpoint,
                response.accessToken,
                updateUI
              );
              handleClose();
            })
            .catch((error) => {
              console.log(error);
            });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    height: 'auto',
    bgcolor: 'background.paper',
    borderRadius: '10px',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };
  return (
    <Modal
      open={openExternalProviderPopup}
      onClose={handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box sx={{ ...style, width: 600 }}>
        <Grid container alignItems={'center'}>
          <Grid item sx={{ ml: 'auto', displayPrint: 'none' }}>
            <Tooltip title="Close">
              <IconButton onClick={handleClose}>
                <Iconify icon="material-symbols:close" />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
        <Typography sx={{ fontSize: '24px', fontWeight: '700' }}>
          Sync {window.location.pathname.split('/')[2].charAt(0).toUpperCase()}
          {window.location.pathname.split('/')[2].slice(1)}
        </Typography>
        <Grid container sx={{ pt: 3 }} alignItems={'center'}>
          <Grid item xs={12} sm={4} sx={{ textAlign: 'center' }}>
            <button
              type="button"
              id="authorize_button_Google"
              hidden={accessToken && expiresIn}
              onClick={handleGoogleAuthClick}
              style={{
                backgroundImage: "url('https://uploads.greyerp.com/googlelogo.png')",
                backgroundSize: 'cover',
                border: 'none',
                backgroundColor: 'transparent',
                cursor: 'pointer',
                width: '118px',
                height: '118px',
              }}
            >
              {' '}
            </button>
          </Grid>
          {/* <Grid item xs={12} sm={4} sx={{textAlign:'center'}} >
           <button type='button' id="authorize_button_Apple" style={{backgroundImage:"url('https://uploads.greyerp.com/applylogo.png')",backgroundSize:"cover", border:"none",backgroundColor:"transparent",width:"105px", height:"118px",cursor:'pointer'}}> </button>
        </Grid>*/}
          <Grid item xs={12} sm={4} sx={{ textAlign: 'center' }}>
            <button
              type="button"
              id="authorize_button_Outlook"
              onClick={handleOutlookAuthClick}
              style={{
                backgroundImage: "url('https://uploads.greyerp.com/outlooklogo.png')",
                backgroundSize: 'cover',
                border: 'none',
                backgroundColor: 'transparent',
                width: '118px',
                height: '108px',
                cursor: 'pointer',
              }}
            >
              {' '}
            </button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}
