import { Stack, Divider } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { CardPanel } from 'src/controls/cardpanel';
import { DropDownGREYERP } from 'src/controls/dropdown';
import { TextBoxGREYERP } from 'src/controls/textbox';
import React from 'react';
import { useTheme } from '@mui/material/styles';
import { PaymentMode, PaymentModeRP } from 'src/data/data';
import { ItemsComponent } from 'src/controls/items/ItemsComponent';
import Picker from 'src/controls/picker';
import ChequeDetails from 'src/controls/chequedetail';
import { FormContext } from 'src/layouts/dashboard/DashboardLayout';
import {
  GetAndSetList,
  GetVoucherNumber,
  HandleOnChangeValue,
  SelectPaymentMode,
  arabic,
} from 'src/data/scripts';
import InvoiceAnalytic from 'src/sections/@dashboard/invoice/InvoiceAnalytic';
import { SwithGREYERP } from 'src/controls/swithgrey';
import { DropDownENUM } from 'src/controls/dropdownenum';
import { useQuery } from '@tanstack/react-query';
import { useFormData } from 'src/hooks/useQueryHooks';

export default function Receipt({ isReceipt }) {
  let formID = window.location.pathname?.split('/')[2]?.replaceAll('-', '');
  let ID = window.location.pathname?.split('/')[3]?.replaceAll('-', '');
  const { setFormData: setObject } = useFormData();
  const { data: object } = useQuery({ queryKey: 'form' });
  const { data: objectEdit } = useQuery({
    queryKey: [formID, ID],
    select: (res) => res[0],
    enabled: Boolean(formID) && !!ID,
  });

  const { setExistingItemsData, formObject, setFormObject } = useContext(FormContext);
  const { Series = '', Number = '', FromAccount, Mode, Total, ToAccount } = object || {};
  const {
    Series: EditSeries = '',
    Number: EditNumber = '',
    Invoice: EditInvoice,
  } = objectEdit || {};
  const { showAccount, showChequeDetails, invoicesList, accSummary, Invoice, toAccount } =
    formObject || {};
  const [calculation, setCalculation] = useState(false);
  let receipt = window.location.pathname.split('/')[2]?.toUpperCase() == 'RECEIPTS' || isReceipt;
  const [relatedto, setRelatedto] = useState(0);
  const [relatedToID, setRelatedToID] = useState(0);

  useEffect(() => {
    if (isNaN(window.location.pathname.split('/')[3])) {
      HandleOnChangeValue(
        {
          ...(receipt ? { ToAccount: '1101' } : { FromAccount: '1101' }),
        },
        setObject
      );
    } else {
      if (objectEdit && Object.keys(objectEdit).length > 0) {
        const { toAccount, documentTo } = objectEdit;

        GetAndSetList(
          `getItems/accountsummary/Name/${localStorage.getItem('username')}/${localStorage.getItem(
            'client'
          )}/${objectEdit['FromAccount']}`,
          setFormObject,
          'accSummary'
        );

        GetAndSetList(
          `getItems/${
            receipt ? 'pendingsalesinvoicesedit' : 'pendingpurchaseinvoicesedit'
          }/Name/${localStorage.getItem('username')}/${localStorage.getItem('client')}/${
            objectEdit['ToAccount']
          }/${objectEdit['Invoice']}`,
          setFormObject,
          'invoicesList'
        );
      }
    }
  }, [window.location.pathname.split('/')[2], EditInvoice]);

  const theme = useTheme();
  return (
    <>
      <CardPanel
        heading={'Document Details'}
        cardItem={
          <>
            <Picker type="date" name="Date" focus={true} date={new Date()} required={true}></Picker>
            <DropDownGREYERP
              onChange={(e, selectedValue) =>
                HandleOnChangeValue(
                  {
                    ...(receipt
                      ? {
                          FromAccount: selectedValue?.ID,
                        }
                      : { ToAccount: selectedValue?.ID }),
                  },
                  setObject,
                  [
                    GetVoucherNumber(
                      arabic.test(selectedValue?.Name.split('(')[0])
                        ? 'A R B'
                        : selectedValue?.Name.split('(')[0],
                      setObject,
                      null,
                      EditNumber
                    ),
                    GetAndSetList(
                      `getItems/accountsummary/Name/${localStorage.getItem(
                        'username'
                      )}/${localStorage.getItem('client')}/${selectedValue?.ID}`,
                      setFormObject,
                      'accSummary'
                    ),

                    GetAndSetList(
                      `getItems/${
                        receipt ? 'pendingsalesinvoices' : 'pendingpurchaseinvoices'
                      }/Name/${localStorage.getItem('username')}/${localStorage.getItem(
                        'client'
                      )}/${selectedValue?.ID}`,
                      setFormObject,
                      'invoicesList'
                    ),
                    GetAndSetList(
                      `getCustomPostBackItem/${
                        receipt ? 'outstandingsalesinvoices' : 'outstandingpurchaseinvoices'
                      }/${localStorage.getItem('username')}/${localStorage.getItem(
                        'client'
                      )}/${parseInt(selectedValue?.ID)}`,
                      setExistingItemsData,
                      null
                    ),
                  ]
                )
              }
              items={receipt ? `sundrydebtors/Name` : `sundrycreditors/Name`}
              postBackSelectedValue={receipt ? FromAccount : ToAccount}
              name={receipt ? 'FromAccount' : 'ToAccount'}
              label={'Document To'}
              required
              span={2}
            />
            <TextBoxGREYERP
              placeholder="Amount"
              name="Total"
              label="Amount"
              type="Number"
              required={true}
              defaultValue={Total}
              onChange={(e) => (
                HandleOnChangeValue(
                  {
                    Total: e.target.value,
                  },
                  setObject
                ),
                setCalculation(true)
              )}
              step={'Any'}
            />
            <TextBoxGREYERP placeholder="Reference" name="Reference" />
            <TextBoxGREYERP
              placeholder="Voucher Number"
              disabled
              name="Number"
              defaultValue={Series ? Series + Number : EditSeries + EditNumber}
              required={true}
            />
            <TextBoxGREYERP
              placeholder="Narration"
              label="Narration"
              name="Description"
              span={2}
              multiline={true}
              required={true}
            />
            <TextBoxGREYERP placeholder="Received From" name="Person" />
            <DropDownGREYERP items={`costcenters/name`} name="CostCenter" />
            <DropDownGREYERP items={`Projects/name`} name="Project" />

            {/* <DropDownGREYERP
              name="RelatedToID"
              onChange={(e, selectedValue) => {
                setRelatedToID(selectedValue?.ID);
                setRelatedto(
                  selectedValue.Name.substring(selectedValue.Name.lastIndexOf('(') + 1).slice(0, -1)
                );
              }}
              items={`relatedto/name`}
              span={2}
              postBackSelectedValue={relatedToID}
            />
            <input
              name="RelatedTo"
              id="RelatedTo"
              value={relatedto}
              style={{ display: 'none' }}
            ></input> */}

            <DropDownGREYERP
              name="Invoice"
              label="Pending Invoices"
              span={2}
              postBackitems={invoicesList}
            />
            <TextBoxGREYERP placeholder="Internal Notes" name="Comments" multiline={true} />
            <DropDownENUM
              name="Mode"
              required={true}
              items={PaymentMode}
              onChange={(e, selectedValue) => {
                SelectPaymentMode(selectedValue?.ID, setFormObject);
                HandleOnChangeValue(
                  {
                    Mode: selectedValue?.ID,
                    ...(selectedValue?.ID == 'Cash' && {
                      ...(receipt ? { ToAccount: '1101' } : { FromAccount: '1101' }),
                    }),
                  },
                  setObject
                );
              }}
              postBackSelectedValue={Mode}
            />
            <SwithGREYERP name="Print" />
            {showAccount && (
              <DropDownGREYERP
                span={2}
                items={`bankaccounts/name`}
                name={receipt ? 'ToAccount' : 'FromAccount'}
                required={true}
              />
            )}
          </>
        }
      />
      {showChequeDetails && <ChequeDetails />}
      <CardPanel
        heading="Account Summary"
        cardItem={
          <>
            <Stack
              direction="row"
              divider={<Divider orientation="vertical" flexItem sx={{ borderStyle: 'dashed' }} />}
              sx={{ py: 2 }}
            >
              <InvoiceAnalytic
                title="Opening"
                price={accSummary?.opening}
                icon="eva:file-fill"
                color={theme.palette.text.secondary}
              />
              <InvoiceAnalytic
                title="Invoiced"
                price={accSummary?.due}
                icon="fa6-solid:file-invoice-dollar"
                color={theme.palette.text.primary}
              />
              <InvoiceAnalytic
                title="Total"
                price={accSummary?.opening + accSummary?.due}
                icon="clarity:list-line"
                color={theme.palette.info.main}
              />
              <InvoiceAnalytic
                title="Paid"
                price={accSummary?.paid}
                icon="eva:checkmark-circle-2-fill"
                color={theme.palette.success.main}
              />
              <InvoiceAnalytic
                title="Pending"
                price={accSummary?.opening + accSummary?.bills - accSummary?.paid}
                icon="eva:clock-fill"
                color={theme.palette.warning.main}
              />
              <InvoiceAnalytic
                title="Due"
                price={accSummary?.opening + accSummary?.due - accSummary?.paid}
                icon="eva:bell-fill"
                color={theme.palette.error.main}
              />
            </Stack>
          </>
        }
      />

      {/* {receipt ? (
        <ItemsComponent
          heading={'Outstanding Invoices'}
          disableAddItem={true}
          disableCalculation={true}
          disableQuantity={true}
          enterOnChangeFunc={false}
          appendCTA={true}
          // externalOnChange={(items,extra,setValue)=>{
          //   let Total = 0.0;
          //   items?.forEach((obj,index)=>{
          //     let objAllocatedAmount = obj?.Allocated >= extra[index]?.GrandTotal ? extra[index]?.GrandTotal : obj?.Allocated;
          //     setValue(`items[${index}].Allocated`, objAllocatedAmount);
          //     Total+=(parseFloat(objAllocatedAmount) || 0.0)});
          //   setCalculation(false)
          //   HandleOnChangeValue(
          //     {
          //       Total
          //     },
          //     setObject
          //   );
          // }}
          postBackCalcFunc={(items, extra, setValue) => {
            if (!!parseInt(Total) && items?.length > 0 && calculation) {
              let outstandingInvoiceCount = items?.length || 0;
              let propDistAmount = parseFloat(Total || 0) / outstandingInvoiceCount;
              items.forEach((obj, index) => {
                propDistAmount =
                  propDistAmount >= extra[index]?.GrandTotal
                    ? extra[index]?.GrandTotal
                    : propDistAmount;
                setValue(`items[${index}].Allocated`, parseFloat(propDistAmount));
              });
            }
          }}
          columnsToShow={{
            InvoiceDate: '',
            DueDate: '',
            Number: '',
            InvoiceReference: '',
            GrandTotal: 0.0,
            Outstanding: 0.0,
            Allocated: 0.0,
          }}
          columnsToAppend={{
            SalesInvoice: 0,
            Allocated: 0,
          }}
        />
      ) : (
        <ItemsComponent
          heading={'Outstanding Invoices'}
          disableAddItem={true}
          disableCalculation={true}
          disableQuantity={true}
          enterOnChangeFunc={false}
          columnsToShow={{
            InvoiceDate: '',
            DueDate: '',
            Number: '',
            InvoiceReference: '',
            GrandTotal: 0.0,
            Outstanding: 0.0,
            Allocated: 0.0,
          }}
          columnsToAppend={{
            PurchaseInvoice: 0,
            Allocated: 0,
          }}
        />
      )} */}
    </>
  );
}
