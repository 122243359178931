

// scroll bar
import 'simplebar-react/dist/simplebar.min.css';

// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css';
import { BrowserRouter } from 'react-router-dom';
// i18n
import 'src/locales/i18n';

// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
// import { LocalizationProvider } from '@mui/x-date-pickers';
import { LocalizationProvider } from 'src/locales';
// components
import SnackbarProvider from './components/snackbar';
import { MotionLazyContainer } from './components/animate';
import { GoogleOAuthProvider, useGoogleLogin } from '@react-oauth/google';
import { clientID } from './pages/profile/integrations';
import { SettingsProvider } from './components/settings';
import cryptoJs from 'crypto-js';
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import {ReactQueryDevtools} from '@tanstack/react-query-devtools'



export default function App() {

  let rights = localStorage.getItem('rights') ? JSON.parse(localStorage.getItem('rights')) :'';
  const bytes = cryptoJs.AES?.decrypt(rights, 'appsgreyerprights');
  const decryptedStringRights = bytes?.toString(cryptoJs.enc.Utf8);
  //localStorage.clear();

  const queryClient = new QueryClient()
  return (
      <GoogleOAuthProvider clientId={clientID}>
          <QueryClientProvider client={queryClient}>
        {/* <LocalizationProvider dateAdapter={AdapterDateFns}> */}
        <LocalizationProvider >
            <BrowserRouter>
              <MotionLazyContainer>
                <SettingsProvider>
                <ThemeProvider>
                      <SnackbarProvider>            
                        <Router rights={decryptedStringRights} />
                      </SnackbarProvider>
                </ThemeProvider>
                </SettingsProvider>
              </MotionLazyContainer>
            </BrowserRouter>
        </LocalizationProvider>
        <ReactQueryDevtools initialIsOpen={false} position='bottom' />
        </QueryClientProvider>
        </GoogleOAuthProvider>
     
  );
}
