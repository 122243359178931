import { DropDownGREYERP } from 'src/controls/dropdown';
import { TextBoxGREYERP } from 'src/controls/textbox';
import UploadGREY from 'src/controls/uploadgrey';
import { SwithGREYERP } from 'src/controls/swithgrey';
import { CardPanel } from 'src/controls/cardpanel';
import { Grid } from '@mui/material';
import { TextEditorGrey } from 'src/controls/texteditorgrey';
import { ItemsComponent } from 'src/controls/items/ItemsComponent';

export default function Product() {
  return (
    <>
      <CardPanel
        heading="Product Details"
        cardItem={
          <>
            <UploadGREY />
            <Grid
              container
              md={3}
              sx={{ maxWidth: '80% !important', flexBasis: '80% !important' }}
              spacing={2}
              rowSpacing={1}
              columns={{ xs: 3, sm: 3, md: 4, lg: 4 }}
            >
              <TextBoxGREYERP name="Name" placeholder="Product Name" required={true} focus={true} />
              <TextBoxGREYERP name="NameArabic" placeholder="اسم المنتج" dir="rtl" />
              <TextBoxGREYERP
                name="Price"
                placeholder="10.300"
                type="Number"
                step="any"
                required={true}
              />
              <TextBoxGREYERP
                name="Sort"
                placeholder="Sort"
                type="Number"
                required={true}
                span={0.5}
              />
              <SwithGREYERP name="Status" span={0.5} />
            </Grid>
          </>
        }
      />

      <CardPanel
        heading="Other Details"
        cardItem={
          <>
            <TextBoxGREYERP
              name="Description"
              span={2}
              multiline={true}
              placeholder="Product Description"
            />
            <TextBoxGREYERP
              name="DescriptionArabic"
              placeholder="تفاصيل المنتج"
              multiLine={true}
              dir="rtl"
              span={2}
            />
            <DropDownGREYERP
              firstItem="0,Main"
              items={`productcategories/name`}
              name="Category"
              required
            />
            <TextBoxGREYERP name="Hyperlink" placeholder="Redirection Link" type="Url" />
            <TextBoxGREYERP
              name="HyperlinkArabic"
              placeholder="Redirection Link Arabic"
              type="Url"
            />
            <TextBoxGREYERP
              span={2}
              placeholder="Internal Notes"
              name="Comments"
              multiline={true}
            />
          </>
        }
      />

      <CardPanel
        heading="Content"
        cardItem={
          <>
            <TextEditorGrey name="Content" placeholder="Content" />
            <TextEditorGrey name="ContentArabic" placeholder="المحتوى" dir="right" />
          </>
        }
      />
      <ItemsComponent
        heading="Product Variations"
        disableCalculation={true}
        disableQuantity={true}
        tablename={'productvarients'}
        enterOnChangeFunc={false}
        columnsToShow={{ Size: 0, Colour: 0, Price: 0.0, Image: '' }}
      />
    </>
  );
}
