import { useContext } from 'react';
import { CardPanel } from 'src/controls/cardpanel';
import { DropDownGREYERP } from 'src/controls/dropdown';
import { TextBoxGREYERP } from 'src/controls/textbox';
import { ItemsComponent } from 'src/controls/items/ItemsComponent';
import Picker from 'src/controls/picker';
import { FormContext } from 'src/layouts/dashboard/DashboardLayout';

export default function DeliveryNotes() {
  const { object, setObject, objectEdit, setExistingItemsData, formObject, setFormObject } =
    useContext(FormContext);
  const { Series = '', Number = '', Lead } = object || {};
  const { Series: EditSeries = '', Number: EditNumber = '' } = objectEdit || {};
  const { SalesOrders, Sections } = formObject || {};

  return (
    <>
      <CardPanel
        heading="Fields"
        cardItem={
          <>
            <Picker type='date' required={true} date={new Date()} name="Date" type="date" focus={true} />
            <TextBoxGREYERP name="Subject" placeholder="Delivery Note Subject" />

            <TextBoxGREYERP name="Reference" placeholder="Delivery Note Reference" />

            <DropDownGREYERP
              required={true}
              items={`relatedto/name`}
              name="Lead"
              onChange={(e, selectedValue) =>
                HandleOnChangeValue({ Lead: selectedValue?.ID }, setObject, [
                  GetAndSetList(
                    `getItems/salesorders/name/${localStorage.getItem(
                      'username'
                    )}/${localStorage.getItem('client')}/${parseInt(selectedValue?.ID)}`,
                    setFormObject,
                    'SalesOrders'
                  ),
                ])
              }
              postBackSelectedValue={Lead}
            />
            <TextBoxGREYERP
              defaultValue={Series ? Series + Number : EditSeries + EditNumber}
              disabled
              required
              name="Number"
              placeholder="Delivery Note Number"
            />

            <DropDownGREYERP
              postBackitems={SalesOrders}
              onChange={(e, selectedValue) =>
                HandleOnChangeValue({ Warehouse: selectedValue?.ID }, setObject, [
                  GetAndSetList(
                    `getCustomPostBackItem/salesorderdetails/${localStorage.getItem(
                      'username'
                    )}/${localStorage.getItem('client')}/${parseInt(selectedValue?.ID)}`,
                    setExistingItemsData,
                    null
                  ),
                ])
              }
              required={true}
              name="SalesOrder"
            />

            <DropDownGREYERP
              required={true}
              onChange={(e, selectedValue) =>
                HandleOnChangeValue({ Warehouse: selectedValue?.ID }, setObject, [
                  GetAndSetList(
                    `getItems/warehousesections/name/${localStorage.getItem(
                      'username'
                    )}/${localStorage.getItem('client')}/${parseInt(selectedValue?.ID)}`,
                    setFormObject,
                    'Sections'
                  ),
                ])
              }
              items={`warehouses/name`}
              name="Warehouse"
            />

            <DropDownGREYERP postBackitems={Sections} name="Section" />

            <TextBoxGREYERP name="Comments" multiline={true} placeholder="Internal Notes" />
          </>
        }
      />

      <ItemsComponent
        formType="saleable"
        disableAddItem={true}
        disableQuantity={true}
        disableCalculation={true}
        disableItem={true}
        columnsToAppend={{ ID: 0, Item: 0, DeliveredUnit: 0, DeliveredQuantity: 0, Note: '' }}
        columnsToShow={{
          Item: 0,
          OrderedQuantity: 0,
          OrderedUnit: 0,
          DeliveredQuantity: 0,
          DeliveredUnit: 0,
          Note: '',
        }}
      />
    </>
  );
}
