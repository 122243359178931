import PropTypes from 'prop-types';
// @mui
import {
  Box,
  Card,
  Stack,
  Button,
  Divider,
  TextField,
  CardHeader,
  Typography,
  CardContent,
  InputAdornment,
} from '@mui/material';
// utils
import { fCurrency } from '../../../../utils/formatNumber';
// components
import Iconify from '../../../../components/iconify';
import { useTheme, styled } from '@mui/material/styles';

// ----------------------------------------------------------------------

CheckoutSummary.propTypes = {
  onEdit: PropTypes.func,
  total: PropTypes.number,
  discount: PropTypes.number,
  subtotal: PropTypes.number,
  shipping: PropTypes.number,
  enableEdit: PropTypes.bool,
  enableDiscount: PropTypes.bool,
  onApplyDiscount: PropTypes.func,
};

export default function CheckoutSummary({
  total,
  onEdit,
  discount,
  subtotal,
  vat,
  onApplyDiscount,
  enableEdit = false,
  enableDiscount = false,
}) {
  const theme = useTheme();
  return (
    <Card sx={{backgroundColor:theme.palette.primary.main,color:'white'}} >
      <CardHeader
        // title="Order Summary"
       
        action={
          enableEdit && (
            <Button size="small" onClick={onEdit} startIcon={<Iconify icon="eva:edit-fill" />}>
              Edit
            </Button>
          )
        }
      />

      <CardContent sx={{py:1}}>
        <Stack spacing={1} sx={{pb:0}}>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h6" >
              Sub Total
            </Typography>
            <Typography variant="h6">{subtotal ? fCurrency(subtotal): '0.000'}</Typography>
          </Stack>

          <Stack direction="row" justifyContent="space-between">
            <Typography variant="subtitle1">
              Discount
            </Typography>
            <Typography variant="subtitle1">{discount ? fCurrency(-discount) : '0.000'}</Typography>
          </Stack>

          <Stack direction="row" justifyContent="space-between">
            <Typography variant="subtitle1" >
              VAT
            </Typography>
            <Typography variant="subtitle1">
              {vat ? fCurrency(vat) : '0.000'}
            </Typography>
          </Stack>

          <Divider />

          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h4">Total</Typography>
            <Box sx={{ textAlign: 'right' }}>
              <Typography variant="h4" >
                {total ? fCurrency(total):'0.000'}
              </Typography>
       
            </Box>
          </Stack>

          {/* {enableDiscount && onApplyDiscount && (
            <TextField
              fullWidth
              placeholder="Discount codes / Gifts"
              value="DISCOUNT5"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Button onClick={() => onApplyDiscount(5)} sx={{ mr: -0.5 }}>
                      Apply
                    </Button>
                  </InputAdornment>
                ),
              }}
            />
          )} */}
        </Stack>
      </CardContent>
    </Card>
  );
}
