
import { CardPanel } from 'src/controls/cardpanel';
import { DropDownGREYERP } from 'src/controls/dropdown';
import { TextBoxGREYERP } from 'src/controls/textbox';
import { Titles,  NoofEmployees, PaymentTerms } from 'src/data/data';
import { DropDownENUM } from 'src/controls/dropdownenum';
import UploadGREY from 'src/controls/uploadgrey';
import ImageContainer from 'src/controls/imagecontainer';
import Picker from 'src/controls/picker';
import { toTitleCase } from 'src/data/scripts';

export default function Lead() {

  return (
    <>
      <CardPanel
        heading={ toTitleCase(window.location.pathname.split('/')[2]?.slice(0,-1)) + " Details"}
        cardItem={
          <>
         <UploadGREY />
         <ImageContainer>
            <TextBoxGREYERP  placeholder="Company Name" name="Company" required={true} focus={true} span={1.5} />
            <DropDownENUM items={Titles} name="Title" required={true} span={.5}/>
            <TextBoxGREYERP  placeholder="John" name="FirstName" required={true} />
            <TextBoxGREYERP  placeholder="Doe" name="LastName" />
            <TextBoxGREYERP  placeholder="General Manager" name="Designation" />
            <DropDownGREYERP  items="leadtypes/name" firstItem="0,None" name="LeadType" label="Type" />
            <DropDownGREYERP items="leadsource/name" firstItem="0,None" name="LeadSource" label="Source"/>
            <DropDownGREYERP items="leadstatus/name" firstItem="0,None" name="LeadStatus" label="Status"/>
            <DropDownGREYERP items="assignee/name" firstItem="Only Me,Only Me" name="Access" multiple={true} required={true}  />
            <TextBoxGREYERP  placeholder="Lead Description" name="Description" span={3} multiline={true} />
            <TextBoxGREYERP  placeholder="Comments (Optional)" multiline={true}  span={4} name="Comments" />
            </ImageContainer>
          </>
        }
      />
      {window.location.pathname.split('/')[2]?.toLowerCase() == "corporates" && (
  <CardPanel
  heading="Commercial Details"
  cardItem={
    <>
     <TextBoxGREYERP label="Current Balance" name="Balance" placeholder="Opening Balance" required={true} />
              <TextBoxGREYERP name="CreditLimit" placeholder="Credit Limit" required={true} />
              <DropDownGREYERP items={`salespricelists/name`}  name="PriceList" />
              <DropDownENUM items={PaymentTerms} name="PaymentTerms" />
              <TextBoxGREYERP label="VAT Number" name="TaxNumber" placeholder="VAT Number" />
    </>
  }
/>
)}

      <CardPanel
        heading="Contact Details"
        cardItem={
          <>
            <TextBoxGREYERP  placeholder="2467XXXX" type="number" name="Office" />
            <TextBoxGREYERP  placeholder="12" type="number" name="Extension" />
            <TextBoxGREYERP  placeholder="09686372XXX" name="Phone" />
            <TextBoxGREYERP  placeholder="09686372XXX" name="Phone2" />
            <TextBoxGREYERP  placeholder="someone@something.com" name="Email" />
            <TextBoxGREYERP  placeholder="www.something.com" name="Website" />
            <TextBoxGREYERP placeholder="Ghala" name="Location" />
            <TextBoxGREYERP  placeholder="Way# XXX, Ghala, Muscat..." name="Address" multiline={true} span={3} />
          </>
        }
      />
{window.location.pathname.split('/')[2]?.toLowerCase() == "individuals" && (
<>
        <CardPanel
        heading="License Details"
        cardItem={
          <>
           <TextBoxGREYERP name="License" type="file" value="" />
            <TextBoxGREYERP label="License Number" placeholder="100857418" name="LicenseNumber" />
            <Picker type='date' label="Expiry" placeholder="ex" name="LicenseExpiry" />
            <TextBoxGREYERP label="Issued At" placeholder="Muscat" name="LicenseIssue" />

          </>
        }
      />
        <CardPanel
        heading="Resident Card Details"
        cardItem={
          <>
           <TextBoxGREYERP name="License" type="file" value="" />
            <TextBoxGREYERP placeholder="100857418" name="IDCardNumber" />
            <Picker type='date' label="Expiry" placeholder="ex" name="IDCardExpiry" />
            <TextBoxGREYERP label="Issued At" placeholder="Muscat" name="IDCardIssue" />

          </>
        }
      />
         <CardPanel
        heading="Passport Details"
        cardItem={
          <>
           <TextBoxGREYERP name="Passport" type="file" value="" />
            <TextBoxGREYERP label="License Number" placeholder="L60069871" name="PassportNumber" />
            <Picker type='date' label="Expiry" placeholder="ex" name="PassportExpiry" />
            <TextBoxGREYERP label="Issued At" placeholder="Muscat" name="PassportIssue" />

          </>
        }
      />
      </>
)}
        <CardPanel
        heading="Other Details"
        cardItem={
          <>
          <DropDownGREYERP  items="industries/name" firstItem="0,None" name="Industry"  />
          <DropDownENUM  items={NoofEmployees}  name="NoofEmployees" label="No. Of Employees"  />
          <DropDownGREYERP  items="territories/name" firstItem="0,None" name="Territory"  />
          </>
        }
      />


{/*
      <ItemsComponent
        tablename="Notes"
        heading={'Note'}
        disableCalculation={true}
        disableQuantity={true}
        columnsToShow={{ text: '' }}
      />

        <ItemsComponent
        tablename="Meetings"
        heading={'Events'}
        disableCalculation={true}
        disableQuantity={true}
        columnsToShow={{ Title: '',Date: '',Duration: '',Location: '',Description: '' }}
      />

      <ItemsComponent
        tablename="Calls"
        heading={'Call'}
        disableCalculation={true}
        disableQuantity={true}
        columnsToShow={{ Purpose: '',Description: '',DateTime: '',Status: '',Comments: '' }}
      />

      <ItemsComponent
        tablename="tasks"
        heading={'Tasks'}
        disableCalculation={true}
        disableQuantity={true}
        columnsToShow={{ Name: '',Description: '',DueDateTime: '',Project: '',Status: '',Assignee: '',Priority: '' }}
      />  */}



    </>
  );
}
