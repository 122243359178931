import { CardPanel } from "src/controls/cardpanel";
import { DropDownGREYERP } from "src/controls/dropdown";
import { TextBoxGREYERP } from "src/controls/textbox";

export default function JobApplication()
{ 
    return(
        <>
      <CardPanel heading="Job Application Details" cardItem={<>
      <TextBoxGREYERP label="Name" name="Name" placeholder="Person Name" required focus={true} />
      <TextBoxGREYERP label="Phone" name="Phone" placeholder="009689461XXX" type="Number" required />
      <TextBoxGREYERP label="Alternate Phone" name="Phone2" placeholder="009719461XXX" type="Number" />
      <TextBoxGREYERP label="Email" name="Email" placeholder="xxx@abc.com" type="Email" required />
      <DropDownGREYERP items={`JobPosts/JobId`} name="JobPost" required={true} />
      <TextBoxGREYERP label="Total Experience" sufix={"Years"} name="TotalExperience" placeholder="Total Experience" type="Number" />
      <TextBoxGREYERP label="Relevant Experience" sufix={"Years"} name="RelevantExperience" placeholder="Relevant Experience" type="Number" /> 
      <TextBoxGREYERP label="Notice Period" sufix="Days" name="NoticePeriod" placeholder="Notice Period" type="Number" />
      <TextBoxGREYERP label="Current Salary" name="CurrentSalary" placeholder="Current Salary" type="Number" required step="any" />
      <TextBoxGREYERP label="Salary Expectation" name="SalaryExpectation" placeholder="Salary Expectation" type="Number" required step="any" />
      <DropDownGREYERP items={`JobApplicationSource/name`} name="Source" required={true} /> 
      <DropDownGREYERP items={`JobApplicationStatus/name`} name="Status"  required={true} />
      <TextBoxGREYERP label="Resume" name="Resume" type="file" /> 
      <TextBoxGREYERP label="Cover Letter" name="CoverLetter" type="file" />
      <TextBoxGREYERP label="Description" name="Description" span={2} multiline={true}  MultiLine={true} />
      <TextBoxGREYERP label="Comments" placeholder="Internal Notes (Optional)"  span={2} name="Comments" multiline={true}  MultiLine={true} />

</>} />


   </>


  )

 
}
