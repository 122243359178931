import { useContext, useState } from 'react';
import { MenuItem, Stack, Typography } from '@mui/material';
import MenuPopover from '../../../components/menu-popover';
import { FormContext } from '../DashboardLayout';

// ----------------------------------------------------------------------

export default function CompanyPopover() {
  const { clientId, setClientId, setDataFetched } = useContext(FormContext);

  let companies = JSON.parse(localStorage.getItem('companies') ?? []);
  const [selectedVal, setselectedVal] = useState(clientId);
  let selectedCompany;

  companies.map((item) => {
    if (item.ID == localStorage.getItem('client')) {
      selectedCompany = item.Name;
    }
  });
  const [selectedDisplayVal, setselectedDisplayVal] = useState(selectedCompany);

  const [openPopover, setOpenPopover] = useState(false);
  const handleOpenPopover = (event) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const handleCompanyChange = (company) => {
    if (company) {
      localStorage.setItem('client', company.ID);
      setClientId(company.ID);
      setDataFetched(false);
      setselectedVal(company.ID);
      setselectedDisplayVal(company.Name);
      handleClosePopover();
    }
  };

  return (
    <>
      <Typography
        variant="body2"
        onClick={handleOpenPopover}
        sx={{
          ...(openPopover && {
            bgcolor: 'action.selected',
          }),
        }}
      >
        <Typography
          component={'span'}
          variant="body1"
          sx={{
            color: 'white',
            fontSize: '13px',
            mr: 2,
            cursor: 'pointer',
            width: '125px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            height: '20px',
            display: 'block',
          }}
        >
          {selectedDisplayVal}
        </Typography>
      </Typography>

      <MenuPopover open={openPopover} onClose={handleClosePopover}>
        <Stack sx={{ '& .MuiMenuItem-root': { fontSize: '13px' } }} spacing={0.75}>
          {companies &&
            companies.map((option) => (
              <MenuItem
                key={option.ID}
                selected={option.ID == selectedVal}
                onClick={() => handleCompanyChange(option)}
              >
                {option.Name}
              </MenuItem>
            ))}
        </Stack>
      </MenuPopover>
    </>
  );
}
