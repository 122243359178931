import { DropDownGREYERP } from 'src/controls/dropdown';
import { DropDownENUM } from 'src/controls/dropdownenum';
import { TextBoxGREYERP } from 'src/controls/textbox';
import PopUpPanel from 'src/controls/popuppanel';
import { AddonsTypes, Areas, Banks } from 'src/data/data';
import { SwithGREYERP } from 'src/controls/swithgrey';

export default function Addon() {
 
  return (
    <>
      <PopUpPanel
        heading={'Add'}
        child={
          <>           
              <TextBoxGREYERP  required={true} focus={true} name="Name" placeholder="Addon Name" span={2} />
              <TextBoxGREYERP  placeholder="Addon Description" name="Description" span={2} multiline={true} MultiLine={true} span={2} rows={3} />   
              <DropDownENUM items={AddonsTypes} name="Type"  required={true} />
              <TextBoxGREYERP  name="Price" placeholder="3.000" type="Number" step="any" required={true} />
              <DropDownGREYERP items={"taxCategories/name"} name="Tax"  required={true} />
              <SwithGREYERP  name="Status" span={.5} />
            <TextBoxGREYERP  placeholder="Internal Notes" name="Comments" multiline={true} MultiLine={true} span={2} rows={3}/>
          </>
        }
      />
    </>
  );
}
