
import { CardPanel } from 'src/controls/cardpanel';
import { DropDownGREYERP } from 'src/controls/dropdown';
import { TextBoxGREYERP } from 'src/controls/textbox';
import Picker from 'src/controls/picker';
export default function Damage() {

  return (
    <>

      <CardPanel
        heading="Damage Details"
        cardItem={
          <>
        <Picker type='date' label="Incident Date" name="DateTime" placeholder="Damage Date" required={true} focus={true}></Picker>
        <DropDownGREYERP items="vehicles/name" name="Vehicle" required={true} />
        <DropDownGREYERP items="bookings/name" name="Booking"  />
        <DropDownGREYERP items="damagetypes/name" name="Type" required={true} />
        <TextBoxGREYERP  placeholder="3.050"  name="EstimatedCost" type="Number" required={true} step="any"/>
        <Picker type='date' label="Estimated Repair Date" name="EstimatedDateTime" placeholder="Repair Date" ></Picker>
        <TextBoxGREYERP placeholder="Damage Description" name="Description" span={2} required={true} multiline={true} />
        <TextBoxGREYERP placeholder="Comments (Optional)" name="Comments" multiline={true} span={2} />
          </>
        }
      />


    </>
  );
}
