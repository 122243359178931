import React from 'react';
import FormPrint from './accounts/formprint';
import { Box, Grid, Typography } from '@mui/material';
import Image from '../components/image';
import footerimg from '../assets/grey-logo-bird-black.png';
function LinkPage() {
  return (
    <>
      <FormPrint />
      <Box>
        <Grid container alignItems={'center'} justifyContent={'center'} sx={{ py: '40px' }}>
          <Grid container direction={'column'}>
          <Image
              disabledEffect
              alt="illustration-invite"
              src="/assets/illustrations/characters/logo-black.png"
              sx={{
                left: 60,
                zIndex: 9,
                width: 50,
                position: 'relative',
                filter: 'drop-shadow(0 12px 24px rgba(0,0,0,0.24))',
              }}
            />
            <image href={footerimg} height="100" width="50" />
            <Typography
              sx={{
                m: 0,
                lineHeight: 1.5,
                fontSize: '0.75rem',
                fontWeight: '400',
                fontFamily: 'Public Sans, sans-serif',
                'a':{
                  color:'#3d86c7 !important',
                  textDecoration:"none",
                  cursor:"pointer"
                }
              }}
            >
              {' '}
              <a target='_blank' to={"https://greyerp.com"}>GREY ERP</a>  © All rights reserved.
            </Typography>
       
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default LinkPage;
