import { DropDownGREYERP } from 'src/controls/dropdown';
import { default as DataGrid } from '../Grid';
import { useContext } from 'react';
import { FormContext } from 'src/layouts/dashboard/DashboardLayout';
import { useState } from 'react';
import { Grid, Stack } from '@mui/material';
import PrintDocumentLayout from 'src/controls/printdocumentlayout';

export default function AccountStatement() {
  const { clientId } = useContext(FormContext);
  const [apiUrl, setapiUrl] = useState();
  const [accountSelected, setAccountSelected] = useState();
  const [accountSelectedWithName, setAccountSelectedWithName] = useState();
  const [dates, setDates] = useState({
    FirstDate: '',
    LastDate: '',
  });

  async function onChnageAccount(e, selectedValue) {
    if (selectedValue != null) {
      setAccountSelected(selectedValue?.ID);
      setAccountSelectedWithName(selectedValue);

      let firstDate = localStorage.getItem('firstdate');
      let LastDate = localStorage.getItem('lastdate');
      let name = selectedValue?.Name.replace(/[0-9]/g, '');

      setDates({
        FirstDate: firstDate,
        LastDate: LastDate,
        Name: name,
      });

      setapiUrl(
        `getreports/accountstatementreport/${localStorage.getItem(
          'client'
        )}/${firstDate}/${LastDate}/${selectedValue?.ID}`
      );
    }
  }

  return (
    <>
      <PrintDocumentLayout dates={dates}>
        <DataGrid
          name="AccountStatement"
          apiExternalUrl={apiUrl}
          openPrintTable={false}
          selectedEntity={accountSelectedWithName}
          TopToolbarCustomActions={
            <Grid container sx={{ m: 0, p: 0, mb: 1, width: '40%', displayPrint: 'none' }}>
              <DropDownGREYERP
                items={`accounts/name`}
                name="Account"
                required
                focus={true}
                onChange={onChnageAccount}
                span={12}
                reTrigger={clientId}
                postBackSelectedValue={accountSelected}
              />
            </Grid>
          }
        />
      </PrintDocumentLayout>
    </>
  );
}
