import { useContext, useEffect, useState } from 'react';
import { FormContext } from 'src/layouts/dashboard/DashboardLayout';
import { CardPanel } from 'src/controls/cardpanel';
import { DropDownGREYERP } from 'src/controls/dropdown';
import { TextBoxGREYERP } from 'src/controls/textbox';
import { SwithGREYERP } from 'src/controls/swithgrey';
import Picker from 'src/controls/picker';
import React from 'react';
import { ItemsComponent } from 'src/controls/items/ItemsComponent';
import SwapSections from 'src/controls/SwapSections';
import { ApprovalStages, HttpRequestGet } from 'src/data/data';
import {
  GetAndSetList,
  GetVoucherNumber,
  HandleOnChangeValue,
  HttpRequest,
} from 'src/data/scripts';
import { DropDownENUM } from 'src/controls/dropdownenum';
import { useFormData } from 'src/hooks/useQueryHooks';
import { useQuery } from '@tanstack/react-query';

export default function Order() {
  return (
    <>
      <SwapSections
        List={[
          <Fields />,
          <div id="divItems" className="divItems">
            <ItemsComponent
              formType={
                window.location.pathname.split('/')[1]?.toUpperCase() == 'SALES'
                  ? 'saleable'
                  : 'purchasable'
              }
              columnsToShow={{
                Item: 0,
                Description: '',
                Quantity: 1,
                Unit: 0,
                Price: 0,
                DiscountPercentage: 0,
                Discount: 0.0,
                Tax: 3,
                TaxAmount: 0,
                Total: 0.0,
              }}
              columnsToAppend={{
                Item: 0,
                Description: '',
                Quantity: 1,
                Unit: 0,
                Price: 0,
                DiscountPercentage: 0,
                Discount: 0.0,
                Tax: 3,
                TaxAmount: 0,
                Total: 0.0,
              }}
            />
          </div>,
        ]}
      />
    </>
  );
}

function Fields() {
  const {
    // setObject,
    // objectEdit,
    setExistingItemsData,
    setFormObject,
    popupEditId,
    // object,
    formObject,
  } = useContext(FormContext);

  let formID = window.location.pathname?.split('/')[2]?.replaceAll('-', '');
  let ID = window.location.pathname?.split('/')[3]?.replaceAll('-', '') ?? popupEditId;

  const { setFormData: setObject, updateWholeForm } = useFormData();
  const { data: object } = useQuery({
    queryKey: 'form',
  });

  const { data: objectEdit } = useQuery({
    queryKey: [formID, ID],
    select: (res) => res[0],
    enabled: Boolean(formID) && !!ID,
  });

  const { Series = '', Number = '', DocumentTo, PriceList, Source, PaymentDueDate } = object || {};
  const { Series: EditSeries = '', Number: EditNumber = '' } = objectEdit || {};
  const { SourceList } = formObject || {};
  let sales = window.location.pathname.split('/')[1]?.toUpperCase() == 'SALES';

  useEffect(() => {
    if (PriceList) {
      GetAndSetList(
        `getRecordById/${
          sales ? 'salespricelistdetails' : 'purchasepricelistdetails'
        }/${PriceList}`,
        setFormObject,
        'FullPriceList'
      );
    }
    if (objectEdit && Object.keys(objectEdit).length > 0) {
      GetAndSetList(
        `getItems/${
          sales ? 'purchaseordersourcelistedit' : 'purchaseordersourcelistedit'
        }/name/${localStorage.getItem('username')}/${localStorage.getItem('client')}/${
          objectEdit['DocumentTo']
        }/${window.location.pathname.split('/')[3]}`,
        setFormObject,
        'SourceList'
      );
    }
  }, [PriceList]);

  return (
    <div id="divFields" className="divFields">
      <CardPanel
        heading="Document Details"
        cardItem={
          <>
            <Picker type="date" focus={true} name="Date" date={new Date()} required={true}></Picker>
            <DropDownGREYERP
              onChange={(e, selectedValue) =>
                HandleOnChangeValue(
                  {
                    DocumentTo: selectedValue?.ID,
                  },
                  setObject,
                  [
                    GetVoucherNumber(selectedValue?.Name, setObject, null, EditNumber),
                    GetAndSetList(
                      `getItems/${
                        sales ? 'pricelistsclient' : 'pricelistsvendor'
                      }/name/${localStorage.getItem('username')}/${localStorage.getItem(
                        'client'
                      )}/${selectedValue?.ID}`,
                      setObject,
                      'PriceList'
                    ),
                    GetAndSetList(
                      `getItems/${
                        sales ? 'salesordersourcelist' : 'purchaseordersourcelist'
                      }/name/${localStorage.getItem('username')}/${localStorage.getItem(
                        'client'
                      )}/${selectedValue?.ID}`,
                      setFormObject,
                      'SourceList'
                    ),
                    (async function GetPaymentDate() {
                      let DocumentTo = await HttpRequest(
                        `getItems/${
                          sales ? 'paymentduedate' : 'paymentduedatevendor'
                        }/name/${localStorage.getItem('username')}/${localStorage.getItem(
                          'client'
                        )}/${selectedValue?.ID}`
                      );

                      if (DocumentTo.length > 0) {
                        let dueDate = new Date();
                        dueDate.setDate(
                          dueDate.getDate() + parseInt(DocumentTo[0]?.Paymentterms ?? 0)
                        );
                        HandleOnChangeValue(
                          {
                            PaymentDueDate: dueDate?.toISOString(),
                          },
                          setObject
                        );
                      }
                    })(),
                  ]
                )
              }
              postBackSelectedValue={DocumentTo}
              items={sales ? `clients/Name` : `vendors/Name`}
              name="DocumentTo"
              required={true}
              span={2}
            />
            <DropDownGREYERP
              name="Source"
              label="Source Document"
              postBackitems={SourceList}
              postBackSelectedValue={Source}
              onChange={(e, selectedValue) =>
                HandleOnChangeValue({ Source: selectedValue?.ID }, setObject, [
                  GetAndSetList(
                    `getCustomPostBackItem/${
                      sales
                        ? selectedValue?.Name?.startsWith('QUO')
                          ? 'quotationdetails'
                          : 'salesorderdetails'
                        : 'vendorquotationdetails'
                    }/${localStorage.getItem('username')}/${localStorage.getItem(
                      'client'
                    )}/${parseInt(selectedValue?.ID)}`,
                    setExistingItemsData,
                    null
                  ),
                ])
              }
            />
            <DropDownGREYERP
              postBackSelectedValue={PriceList}
              items={sales ? `salespricelists/Name` : `purchasepricelists/Name`}
              name="PriceList"
              onChange={(e, selectedValue) =>
                HandleOnChangeValue({ PriceList: selectedValue?.ID }, setObject)
              }
            />
            <TextBoxGREYERP
              disabled
              name="Number"
              defaultValue={Series ? Series + Number : EditSeries + EditNumber}
              required={true}
            />
            <Picker
              type="date"
              placeholder="Due Date"
              name="PaymentDueDate"
              label="Due Date"
              postBackDate={PaymentDueDate}
            ></Picker>
            <Picker type="date" placeholder="Items Delivery Date" name="DeliveryDate"></Picker>
            <DropDownGREYERP name="RelatedToID" label="Related To" items={`relatedto/name`} />
            <DropDownGREYERP items={`Projects/name`} name="Project" />
            <TextBoxGREYERP placeholder="Document Subject" name="Subject" />
            <TextBoxGREYERP placeholder="Document Reference" name="Reference" />
            <TextBoxGREYERP
              placeholder="Internal Notes"
              name="Comments"
              multiline={true}
              span={2}
            />
            {/* <DropDownENUM name="Status" items={ApprovalStages} disabledItems={['Draft','Rejected']} /> */}
            <SwithGREYERP name="Print" />
          </>
        }
      />
    </div>
  );
}
