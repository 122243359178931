import PropTypes from 'prop-types';
import { Typography, Stack } from '@mui/material';
import Image from '../../components/image';
//
import { StyledRoot, StyledSectionBg, StyledSection, StyledContent } from './styles';
import LogoBlack from 'src/components/logo/LogoBlack';
import { SeoIllustration } from '../../assets/illustrations';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

LoginLayout.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  illustration: PropTypes.string,
};

export default function LoginLayout({ children, illustration, title }) {
  const { t } = useTranslation();
  return (
    <StyledRoot>
      <LogoBlack
        sx={{
          zIndex: 9,
          position: 'absolute',
        }}
      />

      <StyledSection>
        <h1 variant="h3" sx={{ mb: 10, maxWidth: 480, textAlign: 'center' }}>
          {title || t('Hi, welcome back to') + ' Grey ERP'}
        </h1>

        <SeoIllustration
          sx={{
            width: 400,
            margin: { xs: 'auto', md: 'inherit' },
          }}
        />

        <StyledSectionBg />
      </StyledSection>

      <StyledContent sx={{ alignItems: 'center', justifyContent: 'center' }}>
        <Stack sx={{ width: 1 }}> {children} </Stack>
      </StyledContent>
    </StyledRoot>
  );
}
