import PropTypes from 'prop-types';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Card, Typography, Stack, Link, Grid } from '@mui/material';
// utils
import { fNumber, fPercent } from '../utils/formatNumber';
// components
import Iconify from '../components/iconify';
import Chart from '../components/chart';

import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

export default function Tile({
  title,
  percent,
  bgColor,
  total,
  iconWidth,
  color,
  chart,
  icon,
  suffix,
  prefix,
  link,
  sx,
  ...other
}) {
  const { t } = useTranslation();

  return (
    <Link to={link} component={RouterLink} underline="none" style={{ textDecoration: 'none' }}>
      <Card
        sx={{
          display: 'flex',
          mb: 2,
          bgcolor: bgColor ? bgColor : 'white',
          textAlign: 'center',
          alignItems: 'center',
          p: 3,
          ...sx,
        }}
        {...other}
      >
        <Box sx={{ flexGrow: 1 }}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Iconify sx={{ color: color ?? 'white' }} icon={icon} width={iconWidth ?? 30} />
            </Grid>
            <Grid item>
              <Typography sx={{ color: color ?? 'white', textAlign: 'right' }} variant="h4">
                {prefix && prefix.toString()} {fNumber(total)} {suffix && suffix.toString()}
              </Typography>
              <Typography variant="subtitle2" sx={{ color: color ?? 'white' }}>
                {t(title)}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Card>
    </Link>
  );
}
