import { useContext, useEffect, useState } from 'react';
import { CardPanel } from 'src/controls/cardpanel';
import { TextBoxGREYERP } from 'src/controls/textbox';
import { SwithGREYERP } from 'src/controls/swithgrey';
import Picker from 'src/controls/picker';
import React from 'react';
import { ItemsComponent } from 'src/controls/items/ItemsComponent';
import { FormContext } from 'src/layouts/dashboard/DashboardLayout';

import { GetVoucherNumber, getClientName } from 'src/data/scripts';
import { DropDownGREYERP } from 'src/controls/dropdown';

export default function JournalVoucher() {
  const { object, setObject, objectEdit, formObject, setFormObject } = useContext(FormContext);
  const { Series = '', Number = '' } = object || {};
  const { Series: EditSeries = '', Number: EditNumber = '' } = objectEdit || {};
  const { JVData, JVDataWithoutFilteration } = formObject || {};

  async function GetJV() {
    let data = await HttpRequestGet(
      `getItems/journalentries/name/${localStorage.getItem('username')}/${localStorage.getItem(
        'client'
      )}`
    );

    setFormObject((prev) => {
      return { ...prev, JVDataWithoutFilteration: data };
    });

    const idMapping = data.reduce((acc, el, i) => {
      acc[el.ID] = i;
      return acc;
    }, {});

    let root;
    let header = [];
    let dataTbl1 = [];

    data.forEach((el) => {
      if (el.parentid == '0') {
        header.push(el.ID);
        return;
      }
    });

    data.forEach((el) => {
      if (el.parentid == '0') {
        root = el;
        return;
      }
      const parentEl = data[idMapping[el.parentid]];
      parentEl && (parentEl.subRows = [...(parentEl.subRows || []), el]);
      if (parentEl && header.includes(parentEl.ID)) {
        dataTbl1.push(parentEl);
      }
    });

    dataTbl1 = [...new Set(dataTbl1)];
    addingDepth(dataTbl1, 0);
    setFormObject((prev) => {
      return { ...prev, JVData: dataTbl1 };
    });
  }

  function addingDepth(items, depth) {
    items.map((i) => {
      i['depth'] = depth;
      i.subRows && addingDepth(i.subRows, depth + 1);
    });
  }

  useEffect(() => {
    GetJV();
    if (isNaN(window.location.pathname.split('/')[3])) {
      GetVoucherNumber(getClientName(), setObject);
    }
  }, []);

  return (
    <>
      <CardPanel
        heading="Fields"
        cardItem={
          <>
            <Picker type="date" name="Date" required={true} focus={true}></Picker>
            <TextBoxGREYERP
              placeholder="Journal Number"
              disabled
              name="Number"
              defaultValue={Series ? Series + Number : EditSeries + EditNumber}
              required={true}
            />
            <TextBoxGREYERP placeholder="Journal Reference" name="Reference" />
            <DropDownGREYERP items={`costcenters/name`} name="CostCenter" />
            <DropDownGREYERP items={`Projects/name`} name="Project" />
            <SwithGREYERP name="Print" />
            <TextBoxGREYERP
              placeholder="Internal Notes"
              name="Comments"
              multiline={true}
              span={2}
            />
          </>
        }
      />

      <ItemsComponent
        heading={'Entries'}
        dataTbl={JVData}
        dataWithoutFilteration={JVDataWithoutFilteration}
        columnsToShow={{ Account: 0, Debit: 0, Credit: 0, Narration: '' }}
        disableCalculation={true}
        disableQuantity={true}
      />
    </>
  );
}
