import { Box, Checkbox, Grid, IconButton, Modal, Stack, Tooltip } from '@mui/material';
import React, { useRef } from 'react';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Typography from '@mui/material/Typography';
import Iconify from 'src/components/iconify/Iconify';
import { useContext } from 'react';
import { FormContext } from 'src/layouts/dashboard/DashboardLayout';
import { LoadingButton } from '@mui/lab';
import { useState } from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import { useSnackbar } from 'notistack';
import { msalConfig, loginRequest, tokenRequest } from 'src/outlook/authConfig';
import { callMSGraph, updateUI, graphConfig, callCalendarsGraph } from 'src/outlook/graph';
import { getTokenPopup } from 'src/outlook/authPopup';
import { useEffect } from 'react';
import { TextBoxGREYERP } from 'src/controls/textbox';

export const clientID = '32960899767-1ptulk38bqfkpehvim9ragdu7mnvf89d.apps.googleusercontent.com';
export const clientSecret = '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';
export const redirectUri = 'https://apps.greyerp.com';

export default function Integrations({ userInfo }) {
  const {
    openExternalProviderPopup,
    setOpenExternalProviderPopup,
    setExternalEmails,
    setDataFetched,
  } = useContext(FormContext);
  const [openExchangeServerPopup, setOpenExchangeServerPopup] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const handleClose = () => {
    setOpenExternalProviderPopup(false);
    setOpenExchangeServerPopup(false);
  };

  const [itemSelected, setItemselected] = useState(false);

  const [dataFetchedServer, setDataFetchedServer] = useState(false);

  // user: "info@masrental.com",
  //     password: "M@SR847rt$$",
  //     host: "cmx5.my-hosting-panel.com",
  //     port: 993,

  let labelMails = [
    {
      id: 'all',
      type: 'system',
      name: 'all mail',
      unreadCount: 0,
    },
    {
      id: 'inbox',
      type: 'system',
      name: 'inbox',
      unreadCount: 0,
    },
    {
      id: 'sent',
      type: 'system',
      name: 'sent',
      unreadCount: 0,
    },
    {
      id: 'drafts',
      type: 'system',
      name: 'drafts',
      unreadCount: 0,
    },
    {
      id: 'trash',
      type: 'system',
      name: 'trash',
      unreadCount: 0,
    },
    {
      id: 'spam',
      type: 'system',
      name: 'spam',
      unreadCount: 0,
    },
    {
      id: 'important',
      type: 'system',
      name: 'important',
      unreadCount: 0,
    },
    {
      id: 'starred',
      type: 'system',
      name: 'starred',
      unreadCount: 0,
    },
    {
      id: 'id_social',
      type: 'custom',
      name: 'social',
      unreadCount: 0,
      color: '#00AB55',
    },
    {
      id: 'id_promotions',
      type: 'custom',
      name: 'promotions',
      unreadCount: 0,
      color: '#FFC107',
    },
    {
      id: 'id_forums',
      type: 'custom',
      name: 'forums',
      unreadCount: 0,
      color: '#FF4842',
    },
  ];

  const mails = {
    allIds: [],
    byId: {},
  };

  const thirdPartyProviderNames = [
    {
      name: 'Google',
      icon: 'google.svg',
    },
    {
      name: 'Microsoft',
      icon: 'ms-svg.svg',
    },
    {
      name: 'Apple',
      icon: 'apple-logo.svg',
    },
    {
      name: 'Exchange Server Mails',
      icon: 'mail-exc.png',
    },
  ];

  const thirdPartyProviderDefault = [
    {
      name: 'Google',
      icon: 'google.svg',
      checked: false,
    },
    {
      name: 'Microsoft',
      icon: 'ms-svg.svg',
      checked: false,
    },
    {
      name: 'Apple',
      icon: 'apple-logo.svg',
      checked: false,
    },
  ];

  const thirdPartyProviderProducts = [
    {
      providerName: 'google',
      products: [
        {
          fieldName: 'googlecontacts',
          name: 'Google Contacts',
          icon: 'contacts.svg',
          checked: false,
          scope: 'https://www.googleapis.com/auth/contacts',
        },
        {
          fieldName: 'googlecalender',
          name: 'Google Calender',
          icon: 'google-cal.png',
          checked: false,
          scope: 'https://www.googleapis.com/auth/calendar',
        },
        {
          fieldName: 'gmail',
          name: 'Gmail',
          icon: 'gmail.svg',
          checked: false,
          scope: 'https://mail.google.com/',
        },
      ],
    },
    {
      providerName: 'microsoft',
      products: [
        {
          fieldName: 'microsoftcontacts',
          name: 'Microsoft Contacts',
          icon: 'contacts.svg',
          checked: false,
          scope: '',
        },
        {
          fieldName: 'microsoftcalender',
          name: 'Microsoft Calender',
          icon: 'google-cal.png',
          checked: false,
          scope: 'https://www.microsoft.com/auth/calendar',
        },
        {
          fieldName: 'outlook',
          name: 'Outlook',
          icon: 'microsoft-outlook.svg',
          checked: false,
          scope: 'https://mail.microsoft.com/',
        },
      ],
    },
  ];

  const thirdPartyProviderScopesURL = [
    {
      providerName: 'google',
      products: [
        {
          fieldName: 'googlecontacts',
          url: '',
          scope: '',
          func: (data) => fetchContacts(data?.connections),
        },
        {
          fieldName: 'googlecalender',
          url: '',
          scope: '',
          func: (data) => fetchEvents(data?.items),
        },
      ],
    },
    {
      providerName: 'microsoft',
      products: [
        {
          fieldName: 'microsoftcontacts',
          url: '',
          scope: '',
          func: (graphConfig, accessToken, updateUI) => {},
        },
        {
          fieldName: 'microsoftcalender',
          url: '',
          scope: 'https://www.microsoft.com/auth/calendar',
          func: (graphConfig, accessToken, updateUI) =>
            callCalendarsGraph(graphConfig.graphCalendarsEndpoint, accessToken, updateUI),
        },
        {
          fieldName: 'outlook',
          url: '',
          scope: 'https://mail.microsoft.com/',
          func: (graphConfig, accessToken, updateUI) => {},
        },
      ],
    },
  ];

  const exchangeServerSettingList = [
    {
      name: 'POP3',
      value: 0,
      checked: false,
    },
    {
      name: 'IMAP',
      value: 0,
      checked: false,
    },
    {
      name: 'SMTP',
      value: 0,
      checked: false,
    },
  ];

  const [checkBoxProvider, setCheckBoxProvider] = useState([]);
  const [selectedProvider, setSelectedProvider] = useState('');
  const [defaultProvider, setDefaultProvider] = useState(thirdPartyProviderDefault);
  const [selectedScopes, setSelectedScopes] = useState('');
  const [exchangeServerSettings, setExchangeServerSettings] = useState(exchangeServerSettingList);
  const [settingsCredentials, setSettingsCredentials] = useState({});
  const [serverMails, setServerMails] = useState([]);

  useEffect(() => {
    if (selectedProvider) {
      thirdPartyProviderProducts.forEach((product) => {
        if (product.providerName == selectedProvider) {
          setCheckBoxProvider([product]);
        }
      });
    }
  }, [selectedProvider]);

  const alreadySyncedProvider = {
    GOOGLE: userInfo?.GoogleRefreshToken,
    MICROSOFT: userInfo?.MicrosoftRefreshToken,
  };

  useEffect(() => {
    setDefaultProvider(
      thirdPartyProviderDefault.map((provider) => {
        if (alreadySyncedProvider[provider?.name?.toUpperCase()]) {
          provider.checked = true;
          return provider;
        } else {
          return provider;
        }
      })
    );
  }, []);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    height: 'auto',
    bgcolor: 'background.paper',
    borderRadius: '10px',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

  function onChangeProviderChecked(e) {
    let thirdPartyProviderProductsCopy = [...checkBoxProvider];
    let providerList = [];
    thirdPartyProviderProductsCopy = thirdPartyProviderProductsCopy.map((provider) => {
      if (provider.providerName == selectedProvider) {
        providerList = provider.products.map((product) => {
          if (product.fieldName == e.target.name) {
            product.checked = e.target.checked;
            return product;
          } else {
            return product;
          }
        });
        provider.products = providerList;

        return provider;
      } else {
        return provider;
      }
    });
    setItemselected(providerList.filter((item) => item.checked).length > 0 ? true : false);
    setCheckBoxProvider(thirdPartyProviderProductsCopy);
  }

  async function fetchContacts(connections) {
    const contacts = connections.map(function (connection) {
      const {
        resourceName,
        names = [],
        organizations = [],
        emailAddresses = [],
        addresses = [],
        photos = [],
        phoneNumbers = [],
      } = connection;
      const { givenName = '', middleName = '', familyName = '' } = names.length > 0 && names[0];
      const { name = '', title = '' } = organizations.length > 0 && organizations[0];
      const {
        country = '',
        postalCode = '',
        city = '',
        streetAddress = '',
        extendedAddress = '',
      } = addresses.length > 0 && addresses[0];
      return {
        FirstName: givenName?.replaceAll("'", '`'),
        MiddleName: middleName?.replaceAll("'", '`'),
        LastName: familyName?.replaceAll("'", '`'),
        NickName: '',
        Organization: name?.replaceAll("'", '`'),
        JobTitle: title.replaceAll("'", '`'),
        Phone: phoneNumbers?.length > 0 ? phoneNumbers[0]?.value.replaceAll("'", '`') : '',
        Email: emailAddresses?.length > 0 ? emailAddresses[0]?.value.replaceAll("'", '`') : '',
        Street1: streetAddress?.replaceAll("'", '`'),
        Street2: extendedAddress?.replaceAll("'", '`'),
        City: city?.replaceAll("'", '`'),
        PostalCode: postalCode?.replaceAll("'", '`'),
        Country: country?.replaceAll("'", '`'),
        Image: photos?.length > 0 ? photos[0]?.url : '',
      };
    });

    contacts.length > 0 &&
      Promise.all(
        contacts.map(
          (contact, index) =>
            new Promise((resolve) => {
              setTimeout(() => {
                HttpRequest(
                  `getItems/contactsdetails/Name/${localStorage.getItem(
                    'username'
                  )}/${localStorage.getItem('client')}/${contact.ProviderID}`
                )
                  .then((response) => response)
                  .then((json) => {
                    console.log(json, contact);
                    if (json.ID == '0') {
                      return HttpRequest(
                        `contacts/${localStorage.getItem('client')}/${localStorage.getItem(
                          'username'
                        )}`,
                        'POST',
                        contact
                      );
                    } else {
                      return HttpRequest(
                        `contacts/${json.ID}/${localStorage.getItem('username')}`,
                        'PUT',
                        contact
                      );
                    }
                  })
                  .then(resolve)
                  .catch(console.error);
              }, index * 10); // delay by 1 second per contact index
            })
        )
      )
        .then((responses) => {
          setDataFetched(false);
        })
        .catch(console.error);
  }

  async function fetchEvents(events) {
    console.log(events);
    const Meetings = events.map(function (event) {
      const { id = '', summary = '', location = '', start, end, description = '' } = event;
      return {
        ProviderID: id,
        title: summary?.replaceAll("'", '`'),
        location: location?.replaceAll("'", '`'),
        startDateTime: start?.dateTime ? new Date(start?.dateTime).toISOString() : '',
        endDateTime: end?.dateTime ? new Date(end?.dateTime).toISOString() : '',
        description: description?.replaceAll("'", '`'),
      };
    });
    Promise.all(
      Meetings.map(
        (Meeting, index) =>
          new Promise((resolve) => {
            setTimeout(() => {
              HttpRequest(
                `getItems/eventssdetails/Name/${localStorage.getItem(
                  'username'
                )}/${localStorage.getItem('client')}/${Meeting.ProviderID}`
              )
                .then((response) => response)
                .then((json) => {
                  if (json.ID == '0') {
                    return HttpRequest(
                      `events/${localStorage.getItem('client')}/${localStorage.getItem(
                        'username'
                      )}`,
                      'POST',
                      Meeting
                    );
                  } else {
                    return HttpRequest(
                      `events/${json.ID}/${localStorage.getItem('username')}`,
                      'PUT',
                      Meeting
                    );
                  }
                })
                .then(resolve)
                .catch(console.error);
            }, index * 10); // delay by 1 second per contact index
          })
      )
    )
      .then(async (responses) => {
        responses.map(async (i) => {
          let data = await i.json();
          console.log(data);
        });
      })
      .catch(console.error);
  }

  const myMSALObj = new Msal.UserAgentApplication(msalConfig);
  function ContinueWithMicrosoft(e) {
    e.preventDefault();
    myMSALObj
      .loginPopup(loginRequest)
      .then((loginResponse) => {
        if (myMSALObj.getAccount()) {
          getTokenPopup(tokenRequest)
            .then((response) => {
              console.log(response);
              let { accessToken, expiresOn } = response;
              HttpRequest(`users/${userInfo.ID}/${localStorage.getItem('username')}`, 'PUT', {
                MicrosoftRefreshToken: 'dsfsdfdfdf',
              })
                .then((data) => data)
                .then((json) => {
                  if (!isNaN(json)) {
                    setDefaultProvider((prev) =>
                      prev.map((provider) => {
                        if (provider?.name?.toUpperCase() == 'MICROSOFT') {
                          provider.checked = true;
                          return provider;
                        } else {
                          return provider;
                        }
                      })
                    );
                  }
                });

              localStorage.setItem('maccess', accessToken);
              var today = new Date();
              localStorage.setItem('mexpiry', today.setHours(today.getHours() + 1));
              let scopesList = selectedScopes.split(' ');
              scopesList.forEach((scope) => {
                thirdPartyProviderScopesURL.forEach((item) => {
                  if (item.providerName == selectedProvider) {
                    item.products.forEach((product) => {
                      if (product.scope == 'https://www.microsoft.com/auth/calendar') {
                        product.func(graphConfig, response.accessToken, updateUI);
                      }
                    });
                  }
                });
              });
              enqueueSnackbar('Sync Successful!');
              setOpenExternalProviderPopup(false);
              setDataFetched(false);
            })
            .catch((error) => {
              console.log(error);
            });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function onChangeExchangeServerChecked(e) {
    let exchangeServerSettingsUpdated = exchangeServerSettings.map((setting) => {
      if (setting.name == e.target.name) {
        setting.checked = e.target.checked;
        return setting;
      } else {
        setting.checked = false;
        return setting;
      }
    });

    setExchangeServerSettings(exchangeServerSettingsUpdated);
  }

  function onChangeExchangeSettings(e) {
    setSettingsCredentials((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  }

  useEffect(() => {
    if (serverMails.length > 0) {
    }
  }, [serverMails]);

  const formRef = useRef();

  return (
    <>
      <Stack direction={'row'} justifyContent={'space-around'} alignItems={'center'}>
        {/* <Stack>
          <Typography sx={{ fontSize: '20px', fontWeight: '600', width: '100%' }}>
            Default Sync
          </Typography>
          <Stack justifyContent={'flex-start'}>
            {defaultProvider.map((provider) => {
              return (
                <CheckBoxTitle
                  setting={provider}
                  onChangeExchangeServerChecked={() => {}}
                  showIcon={true}
                />
              );
            })}
          </Stack>
        </Stack> */}
        <Stack
          sx={{ py: '100px' }}
          direction={'row'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          {thirdPartyProviderNames.map((provider) => {
            const { name, icon } = provider;
            let title =
              name == 'Exchange Server Mails' ? 'Exchange Server Mails' : `Sign in with ${name}`;
            return (
              <div style={{ height: '40px', width: '250px', marginRight: '20px' }}>
                <div className="S9gUrf-YoZ4jf" style={{ position: 'relative' }}>
                  <div>
                    <button
                      tabindex="0"
                      role="button"
                      style={{ width: '100%' }}
                      onClick={(e) => {
                        e.preventDefault();
                        setSelectedProvider(name.toLowerCase());
                        if (name == 'Exchange Server Mails') {
                          setOpenExchangeServerPopup(true);
                        } else {
                          setOpenExternalProviderPopup(true);
                        }
                      }}
                      aria-labelledby="button-label"
                      className="nsm7Bb-HzV7m-LgbsSe  hJDwNd-SxQuSe i5vt6e-Ia7Qfc uaxL4e-RbRzK"
                    >
                      <div className="nsm7Bb-HzV7m-LgbsSe-bN97Pc-sM5MNb ">
                        <div className="nsm7Bb-HzV7m-LgbsSe-Bz112c">
                          <img src={`/assets/${icon}`} />
                        </div>
                        <span className="nsm7Bb-HzV7m-LgbsSe-BPrWId">{title}</span>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            );
          })}
          <Modal
            open={openExternalProviderPopup}
            onClose={handleClose}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
          >
            <Box sx={{ ...style, width: 600 }}>
              <Grid container alignItems={'center'}>
                <Typography sx={{ fontSize: '20px', fontWeight: '600' }}>Sync</Typography>
                <Grid item sx={{ ml: 'auto', displayPrint: 'none' }}>
                  <Tooltip title="Close">
                    <IconButton onClick={handleClose}>
                      <Iconify icon="material-symbols:close" />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>

              <MenuList>
                {checkBoxProvider?.length > 0 &&
                  checkBoxProvider?.map((provider) => {
                    const { providerName, products } = provider;
                    return products.map((product) => (
                      <MenuItem>
                        <ListItemIcon>
                          <Checkbox
                            disableRipple
                            checked={product.checked}
                            onChange={onChangeProviderChecked}
                            color="info"
                            name={product.fieldName}
                            //disabled={alreadySyncedProvider[providerName.toUpperCase()] ? true : false}
                          />
                        </ListItemIcon>
                        <ListItemText sx={{ mr: '25px' }}>{product.name}</ListItemText>

                        <img src={`/assets/${product.icon}`} height="35px" width="35px" />
                      </MenuItem>
                    ));
                  })}
              </MenuList>
              <Grid container justifyContent={'flex-end'}>
                <Grid item>
                  <LoadingButton
                    onClick={(e) => {
                      let SCOPES = '';
                      let selectedScopes = checkBoxProvider
                        .filter((provider) => provider.providerName == selectedProvider)[0]
                        .products.filter((product) => product.checked);

                      selectedScopes.forEach((product) => {
                        SCOPES += product.scope + ' ';
                      });
                      setSelectedScopes(SCOPES);
                      if (selectedProvider == 'google') {
                      } else if (selectedProvider == 'microsoft') {
                      }
                    }}
                    disabled={!itemSelected}
                    accessKey="s"
                    variant="outlined"
                    sx={{ mr: 1, mt: 2 }}
                  >
                    Continue
                  </LoadingButton>
                </Grid>
              </Grid>
            </Box>
          </Modal>

          <Modal
            open={openExchangeServerPopup}
            onClose={handleClose}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
          >
            <Box sx={{ ...style, width: 600 }}>
              <Grid container alignItems={'center'}>
                <Typography sx={{ fontSize: '20px', fontWeight: '600', mb: 2 }}>
                  Configuration Settings
                </Typography>
                <Grid item sx={{ ml: 'auto', displayPrint: 'none' }}>
                  <Tooltip title="Close">
                    <IconButton onClick={handleClose}>
                      <Iconify icon="material-symbols:close" />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>

              <form ref={formRef}>
                <Stack direction={'row'} spacing={5}>
                  <TextBoxGREYERP
                    label={'Email Address'}
                    name={'user'}
                    onChange={onChangeExchangeSettings}
                    required={true}
                  />
                  <TextBoxGREYERP
                    label={'Password'}
                    name={'password'}
                    onChange={onChangeExchangeSettings}
                    required={true}
                  />
                </Stack>
                <Divider sx={{ my: 3 }} />
                <Stack direction={'row'} spacing={5}>
                  <TextBoxGREYERP
                    label={'Host'}
                    name={'host'}
                    required={true}
                    onChange={onChangeExchangeSettings}
                  />
                  <TextBoxGREYERP
                    label={'Port No'}
                    name={'port'}
                    required={true}
                    onChange={onChangeExchangeSettings}
                  />
                </Stack>
              </form>
              <Stack direction={'row'} spacing={3} sx={{ mt: 0.5 }}>
                {exchangeServerSettings.map((setting) => {
                  return (
                    <CheckBoxTitle
                      setting={setting}
                      onChangeExchangeServerChecked={onChangeExchangeServerChecked}
                    />
                  );
                })}
              </Stack>
              <Grid container justifyContent={'flex-end'}>
                <Grid item>
                  <LoadingButton
                    onClick={(e) => {
                      if (formRef.current.reportValidity()) {
                      }
                    }}
                    accessKey="s"
                    loading={dataFetchedServer}
                    variant="outlined"
                    sx={{ mr: 1, mt: 2 }}
                  >
                    Continue
                  </LoadingButton>
                </Grid>
              </Grid>
            </Box>
          </Modal>
        </Stack>
      </Stack>
    </>
  );
}

function CheckBoxTitle({ setting, onChangeExchangeServerChecked, showIcon = false }) {
  return (
    <>
      <Stack direction={'row'} alignItems={'center'}>
        <Checkbox
          disableRipple
          checked={setting.checked}
          size="small"
          onChange={onChangeExchangeServerChecked}
          color="info"
          name={setting.name}
        />
        {showIcon && (
          <img
            src={`/assets/${setting.icon}`}
            height={15}
            width={15}
            style={{ marginRight: '6px' }}
          />
        )}
        <Typography variant="body" sx={{ fontSize: '12px', fontWeight: '500' }}>
          {setting.name}
        </Typography>
      </Stack>
    </>
  );
}
