import {
  ClickAwayListener,
  Grow,
  IconButton,
  MenuList,
  Paper,
  Popper,
  Stack,
  Tooltip,
  Grid as GridBox,
  Checkbox,
} from '@mui/material';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Download, PictureAsPdf, Article, CheckBox } from '@mui/icons-material';
import {
  MaterialReactTable,
  MRT_ShowHideColumnsButton,
  MRT_ToggleFiltersButton,
  MRT_Table,
  useMaterialReactTable,
} from 'material-react-table';
import { Box, Button, ListItemIcon, MenuItem, Link } from '@mui/material';
import { FileCopy, Edit, Print } from '@mui/icons-material';
import { useNavigate } from 'react-router';
import { FormContext } from 'src/layouts/dashboard/DashboardLayout';
import PopupPrint from 'src/controls/printpoppanel';
import { useSnackbar } from 'notistack';
import { ExportToCsv } from 'export-to-csv';
import { HttpRequest, SaveFormOnClick, formatdecimals } from 'src/data/scripts';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import ImportCSVGREY from 'src/controls/items/importgreycsv';
import {
  PaymentStatusColor,
  accVouchersUrl,
  formsWithVN,
  integerFormattingCols,
  mastersForm,
  numberCols,
  totalCols,
} from 'src/data/data';
import { sentenceCase } from 'change-case';
import Label from 'src/components/label/Label';
import PrintTable from 'src/controls/printtable';
import LoadingScreen from 'src/components/loading-screen/LoadingScreen';
import Iconify from 'src/components/iconify/Iconify';
import cryptoJs from 'crypto-js';
import arabicFont from 'src/assets/arabic/Amiri-Regular.ttf';
import { useGetGridData } from 'src/hooks/useQueryHooks';
import { useTranslation } from 'react-i18next';
import { ClearIcon } from '@mui/x-date-pickers';

export default function Grid({
  name,
  PopupComponent,
  apiExternalUrl,
  openPrintTable = true,
  FormComponent,
  height,
  TopToolbarCustomActions,
  showPagination = true,
  externalDisplay,
}) {
  const { t } = useTranslation();
  let focus = true;
  let tempColData = [];
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const tableInstanceRef = useRef(null);
  const anchorRef = React.useRef(null);
  // const [data, setData] = useState([])

  const {
    submitForm,
    openPrint,
    setOpenPrint,
    setFirstRowInfo,
    editOnCell,
    setEditOnCell,
    setRecordId,
    clientId,
    openImportPrint,
    setObjectEdit,
    data,
    setData,
    loading,
    setLoading,
    recordId,
    setPopupEditId,
    setObject,
    openDrawer,
    tableName,
    setTableName,
    setOpenDrawer,
    dataFetched,
    setDataFetched,
    displayPrint,
    setGridTableInstance,
    setCloneObject,
    setColumnsGridImport,
    gridDataReserve,
    setGridDataReserve,
  } = useContext(FormContext);

  const url = window.location.pathname.split('/');
  const tblName = url[2].replaceAll('-', '');
  const reportGrid = url[2].includes('-report');
  const mastersPage = url[1].includes('masters');
  const actionName = window.location.pathname.split('/')[3];
  const Form = window.location.pathname.split('/')[2]?.toLowerCase();

  const [open, setOpen] = React.useState(false);
  const [hideFirstRowMasters, setHideFirstRowMasters] = React.useState(false);
  const [detailPanelComponent, setDetailPanelComponent] = useState(FormComponent);
  const [columns, setColumns] = useState([]);
  const [showProgressBars, setShowProgressBars] = useState(true);
  const [expanded, setExpanded] = useState({ 1: false });
  const [rowCount, setRowCount] = useState(null);
  const [search, setSearch] = useState('');

  let ecryptedRights = localStorage.getItem('userRights')
    ? JSON.parse(localStorage.getItem('userRights'))
    : '';
  const bytes = cryptoJs.AES?.decrypt(ecryptedRights, 'userRights');
  const rights = bytes?.toString(cryptoJs.enc.Utf8);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const prefixLS = 'grey';

  let localStorageName = prefixLS + name;
  let localStorageState = JSON.parse(localStorage.getItem(localStorageName) ?? '{}') ?? {};
  //8 State
  name = name?.replaceAll(' ', '');
  const [columnVisibility, setColumnVisibility] = useState({});
  const [sorting, setSorting] = useState([]);
  const [columnPinning, setColumnPinning] = useState({
    left: ['mrt-row-actions', 'mrt-row-select', 'mrt-row-expand'],
    right: [],
  });
  const [columnSizing, setColumnSizing] = useState('');
  const [pagination, setPagination] = useState(
    localStorageState?.page ?? { pageIndex: 0, pageSize: 50 }
  );
  const [grouping, setGrouping] = useState([]);
  const [columnOrder, setColumnOrder] = useState([]);

  //States Updater Func
  function setPaginationFunc(func) {
    let newPaginationState = func(pagination);
    setPagination(newPaginationState);
    //  if (newPaginationState.pageIndex === 0) newPaginationState.pageIndex = 1;
    localStorageState['page'] = newPaginationState;
    // if (localStorageState.page.pageIndex === 0) localStorageState.pageIndex = 1;

    localStorage.setItem(localStorageName, JSON.stringify(localStorageState));
  }

  function setColumnOrderFunc(updater) {
    reportGrid && Array.isArray(updater) && updater?.splice(0, 2);
    setColumnOrder((prev) => (updater instanceof Function ? updater(prev) : updater));
    localStorageState['order'] = updater instanceof Function ? updater(columnOrder) : updater;
    localStorage.setItem(localStorageName, JSON.stringify(localStorageState));
  }

  useEffect(() => {
    setColumns([]);
    setData([]);
    setShowProgressBars(true);
    // setDataFetched(false);
    setDataFetched(true);
  }, [pagination?.pageSize, pagination?.pageIndex]);

  const {
    data: gridData,
    error,
    isFetched,
  } = useGetGridData(
    apiExternalUrl,
    tblName,
    localStorage.getItem('username'),
    localStorage.getItem('client'),
    JSON.parse(localStorage.getItem(localStorageName))?.page?.pageIndex || 0,
    JSON.parse(localStorage.getItem(localStorageName))?.page?.pageSize || 50,
    search
  );


  useEffect(() => {
    // if(isFetched){
    // setDataFetched(true)
    if (Array.isArray(gridData)) {
      GetData(gridData);
    }
    // if(Array.isArray(gridData)){
    //   gridData.map((item,i)=>{
    //     queryClient.setQueryData([`${tblName}`, `${item?.ID}`], ()=>[item])
    //   })
    // }

    // }
  }, [isFetched, gridData]);

   useEffect(() => {
setSearch('');
      }, [
    tableName,
    clientId
  ]);
  useEffect(() => {
    if (Array.isArray(gridData)) {
      GetData(gridData);
    }

    //7 States
    setSorting(localStorageState?.sort ?? []);
    setColumnSizing(localStorageState?.size ?? '');
    setColumnPinning(
      localStorageState?.pin ?? {
        left: ['mrt-row-actions', 'mrt-row-select', 'mrt-row-expand'],
        right: [],
      }
    );
    setColumnVisibility(localStorageState?.hide ?? {});
    setPagination(localStorageState?.page ?? { pageIndex: 0, pageSize: 50 });
    setDetailPanelComponent(FormComponent);

    return () => {
      setEditOnCell(false);
    };
  }, [
    name,
    dataFetched,
    apiExternalUrl,
    tableName,
    clientId,
    actionName,
    FormComponent,
    gridData,
    isFetched,
  ]);

  const table = useMaterialReactTable({
    columns,
    data,
    enableColumnOrdering: true,
    enableStickyHeader: true,
    enableStickyFooter: true,
    enableColumnFilterModes: true,
    showColumnFilters: true,
    enableGlobalFilterModes: true,
    showProgressBars: true,
    enableColumnResizing: true,
    columnResizeMode: 'onChange',
    enableGrouping: true,
    enableColumnPinning: true,
    enableMultiSort: true,
    enableGlobalFilter: true,
    enableFilterMatchHighlighting: true,
    isMultiSortEvent: () => true,
    manualPagination: true,
    muiPaginationProps: {
      rowsPerPageOptions: [10, 20, 50, 100],
      showFirstButton: true,
      showLastButton: true,
      // labelDisplayedRows: ({ from, to, count }) => {
      //   return `${from}–${to} off ${count !== -1 ? count : `more than ${to}`}`;
      // },
    },
    // filterFns: {
    //   myCustomFilterFn: (row, id, filterValue) => {
    //     let value = row?.getValue(id) ?? '';
    //     setSearch(filterValue);
    //     return (
    //       value &&
    //       value
    //     );
    //   },
    // },
    rowCount: rowCount,
    globalFilterFn: 'myCustomFilterFn',
    muiSearchTextFieldProps: {
      id: 'searchinput',
      placeholder: `Search records (Alt + S)`,
      accessKey: 's',
      accessorKey: 's',
      value: search,
      onChange: (e) => {
        setSearch(e.target.value)
      },
      endAdornment: (
        <IconButton aria-label="clear searchff" onClick={() => setSearch('')} edge="end">
        <ClearIcon></ClearIcon>
        </IconButton>
      ),
    },
    enablePagination: showPagination,
    enableHiding: true,
    enableRowActions: true,
    layoutMode: (reportGrid || displayPrint) && 'grid',
    muiTableContainerProps: (table) => {
      return {
        sx: {
          height: 'calc(100vh - 295px)',
        },
      };
    },
    muiTablePaperProps: {
      sx: {
        '& svg[data-testid="SaveIcon"]': {
          fill: '#078dee !important',
        },
        boxShadow: '0 0 2px 0 rgba(145, 158, 171, 0.2), 0 12px 24px -4px rgba(145, 158, 171, 0.12)',
        borderRadius: '8px',
        padding: '5px',
        border: '0px',
        cursor: loading && 'progress !important',
        display: externalDisplay,
      },
    },
    muiTableBodyProps: ({ cell, column, row, table }) => {
      return {
        sx: {
          '& .MuiTableCell-root': {
            whiteSpace: 'break-spaces ',
          },
          height: height,
          '& .MuiTableRow-root:first-of-type .MuiTableCell-root:nth-of-type(2)': {
            color: !sorting[0]?.desc && !reportGrid ? 'white' : 'black',
          },

          '& .MuiIconButton-colorInfo': {
            color: '#0351AB',
          },
        },
      };
    },
    displayColumnDefOptions: {
      'mrt-row-select': {
        enableHiding: false,
        enablePinning: false,
        enableColumnOrdering: false,
        enableColumnDragging: false,
        maxSize: 55,
      },
      'mrt-row-actions': {
        enableHiding: false,
        enablePinning: false,
        enableColumnOrdering: false,
        enableColumnDragging: false,
        header: '',
        maxSize: 55,
      },
      // 'mrt-row-expand': {
      //   enableHiding: false,
      //   enablePinning: false,
      //   enableColumnOrdering: false,
      //   enableColumnDragging: false,
      //   header: '',
      //   maxSize: 60,

      //   ...(!(table.options?.state?.grouping?.length > 0) && {
      //     Cell: ({ row, table }) => {
      //       return (
      //         <IconButton
      //           sx={{
      //             justifyContent: 'flex-start',
      //             color: '#637381',
      //             '& svg': {
      //               transition: 'transform 200ms ease 0s',
      //               transform: row.getIsExpanded() ? 'rotate(180deg)' : 'rotate(0deg)',
      //             },
      //           }}
      //           onClick={async () => {
      //             row.getIsExpanded() == false && setObjectEdit(null);
      //             if (!row.getIsExpanded()) {
      //               let objectEditRes = await HttpRequest(
      //                 `getRecordById/${window.location.pathname
      //                   .split('/')[2]
      //                   .replaceAll('-', '')}/${row.original.ID}`
      //               );
      //               setObjectEdit(objectEditRes[0]);
      //               setPopupEditId({
      //                 form: tblName,
      //                 id: row.original.ID,
      //               });
      //               setEditOnCell(true);
      //             } else {
      //               setEditOnCell(false);
      //             }

      //             let expandedObj = {
      //               [row.index]: row.getIsExpanded() ? false : true,
      //             };
      //             tableInstanceRef.current.setExpanded(expandedObj);
      //           }}
      //         >
      //           <KeyboardArrowDownIcon />
      //         </IconButton>
      //       );
      //     },
      //   }),
      // },
    },
    enableRowSelection: true,
    initialState: {
      showGlobalFilter: true,
      enableRowActions: false,
      density: 'compact',
      columnVisibility: { ROW: false },
      pagination: {
        pageIndex: 0,
        pageSize: 50,
      },
    },
    cursor: 'text',
    positionToolbarAlertBanner: 'bottom',
    defaultDisplayColumn: {
      enableColumnOrdering: true,
      enableHiding: true,
      enableRowActions: false,
    },
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    state: {
      columnPinning,
      columnSizing,
      pagination,
      columnVisibility,
      columnOrder,
      grouping,
      sorting,
      showProgressBars,
      expanded,
    },
    onColumnPinningChange: (updater) => {
      if (updater instanceof Function) {
        localStorageState['pin'] = updater(columnPinning);
      } else {
        localStorageState['pin'] = updater;
      }
      localStorage.setItem(localStorageName, JSON.stringify(localStorageState));
      setColumnPinning((prev) => (updater instanceof Function ? updater(prev) : updater));
    },
    onExpandedChange: (updater) => {
      setExpanded((prev) => (updater instanceof Function ? updater(prev) : updater));
    },
    onGroupingChange: (updater) => {
      if (Array.isArray(updater) && updater.includes('Date')) {
        setGrouping([]);
      } else {
        localStorageState['group'] = updater(grouping);
        localStorage.setItem(localStorageName, JSON.stringify(localStorageState));
        setGrouping((prev) => (updater instanceof Function ? updater(prev) : updater));
      }
    },
    onSortingChange: (updater) => {
      localStorageState['sort'] = updater(sorting);
      localStorage.setItem(localStorageName, JSON.stringify(localStorageState));
      setSorting((prev) => (updater instanceof Function ? updater(prev) : updater));
    },
    onColumnSizingChange: (updater) => {
      localStorageState['size'] = updater(columnSizing);
      localStorage.setItem(localStorageName, JSON.stringify(localStorageState));
      setColumnSizing((prev) => (updater instanceof Function ? updater(prev) : updater));
    },
    onColumnOrderChange: setColumnOrderFunc,
    onPaginationChange: setPaginationFunc,
    onColumnVisibilityChange: (updater) => {
      if (updater instanceof Function) {
        localStorageState['hide'] = updater(columnVisibility);
        localStorage.setItem(localStorageName, JSON.stringify(localStorageState));
      } else {
        localStorageState['hide'] = updater;
        localStorage.setItem(localStorageName, JSON.stringify(localStorageState));
      }
      setColumnVisibility((prev) => (updater instanceof Function ? updater(prev) : updater)); //hack to rerender after state update
    },
    muiTableBodyRowProps: ({ row }) => ({
      onClick: (event) => {},
      sx: {
        cursor: 'text', //you might want to change the cursor too when adding an onClick
      },
    }),
    muiTableBodyCellProps: ({ cell, column, row, table }) => {
      return {
        sx: {
          cursor: loading && 'progress !important',
          borderBottom: '1px solid rgb(224, 224, 224)',
        },
      };
    },
    renderRowActionMenuItems: ({ row, closeMenu }) => [
      <MenuItem
        key={1}
        onClick={() => {
          if (PopupComponent) {
            setPopupEditId(row?.original?.ID);
            setOpenDrawer(true);
          } else
            navigate(
              `/${window.location.pathname.split('/')[1]}/${
                window.location.pathname.split('/')[2]
              }/${row.original.ID}`
            );
          closeMenu();
        }}
        sx={{ m: 0 }}
      >
        <ListItemIcon sx={{ m: 0 }}>
          <Edit />
        </ListItemIcon>
        Edit
      </MenuItem>,

      <MenuItem
        key={2}
        onClick={() => {
          setCloneObject(row.original.ID);
          navigate(
            `/${window.location.pathname.split('/')[1]}/${
              window.location.pathname.split('/')[2]
            }/new`
          );
        }}
        sx={{ m: 0 }}
      >
        <ListItemIcon sx={{ m: 0 }}>
          <FileCopy color="success" />
        </ListItemIcon>
        Clone
      </MenuItem>,

      <PrintOption
        row={row}
        setRecordId={setRecordId}
        setOpenPrint={setOpenPrint}
        closeMenu={closeMenu}
        setLoading={setLoading}
      />,
      <MenuItem
        key={4}
        disabled={rights.includes(Form + '/delete') ? false : true}
        onClick={() => {
          enqueueSnackbar('Do you want to proceed?', {
            variant: 'warning',
            autoHideDuration: 25000,
            action: (key) => (
              <React.Fragment>
                <Button
                  color="error"
                  variant="contained"
                  sx={{ mr: 1 }}
                  size="small"
                  onClick={() => {
                    // Perform the delete operation
                    HttpRequest(
                      `${window.location.pathname
                        .split('/')[2]
                        ?.replaceAll(' ', '')
                        .replaceAll('-', '')
                        .toLowerCase()}/${row.original.ID}`,
                      'DELETE'
                    )
                      .then(async (response) => {
                        let data = await response.json();

                        if (data?.length < 1) {
                          let expandedObj = {
                            [row.index]: false,
                          };
                          table?.setExpanded(expandedObj);
                          const newData = data?.filter((ROW) => ROW.ID !== row.original.ID);
                          newData?.length < 1 && setColumns([]);
                          setData(newData);
                          enqueueSnackbar('Record deleted.', { variant: 'success' });
                        } else {
                          enqueueSnackbar('Unable to delete the record.', {
                            variant: 'error',
                          });
                        }
                      })
                      .catch((error) => {
                        enqueueSnackbar(`Error: ${error.message}`, { variant: 'error' });
                      })
                      .finally(() => {
                        closeSnackbar(key);
                        closeMenu();
                      });
                  }}
                >
                  Delete
                </Button>
                <Button
                  color="inherit"
                  variant="contained"
                  sx={{ mr: 1 }}
                  size="small"
                  onClick={() => {
                    closeSnackbar(key);
                    closeMenu();
                  }}
                >
                  Cancel
                </Button>
              </React.Fragment>
            ),
          });
        }}
        sx={{ m: 0 }}
      >
        <ListItemIcon sx={{ m: 0 }}>
          <Iconify sx={{ color: '#FF5630' }} icon="eva:trash-2-outline" />
        </ListItemIcon>
        Delete
      </MenuItem>,
    ],
    renderToolbarInternalActions: ({ table }) => (
      <Box sx={{ displayPrint: 'none' }}>
        <MRT_ToggleFiltersButton
          Tooltip="Filters (Alt + F)"
          accessorKey="f"
          accessKey="f"
          table={table}
        />
        <MRT_ShowHideColumnsButton
          Tooltip="Columns (Alt + C)"
          accessorKey="c"
          accessKey="c"
          table={table}
        />
        <Tooltip arrow title="Export (Alt + X)">
          <IconButton ref={anchorRef} accessorKey="x" accessKey="x" onClick={handleToggle}>
            <Download />
          </IconButton>
        </Tooltip>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom-start"
          transition
          sx={{
            zIndex: 50,
            '& .MuiPaper-root': {
              p: '0px',
            },
            mt: '10px !important',
            boxShadow:
              '0 0 2px 0 rgba(145, 158, 171, 0.24), -20px 20px 40px -4px rgba(145, 158, 171, 0.24) !important',
            borderRadius: '12px !important',
          }}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom',
              }}
            >
              <Paper sx={{ px: 1, py: 2 }}>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                  >
                    <MenuItem key={'success'} onClick={handleExportData}>
                      {' '}
                      <ListItemIcon sx={{ m: 0 }}>
                        <Article color="success" />
                      </ListItemIcon>
                      {t('Export to CSV')}
                    </MenuItem>
                    <MenuItem key={'export'} onClick={downloadPdf}>
                      {' '}
                      <ListItemIcon sx={{ m: 0 }}>
                        <PictureAsPdf color="pdf" />
                      </ListItemIcon>
                      {t('Export to PDF')}
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Box>
    ),
    renderTopToolbarCustomActions: ({ table }) => {
      if (TopToolbarCustomActions) {
        return TopToolbarCustomActions;
      } else {
        const handleDelete = () => {
          var deleteIDs = '';
          table?.getSelectedRowModel()?.flatRows?.map((row) => {
            deleteIDs += row.original.ID + ',';
          });
          let recordUnDeletedList = [];

          HttpRequest(
            `${name.replaceAll(' ', '').replaceAll('-', '').toLowerCase()}/${deleteIDs}`,
            'DELETE'
          )
            .then((response) => response)
            .then((res) => {
              res.map((i) =>
                i.map((id) => {
                  if (id.toString()?.length > 0) {
                    recordUnDeletedList.push(id);
                  }
                })
              );

              const deletedIdsArray = deleteIDs?.slice(0, -1).split(',');
              let deletedIdsList = deletedIdsArray?.filter((item) => {
                if (recordUnDeletedList.includes(item)) {
                  return false;
                } else {
                  return true;
                }
              });

              const newData = data.filter((ROW) => {
                if (deletedIdsList.includes(`${ROW.ID}`)) {
                  return false;
                }
                return true;
              });

              let formName = window.location.pathname.split('/')[2]?.toUpperCase();
              // if (formConnectedToExternalProvider.includes(formName)) {
              //   deleteContactsExternalProvider(table);
              // }
              table.toggleAllPageRowsSelected(false);

              setData(newData);
              GetData(newData);
              newData?.length < 1 && setColumns([]);
              if (recordUnDeletedList?.length > 0) {
                enqueueSnackbar(`Unable to delete ${recordUnDeletedList?.length} records.`);
              } else {
                enqueueSnackbar(t('Records Deleted'));
              }
            });
        };
        const handleConfirmDelete = () => {
          closeSnackbar();
          handleDelete();
        };

        const handleCancelDelete = () => {
          closeSnackbar();
        };

        const handleDeleteButton = () => {
          enqueueSnackbar('Do you want to proceed?', {
            variant: 'warning',
            autoHideDuration: 25000,
            action: (
              <>
                <Button
                  color="error"
                  variant="contained"
                  sx={{ mr: 1 }}
                  size="small"
                  onClick={handleConfirmDelete}
                >
                  Delete
                </Button>
                <Button
                  color="inherit"
                  variant="contained"
                  sx={{ mr: 1 }}
                  size="small"
                  onClick={handleCancelDelete}
                >
                  Cancel
                </Button>
              </>
            ),
          });
        };

        return !window.location.pathname.split('/')[2].includes('-report') ? (
          <div style={{ display: 'flex', gap: '0.5rem' }}>
            <Button
              size="small"
              color="error"
              // disabled={!table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()}
              disabled={
                rights.includes(Form + '/delete')
                  ? !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
                  : true
              }
              onClick={handleDeleteButton}
              variant="contained"
            >
              Delete
            </Button>
          </div>
        ) : (
          //remove this code later
          <div style={{ display: 'flex', gap: '0.5rem' }}></div>
        );
      }
    },
    muiDetailPanelProps: () => {
      return {
        sx: {
          p: '0px !important',
        },
      };
    },
    renderDetailPanel:
      // detailPanelComponent && (reportGrid || FormComponent)
      detailPanelComponent && reportGrid
        ? ({ row, table, cell }) => {
            return (
              <GridBox
                container
                sx={{
                  display: 'flex',
                  width: 'calc(100vw - 45px)',
                  flexDirection: 'column',
                  backgroundColor: '#F2F2F2',
                  px: 3,
                  m: 0,
                  pt: 3,
                }}
              >
                <GridBox item>{detailPanelComponent}</GridBox>
              </GridBox>
            );
          }
        : null,
  });

  //Export PDF
  const downloadPdf = () => {
    const doc = new jsPDF();

    doc.addFileToVFS(arabicFont);
    doc.addFont(arabicFont, 'ArabicFont', 'normal');

    let colVisibleState = table.getState().columnVisibility;
    let colVisibleArray = columns.map((i) => {
      if (i.accessorKey != 'ID') {
        return i.accessorKey;
      }
      return false;
    });
    colVisibleArray = colVisibleArray.filter((item) => item != false);
    let colExportData = [];
    let rowExportData = [];

    for (const key in colVisibleState) {
      if (!colVisibleState[key]) {
        colVisibleArray = colVisibleArray.filter((i) => i != key);
      }
    }

    if (table && table.getFilteredRowModel().rows?.length > 1) {
      for (const key in table?.getFilteredRowModel().rows[0]._valuesCache) {
        if (colVisibleArray.includes(key)) {
          colExportData.push(key);
        }
      }
    }

    table &&
      table?.getFilteredRowModel().rows.map((row) => {
        let rowFiltered = [];
        for (const key in row._valuesCache) {
          if (colExportData.includes(key)) {
            if (key == 'Date') {
              rowFiltered.push(row._valuesCache[key]?.toLocaleDateString('en-GB'));
            } else {
              rowFiltered.push(row._valuesCache[key]);
            }
          }
        }
        rowExportData.push(rowFiltered);
      });

    autoTable(doc, {
      head: [colExportData],
      body: rowExportData,
      styles: { font: 'ArabicFont' },
    });
    // doc.autoTable({
    //   head: [colExportData],
    //   body: rowExportData,
    // });
    const fileName = name ? name : recordId?.name ?? '';
    doc.save(`${fileName}.pdf`);
  };

  //Export CSV
  const handleExportData = () => {
    let colExportData = [];
    let rowExportData = [];

    let colVisibleState = table.getState().columnVisibility;
    let colVisibleArray = columns.map((i) => {
      if (i.accessorKey != 'ID') {
        return i.accessorKey;
      }
      return false;
    });
    colVisibleArray = colVisibleArray.filter((item) => item != false);

    for (const key in colVisibleState) {
      if (!colVisibleState[key]) {
        colVisibleArray = colVisibleArray.filter((i) => i != key);
      }
    }

    if (table && table.getFilteredRowModel().rows?.length > 1) {
      for (const key in table?.getFilteredRowModel().rows[0]._valuesCache) {
        if (colVisibleArray.includes(key)) {
          colExportData.push(key);
        }
      }
    }

    const fileName = name ? name : recordId?.name ?? '';

    const csvOptions = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      useBom: true,
      filename: `${fileName}`,
      useKeysAsHeaders: false,
      headers: colExportData,
    };
    const csvExporter = new ExportToCsv(csvOptions);

    table &&
      table.getFilteredRowModel().rows.map((row) => {
        let rowFiltered = {};
        for (const key in row._valuesCache) {
          if (colExportData.includes(key)) {
            rowFiltered[key] = row._valuesCache[key];
          }
        }
        rowExportData.push(rowFiltered);
      });

    csvExporter.generateCsv(rowExportData);
  };

  async function GetData(json) {
    setCloneObject(0);
    apiExternalUrl && setShowProgressBars(true);
    table.toggleAllRowsExpanded(false);
    if (table && json?.length > 0) {
      setGridTableInstance(table);
      // setFirstRowInfo(tableInstanceRef.current.getRow('0'));
      setFirstRowInfo(json[0]);
      // setFirstRowInfo(table?.getRow('0'));
    }

    if (dataFetched || tableName != tblName || apiExternalUrl) {
      table.toggleAllPageRowsSelected(false);
      // let json = (await HttpRequestGet(`${apiUrl}`)) || [];
      // let json = await gridData;

      setRowCount(json[0]?.RecordCount || 50);
      setData(json ?? []);
      !reportGrid &&
        setGridDataReserve((prev) => {
          return { ...prev, ['data']: json };
        });
      setShowProgressBars(false);
      setTableName(tblName);
      setColumnsGridImport(tblName);

      let columnNames = json?.length > 0 ? Object.keys(json[0]) : [];
      function GenerateDynamicColumns(columnNames, json) {
        for (let item of columnNames) {
          let colEmail = ['Email'];
          let numbers = ['Phone', 'Phone2', 'Office'];
          let colDate = ['Date'];
          let colEdit = ['IsDefault'];
          let calculation = {
            [item]: 0,
          };

          json.forEach((row) => {
            calculation[item] += row[item];
          });

          const renderedCellDisplay = [
            {
              cols: [...colEmail],
              func: (cell) => (
                <Link
                  target="_blank"
                  href={`mailto:${cell.getValue()}`}
                  sx={{ display: 'inline', mr: 1, width: '65px' }}
                >
                  {cell.getValue()}
                </Link>
              ),
            },
            {
              cols: [...numbers],
              func: (cell) => (
                <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
                  {cell.getValue()?.length >= 8 && (
                    <Link
                      href={`tel:${cell.getValue()}`}
                      sx={{ display: 'inline', mr: 'auto', width: '108px' }}
                    >
                      {' '}
                      {cell.getValue()}{' '}
                    </Link>
                  )}
                  {cell.getValue()?.length >= 11 && (
                    <Link
                      sx={{
                        textDecoration: 'none !important',
                        color: '#25d366 !important',
                        '& .MuiSvgIcon-root': {
                          width: '20px !important',
                        },
                      }}
                      target="_blank"
                      href={`https://wa.me/${cell.getValue()}`}
                    >
                      <WhatsAppIcon color="#25d366 !important" />
                    </Link>
                  )}
                </Box>
              ),
            },
            {
              cols: [...colDate],
              func: (cell) =>
                (cell.getValue() && new Date(cell.getValue()).toLocaleDateString('en-GB')) || '',
            },
            {
              cols: ['Image'],
              func: (cell) => (
                <img
                  alt="Image"
                  src={`https://uploads.greyerp.com/` + cell.getValue()}
                  loading="lazy"
                  style={{ borderRadius: '5px', height: '100px' }}
                />
              ),
            },
            {
              cols: ['Status'],
              func: (cell) => (
                <Label
                  variant="soft"
                  color={
                    PaymentStatusColor.filter((i) => i.Name == (cell.getValue() ?? ''))[0]?.color ??
                    'primary'
                  }
                >
                  {cell.getValue() ? sentenceCase(cell.getValue()) : ''}
                </Label>
              ),
            },
            {
              cols: ['IsDefault'],
              func: (cell) => <Checkbox checked={!!cell.getValue()} disabled={true} />,
            },
            {
              cols: ['ID'],
              func: (cell) => (
                <Link
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    if (PopupComponent) {
                      setPopupEditId(cell.getValue());
                      setOpenDrawer(true);
                    } else {
                      navigate(
                        `/${url[1]?.toLowerCase()}/${window.location.pathname
                          .split('/')[2]
                          ?.toLowerCase()}/${cell.getValue()}`
                      );
                    }
                  }}
                >
                  {cell.getValue()}
                </Link>
              ),
            },
            {
              cols: ['Number'],
              func: (cell) => {
                let voucher, component;
                let VNForm = formsWithVN
                  .toString()
                  .replaceAll('-', '')
                  .includes(tblName?.toLowerCase());
                if (!VNForm) {
                  voucher = cell.row.original.Voucher?.toUpperCase();
                  accVouchersUrl.forEach((value, key) => {
                    if (key == voucher) {
                      component = value;
                    }
                  });
                }

                return (
                  <>
                    <Link
                      target="_blank"
                      rel="noreferrer"
                      onClick={async () => {
                        if (VNForm) {
                          navigate(
                            `/${window.location.pathname
                              .split('/')[1]
                              ?.toLowerCase()}/${window.location.pathname
                              .split('/')[2]
                              ?.toLowerCase()}/${cell.row.original.ID}`
                          );
                          return;
                        }

                        cell.row.getIsExpanded() == false && (setObjectEdit(null), setObject(null));
                        if (!cell.row.getIsExpanded()) {
                          let formName = reportGrid ? voucher + 'S' : tblName;
                          let ID = reportGrid ? cell.row.original.ROW : cell.row.original.ID;

                          let objectEditRes = await HttpRequest(`getRecordById/${formName}/${ID}`);

                          setObjectEdit(objectEditRes[0]);
                          setPopupEditId({
                            form: formName,
                            id: ID,
                          });
                          setEditOnCell(true);
                        } else {
                          setEditOnCell(false);
                        }
                        setDetailPanelComponent(component);

                        let expandedObj = {
                          [cell.row.index]: cell.row.getIsExpanded() ? false : true,
                        };
                        table?.setExpanded(expandedObj);
                      }}
                      style={{ cursor: 'pointer' }}
                    >
                      {cell.getValue()}
                    </Link>
                  </>
                );
              },
            },
          ];

          if (
            item == 'ID' &&
            [...formsWithVN, 'account-statement-report'].includes(url[2].toLowerCase())
          )
            continue;

          if (item == 'RecordCount') continue;

          let floatCol = numberCols.includes(item);
          let taxType = tblName?.toUpperCase() == 'TAX-CATEGORIES' && item?.toUpperCase() == 'TYPE';
          tempColData.push({
            accessorKey: item.replaceAll(' ', ''),
            header: item.replaceAll('_', ' '),
            filterFn: item == 'Cheque_Number' ? 'equals' : 'contains',

            muiEditTextFieldProps: {
              variant: 'outlined',
              helperText: '',
              size: 'small',
              focused: true,
              autoFocus: focus,
              type: floatCol ? 'number' : colEmail.includes(item) ? 'email' : '',
              margin: 'normal',
              inputProps: { style: { fontSize: 13, fontWeight: 'light' } },
              sx: {
                '& .MuiOutlinedInput-root.Mui-focused': {
                  '& > fieldset': {
                    borderColor: '#E5E8EB',
                  },
                },
                '& .MuiButtonBase-root': {
                  fontSize: '13px',
                },
                '& .MuiFormLabel-root.Mui-focused': {
                  color: 'text.primary',
                },
                input: {
                  '&::placeholder': {
                    // <----- Add this.
                    opacity: 0.6,
                    fontWeight: 'light',
                    textTransform: 'none',
                  },
                },
              },
            },
            ...([...totalCols, ...integerFormattingCols].includes(item) && {
              aggregationFn: 'sum',
              AggregatedCell: ({ cell }) => (
                <Box color="black" sx={{ fontWeight: 'bold' }}>
                  {floatCol ? formatdecimals(cell.getValue()) : cell.getValue()}
                </Box>
              ),
              Footer: ({ column, footer, table }) => {
                return (
                  <Stack>
                    <Box color="black">
                      {floatCol ? formatdecimals(calculation[item]) : calculation[item]}
                    </Box>
                  </Stack>
                );
              },
            }),
            ...(colDate.includes(item) && {
              sortingFn: 'datetime',
              accessorFn: (row) => row.Date && new Date(row.Date),
            }),
            ...(colEdit.includes(item) && {
              Edit: ({ cell, column, table }) => {
                return (
                  <Checkbox
                    checked={!!cell.getValue()}
                    onChange={(e) => {
                      const tblDataFiltered = json.map((row) => {
                        if (row.ID == cell.row.original.ID) {
                          row['IsDefault'] = e.target.checked;
                          return row;
                        }
                        return row;
                      });
                      setData(tblDataFiltered);
                    }}
                  />
                );
              },
            }),

            ...(taxType && {
              editVariant: 'boolean',
              editSelectOptions: [
                {
                  value: 'Amount',
                  text: 'Amount',
                },
                {
                  value: 'Percentage',
                  text: 'Percentage',
                },
              ],
            }),
            Cell: ({ renderedCellValue, cell }) => {
              let colExist = renderedCellDisplay.filter((column) => column.cols.includes(item));
              if (colExist?.length > 0) {
                return colExist[0].func(cell);
              } else {
                return floatCol ? formatdecimals(renderedCellValue) : renderedCellValue;
              }
            },
          });

          focus = false;
        }
      }
      GenerateDynamicColumns(columnNames, json);
      const tempUniqueColData = tempColData.filter((obj, index) => {
        return index === tempColData.findIndex((o) => obj?.accessorKey === o?.accessorKey);
      });
      setColumns(tempUniqueColData ?? []);
      setColOrder(tempUniqueColData ?? []);
      !reportGrid &&
        setGridDataReserve((prev) => {
          return { ...prev, ['column']: tempUniqueColData };
        });
      setDataFetched(true);
    } else {
      if (!reportGrid) {
        let gridDataReserveCopy = gridDataReserve;
        setData(json);
        // setData(gridDataReserveCopy?.data ?? []);
        setColumns(gridDataReserveCopy?.column ?? []);
        setColOrder(gridDataReserveCopy?.column);
      }

      setShowProgressBars(false);
    }

    function setColOrder(columns) {
      reportGrid
        ? setColumnOrder(
            !localStorageState?.order
              ? [...columns.map((c) => c.accessorKey)]
              : localStorageState?.order ?? []
          )
        : setColumnOrder(
            !localStorageState?.order
              ? [
                  'mrt-row-actions',
                  'mrt-row-expand',
                  'mrt-row-select',
                  ...columns.map((c) => c.accessorKey),
                ]
              : localStorageState?.order ?? []
          );

      setGrouping(localStorageState?.group ?? []);
    }

    if (columns && columns?.length > 0) {
      //  setColOrder(columns);
      setGrouping(localStorageState?.group ?? []);
    }
  }

  return (
    <>
      {displayPrint ? (
        <PrintTable printData={data} showFooter={true} />
      ) : showProgressBars && apiExternalUrl ? (
        <LoadingScreen />
      ) : (
        // <h1>Loading ....</h1>
        <>
          <MaterialReactTable table={table} />

          {openDrawer && PopupComponent}
          {openPrint && openPrintTable && <PopupPrint />}
          {openImportPrint && <ImportCSVGREY />}
        </>
      )}
    </>
  );
}

function PrintOption({ row, setRecordId, setOpenPrint, closeMenu, setLoading }) {
  const printExcluded = ['BANKACCOUNTS', 'TRANSFERS', 'BUILDINGS', 'UNITS', 'MAINTENANCES'];
  const mastersPage = window.location.pathname.split('/')[1].includes('masters');

  return (
    <>
      {!printExcluded.includes(
        window.location.pathname.split('/')[2]?.replaceAll('-', '')?.toUpperCase()
      ) && !mastersPage ? (
        <MenuItem
          key={3}
          onClick={() => {
            setRecordId(row.original.ID);
            setOpenPrint(true);
            setLoading(true);
            closeMenu();
          }}
          sx={{ m: 0 }}
        >
          <ListItemIcon sx={{ m: 0 }}>
            <Print color="primary" />
          </ListItemIcon>
          Print
        </MenuItem>
      ) : (
        <MenuItem sx={{ display: 'none' }}></MenuItem>
      )}
    </>
  );
}
