import { TextBoxGREYERP } from 'src/controls/textbox';
import PopUpPanel from 'src/controls/popuppanel';
export default function MastersRow({openPopUp}) {
  return (
    <>
      <PopUpPanel
        heading={'Add'}
        openPopup={openPopUp}
        child={
          <>
            <TextBoxGREYERP name="Name" placeholder="Name" span={2} required={true} />
            <TextBoxGREYERP name="Description" multiline={true} span={2} placeholder="Description" MultiLine={true} />
            <TextBoxGREYERP name="Comments" multiline={true} span={2} placeholder="Comments (Optional)" type="Internal Notes" />
          </>
        }
      />
    </>
  );
}
