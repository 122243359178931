import { TextBoxGREYERP } from 'src/controls/textbox';
import { Days, TaxType } from 'src/data/data';
import { DropDownENUM } from 'src/controls/dropdownenum';
import { CardPanel } from 'src/controls/cardpanel';
import { ItemsComponent } from 'src/controls/items/ItemsComponent';
import { useContext, useState } from 'react';
import { Button, Grid } from '@mui/material';
import { useSnackbar } from 'notistack';
import { FormContext } from 'src/layouts/dashboard/DashboardLayout';
import Picker from 'src/controls/picker';

export default function HolidayLists() {
  const { object,setExistingItemsData } = useContext(FormContext);
  const [weekOffs, setWeekOffs] = useState();
  const [weekOffDay, setWeekOffDay] = useState();
  const { enqueueSnackbar } = useSnackbar();

  function WeekOffs(e, newValue) {
    if (newValue) {
      setWeekOffs((prev) => {
        return { ...prev, ['WeekOffDay']: newValue.ID };
      });
      setWeekOffDay(newValue.ID)

    } else {
      setWeekOffs((prev) => {
        return { ...prev, [e.target.name]: e.target.value };
      });
    }
  }

  function AddDays() {
    if (weekOffs && weekOffs['WeekOffName'] && weekOffs['WeekOffDescription'] && weekOffs['WeekOffDay'] && object['StartDate'] && object['EndDate']) {
      let fromDate = new Date(object['StartDate']);
      let toDate = new Date(object['EndDate']);
      let holidaysList =[];
      while (fromDate < toDate) {
        if (fromDate.getDay() == weekOffs['WeekOffDay']) {
          let copyOfDate = new Date(fromDate.valueOf())
          let holiday = {};
          holiday['Date'] = copyOfDate;
          holiday['Name'] = weekOffs['WeekOffName'] ?? '';
          holiday['Description'] = weekOffs['WeekOffDescription'] ?? '';
          holidaysList.push(holiday);
        }
        fromDate.setDate(fromDate.getDate() + 1)
      }

      setExistingItemsData((prev=[])=>[...prev,...holidaysList]);

    }else
    {
      enqueueSnackbar('Plese Enter Start Date, End Date, Day, Name & Description!');
    }
  }

  return (
    <>
      <CardPanel
        heading="Holidays List"
        cardItem={
          <>
            <TextBoxGREYERP name="Name" placeholder="Name"  required={true} focus={true} />
            <Picker type='date' name="StartDate" required={true} ></Picker>
            <Picker type='date' name="EndDate"  required={true} ></Picker>
            <TextBoxGREYERP name="Comments" span={2} multiline={true} placeholder="Comments" />
          </>
        }
      />

      <CardPanel
        heading="Week Offs"
        cardItem={
          <>
            <DropDownENUM onChange={WeekOffs} postBackSelectedValue={weekOffDay}   name="WeekOffDay" firstItemSelect={false} items={Days} />
            <TextBoxGREYERP onChange={WeekOffs} name="WeekOffName"  placeholder="Name" />
            <TextBoxGREYERP onChange={WeekOffs} name="WeekOffDescription" span={2} placeholder="Description" />
            <Grid item>
            <Button variant='outlined' size="small" onClick={AddDays}> Add </Button>
            </Grid>
          
          </>
        }
      />

      <ItemsComponent
        heading={'Holidays'}
        tablename="holidaylistdetails"
        disableCalculation={true}
        disableQuantity={true}
        columnsToShow={{ Date: '', Name: '', Description: '' }}
        columnsToAppend={{ Date: '', Name: '', Description: '' }}
      />
    </>
  );
}
