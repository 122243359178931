import PropTypes from 'prop-types';
import sum from 'lodash/sum';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Grid, Card, Button, CardHeader, Typography, Stack } from '@mui/material';

// components
import Iconify from '../../../../../components/iconify';
import EmptyContent from '../../../../../components/empty-content';
//
import CheckoutSummary from '../CheckoutSummary';
import CheckoutCartProductList from './CheckoutCartProductList';
import { useEffect, useState } from 'react';
import Calculator from 'src/components/Calculator';
import { useTheme, styled } from '@mui/material/styles';

// ----------------------------------------------------------------------

CheckoutCart.propTypes = {
  checkout: PropTypes.object,
  onNextStep: PropTypes.func,
  onDeleteCart: PropTypes.func,
  onApplyDiscount: PropTypes.func,
  onDecreaseQuantity: PropTypes.func,
  onIncreaseQuantity: PropTypes.func,
};

export default function CheckoutCart({
  checkout,
  onNextStep,
  onApplyDiscount,
  onDeleteCart,
  onIncreaseQuantity,
  onDecreaseQuantity,
  setSelectedProducts,
  selectedProducts = [],
}) {
  // const { cart, total, discount, subtotal } = checkout;

  //const totalItems = sum(cart.map((item) => item.quantity));

  //const isEmptyCart = !cart.length;

  const totalItems = selectedProducts.length ?? 0;

  const isEmptyCart = selectedProducts.length > 0 ? false : true;

  const [orderSummary, setOrderSummary] = useState({
    total: 0.0,
    subtotal: 0.0,
    discount: 0,
  });


  useEffect(() => {
   
    if (selectedProducts.length > 0) {
      let orderSumm = {
        total: 0.0,
        subtotal: 0.0,
        discount: 0,
      };
      selectedProducts.map((item, index) => {
        orderSumm.total += item.subtotal;
        orderSumm.subtotal += item.subtotal;
      });

      setOrderSummary(orderSumm);
    }
  }, [selectedProducts]);

  const cart = [
    {
      id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1',
      name: 'Nike Air Force 1 NDESTRUKT',
      cover: 'https://api-dev-minimal-v4.vercel.app/assets/images/products/product_1.jpg',
      available: 29,
      price: 16.19,
      colors: ['#00AB55'],
      size: '9',
      quantity: 1,
      subtotal: 16.19,
    },
    {
      id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b3',
      name: 'Nike Air Zoom Pegasus 37 A.I.R. Chaz Bear',
      cover: 'https://api-dev-minimal-v4.vercel.app/assets/images/products/product_3.jpg',
      available: 2,
      price: 34.3,
      colors: ['#FFFFFF'],
      size: '6',
      quantity: 1,
    },
    {
      id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b13',
      name: 'Nike Blazer Low 77 Vintage',
      cover: 'https://api-dev-minimal-v4.vercel.app/assets/images/products/product_13.jpg',
      available: 30,
      price: 90.69,
      colors: ['#FFFFFF'],
      size: '6',
      quantity: 1,
    },
  ];

  return (
    <Grid container direction={'column'} justifyContent={'space-between'} alignItems={'space-between'} spacing={1} sx={{ flexBasis: '50%' }}>
      <Grid item flex={1} >
        <Card sx={{ mb: .2 }}>
     
          {!isEmptyCart ? (
            <CheckoutCartProductList
              products={selectedProducts}
              setOrderSummary={setOrderSummary}
              setSelectedProducts={setSelectedProducts}
              
            />
          ) : (
            <EmptyContent
              title="This order is empty"
              description=""
              img="/assets/illustrations/illustration_empty_cart.svg"
            />
          )}
        </Card>
      </Grid>

      <Grid item >
        <Grid container gap={1} >
          <Grid item sx={{flexBasis:'50%'}}>
          <Calculator/>
          </Grid>
       
       <Grid item sx={{flex:1}}>
     {/* <Stack> */}
     <CheckoutSummary
          enableDiscount
          total={orderSummary.total}
          discount={orderSummary.discount}
          subtotal={orderSummary.subtotal}
          onApplyDiscount={onApplyDiscount}
        />
           {/* <Button
          fullWidth
          sx={{height:'100%'}}
          size="medium"
          type="submit"
          variant="contained"
          disabled={!selectedProducts.length}
          onClick={onNextStep}
        >
          Payment
        </Button>
     </Stack> */}

    
       </Grid>
    
        </Grid>
       
  
      </Grid>
    
    </Grid>
  );
}
