/* eslint-disable react/prop-types */
import { Box, Button, Card, CardHeader, Grid, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Iconify from 'src/components/iconify/Iconify';

export function CardPanel({
  cardItem,
  heading,
  subHeading,
  direction,
  className,
  id,
  onDrag,
  justifyContent,
  flexGrow,
  headerChild,
  sx = {},
}) {
  const { t } = useTranslation();

  return (
    <Card
      className={className}
      sx={{
        mb: 2,
        //displayPrint: 'none',
        //height: '100%',
        flexGrow: flexGrow ?? 1,
        overflow: 'visible',
        position: 'inherit',
        ...sx,
      }}
    >
      <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
        <CardHeader title={t(heading)} subheader={t(subHeading)} />
        {headerChild}
      </Stack>

      <Box sx={{ m: 0, p: '20px' }}>
        {cardItem && (
          <Grid
            container
            direction={direction ?? 'row'}
            sx={{ alignItems: 'center', justifyContent: justifyContent }}
            spacing={2}
            rowSpacing={1}
            columns={{ xs: 3, sm: 3, md: 4, lg: 5 }}
          >
            {cardItem}
          </Grid>
        )}
      </Box>
    </Card>
  );
}
