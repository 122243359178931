import * as React from 'react';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { FormControl, Grid } from '@mui/material';
import { FormContext } from 'src/layouts/dashboard/DashboardLayout';
import { useTranslation } from 'react-i18next';

export function DateTimePickerItem({
  placeholder,
  label,
  name,
  field,
  required,
  width,
  span,
  onChange,
  setReTrigger,
  disabled,
  index,
}) {
  const { t } = useTranslation();

  const { setValue, watch } = useFormContext();
  const values = watch();
  const [valueDate, setValueDate] = useState('');

  const registerDataChange = (e, val) => {
    const nameControl = name.split('.');
    values.items.map((item, index) => {
      for (const key in item) {
        if (key == nameControl[1] && field.name == `items[${index}].${nameControl[1]}`) {
          item[key] = new Date(e.$d).toISOString();
          setReTrigger(true);
          field.onChange(item[key]);
        }
      }
    });
  };

  useEffect(() => {
    const nameControl = name.split('.');
    values.items.map((item, index) => {
      for (const key in item) {
        if (key == nameControl[1] && field.name == `items[${index}].${nameControl[1]}`) {
          if (!isNaN(window.location.pathname.split('/')[3])) {
            if (values.items[index][key] != '1900-01-01T00:00:00.000Z') {
              let newdate = new Date(values.items[index][key]);
              setValueDate(dayjs(newdate));
            }
          } else {
            let newdate = values.items[index][key];
            setValueDate(dayjs(newdate));
          }
        }
      }
    });
  }, [values.items[index], name]);

  return (
    <Grid md={span ?? 1} lg={span ?? 1} item style={{ display: 'flex' }}>
      <FormControl
        sx={{
          '.MuiOutlinedInput-root.MuiInputBase-sizeSmall': {
            fontWeight: 'light',
            fontSize: '13px',
          },
          '& > div': {
            overflow: 'hidden',
          },
          '& > div > div': {
            //flex:1
          },
          '& .MuiTextField-root': {
            minWidth: '200px !important',
          },

          width: width ?? '100%',
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={['DateTimePicker']}>
            <DateTimePicker
              disabled={disabled}
              label={label ? t(label) : t(name?.match(/[A-Z][a-z]+|[0-9]+/g)?.join(' '))}
              placeholder={placeholder}
              name={name ? name : field.name}
              value={valueDate ? valueDate : ''}
              //format='DD/MM/YYYY HH:MM'
              onChange={onChange ? onChange : registerDataChange}
              slotProps={{
                textField: {
                  required: required,
                },
              }}
              inputProps={{ style: { fontSize: '13', fontWeight: 'light' } }}
              size="small"
              margin="normal"
              sx={{
                '& input': {
                  p: '8px 10px',
                  fontSize: '13px',
                },
                width: '100%',
                '& .MuiFormLabel-root': {
                  fontSize: '13px !important',
                  fontWeight: 'light',
                  //lineHeight:'.8em',,
                  // color:'black',
                  top: '-8px !important',
                },
                '& .MuiInputLabel-shrink': {
                  fontSize: '1rem !important',
                  top: '0px !important',
                  color: 'black !important',
                },
                '& .MuiInputLabel-root.Mui-focused': {
                  lineHeight: '1.4375em',
                  color: 'black',
                  fontSize: '1rem !important',
                  top: '0px !important',
                },
              }}
              //onChange={(newValue) => setValue(newValue)}
            />
          </DemoContainer>
        </LocalizationProvider>
      </FormControl>
    </Grid>
  );
}
