import { CardPanel } from 'src/controls/cardpanel';
import { DropDownGREYERP } from 'src/controls/dropdown';
import { DropDownENUM } from 'src/controls/dropdownenum';
import { TextBoxGREYERP } from 'src/controls/textbox';
import { ItemTypes,ValuationMethod  } from 'src/data/data';
import UploadGREY from 'src/controls/uploadgrey';
import { CheckBoxGREYERP } from 'src/controls/checkbox';
import { TextEditorGrey } from 'src/controls/texteditorgrey';
import { ItemsComponent } from 'src/controls/items/ItemsComponent';
import ImageContainer from 'src/controls/imagecontainer';

export default function Item() {
  return (
    <>
      <CardPanel
        heading="Item Details"
        cardItem={
          <>
            <UploadGREY />
            <ImageContainer>
              <TextBoxGREYERP name="Name" placeholder="Item Name" required={true} focus={true} />
              <TextBoxGREYERP name="Reference" placeholder="Item Reference" />
              <TextBoxGREYERP name="Barcode" placeholder="Scan or Type" />
              <DropDownENUM items={ItemTypes} name="Type" />
              <CheckBoxGREYERP label="Can be Sold" name="Saleable" />
              <CheckBoxGREYERP label="Can be Purchased" name="Purchasable" />
              <CheckBoxGREYERP label="Is Landed Cost" name="LandedCost" />
              <CheckBoxGREYERP label="Allow Negative Stock" name="NegativeStock" />
              <TextBoxGREYERP name="Brand" placeholder="Item Brand" />
              <DropDownGREYERP firstItem="0,Main"  items={`itemcategories/name`} name="Category" />
              <DropDownGREYERP items={`baseunits/name`} label="UOM" name="BaseUnit" required={true} />
              <TextBoxGREYERP name="Weight" type="number" placeholder="4.5" step="any" sufix="kg" ></TextBoxGREYERP>
              <TextBoxGREYERP name="Volume" type="number" placeholder="10" step="any" sufix="m3" ></TextBoxGREYERP>
              <TextBoxGREYERP name="MaxQuantity" type="number" placeholder="Max Quantity" step="any" ></TextBoxGREYERP>
              <TextBoxGREYERP name="MinQuantity" type="number" placeholder="Min Quantity" step="any" ></TextBoxGREYERP>
              <TextBoxGREYERP name="ReorderLevel" label="Re-Order Level" type="number" placeholder="100" step="any" ></TextBoxGREYERP>
              <TextBoxGREYERP name="PartNo" placeholder="Part No" />
              <TextBoxGREYERP label="Opening Stock" name="Stock" type="number" placeholder="Quantity On Hand" step="any" required={true}></TextBoxGREYERP>
              <DropDownENUM items={ValuationMethod} name="ValuationMethod" />
              <DropDownGREYERP items={`warehouses/name`} name="Warehouse" />
              <TextBoxGREYERP label="Cost" name="Cost" type="number" placeholder="Item Cost" step="any" required={true} ></TextBoxGREYERP>
              <DropDownGREYERP items={`taxCategories/name`} name="PurchaseTax" required={true} />
              <TextBoxGREYERP name="Price" label="Standard Price" type="number" placeholder="10.500" step="any" required={true} ></TextBoxGREYERP>
              <DropDownGREYERP items={`taxCategories/name`} name="SalesTax" required={true} />
              <TextBoxGREYERP name="Warranty" type="number" placeholder="Item Warranty" prefix="Days" ></TextBoxGREYERP>
              <TextBoxGREYERP name="Expiry" type="number" placeholder="Item Expiry" prefix="Days" ></TextBoxGREYERP>
              <TextBoxGREYERP multiline={true} span={2} name="ShortDescription" placeholder="Item Description" />
              <TextBoxGREYERP multiline={true} span={2} name="Comments" placeholder="Comments (Optional)" />
            </ImageContainer>
          </>
        }
      />

      <CardPanel
        heading="Description"
        cardItem={
          <>
            <TextEditorGrey name="Description" placeholder="Item Description" />
          </>
        }
      />
      <ItemsComponent
        heading={'Sub Items'}
        tablename="ItemDetails"
        formType="saleable"
        disableCalculation={true}
        disableQuantity={true}
        enterOnChangeFunc={false}
        disabledColumns={['UNIT']}
        columnsToShow={{ Item: 0, Quantity: 1, Unit: 0, Note: '' }}
        columnsToAppend={{ Item: 0, Quantity: 1, Note: '' }}
        appendCTA={true}
      />
    </>
  );
}
