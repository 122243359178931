import { useContext, useEffect } from 'react';
import { FormContext } from 'src/layouts/dashboard/DashboardLayout';
import { CardPanel } from 'src/controls/cardpanel';
import { DropDownGREYERP } from 'src/controls/dropdown';
import { TextBoxGREYERP } from 'src/controls/textbox';
import Picker from 'src/controls/picker';
import React from 'react';
import { ItemsComponent } from 'src/controls/items/ItemsComponent';
import SwapSections from 'src/controls/SwapSections';
import { GetAndSetList, GetVoucherNumber, HandleOnChangeValue, getClientName } from 'src/data/scripts';
import { SwithGREYERP } from 'src/controls/swithgrey';
import { useFormData } from 'src/hooks/useQueryHooks';
import { useQuery } from '@tanstack/react-query';

export default function RequestforQuotation() {
  return (
    <>
      <SwapSections
        List={[
          <Fields />,
          <div id="divItems" className="divItems">
            <ItemsComponent
              formType="purchasable"
              disableCalculation={true}
              disableQuantity={false}
              enterOnChangeFunc={false}
              columnsToShow={{ Item: 0, Quantity: 1, Note: '' }}
              columnsToAppend={{ Item: 0, Quantity: 1, Note: '' }}
            />
          </div>,
        ]}
      />
    </>
  );
}

function Fields() {
  const { setExistingItemsData,popupEditId } = useContext(FormContext);
  let form = window.location.pathname?.split('/')[2]?.replaceAll('-', '');
  let ID = window.location.pathname?.split('/')[3]?.replaceAll('-', '') ?? popupEditId;
  
  const { setFormData:setObject} = useFormData()
  // const [object, setData] = useState(updatedFormData())
  const {data:object}= useQuery({
    queryKey:'form',
    })
  const {data:objectEdit}= useQuery({
      queryKey:[form, ID],
      select:(res)=>res[0],
      enabled: Boolean(form)
  })
  const { Series = '', Number = '', Source } = object || {};
  const { Series: EditSeries = '', Number: EditNumber = '' } = objectEdit || {};

  useEffect(() => {
    if (isNaN(window.location.pathname.split('/')[3])) {
      GetVoucherNumber(getClientName(), setObject)
    }
  }, []);

  return (
    <>
      <div id="divFields" className="divFields">
        <CardPanel
          heading="Request for Quotation"
          cardItem={
            <>
              <Picker type='date' name="Date" date={new Date()} required={true} focus={true} ></Picker>
              <DropDownGREYERP items={`vendors/Name`} name="Vendor" label="Vendors" span={2} multiple={true} />
              <DropDownGREYERP name="Source" items={`purchaserequisitions/name`} label="Source Document" postBackSelectedValue={Source}
                onChange={(e, selectedValue) =>
                  HandleOnChangeValue({ Source: selectedValue?.ID }, setObject, [
                    GetAndSetList(`getCustomPostBackItem/purchaserequisitiondetailsrfq/${localStorage.getItem( 'username' )}/${localStorage.getItem('client')}/${parseInt(selectedValue?.ID)}`,
                      setExistingItemsData,
                      null
                    ),
                  ])
                }
              />
              <Picker type='date' name="DueDate"></Picker>
              <TextBoxGREYERP placeholder="RFQ Number" disabled name="Number" defaultValue={Series ? Series + Number : EditSeries + EditNumber} required={true} />
              <TextBoxGREYERP placeholder="Document Subject" name="Subject" />
              <TextBoxGREYERP placeholder="Document Reference" name="Reference" />
              <SwithGREYERP name="Print" />
              <TextBoxGREYERP placeholder="Internal Notes" name="Comments" multiline={true} span={2} />
            </>
          }
        />
      </div>
    </>
  );
}
