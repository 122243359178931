/* eslint-disable react/prop-types */
import {
  Autocomplete,
  createFilterOptions,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useContext, useEffect, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { HttpRequest } from 'src/data/scripts';

export function DropDownItem({
  optionsListAPI,
  width,
  label,
  field,
  name,
  required,
  onChange,
  index,
  handleChangeValues,
  hidden = false,
  Units = null,
  setUnits,
  appendedColumns,
  disabled,
  handleGenericTotalValues,
  displayFooterCalc,
  setQty,
  setDisableVariableTax,
  taxItems,
  itemID,
  editModeOnNew,
  setReTrigger,
  disableQuantity,
  setTrigger,
  trigger,
  formType,
}) {
  const { t } = useTranslation();

  const { control, setValue, watch } = useFormContext();
  const values = watch();
  const [dropDownItems, setDropDownItems] = useState([]);
  const [defaultValue, setDefaultValue] = useState(null);
  const [unitsArray, setUnitsArray] = useState([]);

  const [dataFetched, setDataFetched] = useState(false);

  const nameControl = field.name.split('.')[1];

  const { enqueueSnackbar } = useSnackbar();

  let actualVal;

  let reTriggerForms = ['items'];

  //If Unit set Default Value
  useEffect(() => {
    if (nameControl == 'Unit' && Units) {
      values.items.map((item, indexItem) => {
        if (index == indexItem) {
          for (const key in Units) {
            if (key == item.Item || key == item.ID) {
              setUnitsArray(Units[key]);
              setDefaultValue(Units[key][0]);
            }
          }
        }
      });
    }

    if (!isNaN(window.location.pathname.split('/')[3]) && nameControl == 'Unit' && !dataFetched) {
      values.items.map((item) => {
        HttpRequest(
          `getItems/unitofmeasurements/name/${localStorage.getItem(
            'username'
          )}/${localStorage.getItem('client')}/${item.Item}`
        )
          .then((response) => response)
          .then((units) => {
            setUnits((prev) => {
              return { ...prev, [parseInt(item.Item)]: units };
            });
            setDisableVariableTax((prev) => {
              return { ...prev, [parseInt(item.Item)]: true };
            });
            !Object.keys(values?.extra[0])?.includes('Unit') &&
              setValue(`items[${index}].Unit`, units[0].ID);
            setDataFetched(true);
          });
      });
    }
  }, [Units]);

  useEffect(() => {
    setDataFetched(false);
    //For Items only
    if (!dataFetched && optionsListAPI) {
      HttpRequest(
        `getItems/${optionsListAPI}/${localStorage.getItem('username')}/${localStorage.getItem(
          'client'
        )}${itemID ? `/${itemID}` : ''}`
      )
        .then((response) => response)
        .then((json) => {
          setDropDownItems(json);
          setDataFetched(true);
          let colFirstItemSelect = ['Tax', 'LeaveType', 'Level'];

          if (colFirstItemSelect.includes(nameControl)) {
            setDefaultValue(json[0]);
          } else {
            setDefaultValue(null);
          }

          //In Edit Mode
          if (!isNaN(window.location.pathname.split('/')[3])) {
            if (nameControl == 'Tax' && taxItems.length > 0) {
              actualVal = taxItems.filter((item) => parseInt(item.ID) == parseInt(field.value));
              setDefaultValue(actualVal[0]);
            } else {
              actualVal = json?.filter((item) => parseInt(item.ID) == parseInt(field.value));
              setDefaultValue(actualVal[0]);
            }
          }

          if (itemID || editModeOnNew) {
            actualVal = json?.filter((item) => parseInt(item.ID) == parseInt(field.value));
            setDefaultValue(actualVal[0]);
          }
        });
    }
  }, []);

  useEffect(() => {
    const nameControl = name.split('.');
    if (dropDownItems?.length > 0 || taxItems?.length > 0) {
      if (nameControl[1] == 'Item') {
        values.items.map((item, index) => {
          for (const key in item) {
            if (
              key == nameControl[1] &&
              field.name == `items[${index}].${nameControl[1]}` &&
              item[key]
            ) {
              actualVal = dropDownItems.filter(
                (item1) => parseInt(item1.ID) == parseInt(item[key])
              );

              actualVal.length > 0 &&
                setDefaultValue({
                  ID: actualVal[0].ID,
                  Name: actualVal[0].Name,
                });

              actualVal[0] && field.onChange(actualVal[0].ID);
            }
          }
        });
      } else if (nameControl[1] == 'Tax' && taxItems?.length > 0) {
        actualVal = taxItems?.filter((item) => parseInt(item.ID) == parseInt(field.value));
        setDefaultValue(actualVal[0]);
        setTrigger(false);
      }
    }
  }, [values.items[index], dropDownItems, trigger]);

  const registerDataChange = async (e, selectedValue) => {
    if (nameControl == 'Item') {
      if (selectedValue != null) {
        // const itemExist = values.items.filter((item) => item.Item?.toString() == selectedValue.ID?.toString());

        // if(itemExist.length > 0){
        //   enqueueSnackbar('Selected item already exists!', { variant: 'warning' });
        //   setDefaultValue(prev=>{return{...prev,ID:Math.random(),Name:''}});
        //   return;
        // }

        HttpRequest(
          `getItems/${
            formType == 'saleable'
              ? 'salesitems'
              : formType == 'purchasable'
                ? 'purchaseitems'
                : 'bookingitems'
          }/name/${localStorage.getItem('username')}/${localStorage.getItem('client')}/${
            selectedValue?.ID
          }`
        )
          .then((response) => response)
          .then((itemInfo) => {
            let taxTypes = ['SalesTax', 'PurchaseTax'];
            //Total on Row for postback (find solution)

            for (const key in itemInfo[0]) {
              if (
                appendedColumns.includes(key) ||
                (appendedColumns.includes('Tax') && taxTypes.includes(key))
              ) {
                if (key == 'Description') {
                  itemInfo[0]?.Description &&
                  itemInfo[0]?.Description != '' &&
                  itemInfo[0]?.Description != null
                    ? setValue(`items[${index}].Description`, itemInfo[0].Description)
                    : setValue(`items[${index}].Description`, itemInfo[0].Name);
                } else if (taxTypes.includes(key)) {
                  let keyNew = formType == 'saleable' ? 'SalesTax' : 'PurchaseTax';
                  setValue(`items[${index}].Tax`, itemInfo[0][keyNew]);
                  setTrigger(true);
                } else {
                  setValue(`items[${index}].${key}`, itemInfo[0][key]);
                }
              } else {
                setValue(`extra[${index}].${key}`, itemInfo[0][key]);
              }
            }

            HttpRequest(
              `getItems/unitofmeasurements/name/${localStorage.getItem(
                'username'
              )}/${localStorage.getItem('client')}/${selectedValue?.ID}`
            )
              .then((response) => response)
              .then((units) => {
                setUnits((prev) => {
                  return { ...prev, [parseInt(selectedValue?.ID)]: units };
                });
                setDisableVariableTax((prev) => {
                  return { ...prev, [parseInt(selectedValue?.ID)]: true };
                });

                Object.keys(values.items[0]).includes('Unit') &&
                  setValue(`items[${index}].Unit`, units[0].ID);

                if (!disableQuantity) {
                  setValue(`items[${index}].Quantity`, 1);
                  setQty(1);
                }

                handleChangeValues && handleChangeValues(e, selectedValue, index, field);
                setValue(`items[${index}].Item`, parseInt(selectedValue?.ID));
              });

            if (displayFooterCalc) {
              handleGenericTotalValues(values.items);
            }
            reTriggerForms.includes(window.location.pathname.split('/')[2].toLowerCase()) &&
              setReTrigger(true);
          });

        return field.onChange(selectedValue?.ID);
      }
    }
    setReTrigger(true);
    return field.onChange(selectedValue?.ID);
  };

  const filterOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option) => {
      let optionArray = Object.keys(option);
      let name = optionArray.filter((item) => item != 'id' && item != 'ID' && item != 'Id');
      return option[name[0]];
    },
  });
  return (
    <Grid item xs={3} lg={2} sx={{ display: hidden && 'none' }}>
      <FormControl
        sx={{
          'MuiFormControl-root': {
            paddingTop: '0px',
          },
          width: '100%',
          // '.MuiOutlinedInput-root.MuiInputBase-sizeSmall': {
          //   fontWeight: 'light',
          //   fontSize: 11,
          // },
          '& .MuiFormControl-root .MuiInputBase-input': {
            fontSize: 11,
          },
        }}
      >
        <Autocomplete
          name={field.name}
          onBlur={field.onBlur}
          defaultValue={defaultValue && defaultValue}
          ref={field.ref}
          size="small"
          sx={{
            '.MuiOutlinedInput-root.MuiInputBase-sizeSmall': {
              paddingTop: '2px',
              paddingBottom: '6px',
            },
            '& .MuiFormControl-root': {
              backgroundColor: disabled && '#FBFCFD',
            },
            fontSize: 9,
          }}
          id="combo-box-demo"
          onChange={onChange ? onChange : registerDataChange}
          filterOptions={filterOptions}
          disabled={disabled}
          getOptionLabel={(option) => {
            let optionArray = Object.keys(option);
            let name = optionArray.filter((item) => item != 'id' && item != 'ID' && item != 'Id');
            return option[name[0]];
          }}
          options={nameControl == 'Unit' ? unitsArray : dropDownItems}
          key={defaultValue?.ID}
          renderInput={(params) => (
            <TextField
              sx={{
                '.css-9xn2n1-MuiFormLabel-root-MuiInputLabel-root': {
                  // <----- Add this.
                  opacity: 1,
                  fontWeight: 'light',
                  fontSize: 11,
                },
              }}
              required={true}
              size="small"
              margin="normal"
              {...params}
              label={label ? label : name.match(/[A-Z][a-z]+|[0-9]+/g).join(' ')}
            />
          )}
        ></Autocomplete>
      </FormControl>
    </Grid>
  );
}
