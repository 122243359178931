import React, { useContext, useEffect, useState } from 'react';
import { CardPanel } from './cardpanel';
import { DropDownENUM } from './dropdownenum';
import MaterialReactTable from 'material-react-table';
import { Stack } from '@mui/material';
import { TextBoxGREYERP } from './textbox';
import ChartGREYERP from './chart';
import { default as DataGrid } from '../pages/Grid';
import { FormContext } from 'src/layouts/dashboard/DashboardLayout';
import { TypePredicateKind } from 'typescript';

const tables = [{ ID: 'Sales', Name: 'Invoices' }];

const ReportType = [
  { ID: 'grid', Name: 'Table' },
  { ID: 'bar', Name: 'Bar' },
  { ID: 'column', Name: 'Column' },
  { ID: 'pie', Name: 'Pie' },
  { ID: 'donut', Name: 'Donut' },
];

const componentType = new Map([
  [
    'grid',
    (url) => (
      <DataGrid
        apiExternalUrl={`getRecords/salesorders/${localStorage.getItem(
          'username'
        )}/${localStorage.getItem('client')}`}
        name={'customreportdesigner'}
      />
    ),
  ],
  ['bar', (data) => <ChartGREYERP />],
  ['column', (data) => <ChartGREYERP />],
  ['pie', (data) => <ChartGREYERP />],
  ['donut', (data) => <ChartGREYERP />],
]);

function CustomReportDesigner() {
  const { object, gridTableInstance } = useContext(FormContext);
  const { Type = '', Table = '' } = object || {};

  const [displayComponent, setdisplayComponent] = useState();

  useEffect(() => {
    if (Type && Table) {
      componentType.forEach((value, key) => {
        if (key == Type) {
          setdisplayComponent(
            value(
              `getRecords/salesorders/${localStorage.getItem('username')}/${localStorage.getItem(
                'client'
              )}`
            )
          );
        }
      });
    }
  }, [Type, Table]);

 // console.log(Type,'type');

  return (
    <>
      <Stack>
        <CardPanel
          heading={'Report'}
          cardItem={
            <>
              <DropDownENUM name={'TableName'} label={'Table'} items={tables} firstItemSelect={false} required={true} />
              <TextBoxGREYERP name={'Title'} required={true} />
              <TextBoxGREYERP name={'SubTitle'} />
              <DropDownENUM
                name={'Type'}
                items={ReportType}
                required={true}
                firstItemSelect={false}
              />
               <TextBoxGREYERP name={'URL'} label={'Destination'} />
            </>
          }
        />
         <DataGrid
         
            apiExternalUrl={`getRecords/invoices/${localStorage.getItem(
              'username'
            )}/${localStorage.getItem('client')}`}
            name={'customreportdesigner'}
            externalDisplay={Type=='grid' ? '' : 'none'}
          />

        {/* {Type == 'grid' ? (
          <DataGrid
            apiExternalUrl={`getRecords/salesorders/${localStorage.getItem(
              'username'
            )}/${localStorage.getItem('client')}`}
            name={'customreportdesigner'}
          />
        ) : (
          <ChartGREYERP type={Type} />
        )} */}
      </Stack>

      {/* {displayComponent && displayComponent} */}
    </>
  );
}

export default CustomReportDesigner;
