import { DropDownGREYERP } from 'src/controls/dropdown';
import { TextBoxGREYERP } from 'src/controls/textbox';
import PopUpPanel from 'src/controls/popuppanel';
import { SwithGREYERP } from 'src/controls/swithgrey';

export default function Video() {

    return (
        <>
            <PopUpPanel
        heading={'Add'}
        child={
          <>
            <TextBoxGREYERP  name="Title" placeholder="Video Title" MultiLine={true} focus={true}/>
            <TextBoxGREYERP  name="TitleArabic" placeholder="اسم العميل" MultiLine={true} dir="rtl"/>
            <TextBoxGREYERP  name="Video" type="file" value="" />
            <TextBoxGREYERP  name="Link" placeholder="Link" type="Url"/>
            <DropDownGREYERP firstItem="0,Main" items={`videocategories/name`} name="Category"  required={true} />
            <TextBoxGREYERP  name="Sort" placeholder="Sort" type="Number" required={true} span={.5}/>
            <SwithGREYERP  name="Status" span={.5} />
            <TextBoxGREYERP multiline rows={3} name="Comments" placeholder="Internal Notes" span={2} />
          </>
          
   }
   />
 </>
    )
}