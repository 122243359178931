import { useContext, useEffect, useState } from 'react';
import { Container, Stack } from '@mui/material';
import { DragDropContext, Droppable } from '@hello-pangea/dnd';
import { SkeletonKanbanColumn } from '../../components/skeleton';
import ProjectForm, { TasksForm } from './tasksform';
import { FormContext } from 'src/layouts/dashboard/DashboardLayout';
import { KanbanColumn } from 'src/sections/@dashboard/kanban';
import { HttpRequest } from 'src/data/scripts';

export const columnInfo = [
  {
    ID: 0,
    Name: 'Todo',
  },
  {
    ID: 1,
    Name: 'Doing',
  },
  {
    ID: 2,
    Name: 'Review',
  },
  {
    ID: 3,
    Name: 'Done',
  },
];

// ----------------------------------------------------------------------

export default function KanbanPage({ showNav = true }) {
  const [refresh, setRefresh] = useState(false);
  const {
    taskData,
    setTaskData,
    openDrawer,
    setProjectTasks,
    setProjectTasksExisting,
    openTaskPopup,
    projectTasks,
  } = useContext(FormContext);
  const [dataFetched, setDataFetched] = useState(false);
  const [idCounter, setIDCounter] = useState(0);
  const [cardsState, setCardsState] = useState({});

  let tasksList = {};
  useEffect(() => {
    HttpRequest(
      `getRecords/tasks/${localStorage.getItem('username')}/${localStorage.getItem('client')}`
    )
      .then((response) => response)
      .then((boardData) => {
        let columnOrder = [];
        let cards = {};
        let columns = {};
        columnInfo.forEach((element, index) => {
          columnOrder.push(columnInfo[index]['ID']);
        });
        columnOrder = [...new Set(columnOrder)];

        boardData?.map((element, index) => {
          const id = element.ID;
          cards[id] = {
            id: id,
            name: element.Name,
            description: element.Description,
            assignee: [
              {
                id: '',
                avatar: '',
                name: element.Assignee,
              },
            ],
            due: [element.DueDateTime],
            attachments: [],
            comments: [],
            completed: element.Status,
            Status: element.Status,
            priority: element.Priority,
          };
        });

        columnInfo.map((element, index) => {
          const columnId = element.ID;
          const columnName = element.Name;
          const correctTask = boardData.filter((item) => {
            if (item.Status == columnName) {
              return true;
            }
            return false;
          });
          columns[columnId] = {
            id: columnId,
            name: element.Name,
            cardIds: correctTask.map((item) => {
              return item.ID;
            }),
          };
        });

        let colOrderinLS = JSON.parse(localStorage.getItem('tasksort') ?? '{}') ?? {};
        let user = localStorage.getItem('username');
        let cardIds = colOrderinLS[user];
        if (cardIds) {
          Object.values(cards).map((card) => {
            let existingId = false;

            if (colOrderinLS && cardIds) {
              Object.values(cardIds).map((column) => {
                if (column?.cardIds?.includes(card.id)) {
                  existingId = true;
                }
              });
            }

            let columnId = columnInfo.filter(
              (col) => col.Name.toUpperCase() == card.Status.toUpperCase()
            )[0]?.ID;
            if (!existingId && columnId) {
              let column = cardIds[`${columnId}`];
              if (column?.cardIds) {
                column.cardIds = [...column?.cardIds, card.id];
              } else {
                column.cardIds = [card.id];
              }

              cardIds[`${columnId}`] = column;
            }
          });
        }

        if (colOrderinLS && cardIds) {
          colOrderinLS[user] = cardIds;
          localStorage.setItem('tasksort', JSON.stringify(colOrderinLS));
          columns = cardIds;
        } else {
          colOrderinLS[user] = columns;
          localStorage.setItem('tasksort', JSON.stringify(colOrderinLS));
        }

        tasksList = {
          isLoading: false,
          error: null,
          board: {
            cards,
            columns,
            columnOrder,
          },
        };

        setTaskData(tasksList);
        setDataFetched(true);
      });

    if (window.location.pathname.split('/')[2].toUpperCase() == 'TASKS' && dataFetched) {
    } else if (window.location.pathname.split('/')[2].toUpperCase() == 'PROJECTS' && dataFetched) {
      let columns = {};
      columnInfo.map((element, index) => {
        const columnId = element.ID;

        const correctTask = Object.values(cardsState).filter((item) => {
          if (item.Status == columnId) {
            return true;
          }
          return false;
        });
        columns[columnId] = {
          id: columnId,
          name: element.Name,
          cardIds: correctTask.map((item) => {
            return item.id;
          }),
        };
      });
      let columnDataCopy = { ...taskData };
      columnDataCopy.board.cards = { ...cardsState };
      columnDataCopy.board.columns = { ...columns };
      setTaskData(columnDataCopy);
      //setDataFetched(true)
    }

    setRefresh(false);
    //dispatch(getBoard());
  }, [refresh, openDrawer]);

  const onDragEnd = (result) => {
    const { destination, source, draggableId, type } = result;

    if (!destination) return;

    if (destination.droppableId === source.droppableId && destination.index === source.index)
      return;

    if (type === 'column') {
      const newColumnOrder = Array.from(taskData.board.columnOrder);

      newColumnOrder.splice(source.index, 1);

      newColumnOrder.splice(destination.index, 0, draggableId);
      const colData = taskData;
      colData.board.columnOrder = newColumnOrder;
      setTaskData(colData);
      //dispatch(persistColumn(newColumnOrder));
      return;
    }

    const start = taskData.board.columns[source.droppableId];
    const finish = taskData.board.columns[destination.droppableId];

    if (start.id === finish.id) {
      const updatedCardIds = [...start.cardIds];

      updatedCardIds.splice(source.index, 1);

      updatedCardIds.splice(destination.index, 0, draggableId);

      const updatedColumn = {
        ...start,
        cardIds: updatedCardIds,
      };

      setTaskData((prev) => {
        let user = localStorage.getItem('username');
        let colOrderinLS = JSON.parse(localStorage.getItem('tasksort'));
        colOrderinLS[user] = { ...taskData.board.columns, [updatedColumn.id]: updatedColumn };
        localStorage.setItem('tasksort', JSON.stringify(colOrderinLS));
        // localStorage.setItem(
        //   'tasksort',
        //   JSON.stringify({
        //       ...taskData.board.columns,
        //       [updatedColumn.id]: updatedColumn,
        //   })
        // );
        return {
          ...prev,
          ['board']: {
            ['cards']: prev.board.cards,
            ['columnOrder']: prev.board.columnOrder,
            ['columns']: {
              ...taskData.board.columns,
              [updatedColumn.id]: updatedColumn,
            },
          },
        };
      });

      // dispatch(
      //   persistCard({
      //     ...board?.columns,
      //     [updatedColumn.id]: updatedColumn,
      //   })
      // );
      return;
    }

    const startCardIds = [...start.cardIds];

    startCardIds.splice(source.index, 1);

    const updatedStart = {
      ...start,
      cardIds: startCardIds,
    };

    const finishCardIds = [...finish.cardIds];

    finishCardIds.splice(destination.index, 0, draggableId);

    const updatedFinish = {
      ...finish,
      cardIds: finishCardIds,
    };

    // console.log(result);
    // console.log(updatedStart, updatedFinish);

    // dispatch(
    //   persistCard({
    //     ...taskData.board.columns,
    //     [updatedStart.id]: updatedStart,
    //     [updatedFinish.id]: updatedFinish,
    //   })
    // );

    feHttpRequesttch(
      `tasks/${draggableId}/${localStorage.getItem('username')}`,
      'PUT',
      JSON.stringify({
        status: columnInfo.filter((i) => i.ID == updatedFinish.id)[0].Name,
      })
    );

    setTaskData((prev) => {
      let user = localStorage.getItem('username');
      let colOrderinLS = JSON.parse(localStorage.getItem('tasksort'));
      colOrderinLS[user] = {
        ...taskData.board.columns,
        [updatedStart.id]: updatedStart,
        [updatedFinish.id]: updatedFinish,
      };
      localStorage.setItem('tasksort', JSON.stringify(colOrderinLS));

      return {
        ...prev,
        ['board']: {
          ['cards']: prev.board.cards,
          ['columnOrder']: prev.board.columnOrder,
          ['columns']: {
            ...taskData.board.columns,
            [updatedStart.id]: updatedStart,
            [updatedFinish.id]: updatedFinish,
          },
        },
      };
    });
  };

  return (
    <>
      <Container maxWidth={false} sx={{ width: '98vw', p: '0px !important' }}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="all-columns" direction="horizontal" type="column">
            {(provided) => (
              <Stack
                {...provided.droppableProps}
                ref={provided.innerRef}
                spacing={2}
                direction="row"
                alignItems="flex-start"
                sx={{
                  height: '100%',
                  // height: '85%',
                  //overflowY: 'hidden',
                  '& .MuiPaper-root': {
                    borderStyle: 'none',
                  },
                  '& .MuiPaper-outlined': {
                    //ml: '11px !important',
                  },
                  '& .MuiPaper-root > div': {
                    cursor: 'default',
                  },
                  '> div': {
                    width: '200px !important',
                    flex: 1,
                  },
                  //...hideScrollbarX,
                }}
              >
                {taskData.board ? (
                  taskData.board.columnOrder.map((columnId, index) => {
                    return (
                      <KanbanColumn
                        index={index}
                        key={columnId}
                        column={taskData.board.columns[columnId]}
                        cards={taskData.board.cards}
                        setRefresh={setRefresh}
                        cardsState={cardsState}
                        setCardsState={setCardsState}
                        idCounter={idCounter}
                        setIDCounter={setIDCounter}
                      />
                    );
                  })
                ) : (
                  <SkeletonKanbanColumn />
                )}

                {/* {taskData != null && taskData.board ? (
                  taskData.board.columnOrder.map((columnId, index) => (
                    <KanbanColumn
                      index={index}
                      key={columnId}
                      column={taskData.board.columns[columnId]}
                      cards={taskData.board.cards}
                      setRefresh={setRefresh}
                    />
                  ))
                ) : (
                  <SkeletonKanbanColumn />
                )} */}

                {provided.placeholder}
                {/* <KanbanColumnAdd setRefresh={setRefresh}/> */}
              </Stack>
            )}
          </Droppable>
        </DragDropContext>
      </Container>
      {openTaskPopup && (
        <ProjectForm
          setCardsState={setCardsState}
          setRefresh={setRefresh}
          cardsState={cardsState}
        />
      )}
      {openDrawer && <TasksForm />}
    </>
  );
}
