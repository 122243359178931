import { useCallback } from 'react';
// form
import { useForm } from 'react-hook-form';

import { useSnackbar } from 'notistack';
import FormProvider from 'src/components/hook-form/FormProvider';
import { TextBoxGREYERP } from 'src/controls/textbox';
import { CardPanel } from 'src/controls/cardpanel';

// ----------------------------------------------------------------------

export default function AccountGeneral({ userInfo }) {
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = async (data) => {
    try {
      await new Promise((resolve) => setTimeout(resolve, 500));
      enqueueSnackbar('Update success!');
  //    console.log('DATA', data);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <form onSubmit={onSubmit}>
      <CardPanel
        heading={'User Details'}
        cardItem={
          <>
            <TextBoxGREYERP
              disabled
              defaultValue={userInfo?.Name}
              name="displayName"
              label="Name"
            />

            <TextBoxGREYERP
              name="email"
              defaultValue={userInfo?.Email}
              disabled
              label="Email Address"
            />

            <TextBoxGREYERP name="type" defaultValue={userInfo?.Type} disabled label="Type" />

            <TextBoxGREYERP name="status" defaultValue={userInfo?.Status} disabled label="Status" />
            <TextBoxGREYERP
              name="date"
              defaultValue={userInfo?.CreatedDate?.substring(0, 10)}
              disabled
              label="Created Date"
            />
          </>
        }
      />
    </form>
  );
}
