import { useCallback, useContext, useEffect, useState } from 'react';
import { useFormContext, useFieldArray, Controller } from 'react-hook-form';
import {
  Box,
  Stack,
  Button,
  Typography,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  Paper,
  TableFooter,
} from '@mui/material';
import Iconify from '../../components/iconify';
import { FormContext } from 'src/layouts/dashboard/DashboardLayout';
import { TextBoxItem } from 'src/controls/items/textBoxItem';
import { DropDownItem } from 'src/controls/items/dropdownItem';
import GroupedSelectChild from '../selectgroup';
import { useSnackbar } from 'notistack';
import { DatePickerItem } from './datepickerItem';
import { DateTimePickerItem } from './datetimepickerItem';
import { priceListsForms, sectionForms } from 'src/data/data';
import { HandleOnChangeValue, HttpRequest, formatdecimals } from 'src/data/scripts';
import { useFormData } from 'src/hooks/useQueryHooks';
import { useTranslation } from 'react-i18next';

export default function Items({
  formType,
  editModeOnNew,
  columnsToAppend,
  existingItemsDataExternal,
  columnsToShow,
  appendCTA,
  disableQuantity = false,
  disableCalculation = false,
  disableDiscount = false,
  disableSubTotal = false,
  disableTax = false,
  disableAddItem = false,
  heading,
  externalOnChange,
  displayFooterCalc = false,
  disableItem = false,
  dataTbl,
  disabledColumnsExternal = [],
  dataWithoutFilteration,
  appendCalculation = true,
  enterOnChangeFunc = true,
  displaySpecificCalc = false,
  tablename = window.location.pathname.split('/')[2].replaceAll('-', '').slice(0, -1) + 'details',
  postBackCalcFunc,
}) {
  const { t } = useTranslation();

  const formName = window.location.pathname.split('/')[2].replaceAll('-', '').slice(0, -1);
  const JVForm =
    window.location.pathname.split('/')[2].replace('-', '').toUpperCase() == 'JOURNALVOUCHERS';

  const { enqueueSnackbar } = useSnackbar();
  const [itemCount, setItemCount] = useState(0);
  const [dataFetched, setDataFetched] = useState(false);
  const [appendedColumns, setAppendedColumns] = useState();
  const [showColumns, setShowColumns] = useState();
  const { setItems, cloneObject } = useContext(FormContext);
  const [ReTrigger, setReTrigger] = useState(false);
  const [trigger, setTrigger] = useState(false);
  const [qty, setQty] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [taxTotal, setTaxTotal] = useState(0);
  const [discTotal, setDiscTotal] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);
  const [defaultTaxCategory, setDefaultTaxCategory] = useState([]);
  const [itemsReserve, setItemsReserve] = useState();
  const {
    helperText,
    setHelperText,
    resetFields,
    Items,
    setImage,
    existingItemsData,
    setExistingItemsData,
    swappedSections,
    formObject,
  } = useContext(FormContext);
  const [units, setUnits] = useState([]);
  const [taxItems, settaxItems] = useState();
  const [itemsMain, setItemsMain] = useState([]);
  const [disableVariableTax, setDisableVariableTax] = useState({});
  const [displayTotalValues, setDisplayTotalValues] = useState();
  const { control, setValue, watch, reset } = useFormContext();
  const { fields, append, remove, prepend } = useFieldArray({
    control,
    name: 'items',
  });

  const { setFormData: setObject, formData: object, updateWholeForm } = useFormData();

  useEffect(() => {
    if (existingItemsDataExternal?.length > 0) {
      setExistingItemsData(existingItemsDataExternal);
    }

    if (postBackCalcFunc) {
      postBackCalcFunc(values?.items, values?.extra, setValue);
    }
  }, [existingItemsDataExternal, postBackCalcFunc]);

  const values = watch();
  let footerCols =
    values?.items?.length > 0 &&
    columnsToShow &&
    (JVForm ? ['Account', 'Debit', 'Credit', 'Narration'] : Object.keys(columnsToShow));

  let calcCols = JVForm
    ? ['Account', 'Debit', 'Credit', 'Narration']
    : columnsToShow && Object.keys(columnsToShow);

  const handleAdd = () => {
    if (
      (formName.toLowerCase() == 'salesinvoice' ||
        formName.toLowerCase() == 'quotation' ||
        formName.toLowerCase() == 'salesorder' ||
        formName.toLowerCase() == 'salesreturn' ||
        formName.toLowerCase() == 'purchaseorder' ||
        formName.toLowerCase() == 'purchaseinvoice') &&
      !object.DocumentTo &&
      isNaN(window.location.pathname.split('/')[3])
    ) {
      enqueueSnackbar('Please Select Document To', { variant: 'warning' });
      return;
    }

    setItemCount((item) => item + 1);

    if (columnsToAppend && appendCTA) {
      setAppendedColumns(Object.keys(columnsToAppend));
      setShowColumns(Object.keys(columnsToShow));
      append(columnsToAppend);
      return;
    } else if (columnsToShow) {
      setAppendedColumns(Object.keys(columnsToShow));
      append(columnsToShow);
      return;
    } else {
      setAppendedColumns(Object.keys(AllColumns));
      append(AllColumns);
    }
  };

  const handleRemove = (index) => {
    setItemCount((index) => index - 1);
    remove(index);

    if (appendCTA) {
      handleChangeQuantity();
    } else {
      calculateValues(
        values.items.filter((item, indexItem) => indexItem != index),
        taxItems,
        appendedColumns
      );
      setReTrigger(true);
    }
  };

  useEffect(() => {
    if (externalOnChange && (values?.extra?.length == 0 || values?.extra?.length > 0)) {
      externalOnChange(null, values, taxItems);
    }
  }, [itemCount]);

  let AllColumns = {
    ID: 0,
    Item: 0,
    Description: '',
    Quantity: 1,
    Unit: 0,
    Price: 0,
    DiscountPercentage: 0.0,
    Discount: 0,
    Tax: 0,
    TaxAmount: 0,
    Total: 0,
    // [formName]: 0,
  };

  // useEffect(() => {
  //   if (resetFields) {
  //     setItemCount(0);
  //     setTaxTotal(0);
  //     setQty(0);
  //     setDiscTotal(0);
  //     setGrandTotal(0);
  //     setSubTotal(0);
  //     reset({});
  //   }
  // }, [resetFields]);

  useEffect(() => {
    HttpRequest(
      `getItems/taxcategories/name/${localStorage.getItem('username')}/${localStorage.getItem(
        'client'
      )}`
    )
      .then((response) => response)
      .then((taxItems) => {
        setHelperText('');
        settaxItems(taxItems);
        setDefaultTaxCategory(taxItems[0]);

        let id = cloneObject ? cloneObject : window.location.pathname.split('/')[3];
        if (
          (!isNaN(window.location.pathname.split('/')[3]) && dataFetched == false) ||
          cloneObject
        ) {
          let urls = `getRecordById/${
            tablename
              ? tablename
              : window.location.pathname
                  .split('/')[2]
                  .replaceAll('-', '')
                  .slice(0, -1)
                  .toLowerCase() + 'details'
          }/${id}`;
          //console.log("urls", urls);
          HttpRequest(urls)
            .then((response) => response)
            .then((AllitemsEdit) => {
              //  console.log("AllitemsEdit",AllitemsEdit);
              setExistingItemsData((prev = []) => [...AllitemsEdit]);
              let columnsToAppendVariable;
              if (columnsToAppend && appendCTA) {
                setAppendedColumns(Object.keys(columnsToAppend));
                columnsToAppendVariable = Object.keys(columnsToAppend);
                setShowColumns(Object.keys(columnsToShow));
              } else if (columnsToShow) {
                setAppendedColumns(Object.keys(columnsToShow));
                columnsToAppendVariable = Object.keys(columnsToShow);
              } else {
                setAppendedColumns(Object.keys(AllColumns));
                columnsToAppendVariable = Object.keys(AllColumns);
              }

              let quantity = 0;

              AllitemsEdit.forEach((itemEdit, index) => {
                for (const key in itemEdit) {
                  if (columnsToAppendVariable.includes(key)) {
                    if (key == 'Date') {
                      setValue(
                        `items[${index}].${key}`,
                        new Date(itemEdit[key]).toLocaleDateString()
                      );
                    } else {
                      setValue(`items[${index}].${key}`, itemEdit[key]);
                    }
                  } else {
                    setValue(`extra[${index}].${key}`, itemEdit[key]);
                  }
                }
                quantity += itemEdit?.Quantity;
              });

              setQty(quantity);
              //
              if (formName == 'stocktransfer') {
                calculateQuantity(AllitemsEdit);
              }

              (displayFooterCalc || displaySpecificCalc) && handleGenericTotalValues(AllitemsEdit);
              !disableCalculation &&
                calculateValues(AllitemsEdit, taxItems, columnsToAppendVariable);
              setItemCount(AllitemsEdit.length);
              if (externalOnChange) {
                // externalOnChange(null, values, taxItems);
                externalOnChange(null, { items: AllitemsEdit }, taxItems);
              }
              setDataFetched(true);
            });
        }
      });
  }, [existingItemsData, cloneObject]);

  useEffect(() => {
    if (columnsToAppend && formName.toLowerCase() == 'inventoryadjustment') {
      let adjustmentsManipulation =
        values.items &&
        values.items.map((item) => {
          const { Name, ID, ...other } = item;
          other['Item'] = ID;
          return other;
        });

      let itemsArray = {
        [tablename]: {
          items: adjustmentsManipulation,
        },
      };
      setItems((prev) => {
        return { ...prev, ...itemsArray };
      });
    } else if (formName == 'deliverynote') {
      //
      let OrderedQuantity = 0,
        DeliveredQuantity = 0;
      let deliveryNote =
        values.items &&
        values?.items?.map((item) => {
          const { ID, ...other } = item;
          other['SalesOrderDetailsID'] = ID;
          delete other['Item'];
          DeliveredQuantity += Number(item.DeliveredQuantity ?? 0);
          return other;
        });

      values?.extra?.map((item) => {
        OrderedQuantity += Number(item.OrderedQuantity ?? 0);
      });

      setObject({ OrderedQuantity, DeliveredQuantity });
      let itemsArray = {
        [tablename]: {
          items: deliveryNote,
        },
      };
      setItems((prev) => {
        return { ...prev, ...itemsArray };
      });
    } else if (formName.toLowerCase() == 'goodsreceivednote') {
      let TotalQuantity = 0;
      let goodsReceivedNote =
        values?.items &&
        values?.items?.map((item, index) => {
          const { ID, ...other } = item;
          delete other['Item'];
          TotalQuantity += Number(item.ReceivedQuantity);
          other['purchaseorderdetailsid'] = values.extra[index]?.ID;
          return other;
        });

      setObject(TotalQuantity);
      let itemsArray = {
        [tablename]: {
          items: goodsReceivedNote,
        },
      };
      setItems((prev) => {
        return { ...prev, ...itemsArray };
      });
    } else if (formName.toLowerCase() == 'item') {
      let subItems = values?.items?.map((item, index) => {
        const { Item, ...other } = item;
        other['subitem'] = Item;
        return other;
      });
      let itemsArray = {
        [tablename]: {
          items: subItems,
        },
      };
      setItems((prev) => {
        return { ...prev, ...itemsArray };
      });
    } else if (formName == 'receipt' || formName == 'payment') {
      const items = values?.items?.filter((obj) => (!!parseInt(obj?.Allocated) ? true : false));
      let itemsArray = {
        [tablename]: {
          items,
        },
      };

      setItems((prev) => {
        return { ...prev, ...itemsArray };
      });
    } else {
      let itemsArray = {
        [tablename]: values,
      };

      setItems((prev) => {
        return { ...prev, ...itemsArray };
      });

      if (formName == 'expense') {
        HandleOnChangeValue(
          {
            Amount: subTotal,
            Tax: taxTotal,
            TaxCategory: '0',
            Total: grandTotal,
          },
          setObject
        );
      }
      //setItems(values)

      if (appendCalculation) {
        let objectCols = {};
        if (!disableCalculation && !disableQuantity) {
          for (const key in values) {
            if (key == 'items' || key == 'extra') {
            } else {
              objectCols[key] = values[key];
            }
          }
        } else if (disableCalculation && !disableQuantity) {
          //  objectCols['TotalQuantity'] = values['TotalQuantity'];
          objectCols['TotalQuantity'] = qty;
        }
        setObject(objectCols);
      }
    }

    setReTrigger(false);
  }, [ReTrigger]);

  useEffect(() => {
    if (existingItemsData && existingItemsData.length > 0 && !cloneObject) {
      //  reset({});
    }
  }, [existingItemsData, cloneObject]);

  useEffect(() => {
    if (existingItemsData && existingItemsData.length > 0 && !swappedSections && columnsToAppend) {
      let columnsToAppendVariable = Object.keys(columnsToAppend);
      setAppendedColumns(columnsToAppendVariable);
      setShowColumns(Object.keys(columnsToShow));
      let qty = 0;
      existingItemsData.forEach((itemEdit, index) => {
        for (const key in itemEdit) {
          if (columnsToAppendVariable.includes(key)) {
            setValue(`items[${index}].${key}`, itemEdit[key]);
          } else {
            setValue(`extra[${index}].${key}`, itemEdit[key]);
          }
        }

        qty += Number(itemEdit.Quantity);
        if (Object.keys(columnsToShow).includes('Difference')) {
          setValue(`items[${index}].Difference`, 0);
        }
      });

      if (qty) {
        setQty(qty);
      }

      setItemCount(existingItemsData.length);
      setDataFetched(true);
      externalOnChange && externalOnChange(null, { items: existingItemsData }, false, true);
      displayFooterCalc && handleGenericTotalValues(existingItemsData);
      !disableCalculation && calculateValues(existingItemsData, taxItems, columnsToAppendVariable);
    }
  }, [existingItemsData, columnsToAppend]);

  const calculateValues = (items, taxList, appendedColumns) => {
    let qty = 0,
      subTotal = 0,
      taxTotal = 0,
      discTotal = 0,
      grandTotal = 0;

    items.map((item) => {
      let Amount = appendedColumns.includes('Price')
        ? item.Price
        : appendedColumns.includes('Amount')
          ? item.Amount
          : appendedColumns.includes('EstimatedCost') && item.EstimatedCost;
      qty += Number(item.Quantity);
      subTotal += Number(Amount) * Number(item.Quantity ?? 1);
      discTotal += Number(item.Discount ?? 0);
      grandTotal += Number(item.Total);

      const taxItem1 =
        taxList?.filter((item1) => Number(item1.ID) == Number(item.Tax))[0] ?? defaultTaxCategory;
      if (taxItem1.Type == 'Percentage') {
        taxTotal +=
          (Number(item.Quantity ?? 1) * Number(Amount) - Number(item.Discount ?? 0)) *
          (Number(taxItem1.Value) / 100);
      } else {
        taxTotal += Number(taxItem1.Value);
      }
    });

    setQty(qty);
    setSubTotal(subTotal);
    setDiscTotal(discTotal);
    setTaxTotal(taxTotal);
    setGrandTotal(grandTotal);

    setValue(`TotalQuantity`, qty);
    setValue(`SubTotal`, subTotal);
    setValue(`TotalTax`, taxTotal);
    setValue(`TotalDiscount`, discTotal);
    setValue(`GrandTotal`, grandTotal);
  };

  const calculateQuantity = (items) => {
    let qty = 0;

    items.map((item) => {
      qty += Number(item.Quantity);
    });

    setQty(qty);
  };

  let excludedTotalArray = ['requestforquotation', 'leavelists', 'salarystructure', 'employee'];
  let excludedcalcTotalArray = ['requestforquotation', 'salarystructure', 'salaryslip', 'employee'];

  const handleChangeValues = useCallback(
    (event, taxValue, index, field) => {
      const taxFieldExist = field?.name?.split('.')[1] == 'Tax';
      const qtyFieldExist = field?.name?.split('.')[1] == 'Quantity';
      const discFieldExist = field?.name?.split('.')[1] == 'Discount';
      const discPercentFieldExist = field?.name?.split('.')[1] == 'DiscountPercentage';
      if (taxFieldExist) {
        setValue(field.name, Number(taxValue.ID ? taxValue.ID : 0));
      } else if (qtyFieldExist) {
        setValue(field.name, Number(event.target.value ? event.target.value : 1));
      } else {
        setValue(field?.name, Number(event?.target?.value ? event?.target?.value : 0));
      }

      if (
        priceListsForms.includes(
          window.location.pathname.split('/')[2].replaceAll('-', '').toUpperCase()
        ) &&
        object['PriceList'] &&
        formObject?.FullPriceList?.length > 0
      ) {
        const itemField = field?.name?.split('.')[1] == 'Item';
        if (qtyFieldExist || itemField) {
          let plitem;
          formObject?.FullPriceList?.forEach((pli) => {
            if (
              values?.items?.filter((item, index1) => index1 == index)[0]?.Item?.toString() ==
                pli.Item?.toString() ||
              taxValue?.ID?.toString() == pli.Item?.toString()
            ) {
              plitem = pli;
            }
          });
          let itemExist = itemsReserve?.filter((i) => i.Item == plitem?.Item)[0];
          let correctItemRow = itemField
            ? values?.items?.filter((item, index2) => index2 == index)
            : values?.items?.filter((item) => item.Item == plitem?.Item);
          if (correctItemRow.length > 0) {
            //!(Number(plitem?.MinimumQuantity) == 0 && Number(plitem?.MaximumQuantity) == 0) &&
            let qty = qtyFieldExist
              ? event.target.value
              : itemField
                ? values?.items?.filter((item, index1) => index == index1)[0]?.Quantity
                : 0;
            qty = Number(qty);

            if (plitem) {
              if (
                qty >= Number(plitem?.MinimumQuantity || 0) &&
                qty <= Number(plitem?.MaximumQuantity || Infinity)
              ) {
                if (!itemExist) {
                  let itemCopy = Object.assign({}, correctItemRow[0]);
                  setItemsReserve((prev = []) => [...prev, itemCopy]);
                }
                setValue(`items[${index}].Price`, plitem.Price);
              } else {
                if (itemExist) {
                  setValue(`items[${index}].Price`, itemExist?.Price);
                }
              }
            }
          }
        }
      }

      // ((new Date(plitem?.StartDate) <= new Date() &&
      // new Date() <= new Date(plitem?.EndDate)) ||
      // plitem.EndDate == '1900-01-01T00:00:00.000Z' ||
      // plitem.StartDate == '1900-01-01T00:00:00.000Z')

      values?.items?.map((item, indexItem) => {
        let Amount = appendedColumns.includes('Price')
          ? item.Price
          : appendedColumns.includes('Cost')
            ? item.Cost
            : appendedColumns.includes('Amount')
              ? item.Amount
              : appendedColumns.includes('EstimatedCost') && item.EstimatedCost;

        if (discFieldExist) {
          const discountPercentage =
            (Number(item.Discount ?? 0) / (Number(item.Quantity ?? 0) * Number(Amount))) * 100;
          setValue(`items[${indexItem}].DiscountPercentage`, discountPercentage.toFixed(2));
        } else if (discPercentFieldExist) {
          const discount =
            Number(item.DiscountPercentage ?? 0) *
            ((Number(item.Quantity ?? 0) * Number(Amount)) / 100);
          setValue(`items[${indexItem}].Discount`, discount);
        } else if (taxFieldExist || qtyFieldExist || Amount) {
          if (Object.keys(item).includes('Discount')) {
            setValue(`items[${indexItem}].DiscountPercentage`, item.DiscountPercentage);
            setValue(`items[${indexItem}].Discount`, item.Discount);
          }
        } else {
          if (Object.keys(item).includes('Discount')) {
            setValue(`items[${indexItem}].DiscountPercentage`, 0);
            setValue(`items[${indexItem}].Discount`, 0);
          }
        }

        let taxItem = taxItems.filter((item1) => item1.ID == item.Tax)[0] ?? {
          Type: '',
          Value: '',
        };

        if (!taxItem && Object.keys(item).includes('Tax')) {
          taxItem = defaultTaxCategory;

          setValue(`items[${indexItem}].Tax`, taxItem?.ID);
        }

        if (taxFieldExist) {
          let key = item.Item ?? item.ToAccount;
          setDisableVariableTax((prev) => {
            return { ...prev, [key]: true };
          });
        }

        if (excludedTotalArray.includes(formName)) {
          return;
        } else if (formName == 'salaryslip') {
          externalOnChange(event, values);
        } else if (formName?.toLowerCase() == 'expense') {
          if (taxItem.Type == 'Percentage') {
            setValue(
              `items[${indexItem}].TaxAmount`,
              (Number(Amount ?? 0) * (Number(taxItem.Value) / 100)).toFixed(3)
            );
            setValue(
              `items[${indexItem}].Total`,
              (Number(Amount ?? 0) + Number(Amount ?? 0) * (Number(taxItem.Value) / 100)).toFixed(3)
            );
          } else {
            setValue(`items[${indexItem}].TaxAmount`, Number(taxItem.Value).toFixed(3));
            setValue(
              `items[${indexItem}].Total`,
              (Number(Amount ?? 0) + Number(taxItem.Value)).toFixed(3)
            );
          }
        } else {
          if (taxItem.Type == 'Percentage') {
            setValue(
              `items[${indexItem}].Total`,
              (
                Number(item.Quantity ?? 0) * Number(Amount ?? 0) -
                Number(item.Discount ?? 0) +
                (Number(item.Quantity ?? 0) * Number(Amount ?? 0) - Number(item.Discount ?? 0)) *
                  (Number(taxItem.Value) / 100)
              ).toFixed(3)
            );
            setValue(
              `items[${indexItem}].TaxAmount`,
              (
                (Number(item.Quantity ?? 0) * Number(Amount ?? 0) - Number(item.Discount ?? 0)) *
                (Number(taxItem.Value) / 100)
              ).toFixed(3)
            );
          } else {
            setValue(
              `items[${indexItem}].Total`,
              (
                Number(item.Quantity ?? 0) * Number(Amount ?? 0) -
                Number(item.Discount ?? 0) +
                Number(taxItem.Value ?? 0)
              ).toFixed(3)
            );
            Object.keys(item).includes('Tax') &&
              setValue(`items[${indexItem}].TaxAmount`, Number(taxItem.Value).toFixed(3));
          }
        }

        if (Number(Amount) == 0) {
          setValue(`items[${indexItem}].Total`, Number(0));
        }
      });

      if (!excludedcalcTotalArray.includes(formName)) {
        calculateValues(values.items, taxItems, appendedColumns);
      }

      //
      if (formName == 'requestforquotation') {
        let qty = 0;
        values.items.map((i) => {
          qty += Number(i.Quantity);
        });
        setQty(qty);

        setObject({ TotalQuantity: qty });
      }

      setReTrigger(true);

      if (taxFieldExist) {
        return field.onChange(taxValue.ID);
      }
      return field.onChange(event.target.value);
    },
    [
      setValue,
      values.items,
      appendedColumns,
      disableVariableTax,
      taxItems,
      itemsReserve,
      formObject,
    ]
  );

  const handleChangeValuesForAdjustments = useCallback(
    (event, taxValue, index, field) => {
      setValue(field.name, Number(event.target.value ? event.target.value : 0));

      values.items.map((item, indexItem) => {
        if (indexItem == index) {
          setValue(`extra[${indexItem}].Converted`, parseInt(event.target.value));
          setValue(
            `items[${indexItem}].Difference`,
            -(parseInt(event.target.value) - parseInt(item.OnHandQuantity))
          );
        }
      });

      setReTrigger(true);
      handleGenericTotalValues(values.items);
      return field.onBlur(event.target.value);
    },
    [setValue, values.extra, values.items, itemsMain]
  );

  const handleGenericTotalValues = useCallback(
    (existingItemsData) => {
      let totalVal = {};
      let colFirstTime = {};
      let excludedCols = ['Item', 'Unit', 'Count', 'Name'];
      for (const key in existingItemsData[0]) {
        if (existingItemsData[0][key] && typeof parseInt(existingItemsData[0][key]) === 'number') {
          colFirstTime[key] = true;
        }
      }
      let grandTotal = 0;
      let qty = 0;
      existingItemsData.map((item, index) => {
        qty += Number(item.Quantity);
        for (const key in item) {
          let forbidden = false;
          excludedCols.includes(key) && (forbidden = true);
          if (Object.keys(columnsToShow).includes(key) && !forbidden) {
            if (colFirstTime[key]) {
              totalVal[`Total.${key}`] =
                item[key] && typeof parseInt(item[key]) === 'number'
                  ? parseFloat(item[key] ?? 0)
                  : 0;
              colFirstTime[key] = false;
            } else {
              for (const key2 in totalVal) {
                if (totalVal.hasOwnProperty(`Total.${key}`) && key2.split('.')[1] == key) {
                  totalVal[`Total.${key}`] += parseFloat(item[key] ?? 0);
                }
              }
            }
          }

          if (key == 'Total') {
            setValue(`items[${index}].Total`, Number(item.Quantity) * Number(item.EstimatedCost));
          }
        }

        if (item.EstimatedCost) {
          grandTotal += Number(item.Quantity) * Number(item.EstimatedCost);
          totalVal[`Total.${'Total'}`] = grandTotal;
          setGrandTotal(grandTotal);
        }
      });

      if (formName.toLowerCase() == 'inventoryadjustment') {
        setObject({
          OnHandQuantity: totalVal['Total.OnHandQuantity'],
          DifferenceQuantity: totalVal['Total.Difference'] ?? 0,
          CountedQuantity: totalVal['Total.CountedQuantity'],
        });
      } else if (formName.toLowerCase() == 'purchaserequisition') {
        setObject({
          TotalQuantity: totalVal['Total.Quantity'],
          EstimatedCost: totalVal['Total.EstimatedCost'] ?? 0,
          GrandTotal: totalVal['Total.Total'],
        });
      }

      setQty(qty);
      setReTrigger(true);
      setDisplayTotalValues(totalVal);
    },
    [setValue, values.items, appendedColumns, existingItemsData]
  );

  const handleChangeQuantity = useCallback(
    (event, taxValue, index, field) => {
      field && setValue(field.name, Number(event.target.value ? event.target.value : 0));

      let qty = 0;
      values.items.map((item, indexItem) => {
        qty += item.Quantity;
      });

      setQty(qty);
      setValue('TotalQuantity', qty);
      setReTrigger(true);

      return field && field.onBlur(event.target.value);
    },
    [setValue, values.items]
  );

  useEffect(() => {
    if (
      sectionForms.includes(window.location.pathname?.split('/')[2].toUpperCase()) &&
      swappedSections
    ) {
      if (Items[tablename]) {
        const { GrandTotal, SubTotal, TotalTax, TotalQuantity, TotalDiscount, items, extra } =
          Items[tablename];

        let TotalQuantityItems = 0;
        let Total = 0;
        let columnsToAppendVariable;
        if (columnsToAppend && appendCTA) {
          setAppendedColumns(Object.keys(columnsToAppend));
          columnsToAppendVariable = Object.keys(columnsToAppend);
          setShowColumns(Object.keys(columnsToShow));
        } else if (columnsToShow) {
          setAppendedColumns(Object.keys(columnsToShow));
          columnsToAppendVariable = Object.keys(columnsToShow);
        } else {
          setAppendedColumns(Object.keys(AllColumns));
          columnsToAppendVariable = Object.keys(AllColumns);
        }

        items?.forEach((itemEdit, index) => {
          for (const key in itemEdit) {
            setValue(`items[${index}].${key}`, itemEdit[key]);
          }
          TotalQuantityItems += Number(itemEdit.Quantity ?? 0);
          Total += Number(itemEdit.Total ?? 0);
        });

        setQty(TotalQuantity ?? TotalQuantityItems);
        setSubTotal(SubTotal);
        setDiscTotal(TotalDiscount);
        setTaxTotal(TotalTax);
        setGrandTotal(GrandTotal ?? Total);
        setItemCount(items?.length ?? 0);
        setValue(`TotalQuantity`, TotalQuantity);
        setValue(`SubTotal`, SubTotal);
        setValue(`TotalTax`, TotalTax);
        setValue(`TotalDiscount`, TotalDiscount);
        setValue(`GrandTotal`, GrandTotal);
      }
    }
  }, [swappedSections]);

  const alteredFieldNames = [
    {
      actualName: 'Converted',
      displayName: 'Base Qty',
    },
    {
      actualName: 'Quantity',
      displayName: `Qty (${qty})`,
    },
    {
      actualName: 'Discount',
      displayName: 'Disc',
    },
    {
      actualName: 'DiscountPercentage',
      displayName: 'Disc %',
    },
    {
      actualName: 'ToAccount',
      displayName: 'To Account',
    },
    {
      actualName: 'TaxAmount',
      displayName: 'Tax Amount',
    },
    {
      actualName: 'NewLocation',
      displayName: 'New Location',
    },
    {
      actualName: 'ToEmployee',
      displayName: 'To Employee',
    },
    {
      actualName: 'SkillID',
      displayName: 'Skill',
    },
    {
      actualName: 'SkillLevel',
      displayName: 'Level',
    },
    {
      actualName: 'EvaluationDate',
      displayName: 'Evaluation Date',
    },
    {
      actualName: 'DependentName',
      displayName: 'Dependent Name',
    },
    {
      actualName: 'DependentRelation',
      displayName: 'Relationship',
    },
    {
      actualName: 'DependentDOB',
      displayName: 'Date of Birth',
    },
    {
      actualName: 'LanguageName',
      displayName: 'Language Name',
    },
    {
      actualName: 'LanguageLevel',
      displayName: 'Level',
    },
    {
      actualName: 'LeaveType',
      displayName: 'Leave Type',
    },
    {
      actualName: 'SalaryComponent',
      displayName: 'Salary Component',
    },
    {
      actualName: 'InitialUnit',
      displayName: 'Initial Unit',
    },
    {
      actualName: 'InitialQuantity',
      displayName: 'Initial Quantity',
    },
    {
      actualName: 'ReceivedUnit',
      displayName: 'Received Unit',
    },
    {
      actualName: 'MinimumQuantity',
      displayName: 'Min Quantity',
    },
    {
      actualName: 'MaximumQuantity',
      displayName: 'Max Quantity',
    },
    {
      actualName: 'ReceivedQuantity',
      displayName: 'Received Quantity',
    },
    {
      actualName: 'ToAccount',
      displayName: 'Account',
    },
    {
      actualName: 'DeliveredUnit',
      displayName: 'Delivered Unit',
    },
    {
      actualName: 'NextDueDate',
      displayName: 'Next DueDate',
    },
    {
      actualName: 'DeliveredQuantity',
      displayName: 'Delivered Quantity',
    },
    {
      actualName: 'OrderedUnit',
      displayName: 'Ordered Unit',
    },
    {
      actualName: 'OrderedQuantity',
      displayName: 'Ordered Quantity',
    },
    {
      actualName: 'CountedQuantity',
      displayName: 'Count',
    },
    {
      actualName: 'OnHandQuantity',
      displayName: 'On Hand',
    },
    {
      actualName: 'text',
      displayName: 'Note',
    },
    {
      actualName: 'WorkCenter',
      displayName: 'Work Center',
    },
    {
      actualName: 'StartDate',
      displayName: 'Start Date',
    },
    {
      actualName: 'BillOfMaterial',
      displayName: 'BOM',
    },
  ];

  const dropDownUrlsColNameCommon = [
    {
      tablename: 'SalaryStructureEarnings',
      apiUrl: 'salarycomponentsearning/name',
      nameControl: 'SalaryComponent',
    },
    {
      tablename: 'SalaryStructureDeductions',
      apiUrl: 'salarycomponentsdeduction/name',
      nameControl: 'SalaryComponent',
    },
    {
      tablename: 'salaryslipearnings',
      apiUrl: 'salarycomponentsearning/name',
      nameControl: 'SalaryComponent',
    },
    {
      tablename: 'salaryslipdeductions',
      apiUrl: 'salarycomponentsdeduction/name',
      nameControl: 'SalaryComponent',
    },
    {
      tablename: 'employeeeducationdetails',
      apiUrl: 'language/name',
      nameControl: 'Level',
    },
    {
      tablename: 'employeelanguagedetails',
      apiUrl: 'language/name',
      nameControl: 'LanguageLevel',
    },
    {
      tablename: 'employeeskilldetails',
      apiUrl: 'skills/name',
      nameControl: 'SkillID',
    },
    {
      tablename: 'employeeskilldetails',
      apiUrl: 'language/name',
      nameControl: 'SkillLevel',
    },
    {
      tablename: 'Calls',
      apiUrl: 'callstatus/name',
      nameControl: 'Status',
    },
    {
      tablename: 'tasks',
      apiUrl: 'assignee/name',
      nameControl: 'Assignee',
    },
    {
      tablename: 'tasks',
      apiUrl: 'projects/name',
      nameControl: 'Project',
    },
  ];

  const dropDownUrls = [
    {
      nameControl: 'LeaveType',
      apiUrl: 'leavetypes/name',
    },
    {
      nameControl: 'Warehouse',
      apiUrl: 'warehouses/name',
    },
    {
      nameControl: 'BillOfMaterial',
      apiUrl: 'billofmaterials/name',
    },
    {
      nameControl: 'DependentRelation',
      apiUrl: 'dependent/name',
    },
    {
      nameControl: 'Method',
      apiUrl: 'splitmethods/name',
    },
    {
      nameControl: 'Username',
      apiUrl: 'clientusers/name',
    },
    {
      nameControl: 'Employee',
      apiUrl: 'employees/name',
    },
    {
      nameControl: 'Operation',
      apiUrl: 'operations/name',
    },
    {
      nameControl: 'WorkCenter',
      apiUrl: 'workcenters/name',
    },
    {
      nameControl: 'ToEmployee',
      apiUrl: 'employees/name',
    },
    {
      nameControl: 'interval',
      apiUrl: 'intervals/name',
    },
    {
      nameControl: 'Asset',
      apiUrl: 'assets/name',
    },
    {
      nameControl: 'NewLocation',
      apiUrl: 'locations/name',
    },
    {
      nameControl: 'Day',
      apiUrl: 'days/name',
    },
    {
      nameControl: 'Tax',
      apiUrl: 'taxcategories/name',
    },
    {
      nameControl: 'Size',
      apiUrl: 'sizes/name',
    },
    {
      nameControl: 'Colour',
      apiUrl: 'colours/name',
    },
  ];

  return (
    <>
      <Typography variant="h6" sx={{ pt: 4, pb: 1, px: 5 }} paragraph>
        {heading ?? <>Items</>} {`(${itemCount})`}
      </Typography>
      <Table
        sx={{
          borderRadius: '6px',
          pb: 4,
          px: 5,
          mx: 4,
          mb: 2,
          '& fieldset': {
            border: '0px solid white !important',
          },
        }}
        id={'itemsbox'}
      >
        <TableHead>
          <TableRow>
            {footerCols &&
              footerCols.map((item) => {
                if (item == 'ID' || (item.toLowerCase() == formName && formName != 'item')) {
                  return false;
                }
                item =
                  alteredFieldNames.filter(
                    (name) => name.actualName.toLowerCase() == item.toLowerCase()
                  )[0]?.displayName ?? item;
                return (
                  <TableCell sx={{ fontSize: 12, p: '8px', fontWeight: 'bold' }}>{item}</TableCell>
                );
              })}
          </TableRow>
        </TableHead>
        <TableBody>
          {values.items &&
            values.items.map((item, index) => {
              if (isNaN(window.location.pathname.split('/')[3])) {
                if (item.hasOwnProperty('ID')) {
                  //delete item['ID'];
                } else if (item.hasOwnProperty(formName)) {
                  //delete item[formName];
                }
              }

              let itemProperties;
              if (columnsToShow) {
                itemProperties = Object.keys(columnsToShow);
              } else {
                itemProperties = Object.keys(item);
              }

              return (
                <>
                  <TableRow>
                    {itemProperties.map((property) => {
                      let nameControl = property;
                      if (
                        nameControl == 'id' ||
                        nameControl == 'ID' ||
                        (nameControl.toLowerCase() == formName && formName != 'item')
                      ) {
                        return;
                      }
                      let Dropdowns = [
                        'Item',
                        'BillOfMaterial',
                        'Interval',
                        'Operation',
                        'WorkCenter',
                        'Warehouse',
                        'Employee',
                        'ToEmployee',
                        'Asset',
                        'NewLocation',
                        'Username',
                        'Unit',
                        'Tax',
                        'InitialUnit',
                        'ReceivedUnit',
                        'OrderedUnit',
                        'DeliveredUnit',
                        'Account',
                        'ToAccount',
                        'Level',
                        'DependentRelation',
                        'Method',
                        'Skill',
                        'LeaveType',
                        'SalaryComponent',
                        'Level',
                        'Day',
                        'SkillID',
                        'SkillLevel',
                        'LanguageLevel',
                        'Size',
                        'Colour',
                        'Status',
                        'Assignee',
                      ];

                      let DatePicker = [
                        'Date',
                        'EvaluationDate',
                        'DependentDOB',
                        'StartDate',
                        'EndDate',
                        'NextDueDate',
                      ];

                      let DateTimePicker = [
                        'CheckIn',
                        'CheckOut',
                        'StartTime',
                        'EndTime',
                        'DateTime',
                        'DueDateTime',
                      ];

                      formName?.toLowerCase() == 'maintenance' && Dropdowns.splice(0, 1);

                      let Numbers = [
                        'TaxAmount',
                        'Quantity',
                        'Price',
                        'Cost',
                        'Amount',
                        'Discount',
                        'DiscountPercentage',
                        'EstimatedCost',
                        'Total',
                        'Year',
                        'Percentage',
                        'GrandTotal',
                        'Outstanding',
                        'Allocated',
                      ];
                      let disabledColumns = [
                        'TaxAmount',
                        'Total',
                        'InitialUnit',
                        'InitialQuantity',
                        'OrderedUnit',
                        'OrderedQuantity',
                        'Description',
                        'OnHandQuantity',
                        'Converted',
                        'Damages',
                        'Engine',
                        'Difference',
                        'GrandTotal',
                        'Outstanding',
                        'Number',
                        'InvoiceDate',
                        'DueDate',
                        'InvoiceReference',
                      ];

                      let groupedDropdownCols = ['ToAccount', 'Account'];

                      let apiUrl;
                      let itemID;
                      let handleChangeValuesFunc;
                      let disabled = false;

                      let fieldName = appendedColumns?.includes(property)
                        ? `items[${index}].${nameControl}`
                        : `extra[${index}].${nameControl}`;

                      disabled = disabledColumns?.includes(nameControl) ? true : false;
                      disabled = disabledColumnsExternal?.includes(nameControl.toUpperCase())
                        ? true
                        : disabled;

                      if (nameControl == 'Item') {
                        apiUrl = `items${formType}/name`;
                        if (appendCTA) {
                          handleChangeValuesFunc = handleChangeQuantity;
                        } else if (!disableCalculation && !disableQuantity) {
                          handleChangeValuesFunc = handleChangeValues;
                        }
                        disabled = disableItem ? true : false;
                      } else if (nameControl == 'Description') {
                        disabled = disableItem ? true : false;
                      }

                      apiUrl =
                        dropDownUrls?.filter(
                          (item) => item.nameControl.toLowerCase() == nameControl.toLowerCase()
                        )[0]?.apiUrl ?? apiUrl;
                      apiUrl =
                        dropDownUrlsColNameCommon.filter(
                          (item) =>
                            item.nameControl.toLowerCase() == nameControl.toLowerCase() &&
                            item.tablename.toLowerCase() == tablename.toLowerCase()
                        )[0]?.apiUrl ?? apiUrl;

                      if (nameControl?.toUpperCase().includes('UNIT')) {
                        itemID = item.Item ?? item.ID;
                        apiUrl = 'unitofmeasurements/name';
                      }

                      let prop = item.Item ?? item.ToAccount;

                      if (disableVariableTax && Object.keys(disableVariableTax)?.length > 0) {
                        for (const key in disableVariableTax) {
                          if (nameControl == 'TaxAmount' && key == prop) {
                            disabled = disableVariableTax[key];
                          }
                        }
                      } else {
                        if (nameControl == 'TaxAmount') {
                          disabled = true;
                        }
                      }

                      const colWithWidth = [
                        'Description',
                        'Item',
                        'ToAccount',
                        'DueDate',
                        'InvoiceDate',
                        'Number',
                        'InvoiceReference',
                        'Allocated',
                      ];
                      const colWithWidth250 = [
                        'CheckIn',
                        'CheckOut',
                        'StartTime',
                        'Employee',
                        'EndTime',
                        'LeaveType',
                        'SalaryComponent',
                        'Method',
                        'Name',
                        'Username',
                        'Date',
                        'ToEmployee',
                        'Asset',
                        'NewLocation',
                        'NextDueDate',
                        'Level',
                        'WorkCenter',
                        'Operation',
                        'BillOfMaterial',
                        'Warehouse',
                        'StartDate',
                      ];
                      return (
                        <>
                          <TableCell
                            sx={{
                              border: '1px solid rgb(224, 224, 224)',
                              padding: '0px',
                              width: colWithWidth.includes(nameControl)
                                ? 'auto !important'
                                : colWithWidth250.includes(nameControl)
                                  ? '250px'
                                  : nameControl == 'Image'
                                    ? '350px'
                                    : nameControl == 'Total'
                                      ? '130px'
                                      : nameControl == 'Tax'
                                        ? '130px'
                                        : '100px !important',
                            }}
                          >
                            {Dropdowns.includes(nameControl) ? (
                              <Controller
                                name={fieldName}
                                control={control}
                                render={({ field, fieldState: { error } }) => {
                                  if (groupedDropdownCols.includes(nameControl)) {
                                    return (
                                      <GroupedSelectChild
                                        name={`items[${index}].${nameControl}`}
                                        data={dataTbl}
                                        field={field}
                                        setReTrigger={setReTrigger}
                                        values={values}
                                        index={index}
                                        dataWithoutFilteration={dataWithoutFilteration}
                                      />
                                    );
                                  } else {
                                    return (
                                      <DropDownItem
                                        field={field}
                                        name={fieldName}
                                        required={nameControl == 'Item' && true}
                                        optionsListAPI={apiUrl}
                                        formType={formType}
                                        label={t(nameControl)}
                                        index={index}
                                        append={append}
                                        disabled={disabled}
                                        disableQuantity={disableQuantity}
                                        handleChangeValues={handleChangeValuesFunc}
                                        handleGenericTotalValues={handleGenericTotalValues}
                                        displayFooterCalc={displayFooterCalc}
                                        taxItems={taxItems}
                                        onChange={
                                          nameControl == 'Tax'
                                            ? (event, selectedValue) =>
                                                handleChangeValues(
                                                  event,
                                                  selectedValue,
                                                  index,
                                                  field
                                                )
                                            : null
                                        }
                                        Units={units ?? []}
                                        setUnits={setUnits}
                                        appendedColumns={appendedColumns}
                                        setQty={setQty}
                                        setDisableVariableTax={setDisableVariableTax}
                                        itemID={itemID}
                                        editModeOnNew={editModeOnNew}
                                        setReTrigger={setReTrigger}
                                        trigger={trigger}
                                        setTrigger={setTrigger}
                                      />
                                    );
                                  }
                                }}
                              />
                            ) : DatePicker.includes(nameControl) ? (
                              <Controller
                                name={fieldName}
                                control={control}
                                render={({ field, fieldState: { error } }) => {
                                  return (
                                    <DatePickerItem
                                      field={field}
                                      name={fieldName}
                                      placeholder={nameControl}
                                      setReTrigger={setReTrigger}
                                    />
                                  );
                                }}
                              />
                            ) : DateTimePicker.includes(nameControl) ? (
                              <Controller
                                name={fieldName}
                                control={control}
                                render={({ field, fieldState: { error } }) => {
                                  return (
                                    <DateTimePickerItem
                                      field={field}
                                      name={fieldName}
                                      placeholder={nameControl}
                                      setReTrigger={setReTrigger}
                                    />
                                  );
                                }}
                              />
                            ) : (
                              <Controller
                                name={fieldName}
                                control={control}
                                render={({ field, fieldState: { error } }) => {
                                  if (nameControl == 'Image') {
                                    delete field.value;
                                  }
                                  return (
                                    <TextBoxItem
                                      size="small"
                                      required={nameControl == 'Description' && true}
                                      field={field}
                                      placeholder={nameControl}
                                      name={fieldName}
                                      index={index}
                                      type={
                                        nameControl == 'Image'
                                          ? 'file'
                                          : Numbers.includes(nameControl) && 'number'
                                      }
                                      multiline={nameControl == 'Description' && true}
                                      disabled={disabled}
                                      itemsMain={itemsMain}
                                      setReTrigger={setReTrigger}
                                      handleGenericTotalValues={handleGenericTotalValues}
                                      displayFooterCalc={displayFooterCalc}
                                      disableVariableTax={disableVariableTax}
                                      onChange={(event) => {
                                        if (enterOnChangeFunc) {
                                          if (
                                            Numbers.includes(nameControl) &&
                                            (displayFooterCalc || displaySpecificCalc)
                                          ) {
                                            handleGenericTotalValues(values.items);
                                          } else if (Numbers.includes(nameControl)) {
                                            if (appendCTA) {
                                              handleChangeQuantity(event, null, index, field);
                                            } else {
                                              handleChangeValues(event, null, index, field);
                                            }
                                          } else if (nameControl == 'CountedQuantity') {
                                            handleChangeValuesForAdjustments(
                                              event,
                                              null,
                                              index,
                                              field
                                            );
                                          } else if (nameControl == 'Amount') {
                                            externalOnChange(event, values);
                                          } else if (
                                            nameControl == 'DeliveredQuantity' ||
                                            nameControl == 'ReceivedQuantity'
                                          ) {
                                            setReTrigger(true);
                                          }
                                        } else if (nameControl == 'Image') {
                                          let file = event.target.files[0];

                                          file &&
                                            setImage((prev) => {
                                              return { ...prev, [`items[${index}].Image`]: file };
                                            });
                                          setValue(`items[${index}].Image`, file?.name);
                                          setReTrigger(true);
                                        } else if (nameControl == 'Quantity') {
                                          let qty = 0;
                                          values.items.map((item) => {
                                            qty += Number(item.Quantity);
                                          });

                                          setQty(qty);
                                          setReTrigger(true);
                                        } else if (nameControl == 'Allocated') {
                                          externalOnChange(values?.items, values?.extra, setValue);
                                          setReTrigger(true);
                                        }
                                      }}
                                    />
                                  );
                                }}
                              />
                            )}
                          </TableCell>
                        </>
                      );
                    })}
                    {/* width: '10px',ml:"2px",
                          minWidth: 'initial', */}
                    {!disableAddItem && (
                      <Button
                        sx={{
                          '& .css-cstir9-MuiButton-startIcon': {
                            margin: 0,
                            //
                          },
                          ml: 1,
                          mr: 3,
                          minWidth: '30px',
                        }}
                        size="small"
                        color="error"
                        startIcon={<Iconify icon="eva:trash-2-outline" />}
                        onClick={() => handleRemove(index)}
                      ></Button>
                    )}
                  </TableRow>
                </>
              );
            })}

          {displayFooterCalc && displayTotalValues && (
            <TableRow>
              {footerCols &&
                footerCols.map((item) => {
                  if (item == 'id' || item == 'ID' || item.toLowerCase() == formName) {
                    return false;
                  }
                  let valueBox;
                  for (const key in displayTotalValues) {
                    if (key.split('.')[1] == item) {
                      valueBox = displayTotalValues[key];
                    }
                  }
                  return (
                    <>
                      <TableCell
                        sx={{
                          fontWeight: 'bold',
                          border: '1px solid rgb(224, 224, 224)',
                        }}
                      >
                        {valueBox}
                      </TableCell>
                    </>
                  );
                })}
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            {calcCols &&
              calcCols.map((item, index) => {
                return (
                  <TableCell
                    colSpan={calcCols.length - 3 == index && 3}
                    sx={{ fontSize: 12, p: '8px', fontWeight: 'bold', verticalAlign: 'top' }}
                  >
                    {index == 0 ? (
                      !disableAddItem && (
                        <Button
                          size="small"
                          startIcon={<Iconify icon="eva:plus-fill" />}
                          onClick={handleAdd}

                          // sx={{ flexShrink: 0, mt: 1, ml: 4, my: 5 }}
                        >
                          Add Item
                        </Button>
                      )
                    ) : calcCols.length - 3 == index ? (
                      <Box
                        sx={{
                          '& ': {
                            color: 'black',
                          },
                        }}
                      >
                        {displaySpecificCalc && (
                          <Stack spacing={1} sx={{ mb: 0, pb: 2 }}>
                            <Stack direction="row" justifyContent="flex-end">
                              <Typography variant="h6">Net Amount :</Typography>
                              <Typography variant="h6" sx={{ textAlign: 'right', width: 120 }}>
                                {grandTotal ? formatdecimals(grandTotal) : formatdecimals(0)}
                              </Typography>
                            </Stack>
                          </Stack>
                        )}
                        {!disableCalculation && (
                          <Stack spacing={1} sx={{ mb: 0, pb: 2 }}>
                            {!disableSubTotal && (
                              <Stack direction="row" justifyContent="flex-end">
                                <Typography>Sub Total :</Typography>
                                <Typography
                                  className="input-label"
                                  id="SubTotal"
                                  sx={{ textAlign: 'right', width: 120 }}
                                >
                                  {subTotal ? formatdecimals(subTotal) : formatdecimals(0)}
                                </Typography>
                              </Stack>
                            )}
                            {!disableDiscount && (
                              <Stack direction="row" justifyContent="flex-end">
                                <Typography>Discount :</Typography>
                                <Typography
                                  className="input-label"
                                  id="TotalDiscount"
                                  sx={{
                                    textAlign: 'right',
                                    width: 120,

                                    ...(values?.discount && { color: 'error.main' }),
                                  }}
                                >
                                  {discTotal ? formatdecimals(discTotal) : formatdecimals(0)}
                                </Typography>
                              </Stack>
                            )}
                            {!disableTax && (
                              <Stack direction="row" justifyContent="flex-end">
                                <Typography>Taxes :</Typography>
                                <Typography
                                  id="TotalTax"
                                  className="input-label"
                                  sx={{ textAlign: 'right', width: 120 }}
                                >
                                  {taxTotal ? formatdecimals(taxTotal) : formatdecimals(0)}
                                </Typography>
                              </Stack>
                            )}
                            <Stack direction="row" justifyContent="flex-end">
                              <Typography variant="h6">Net Amount :</Typography>
                              <Typography
                                variant="h6"
                                id="GrandTotal"
                                className="input-label"
                                sx={{ textAlign: 'right', width: 120 }}
                              >
                                {grandTotal ? formatdecimals(grandTotal) : formatdecimals(0)}
                              </Typography>
                            </Stack>
                            <Typography
                              id="TotalQuantity"
                              style={{ visibility: 'hidden' }}
                              className="input-label"
                            >
                              {qty}
                            </Typography>
                          </Stack>
                        )}
                      </Box>
                    ) : null}
                  </TableCell>
                );
              })}
          </TableRow>
        </TableFooter>
      </Table>
      {helperText && (
        <Stack
          spacing={2}
          direction={{ xs: 'row', md: 'row' }}
          alignItems={{ xs: 'flex-start' }}
          justifyContent={'space-between'}
          sx={{ flexShrink: 0, mt: 1, mx: 4, my: 2, pb: 2 }}
        >
          <Typography variant="subtitle1" sx={{ color: 'red', fontSize: 12 }}>
            {helperText}
          </Typography>
        </Stack>
      )}
    </>
  );
}
