import { TextBoxGREYERP } from 'src/controls/textbox';
import PopUpPanel from 'src/controls/popuppanel';
import UploadGREY from 'src/controls/uploadgrey';
import { SwithGREYERP } from 'src/controls/swithgrey';
export default function TeamMember() {
    return (
        <>
                      <PopUpPanel
        heading={'Add'}
        child={
          <>
            <UploadGREY span={2}/>
            <TextBoxGREYERP  name="Name" placeholder="Member Name" required={true} focus={true}/>
            <TextBoxGREYERP  name="NameArabic" placeholder="اسم"  dir="rtl"/>
            <TextBoxGREYERP  name="Designation" placeholder="Designation"  />
            <TextBoxGREYERP name="DesignationArabic" placeholder="تعيين"  dir="rtl"/>
            <TextBoxGREYERP  name="About" placeholder="About"  MultiLine={true} span={2}/>
            <TextBoxGREYERP  name="AboutArabic" placeholder="عن"  MultiLine={true} dir="rtl" span={2}/>
            <TextBoxGREYERP  name="Hyperlink" placeholder="Hyperlink" type="Url"/>
            <TextBoxGREYERP  name="HyperlinkArabic" placeholder="ارتباط تشعبي" type="Url" />
            <TextBoxGREYERP  name="Sort" placeholder="Sort" type="Number" span={.5} required={true} />
            <SwithGREYERP  name="Status" span={.5}  />
    </>
   }
   />
 </>
);
}