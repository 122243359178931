import { CardPanel } from 'src/controls/cardpanel';
import { DropDownGREYERP } from 'src/controls/dropdown';
import { TextBoxGREYERP } from 'src/controls/textbox';
import {
  LeadSource,
  Titles,
  CompanyTypes,
  Access,
  JobTypes,
  Gender,
  BloodGroup,
  Religion,
  MaritalStatus,
  Nationality,
  Status,
  MotherTongue,
  ApplicationStatus,
  Banks,
} from 'src/data/data';
import { DropDownENUM } from 'src/controls/dropdownenum';
import UploadGREY from 'src/controls/uploadgrey';
import { ItemsComponent } from 'src/controls/items/ItemsComponent';
import { TextEditorGrey } from 'src/controls/texteditorgrey';
import ImageContainer from 'src/controls/imagecontainer';
import { CheckBoxGREYERP } from 'src/controls/checkbox';
import Picker from 'src/controls/picker';

export default function Employee() {
  return (
    <>
      <CardPanel
        heading="Employee Details"
        cardItem={
          <>
            <UploadGREY />
            <ImageContainer>
              <DropDownENUM items={Titles} name="Title" focus={true} required={true} />
              <TextBoxGREYERP placeholder="John" name="FirstName" required={true} />
              <TextBoxGREYERP placeholder="Doe" name="MiddleName" />
              <TextBoxGREYERP placeholder="Doe" name="Lastname" />
              <Picker
                type="date"
                name="JoiningDate"
                placeholder="Employee Joined Date"
                required
              ></Picker>
              <DropDownGREYERP items={`designations/name`} name="Designation" required={true} />
              <DropDownENUM items={JobTypes} name="Type" />
              <DropDownGREYERP items={`departments/name`} name="Department" required={true} />
              <DropDownGREYERP items="employeegrades/name" name="Grade" required={true} />
              <DropDownGREYERP items="employeegroups/name" name="EmployeeGroup" required={true} />
              <DropDownGREYERP items="employees/name" name="Manager" />
              <TextBoxGREYERP placeholder="Username" name="Username" />
              <TextBoxGREYERP placeholder="DeviceID" name="DeviceID" />
              <CheckBoxGREYERP name="Status" label="Active" span={0.4} />
            </ImageContainer>
          </>
        }
      />

      <CardPanel
        heading="Work Details"
        cardItem={
          <>
            <TextBoxGREYERP
              placeholder="009689461XXX"
              name="WorkPhone"
              type="Number"
              required={true}
            />
            <TextBoxGREYERP placeholder="someone@something.com" name="WorkEmail" />
            <TextBoxGREYERP
              placeholder="Way# XXX, Ghala, Muscat..."
              name="WorkAddress"
              multiline={true}
              span={2}
            />
            <DropDownGREYERP items="locations/name" required={true} name="WorkLocation" />
            <DropDownGREYERP items={`WorkingHours/name`} name="WorkingHours" required={true} />
            <DropDownGREYERP
              items={`SalaryStructures/name`}
              name="SalaryStructure"
              required={true}
            />
            <DropDownGREYERP items={`HolidayLists/name`} name="HolidayList" required={true} />
            <DropDownGREYERP items={`LeaveLists/name`} name="LeaveList" required={true} />
            <DropDownGREYERP items={`LeaveBlockLists/name`} name="LeaveBlockList" required={true} />
            <TextBoxGREYERP
              label="Notice Period"
              placeholder="30"
              name="NoticePeriod"
              required={true}
              type="number"
            />
            <TextBoxGREYERP
              placeholder="Remarks (Optional)"
              name="Comments"
              multiline={true}
              span={2}
            />
          </>
        }
      />
      <CardPanel
        heading="License Details"
        cardItem={
          <>
            <TextBoxGREYERP name="License" type="file" multiple={true} />
            <TextBoxGREYERP name="LicenseNumber" />
            <Picker type="date" name="LicenseIssue" label="Issue Date" />
            <Picker type="date" name="LicenseExpiry" label="Expiry Date" />
          </>
        }
      />

      <CardPanel
        heading="ID Card Details"
        cardItem={
          <>
            <TextBoxGREYERP name="IDCard" type="file" multiple={true} />
            <TextBoxGREYERP name="IDCardNumber" />
            <Picker type="date" name="IDCardIssue" label="Issue Date" />
            <Picker type="date" name="IDCardExpiry" label="Expiry Date" />
          </>
        }
      />
      <CardPanel
        heading="Personal Details"
        cardItem={
          <>
            <TextBoxGREYERP placeholder="009689461XXX" name="Phone" type="Number" />
            <TextBoxGREYERP
              placeholder="009719461XXX"
              name="Phone2"
              label="AlternatePhone"
              type="Number"
            />
            <TextBoxGREYERP placeholder="someone@something.com" name="Email" />
            <TextBoxGREYERP
              placeholder="Way# XXX, Ghala, Muscat..."
              name="Address"
              multiline={true}
              span={2}
            />
            <TextBoxGREYERP placeholder="Emergency Contact Person" name="EmergencyContactPerson" />
            <DropDownGREYERP
              items="dependent/name"
              name="EmergencyPersonRelationship"
              label={'Emergency Person Relation'}
            />
            <TextBoxGREYERP placeholder="009719461XXX" name="EmergencyPhone" type="Number" />
            <DropDownENUM firstItemSelect={false} required={true} items={Gender} name="Gender" />
            <DropDownENUM firstItemSelect={false} items={BloodGroup} name="BloodGroup" />
            <DropDownENUM firstItemSelect={false} items={Religion} name="Religion" />
            <DropDownENUM firstItemSelect={false} items={MotherTongue} name="MotherTongue" />
            <Picker type="date" name="DOB" placeholder="DOB"></Picker>
            <Picker type="date" name="RetirementDate" placeholder="Retirement Date"></Picker>
            <DropDownENUM firstItemSelect={false} items={MaritalStatus} name="MaritalStatus" />
            <DropDownENUM firstItemSelect={false} items={Nationality} name="Nationality" />
            <DropDownENUM firstItemSelect={false} items={ApplicationStatus} name="Status" />
            <TextBoxGREYERP name="CV" type="file" label="CV" value="" />
          </>
        }
      />
      <CardPanel
        heading="About"
        cardItem={
          <>
            <TextEditorGrey name="About" placeholder="Content" />
          </>
        }
      />

      <CardPanel
        heading="Passport Details"
        cardItem={
          <>
            <TextBoxGREYERP name="Passport" type="file" multiple={true} />
            <TextBoxGREYERP name="PassportNumber" />
            <Picker type="date" name="PassportIssue" label="Issue Date" />
            <Picker type="date" name="PassportExpiry" label="Expiry Date" />
          </>
        }
      />

      <CardPanel
        heading="Visa Details"
        cardItem={
          <>
            <TextBoxGREYERP name="Visa" type="file" multiple={true} />
            <TextBoxGREYERP name="VisaNo" label="VisaNumber" />
            <Picker type="date" name="VisaIssue" label="Issue Date" />
            <Picker type="date" name="VisaExpiry" label="Expiry Date" />
          </>
        }
      />

      <CardPanel
        heading="Bank Account Details"
        cardItem={
          <>
            <TextBoxGREYERP name="AccountNumber" placeholder="100250698720" />
            <TextBoxGREYERP name="IBAN" placeholder="IBAN" label="IBAN" />
            <DropDownENUM firstItemSelect={false} items={Banks} name="Bank" />
            <TextBoxGREYERP name="Branch" placeholder="Ruwi" />
            <TextBoxGREYERP
              name="BankAddress"
              placeholder="Way# XXX, Ghala, Muscat..."
              MultiLine={true}
            />
            <TextBoxGREYERP name="IFSCCode" label="IFSC Code" placeholder="IFSC Code" />
          </>
        }
      />

      <CardPanel
        heading="Previous Work Details"
        cardItem={
          <>
            <TextBoxGREYERP
              name="PreviousCompanyName"
              placeholder="Bahwan Engineering"
              label="Company Name"
            />
            <DropDownGREYERP
              items={`designations/name`}
              name="PreviousJobDesignation"
              label="Job Designation"
            />
            <TextBoxGREYERP
              name="PreviousJobDuration"
              placeholder="Job Duration"
              label="Job Duration"
              type="Number"
            />
            <Picker
              type="date"
              name="PreviousJobLeftDate"
              label="Job left Date"
              placeholder="Employee Job left Date"
            ></Picker>
            <TextBoxGREYERP
              name="PreviousJobLeftReason"
              placeholder="Career Growth"
              label="Job Left Reason"
              MultiLine={true}
            />
            <TextBoxGREYERP name="ReferenceName" placeholder="George Mathew" />
            <TextBoxGREYERP name="ReferencePhone" placeholder="009689461XXX" type="Number" />
            <TextBoxGREYERP name="ReferenceEmail" placeholder="xxx@abc.com" />
          </>
        }
      />

      <ItemsComponent
        tablename="employeeeducationdetails"
        heading={'Education Details'}
        disableCalculation={true}
        disableQuantity={true}
        columnsToShow={{
          Course: '',
          Level: '',
          College: '',
          University: '',
          Year: 0,
          Percentage: 0.0,
        }}
      />

      <ItemsComponent
        tablename="employeeskilldetails"
        heading={'Skill Details'}
        disableCalculation={true}
        disableQuantity={true}
        columnsToShow={{ SkillID: '', SkillLevel: '', EvaluationDate: '' }}
      />

      <ItemsComponent
        tablename="employeedependentdetails"
        heading={'Dependent Details'}
        disableCalculation={true}
        disableQuantity={true}
        columnsToShow={{ DependentName: '', DependentRelation: '', DependentDOB: '' }}
      />

      <ItemsComponent
        tablename="employeelanguagedetails"
        heading={'Language Details'}
        disableCalculation={true}
        disableQuantity={true}
        columnsToShow={{ LanguageName: '', LanguageLevel: '' }}
      />
    </>
  );
}
