import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { alpha, styled } from '@mui/material/styles';
import { Box, Divider, Typography, Stack, MenuItem, FormControlLabel, Switch } from '@mui/material';

// components
import { CustomAvatar } from '../../../components/custom-avatar';
import { useSnackbar } from 'notistack';
import MenuPopover from '../../../components/menu-popover';
import { IconButtonAnimate } from '../../../components/animate';
import { useSettingsContext } from 'src/components/settings';
import { FormContext } from '../DashboardLayout';
import { useTranslate } from 'src/locales';

// ----------------------------------------------------------------------

const OPTIONS = [
  {
    label: 'Support',
    linkTo: 'https://greyerp.com/support',
  },
  {
    label: 'Settings',
    linkTo: '/utility/settings',
  },
  // {
  //   label: 'Marketplace',
  //   linkTo: '/utility/marketplace',
  // },
  // {
  //   label: 'About Us',
  //   linkTo: 'https://rawatmakan.com',
  // },
];

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  width: 60,
  height: 30,
  padding: 9,
  '& .MuiSwitch-switchBase': {
    margin: 1,
    padding: 0,
    transform: 'translateX(6px)',
    '&.Mui-checked': {
      color: '#fff',
      transform: 'translateX(22px)',
      '& .MuiSwitch-thumb:before': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          '#fff'
        )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
      },
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: theme.palette.mode === 'dark' ? '#003892' : '#fdcc6c',
    width: 26,
    height: 26,
    '&:before': {
      content: "''",
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
        '#fff'
      )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
    },
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
    borderRadius: 20 / 2,
  },
}));

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const navigate = useNavigate();
  const {t} = useTranslate()

  const { themeMode, setThemeModeGrey } = useSettingsContext();

  const { enqueueSnackbar } = useSnackbar();

  const [openPopover, setOpenPopover] = useState(false);

  const handleOpenPopover = (event) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(false);
  };

  const handleLogout = async () => {
    try {
      localStorage.removeItem('isAuthenticated');
      localStorage.removeItem('requestedPath');
      localStorage.removeItem('client');
      localStorage.removeItem('username');
      localStorage.removeItem('navConfigERP');
      handleClosePopover();
      navigate(0, { replace: true });
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout!', { variant: 'error' });
    }
  };

  const handleClickItem = (path) => {
    handleClosePopover();
    navigate(path);
  };

  const avatar = localStorage.getItem('clientpicture').toString();
  return (
    <>
      <IconButtonAnimate
        onClick={handleOpenPopover}
        sx={{
          p: 0,
          ...(openPopover && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        {avatar !== 'null' ? (
          <CustomAvatar
            src={`https://uploads.greyerp.com/${avatar}`}
            name={localStorage.getItem('username')}
          />
        ) : (
          <CustomAvatar name={localStorage.getItem('username')} />
        )}
      </IconButtonAnimate>

      <MenuPopover open={openPopover} onClose={handleClosePopover} sx={{ width: 200, p: 0 }}>
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {localStorage.getItem('username')}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack
          sx={{
            px: 1,
            '& li': {
              color: themeMode == 'dark' && 'white !important',
            },
          }}
        >
          {OPTIONS.map((option) => (
            <MenuItem key={option.label} onClick={() => handleClickItem(option.linkTo)}>
              {t(option.label)}
            </MenuItem>
          ))}
        </Stack>
        <Box>
          <FormControlLabel
            sx={{ width: '100%', justifyContent: 'space-between' }}
            control={
              <MaterialUISwitch
                onChange={(event) => {
                  let theme = event.target.checked ? 'dark' : 'light';
                  localStorage.setItem('mode', theme);
                  setThemeModeGrey(theme);
                }}
                defaultChecked={themeMode == 'light' ? false : true}
                value={themeMode == 'light' ? false : true}
                sx={{ mr: 3 }}
              />
            }
            label={t("Mode")}
            labelPlacement="start"
          />
        </Box>
        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem
          onClick={handleLogout}
          sx={{ m: 1, color: themeMode == 'dark' && 'white !important' }}
        >
         { t("Logout")}
        </MenuItem>
      </MenuPopover>
    </>
  );
}
