import { alpha, styled } from '@mui/material/styles';
import {
  Card,
  Grid,
  Table,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  Popover,
  Tooltip,
  IconButton,
} from '@mui/material';
import { useContext, useEffect, useRef, useState } from 'react';
import React from 'react';
import { FormContext } from 'src/layouts/dashboard/DashboardLayout';
import { HttpRequest, amountInWords, formatdecimals, toTitleCase } from 'src/data/scripts';
import { useReactToPrint } from 'react-to-print';
import Iconify from 'src/components/iconify/Iconify';

const StyledRowResult = styled(TableRow)(({ theme }) => ({
  '& td': {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));

export default function PrintReceipt() {
  const [data, setData] = useState();
  const { openPrint, setOpenPrint, recordId } = useContext(FormContext);
  const componentRef = useRef();
  let receipt = window.location.pathname.split('/')[2]?.toUpperCase() == 'RECEIPTS';

  useEffect(() => {
    GetData();
  }, []);

  async function GetData() {
    let data = await HttpRequest(
      `getRecordById/${receipt ? `receiptprintdetails` : `paymentprintdetails`}/${recordId}`
    );

    setData((prev) => {
      return { ...prev, ...data[0] };
    });

    let Layout = await HttpRequest(
      `getvalue/clients/${receipt ? `receiptlayout` : `paymentlayout`}/${localStorage.getItem(
        'client'
      )}`
    );
    let documentLayout;
    receipt
      ? (documentLayout = await HttpRequest(
          `getRecordById/documentlayouts/${Layout[0].receiptlayout}`
        ))
      : (documentLayout = await HttpRequest(
          `getRecordById/documentlayouts/${Layout[0].paymentlayout}`
        ));
    setData((prev) => {
      return { ...prev, ...documentLayout[0] };
    });
  }

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const RowOneStart = [
    ['Rials', 'Bzs'],
    [
      formatdecimals(data?.Total || 0).split('.')[0],
      formatdecimals(data?.Total || 0).split('.')[1],
    ],
  ];

  const RowOneEnd = [
    {
      Name: 'Dated: ',
      Value: data?.Date,
    },
    {
      Name: 'Number: ',
      Value: `${data?.series || ''}${data?.number || ''}`,
    },
  ];

  const Rows = [
    {
      NameEnglish: receipt ? `Receipt To` : 'Paid To',
      NameArabic: receipt ? 'مستلم من' : 'إيصال إلى',
      Value: data?.Company,
    },
    {
      NameEnglish: 'The Sum Of',
      NameArabic: 'مبلغ وقدره',
      Value: `${data?.Total ? `${toTitleCase(amountInWords(data.Total))} Only` : ''}`,
    },
    {
      NameEnglish: receipt ? `Received As` : `Paid As`,
      NameArabic: 'نقدا / شيك رقم',
      Value: `${
        data?.Mode == 'Cheque'
          ? 'Cheque, Cheque #' + data?.ChequeNo + ', Cheque Date: ' + data.ChequeDate
          : data?.Mode
      }`,
    },
    {
      NameEnglish: 'Narration',
      NameArabic: ' وذلك عن',
      Value: data?.description,
    },
    {
      NameEnglish: receipt ? "Receiver's Sign." : "Payer's Sign.",
      NameArabic: receipt ? 'توقيع المستلم' : 'توقيع الدافع',
      Value: '',
    },
  ];

  //console.log(data);

  return (
    <>
      <Popover
        sx={{
          backgroundColor: alpha('#212B36', 0.4),
          '& *': {
            fontWeight: 'bold',
          },
          '& table p': {
            fontSize: '13px',
          },
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={openPrint}
      >
        <Card ref={componentRef} sx={{ pt: 5, px: 3, width: '850px', '& img': { width: '100%' } }}>
          <Grid container alignItems={'center'}>
            <Grid item sx={{ ml: 'auto', displayPrint: 'none' }}>
              <Tooltip title="Print">
                <IconButton onClick={handlePrint}>
                  <Iconify icon="eva:printer-fill" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Close">
                <IconButton onClick={(e) => setOpenPrint(false)}>
                  <Iconify icon="material-symbols:close" />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
          <Grid container>
            <div
              className="ql-editor"
              style={{ width: '100%' }}
              dangerouslySetInnerHTML={{ __html: data?.Header }}
            />
          </Grid>

          <Grid container justifyContent={'center'}>
            <Typography variant="h4">
              {data?.Heading &&
              (data?.Heading == 'DOCUMENT NAME' || data?.Heading == '<p>DOCUMENT NAME</p>') ? (
                receipt ? (
                  'RECEIPT VOUCHER'
                ) : (
                  'PAYMENT VOUCHER'
                )
              ) : (
                <div
                  className="ql-editor"
                  style={{ width: '100%' }}
                  dangerouslySetInnerHTML={{ __html: data?.Heading }}
                />
              )}
            </Typography>
          </Grid>

          <Grid container sx={{ pt: 3 }} justifyContent={'space-between'}>
            <Grid item sx={{}}>
              <TableContainer sx={{ overflow: 'unset' }}>
                <Table sx={{ width: '100%', ml: '15px' }}>
                  {
                    <TableBody>
                      {RowOneStart.map((item, index) => {
                        return (
                          <StyledRowResult
                            sx={{
                              '& *': {
                                py: '0px !important',
                              },
                            }}
                          >
                            {item.map((val) => {
                              return (
                                <TableCell sx={{ border: index == 1 && '1px solid black' }}>
                                  <Typography variant="span">{val}</Typography>
                                </TableCell>
                              );
                            })}
                          </StyledRowResult>
                        );
                      })}
                    </TableBody>
                  }
                </Table>
              </TableContainer>
            </Grid>
            <Grid item flexBasis={'30%'}>
              {RowOneEnd.map((item) => {
                return (
                  <Typography variant="body2">
                    {item.Name}
                    <Typography variant="span" sx={{ fontWeight: '200 !important' }}>
                      {item.Value}
                    </Typography>
                  </Typography>
                );
              })}
            </Grid>
          </Grid>

          <TableContainer sx={{ overflow: 'unset' }}>
            <Table sx={{ width: '100%', mt: 2 }}>
              <TableBody>
                {Rows.map((item, index) => {
                  return (
                    <StyledRowResult>
                      <TableCell width={140}>
                        <>
                          <Typography variant="body2">{item.NameEnglish}</Typography>
                        </>
                      </TableCell>
                      <TableCell
                        colSpan={10}
                        align="left"
                        sx={{
                          typography: 'subtitle1',
                          borderBottom: !(index == Rows.length - 1) ? 'dotted 1px' : '',
                        }}
                      >
                        <Typography variant="body2">{item.Value}</Typography>
                      </TableCell>

                      <TableCell align="right" width={140}>
                        <Typography>{item.NameArabic}</Typography>
                      </TableCell>
                    </StyledRowResult>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>

          <div className="ql-editor" dangerouslySetInnerHTML={{ __html: data?.Bottom }} />
          <div className="ql-editor" dangerouslySetInnerHTML={{ __html: data?.Footer }} />
        </Card>
      </Popover>
    </>
  );
}
