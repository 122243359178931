import { Divider, Grid, Stack, Typography } from '@mui/material';
import { setDate } from 'date-fns';
import React, { useContext, useEffect, useState } from 'react';

import { HttpRequest, toTitleCase } from 'src/data/scripts';
import { FormContext } from 'src/layouts/dashboard/DashboardLayout';
import { mDateTime } from 'src/utils/formatTime';

function PrintDocumentLayout(props) {
  let FormName = window.location.pathname.split('/')[2]?.replaceAll('-', '')?.toUpperCase();
  const [docLayout, setdocLayout] = useState();
  const [dates, setDates] = useState(
    props?.dates ?? {
      firstDate: '',
      lastDate: '',
    }
  );

  const { printRef } = useContext(FormContext);

  async function GetData() {
    let docLayoutDetailsRes = await HttpRequest(`getRecordById/documentlayouts/37`);

    setdocLayout((prev) => {
      return { ...prev, ...docLayoutDetailsRes[0] };
    });
  }
  useEffect(() => {
    if (props?.dates) {
      setDates(props.dates);
    }

    GetData();
  }, [props]);

  return (
    <Stack sx={{ height: '100%', mx: 0, px: 0 }} ref={printRef} id="printablediv">
      <Stack
        justifyContent={'center'}
        alignItems={'center'}
        sx={{ pb: 3, display: 'none', displayPrint: 'flex' }}
      >
        <Typography sx={{ fontWeight: 'bold', fontSize: '25px' }}>
          {
            JSON.parse(localStorage.getItem('companies')).filter(
              (record) => record.ID == localStorage.getItem('client')
            )[0].Name
          }
        </Typography>
        <Typography sx={{ fontWeight: 'bold', fontSize: '16px', mt: 1 }}>
          {dates?.Name
            ? dates?.Name
            : toTitleCase(
                window.location.pathname
                  .split('/')[2]
                  .toLowerCase()
                  .replaceAll('report', '')
                  .replaceAll('-', ' ')
              )}
        </Typography>
        <Typography sx={{ fontSize: '12px' }}>
          {' '}
          {mDateTime(dates?.firstDate).split(' ')[0]} to {mDateTime(dates?.lastDate).split(' ')[0]}
        </Typography>
      </Stack>
      {React.cloneElement(props.children, {
        font: docLayout?.Font,
        fontSize: docLayout?.FontSize,
        color: docLayout?.Color,
        setDates,
      })}
      <Stack
        sx={{
          '& img': { width: '100%' },
          p: '0px !important',
          display: 'none',
          displayPrint: 'block',
          mx: 0,
          px: 0,
        }}
      >
        <div
          className="ql-editor"
          style={{ width: '100%', alignSelf: 'flex-end' }}
          dangerouslySetInnerHTML={{ __html: docLayout?.Bottom }}
        />
      </Stack>
      <Stack
        sx={{
          '& img': { width: '100%' },
          p: '0px !important',
          display: 'none',
          displayPrint: 'block',
        }}
      >
        <Divider sx={{ mt: 5 }} />

        <div
          className="ql-editor"
          style={{ width: '100%', alignSelf: 'flex-end' }}
          dangerouslySetInnerHTML={{ __html: docLayout?.Footer }}
        />
      </Stack>
    </Stack>
  );
}
export default PrintDocumentLayout;
