import React from 'react';
import { CardPanel } from 'src/controls/cardpanel';
import { ItemsComponent } from 'src/controls/items/ItemsComponent';
import { TextBoxGREYERP } from 'src/controls/textbox';

export default function PriceList() {
  let sales = window.location.pathname.split('/')[1]?.toUpperCase() == 'SALES';
  return (
    <>
      <CardPanel
        heading="List Details"
        cardItem={
          <>
            <TextBoxGREYERP placeholder="Name" name="Name" required={true} focus={true} />
            <TextBoxGREYERP placeholder="Description" name="Description" multiline={true} span={2} />
            <TextBoxGREYERP        placeholder="Internal Notes" name="Comments" multiline={true} span={2} />
          </>
        }
      />

      <ItemsComponent
        tablename={sales ? 'salespricelistdetails': 'purchasepricelistdetails' }
        formType={sales ? 'saleable' : 'purchasable'}
        disableCalculation={true}
        disableQuantity={true}
        enterOnChangeFunc={false}
        columnsToShow={{
          Item: 0,
          Unit: 1,
          Price: 0,
          MinimumQuantity: 0,
          MaximumQuantity: 0,
          // StartDate: '',
          // EndDate: '',
        }}
      />
    </>
  );
      }

