import { useContext } from 'react';
import { CardPanel } from 'src/controls/cardpanel';
import { DropDownGREYERP } from 'src/controls/dropdown';
import { TextBoxGREYERP } from 'src/controls/textbox';
import { ItemsComponent } from 'src/controls/items/ItemsComponent';
import Picker from 'src/controls/picker';
import { FormContext } from 'src/layouts/dashboard/DashboardLayout';
import { GetVoucherNumber, HandleOnChangeValue } from 'src/data/scripts';

export default function GoodsReceivedNotes() {
  const { object, setObject, objectEdit, formObject, setFormObject, setExistingItemsData } =
    useContext(FormContext);
  const { Series = '', Number = '', PurchaseOrder, Company } = object || {};
  const { Series: EditSeries = '', Number: EditNumber = '' } = objectEdit || {};
  const { Sections, PurchaseOrders } = formObject || {};


  return (
    <>
      <CardPanel
        heading="Fields"
        cardItem={
          <>
            <Picker type='date'
              required={true}
              date={new Date()}
              name="Date"
              type="date"
              focus={true}
            />
            <TextBoxGREYERP name="Subject" placeholder="GRN Subject" />

            <TextBoxGREYERP name="Reference" placeholder="GRN Reference" />

            <DropDownGREYERP
              required={true}
              items={`company/name`}
              name="Company"
              onChange={(e, selectedValue) => {
                HandleOnChangeValue(
                  {
                    Company: selectedValue?.ID,
                  },
                  setObject,
                  [
                    GetVoucherNumber(selectedValue?.Name, setObject, 'GoodsReceivedNote'),
                    GetAndSetList(
                      `getItems/warehousesections/name/${localStorage.getItem(
                        'username'
                      )}/${localStorage.getItem('client')}/${parseInt(selectedValue?.ID)}`,
                      setFormObject,
                      'accSummary'
                    ),
                    GetAndSetList(
                      `/getItems/PurchaseOrders/name/${localStorage.getItem(
                        'username'
                      )}/${localStorage.getItem('client')}/${selectedValue?.ID}`,
                      setFormObject,
                      'invoicesList'
                    ),
                  ]
                );
              }}
              postBackSelectedValue={Company}
            />

            <TextBoxGREYERP
              defaultValue={Series ? Series + Number : EditSeries + EditNumber}
              disabled
              required={true}
              name="Number"
              placeholder="GRN Number"
            />

            <DropDownGREYERP
              postBackitems={PurchaseOrders}
              onChange={(e, selectedValue) =>
                HandleOnChangeValue(
                  {
                    Company: selectedValue?.ID,
                  },
                  setObject,
                  [
                    GetAndSetList(
                      `getCustomPostBackItem/purchaseorderdetails/${localStorage.getItem(
                        'username'
                      )}/${localStorage.getItem('client')}/${parseInt(selectedValue?.ID)}`,
                      setExistingItemsData,
                      null
                    ),
                  ]
                )
              }
              postBackSelectedValue={PurchaseOrder}
              required={true}
              name="PurchaseOrder"
              label="Source Document"
            />

            <DropDownGREYERP required={true} items={`warehouses/name`} name="Warehouse" />

            <DropDownGREYERP postBackitems={Sections} name="Section" />

            <TextBoxGREYERP
              name="Comments"
              multiline={true}
              placeholder="Internal Notes"
              span={2}
            />
          </>
        }
      />

      <ItemsComponent
        formType="purchasable"
        disableAddItem={true}
        disableQuantity={true}
        disableCalculation={true}
        disabledColumns={['INITIALQUANTITY', 'INITIALUNIT']}
        columnsToAppend={{ Item: 0, ReceivedUnit: 0, ReceivedQuantity: 0, Note: '' }}
        columnsToShow={{
          Item: 0,
          InitialQuantity: 0,
          InitialUnit: 0,
          ReceivedQuantity: 0,
          ReceivedUnit: 0,
          Note: '',
        }}
      />
    </>
  );
}
