
// eslint-disable-next-line import/no-unresolved
import { CardPanel } from 'src/controls/cardpanel';
import { TextBoxGREYERP } from 'src/controls/textbox';

import { PayrollFrequency } from 'src/data/data';
import { DropDownENUM } from 'src/controls/dropdownenum';

import UppyFilePicker from 'src/controls/uppyFilePicker';
import { ItemsComponent } from 'src/controls/items/ItemsComponent';
export default function SalaryStructure() {
  return (
    <>

      <CardPanel
        heading="Salary Structure Details"
        cardItem={
          <>
            <TextBoxGREYERP placeholder="Name" name="Name" required={true} focus={true} />

            <DropDownENUM items={PayrollFrequency} name="PayrollFrequency" required={true} />

            <TextBoxGREYERP
              placeholder="25"
              name="LeaveEncashment"
              required
              type="number"
              step="any"
            />
            <TextBoxGREYERP
              placeholder="5"
              name="MaximumBenefits"
              required
              type="number"
              step="any"
            />
            <TextBoxGREYERP placeholder="Description" name="Description" span={2} multiline={true} MultiLine={true} />
            <TextBoxGREYERP
              placeholder="Internal Notes"
              name="Comments" multiline={true}
              MultiLine={true}
              span={2}
            />
          </>
        }
      />

      <ItemsComponent
        tablename="SalaryStructureEarnings"
        heading={'Earnings'}
        disableCalculation={true}
        disableQuantity={true}
        columnsToShow={{ SalaryComponent: '', Amount: 0 }}
      />
      <ItemsComponent
        tablename="SalaryStructureDeductions"
        heading={'Deductions'}
        disableCalculation={true}
        disableQuantity={true}
        columnsToShow={{ SalaryComponent: '', Amount: 0 }}
      />
    </>
  );
}
