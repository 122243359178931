import { DropDownGREYERP } from 'src/controls/dropdown';
import { TextBoxGREYERP } from 'src/controls/textbox';
import PopUpPanel from 'src/controls/popuppanel';

export default function Departments() {
  return (
    <>
      <PopUpPanel
        heading={'Add'}
        child={
          <>
            <TextBoxGREYERP span={2} name="Name" placeholder="Accounts" required={true} />
            <DropDownGREYERP span={2} items="employees/name" name="Manager" />
            <DropDownGREYERP span={2} items="departments/name" name="ParentDepartment" />
            <TextBoxGREYERP span={2} name="Comments" multiline={true} placeholder="Comments (Optional)" type="Internal Notes" />
          </>
        }
      />
    </>
  );
}
