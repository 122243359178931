/* eslint-disable react/prop-types */
import { FormControl, Grid } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import * as React from 'react';
import { FormContext } from 'src/layouts/dashboard/DashboardLayout';
import { sectionForms } from 'src/data/data';
import useResponsive from 'src/hooks/useResponsive';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import { useFormData } from 'src/hooks/useQueryHooks';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

const DATE = 'date';
const TIME = 'time';
const DATETIME = 'dateTime';

const Picker = ({
  type = 'date',
  name,
  required,
  date,
  focus,
  label,
  onChange,
  postBackDate,
  span,
  disabled,
  objectSet = true,
}) => {
  const { popupEditId, swappedSections } = useContext(FormContext);
  const { t } = useTranslation();

  const [valueDate, setValueDate] = useState('');
  const isDesktop = useResponsive('up', 'lg');

  let form = window.location.pathname?.split('/')[2]?.replaceAll('-', '');
  let ID = window.location.pathname?.split('/')[3]?.replaceAll('-', '') ?? popupEditId;

  const { setFormData: setObject, formData: object, updateWholeForm } = useFormData();

  const { data: objectEdit } = useQuery({
    queryKey: [form, ID],
    select: (res) => res[0],
    enabled: Boolean(form),
  });

  const checkDateValidity = (date) => date && date instanceof Date && !isNaN(date);

  function SetDateFormat(date) {
    if (checkDateValidity(date)) {
      let dateNew = date.toLocaleDateString('en-US').split('/');
      if (type === DATETIME) {
        setObject({ [name]: date?.toISOString() });
      } else if (type === DATE) {
        setObject({
          [name]: `${dateNew[2]}-${
            dateNew[0]?.toString().length < 2 ? `0${dateNew[0]}` : dateNew[0]
          }-${dateNew[1]?.toString().length < 2 ? `0${dateNew[1]}` : dateNew[1]}T00:00:00Z`,
        });
      }

      if (date.getFullYear() === 1900) setValueDate('');
      else setValueDate(date);
    }
  }

  useEffect(() => {
    if (date) {
      SetDateFormat(date);
    }
  }, []);

  useEffect(() => {
    if (postBackDate && objectSet) {
      SetDateFormat(new Date(postBackDate));
    }
  }, [postBackDate]);

  useEffect(() => {
    if (objectEdit && Object.keys(objectEdit).length > 0) {
      if (name === TIME) {
        let currentDate = new Date();

        let timeString = objectEdit[name];

        let timeParts = timeString.split(':');
        let hours = parseInt(timeParts[0]);
        let minutes = parseInt(timeParts[1]);

        currentDate.setHours(hours);
        currentDate.setMinutes(minutes);
        SetDateFormat(currentDate);
      } else {
        if (objectEdit[name]) {
          SetDateFormat(new Date(objectEdit[name]));
        }
      }
    }
  }, [objectEdit]);

  useEffect(() => {
    if (
      sectionForms.includes(window.location.pathname?.split('/')[2]?.toUpperCase()) &&
      swappedSections
    ) {
      setValueDate(new Date(object[name]));
    }
  }, []);

  return (
    <Grid md={span ?? 1} lg={span ?? 1} xs={!isDesktop && 12} item>
      {type === DATE ? (
        <DatePicker
          label={label ? t(label) : t(name?.match(/[A-Z][a-z]+|[0-9]+/g)?.join(' '))}
          disabled={disabled}
          format="dd/MM/yyyy"
          slotProps={{
            textField: {
              required: required,
              size: 'small',
            },
          }}
          value={valueDate ? valueDate : null}
          name={name}
          onChange={
            onChange
              ? onChange
              : (e) => {
                  setValueDate(e);
                  if (checkDateValidity(e)) {
                    let dateNew = e.toLocaleDateString('en-US').split('/');
                    setObject({
                      [name]: `${dateNew[2]}-${
                        dateNew[0]?.toString().length < 2 ? `0${dateNew[0]}` : dateNew[0]
                      }-${
                        dateNew[1]?.toString().length < 2 ? `0${dateNew[1]}` : dateNew[1]
                      }T00:00:00Z`,
                    });
                  } else {
                    let objectCopy = { ...object };
                    delete objectCopy[name];
                    updateWholeForm(objectCopy);
                  }
                }
          }
        />
      ) : type === DATETIME ? (
        <DateTimePicker
          size="small"
          disabled={disabled}
          viewRenderers={{
            hours: renderTimeViewClock,
            minutes: renderTimeViewClock,
            seconds: renderTimeViewClock,
          }}
          slotProps={{
            textField: {
              required: required,
              size: 'small',
            },
          }}
          label={`${name?.match(/[A-Z][a-z]+|[0-9]+/g)?.join(' ')}`}
          value={valueDate ? valueDate : null}
          format="dd/MM/yyyy hh:mm a"
          name={name}
          onChange={
            onChange
              ? onChange
              : (e) => {
                  //    console.log(e);
                  if (e) {
                    SetDateFormat(e);
                  } else {
                    let objectCopy = { ...object };
                    delete objectCopy[name];
                    updateWholeForm(objectCopy);
                  }
                }
          }
        />
      ) : type === TIME ? (
        <TimePicker
          disabled={disabled}
          value={valueDate ? valueDate : null}
          label={`${name?.match(/[A-Z][a-z]+|[0-9]+/g)?.join(' ')}`}
          name={name}
          viewRenderers={{
            hours: renderTimeViewClock,
            minutes: renderTimeViewClock,
            seconds: renderTimeViewClock,
          }}
          slotProps={{
            textField: {
              required: required,
              size: 'small',
            },
          }}
          onChange={
            onChange
              ? onChange
              : (e) => {
                  setValueDate(e);
                  if (checkDateValidity(e)) {
                    const hour = e?.getHours();
                    const minute = e?.getMinutes();
                    setObject({ [name]: `${hour}:${minute}` });
                  } else {
                    let objectCopy = { ...object };
                    delete objectCopy[name];
                    updateWholeForm(objectCopy);
                  }
                }
          }
        />
      ) : (
        <></>
      )}
    </Grid>
  );
};

export default Picker;
