import PropTypes from 'prop-types';
import { forwardRef, useContext, useEffect, useRef, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// @mui
import { Tooltip, Link, ListItemText, Button, Stack, IconButton, Box } from '@mui/material';

//
import Iconify from '../../iconify';
import { StyledItem, StyledIcon } from './styles';
import { FormContext } from 'src/layouts/dashboard/DashboardLayout';
import { formsWithoutAdd, rowForms } from 'src/data/data';
import { useSettingsContext } from 'src/components/settings';
import { useTranslate } from 'src/locales';
// ----------------------------------------------------------------------

const NavItem = forwardRef(({ item, depth, open, active, isExternalLink, ...other }, ref) => {
  const navigate = useNavigate();
  const [reportHead, setreportHead] = useState(false);
  const [newClickedRowForm, setNewClickedRowForm] = useState(false);
  const { themeMode, setThemeModeGrey} = useSettingsContext();
  const { t } = useTranslate();

  //const moduleName = window.location.pathname.split("/");

  let dashboardTitle;
  let titleRevised;
  const { title, path, icon, children, disabled, roles, child } = item;

  const {
    setFirstRowVisibility,
    gridTableInstance,
    firstRowInfo,
    plusClicked,
    setPlusClicked,
    setNewPopup,
    popupItems,
  } = useContext(FormContext);

 
  if (title.toUpperCase().includes('DASHBOARD')) {
    dashboardTitle =
      title.split(' ')[1].charAt(0).toUpperCase() + title.split(' ')[1].toLowerCase().slice(1);
  } else if (title.toUpperCase().includes('REPORT')) {
    dashboardTitle = title.charAt(0).toUpperCase() + title.toLowerCase().slice(1);
  } else {
    if (title.length == 2 || title.length == 3) {
      titleRevised = title;
    } else {
      titleRevised = title.charAt(0).toUpperCase() + title.toLowerCase().slice(1);
    }
  }

  useEffect(() => {
    if (title.toUpperCase() == 'REPORTS') {
      setreportHead(true);
    }
  }, []);

  useEffect(() => {
    setNewPopup(false);
    if (
      rowForms.includes(window.location.pathname.split('/')[2]) &&
      gridTableInstance &&
      child &&
      window.location.pathname.split('/')[3]?.toUpperCase() == 'NEW' &&
      newClickedRowForm
    ) {
      gridTableInstance.toggleAllPageRowsSelected(false);
      setFirstRowVisibility(true);
      gridTableInstance.setEditingRow(firstRowInfo);
      setPlusClicked(true);
      //navigate((path + '/new').toLowerCase())
      return;
    } else if (
      popupItems.includes(window.location.pathname.split('/')[2]) &&
      window.location.pathname.split('/')[3]?.toUpperCase() == 'NEW'
    ) {
      setNewPopup(true);
    }
  }, [window.location.pathname.split('/')[2], window.location.pathname.split('/')[3]]);

  const subItem = depth !== 1;

  const btnClickRef = useRef(null);
  function handleClickBtn(e) {
    if (rowForms.includes(window.location.pathname.split('/')[2])) {
      gridTableInstance.toggleAllPageRowsSelected(false);
      setFirstRowVisibility(true);
      gridTableInstance.setEditingRow(firstRowInfo);
      setPlusClicked(true);
      navigate((path + '/new').toLowerCase());
      return;
    }

    navigate((path + '/new').toLowerCase());
  }

  const renderContent = (
    <Box sx={{ display: 'flex', mx: child ? 0 : 0.5, alignItems: 'center' }} >
      <Link
        component={RouterLink}
        to={child ? path.toLowerCase() : null}
        underline="none"
        sx={{
          width: '100%',
          flex: 2,
          ':hover': {
            color: 'secondary',
            backgroundColor: 'rgba(145, 158, 171, 0.08)',
          },
        }}
        // sx={{ ml: child || title === 'Dashboard' ? 0 : 3 }}
      >
        <StyledItem
          // onClick={(e) => {
          //   setPlusClicked(false);
          // }}
          ref={ref}
          open={open}
          depth={depth}
          active={active}
          disabled={disabled}
          {...other}
        >
          {/* {icon && <StyledIcon>{icon}</StyledIcon>} */}


          <ListItemText
            primary={`${titleRevised ?  t(titleRevised.replace(/ /g, '_')) :(!reportHead && dashboardTitle.toUpperCase().includes('REPORT') && path.split("/")[1]?.toUpperCase() != 'REPORTS' )? t(dashboardTitle?.replace(/report/gi,'').trim().replace(/ /g, '_')) :  t(dashboardTitle.replace(/ /g, '_'))}`}
            primaryTypographyProps={{
              noWrap: true,
              sx: {
                fontSize: 13,
                lineHeight: '16px',
                textAlign: 'center',
                ...(active && {
                  fontWeight: 'fontWeightMedium',
                }),
                ...(subItem && {
                  fontSize: 13,
                  width: 'auto',
                  textAlign: 'left',
                }),
              },
            }}
            sx={{ color:themeMode=='dark' ? child ? 'white' : active ? 'black' : 'white' : child ? 'black' : active ? 'black' : 'white' }}
          />
        </StyledItem>
      </Link>
      {dashboardTitle != null && reportHead == false && <Box sx={{ width: 18, my: 2 }}> </Box>}

      <Link
        to={(path + '/new').toLowerCase()}
        component={RouterLink}
        onClick={(e) => {
          setNewClickedRowForm(true);
          
        }}
        sx={{
          '&:hover': {
            //background: "#078dee",
          },
        }}
      >
        {child && dashboardTitle == null && !formsWithoutAdd.includes(title) && (
          <IconButton ref={btnClickRef} color={'primary'} sx={{ pointerEvents: 'none' }}>
            <Iconify sx={{ color: 'primary' }} icon={'eva:plus-fill'} width={16} />
          </IconButton>
        )}
      </Link>
    </Box>
  );

  const renderItem = () => {
    // ExternalLink
    if (isExternalLink)
      return (
        <Link href={path} target="_blank" rel="noopener" underline="none">
          {renderContent}
        </Link>
      );

    // Default
    return <>{renderContent}</>;
  };

  return renderItem();
});

NavItem.propTypes = {
  open: PropTypes.bool,
  active: PropTypes.bool,
  item: PropTypes.object,
  depth: PropTypes.number,
  isExternalLink: PropTypes.bool,
};

export default NavItem;
