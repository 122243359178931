/* eslint-disable react/prop-types */
import { FormControl, FormControlLabel, Grid, TextField, Switch, Checkbox } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormData } from 'src/hooks/useQueryHooks';
import useResponsive from 'src/hooks/useResponsive';
import { FormContext } from 'src/layouts/dashboard/DashboardLayout';

export function CheckBoxGREYERP({
  label,
  name,
  width,
  span,
  focus = false,
  onChange,
  checked = false,
  defaultVal,
}) {
  const { t } = useTranslation();

  const { popupEditId, resetFields, setResetFields } = useContext(FormContext);

  let form = window.location.pathname?.split('/')[2]?.replaceAll('-', '');
  let ID = window.location.pathname?.split('/')[3]?.replaceAll('-', '') ?? popupEditId;

  const { data: objectEdit } = useQuery({
    queryKey: [form, ID],
    select: (res) => res[0],
    enabled: Boolean(form),
  });

  const [defaultCheckVal, setdefaultCheckVal] = useState(false);
  const [focused, setFocused] = useState(focus);
  const isDesktop = useResponsive('up', 'lg');

  const registerDataChange = (e) => {
    setdefaultCheckVal(e.target.checked);
    setFocused(false);
  };

  useEffect(() => {
    if (objectEdit) {
      const objectPropertyNames = Object.keys(objectEdit);
      const valueArray = objectPropertyNames.filter(
        (item, index, object) => item?.toUpperCase() == name?.toUpperCase()
      );
      let checked = objectEdit[valueArray[0]];
      setdefaultCheckVal(checked);
    }
  }, [objectEdit]);
  return (
    <>
      {/* //xs={12} sm={6} md={3} */}
      <Grid md={span ?? 1} xs={!isDesktop && 6} item>
        <FormControlLabel
          label={label ? t(label) : t(name?.match(/[A-Z][a-z]+|[0-9]+/g)?.join(' '))}
          value={false}
          control={
            <Checkbox
              onFocus={(e) => setFocused(true)}
              name={name}
              checked={defaultCheckVal}
              onChange={onChange ? onChange : registerDataChange}
            />
          }
        />
      </Grid>
    </>
  );
}
