import PropTypes from 'prop-types';
import { memo, useContext, useEffect } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import { AppBar, Box, Toolbar } from '@mui/material';
// config
import { HEADER } from '../../../config-global';
// utils
import { bgBlur } from '../../../utils/cssStyles';
// components
import { NavSectionHorizontal } from '../../../components/nav-section';
import { FormContext } from '../DashboardLayout';


// ----------------------------------------------------------------------

function NavHorizontal({name}) {
  const theme = useTheme();
  
  const { setAltMode } = useContext(FormContext);

  useEffect(() => {
    
  },[name])
  
  return (
    <AppBar
      component="nav"
      // onKeyDown={(event) => {
      //   console.log(event.key)
      //   event.preventDefault();
      //   if (event.key === 'Alt') {
      //     setAltMode(prev=>!prev);
      //   }

      // }}
      // tabindex={'0'}
      color="transparent"
      sx={{
        boxShadow: 0,
        top: HEADER.H_DASHBOARD_DESKTOP_OFFSET,
        displayPrint:'none',
        zIndex:5
      }}
    >
      <Toolbar
        sx={{
          ...bgBlur({
            color: theme.palette.background.default,
          }),
        }}
      >
        <NavSectionHorizontal name={name}  />
      </Toolbar>

      {/* <Shadow /> */}
    </AppBar>
  );
}

export default memo(NavHorizontal);

// ----------------------------------------------------------------------

Shadow.propTypes = {
  sx: PropTypes.object,
};

function Shadow({ sx, ...other }) {
  return (
    <Box
      sx={{
        left: 0,
        right: 0,
        bottom: 0,
        height: 24,
        zIndex: -1,
        width: 1,
        m: 'auto',
        borderRadius: '50%',
        position: 'absolute',
        boxShadow: (theme) => theme.customShadows.z8,
        ...sx,
      }}
      {...other}
    />
  );
}
