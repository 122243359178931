import { Box, Checkbox, FormControlLabel, FormGroup, Grid, InputAdornment, Paper, Typography } from "@mui/material";
import { func } from "prop-types";
import { useContext } from "react";


 import { FormContext } from "src/App";
import { CardPanel } from "src/controls/cardpanel";
import { DropDownGREYERP } from "src/controls/dropdown";
import { DropDownENUM } from "src/controls/dropdownenum";
import { TextBoxGREYERP } from "src/controls/textbox";
import UploadGREY from "src/controls/uploadgrey";
import NavHorizontal from "src/layouts/dashboard/nav/NavHorizontal";
import Editor from 'src/components/editor';
import 'react-quill/dist/quill.snow.css';
import { JobTypes } from "src/data/data";
import { DropzoneGREY } from "src/controls/dropzone";
import UppyFilePicker from 'src/controls/uppyFilePicker';
import { TextEditorGrey } from "src/controls/texteditorgrey";

import { SwithGREYERP } from "src/controls/swithgrey";
export default function JobPost()
{ 
    return(
        <>

      <CardPanel heading="Job Post Details" cardItem={<>
        <Picker type='date' name="Date" placeholder="Date" required={true} focus={true}></Picker>

<TextBoxGREYERP label="Job ID" placeholder="SAL001" name="JobId" required={true} />
                        
<DropDownGREYERP items={`designations/name`} name="Designation"  />
<DropDownGREYERP items={`departments/name`} name="Department"  required={true} />
<DropDownENUM  items={JobTypes} name="Type" required={true} />
<DropDownGREYERP items={`employees/name`} name="Responsible" required={true} />


<TextBoxGREYERP label="Qualification" placeholder="Qualification" name="Qualification"   />
<TextBoxGREYERP label="Qualification in Arabic" placeholder="Qualification in Arabic" name="QualificationArabic" dir="rtl"  />                    

<TextBoxGREYERP label="Positions" placeholder="9" name="Positions" required={true} type="number"  />

<Picker type='date' name="DueDate" placeholder="Due Date" required={true}></Picker>               

</>} />

<CardPanel
        heading="Description"
        cardItem={
          <>
     
      <TextEditorGrey name="Description" span={2} multiline={true} placeholder="Job Description"/>
      <TextEditorGrey name="DescriptionArabic" placeholder="المسمى الوظيفي" dir="right"/>
           
          </>
        }
      />  


    <CardPanel heading="Other Details" cardItem={<>

<TextBoxGREYERP label="Skills" placeholder="Skills" name="Skills"  MultiLine={true} />

<TextBoxGREYERP label="Age" placeholder="29" name="Age"  type="number"  />
<TextBoxGREYERP label="Salary" placeholder="200" name="Salary"  type="number"  />

<DropDownGREYERP items={`WorkingHours/name`} name="WorkingHours" required={true} /> 
<DropDownGREYERP items={`Locations/name`} name="Location" required={true} />     
<TextBoxGREYERP  name="Sort" placeholder="Sort" type="Number" required={true} span={.5}/>
            <SwithGREYERP  name="Status" span={.5} />      

</>} />

<UppyFilePicker/>
   </>


  )

 
}
