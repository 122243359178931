// Create the main myMSALObj instance

import { msalConfig,loginRequest,tokenRequest } from "./authConfig";
import { callMSGraph,updateUI,graphConfig } from "./graph";

// configuration parameters are located at authConfig.js
const myMSALObj = new Msal.UserAgentApplication(msalConfig);

function signInOutlook() {
  myMSALObj.loginPopup(loginRequest)
    .then(loginResponse => {
   //   console.log("id_token acquired at: " + new Date().toString());
    //  console.log(loginResponse);
      
      if (myMSALObj.getAccount()) {
      //  showWelcomeMessage(myMSALObj.getAccount());
      }
    }).catch(error => {
     // console.log(error);
    });
}

function signOutOutlook() {
  myMSALObj.logout();
}

export const getTokenPopup = (request) => {
  return myMSALObj.acquireTokenSilent(request)
    .catch(error => {
      // console.log(error);
      // console.log("silent token acquisition fails. acquiring token using popup");
          
      // fallback to interaction when silent call fails
        return myMSALObj.acquireTokenPopup(request)
          .then(tokenResponse => {
            return tokenResponse;
          }).catch(error => {
           // console.log(error);
          });
    });
}

function seeProfile() {
  if (myMSALObj.getAccount()) {
    getTokenPopup(loginRequest)
      .then(response => {
        callMSGraph(graphConfig.graphMeEndpoint, response.accessToken, updateUI);
      }).catch(error => {
      //  console.log(error);
      });
  }
}

function readContact() {
  if (myMSALObj.getAccount()) {
    getTokenPopup(tokenRequest)
      .then(response => {
        callMSGraph(graphConfig.graphMailEndpoint, response.accessToken, updateUI);
      }).catch(error => {
       // console.log(error);
      });
  }
}
