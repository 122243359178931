import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { CardPanel } from 'src/controls/cardpanel';
import { CheckBoxGREYERP } from 'src/controls/checkbox';
import { DropDownENUM } from 'src/controls/dropdownenum';
import ImageContainer from 'src/controls/imagecontainer';
import { SwithGREYERP } from 'src/controls/swithgrey';
import { TextBoxGREYERP } from 'src/controls/textbox';
import UploadGREY from 'src/controls/uploadgrey';
import { UserRole, modules } from 'src/data/data';
import { HttpRequest, toTitleCase } from 'src/data/scripts';
import { useQuery } from '@tanstack/react-query';
import { useFormData } from 'src/hooks/useQueryHooks';

const rightsHead = [undefined, 'View', 'Add', 'Edit', 'Delete'];

function Users() {
  let formID = window.location.pathname?.split('/')[2]?.replaceAll('-', '');
  let ID = window.location.pathname?.split('/')[3]?.replaceAll('-', '') ?? popupEditId;
  const { setFormData: setObject } = useFormData();
  const { data: object } = useQuery({ queryKey: 'form' });
  const { data: objectEdit } = useQuery({
    queryKey: [formID, ID],
    select: (res) => res[0],
    enabled: Boolean(formID) && !!ID,
  });

  const [rights, setRights] = useState('');
  const [modulesRights, setModulesRights] = useState('');
  const [clientModules, setClientModules] = useState('');

  useEffect(() => {
    (async function () {
      let clientdetails = await HttpRequest(
        `getvalue/clients/modules/${localStorage.getItem('client')}`
      );
      setClientModules(clientdetails[0].modules);
    })();
  }, []);

  useEffect(() => {
    setObject((prev) => {
      return { ...prev, Rights: rights };
    });
  }, [rights]);

  useEffect(() => {
    if (objectEdit && Object.keys(objectEdit).length > 0) {
      setRights(objectEdit['Rights'] || '');
      let moduleRightsCopy = '';
      modules.forEach((module) => {
        let moduleChildren = module.child.filter(
          (form) =>
            !(form.toLowerCase().includes('report') || form.toLowerCase().includes('dashboard'))
        );
        [null, 'new', 'edit', 'delete'].map((action) => {
          let totalForms = module.child.filter(
            (form) =>
              !(
                form.toLowerCase().includes('report') || form.toLowerCase().includes('dashboard')
              ) &&
              objectEdit['Rights'].includes(
                action ? `${form.toLowerCase()}/${action}` : form.toLowerCase()
              )
          );
          if (totalForms?.length == moduleChildren.length) {
            moduleRightsCopy = moduleRightsCopy.concat(
              `${module.module?.toLowerCase()}${action ? `/${action}` : ''}`,
              ','
            );
          }
        });
      });
      setModulesRights(moduleRightsCopy);
    }
  }, [objectEdit]);
  return (
    <>
      <CardPanel
        heading="User Details"
        cardItem={
          <>
            <UploadGREY name="Picture" />
            <ImageContainer>
              <TextBoxGREYERP name="Name" placeholder={'Full Name'} required={true} />
              <TextBoxGREYERP
                name="Username"
                spaces={false}
                placeholder={'UserName'}
                maxLength={20}
                required={true}
              />
              <DropDownENUM name="Type" items={UserRole} required={true} />
              <CheckBoxGREYERP name="Status" span={0.5} label="Active" />
              <TextBoxGREYERP name="Email" placeholder="Email ID" type={'email'} required={true} />
              <TextBoxGREYERP
                name="Password"
                placeholder="Password"
                type={'password'}
                required={true}
              />
            </ImageContainer>
          </>
        }
      />

      <CardPanel
        heading="User Rights"
        cardItem={
          <>
            <TableContainer>
              <Table size="small" stickyHeader>
                <TableHead
                  sx={{
                    '& .MuiTableCell-head': {
                      backgroundImage: 'none',
                      color: 'black',
                    },
                  }}
                >
                  <TableRow>
                    {rightsHead.map((item) => {
                      return <TableCell sx={{ pl: '45px' }}>{item}</TableCell>;
                    })}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {modules.map((item) => {
                    if (clientModules.toLowerCase().includes(item.module.toLowerCase())) {
                      if (item.module.toLowerCase().includes('utility')) {
                        return;
                      }
                      return (
                        <>
                          <TableRow sx={{ mt: '20px !important' }}>
                            <TableCell
                              sx={{
                                fontWeight: 'bold',
                                borderTop: '.3px  solid rgb(224, 224, 224)',
                                borderBottom: '1px  solid black',
                                py: '0px !important',
                              }}
                            >
                              {item.module}
                            </TableCell>
                            {[null, 'new', 'edit', 'delete'].map((action) => {
                              return (
                                <TableCell
                                  sx={{
                                    fontWeight: 'bold',
                                    borderTop: '.3px  solid rgb(224, 224, 224)',
                                    borderBottom: '1px  solid black',
                                    py: '0px !important',
                                  }}
                                >
                                  {item.module.toLowerCase().includes('report') && action ? null : (
                                    <SwithGREYERP
                                      name={action ? `${item.module}/${action}` : item.module}
                                      showLabel={false}
                                      setItem={setModulesRights}
                                      value={
                                        (new RegExp(
                                          [
                                            `${item.module}/new`,
                                            `${item.module}/edit`,
                                            `${item.module}/delete`,
                                          ].join('|'),
                                          'gi'
                                        ).test(modulesRights) &&
                                          !action) ||
                                        modulesRights.includes(
                                          action
                                            ? `${item.module?.toLowerCase()}/${action}`
                                            : item.module?.toLowerCase()
                                        )
                                          ? true
                                          : false
                                      }
                                      enterEdit={false}
                                    />
                                  )}
                                </TableCell>
                              );
                            })}
                          </TableRow>
                          {item.child.map((form) => {
                            return (
                              <TableRow>
                                <TableCell
                                  sx={{
                                    py: '0px !important',
                                    borderBottom: '.1px  solid rgb(224, 224, 224)',
                                  }}
                                >
                                  {toTitleCase(
                                    form.includes('Dashboard')
                                      ? 'Dashboard'
                                      : form?.replaceAll('-', ' ')
                                  )}
                                </TableCell>
                                {[null, 'new', 'edit', 'delete'].map((action) => {
                                  return (
                                    <TableCell
                                      sx={{
                                        py: '0px !important',
                                        borderBottom: '.1px  solid rgb(224, 224, 224)',
                                      }}
                                    >
                                      {(form.toLowerCase().includes('report') ||
                                        form.toLowerCase().includes('dashboard')) &&
                                      action ? null : (
                                        <SwithGREYERP
                                          name={action ? `${form}/${action}` : form}
                                          showLabel={false}
                                          setItem={setRights}
                                          value={
                                            modulesRights.includes(
                                              action
                                                ? `${item.module?.toLowerCase()}/${action}`
                                                : `${item.module?.toLowerCase()},`
                                            )
                                              ? true
                                              : false
                                          }
                                        />
                                      )}
                                    </TableCell>
                                  );
                                })}
                              </TableRow>
                            );
                          })}
                        </>
                      );
                    }
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        }
      />
    </>
  );
}

export default Users;
