// import { LogLevel } from "@azure/msal-browser";
// Config object to be passed to Msal on creation.
// For a full list of msal.js configuration parameters, 
// visit https://azuread.github.io/microsoft-authentication-library-for-js/docs/msal/modules/_authenticationparameters_.html
export const msalConfig = {
  auth: {
    clientId: "9f4b1726-9e6c-473d-9462-ec2044e50f16",
    authority: "https://login.microsoftonline.com/common",
   // redirectUri: "https://apps.greyerp.com/",
    redirectUri: "http://localhost:3000/",
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  }
};  
  
// Add here the scopes to request when obtaining an access token for MS Graph API
// for more, visit https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-core/docs/scopes.md
export const loginRequest = {
  scopes: ["openid", "profile", "User.Read","Contacts.ReadWrite","Calendars.ReadWrite"]
};

// Add here scopes for access token to be used at MS Graph API endpoints.
export const tokenRequest = {
  scopes: ["Contacts.ReadWrite","Calendars.ReadWrite"]
};