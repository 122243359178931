
import { CardPanel } from 'src/controls/cardpanel';
import { TextBoxGREYERP } from 'src/controls/textbox';
import { ItemsComponent } from 'src/controls/items/ItemsComponent';

export default function LeaveList() {
  return (
    <>

      <CardPanel
        heading="Contract Details"
        cardItem={
          <>
            <TextBoxGREYERP name="Name" placeholder="Leaves List Name" required={true} focus={true} />
            <TextBoxGREYERP
              label="Comments"
              placeholder="Remarks (Optional)"
              name="Comments" multiline={true}
              MultiLine={true}
              span={2}
            />
          </>
        }
      />

      <ItemsComponent
        tablename="leaves"
        heading={'Leaves Allocation'}
        disableCalculation={true}
        disableQuantity={true}
        columnsToAppend={false}
        columnsToShow={{ LeaveType: '', Count: 0 }}
      />
    </>
  );
}
