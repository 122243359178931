import { alpha, styled } from '@mui/material/styles';
import {
  Card,
  Grid,
  Table,
  Divider,
  TableRow,
  TableBody,
  TableHead,
  TableCell,
  Typography,
  TableContainer,
  Popover,
  Tooltip,
  IconButton,
  MenuItem,
  Menu,
  Stack,
  CircularProgress,
} from '@mui/material';
import '../assets/index.css';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { useContext, useEffect, useRef, useState } from 'react';
import { FormContext } from 'src/layouts/dashboard/DashboardLayout';
import Iconify from 'src/components/iconify/Iconify';
import {
  toTitleCase,
  amountInWords,
  getBase64,
  formatdecimals,
  HttpRequest,
} from 'src/data/scripts';
import { QRCodeSVG } from 'qrcode.react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useReactToPrint } from 'react-to-print';
import cryptoJs from 'crypto-js';
import { secret } from 'src/data/data';
import { CheckBoxGREYERP } from 'src/controls/checkbox';
let decimalArray = ['Price', 'Tax', 'Total'];

export default function PrintDocument() {
  const { openPrint, setOpenPrint, recordId, setLoading } = useContext(FormContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const componentRef = useRef();

  let FormName = window.location.pathname.split('/')[2]?.replaceAll('-', '')?.toUpperCase();

  let encryptedString = cryptoJs.AES.encrypt(
    JSON.stringify(
      `${window.location.pathname.split('/')[2]}/${localStorage.getItem(
        'username'
      )}/${localStorage.getItem('client')}/${recordId}`
    ),
    secret
  ).toString();
  encryptedString = encryptedString?.replaceAll('/', '@');

  //States
  const [invoiceDetails, setInvoiceDetails] = useState({});
  const [datasection, setDataSection] = useState([]);
  const [invoiceItems, setInvoiceItems] = useState();
  const [itemsColumns, setItemsColumns] = useState();
  const [QRCodeValue, setQRCodeValue] = useState('');
  const [pageItems, setPageItems] = useState([]);
  const [copied, setCopied] = useState(false);
  const [showOptions, setShowOptions] = useState([]);
  const [currency, setCurrency] = useState();
  const [dataFetched, setDataFetched] = useState(false);

  let InvoiceList = [
    {
      name: 'Reference',
      checked: false,
    },
    {
      name: 'DueDate',
      checked: false,
    },
    {
      name: 'Vat',
      checked: false,
      label: 'VAT',
    },
    {
      name: 'VatPercentage',
      checked: false,
      label: 'VAT Percentage',
    },
    {
      name: 'VatNumber',
      checked: false,
      label: 'VATIN',
    },
    {
      name: 'Address',
      checked: false,
    },
    {
      name: 'QuotationNumber',
      checked: false,
    },

    {
      name: 'QuotationDate',
      checked: false,
    },
    {
      name: 'PurchaseOrderNumber',
      checked: false,
    },
    {
      name: 'PurchaseOrderDate',
      checked: false,
    },
    {
      name: 'DeliveryDate',
      checked: false,
    },
    {
      name: 'DeliveryNote',
      checked: false,
    },
    {
      name: 'QRCode',
      checked: false,
      label: 'QR Code',
    },
    {
      name: 'TotalQuantity',
      checked: false,
    },
    {
      name: 'TotalDiscount',
      checked: false,
    },
    {
      name: 'Discount',
      checked: false,
    },
  ];

  let QuotationList = [
    {
      name: 'Reference',
      checked: false,
    },
    {
      name: 'ValidThrough',
      checked: false,
    },
    {
      name: 'Vat',
      checked: false,
      label: 'VAT',
    },
    {
      name: 'VatPercentage',
      checked: false,
      label: 'VAT Percentage',
    },
    {
      name: 'Qty',
      checked: false,
      label: 'Quantity',
    },
    {
      name: 'TotalQuantity',
      checked: false,
    },
    {
      name: 'TotalDiscount',
      checked: false,
    },
    {
      name: 'Discount',
      checked: false,
    },
    {
      name: 'DiscountPercentage',
      checked: false,
      label: 'Discount Percentage',
    },
  ];

  let CurrencyList = [
    {
      name: 'Saudi Riyals',
      unit: 'Halala',
      decimal: 2,
    },
    {
      name: 'Omani Riyals',
      unit: 'Baisa',
      decimal: 3,
    },
    {
      name: 'US Dollars',
      unit: 'Cents',
      decimal: 2,
    },
    {
      name: 'UAE Dirhams',
      unit: 'Fils',
      decimal: 2,
    },
  ];

  let colsToHideAsPerForm = [
    {
      Form: 'QUOTATIONS',
      Columns: ['NETAMOUNT', 'VATIN', 'WORDSAMOUNT'],
    },
  ];

  let rightAligCols = ['AMOUNT', 'TAX', 'PRICE', 'DISCOUNT', 'TOTAL', 'QTY', 'QUANTITY'];

  const showOptionsList = {
    SALESINVOICES: InvoiceList,
    PURCHASEINVOICES: InvoiceList,
    QUOTATIONS: QuotationList,
    SALESORDERS: InvoiceList,
    PURCHASEORDERS: InvoiceList,
  };

  const handleVisibleEvent = async (e) => {
    let newDocSettingString = '';
    let updatedShowOptions = showOptions?.map((item) => {
      if (item.name == e.target.name) {
        item.checked = e.target.checked;
        return item;
      }
      return item;
    });

    updatedShowOptions.map((item) => {
      if (item.checked) {
        newDocSettingString += `Show${item.name},`;
      }
    });

    setShowOptions(updatedShowOptions);
    await HttpRequest(
      `deletedocumentsettings/${window.location.pathname
        .split('/')[2]
        .slice(0, -1)
        .toUpperCase()}/${localStorage.getItem('username')}`,
      'DELETE'
    );

    if (newDocSettingString) {
      var docSettingObject = {};
      docSettingObject['Columns'] = newDocSettingString;
      docSettingObject['Module'] = window.location.pathname.split('/')[2].slice(0, -1);
      docSettingObject['Username'] = localStorage.getItem('username');

      HttpRequest(
        `documentsettings/${localStorage.getItem('client')}/${localStorage.getItem('username')}`,
        'POST',
        docSettingObject
      );
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: '@page { margin:0px !important; }',
  });

  useEffect(() => {
    setTimeout(() => {
      setCopied(false);
    }, 10000);
  }, [copied]);

  async function GetData(optionsList) {
    let CompanyName = '';
    let VATIN = '';
    let columnName = `${FormName.slice(0, -1)}Layout`;
    let invoiceDetailsRes = await HttpRequest(`getRecordById/${FormName}/${recordId}`);

    let tblName = FormName.includes('PURCHASE') ? 'vendors' : 'leads';

    let DocumentToDetailsRes = await HttpRequest(
      `getItems/${
        FormName.includes('PURCHASE') ? `printvendordetails` : `printclientdetails`
      }/${columnName}/${localStorage.getItem('username')}/${localStorage.getItem('client')}/${
        invoiceDetailsRes[0]?.DocumentTo
      }/${tblName}`
    );
    setInvoiceDetails((prev) => {
      return { ...prev, ...DocumentToDetailsRes };
    });
    CurrencyList.forEach((currency) => {
      if (
        DocumentToDetailsRes?.CurrencyName?.toUpperCase().includes(
          currency.name.split(' ')[0]?.toUpperCase()
        )
      ) {
        setCurrency(currency);
      }
    });
    CompanyName = DocumentToDetailsRes.Name;
    VATIN = DocumentToDetailsRes.VATIN;

    setInvoiceDetails((prev) => {
      return { ...prev, ...invoiceDetailsRes[0] };
    });

    const invoicedate = new Date(invoiceDetailsRes[0].Date);
    const formattedDate = invoicedate.toLocaleString('en-US', {
      timeZone: 'UTC',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    });

    let QRCodeVal = getBase64(
      CompanyName,
      VATIN,
      formattedDate,
      invoiceDetailsRes[0].GrandTotal,
      invoiceDetailsRes[0].TotalTax
    );
    setQRCodeValue(QRCodeVal);

    let printDetailsRes = await HttpRequest(
      `getRecordById/${FormName.slice(0, -1)}print/${recordId}`
    );

    setInvoiceItems(printDetailsRes);
    let columns = [];
    columns = printDetailsRes?.length > 0 ? Object.keys(printDetailsRes[0]) : [];
    let itemIndex = columns.indexOf('Description');
    columns[itemIndex] = `Item الوصف`;
    let taxIndex = columns.indexOf('Tax');
    columns[taxIndex] = `VAT ضريبة`;
    let totalIndex = columns.indexOf('Total');
    columns[totalIndex] = `Amount المبلغ`;
    let qtyIndex = columns.indexOf('Quantity');
    columns[qtyIndex] = `Qty الكمية`;
    let PriceIndex = columns.indexOf('Price');
    columns[PriceIndex] = `Price معدل`;
    let RefIndex = columns.indexOf('Reference');

    columns[RefIndex] = `Reference المرجعي`;
    let DiscountIndex = columns.indexOf('Discount');
    columns[DiscountIndex] = `Discount تخفيض`;
    //delete columns.Discount;
    delete columns.TaxPercentage;
    delete columns.Unit;
    let TaxPercentageIndex = columns.indexOf('TaxPercentage');
    columns.splice(TaxPercentageIndex, 1);
    let UnitIndex = columns.indexOf('Unit');
    columns.splice(UnitIndex, 1);
    let discIndex = columns.indexOf('DiscountPercentage');
    columns.splice(discIndex, 1);

    // columns.splice(3, 1);
    // columns.splice(4, 1);
    // columns.splice(5, 1);
    //  console.log(columns);
    setItemsColumns(columns);

    let docLayoutDetailsRes = await HttpRequest(
      `getRecordById/${FormName.slice(0, -1)}documentlayout/${localStorage.getItem('client')}`
    );
    setInvoiceDetails((prev) => {
      return { ...prev, ...docLayoutDetailsRes };
    });

    let docSettingsDetailsRes = await HttpRequest(
      `getItems/DocumentSettings/${window.location.pathname
        .split('/')[2]
        .slice(0, -1)
        .toUpperCase()}/${localStorage.getItem('username')}/${localStorage.getItem('client')}`
    );

    if (docSettingsDetailsRes?.columns) {
      let showOptionsList = docSettingsDetailsRes?.columns?.split(',');
      let showOptionsState = [...optionsList];
      showOptionsList.forEach((option) => {
        showOptionsState.forEach((item) => {
          if (option.trim().includes(item.name)) {
            item.checked = true;
          }
        });
      });

      setShowOptions(showOptionsState);
    }

    let docSectionsDetailsRes = await HttpRequest(
      `getRecordById/${FormName.slice(0, -1)}sections/${recordId}`
    );

    setDataSection(docSectionsDetailsRes);
    setDataFetched(true);
  }

  useEffect(() => {
    let optionsList = [];
    Object.keys(showOptionsList).forEach((form) => {
      if (form == FormName) {
        optionsList = showOptionsList[form];
        setShowOptions(showOptionsList[form]);
      }
    });
    GetData(optionsList);
  }, []);

  useEffect(() => {
    if (dataFetched) {
      let invoiceGridDetails = [
        {
          [`${toTitleCase(
            window.location.pathname.split('/')[2]?.slice(0, -1)?.replaceAll('-', ' ')
          )}`]: `${invoiceDetails.Series} ${invoiceDetails.Number}`,
          Date: new Date(invoiceDetails.Date)
            .toLocaleDateString('en-US', {
              year: 'numeric',
              month: 'short',
              day: '2-digit',
            })
            .replace(/\//g, '-'),
          DueDate: new Date(invoiceDetails.DueDate)
            .toLocaleDateString('en-US', {
              year: 'numeric',
              month: 'short',
              day: '2-digit',
            })
            .replace(/\//g, '-'),
          DeliveryNote: invoiceDetails?.DeliveryNoteNumber ?? '',
          Reference: invoiceDetails?.Reference,
          ValidThrough: new Date(invoiceDetails?.ValidThrough)
            .toLocaleDateString('en-US', {
              year: 'numeric',
              month: 'short',
              day: '2-digit',
            })
            .replace(/\//g, '-'),
          Vatin: invoiceDetails.VATIN,
        },
        {
          [`${toTitleCase(
            window.location.pathname.split('/')[2]?.slice(0, -1)?.replaceAll('-', ' ')
          )} To`]: invoiceDetails?.Company,
          ContactPerson: invoiceDetails?.ContactPerson,
          Phone: invoiceDetails?.Phone,
          Address: invoiceDetails?.Address,
          VatNumber: invoiceDetails?.TaxNumber,
          PurchaseOrder: invoiceDetails?.PurchaseOrderNumber,
          PurchaseOrderDate:
            invoiceDetails?.PurchaseOrderDate !== '1900-01-01T00:00:00.000Z'
              ? new Date(invoiceDetails?.PurchaseOrderDate)
                  .toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: 'short',
                    day: '2-digit',
                  })
                  .replace(/\//g, '-')
              : '',
        },
      ];

      let invoiceCalculation = [
        {
          Name: 'Total Quantity',
          Value: invoiceDetails.TotalQuantity,
        },
        {
          Name: 'Sub Total الإجمالي',
          Value: formatdecimals(invoiceDetails.SubTotal),
        },
        {
          Name: 'Total Discount الخصم',
          Value: formatdecimals(invoiceDetails.TotalDiscount),
        },
        {
          Name: `Total VAT الضريبة`,
          Value: formatdecimals(invoiceDetails.TotalTax),
        },
        {
          Name: 'Net Amount المبلغ الصافي',
          Value: formatdecimals(invoiceDetails.GrandTotal),
        },
      ];

      let columnWithTitle = ['Date'];
      let columnWithHash = [
        toTitleCase(window.location.pathname.split('/')[2]?.slice(0, -1)?.replaceAll('-', ' ')),
        'DeliveryNote',
        'PurchaseOrder',
      ];
      let upperCaseCols = ['Vatin'];
      let columnWithColor = [
        toTitleCase(window.location.pathname.split('/')[2]?.slice(0, -1)?.replaceAll('-', ' ')),
      ];

      let diffColumn = [
        `${toTitleCase(
          window.location.pathname.split('/')[2]?.slice(0, -1)?.replaceAll('-', ' ')
        )} To`,
      ];
      let invoiceInfo = (
        <InvoiceFields
          invoiceGridDetails={invoiceGridDetails}
          showOptions={showOptions}
          invoiceDetails={invoiceDetails}
          columnWithColor={columnWithColor}
          columnWithTitle={columnWithTitle}
          upperCaseCols={upperCaseCols}
          columnWithHash={columnWithHash}
          colsToHideAsPerForm={colsToHideAsPerForm}
          diffColumn={diffColumn}
        />
      );
      let invoiceTable = (
        <InvoiceTable
          itemsColumns={itemsColumns}
          showOptions={showOptions}
          invoiceItems={invoiceItems}
          invoiceCalculation={invoiceCalculation}
          invoiceDetails={invoiceDetails}
          currency={currency}
          colsToHideAsPerForm={colsToHideAsPerForm}
          rightAligCols={rightAligCols}
        />
      );
      let sections = datasection;
      let sectionsOrder = invoiceDetails.SectionsOrder;
      let itemsArray = [];
      sections?.sort((a, b) => (a.Id > b.Id ? 1 : -1));
      if (sectionsOrder) {
        sectionsOrder?.split(',').map((i) => {
          if (i?.toUpperCase().includes('FIELD') || i?.toUpperCase().includes('UPDATEPANEL')) {
            itemsArray.push(invoiceInfo);
          } else if (i?.toUpperCase().includes('ITEM')) {
            itemsArray.push(invoiceTable);
          } else if (i?.toUpperCase().includes('SECTION')) {
            let sec = (
              <Stack
                style={{
                  paddingLeft: '40px',
                  paddingRight: '40px',
                  fontFamily: invoiceDetails.font,
                  fontSize: invoiceDetails.fontSize,
                }}
                // sx={{'& .ql-align-center':{ textAlign: 'center !important'}}}  not working, need to check this.
                sx={{
                  '& p,ol': { margin: '0 !important' },
                  '& .ql-align-center': { textAlign: 'center !important' },
                }}
                dangerouslySetInnerHTML={{ __html: sections[sections.length - 1]?.Content }}
              />
            );

            itemsArray.push(sec);
            sections.pop();
          }
        });
      } else {
        itemsArray.push(invoiceInfo);
        itemsArray.push(invoiceTable);
      }

      setPageItems(itemsArray);
      setLoading(false);
    }
  }, [datasection, invoiceDetails, showOptions]);

  if ('Series' in invoiceDetails && 'Number' in invoiceDetails) {
    document.title = invoiceDetails?.Series + invoiceDetails?.Number ?? '';
  }

  return (
    <>
      {pageItems?.length >= 2 && (
        <Popover
          sx={{ backgroundColor: alpha('#212B36', 0.4) }}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={openPrint}
        >
          <Card
            ref={componentRef}
            id="printablediv"
            sx={{ width: '8.3in', height: '11.7in !important' }}
          >
            <Stack justifyContent={'space-between'} sx={{ height: '100%' }}>
              <Stack alignItems={'space-between'}>
                <Grid container alignItems={'center'}>
                  <Grid sx={{ displayPrint: 'none', flex: 1, mt: 3, px: 5 }} item>
                    <IconButton
                      aria-label="more"
                      id="long-button"
                      aria-controls={open ? 'long-menu' : undefined}
                      aria-expanded={open ? 'true' : undefined}
                      aria-haspopup="true"
                      onClick={handleClick}
                    >
                      <MoreVertIcon />
                    </IconButton>
                    <Menu
                      id="long-menu"
                      MenuListProps={{
                        'aria-labelledby': 'long-button',
                      }}
                      anchorEl={anchorEl}
                      open={open}
                      sx={{ '& .MuiMenuItem-root': { p: '0px 15px' } }}
                      onClose={handleClose}
                      PaperProps={{
                        style: {
                          maxHeight: 100 * 4.5,
                          width: '25ch',
                          padding: '8px',
                        },
                      }}
                    >
                      {showOptions?.map((item) => {
                        return (
                          <MenuItem>
                            <CheckBoxGREYERP
                              name={item.name}
                              span={2}
                              checked={item.checked}
                              label={item?.label}
                              onChange={handleVisibleEvent}
                            />
                          </MenuItem>
                        );
                      })}
                    </Menu>
                  </Grid>

                  <Grid item sx={{ ml: 'auto', displayPrint: 'none', mt: 3, px: 5 }}>
                    <Tooltip title={copied ? 'Copied' : 'Share via Link'}>
                      <IconButton
                        sx={{
                          '& svg': {
                            color: '#0000EE !important',
                          },
                        }}
                        onClick={(e) => {
                          navigator.clipboard.writeText(
                            `https://apps.greyerp.com/link/${encryptedString.replaceAll('#', '')}`
                          );
                          setCopied(true);
                        }}
                      >
                        <Iconify icon="material-symbols:link-rounded" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Share on Whatsapp">
                      <a
                        target="_blank"
                        href={`https://wa.me/${invoiceDetails[0]?.Phone}?text=https://apps.greyerp.com/link/${encryptedString}`}
                      >
                        <IconButton
                          sx={{
                            '& .MuiSvgIcon-root': {
                              width: '22px !important',
                              color: '#25d366 !important',
                            },
                          }}
                        >
                          <WhatsAppIcon color="#25d366 !important" />
                        </IconButton>
                      </a>
                    </Tooltip>
                    <Tooltip title="Print">
                      <IconButton
                        sx={{
                          '& svg': {
                            color: '#1895ef !important',
                          },
                        }}
                        onClick={handlePrint}
                      >
                        <Iconify icon="eva:printer-fill" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Close">
                      <IconButton sx={{ ml: 3, mr: 0.6 }} onClick={(e) => setOpenPrint(false)}>
                        <Iconify icon="material-symbols:close" />
                      </IconButton>
                    </Tooltip>
                  </Grid>

                  <Grid
                    container
                    sx={{
                      '& img': { width: '100%' },
                      '& .ql-editor': { p: '10px 10px !important' },
                    }}
                  >
                    <div
                      className="ql-editor"
                      style={{ width: '100%' }}
                      dangerouslySetInnerHTML={{ __html: invoiceDetails?.Header }}
                    />
                  </Grid>
                  <Grid
                    container
                    direction={'column'}
                    justifyContent={'center'}
                    alignItems={'center'}
                  >
                    <Typography
                      variant="h4"
                      sx={{ color: invoiceDetails.color, fontFamily: invoiceDetails.font, pb: 3 }}
                    >
                      {invoiceDetails?.Subject ? (
                        invoiceDetails.Subject
                      ) : invoiceDetails?.Heading && invoiceDetails?.Heading == 'DOCUMENT NAME' ? (
                        FormName?.slice(0, 1)
                      ) : (
                        <div
                          className="ql-editor"
                          style={{ width: '100%' }}
                          dangerouslySetInnerHTML={{ __html: invoiceDetails?.Heading }}
                        />
                      )}
                    </Typography>

                    {/* <Typography variant="h4" sx={{ color: invoiceDetails.color, fontFamily: invoiceDetails.font }}>
                <div
                  className="ql-editor"
                  style={{ width: '100%' }}
                  dangerouslySetInnerHTML={{ __html: invoiceDetails?.Heading?.split("p>")[1]?.toUpperCase() }}
                />
              </Typography> */}
                  </Grid>

                  {showOptions?.filter((item) => item.name == 'QRCode')[0]?.checked && (
                    <Grid container sx={{ mt: 0, px: 5 }} justifyContent={'flex-start'}>
                      <Grid
                        item
                        xs={6}
                        sm={3}
                        sx={{
                          '& svg': {
                            height: '70px',
                            width: '120px',
                          },
                        }}
                      >
                        <div style={{ background: 'white', mb: 4 }}>
                          <QRCodeSVG value={QRCodeValue} />
                        </div>
                      </Grid>
                    </Grid>
                  )}
                </Grid>

                {pageItems.map((item) => item)}
              </Stack>
              {invoiceDetails?.Bottom != '' && (
                <Stack
                  sx={{
                    '& img': { width: '100%' },
                    p: '0px !important',
                    paddingLeft: '25px !important',
                    paddingRight: '25px !important',
                    fontSize: invoiceDetails.fontSize,
                  }}
                >
                  <div
                    className="ql-editor"
                    style={{ width: '100%', alignSelf: 'flex-end' }}
                    dangerouslySetInnerHTML={{ __html: invoiceDetails?.Bottom }}
                  />
                </Stack>
              )}

              <Stack
                sx={{
                  '& img': { width: '100%' },
                  '& .ql-editor': { p: '0px 5px !important' },
                  font: invoiceDetails.font,
                  fontSize: invoiceDetails.fontSize,
                }}
              >
                <Divider />

                <div
                  className="ql-editor"
                  style={{ width: '100%', alignSelf: 'flex-end', padding: '0px !important' }}
                  dangerouslySetInnerHTML={{ __html: invoiceDetails?.Footer }}
                />
              </Stack>
            </Stack>
          </Card>
        </Popover>
      )}
    </>
  );
}

function InvoiceFields({
  invoiceGridDetails = [],
  showOptions,
  invoiceDetails,
  columnWithColor,
  columnWithTitle,
  upperCaseCols,
  columnWithHash,
  colsToHideAsPerForm,
  diffColumn,
}) {
  return (
    <Grid container sx={{ px: 5 }}>
      {invoiceGridDetails?.map((item) => {
        return (
          <Grid item xs={6} sx={{ mb: 5 }}>
            {Object.keys(item)?.map((column) => {
              if (
                colsToHideAsPerForm
                  .filter(
                    (item) =>
                      item.Form ==
                      window.location.pathname.split('/')[2]?.replaceAll('-', ' ').toUpperCase()
                  )[0]
                  ?.Columns?.includes(column.toUpperCase())
              ) {
                return;
              }
              let colExist = showOptions?.filter(
                (item) => item.name.toUpperCase() == column.toUpperCase()
              )[0];

              let col = column;
              if (col == 'Vatin') {
                col = 'VATIN';
              } else if (col == 'VatNumber') {
                col = 'TaxNumber';
              }
              return (
                <>
                  {' '}
                  {(invoiceDetails[col] ||
                    columnWithColor.includes(column) ||
                    diffColumn.includes(column)) &&
                    (colExist ? colExist?.checked : true) && (
                      <Typography variant="body1">
                        <Typography
                          variant="span"
                          sx={{
                            fontWeight: 'bold',
                            fontFamily: invoiceDetails.font,
                            fontSize: invoiceDetails.fontSize,
                            color: columnWithColor.includes(column) && invoiceDetails.color,
                          }}
                        >
                          {columnWithTitle.includes(column) &&
                            `${toTitleCase(
                              window.location.pathname
                                .split('/')[2]
                                ?.slice(0, -1)
                                ?.replaceAll('-', ' ')
                            )} `}
                          {upperCaseCols.includes(column)
                            ? column?.toUpperCase()
                            : column == 'VatNumber'
                              ? 'VATIN'
                              : column?.match(/[A-Z][a-z]+|[0-9]+/g)?.join(' ')}
                          {columnWithHash.includes(column) && ' # '}:
                        </Typography>
                        <Typography
                          variant="span"
                          sx={{
                            fontWeight: columnWithColor.includes(column) ? 'bold' : '400',
                            fontFamily: invoiceDetails.font,
                            fontSize: invoiceDetails.fontSize,
                            color: columnWithColor.includes(column) && invoiceDetails.color,
                          }}
                        >
                          {` ${item[column]}`}
                        </Typography>
                      </Typography>
                    )}
                </>
              );
            })}
          </Grid>
        );
      })}
    </Grid>
  );
}

function InvoiceTable({
  itemsColumns,
  showOptions,
  invoiceItems,
  invoiceCalculation,
  invoiceDetails,
  currency,
  colsToHideAsPerForm,
  rightAligCols,
}) {
  return (
    <TableContainer sx={{ overflow: 'unset', alignSelf: 'flex-end', px: 5 }}>
      <Stack sx={{ justifyContent: 'space-between', height: '100%' }}>
        <Table sx={{ width: '100%' }}>
          <TableHead
            sx={{
              borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
              '& th': {
                backgroundColor: `${invoiceDetails.color} !important`,
                color: 'white',
                fontFamily: invoiceDetails.font,
                fontSize: invoiceDetails.fontSize,
                WebkitPrintColorAdjust: 'exact !important',
              },
              backgroundColor: `${invoiceDetails.color} !important`,
              WebkitPrintColorAdjust: 'exact !important',
            }}
          >
            <TableRow>
              {itemsColumns?.map((row, index) => {
                let colExist = showOptions?.filter((option) =>
                  row.toUpperCase().includes(option.name.toUpperCase())
                )[0];
                return (
                  <>
                    {(colExist ? colExist?.checked : true) && (
                      <TableCell
                        sx={{
                          backgroundColor: `${invoiceDetails.color} !important`,
                          fontFamily: invoiceDetails.font,
                          fontSize: invoiceDetails.fontSize,
                          p: '5px 8px !important',
                          textAlign: rightAligCols.includes(row.toUpperCase().split(' ')[0])
                            ? 'right !important'
                            : 'left !important',
                        }}
                      >
                        <Typography variant="subtitle2">{row.split(' ')[0]}</Typography>
                        <Typography variant="subtitle2">{row.split(' ')[1]}</Typography>
                      </TableCell>
                    )}
                  </>
                );
              })}
            </TableRow>
          </TableHead>

          <TableBody sx={{ mb: '40px !important' }}>
            {invoiceItems &&
              invoiceItems.map((row, index) => {
                let rowArray = [];
                let taxarray = [];
                let colNameArray = [];
                let noActionCols = [
                  'ID',
                  'UNIT',
                  'ITEM',
                  // 'DISCOUNT',
                  'DISCOUNTPERCENTAGE',
                  'TAXPERCENTAGE',
                ];

                for (const cell in row) {
                  if (noActionCols.includes(cell.toUpperCase())) {
                  } else if (cell == 'Quantity') {
                    let colExistQuantity = showOptions?.filter(
                      (option) => option.label == 'Quantity'
                    );
                    colNameArray.push(cell);
                    if (colExistQuantity.length > 0) {
                      if (colExistQuantity[0]?.checked) {
                        rowArray.push(`${row[cell]} ${row['Unit']}`);
                        taxarray.push(``);
                      }
                    } else {
                      rowArray.push(row[cell]);
                      taxarray.push(`${row['Unit']}`);
                    }
                  } else if (cell == 'Tax') {
                    let colExistTax = showOptions?.filter((option) => option.name == 'Vat')[0];
                    let colExistPercent = showOptions?.filter(
                      (option) => option.name == 'VatPercentage'
                    )[0];
                    colNameArray.push(cell);
                    if (colExistTax?.checked) {
                      rowArray.push(formatdecimals(row[cell]));
                      if (colExistPercent?.checked) {
                        taxarray.push(`${formatdecimals(row['TaxPercentage'])}%`);
                      }
                    }
                  } else if (cell == 'Discount') {
                    let colExistDiscount = showOptions?.filter(
                      (option) => option.name == 'Discount'
                    )[0];
                    colNameArray.push(cell);
                    let colExistDiscountPercentage = showOptions?.filter(
                      (option) => option.name == 'DiscountPercentage'
                    )[0];
                    if (colExistDiscount?.checked) {
                      rowArray.push(formatdecimals(row[cell]));
                      if (colExistDiscountPercentage?.checked) {
                        taxarray.push(`${formatdecimals(row['DiscountPercentage'])}%`);
                      } else {
                        taxarray.push('');
                      }
                    }
                  } else if (decimalArray.includes(cell)) {
                    taxarray.push('');
                    rowArray.push(formatdecimals(row[cell]));
                    colNameArray.push(cell);
                  } else {
                    taxarray.push('');
                    rowArray.push(row[cell]);
                    colNameArray.push(cell);
                  }
                }

                return (
                  <TableRow
                    key={index}
                    sx={{
                      borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
                    }}
                  >
                    {rowArray.map((cell, index) => (
                      <TableCell
                        sx={{
                          fontFamily: invoiceDetails.font,
                          fontSize: invoiceDetails.fontSize,
                          border: '1px solid rgb(224, 224, 224)',
                          p: '5px 8px !important',
                          textAlign: rightAligCols.includes(colNameArray[index]?.toUpperCase())
                            ? 'right !important'
                            : 'left !important',
                        }}
                      >
                        {cell} {taxarray[index]}
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>

        <Grid container>
          {!colsToHideAsPerForm
            .filter(
              (item) =>
                item.Form ==
                window.location.pathname.split('/')[2]?.replaceAll('-', ' ').toUpperCase()
            )[0]
            ?.Columns?.includes('WORDSAMOUNT') ? (
            <Grid item flexBasis={'60%'} alignSelf={'flex-end'}>
              <Stack direction={'row'}>
                <Typography
                  variant="body2"
                  sx={{
                    fontWeight: '600',
                    fontFamily: invoiceDetails.font,
                    fontSize: invoiceDetails.fontSize,
                    paddingRight: '50px',
                    paddingLeft: '5px',
                  }}
                >
                  {toTitleCase(amountInWords(invoiceDetails?.GrandTotal?.toString().split('.')[0]))}
                  {` ${toTitleCase(currency?.name)} `}
                  {invoiceDetails?.GrandTotal?.toString().split('.')[1]
                    ? `${
                        currency?.decimal
                          ? toTitleCase(
                              amountInWords(
                                invoiceDetails?.GrandTotal?.toString()
                                  .split('.')[1]
                                  ?.substring(0, currency?.decimal ?? 0)
                              )
                            )
                          : toTitleCase(
                              amountInWords(invoiceDetails?.GrandTotal?.toString().split('.')[1])
                            )
                      } ${toTitleCase(currency?.unit)} `
                    : ''}
                  Only
                </Typography>
              </Stack>
            </Grid>
          ) : (
            <Grid item flexBasis={'60%'} alignSelf={'flex-end'}></Grid>
          )}
          <Grid item flexBasis={'40%'} sx={{ paddingRight: '5px' }}>
            <Grid container sx={{ mt: 1 }} direction={'column'}>
              {invoiceCalculation.map((item, index) => {
                let colExist = showOptions?.filter(
                  (option) =>
                    item?.Name?.replaceAll(' ', '')
                      ?.toUpperCase()
                      ?.includes(`${option.name.toUpperCase()}`) ||
                    item?.Name?.replaceAll(' ', '')?.toUpperCase() == option.name.toUpperCase()
                )[0];

                let currencyName =
                  item.Name != 'Total Quantity' && invoiceDetails?.CurrencyShortname;
                return (
                  <>
                    {(colExist ? colExist?.checked : true) && (
                      <Grid item>
                        <Stack direction={'row'} justifyContent={'space-between'}>
                          <Typography
                            variant="body2"
                            sx={{
                              fontWeight: 'bold',
                              width: '150px',
                              fontFamily: invoiceDetails.font,
                              fontSize: invoiceDetails.fontSize,
                            }}
                          >
                            {item.Name}
                          </Typography>

                          <Typography
                            variant="body2"
                            sx={{
                              fontWeight: '600',
                              width: '20px',
                              fontFamily: invoiceDetails.font,
                              fontSize: invoiceDetails.fontSize,
                            }}
                          >
                            {currencyName ?? ''}
                          </Typography>

                          <Typography
                            variant="body2"
                            sx={{
                              fontWeight: '600',
                              width: '80px',
                              fontFamily: invoiceDetails.font,
                              fontSize: invoiceDetails.fontSize,
                              textAlign: 'right',
                              paddingRight: '2px',
                            }}
                          >
                            {item.Value}
                          </Typography>
                        </Stack>
                      </Grid>
                    )}
                  </>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      </Stack>
    </TableContainer>
  );
}
