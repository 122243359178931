import { useState, useRef, useContext, useEffect } from 'react';
import { Box, Button, Grid } from '@mui/material';
import KanbanDetailsPrioritizes from 'src/sections/@dashboard/kanban/details/KanbanDetailsPrioritizes';
import { TextBoxGREYERP } from 'src/controls/textbox';
import Picker from 'src/controls/picker';
import { DropDownGREYERP } from 'src/controls/dropdown';
import { styled, alpha } from '@mui/material/styles';
import { FormContext } from 'src/layouts/dashboard/DashboardLayout';
import { DropDownENUM } from 'src/controls/dropdownenum';
import { columnInfo } from './KanbanPage';
import { TextEditorGrey } from 'src/controls/texteditorgrey';
import { Popover, Typography } from '@mui/material';
import PopUpPanel from 'src/controls/popuppanel';

export default function ProjectForm({ setCardsState, setRefresh, cardsState }) {
  const {
    objectEdit,
    setObject,
    object,
    openTaskPopup,
    setOpenTaskPopup,
    popupEditId,
    projectTasks,
    openDrawer,
    setProjectTasks,
  } = useContext(FormContext);
  const [prioritize, setPrioritize] = useState('low');
  const [relatedTo, setRelatedTo] = useState();
  const [assignee, setAssignee] = useState();
  const [status, setStatus] = useState();
  const [editTask, setEditTask] = useState({});
  const [openDropDown, setopenDropDown] = useState(false);

  const formRefPopup = useRef();

  const handleOnChange = (event, selectedValue, name, date) => {
    let task = [];
    let projectTasksCopy = { ...projectTasks };
    let taskArray;
    if (event) {
      task = Object.values(projectTasksCopy).map((task) => {
        if (task.ID == popupEditId.id) {
          task[event.target.name] = event.target.value;
          return task;
        }
        return false;
      });

      taskArray = [...task];
      taskArray = taskArray.filter((item) => item != false)[0];
      if (event.target.name == 'Name' && taskArray.length > 0) {
        let cardState = { ...cardsState };
        let newTaskName = {};
        Object.values(cardState).forEach((item) => {
          if (item.id == taskArray.ID) {
            item.name = event.target.value;
            newTaskName = item;
          }
        });
        setCardsState((prev) => {
          return { ...prev, [taskArray.ID]: newTaskName };
        });
        setRefresh(true);
      }
    } else if (date) {
      task = Object.values(projectTasksCopy).map((task) => {
        if (task.ID == popupEditId.id) {
          task[name] = new Date(date.$d).toISOString();
          return task;
        }
        return false;
      });

      taskArray = [...task];
      taskArray = taskArray.filter((item) => item != false)[0];
      let cardState = { ...cardsState };
      let newTaskName = {};
      Object.values(cardState).forEach((item) => {
        if (item.id == taskArray.ID) {
          item.due.unshift(new Date(date.$d).toISOString());
          newTaskName = item;
        }
      });
      setCardsState((prev) => {
        return { ...prev, [taskArray.ID]: newTaskName };
      });
    } else if (selectedValue) {
      task = Object.values(projectTasksCopy).map((task) => {
        if (task.ID == popupEditId.id) {
          if (name == 'Assignee') {
            task[name] = selectedValue?.Name;
          } else {
            task[name] = selectedValue?.ID;
          }
          return task;
        }
        return false;
      });
      setopenDropDown(false);
    }

    if (name == 'RelatedTo') {
      setRelatedTo(selectedValue?.ID);
    } else if (name == 'Status') {
      setStatus(selectedValue?.ID);
    } else if (name == 'Priority') {
      setPrioritize(event.target.value);
      let cardState = { ...cardsState };
      let newTaskName = {};
      Object.values(cardState).forEach((item) => {
        if (item.id == taskArray.ID) {
          item.priority = event.target.value;
          newTaskName = item;
        }
      });
      setCardsState((prev) => {
        return { ...prev, [taskArray.ID]: newTaskName };
      });
    } else if (name == 'Assignee') {
      setAssignee(selectedValue?.ID);
    }

    task = task.filter((item) => item != false);
    if (task.length > 0) {
      setProjectTasks((prev) => {
        return { ...prev, [task[0].ID]: task[0] };
      });
    }
  };

  useEffect(() => {
    let projectTasksCopy = { ...projectTasks };
    if (popupEditId) {
      let taskExist = Object.keys(projectTasksCopy).includes(`${popupEditId.id}`);

      if (!taskExist) {
        let task = {
          ID: popupEditId.id,
          Name: popupEditId.name,
          Priority: 'low',
          RelatedTo: 0,
          Status: columnInfo[popupEditId.Status].Name,
          Assignee: 0,
        };
        let taskId = popupEditId.id;
        setProjectTasks((prev) => {
          return { ...prev, [taskId]: task };
        });
      }
    }
  }, [popupEditId]);

  useEffect(() => {
    let task;
    let projectTasksCopy = { ...projectTasks };
    task = Object.values(projectTasksCopy).map((task) => {
      if (task.ID == popupEditId.id) {
        return task;
      }
      return false;
    });
    task = task.filter((item) => item != false);
    if (task.length > 0 && task[0]) {
      setRelatedTo(task[0].RelatedTo);
      setPrioritize(task[0].Priority);
      setAssignee(task[0].Assignee);
      setStatus(task[0].Status);
      setEditTask(task[0]);
    }
  }, [projectTasks]);

  const toggleDrawer = (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setOpenTaskPopup(false);
  };

  return (
    <>
      <Popover
        sx={{
          backgroundColor: alpha('#212B36', 0.4),
          '& .MuiPaper-root': {
            width: '35%',
          },
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={openTaskPopup}
        onClose={toggleDrawer}
        // sx={{
        //   '.MuiPaper-root': { height: 'auto', top: '70px', right: '10px', borderRadius: '8px' },
        // }}
      >
        <Box sx={{}} component={'form'} ref={formRefPopup}>
          <Grid
            container
            direction="column"
            sx={{
              '& .MuiFormControl-root': {
                display: 'flex !important',
              },
              '& .MuiTextField-root': {
                mb: '0px !important',
              },
            }}
          >
            <Box sx={{ pt: 4, pl: 4, pr: 2 }}>
              <Grid container justifyContent={'space-between'}>
                <Grid item>
                  <Typography variant="h6" sx={{}} paragraph>
                    Task {popupEditId?.name}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <Grid container direction={'row'} sx={{ pl: 3, pr: 3, mb: 5 }} spacing={2} rowSpacing={1} columns={{ xs: 2, sm: 2, md: 2, lg: 2 }} >
              <TextBoxGREYERP name="Name" span={2} required={true} placeholder="Task Name" defaultValue={editTask?.Name} onChange={handleOnChange} focus={true} enterEdit={false} />
              <TextBoxGREYERP name="Description" span={2} multiline={true} placeholder="Task Description" onChange={handleOnChange} defaultValue={editTask?.Description} enterEdit={false} />
              <DropDownGREYERP span={2} name="RelatedTo" items={`relatedto/name`} postBackSelectedValue={relatedTo} onChange={(e, selectedValue) => handleOnChange(null, selectedValue, 'RelatedTo')} enterEdit={false} />
              <Picker type='dateTime' placeholder="Due Date" name="DueDateTime" postBackDate={editTask?.DueDateTime} onChange={(e) => handleOnChange(null, null, 'DueDateTime', e)} />
              <DropDownENUM name="Status" items={columnInfo} disabled={true} required={true} postBackSelectedValue={columnInfo.filter((item) => item.Name == status)[0]?.ID} selectedItemIndex={popupEditId?.Column} enterEdit={false} onChange={(e, selectedValue) => handleOnChange(null, selectedValue, 'Status')} />
              <DropDownGREYERP span={2} name="Assignee" items={`clientusers/name`} postBackSelectedValue={assignee} enterEdit={false} open={openDropDown} firstItem={'0,None'} onChange={(e, selectedValue) => handleOnChange(null, selectedValue, 'Assignee')} />
              <KanbanDetailsPrioritizes Tooltip="Priority" prioritize={prioritize} span={2} onChangePrioritize={(e) => handleOnChange(e, null, 'Priority')} />
            </Grid>
          </Grid>
        </Box>
      </Popover>
    </>
  );
}

export function TasksForm() {
  const { objectEdit, setObject, object, popupEditId } = useContext(FormContext);
  const [prioritize, setPrioritize] = useState(!popupEditId && 'low');
  const [status, setStatus] = useState();

  const handleChangePrioritize = (event) => {
    setObject({ ...object, ['priority']: event.target.value });
    setPrioritize(event.target.value);
  };

  useEffect(() => {
    if (!status && !popupEditId) {
      setObject((prev) => {
        return { ...prev, ['Status']: 'Todo',['priority']:'low' };
      });
      setStatus(0);
    }
  }, []);

  useEffect(() => {
    if (objectEdit && Object.keys(objectEdit)?.length > 0) {
      const valueEdit = objectEdit['Priority'];
      const valueEditStatus = objectEdit['Status'];

      valueEdit && setPrioritize(valueEdit.toLowerCase());
      valueEditStatus &&
        setStatus(
          columnInfo
            .map((i) => {
              if (i.Name.toUpperCase() == valueEditStatus.toUpperCase()) {
                return i;
              }
              return false;
            })
            .filter((item) => item != false)[0].ID
        );
    }
  }, [objectEdit]);

  return (
    <>
      <PopUpPanel
        heading={'Add'}
        child={
          <>
            <TextBoxGREYERP name="Name" span={2} required={true} placeholder="Task Name" focus={true} />
            <TextEditorGrey name="Description" span={2} multiline={true} placeholder="Task Description" mini={true} />
            <DropDownGREYERP span={2} name="RelatedTo" items={`relatedto/name`} />
            <Picker type='dateTime' label="Due Date" name="DueDateTime" />
            <DropDownENUM name="Status" items={columnInfo} required={true} postBackSelectedValue={status} firstItemSelect={false} enterEdit={false} onChange={(e, selectedValue) => { setStatus(selectedValue?.ID); setObject({ ...object, ['Status']: selectedValue?.Name }); }} />
            <DropDownGREYERP items={'tasktypes/name'} name="Type" />
            <DropDownGREYERP span={2} name="Project" items={`projects/name`}  />
            <DropDownGREYERP span={2} name="Assignee" items={`clientusers/name`} />
            <KanbanDetailsPrioritizes Tooltip="Priority" span={2} prioritize={prioritize} onChangePrioritize={handleChangePrioritize} />
          </>
        }
      />
    </>
  );
}
