import { useCallback, useContext, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { AvatarPreview, UploadAvatar } from 'src/components/upload';
import { FormContext } from 'src/layouts/dashboard/DashboardLayout';
import { Box, Card, Grid, Typography } from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import Iconify from 'src/components/iconify/Iconify';
import useResponsive from 'src/hooks/useResponsive';
import { useFormData } from 'src/hooks/useQueryHooks';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

//
const StyledDropZone = styled('div')(({ theme, width, height, borderRadius }) => ({
  width: width ? width : 144,
  height: height ? height : 144,
  margin: 'auto',
  display: 'flex',
  cursor: 'pointer',
  overflow: 'hidden',
  borderRadius: borderRadius ? borderRadius : '50%',
  alignItems: 'center',
  position: 'relative',
  justifyContent: 'center',
  border: `1px dashed ${alpha(theme.palette.grey[500], 0.32)}`,
}));

const StyledPlaceholder = styled('div')(({ theme, borderRadius }) => ({
  zIndex: 7,
  display: 'flex',
  borderRadius: borderRadius ? borderRadius : '50%',
  position: 'absolute',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  width: `calc(100% - 16px)`,
  height: `calc(100% - 16px)`,
  color: theme.palette.text.disabled,
  backgroundColor: theme.palette.background.neutral,
  transition: theme.transitions.create('opacity', {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
}));

export default function UploadGREY({ span, width, height, borderRadius, name = 'Image' }) {
  const { popupEditId, image, setImage } = useContext(FormContext);
  const { t } = useTranslation();

  let form = window.location.pathname?.split('/')[2]?.replaceAll('-', '');
  let ID = window.location.pathname?.split('/')[3]?.replaceAll('-', '') ?? popupEditId;

  const { setFormData: setObject, formData: object } = useFormData();

  const { data: objectEdit } = useQuery({
    queryKey: [form, ID],
    select: (res) => res[0],
    enabled: Boolean(form),
  });

  const [file, setFile] = useState('');
  const [editFileUrl, setEditFileUrl] = useState('');
  const isDesktop = useResponsive('up', 'lg');

  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    const newFile = Object.assign(file, {
      preview: URL.createObjectURL(file),
    });
    if (file) {
      setFile(file);
      file &&
        setImage((prev) => {
          return { ...prev?.file, [name]: file };
        });
      console.log(file);
      setObject({ [name]: file.path });
    }
  }, []);

  useEffect(() => {
    if (objectEdit && objectEdit[name]) {
      objectEdit[name] && setEditFileUrl(objectEdit[name]);
    }
  }, [objectEdit]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const hasFile = !!file;

  return (
    <>
      <Grid item style={{ padding: '0px', zIndex: 1 }} xs={!isDesktop && 12} md={span ?? 1}>
        <Box>
          <StyledDropZone
            {...getRootProps()}
            sx={{
              ...(isDragActive && {
                opacity: 0.72,
              }),
              ...(hasFile && {
                '&:hover': {
                  '& .placeholder': {
                    opacity: 1,
                  },
                },
              }),
            }}
            width={width}
            height={height}
            borderRadius={borderRadius}
          >
            <input filename={file.name} name={name} {...getInputProps()} />

            {(hasFile || editFileUrl) && <AvatarPreview file={file} editFileUrl={editFileUrl} />}

            <StyledPlaceholder
              className="placeholder"
              sx={{
                '&:hover': {
                  opacity: 0.72,
                },
                ...(hasFile && {
                  zIndex: 9,
                  opacity: 0,
                  color: 'common.white',
                  bgcolor: (theme) => alpha(theme.palette.grey[900], 0.64),
                }),
              }}
              borderRadius={borderRadius}
            >
              <Iconify icon="ic:round-add-a-photo" width={24} sx={{ mb: 1 }} />

              <Typography variant="caption">{file ? 'Update photo' : 'Upload photo'}</Typography>
            </StyledPlaceholder>
          </StyledDropZone>

          <Typography
            variant="caption"
            sx={{
              mt: 2,
              mx: 'auto',
              display: 'block',
              textAlign: 'center',
              color: 'text.secondary',
            }}
          >
            Upload .jpeg, .jpg, or .png
          </Typography>
        </Box>
      </Grid>
    </>
  );
}
