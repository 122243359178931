import React, { useContext, useEffect, useState } from 'react';
import Uppy from '@uppy/core';
import Webcam from '@uppy/webcam';
import { Dashboard } from '@uppy/react';

// Don't forget the CSS: core and the UI components + plugins you are using.
import '@uppy/core/dist/style.min.css';
import '@uppy/dashboard/dist/style.min.css';
import '@uppy/webcam/dist/style.min.css';
import { Avatar, Badge, Button, Grid, ImageList, ImageListItem, Paper, Stack,Typography } from '@mui/material';
import Dropbox from '@uppy/dropbox';
import Facebook from '@uppy/facebook';
import GoogleDrive from '@uppy/google-drive';
import OneDrive from '@uppy/onedrive';
import Unsplash from '@uppy/unsplash';
import Box from '@uppy/box';
import Zoom from '@uppy/zoom';
import Instagram from '@uppy/instagram';
import Url from '@uppy/url';
import Transloadit from '@uppy/transloadit';
import { COMPANION_URL, COMPANION_ALLOWED_HOSTS } from '@uppy/transloadit';
import { uppyImage } from 'src/layouts/dashboard/DashboardLayout';
import { CardPanel } from './cardpanel';
import ThumbnailGenerator from '@uppy/thumbnail-generator';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import pdfIcon from '../assets/icons/pdfIcon.png'; 
import fileIcon from '../assets/icons/fileIcon.png'; 

let uppy = undefined;
function UppyInstanceCreation() {
  uppy = new Uppy()
    .use(Webcam)
    .use(Dropbox, {
      companionUrl: COMPANION_URL,
      companionAllowedHosts: COMPANION_ALLOWED_HOSTS,
    })
    .use(Facebook, {
      companionUrl: COMPANION_URL,
      companionAllowedHosts: COMPANION_ALLOWED_HOSTS,
    })
    .use(GoogleDrive, {
      companionUrl: COMPANION_URL,
      companionAllowedHosts: COMPANION_ALLOWED_HOSTS,
    })
    .use(OneDrive, {
      companionUrl: COMPANION_URL,
      companionAllowedHosts: COMPANION_ALLOWED_HOSTS,
    })
    .use(Unsplash, {
      companionUrl: COMPANION_URL,
      companionAllowedHosts: COMPANION_ALLOWED_HOSTS,
    })
    .use(Box, {
      companionUrl: COMPANION_URL,
      companionAllowedHosts: COMPANION_ALLOWED_HOSTS,
    })
    .use(Zoom, {
      companionUrl: COMPANION_URL,
      companionAllowedHosts: COMPANION_ALLOWED_HOSTS,
    })
    .use(Url, {
      companionUrl: COMPANION_URL,
      companionAllowedHosts: COMPANION_ALLOWED_HOSTS,
    })
    .use(Instagram, {
      companionUrl: COMPANION_URL,
      companionAllowedHosts: COMPANION_ALLOWED_HOSTS,
    })
    .use(Transloadit, {
      assemblyOptions: {
        params: {
          auth: { key: 'e7e328f346bb49fa80fe93475918685f' },
          template_id: '05c97697f96b4bbf8dcf48baa23be461',
        },
      },
    })
    .on('file-added', async (file) => {
      if (file.source == 'react:Dashboard') {
        const fileNew = file?.data;
        uppyImage.push(fileNew);
        //setImage(prev => {return{...prev,['uppyFile']: [...prev?.uppyFile,fileNew] }})
      } else {
        const response = await fetch(file?.preview);
        const blob = await response.blob();
        const fileNew = new File([blob], file?.name, { type: blob.type });
        uppyImage.push(fileNew);
        //setImage(prev => {return{...prev,['uppyFile']: [...prev?.uppyFile,fileNew] }})
      }
    });
}

export default function UppyFilePicker() {
  const [existingImages, setExistingImages] = useState([]);
  const [reset, setReset] = useState(false);
  useEffect(() => {
    if (!isNaN(window.location.pathname.split('/')[3])) {
      fetch(
        `https://uploads.greyerp.com/home/GetImages?table=${window.location.pathname
          .split('/')[2]
          ?.replaceAll('-', '')}&id=${
            window.location.pathname.split('/')[3]
          }&user=${localStorage.getItem('username')}`,
        {
          method: 'GET',
        }
      )
        .then((data) => data.json())
        .then((json) => {
          let imagesList = [];
          json?.message?.map((imagePath) => {
            let splitPath = imagePath.split('\\');
            imagesList.push(`https://uploads.greyerp.com/${splitPath[splitPath.length - 1]}`);
          });
          setExistingImages(imagesList);
        });
    }
  }, []);

  useEffect(() => {
    uppy = undefined;
    setTimeout(() => {
      UppyInstanceCreation();
      setReset(true);
    }, 100);
  }, [window.location.pathname.split('/')[3], window.location.pathname.split('/')[2]]);

  function extractFileName(url, userName) {
    const index = url.indexOf(userName);
    // Extract the filename after the userName
    const fileName = index !== -1 ? url.substring(index + userName.length + 1) : url;
    return fileName;
  }

  function FilePlaceholder({url, onRemove}) {
    const name = url ? extractFileName(url, localStorage.getItem("username").toUpperCase()) :""
    return (
    <Grid container direction="column" alignItems="center">
    <Grid item style={{ position: 'relative', textAlign: 'center' }}>
      <Button
        onClick={onRemove}
        style={{
          position: 'absolute',
          top: 0,
          right: 0,
          transform: 'translate(50%, -50%)',
          zIndex: 1,
          backgroundColor: 'transparent',
        }}
      >
        <Avatar sx={{ bgcolor: 'transparent' }}>
          <CancelOutlinedIcon />
        </Avatar>
      </Button>

      <a href={url} target='_blank' rel="noopener noreferrer">
      <img src={url.endsWith('.pdf') ? pdfIcon  : url.endsWith('.jpeg') ||  url.endsWith('.jpg') ||  url.endsWith('.png') || url.endsWith('.svg') ?  url : fileIcon} alt={name} style={{ maxWidth: '200px', height: '150px' }} />
      </a>
    </Grid>
    <Grid item style={{ textAlign: 'center' }}>
      <Typography variant="body2" component="a" href={url} target='_blank' download>
        {name}
      </Typography>
    </Grid>
  </Grid>
    );
  }

  return (
    <>
      <CardPanel
        heading="Attachments"
        sx={{ mx: '16px', height: 'auto !important' }}
        cardItem={
          <>
            <Grid
              container
              sx={{
                '& .uppy-Dashboard-inner': {
                  width: '100% !important',
                  height: 'auto !important',
                  backgroundColor: 'white',
                  border: 'none !important',
                },
                '& .uppy-StatusBar-actions': { display: 'none' },
                '& .uppy-Dashboard-progressindicators': { display: 'none' },
                '& .uppy-Dashboard-AddFiles-info': { display: 'none !important' },
                '& .uppy-Dashboard-AddFiles': { border: 'none !important' },
              }}
            >
              {reset && uppy && (
                <Dashboard
                  //showSelectedFiles={showSelectedFiles}
                  style={{ width: '100%', height: 'auto' }}
                  uppy={uppy}
                  plugins={[
                    'Instagram',
                    'Unsplash',
                    'Webcam',
                    'Dropbox',
                    'Facebook',
                    'GoogleDrive',
                    'OneDrive',
                    'Box',
                    'Zoom',
                    'URL',
                  ]}
                />
              )}

              {existingImages.length > 0 ? (
                <Grid item>
                  <Paper>
                    <Stack direction={'row'} spacing={8}>
                    {existingImages.map((url) => (
                      <Stack  >
                        <FilePlaceholder 
                          url={url}
                          onRemove={(e) => {
                            setExistingImages(existingImages.filter((i) => i != url));
                          }}
                        />
                      </Stack>
                    ))}
                    </Stack>
                  </Paper>
                </Grid>
              ) : null}
            </Grid>
          </>
        }
      />

      {/* <CardPanel
        heading="Attachments"
        cardItem={
          <>
      <Grid
              item
              
              sx={{
                '& .uppy-Dashboard-inner': {
                  width: '100% !important',
                  height: '350px !important',
                  width: '100% !important',
                 
                  backgroundColor: 'white',
                  border: 'none !important',
                },
                '& .uppy-StatusBar-actions': { display: 'none' },
                '& .uppy-Dashboard-progressindicators': { display: 'none' },
                '& .uppy-Dashboard-AddFiles-info': { display: 'none !important' },
                '& .uppy-Dashboard-AddFiles': { border: 'none !important' },
                flexBasis: '100% !important',
              }}
            >
      <div classNameName="uppy-Container" style={{width: "100%;"}}>
      <div className="uppy-Root" dir="ltr">
        <div className="uppy-Dashboard uppy-Dashboard--animateOpenClose uppy-size--md uppy-size--lg uppy-Dashboard--isInnerWrapVisible" data-uppy-theme="light" data-uppy-num-acquirers="9" data-uppy-drag-drop-supported="true" aria-hidden="false" aria-disabled="false" aria-label="Uppy Dashboard">
          <div aria-hidden="true" className="uppy-Dashboard-overlay" tabindex="-1"></div>
          <div className="uppy-Dashboard-inner" aria-modal="false" role="false" style={{width:"750px", height: "550px"}}>
            <div className="uppy-Dashboard-innerWrap">
              <div className="uppy-Dashboard-dropFilesHereHint">Drop your files here</div>
              <div className="uppy-DashboardContent-bar">
                <button className="uppy-DashboardContent-back" type="button">Cancel</button>
                <div className="uppy-DashboardContent-title" role="heading" aria-level="1">3 files selected</div>
                <button className="uppy-DashboardContent-addMore" type="button" aria-label="Add more files" title="Add more files">
                  <svg aria-hidden="true" focusable="false" className="uppy-c-icon" width="15" height="15" viewBox="0 0 15 15">
                  <path d="M8 6.5h6a.5.5 0 0 1 .5.5v.5a.5.5 0 0 1-.5.5H8v6a.5.5 0 0 1-.5.5H7a.5.5 0 0 1-.5-.5V8h-6a.5.5 0 0 1-.5-.5V7a.5.5 0 0 1 .5-.5h6v-6A.5.5 0 0 1 7 0h.5a.5.5 0 0 1 .5.5v6z"></path></svg><span className="uppy-DashboardContent-addMoreCaption">Add more</span></button></div>
                  <div className="uppy-Dashboard-files" role="list"><div role="presentation" style={{position: "relative", width: "100%", minHeight: "100%", height: "200px"}}><div role="presentation" style={{position: 'absolute', top: '0px', left: '0px', width: '100%', overflow: 'visible'}}><div className="uppy-Dashboard-filesInner" role="presentation"><div className="uppy-Dashboard-Item is-resumable is-noIndividualCancellation" id="uppy_uppy-android/chrome/512x512/png-1d-1d-1e-image/png-55627-1675483692556" role="listitem"><div className="uppy-Dashboard-Item-preview">
                    <div className="uppy-Dashboard-Item-previewInnerWrap" style={{backgroundColor: "rgb(104, 109, 224)"}}><img className="uppy-Dashboard-Item-previewImg" alt="android-chrome-512x512.png" src="blob:http://localhost:3000/79508151-cdba-4662-9e96-a42990aa4603"/></div></div><div className="uppy-Dashboard-Item-fileInfoAndButtons"><div className="uppy-Dashboard-Item-fileInfo" data-uppy-file-source="react:Dashboard"><div className="uppy-Dashboard-Item-fileName"><div className="uppy-Dashboard-Item-name" title="android-chrome-512x512.png">android-chrome-512x512.png</div></div><div className="uppy-Dashboard-Item-status">
                      <div className="uppy-Dashboard-Item-statusSize">54 KB</div></div></div><div className="uppy-Dashboard-Item-actionWrapper"><button className="uppy-u-reset uppy-Dashboard-Item-action uppy-Dashboard-Item-action--remove" type="button" aria-label="Remove file" title="Remove file"><svg aria-hidden="true" focusable="false" className="uppy-c-icon" width="18" height="18" viewBox="0 0 18 18"><path d="M9 0C4.034 0 0 4.034 0 9s4.034 9 9 9 9-4.034 9-9-4.034-9-9-9z"></path><path fill="#FFF" d="M13 12.222l-.778.778L9 9.778 5.778 13 5 12.222 8.222 9 5 5.778 5.778 5 9 8.222 12.222 5l.778.778L9.778 9z"></path></svg></button>
                      </div></div></div><div className="uppy-Dashboard-Item is-resumable is-noIndividualCancellation" id="uppy_uppy-apple/touch/icon/png-1d-1d-1e-image/png-11060-1675483692565" role="listitem"><div className="uppy-Dashboard-Item-preview"><div className="uppy-Dashboard-Item-previewInnerWrap" style={{backgroundColor: "rgb(104, 109, 224)"}}><img className="uppy-Dashboard-Item-previewImg" alt="apple-touch-icon.png" src="blob:http://localhost:3000/fa5410b4-8534-4599-ad6f-ab57fc0cf1c0"/></div></div><div className="uppy-Dashboard-Item-fileInfoAndButtons"><div className="uppy-Dashboard-Item-fileInfo" data-uppy-file-source="react:Dashboard"><div className="uppy-Dashboard-Item-fileName"><div className="uppy-Dashboard-Item-name" title="apple-touch-icon.png">apple-touch-icon.png</div>
                      </div><div className="uppy-Dashboard-Item-status"><div className="uppy-Dashboard-Item-statusSize">11 KB</div></div></div><div className="uppy-Dashboard-Item-actionWrapper"><button className="uppy-u-reset uppy-Dashboard-Item-action uppy-Dashboard-Item-action--remove" type="button" aria-label="Remove file" title="Remove file"><svg aria-hidden="true" focusable="false" className="uppy-c-icon" width="18" height="18" viewBox="0 0 18 18"><path d="M9 0C4.034 0 0 4.034 0 9s4.034 9 9 9 9-4.034 9-9-4.034-9-9-9z"></path><path fill="#FFF" d="M13 12.222l-.778.778L9 9.778 5.778 13 5 12.222 8.222 9 5 5.778 5.778 5 9 8.222 12.222 5l.778.778L9.778 9z"></path></svg></button></div></div></div><div className="uppy-Dashboard-Item is-resumable is-noIndividualCancellation" id="uppy_uppy-favicon/ico-1e-image/x-icon-15406-1675483692565" role="listitem"><div className="uppy-Dashboard-Item-preview"><div className="uppy-Dashboard-Item-previewInnerWrap" style={{backgroundColor:"rgb(104, 109, 224);"}}><div className="uppy-Dashboard-Item-previewIconWrap">
                        <span className="uppy-Dashboard-Item-previewIcon" style={{color: "rgb(104, 109, 224)"}}><svg aria-hidden="true" focusable="false" width="25" height="25" viewBox="0 0 25 25"><g fill="#686DE0" fill-rule="evenodd"><path d="M5 7v10h15V7H5zm0-1h15a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1z" fill-rule="nonzero"></path><path d="M6.35 17.172l4.994-5.026a.5.5 0 0 1 .707 0l2.16 2.16 3.505-3.505a.5.5 0 0 1 .707 0l2.336 2.31-.707.72-1.983-1.97-3.505 3.505a.5.5 0 0 1-.707 0l-2.16-2.159-3.938 3.939-1.409.026z" fill-rule="nonzero"></path><circle cx="7.5" cy="9.5" r="1.5"></circle></g></svg></span><svg aria-hidden="true" focusable="false" className="uppy-Dashboard-Item-previewIconBg" width="58" height="76" viewBox="0 0 58 76"><rect fill="#FFF" width="58" height="76" rx="3" fill-rule="evenodd"></rect></svg></div></div></div><div className="uppy-Dashboard-Item-fileInfoAndButtons"><div className="uppy-Dashboard-Item-fileInfo" data-uppy-file-source="react:Dashboard"><div className="uppy-Dashboard-Item-fileName"><div className="uppy-Dashboard-Item-name" title="favicon.ico">favicon.ico</div></div><div className="uppy-Dashboard-Item-status"><div className="uppy-Dashboard-Item-statusSize">15 KB</div></div></div><div className="uppy-Dashboard-Item-actionWrapper">
                        <button className="uppy-u-reset uppy-Dashboard-Item-action uppy-Dashboard-Item-action--remove" type="button" aria-label="Remove file" title="Remove file"><svg aria-hidden="true" focusable="false" className="uppy-c-icon" width="18" height="18" viewBox="0 0 18 18"><path d="M9 0C4.034 0 0 4.034 0 9s4.034 9 9 9 9-4.034 9-9-4.034-9-9-9z"></path><path fill="#FFF" d="M13 12.222l-.778.778L9 9.778 5.778 13 5 12.222 8.222 9 5 5.778 5.778 5 9 8.222 12.222 5l.778.778L9.778 9z"></path></svg></button></div></div></div></div></div></div></div><div className="uppy-Dashboard-progressindicators"><div className="uppy-StatusBar is-waiting" aria-hidden="false"><div className="uppy-StatusBar-progress" role="progressbar" aria-label="0%" aria-valuetext="0%" aria-valuemin="0" aria-valuemax="100" aria-valuenow="0" style={{width: "0%"}}></div><div className="uppy-StatusBar-actions"><button type="button" className="uppy-u-reset uppy-c-btn uppy-StatusBar-actionBtn uppy-StatusBar-actionBtn--upload uppy-c-btn-primary" aria-label="Upload 3 files" data-uppy-super-focusable="true">Upload 3 files</button>
                        </div></div><div className="uppy uppy-Informer"><span></span></div></div></div></div></div></div></div>
      
                        </Grid>
                        
          </>
        }/> */}

      {/*    
        <div
          className="uppy-Dashboard-Item is-resumable is-noIndividualCancellation"
          id="uppy_uppy-favicon512/png-1e-image/png-94920-1681015954256"
          role="listitem"
        >
          <div className="uppy-Dashboard-Item-preview">
            <div
              className="uppy-Dashboard-Item-previewInnerWrap"
              style={{ backgroundColor: 'rgb(104, 109, 224)' }}
            >
              <img
                className="uppy-Dashboard-Item-previewImg"
                alt="favicon512.png"
                height={'100px'}
                width='100px'
                src="blob:http://localhost:3000/85f69b03-d690-4894-87ce-516dcacf6687"
              />
            </div>
          </div>
          <div className="uppy-Dashboard-Item-fileInfoAndButtons">
            <div className="uppy-Dashboard-Item-fileInfo" data-uppy-file-source="react:Dashboard">
              <div className="uppy-Dashboard-Item-fileName">
                <div className="uppy-Dashboard-Item-name" title="favicon512.png">
                  favicon512.png
                </div>
              </div>
              <div className="uppy-Dashboard-Item-status">
                <div className="uppy-Dashboard-Item-statusSize">93 KB</div>
              </div>
            </div>
            <div className="uppy-Dashboard-Item-actionWrapper">
              <button
                className="uppy-u-reset uppy-Dashboard-Item-action uppy-Dashboard-Item-action--remove"
                type="button"
                aria-label="Remove file"
                title="Remove file"
              >
                <svg
                  aria-hidden="true"
                  focusable="false"
                  className="uppy-c-icon"
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                >
                  <path d="M9 0C4.034 0 0 4.034 0 9s4.034 9 9 9 9-4.034 9-9-4.034-9-9-9z"></path>
                  <path
                    fill="#FFF"
                    d="M13 12.222l-.778.778L9 9.778 5.778 13 5 12.222 8.222 9 5 5.778 5.778 5 9 8.222 12.222 5l.778.778L9.778 9z"
                  ></path>
                </svg>
              </button>
            </div>
          </div>
        </div> */}
    </>
  );
}
