import { alpha, styled } from '@mui/material/styles';
import {
  Card,
  Grid,
  Typography,
  Button,
  Popover,
  Tooltip,
  IconButton,
  Checkbox,
  Stack,
} from '@mui/material';
import Select from 'react-select';
import Spreadsheet from 'react-spreadsheet';
import DownloadIcon from '@mui/icons-material/Download';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

import { FormContext } from 'src/layouts/dashboard/DashboardLayout';
import Iconify from 'src/components/iconify/Iconify';
import { LoadingButton } from '@mui/lab';
import { ExportToCsv } from 'export-to-csv';
import SearchIcon from '@mui/icons-material/Search';
import { parse } from 'papaparse';
import { useSnackbar } from 'notistack';
import { CompanyTypes, PaymentMode, PaymentTerms, Purpose, Titles, UnitTypes } from 'src/data/data';
import { TextBoxGREYERP } from '../textbox';
import { UploadIllustration } from 'src/assets/illustrations';
import { borderColor } from '@mui/system';
import { HttpRequest } from 'src/data/scripts';

export default function ImportCSVGREY() {
  const [selectedRow, setSelectedRow] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const [rowExportData, setRowExportData] = useState([]);
  const [openSpreadSheet, setOpenSpreadSheet] = useState(false);
  const [columnsExcel, setColumnsExcel] = useState();
  const [sampleRow, setSampleRow] = useState([]);
  const { columnsGridImport, setDataFetched } = useContext(FormContext);
  const [filter, setFilter] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { openImportPrint, setOpenImportPrint } = useContext(FormContext);
  const formRef = useRef();
  const [dropDownSpreadSheet, setdropDownSpreadSheet] = useState([]);

  const [loading, setLoading] = useState(false);

  let checkBoxCols = [
    'Furnished',
    'Parking',
    'Balcony',
    'AirConditioners',
    'Wardrobes',
    'KitchenAppliances',
    'CCTV',
    'Intercom',
    'SwimmingPool',
    'gymnasium',
    'PrepaidMeter',
    'AccessControl',
  ];

  let numberTypeCols = [
    'PHONE',
    'PHONE2',
    'BALANCE',
    'TAXNUMBER',
    'RENT',
    'MANAGEMENTPERCENTAGE',
    'MANAGEMENTFEE',
    'IDNO',
    'CRNO',
    'CUREENTWATERUNITS',
    'CURRENTELECTRICITYUNITS',
    'ASSOCIATION',
    'SIZE',
    'BATHROOMS',
    'TAX',
    'AMOUNT',
    'TOTAL',
  ];

  let DropDownCols = [
    {
      form: '',
      name: 'Building',
      url: 'buildings',
    },
    {
      form: '',
      name: 'Owner',
      url: 'Owners',
    },
    {
      form: '',
      name: 'Unit',
      url: 'Units',
    },
    {
      form: '',
      name: 'Management',
      url: 'Managements',
    },
    {
      form: 'receipts',
      name: 'FromAccount',
      url: 'receiptaccountsimport',
    },
    {
      form: 'receipts',
      name: 'ToAccount',
      url: 'bankaccounts',
    },
    {
      form: 'payments',
      name: 'FromAccount',
      url: 'bankaccounts',
    },
    {
      form: 'payments',
      name: 'ToAccount',
      url: 'paymentaccounts',
    },
    {
      form: '',
      name: 'TaxCategory',
      url: 'taxCategories',
    },
    {
      form: '',
      name: 'Group',
      url: 'vendorgroups',
    },
    // {
    //   name: 'Currency',
    //   url: 'currencies',
    // },
  ];

  let DropDownEnumCols = [
    {
      name: 'Type',
      form: 'Units',
      items: UnitTypes,
    },
    { name: 'Purpose', form: 'Tenants', items: Purpose },
    { name: 'Title', form: 'Leads', items: Titles },
    { name: 'Type', form: 'Clients', items: CompanyTypes },
    { name: 'Mode', form: 'Receipts', items: PaymentMode },
    { name: 'Mode', form: 'Payments', items: PaymentMode },
    { name: 'Type', form: 'Vendors', items: CompanyTypes },
    { name: 'PaymentTerms', form: 'Vendors', items: PaymentTerms },
  ];

  const CheckBoxEdit = ({ cell, onChange, exitEditMode }) => {
    const handleChange = useCallback(
      (selection) => {
        onChange({ ...cell, value: selection });
      },
      [cell, onChange]
    );

    return <Checkbox defaultChecked={cell.value} value={cell.value} onChange={handleChange} />;
  };

  const CheckBoxView = ({ cell }) => {
    return <Checkbox defaultChecked={cell.value} value={cell.value} />;
  };

  const SelectEdit = ({ cell, onChange, exitEditMode }) => {
    const [options, setOptions] = useState([]);
    const [selectedOption, setSelectedOption] = useState();
    let cellMetaData = cell.className.split(',');

    useEffect(() => {
      let optionsJson = JSON.parse(localStorage.getItem('importitems'))?.filter((i) => {
        if (i?.name?.toUpperCase() == cellMetaData[0]?.toUpperCase()) {
          return true;
        }
        return false;
      });
      setOptions(optionsJson[0]?.items);
    }, []);

    useEffect(() => {
      const option = options?.find(
        (option) =>
          option.label?.toUpperCase() == cell.value?.toUpperCase() ||
          option.value?.toUpperCase() == cell.value?.toUpperCase()
      );
      setSelectedOption(option);
    }, [selectedOption, cell.value, options]);

    const handleChange = useCallback(
      (selection) => {
        onChange({ ...cell, value: selection ? selection?.value : null });
        setSelectedOption(options.filter((i) => i?.value == selection?.value)[0]);
      },

      [cell, onChange, options]
    );
    return (
      <Select
        required={cellMetaData[1] == 'No' ? false : true}
        value={selectedOption}
        onChange={handleChange}
        options={options}
        autoFocus
        styles={{ backgroundColor: 'white !important' }}
        defaultMenuIsOpen
        onMenuClose={() => exitEditMode()}
        isSearchable
      />
    );
  };
  const SelectView = ({ cell }) => {
    const [options, setOptions] = useState([]);
    const [selectedOption, setSelectedOption] = useState();
    let cellMetaData = cell.className.split(',');
    useEffect(() => {
      let optionsJson = JSON.parse(localStorage.getItem('importitems'))?.filter((i) => {
        if (i?.name?.toUpperCase() == cellMetaData[0]?.toUpperCase()) {
          return true;
        }
        return false;
      });
      setOptions(optionsJson[0]?.items);
    }, []);

    useEffect(() => {
      const option = options?.find(
        (option) =>
          option.label?.toUpperCase() == cell.value?.toUpperCase() ||
          option.value?.toUpperCase() == cell.value?.toUpperCase()
      );
      setSelectedOption(option);
    }, [selectedOption, cell.value, options]);

    return (
      <Select
        styles={{ backgroundColor: 'white !important' }}
        required={cellMetaData[1] == 'No' ? false : true}
        value={selectedOption}
        options={options}
        isDisabled
        isSearchable
      />
    );
  };

  const TextEdit = ({ cell, onChange, exitEditMode }) => {
    let cellMetaData = cell.className.split(',');
    const inputRef = useRef(null);
    const handleChange = (selection) => {
      if (numberTypeCols.includes(cellMetaData[0]?.toUpperCase())) {
        if (!isNaN(selection.target.value)) {
          onChange({ ...cell, value: selection.target.value });
        }
      } else {
        onChange({ ...cell, value: selection.target.value });
      }
    };
    useEffect(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, [inputRef]);

    return (
      <div className="Spreadsheet__data-editor">
        <input
          ref={inputRef}
          required={cellMetaData[1] == 'No' ? false : true}
          type={cellMetaData[0].toUpperCase() == 'EMAIL' ? 'email' : 'text'}
          style={{ height: '100%', width: '100%', border: '0px solid', outline: 'none !important' }}
          name="txt"
          value={cell?.value}
          onChange={handleChange}
        />
      </div>
    );
  };

  const TextView = ({ cell }) => {
    let cellMetaData = cell.className.split(',');
    return (
      <input
        required={cellMetaData[1] == 'No' ? false : true}
        type={cellMetaData[0].toUpperCase() == 'EMAIL' ? 'email' : 'text'}
        style={{ height: '100%', width: '100%', border: '0px solid' }}
        name="txt"
        value={cell?.value}
      />
    );
  };

  const addRow = useCallback(
    () =>
      setRowExportData((data) => {
        let sampleRow1 = sampleRow.map((a) => ({ ...a }));
        let sampleRowCopy = sampleRow1.map((i) => {
          i.value = null;
          return i;
        });
        return [...data, sampleRowCopy];
      }),
    [setRowExportData, sampleRow]
  );

  const removeRow = () => {
    if (selectedRow || selectedRow == 0) {
      setRowExportData((data) => {
        return data.filter(function (item, index) {
          return index != selectedRow;
        });
      });
      setSelectedRow(null);
    } else {
      setRowExportData((data) => {
        return data.slice(0, data.length - 1);
      });
    }
  };

  const onDropFile = (e) => {
    e.preventDefault();
    Array.from(e.dataTransfer.files)
      .filter((file) => file.type === 'text/csv')
      .forEach(async (file) => {
        // @ts-ignore - File is from using Javascript type
        const text = await file.text();
        csvData = parse(text, { header: false });
        csvData = csvData.data;
        //to append the results to our state
        //console.log(csvData, text);
        let data = [];
        csvData.shift();
        csvData.map((item) => {
          let row = [];

          item.map((i, index) => {
            let obj = {};
            if (checkBoxCols.includes(columnsExcel.map((col) => col.Name)[index])) {
              obj['DataEditor'] = CheckBoxEdit;
              obj['DataViewer'] = CheckBoxView;
              obj['value'] = i.toUpperCase() == 'YES' ? true : false;
            } else if (
              DropDownCols.map((i) => i.name).includes(columnsExcel.map((col) => col.Name)[index])
            ) {
              obj['DataEditor'] = SelectEdit;
              obj['DataViewer'] = SelectView;
              obj['value'] = i;
            } else {
              obj['DataEditor'] = TextEdit;
              obj['DataViewer'] = TextView;
              obj['value'] = i;
            }

            obj['className'] = `${columnsExcel.map((col) => col.Name)[index]},${
              columnsExcel.map((col) => col.Required)[index]
            }`;

            row.push(obj);
          });
          data.push(row);
          setRowExportData(data);
          setOpenSpreadSheet(true);
        });
      });
  };

  const onSelectFile = (e) => {
    e.preventDefault();
    let csvData = [];
    Array.from(e.target.files)
      .filter((file) => file.type === 'text/csv')
      .forEach(async (file) => {
        // @ts-ignore - File is from using Javascript type
        const text = await file.text();
        csvData = parse(text, { header: true });
        let actualCols = columnsExcel?.map((col) => col.Name);
        csvData = csvData.data;
        csvData.pop();
        let data = [];
        let objOrder = {};
        let dropDownColsItems = [];

        await Promise.all(
          actualCols?.map(async (col) => {
            await Promise.all(
              DropDownCols?.map(async (item) => {
                if (
                  item.name.toUpperCase() == col?.toUpperCase() &&
                  item.form == window.location.pathname.split('/')[2]?.toLowerCase()
                ) {
                  let dropdownItemsRes = await HttpRequest(
                    `getItems/${item?.url}/name/${localStorage.getItem(
                      'username'
                    )}/${localStorage.getItem('client')}`
                  );
                  item['items'] = dropdownItemsRes;
                  dropDownColsItems = [...dropDownColsItems, item];
                }
              })
            );
          })
        );
        let formName = window.location.pathname.split('/')[2]?.toUpperCase();
        actualCols.map((col) => {
          DropDownEnumCols.map((denum) => {
            if (
              denum.name?.toUpperCase() == col?.toUpperCase() &&
              denum.form?.toUpperCase() == formName
            ) {
              dropDownColsItems.push(denum);
            }
          });
        });

        let dropDownKeys = ['value', 'label'];
        let dropDownColsItemsCopy = [...dropDownColsItems];
        dropDownColsItemsCopy.forEach((item) => {
          item.items.forEach((item) => {
            item['value'] = item.ID;
            item['label'] = item.Name ?? item.name;

            Object.keys(item).forEach((name) => {
              if (!dropDownKeys.includes(name)) delete item[name];
            });
          });
        });
        setdropDownSpreadSheet(dropDownColsItemsCopy);
        localStorage.setItem('importitems', JSON.stringify(dropDownColsItemsCopy));
        actualCols?.map((i) => {
          objOrder[i] = '';
        });

        let sampleRowAddedCount = 0;
        csvData.map((item) => {
          let row = [];
          const addObjectResource = Object.assign(objOrder, item);

          for (const key in addObjectResource) {
            if (!actualCols.includes(key)) {
              delete addObjectResource[key];
            }
          }

          Object.values(addObjectResource).map((i, index) => {
            let obj = {};
            let columnName = columnsExcel.map((col) => col.Name)[index];
            if (checkBoxCols.includes(columnsExcel.map((col) => col.Name)[index])) {
              obj['DataEditor'] = CheckBoxEdit;
              obj['DataViewer'] = CheckBoxView;
              obj['value'] = i;
            } else if (
              DropDownCols.map((i) => i.name).includes(
                columnsExcel.map((col) => col.Name)[index]
              ) ||
              DropDownEnumCols.map((i) => i.name).includes(
                columnsExcel.map((col) => col.Name)[index]
              )
            ) {
              obj['DataEditor'] = SelectEdit;
              obj['DataViewer'] = SelectView;
              obj['value'] =
                dropDownColsItems
                  .filter((item) => item.name?.toUpperCase() == columnName?.toUpperCase())[0]
                  ?.items?.filter(
                    (item) => item.label?.toString().toUpperCase() == i?.toString().toUpperCase()
                  )[0]?.value ?? '';
            } else {
              obj['DataEditor'] = TextEdit;
              obj['DataViewer'] = TextView;
              obj['value'] = i;
            }

            obj['className'] = `${columnName},${columnsExcel.map((col) => col.Required)[index]}`;
            row.push(obj);
          });
          data.push(row);
          //  console.log(data);
          setRowExportData(data);
          if (sampleRowAddedCount == 0) {
            setSampleRow(row);
          }
          sampleRowAddedCount += 1;
          setOpenSpreadSheet(true);
        });
      });
  };

  const handleExportData = async () => {
    let colExportData = columnsExcel?.map((i) => i.Name);
    let rowExportData = [{}];

    await HttpRequest(
      `getRecordById/sampledata${window.location.pathname.split('/')[2].toLowerCase()}/0`
    )
      .then((response) => response)
      .then((json) => {
        rowExportData = json.length > 0 ? json : [{}];

        const csvOptions = {
          fieldSeparator: ',',
          quoteStrings: '"',
          decimalSeparator: '.',
          showLabels: true,
          useBom: true,
          filename: `${window.location.pathname
            .split('/')[2]
            .charAt(0)
            .toUpperCase()}${window.location.pathname.split('/')[2].slice(1).replaceAll('-', '')}`,
          useKeysAsHeaders: false,
          headers: colExportData,
        };
        const csvExporter = new ExportToCsv(csvOptions);

        csvExporter.generateCsv(rowExportData);
      });
  };

  const handleFilterChange = useCallback(
    (event) => {
      let nextFilter = event.target.value;
      setFilter(nextFilter);
    },
    [setFilter]
  );

  const filtered = useMemo(() => {
    if (filter.length === 0) {
      return rowExportData;
    }
    const filtered = [];
    for (let row = 0; row < rowExportData.length; row++) {
      if (rowExportData.length !== 0) {
        for (let column = 0; column < rowExportData[row].length; column++) {
          const cell = rowExportData[row][column];
          if (cell && cell.value && cell.value?.toUpperCase().includes(filter?.toUpperCase())) {
            filtered.push(rowExportData[row]);
          }
        }
      }
    }
    return filtered;
  }, [rowExportData, filter]);

  useEffect(() => {
    if (columnsGridImport) {
      HttpRequest(
        `getitems/tablescheme/name/${localStorage.getItem('username')}/${localStorage.getItem(
          'client'
        )}/${columnsGridImport}`
      )
        .then((response) => response)
        .then((json) => {
          setColumnsExcel(json);
        });
    }
  }, []);

  const OnImport = async () => {
    let rowData = [];
    let recordAddedCount = 0;
    if (formRef.current.reportValidity()) {
      setLoading(true);
      rowExportData.map((row) => {
        let rowValues = {};
        row.map((col) => {
          if (col.className.split(',')[0]) {
            if (checkBoxCols.includes(col.className.split(',')[0])) {
              rowValues[col.className.split(',')[0]] =
                col.value.toUpperCase() == 'YES' ? true : false;
            } else {
              rowValues[col.className.split(',')[0]] = col.value;
            }
          }
        });
        rowData.push(rowValues);
      });

      let tableName = window.location.pathname.split('/')[2].toLowerCase();
      let seriesNoForms = ['receipts', 'payments'];
      let caseName =
        tableName == 'receipts' ? 'FromAccount' : tableName == 'payments' && 'ToAccount';
      if (tableName == 'clients') {
        tableName = 'clientuploadcvs';
        const data = rowData.map((item) => {
          const { PersonName, Comments, ...otherProps } = item;
          return { FirstName: PersonName, ...otherProps };
        });
        rowData = data;
      } else if (seriesNoForms.includes(tableName)) {
        let response = await HttpRequest(
          `getVoucherNumber/${window.location.pathname
            .split('/')[2]
            .slice(0, -1)
            .replaceAll('-', '')}/${localStorage.getItem('username')}/${localStorage.getItem(
            'client'
          )}/78789899`
        );

        let data = await response.json();
        let VNumber = Number(data[0].Number);
        let VSeries = data[0].Series;
        let leadingZeros = data[0].Number.match(/^0*/)[0].length;
        rowData.forEach(async (item, index) => {
          item['series'] = VSeries;
          item['number'] = String(VNumber).padStart(leadingZeros + String(VNumber).length, '0');
          VNumber += 1;
        });

        // rowData = await getVoucherNumber(rowData);
        // console.log(rowData);
      }

      //console.log(rowData)

      // console.log(rowData);
      let timeout = 300;
      let enqueueTimeout = 2000 * rowData?.length;

      rowData.map(async (importRow) => {
        setTimeout(async () => {
          let response = await HttpRequest(
            `${tableName}/${localStorage.getItem('client')}/${localStorage.getItem('username')}`,
            'POST',
            importRow
          );
          let json = await response.json();
          //    console.log(json);
          if (!isNaN(json)) {
            recordAddedCount += 1;
          }
          timeout += 300;
        }, timeout);
      });

      setTimeout(() => {
        enqueueSnackbar(`${recordAddedCount}/${rowData?.length} Records Imported`);
        setOpenSpreadSheet(false);
        setLoading(false);
        setDataFetched(false);
      }, enqueueTimeout);
    }
  };

  return (
    <>
      <Popover
        sx={{ backgroundColor: alpha('#212B36', 0.4), top: '50px !important' }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        disableScrollLock={true}
        open={openImportPrint}
        PaperProps={{
          style: { width: '100%', height: '100vh' },
        }}
      >
        <Card sx={{ py: 5, px: 5, width: '100%', height: '100%' }}>
          <Grid container sx={{ mb: 3 }} alignItems={'center'} justifyContent="space-between">
            <Grid item>
              <Typography variant="h4">
                Import{' '}
                {(
                  window.location.pathname.split('/')[2].charAt(0).toUpperCase() +
                  window.location.pathname.split('/')[2].slice(1)
                ).replaceAll('-', '')}
              </Typography>
            </Grid>

            <Grid item>
              {' '}
              <Tooltip title="Close">
                <IconButton onClick={(e) => setOpenImportPrint(false)}>
                  <Iconify icon="material-symbols:close" />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
          <Grid container justifyContent={'space-between'}>
            <Grid>
              <LoadingButton
                onClick={handleExportData}
                loadingPosition="start"
                startIcon={<DownloadIcon />}
                variant="outlined"
                sx={{ my: 1 }}
              >
                Download Sample Excel File
              </LoadingButton>
            </Grid>
            {openSpreadSheet && (
              <Grid item>
                <LoadingButton
                  loading={loading}
                  onClick={OnImport}
                  variant="contained"
                  sx={{ my: 1, mr: 1 }}
                >
                  Import
                </LoadingButton>
                <Button
                  onClick={() => setOpenSpreadSheet(false)}
                  variant="contained"
                  color="inherit"
                  sx={{ my: 1 }}
                >
                  Cancel
                </Button>
              </Grid>
            )}
          </Grid>

          {!openSpreadSheet && (
            <>
              <Grid
                item
                sx={{
                  '& .CSVImporter_FileSelector': {
                    padding: '2em !important',
                  },
                }}
              >
                <div
                  className="CSVImporter_FileSelector"
                  style={{
                    flex: 1,
                    border: '1px dashed rgba(145, 158, 171, 0.32)',
                    backgroundColor: '#F4F6F8',
                  }}
                >
                  {' '}
                  <label for="getFile1" style={{ cursor: 'grab' }}>
                    <Grid container alignItems="center" justifyContent="center">
                      <Grid item>
                        <UploadIllustration sx={{ width: 220 }} />
                      </Grid>
                      <Grid
                        item
                        sx={{ ml: 5 }}
                        onDragOver={(e) => {
                          e.preventDefault();
                        }}
                        onDrop={onDropFile}
                      >
                        <input
                          multiple=""
                          onChange={onSelectFile}
                          type="file"
                          tabindex="-1"
                          id="getFile1"
                          style={{ display: 'none' }}
                        />

                        <Typography gutterBottom variant="h5">
                          Drop or Select file
                        </Typography>

                        <Typography for="getFile1" variant="body2" sx={{ color: 'text.secondary' }}>
                          Drop files here or click
                          <Typography
                            variant="body2"
                            component="span"
                            sx={{
                              mx: 0.5,
                              color: 'primary.main',
                              textDecoration: 'underline',
                            }}
                          >
                            browse
                          </Typography>
                          thorough your machine
                        </Typography>
                      </Grid>
                    </Grid>
                  </label>
                </div>
              </Grid>
            </>
          )}

          <Grid container sx={{ mt: 5, height: '64vh', width: '100%' }}>
            {openSpreadSheet && (
              <>
                <Grid container justifyContent={'space-between'} sx={{ width: '100%' }}>
                  <Grid item>
                    <Button
                      onClick={addRow}
                      variant="contained"
                      size="small"
                      sx={{
                        my: 1,
                      }}
                    >
                      Add Row
                    </Button>
                    <Button
                      onClick={removeRow}
                      variant="contained"
                      size="small"
                      color="inherit"
                      sx={{
                        my: 1,
                        ml: 1,
                      }}
                    >
                      Remove Row
                    </Button>
                  </Grid>
                  <Grid item>
                    <TextBoxGREYERP
                      width="300px !important"
                      placeholder={'Search'}
                      defaultValue={filter}
                      name={''}
                      prefix={<SearchIcon />}
                      style={{
                        '& fieldset': {
                          border: '0px',
                          borderBottom: '1.5px solid #000000 !important',
                          borderRadius: '0px',
                        },
                        '& input': {
                          '&::placeholder': {
                            color: '#abb5bf !important',
                          },
                        },
                        mb: 2,
                      }}
                      // onChange2={handleFilterChange}
                    />
                  </Grid>
                </Grid>

                <Grid
                  item
                  sx={{
                    '& .Spreadsheet__header': {
                      minWidth: '150px !important',
                    },
                    '& .Spreadsheet': {
                      height: '400px !important',
                    },
                    '& .Spreadsheet__cell div div': {
                      backgroundColor: 'white !important',
                      color: 'black',
                    },
                    '& *:focus': {
                      outline: 'none',
                    },
                    overflow: 'auto',
                    width: '100%',
                    height: '100%',
                  }}
                >
                  <form id="frmMain" ref={formRef} style={{ overflow: 'auto' }}>
                    <Spreadsheet
                      data={filtered}
                      onChange={setRowExportData}
                      onSelect={(selRow) => {
                        if (selRow.length > 0) {
                          setSelectedRow(selRow[0]?.row);
                        }
                      }}
                      columnLabels={
                        columnsExcel?.length > 0 &&
                        columnsExcel.map((i) => `${i.Name}${i.Required == 'No' ? '' : '*'}`)
                      }
                    />
                  </form>
                </Grid>
              </>
            )}
          </Grid>
        </Card>
      </Popover>
    </>
  );
}

/////////////////////

{
  /* <CSVReader
              onFileLoaded={(csvData) => {
                let data = [];
                console.log(csvData);

                csvData.shift();
                csvData.map((item) => {
                  let row = [];

                  item.map((i, index) => {
                    let obj = {};
                    if (checkBoxCols.includes(columnsExcel.map((col) => col.Name)[index])) {
                      obj['DataEditor'] = CheckBoxEdit;
                      obj['DataViewer'] = CheckBoxView;
                    } else if (DropDownCols.includes(columnsExcel.map((col) => col.Name)[index])) {
                      obj['DataEditor'] = SelectEdit;
                      obj['DataViewer'] = SelectView;
                    } else {
                      obj['DataEditor'] = TextEdit;
                    }

                    obj['value'] = i;
                    obj['className'] = columnsExcel.map((col) => col.Name)[index];

                    row.push(obj);
                  });
                  data.push(row);
                  setRowExportData(data);
                  setOpenSpreadSheet(true);
                });
              }}
            /> */
}

{
  /* <ImportCSV columnsExcel={columnsExcel} /> */
}

// rowData.map(async (item,index) => {
//   let response = await HttpRequest(
//     `getVoucherNumber/${window.location.pathname
//       .split('/')[2]
//       .slice(0, -1)
//       .replaceAll('-', '')}/${localStorage.getItem('username')}/${localStorage.getItem(
//       'client'
//     )}/${item[caseName]}`
//   );

//   let data = await response.json();
//   item['series'] = data[0].Series;
//   item['number'] = data[0].Number;
//   return item;
// })
// );
