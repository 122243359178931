import { DropDownGREYERP } from 'src/controls/dropdown';
import { TextBoxGREYERP } from 'src/controls/textbox';
import PopUpPanel from 'src/controls/popuppanel';
import UploadGREY from 'src/controls/uploadgrey';
import { SwithGREYERP } from 'src/controls/swithgrey';
export default function Faqs() {
  return (
    <>
      <PopUpPanel
        heading={'Add'}
        child={
          <>
            <UploadGREY span={2}/>
              <TextBoxGREYERP multiline={true} name="Question" span={2} placeholder="Question" MultiLine={true} required={true} focus={true}/>
              <TextBoxGREYERP multiline={true} name="Answer" span={2} placeholder="Answer" MultiLine={true} required={true} />
            <TextBoxGREYERP multiline={true} name="QuestionArabic" span={2} placeholder="سؤال" MultiLine={true} dir="rtl" />
            <TextBoxGREYERP multiline={true} name="AnswerArabic" span={2} placeholder="جواب" MultiLine={true} dir="rtl" />
              <DropDownGREYERP firstItem="0,Main" items={`faqcategories/name`} name="Category" required={true} />
              <TextBoxGREYERP name="Sort" placeholder="Sort" type="Number" span={.5} required={true} />
              <SwithGREYERP name="Status" span={.5}/>
            <TextBoxGREYERP name="Comments" placeholder="Internal Notes" MultiLine={true} span={2} />
          </>
        }
      />
    </>
  );
}
