import ReactDOM from 'react-dom/client';
//
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';


// ----------------------------------------------------------------------

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <App />
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// [{
//     "relation": ["delegate_permission/common.handle_all_urls"],
//     "target" : { "namespace": "android_app", "package_name": "apps.greyerp.com",
//                  "sha256_cert_fingerprints": ["2C:C7:1C:A7:BE:48:AB:4A:69:85:99:76:60:DE:08:A5:2F:21:80:40:CF:42:5A:E5:29:C6:5C:89:FD:F1:34:19"] }
//   }]
  
