/* eslint-disable react/prop-types */
import {
  FormControl,
  Grid,
  InputAdornment,
  Stack,
  TextField,
  TextareaAutosize,
} from '@mui/material';
import React, { useContext, useEffect, useState, useRef } from 'react';
import { FormContext } from 'src/layouts/dashboard/DashboardLayout';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import { updateCell } from 'src/data/scripts';
import { dbImagePath, excludedFieldsClone, sectionForms } from 'src/data/data';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import Link from '@mui/material/Link';
import useResponsive from 'src/hooks/useResponsive';
import { useQuery } from '@tanstack/react-query';
import { useFormData } from 'src/hooks/useQueryHooks';
import { useTranslation } from 'react-i18next';
export function TextBoxGREYERP({
  label,
  focus = false,
  dir = 'ltr',
  onChange,
  placeholder,
  name,
  width,
  type,
  prefix,
  sufix,
  step,
  // onChange2, used in importgreycsv
  required,
  disabled,
  multiline = false,
  rows,
  min,
  max,
  enterEdit = true,
  defaultValue,
  Case = 'none',
  maxLength,
  spaces = true,
  span,
  style,
}) {
  const {
    setObjectEdit,
    resetFields,
    setResetFields,
    setImage,
    image,
    editOnCell,
    popupEditId,
    mainFormRef,
    tbldata,
    setTbldata,
    swappedSections,
  } = useContext(FormContext);
  const { t } = useTranslation();

  const [defaultVal, setDefaultVal] = useState(defaultValue);
  const [valueTextBox, setvalueTextBox] = useState('');
  const [focused, setFocused] = useState(editOnCell ? false : focus);
  const [dialCode, setdialCode] = useState('');
  const isDesktop = useResponsive('up', 'lg');

  const { setFormData: setObject, formData: object, updateWholeForm } = useFormData();

  const startsWith = (string, target) => {
    return string.startsWith(target);
  };

  let form = window.location.pathname?.split('/')[2]?.replaceAll('-', '');
  let ID = window.location.pathname?.split('/')[3]?.replaceAll('-', '') ?? popupEditId;

  const { data: objectEdit } = useQuery({
    queryKey: [form, ID],
    select: (res) => res[0],
    enabled: Boolean(form),
  });

  const registerDataChange = (e, dialCodeData) => {
    if (e.target.type?.toUpperCase() == 'FILE') {
      const file = e.target.files[0];
      if (file) {
        file &&
          setImage((prev) => {
            return { ...prev, [name]: file };
          });

        // setObject((prev) => {
        //   return { ...prev, [name]: file?.name };
        // });
        setObject({ [name]: file?.name });

        return;
      }
    }

    if (mobileNumberField.includes(name?.toUpperCase())) {
      setdialCode(dialCodeData.dialCode);
    }

    if (
      !e.target.value &&
      (!isNaN(window.location.pathname.split('/')[3]) || popupEditId) &&
      objectEdit[name]?.toString().length > 0
    ) {
      // setObject({ ...object, [e.target.name]: e.target.value });
      setObject({ [e.target.name]: e.target.value });

      setvalueTextBox(e.target.value);
      setFocused(false);
      return;
    } else if (!e.target.value && !isNaN(window.location.pathname.split('/')[3])) {
      setFocused(false);
      return;
    }

    if (
      (!e.target.value && isNaN(window.location.pathname.split('/')[3]) && !editOnCell) ||
      (mobileNumberField.includes(e.target.name.toUpperCase()) &&
        !e.target.value?.slice(dialCodeData.dialCode.length + 1) &&
        isNaN(window.location.pathname.split('/')[3]))
    ) {
      setFocused(false);
      let objectCopy = { ...object };
      delete objectCopy[e.target.name];

      // setObject(objectCopy);
      updateWholeForm(objectCopy);

      if (editOnCell) {
        if (required) {
          mainFormRef.current.reportValidity();
          setvalueTextBox(objectEdit[name]);
        } else {
          updateCell(name, '', popupEditId, setObjectEdit, tbldata, setTbldata);
        }
      }

      return;
    }

    let fieldType = e.target.type.toUpperCase();
    let fieldStep = step?.toUpperCase();
    let fieldValue = e.target.value;
    let value =
      fieldType == 'NUMBER' && fieldStep == 'ANY'
        ? parseFloat(fieldValue)
        : fieldType == 'NUMBER'
        ? parseInt(fieldValue)
        : fieldValue;

    if (mobileNumberField.includes(name?.toUpperCase())) {
      value = value.replaceAll(' ', '');
    }
    if (editOnCell) {
      if (required && !value) {
        mainFormRef.current.reportValidity();
        setvalueTextBox(objectEdit[name]);
      } else {
        updateCell(name, value, popupEditId, setObjectEdit, tbldata, setTbldata);
      }
    } else {
      // setObject({ ...object, [e.target.name]: value });
      setObject({ [e.target.name]: value });

      setvalueTextBox(e.target.value);
    }
    setFocused(false);
  };

  const [imageLink, setImageLink] = useState('');
  const mobileNumberField = ['PHONE', 'PHONE2', 'PHONE3', 'PHONE4'];

  let textBoxRef = useRef(null);

  // useEffect(() => {
  //   if (resetFields) {
  //     if (mobileNumberField.includes(name?.toUpperCase())) {
  //       // setvalueTextBox(`${dialCode}`);
  //     }
  //     // else {
  //     //   setvalueTextBox('');
  //     // }
  //     if (focus) {
  //       textBoxRef.current.focus();
  //     }

  //     setResetFields(false);
  //     setFocused(focus);
  //   }
  // }, [resetFields]);

  const onChangeEvent = (e) => {
    // if(e.target.type?.toUpperCase() == 'FILE'){
    //   ///handle files
    //   console.log("files===", e.target.files[0]);
    //         const file = e.target.files[0];
    //         if (file) {
    //           file &&
    //             setImage((prev) => {
    //               return { ...prev, [name]: file };
    //             });
    //           setObject({ [name]: file?.name})
    //           return;
    //         }

    // }
    setvalueTextBox(e.target.value);
    // setObject({[e.target.name]: e.target.value})
  };

  // const handlePhone = (value, country, e, formattedValue)=>{
  //   if(mobileNumberField.includes(e.target.name?.toUpperCase())){
  //     setvalueTextBox(value);
  //     setObject({[e.target.name]: value})
  //   }
  // }

  useEffect(() => {
    if (defaultValue || defaultValue == 0) {
      setvalueTextBox(defaultValue);
    }
  }, [defaultValue]);

  useEffect(() => {
    // setFocused(focus);
    if (objectEdit && Object.keys(objectEdit)?.length > 0 && enterEdit) {
      if (excludedFieldsClone.includes(name)) return;
      const objectPropertyNames = Object.keys(objectEdit);

      const valueArray = objectPropertyNames.filter(
        (item, index, object) => item?.toUpperCase() == name?.toUpperCase()
      );

      const valueEdit = objectEdit[valueArray[0]];
      if (type?.toLowerCase() == 'file') {
        if (dbImagePath.includes(name)) {
          setImageLink(valueEdit);
        }
        return;
      }
      (valueEdit == 0 || valueEdit) && setvalueTextBox(valueEdit);
    }
  }, [objectEdit, focus]);

  let imageComp = imageLink ? (
    <Stack
      direction={'row'}
      justifyContent={'center'}
      alignItems={'center'}
      sx={{
        '& svg': {
          fill: '#078DEE !important',
        },
      }}
    >
      <AttachFileIcon fontSize="13px" />
      <Link
        target="_blank"
        sx={{ fontSize: '13px' }}
        href={`https://uploads.greyerp.com/${imageLink}`}
      >
        Attached File
      </Link>
    </Stack>
  ) : null;

  let phoneField = mobileNumberField.includes(name?.toUpperCase());

  useEffect(() => {
    if (
      sectionForms.includes(window.location.pathname.split('/')[2].toUpperCase()) &&
      swappedSections
    ) {
      if (name == 'Number') {
        setvalueTextBox(`${object.series ?? ''}${object.number ?? ''}`);
      } else {
        setvalueTextBox(object[name]);
      }
    }
  }, []);

  //editOnCell ? false : focus,

  return (
    <Grid md={span ?? 1} xs={!isDesktop && 12} item>
      <FormControl sx={{ mt: phoneField && '-5px !important' }}>
        {phoneField ? (
          <>
            <fieldset className="phone-input-1">
              <legend>{`${t(name)}${required ? ` *` : ''}`}</legend>
              <PhoneInput
                country={'om'}
                isValid={(inputNumber, country, countries) => {
                  return countries.some((country) => {
                    return (
                      startsWith(inputNumber, country.dialCode) ||
                      startsWith(country.dialCode, inputNumber)
                    );
                  });
                }}
                value={valueTextBox}
                autoFormat={true}
                countryCodeEditable={true}
                // onChange={handlePhone}

                onBlur={onChange ? onChange : registerDataChange}
                enableSearch={true}
                inputProps={{
                  name: name,
                  focused: true,
                  required: required ?? false,
                  autoFocus: editOnCell ? false : focus,
                }}
              />
            </fieldset>
          </>
        ) : (
          <TextField
            autoFocus={focus}
            focused
            dir={dir}
            inputRef={textBoxRef}
            ref={textBoxRef}
            required={required ?? false}
            size="small"
            margin="dense"
            variant="outlined"
            label={label ? t(label) : t(name?.match(/[A-Z][a-z]+|[0-9]+/g)?.join(' '))}
            // label={name}
            defaultValue={defaultVal ? defaultVal : ''}
            step={step}
            onBlur={onChange ? onChange : registerDataChange}
            inputProps={{
              maxLength: maxLength,
              style: { textTransform: Case },
              min: min,
              max: max,
            }}
            // onWheel={e=>{
            //   e.preventDefault();
            //   document.activeElement.blur();
            //   e.currentTarget.blur();
            // }}
            multiline={multiline}
            maxRows={4}
            placeholder={t(placeholder)}
            id="outlined-required"
            type={type ?? null}
            disabled={disabled ?? false}
            name={name}
            rows={rows}
            //onChange={onChange2 ? onChange2 : onChangeEvent}
            onChange={onChangeEvent}
            value={valueTextBox}
            //helperText={`Please enter an ${name}`}
            color="secondary"
            InputProps={{
              startAdornment: prefix && <InputAdornment position="start">{prefix}</InputAdornment>,

              endAdornment: (sufix || imageComp) && (
                <InputAdornment position="end">{sufix || imageComp}</InputAdornment>
              ),
            }}
            // onFocus={(e) => {
            //   e.preventDefault();
            //   setFocused(true);
            // }}
            key={defaultVal ?? resetFields ?? focus}
            sx={{
              '& .MuiOutlinedInput-root.Mui-focused': {
                '& > fieldset': {
                  borderColor: !focused && '#E5E8EB',
                },
              },
              width: width ?? '100%',
              ...style,
            }}
          />
        )}
      </FormControl>
    </Grid>
  );
}
