import { useContext } from 'react';
import { CardPanel } from 'src/controls/cardpanel';
import { TextBoxGREYERP } from 'src/controls/textbox';
import React from 'react';
import { DropDownENUM } from 'src/controls/dropdownenum';
import { ChequeStatus } from 'src/data/data';
import { FormContext } from 'src/layouts/dashboard/DashboardLayout';
import { Grid } from '@mui/material';
import Picker from './picker';

export default function ChequeDetails() {
  const { image } = useContext(FormContext);
  return (
    <>
      <CardPanel
        heading="Cheque Details"
        cardItem={
          <>
            <TextBoxGREYERP name="ChequeNo" type="number" required={true} />
            <Picker type='date' name="ChequeDate" placeholder="Cheque Date" required={true} ></Picker>
            <TextBoxGREYERP name="Cheque" type="file" value="" />
            <TextBoxGREYERP name="ChequeDescription" multiline={true} />
            <DropDownENUM items={ChequeStatus} name="Status" required={true} />
            <Grid item sx={{ m: 'auto', my: '10px' }}>
              {image?.file && ( <img height={200} width={'100%'} src={URL.createObjectURL(image?.file)} title="Cheque" /> )}
            </Grid>
          </>
        }
      />
    </>
  );
}
