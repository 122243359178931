import { alpha, styled } from '@mui/material/styles';
import {
  Box,
  Card,
  Grid,
  Table,
  Divider,
  TableRow,
  TableBody,
  TableHead,
  TableCell,
  Typography,
  TableContainer,
  Button,
  Popover,
  Stack,
  Tooltip,
  IconButton,
  Popper,
  Grow,
  ClickAwayListener,
  MenuList,
  MenuItem,
  ListItemIcon,
  Menu,
} from '@mui/material';

import { func } from 'prop-types';
import { useContext, useEffect, useStatem, useRef, useState } from 'react';

import { CardPanel } from 'src/controls/cardpanel';
import { DropDownGREYERP } from 'src/controls/dropdown';
import { TextBoxGREYERP } from 'src/controls/textbox';
import NavHorizontal from 'src/layouts/dashboard/nav/NavHorizontal';
import React, { Component } from 'react';
import { useTheme } from '@mui/material/styles';
import { DropDownENUM } from 'src/controls/dropdownenum';
import { ChequeStatus, Mode, PaymentMode } from 'src/data/data';
import Picker from 'src/controls/picker';
import ChequeDetails from 'src/controls/chequedetail';
import { FormContext } from 'src/layouts/dashboard/DashboardLayout';
import {
  HttpRequest,
  amountInWords,
  formatdecimals,
  getTaxCategory,
  getVoucherNumber,
} from 'src/data/scripts';
import { default as DataGrid } from '../Grid';
import { borderBottom } from '@mui/system';
import { useReactToPrint } from 'react-to-print';
import Iconify from 'src/components/iconify/Iconify';

const StyledRowResult = styled(TableRow)(({ theme }) => ({
  '& td': {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));
export default function PrintExpense() {
  const theme = useTheme();
  const [data, setData] = useState();
  const [voucherNumber, setVoucherNumber] = useState('');
  const [accountSummaryData, setAccountSummaryData] = useState([]);
  const [pageHeader, setPageHeader] = useState();
  const [pageFooter, setPageFooter] = useState();
  const [pageHeading, setPageHeading] = useState();
  const [pageBottom, setPageBottom] = useState();
  const [pageColor, setPageColor] = useState();
  const [pageFont, setPageFont] = useState();
  const [pageFontSize, setpageFontSize] = useState();

  const { object, setObject, objectEdit } = useContext(FormContext);
  const { openPrint, setOpenPrint, recordId, setRecordId } = useContext(FormContext);

  useEffect(() => {
    HttpRequest(`getRecordById/expensedetailprint/${recordId}`)
      .then((response) => response)
      .then((json) => {
        //  console.log(json)
        setData(json[0]);
      });

    let documentlayoutsid = 0;

    HttpRequest(`getRecordById/clientdetails/${localStorage.getItem('client')}`)
      .then((response) => response)
      .then((json) => {
        documentlayoutsid = json[0].ExpenseLayout;

        HttpRequest(`getRecordById/documentlayouts/${documentlayoutsid}`)
          .then((response) => response)
          .then((json) => {
            setPageHeader(json[0].Header);
            setPageFooter(json[0].Footer);
            setPageHeading(json[0].Heading);
            setPageBottom(json[0].Bottom);
            setPageColor(json[0].color);
            setPageFont(json[0].font);
            setpageFontSize(json[0].fontSize);
          });
      });
  }, []);

  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const HeadingArabia = {
    sx: {
      fontWeight: '600',
      fontSize: '12px',
      pt: 3,
    },
  };
  const Heading = {
    sx: {
      fontWeight: '600',
      fontSize: '12px',
      pt: 3,
    },
  };
  return (
    <>
      <Popover
        sx={{ backgroundColor: alpha('#212B36', 0.4) }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={openPrint}
        // sx={{
        //   '.MuiPaper-root': { height: 'auto', top: '70px', right: '10px', borderRadius: '8px' },
        // }}
      >
        <Card ref={componentRef} sx={{ pt: 5, px: 3, width: '850px', height: '800px' }}>
          <Grid container alignItems={'center'}>
            <Grid item sx={{ ml: 'auto', displayPrint: 'none' }}>
              <Tooltip title="Print">
                <IconButton onClick={handlePrint}>
                  <Iconify icon="eva:printer-fill" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Close">
                <IconButton onClick={(e) => setOpenPrint(false)}>
                  <Iconify icon="material-symbols:close" />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
          <Grid container>
            <div
              className="ql-editor"
              style={{ width: '100%' }}
              dangerouslySetInnerHTML={{ __html: pageHeader }}
            />
          </Grid>

          <Grid container justifyContent={'center'}>
            <Typography variant="h4">
              {pageHeading &&
              (pageHeading == 'DOCUMENT NAME' || pageHeading == '<p>DOCUMENT NAME</p>') ? (
                'EXPENSE VOUCHER'
              ) : (
                <div
                  className="ql-editor"
                  style={{ width: '100%' }}
                  dangerouslySetInnerHTML={{ __html: pageHeading }}
                />
              )}
            </Typography>
          </Grid>

          <Grid container sx={{ pt: 3 }}>
            <Grid item xs={12} sm={6} sx={{}}>
              <TableContainer sx={{ overflow: 'unset' }}>
                <Table sx={{ width: '100%', ml: '15px' }}>
                  {
                    <TableBody>
                      <StyledRowResult sx={{ py: '0px !important' }}>
                        <TableCell sx={{ width: '35px', py: '0px !important' }}>
                          <Typography
                            variant="span"
                            sx={{ fontWeight: 'bold', fontSize: '13px', py: '0px !important' }}
                          >
                            Rials{' '}
                          </Typography>
                        </TableCell>
                        <TableCell sx={{ width: '35px', py: '0px !important' }}>
                          <Typography
                            variant="span"
                            sx={{ fontWeight: 'bold', fontSize: '13px', py: '0px !important' }}
                          >
                            Bzs{' '}
                          </Typography>
                        </TableCell>

                        <TableCell></TableCell>
                      </StyledRowResult>

                      <StyledRowResult sx={{ py: '0px !important' }}>
                        <TableCell
                          sx={{ border: 'solid 1px', width: '35px', py: '0px !important' }}
                        >
                          <Typography
                            variant="span"
                            sx={{ fontWeight: 'bold', fontSize: '13px', py: '0px !important' }}
                          >
                            {data && parseFloat(data.Amount).toFixed(3).toString().split('.')[0]}
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={{ border: 'solid 1px', width: '35px', py: '0px !important' }}
                        >
                          <Typography
                            variant="span"
                            sx={{ fontWeight: 'bold', fontSize: '13px', py: '0px !important' }}
                          >
                            {data && parseFloat(data.Amount).toFixed(3).toString().split('.')[1]}
                          </Typography>
                        </TableCell>
                        <TableCell></TableCell>
                      </StyledRowResult>
                    </TableBody>
                  }
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={12} sm={6} sx={{}}>
              <Typography variant="body2" sx={{ fontWeight: 'bold', fontSize: '13px' }}>
                Dated{' '}
                <Typography variant="span" sx={{ fontWeight: '200' }}>
                  {data && data.Date}
                </Typography>
              </Typography>

              <Typography variant="body2" sx={{ fontWeight: 'bold', fontSize: '13px' }}>
                Number:{' '}
                <Typography variant="span" sx={{ fontWeight: '200' }}>
                  {data && data.series}
                  {data && data.number}
                </Typography>
              </Typography>
            </Grid>
          </Grid>

          <TableContainer sx={{ overflow: 'unset' }}>
            {/* <Scrollbar> */}
            <Table sx={{ width: '100%' }}>
              {
                <TableBody>
                  <StyledRowResult>
                    <TableCell>
                      <>
                        {' '}
                        <Typography variant="body2" width={100} sx={{ fontWeight: 'bold' }}>
                          Type
                        </Typography>
                      </>
                    </TableCell>
                    <TableCell colSpan={7} align="left" sx={{ borderBottom: 'dotted 1px' }}>
                      <Typography>
                        {data && data.Company}
                        {/* {data && data.invoice} */}
                      </Typography>
                    </TableCell>

                    <TableCell colSpan={1} align="right">
                      <Typography sx={{ fontWeight: 'bold' }}>دفع إلى</Typography>
                    </TableCell>
                  </StyledRowResult>

                  <StyledRowResult>
                    <TableCell>
                      <Typography variant="body2" width={100} sx={{ fontWeight: 'bold' }}>
                        The Sum of
                      </Typography>
                    </TableCell>
                    <TableCell
                      colSpan={7}
                      align="left"
                      sx={{ typography: 'subtitle1', borderBottom: 'dotted 1px' }}
                    >
                      <Typography>{data && amountInWords(data.Amount)} Only</Typography>
                    </TableCell>

                    <TableCell colSpan={1} align="right">
                      <Typography sx={{ fontWeight: 'bold' }}>مبلغ وقدره</Typography>
                    </TableCell>
                  </StyledRowResult>
                  {data && data.Mode == 'Cheque' && (
                    <StyledRowResult>
                      <TableCell>
                        <Typography variant="body2" width={100} sx={{ fontWeight: 'bold' }}>
                          Transaction details
                        </Typography>
                      </TableCell>
                      <TableCell
                        colSpan={3}
                        align="left"
                        sx={{ typography: 'subtitle1', borderBottom: 'dotted 1px' }}
                      >
                        <Typography variant="body2">
                          Cheque No. {data.ChequeNo}, Cheque Date {data.ChequeDate}
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Typography sx={{ fontWeight: 'bold' }}>نقدا / شيك رقم</Typography>
                      </TableCell>

                      <TableCell align="left">
                        <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                          Dated
                        </Typography>
                      </TableCell>
                      <TableCell
                        colSpan={2}
                        align="left"
                        sx={{ typography: 'subtitle1', borderBottom: 'dotted 1px' }}
                      >
                        <Typography variant="body2">{data.Date}</Typography>
                      </TableCell>
                      <TableCell colSpan={2} align="right">
                        <Typography sx={{ fontWeight: 'bold' }}>بتاريخ</Typography>
                      </TableCell>
                    </StyledRowResult>
                  )}

                  {data && data.Mode != 'Cheque' && (
                    <StyledRowResult>
                      <TableCell>
                        <Typography variant="body2" width={100} sx={{ fontWeight: 'bold' }}>
                          Received as
                        </Typography>
                      </TableCell>
                      <TableCell
                        colSpan={3}
                        align="left"
                        sx={{ typography: 'subtitle1', borderBottom: 'dotted 1px' }}
                      >
                        <Typography variant="body2" width={100}>
                          {data.Mode}
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Typography width={100} sx={{ fontWeight: 'bold' }}>
                          نقدا / شيك رقم
                        </Typography>
                      </TableCell>

                      <TableCell align="right">
                        <Typography variant="body2" width={100} sx={{ fontWeight: 'bold' }}>
                          Dated
                        </Typography>
                      </TableCell>
                      <TableCell
                        colSpan={2}
                        align="left"
                        sx={{ typography: 'subtitle1', borderBottom: 'dotted 1px' }}
                      >
                        <Typography variant="body2">{data.Date}</Typography>
                      </TableCell>
                      <TableCell colSpan={2} align="right">
                        <Typography sx={{ fontWeight: 'bold' }}>بتاريخ</Typography>
                      </TableCell>
                    </StyledRowResult>
                  )}

                  <StyledRowResult>
                    <TableCell>
                      <>
                        {' '}
                        <Typography variant="body2" width={100} sx={{ fontWeight: 'bold' }}>
                          Being
                        </Typography>
                      </>
                    </TableCell>
                    <TableCell
                      colSpan={6}
                      align="left"
                      sx={{ typography: 'subtitle1', borderBottom: 'dotted 1px' }}
                    >
                      <Typography variant="subtitle1">{data && data.comments}</Typography>
                    </TableCell>

                    <TableCell colSpan={2} align="right" sx={{ typography: 'subtitle1' }}>
                      <Typography variant="body2" sx={{ fontWeight: '600' }}>
                        وذلك عن
                      </Typography>
                    </TableCell>
                  </StyledRowResult>

                  <StyledRowResult>
                    <TableCell>
                      <Typography variant="body2" width={100} sx={{ fontWeight: 'bold', pt: 3 }}>
                        Receiver's Sign.
                      </Typography>
                    </TableCell>
                    <TableCell colSpan={2}></TableCell>

                    <TableCell align="right" sx={{ typography: 'subtitle1' }}>
                      <Typography variant="body2" sx={{ fontWeight: '600', pt: 3 }}>
                        توقيع المستلم
                      </Typography>
                    </TableCell>
                  </StyledRowResult>
                </TableBody>
              }
            </Table>
            {/* </Scrollbar> */}
          </TableContainer>

          <div className="ql-editor" dangerouslySetInnerHTML={{ __html: pageBottom }} />
          <div className="ql-editor" dangerouslySetInnerHTML={{ __html: pageFooter }} />
        </Card>
      </Popover>
    </>
  );
}
