import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Link } from '@mui/material';
// eslint-disable-next-line import/no-unresolved
import greyErpLogo from'../../assets/logo-white.png';
import logoblack from '../../assets/grey-logo-bird-black.png';

// ----------------------------------------------------------------------

const LogoVertical = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {
  return (
    <Link component={RouterLink} to="/" sx={{ display: 'contents'}}>
      <img src={logoblack} style={{height:40,width:40}} alt="Grey Logo"  />
    </Link>
  );
});

LogoVertical.propTypes = {
  sx: PropTypes.object,
  disabledLink: PropTypes.bool,
};

export default LogoVertical;
