import AccordionGrid from 'src/controls/accordionGrid';
import PrintDocumentLayout from 'src/controls/printdocumentlayout';

export default function ProfitandLoss() {
  let profitAndLoss = {
    head: 'Particulars',
    hasDiffCols:true,
    calCol:'balance',
    calList:['Net Profit'],
    showFooter:true
    
  };
  return (
    <>
      <PrintDocumentLayout>
        <AccordionGrid printLayout={profitAndLoss} />
      </PrintDocumentLayout>
    </>
  );
}

// firstColumn: [
//   {
//     heading: 'Expenses (Direct) (Direct Expenses)',
//     parent: 'Expense',
//     child: 'Direct Expenses',
//   },
//   {
//     heading: 'Expenses (Indirect) (Indirect Expenses)',
//     parent: 'Expense',
//     child: 'Indirect Expenses',
//   },
// ],
// secondColumn: [
//   {
//     heading: 'Sales Accounts',
//     parent: 'Income',
//     child: 'Sales Accounts',
//   },
//   {
//     heading: 'Income (Direct) (Direct Incomes)',
//     parent: 'Income',
//     child: 'Direct Incomes',
//   },
//   {
//     heading: 'Income (Indirect) (Indirect Incomes)',
//     parent: 'Income',
//     child: 'Indirect Incomes',
//   },
// ],
