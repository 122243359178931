import React from 'react';
import { Table, TableRow, TableBody, TableCell, TableHead, TableContainer, Link, } from '@mui/material';
import { useEffect, useState } from 'react';
import { numberCols } from 'src/data/data';
import { formatdecimals, toTitleCase } from 'src/data/scripts';
const subRowsPrintCols = ['BALANCE', 'CREDIT', 'DEBIT', 'NAME', 'OPENING', 'CLOSING'];
const colsWithWidth = ['Particulars', 'Name'];

function PrintTable({
  printData = [],
  showFooter = false,
  hasSubRows = false,
  headerExternal = [],
  normalPrint = true,
  calculationExternal
}) {
  const colsToBold = ['Particulars', 'Debit', 'Credit'];
  const dataToBold = ['Voucher'];
  const colsToHide = ['ID', 'ROW', 'Month', 'Subrows', 'Id', 'Parentid'];

  const dateFormattingCols = ['Date'];
  const calculationCols = ['Debit', 'Credit','Opening','Closing'];

  const [header, setHeader] = useState([]);
  const [bodyData, setBodyData] = useState([]);
  const [footer, setFooter] = useState([]);

  useEffect(() => {
    if (printData.length > 0) {
      if (hasSubRows) {
        setHeader(headerExternal);
      } else {
        setHeader(Object.keys(printData[0]));
      }
      setBodyData(printData);
    }
  }, [printData]);

  return (
    <TableContainer
      sx={{
        height: 'auto !important',
        width: '100%',
        my: 0,
        py: 0,
        overflow: 'visible',

        '& tbody, & table': {
          width: '100%',
          height: 'auto !important',
        },
      }}
    >
      <Table size="small" stickyHeader>
        <TableHead
          sx={{
            '& .MuiTableCell-head': {
              backgroundImage: 'none',
              color: 'black',
              borderTop: '.3px  solid rgb(224, 224, 224)',
              borderBottom: '.3px  solid rgb(224, 224, 224)',
              // p:'0px 70px 0px 0px',
              backgroundColor: 'white',
            },
          }}
        >
          <TableRow>
            {header?.length > 0 &&
              header.map((cell, index) => (
                <>
                  {!colsToHide.includes(cell) && (
                    <TableCell
                      sx={{
                        fontSize: '11px',
                        fontFamily: 'Arial',
                        display: hasSubRows && index == 1 && 'block',

                        p: hasSubRows
                          ? '0px 0px'
                          : !numberCols.includes(cell)
                          ? '0px 50px 0px 0px'
                          : '0px 0px 0px 30px',
                        fontWeight: 'bold',
                        textAlign:
                          (numberCols.includes(cell) || (hasSubRows && index == 1)) &&
                          'right',
                          width:(hasSubRows && index == 1 ) && '200px !important'
                         // width:colsWithWidth.includes(cell) ? '280px !important': "40px !important",
                        //width:colsWithWidth.includes(cell) ? '200px !important': "auto",
                          // (hasSubRows && index != 0 && !normalPrint)
                          //   ? '50px !important'
                          //   : colsWithWidth.includes(cell) ? '200px !important': "auto",
                      }}
                    >
                      {cell}
                    </TableCell>
                  )}
                </>
              ))}
          </TableRow>
        </TableHead>

        {hasSubRows ? (
          normalPrint ? (
            bodyData.length > 0 &&
            bodyData.map((data, index) => (
              <TableSubRows data={data} paddingTop={index == 1 ? true : false} />
            ))
          ) : (
            <TableSubRows data={bodyData} />
          )
        ) : (
          <TableBody>
            {bodyData?.length > 0 &&
              bodyData.map((row, index) => {
                let rowArray = [];
                let colArray = [];
                for (const cell in row) {
                  if (dateFormattingCols.includes(cell)) {
                    rowArray.push(row[cell] ? new Date(row[cell]).toLocaleDateString('en-GB') : '');
                    colArray.push(cell);
                  } else if (!colsToHide.includes(cell)) {
                    rowArray.push(row[cell]);
                    colArray.push(cell);
                  }
                }

              

                return (
                  <TableRow key={index}>
                    {rowArray.map((cell, cellIndex) => (
                      <TableCell
                        sx={{
                          fontSize: '11px',
                          fontFamily: 'Arial',
                          p: !numberCols.includes(colArray[cellIndex])
                            ? '0px 50px 0px 20px'
                            : '0px 0px 0px 30px',
                          verticalAlign: 'top !important',
                          width:colsWithWidth.includes(colArray[cellIndex]) && '200px !important',
                          padding: numberCols.includes(colArray[cellIndex]) && '0px',
                          fontWeight: dataToBold.includes(colArray[cellIndex]) ? 'bold' : '400',
                          textAlign: numberCols.includes(colArray[cellIndex]) && 'right',
                        }}
                      >
                        
                        {((numberCols.includes(colArray[cellIndex]) && cell) || cell === 0)
                          ? formatdecimals(cell)
                          : cell}
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })}
          </TableBody>
        )}
        <TableRow>
          {showFooter &&
            header?.length > 0 &&
            header.map((cell, index) => (
              <>
                {!colsToHide.includes(cell) && (
                  <TableCell
                    sx={{
                      fontSize: '11px',
                      borderTop: '.3px  solid rgb(224, 224, 224)',
                      borderBottom: '.3px  solid rgb(224, 224, 224)',
                      fontFamily: 'Arial',

                      p: normalPrint ? '0px' : (!numberCols.includes(cell))
                        ? '0px 50px 0px 0px'
                        : '0px 0px 0px 30px',
                      fontWeight: 'bold' ,
                      mt:'10px',
                      textAlign: (numberCols.includes(cell) || index == 1) && 'right',
                    }}
                  >


                    {calculationExternal ? isNaN(calculationExternal[index]) ? calculationExternal[index] : formatdecimals(calculationExternal[index])  : calculationCols.includes(cell) &&
                      formatdecimals(
                        bodyData
                          .map((row) => (row[cell] ?? row[cell?.toLowerCase()]?? 0))
                          .reduce((acc, curr) => (acc += parseFloat(curr?.toString().replaceAll('Dr', '')
                          .replaceAll('Cr', '')
                          .replaceAll(',', '')
                          .replaceAll(' ', '')  || 0)), 0)
                      )}
                  </TableCell>
                )}
              </>
            ))}
        </TableRow>
      </Table>
    </TableContainer>
  );
}

export default PrintTable;

function TableSubRows({ data, depth = 1, paddingTop = false }) {
//  console.log(data)
  return (
    <TableBody
      sx={{
        '& *': {
          width: '100%',
        },
        pt: 1,
      }}
    >
      <TableRowPrint data={data} depth={depth} paddingTop={paddingTop} />
    </TableBody>
  );
}

function TableRowPrint({ data, depth = 1, paddingTop = false }) {
  return (
    <>
      {data?.length > 0 &&
        data.map((row, index) => {
          let rowArray = [];
          let colArray = [];

          let subRowComponent = null;

          for (const cell in row) {
            if (cell == 'subRows') {
              subRowComponent = <TableRowPrint data={row[cell]} depth={depth + 1} />;
            }
            if (subRowsPrintCols.includes(cell.toUpperCase())) {
              rowArray.push(row[cell]);
              colArray.push(cell);
            }
          }

          return (
            <>
              <TableRow key={index}>
                {rowArray.map((cell, cellIndex) => (
                  <TableCell
                    sx={{
                      fontSize: '11px',
                      whiteSpace: 'nowrap',
                      //width:colsWithWidth.includes(colArray[cellIndex]) ? '280px !important': "40px !important",
                      // width:
                      //   index != 0
                      //     ? '50px !important'
                      //     : colsWithWidth.includes(cell) && '200px !important',
                      fontFamily: 'Arial',
                      textAlign:
                        (!isNaN(cell?.toString()?.replaceAll(',', '')) ||
                          numberCols.includes(toTitleCase(colArray[cellIndex]))) &&
                        'right',
                      fontWeight: depth == 1 ? 'bold' : '400',
                      p: 0,
                      pt: depth == 1 && (index != 0 || paddingTop) && '10px',
                      pl: colsWithWidth.includes(colArray[cellIndex]) && depth == 2 ? '15px' : depth == 3 ? '20px' : depth == 4  ? '25px': depth == 5? '30px': '10px',
                    }}
                  >
                    {(!isNaN(cell?.toString()?.replaceAll(',', '')) || cell == 0)
                      ? formatdecimals(cell)
                      : cell}
                  </TableCell>
                ))}
              </TableRow>
              {subRowComponent && subRowComponent}
            </>
          );
        })}{' '}
    </>
  );
}
