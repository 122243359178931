import { Box, Checkbox, FormControlLabel, FormGroup, Grid, InputAdornment, Paper, Typography } from "@mui/material";
import { useContext } from "react";

// eslint-disable-next-line import/no-unresolved
import { CardPanel } from "src/controls/cardpanel";
import { DropDownGREYERP } from "src/controls/dropdown";
import { TextBoxGREYERP } from "src/controls/textbox";
import NavHorizontal from "src/layouts/dashboard/nav/NavHorizontal";

import { DropzoneGREY } from "src/controls/dropzone";
import UppyFilePicker from 'src/controls/uppyFilePicker';
export default function Retention()
{ 
    return(
        <>

      <CardPanel heading="Retention Details" cardItem={<>
      
<Picker type='date' name="Date" required={true} focus={true}></Picker>
                        
<DropDownGREYERP items={`employees/name`} name="Employee" required={true} />
<DropDownGREYERP items={`salarycomponents/name`} name="SalaryComponent" required={true} />

<TextBoxGREYERP placeholder="20" name="Amount" required={true} type="number" step="any" />
<TextBoxGREYERP placeholder="Internal Notes" name="Comments" multiline={true} MultiLine={true} />                 

</>} />
<UppyFilePicker/>
   </>


  )

 
}
