import { Box, styled, useTheme } from '@mui/material';
import { fData, fNumber } from 'src/utils/formatNumber';
import Chart, { useChart } from '../components/chart';
import Tile from 'src/controls/tile';

const series = [44, 55, 13, 43];
const series2 = [
  {
    name: 'Net Profit',
    data: [44, 55, 57, 56, 61, 58, 63, 60, 66, 85, 99, 74],
  },
];

export default function ChartGREYERP({ type, data, name, type2 = false, dataStructure }) {
  const theme = useTheme();

  const CHART_HEIGHT = 400;

  const LEGEND_HEIGHT = 72;

  const StyledChart = styled('div')(({ theme }) => ({
    height: CHART_HEIGHT,
    marginTop: theme.spacing(5),
    '& .apexcharts-canvas svg': {
      height: CHART_HEIGHT,
    },
    '& .apexcharts-canvas svg,.apexcharts-canvas foreignObject': {
      overflow: 'visible',
    },
    '& .apexcharts-legend': {
      height: LEGEND_HEIGHT,
      alignContent: 'center',
      position: 'relative !important',
      borderTop: `solid 1px ${theme.palette.divider}`,
      top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`,
    },
  }));

  let chartOptions = {
    series: [],
    chart: {
      type: type,
    },
  };
  let seriesData = [];

  if ((type == 'pie' || type == 'donut') && data) {
    let series = [];
    if (Array.isArray(data)) {
      let keys = Object.keys(data[0]);
      data?.map((item) => {
        let array = {
          label: item[keys[0]] ?? '',
          value: item[keys[1]] ?? '',
        };
        series.push(array);
      });
    }
    // else{
    //   // Object.keys(data)?.length > 0 &&  Object.entries(data).map((item, index) => {
    //   //   let array = {
    //   //     label: item[1].Count,
    //   //     value: item[1].Type,
    //   //   };
    //   //   series.push(array);
    //   // });
    // }
    //console.log(series)
    seriesData = series.map((i) => i.value);

    chartOptions = useChart({
      chart: {
        height: '400px',
        sparkline: {
          enabled: true,
        },
      },
      colors:
        name == 'Unit Occupancy'
          ? [
              theme.palette.primary.main,
              theme.palette.error.main,
              theme.palette.info.main,

              theme.palette.warning.main,
            ]
          : [
              theme.palette.primary.main,
              theme.palette.info.main,
              theme.palette.error.main,
              theme.palette.warning.main,
            ],
      labels: series.map((i) => i.label),
      stroke: {
        colors: [theme.palette.background.paper],
      },
      legend: {
        floating: true,
        horizontalAlign: 'center',
      },
      dataLabels: {
        enabled: true,
        dropShadow: { enabled: false },
      },
      tooltip: {
        fillSeriesColor: false,
        y: {
          formatter: (value) => fNumber(value),
          title: {
            formatter: (seriesName) => `${seriesName}`,
          },
        },
      },
      plotOptions: {
        pie: { donut: { labels: { show: false } } },
      },
    });
  } else if (type == 'line' && data && Object.keys(data)?.length > 0) {
    let total = Object.entries(data).map((item, index) => item[1].Total);
    let Date = Object.entries(data).map((item, index) => item[1].Date);
    let series = [
      {
        data: [{ name: name, data: total }],
      },
    ];

    seriesData = series[0].data;

    chartOptions = useChart({
      xaxis: {
        categories: Date,
      },
    });
  } else if (type == 'bar' && type2 && data) {
    var categories = data.map((item) => item[Object.keys(data[0])[0]]);

    var columnsName = Object.keys(data[0]);

    columnsName = columnsName.filter((item) => {
      if (item == columnsName[0]) {
        return false;
      }
      return true;
    });

    var result1 = data.map(function (a) {
      return isNaN(a[columnsName[0]])
        ? parseFloat(a[columnsName[0]]?.replaceAll(',', ''))
        : parseFloat(a[columnsName[0]]);
    });
    var result2 =
      columnsName[1] &&
      data?.map(function (a) {
        return isNaN(a[columnsName[1]])
          ? parseFloat(a[columnsName[1]]?.replaceAll(',', ''))
          : parseFloat(a[columnsName[1]]);
      });
    let series = [
      {
        data: [
          {
            name: columnsName[0],
            data: result1,
          },
          {
            ...(columnsName[1] && {
              name: columnsName[1],
              data: result2,
            }),
          },
        ],
      },
    ];

    seriesData = series;
    chartOptions = useChart({
      colors: [theme.palette.primary.main, theme.palette.warning.main],
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent'],
      },

      xaxis: {
        categories: categories,
      },

      tooltip: {
        y: {
          formatter: (value) => `${value}`,
        },
      },
    });
  } else if (type == 'bar' && data && Object.keys(data)?.length > 0) {
    var columnsName = Object.keys(data);

    let series = [
      {
        data: [
          {
            name: 'columnsName[0]',
            data: [data[columnsName[0]]],
          },
          {
            name: columnsName[1],
            data: [data[columnsName[1]]],
          },
        ],
      },
    ];

    seriesData = series;

    chartOptions = useChart({
      chart: {
        stacked: true,
      },
      colors: [
        theme.palette.error.main,
        theme.palette.primary.main,

        theme.palette.warning.main,
        theme.palette.text.disabled,
      ],
      stroke: {
        width: 0,
      },
      xaxis: {
        categories: [`${name}`],
      },
      tooltip: {
        y: {
          formatter: (value) => {
            return fData(value);
          },
        },
      },

      plotOptions: {
        bar: {
          borderRadius: 8,
          columnWidth: '20%',
        },
      },
    });
  }
  return (
    <>
      {(type == 'pie' || type == 'donut') && (
        <StyledChart
          sx={{
            '& .apexcharts-legend': {
              top: '-50px !important',
              maxHeight: '300px !important',
            },
          }}
        >
          <Chart
            type={type}
            series={seriesData}
            options={chartOptions}
            width={'100%'}
            height={280}
          />
        </StyledChart>
      )}

      {type == 'line' && (
        <Box sx={{ mt: 3, mx: 3 }} dir="ltr">
          <Chart type="line" series={seriesData} options={chartOptions} height={364} />
        </Box>
      )}

      {type == 'bar' &&
        seriesData.map((item) => (
          <Box sx={{ mt: 3, mx: 3 }} dir="ltr">
            <Chart type="bar" series={item.data} options={chartOptions} height={364} />
          </Box>
        ))}
    </>
  );
}
