import { useState } from 'react';
import { Link, Stack, Alert, IconButton, Button, InputAdornment, TextField } from '@mui/material';
import Iconify from '../../components/iconify';
import { useNavigate } from 'react-router';
import { LoadingButton } from '@mui/lab';
import cryptoJs from 'crypto-js';
import { modules } from 'src/data/data';
import { HttpRequest } from 'src/data/scripts';
import { useTranslation } from 'react-i18next';
// ----------------------------------------------------------------------

export default function AuthLoginForm() {
  const { t } = useTranslation();
  const secret = 'appsgreyerpcom';
  const [showPassword, setShowPassword] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();

  const navigate = useNavigate();
  //'LEAVE-BLOCK-LISTS',

  const [loading, setLoading] = useState(false);

  let navConfig2 = [];
  let addedModule;

  const onSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    HttpRequest(`user/login`, 'POST', {
      username: username,
      password: password,
    })
      .then((response) => response)
      .then((userDetails) => {
        //if (Object.keys(userDetails).length < 1) {
        if (userDetails.isAuthenticated) {
          const { Rights, Modules } = userDetails.result[0];
          //will revise the logic
          const ModulesList = Modules.split(',');
          const RightsList = Rights.split(',');
          let dynamicRoutes = '';

          ModulesList.forEach((module) => {
            let moduleExist = modules.filter(
              (moduleObj) => moduleObj.module.toUpperCase() == module.toUpperCase()
            );
            if (moduleExist.length > 0) {
              let moduleChildList = moduleExist[0]?.child;
              if (moduleChildList) {
                RightsList.forEach((route) => {
                  const RouteName = route.split('/')[0];
                  let right = RouteName.includes('-')
                    ? RouteName.split('-')[0] +
                      '-' +
                      RouteName.split('-')[1] +
                      (RouteName?.split('-')[3] ? `-${RouteName.split('-')[3]}` : '')
                    : RouteName;

                  // const RouteNamePlural = RouteName.includes('-')  ? right : `${right}s`;

                  if (
                    moduleChildList
                      .map((a) => {
                        return a.toLowerCase();
                      })
                      .includes(RouteName.toLowerCase())
                  ) {
                    let path = '';

                    if (route.includes('new')) {
                      path = `/${module?.toLowerCase()}/${right}/new`;
                    } else if (route.includes('edit')) {
                      path = `/${module?.toLowerCase()}/${right}/edit`;
                    } else if (route.includes('dashboard')) {
                      path = `/${module?.toLowerCase()}/dashboard`;
                    } else {
                      path = `/${module?.toLowerCase()}/${right}`;
                    }

                    dynamicRoutes += `,${path}`;
                  }
                });
              }
            }
          });

          let encryptedStringRights = cryptoJs.AES.encrypt(
            dynamicRoutes,
            'appsgreyerprights'
          ).toString();
          localStorage.setItem('rights', JSON.stringify(encryptedStringRights));
          let encryptedStringUserRights = cryptoJs.AES.encrypt(
            userDetails.result[0].Rights,
            'userRights'
          ).toString();
          localStorage.setItem('userRights', JSON.stringify(encryptedStringUserRights));

          for (let right of RightsList) {
            for (let index = 0; index < modules.length; index++) {
              for (let index2 = 0; index2 < modules[index].child.length; index2++) {
                if (right.toUpperCase() == modules[index].child[index2].toUpperCase()) {
                  if (navConfig2.length < 1) {
                    let right3 = right.toUpperCase().includes('DASHBOARD')
                      ? right.split('-')[1]
                      : right;
                    navConfig2.push({
                      subheader: modules[index].module,
                      items: [
                        {
                          title: modules[index].module,
                          path: `/${modules[index].module}`,
                          children: [
                            {
                              title: right.replace(/-/g, ' '),
                              path: `/${modules[index].module}/${right3}`,
                              child: true,
                            },
                          ],
                        },
                      ],
                    });

                    addedModule += `${modules[index].module},`;
                  } else if (addedModule.includes(modules[index].module)) {
                    let right3 = right.toUpperCase().includes('DASHBOARD')
                      ? right.split('-')[1]
                      : right;
                    for (let index3 = 0; index3 < navConfig2.length; index3++) {
                      if (navConfig2[index3].subheader == modules[index].module) {
                        navConfig2[index3].items[0].children.push({
                          title: right.replace(/-/g, ' '),
                          path: `/${modules[index].module}/${right3}`,
                          child: true,
                        });
                      }
                    }
                  } else {
                    let right3 = right.toUpperCase().includes('DASHBOARD')
                      ? right.split('-')[1]
                      : right;
                    ModulesList?.includes(modules[index].module) &&
                      navConfig2.push({
                        subheader: modules[index].module,
                        items: [
                          {
                            title: modules[index].module,
                            path: `/${modules[index].module}`,
                            children: [
                              {
                                title: right.replace(/-/g, ' '),
                                path: `/${modules[index].module}/${right3}`,
                                child: true,
                              },
                            ],
                          },
                        ],
                      });
                    addedModule += `${modules[index].module},`;
                  }
                }
              }
            }
          }

          localStorage.setItem('isAuthenticated', userDetails.isAuthenticated);
          let encryptedStringDate = cryptoJs.AES.encrypt(
            userDetails?.result[0]?.expiry,
            secret
          ).toString();
          localStorage.setItem('Expiry', encryptedStringDate);
          localStorage.setItem('username', userDetails.result[0].Username);
          localStorage.setItem('client', userDetails.result[0].Client);
          localStorage.setItem('companies', JSON.stringify(userDetails.result[0].Company));
          localStorage.setItem('navConfigERP', JSON.stringify(navConfig2));
          localStorage.setItem('decimals', userDetails.result[0].decimals);
          localStorage.setItem('firstdate', userDetails.result[0].firstdate);
          localStorage.setItem('lastdate', userDetails.result[0].lastdate);
          localStorage.setItem('clientpicture', userDetails.result[0].Picture);
          localStorage.getItem('requestedPath') && localStorage.getItem('requestedPath') != '/'
            ? navigate(`/${localStorage.getItem('requestedPath')}`)
            : navigate('/');
          setLoading(false);
        } else if (userDetails.isAuthenticated == false) {
          setShowError(true);
          setErrorMessage(userDetails.error);
          setLoading(false);
        } else {
          setLoading(false);
          navigate('/login');
        }
      });
  };

  return (
    <form onSubmit={onSubmit}>
      <Stack spacing={3}>
        {showError && <Alert severity="error">{errorMessage}</Alert>}

        <TextField
          fullWidth
          required
          focused
          name="username"
          label={t('Username')}
          placeholder="Enter Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />

        <TextField
          fullWidth
          required
          variant="outlined"
          focused
          placeholder="Enter Password"
          name="password"
          label={t('Password')}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>
      <Stack alignItems="flex-end" sx={{ my: 2 }}>
        <Link variant="body2" color="inherit" underline="always">
          {t('Forgot password')}?
        </Link>
      </Stack>
      <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={loading}>
        {t('Login')}
      </LoadingButton>
    </form>
  );
}
