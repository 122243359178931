import { Grid } from '@mui/material';
import React from 'react';
import useResponsive from 'src/hooks/useResponsive';

function ImageContainer(params) {
  const isDesktop = useResponsive('up', 'lg');
  return (
    <Grid
      container
      sx={{
        ml: !isDesktop && '0px',
        maxWidth: isDesktop ? '80% !important' : '100%',
        flexBasis: isDesktop ? '80% !important' : '100%',
      }}
      spacing={2}
      rowSpacing={1}
      columns={{ xs: 3, sm: 3, md: 4, lg: 4 }}
    >
      {params.children}
    </Grid>
  );
}

export default ImageContainer;
