import { DropDownGREYERP } from 'src/controls/dropdown';
import { TextBoxGREYERP } from 'src/controls/textbox';
import PopUpPanel from 'src/controls/popuppanel';
export default function WarehouseSections() {
  return (
    <>
    <PopUpPanel
    heading={'Add'}
    child={
      <>
           <TextBoxGREYERP name="Name"  span={2} placeholder="Section Name" required={true} />
            <DropDownGREYERP items="warehouse/name"  span={2} name="Warehouse" required={true} />
            <TextBoxGREYERP name="Location" multiline={true} span={2} placeholder="Section Location" /> 
            <TextBoxGREYERP name="Comments" multiline={true} span={2} placeholder="Comments (Optional)" type="Internal Notes" />
          </>
       }
       />
     </>
    );
    }
