import { DropDownGREYERP } from 'src/controls/dropdown';
import { TextBoxGREYERP } from 'src/controls/textbox';
import PopUpPanel from 'src/controls/popuppanel';
import UploadGREY from 'src/controls/uploadgrey';
import { SwithGREYERP } from 'src/controls/swithgrey';

export default function Picture() {

    return (
        <>
            <PopUpPanel
        heading={'Add'}
        child={
          <>
         <UploadGREY span={2}/>
                  <TextBoxGREYERP  name="Title" placeholder="Picture Title" MultiLine={true} focus={true}/>

                  <TextBoxGREYERP  name="TitleArabic" placeholder="اسم العميل" MultiLine={true} dir="rtl"/>
                          
            <DropDownGREYERP firstItem="0,Main" items={`videocategories/name`} name="Category"  required={true} />
          
            <TextBoxGREYERP  name="Sort" placeholder="Sort" type="Number" required={true} span={.5}/>
            <SwithGREYERP  name="Status" span={.5} />

           
            <TextBoxGREYERP multiline rows={3}
              name="Comments" 
              placeholder="Internal Notes"
              span={2}
            />
          </>
              
          
   }
   />
 </>
    )
}