import { CardPanel } from 'src/controls/cardpanel';
import Picker from 'src/controls/picker';
import { DropDownGREYERP } from 'src/controls/dropdown';
import { TextBoxGREYERP } from 'src/controls/textbox';

export default function AssetCapitalization() {
  
  return (
    <>
    <CardPanel
      heading="Item Details"
      cardItem={
        <>
            <Picker type='date' name="Date" focus={true} date={new Date()} required={true}></Picker>
           <DropDownGREYERP span={2} required={true} items={'items/name'} name="Item" />
           <DropDownGREYERP items={`locations/name`} label="Target Asset Location" name="Location" required={true} />
           <DropDownGREYERP items={`costcenters/name`}  name="CostCenter" />
            <DropDownGREYERP items={`Projects/name`}  name="Project" />
            <TextBoxGREYERP  placeholder="Description" multiline={true}  span={2} name="Description" />
          <TextBoxGREYERP  placeholder="Internal Notes" multiline={true}  span={2} name="Comments" />
        </>
      }
    />
   </>
  );
}
