import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { CardPanel } from 'src/controls/cardpanel';
import { Charts } from 'src/data/data';
import Tile from 'src/controls/tile';
import { HttpRequest } from 'src/data/scripts';
import { useTranslation } from 'react-i18next';

function Dashboard() {
  const [dashboardCharts, setDashboardCharts] = useState();
  const module = window.location.pathname.split('/')[1];
  useEffect(() => {
    DynamicDashboardGeneration();
  }, [module]);
  const { t } = useTranslation();

  async function DynamicDashboardGeneration() {
    let chartsResponse = await HttpRequest(
      `getRecords/customreports/${localStorage.getItem('username')}/${
        window.location.pathname.split('/')[1]
      }-${window.location.pathname.split('/')[2]}`
    );

    if (chartsResponse.length > 0) {
      let chartComponents = [];
      let Comp;
      chartsResponse[0].map((chartRes, index) => {
        if (chartRes.Type == 'tile') {
          Comp = (
            <Grid item md={chartRes.Width} key={`tile1-${index}`}>
              <Tile
                link={chartRes.Link}
                bgColor={chartRes.BgColor}
                title={chartRes.Title}
                icon={chartRes.Icon}
                iconWidth={40}
                prefix={chartRes.Data[0].Prefix}
                total={chartRes.Data[0].Total}
                suffix={chartRes.Data[0].Suffix}
              />
            </Grid>
          );
          chartComponents.push(Comp);
        } else {
          Charts.map((chart, innerIndex) => {
            if (chart.Type == chartRes.Type) {
              Comp = (
                <Grid item md={chartRes.Width} key={`tile2-${index}-${innerIndex}`}>
                  <CardPanel
                    heading={t(chartRes.Title)}
                    // subHeading={`Total ${chartRes.Data.map((obj) => obj[Object.keys(obj)[1]]).reduce(
                    //   (acc, curr) => (acc += curr)
                    // )}`}
                    cardItem={
                      <Grid item md={12} key={`tile3-${index}`}>
                        {chart.Component(chartRes)}
                      </Grid>
                    }
                  ></CardPanel>
                </Grid>
              );
              chartComponents.push(Comp);
            }
          });
        }
      });

      setDashboardCharts(chartComponents);
    }
  }

  return (
    <>
      <Grid container direction="row" sx={{ marginTop: '0px' }} spacing={2}>
        {dashboardCharts?.map((chart) => chart)}
      </Grid>
    </>
  );
}

export default Dashboard;
