import { alpha, styled } from '@mui/material/styles';
import {
  Card,
  Grid,
  Table,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  Popover,
  Tooltip,
  IconButton,
} from '@mui/material';
import { useContext, useEffect, useRef, useState } from 'react';
import React from 'react';
import { FormContext } from 'src/layouts/dashboard/DashboardLayout';
import { HttpRequest, amountInWords, formatdecimals, toTitleCase } from 'src/data/scripts';
import { useReactToPrint } from 'react-to-print';
import Iconify from 'src/components/iconify/Iconify';

const StyledRowResult = styled(TableRow)(({ theme }) => ({
  '& td': {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));

export default function PrintTenantAgreement() {
  const [data, setData] = useState();
  const [tenantData, setTenantData] = useState();
  const { openPrint, setOpenPrint, recordId } = useContext(FormContext);
  const componentRef = useRef();

  useEffect(() => {
    GetData();
    console.log(tenantData);
  }, []);

  async function GetData() {
    let tenantData = await HttpRequest(`getRecordById/tenancyagreement/${recordId}`);
    setTenantData((prev) => {
      return { ...prev, ...tenantData[0] };
    });

    let Layout = await HttpRequest(
      `getvalue/clients/tenancyagreementlayout/${localStorage.getItem('client')}`
    );
    let documentLayout = await HttpRequest(
      `getRecordById/documentlayouts/${Layout[0].tenancyagreementlayout}`
    );
    setData((prev) => {
      return { ...prev, ...documentLayout[0] };
    });
  }

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <>
      <Popover
        sx={{
          backgroundColor: alpha('#212B36', 0.4),

          '& table p': {
            fontSize: '12px',
          },
          '& table td': {
            paddingBottom: '0',
          },
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={openPrint}
      >
        <Card ref={componentRef} sx={{ pt: 5, px: 3, width: '850px', '& img': { width: '100%' } }}>
          <Grid container alignItems={'center'}>
            <Grid item sx={{ ml: 'auto', displayPrint: 'none' }}>
              <Tooltip title="Print">
                <IconButton onClick={handlePrint}>
                  <Iconify icon="eva:printer-fill" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Close">
                <IconButton onClick={(e) => setOpenPrint(false)}>
                  <Iconify icon="material-symbols:close" />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
          <Grid container>
            <div
              className="ql-editor"
              style={{ width: '100%' }}
              dangerouslySetInnerHTML={{ __html: data?.Header }}
            />
          </Grid>

          <Grid container justifyContent={'center'}>
            <Typography variant="h4">TENANCY AGREEMENT</Typography>
          </Grid>

          <TableContainer sx={{ overflow: 'unset' }}>
            <Table>
              <TableBody>
                <StyledRowResult>
                  <TableCell colSpan={6}>
                    <Typography sx={{ color: 'red' }}>Agreement # {tenantData?.ID}</Typography>
                  </TableCell>
                </StyledRowResult>
                <StyledRowResult>
                  <TableCell colSpan={3}>
                    <Typography
                      sx={{ textDecoration: 'underline', marginTop: '10px' }}
                      variant="h6"
                    >
                      Landlord Details (First Party):
                    </Typography>
                  </TableCell>
                  <TableCell align="right" colSpan={3}>
                    <Typography sx={{ textDecoration: 'underline' }} variant="h6">
                      :تفاصيل المالك (الطرف الأول)
                    </Typography>
                  </TableCell>
                </StyledRowResult>
                <StyledRowResult>
                  <TableCell width={140}>
                    <Typography variant="body2">Name</Typography>
                  </TableCell>
                  <TableCell
                    align="left"
                    colSpan={4}
                    sx={{
                      typography: 'subtitle1',
                      borderBottom: 'dotted 1px',
                    }}
                  >
                    <Typography>{tenantData?.OwnerName}</Typography>
                  </TableCell>
                  <TableCell align="right" width={140}>
                    <Typography variant="body2">اسم</Typography>
                  </TableCell>
                </StyledRowResult>
                <StyledRowResult>
                  <TableCell width={140}>
                    <Typography variant="body2">CR No.</Typography>
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      typography: 'subtitle1',
                      borderBottom: 'dotted 1px',
                    }}
                  >
                    <Typography>{tenantData?.CRNO}</Typography>
                  </TableCell>
                  <TableCell align="right" width={140}>
                    <Typography variant="body2">رقم السجل التجاري</Typography>
                  </TableCell>

                  <TableCell width={140}>
                    <Typography variant="body2">Telephone No.</Typography>
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      typography: 'subtitle1',
                      borderBottom: 'dotted 1px',
                    }}
                  >
                    <Typography>{tenantData?.OwnerPhone}</Typography>
                  </TableCell>
                  <TableCell align="right" width={200}>
                    <Typography variant="body2">رقم الهاتف</Typography>
                  </TableCell>
                </StyledRowResult>
                <StyledRowResult>
                  <TableCell colSpan={3}>
                    <Typography
                      sx={{ textDecoration: 'underline', marginTop: '10px' }}
                      variant="h6"
                    >
                      Tenant Details (Second Party):
                    </Typography>
                  </TableCell>
                  <TableCell align="right" colSpan={3}>
                    <Typography sx={{ textDecoration: 'underline' }} variant="h6">
                      :تفاصيل المستأجر (الطرف الثاني)
                    </Typography>
                  </TableCell>
                </StyledRowResult>
                <StyledRowResult>
                  <TableCell width={140}>
                    <Typography variant="body2">Name</Typography>
                  </TableCell>
                  <TableCell
                    colSpan={4}
                    align="left"
                    sx={{
                      typography: 'subtitle1',
                      borderBottom: 'dotted 1px',
                    }}
                  >
                    <Typography>{tenantData?.Name}</Typography>
                  </TableCell>
                  <TableCell align="right" width={140}>
                    <Typography variant="body2">اسم</Typography>
                  </TableCell>
                </StyledRowResult>
                <StyledRowResult>
                  <TableCell width={140}>
                    <Typography variant="body2">Residend Card No.</Typography>
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      typography: 'subtitle1',
                      borderBottom: 'dotted 1px',
                    }}
                  >
                    <Typography>{tenantData?.IDNO}</Typography>
                  </TableCell>
                  <TableCell align="right" width={250}>
                    <Typography variant="body2">رقم بطاقة الإقامة</Typography>
                  </TableCell>

                  <TableCell width={140}>
                    <Typography variant="body2">Telephone No.</Typography>
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      typography: 'subtitle1',
                      borderBottom: 'dotted 1px',
                    }}
                  >
                    <Typography>{tenantData?.Phone}</Typography>
                  </TableCell>
                  <TableCell align="right" width={160}>
                    <Typography variant="body2">رقم الهاتف</Typography>
                  </TableCell>
                </StyledRowResult>
                <StyledRowResult>
                  <TableCell width={160}>
                    <Typography variant="body2">Permanent Address</Typography>
                  </TableCell>
                  <TableCell
                    colSpan={4}
                    align="left"
                    sx={{
                      typography: 'subtitle1',
                      borderBottom: 'dotted 1px',
                    }}
                  >
                    <Typography>{tenantData?.PreviousAddress}</Typography>
                  </TableCell>
                  <TableCell align="right" width={160}>
                    <Typography variant="body2">العنوان الدائم</Typography>
                  </TableCell>
                </StyledRowResult>
                <StyledRowResult>
                  <TableCell colSpan={3}>
                    <Typography
                      sx={{ textDecoration: 'underline', marginTop: '10px' }}
                      variant="h6"
                    >
                      Property Details:
                    </Typography>
                  </TableCell>

                  <TableCell align="right" colSpan={3}>
                    <Typography sx={{ textDecoration: 'underline' }} variant="h6">
                      :تفاصيل اوضح
                    </Typography>
                  </TableCell>
                </StyledRowResult>
                <StyledRowResult>
                  <TableCell width={140}>
                    <Typography variant="body2">Flat No.</Typography>
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      typography: 'subtitle1',
                      borderBottom: 'dotted 1px',
                    }}
                  >
                    <Typography>{tenantData?.Unit}</Typography>
                  </TableCell>
                  <TableCell align="right" width={140}>
                    <Typography variant="body2">شقة / غرفة رقم </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2">Building Name</Typography>
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      typography: 'subtitle1',
                      borderBottom: 'dotted 1px',
                    }}
                  >
                    <Typography variant="body2" width={150}>
                      {tenantData?.Building}
                    </Typography>
                  </TableCell>
                  <TableCell align="right" width={140}>
                    <Typography variant="body2">اسم المبنى</Typography>
                  </TableCell>
                </StyledRowResult>
                <StyledRowResult>
                  <TableCell width={140}>
                    <Typography variant="body2">Area</Typography>
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      typography: 'subtitle1',
                      borderBottom: 'dotted 1px',
                    }}
                  >
                    <Typography>{tenantData?.Area}</Typography>
                  </TableCell>
                  <TableCell align="right" width={140}>
                    <Typography variant="body2">منطقة</Typography>
                  </TableCell>
                  <TableCell width={140}>
                    <Typography variant="body2">Building No.</Typography>
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      typography: 'subtitle1',
                      borderBottom: 'dotted 1px',
                    }}
                  >
                    <Typography>{tenantData?.BuildingNo}</Typography>
                  </TableCell>
                  <TableCell align="right" width={140}>
                    <Typography variant="body2">رقم المبنى</Typography>
                  </TableCell>
                </StyledRowResult>
                <StyledRowResult>
                  <TableCell width={140}>
                    <Typography variant="body2">Electricity Meter No.</Typography>
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      typography: 'subtitle1',
                      borderBottom: 'dotted 1px',
                    }}
                  >
                    <Typography>{tenantData?.ElectricityMeter}</Typography>
                  </TableCell>
                  <TableCell align="right" width={140}>
                    <Typography variant="body2">رقم عداد الكهرباء</Typography>
                  </TableCell>
                  <TableCell width={140}>
                    <Typography variant="body2">Water Meter No.</Typography>
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      typography: 'subtitle1',
                      borderBottom: 'dotted 1px',
                    }}
                  >
                    <Typography>{tenantData?.WaterMeter}</Typography>
                  </TableCell>
                  <TableCell align="right" width={140}>
                    <Typography variant="body2">رقم عداد المياه</Typography>
                  </TableCell>
                </StyledRowResult>
                <StyledRowResult>
                  <TableCell colSpan={3}>
                    <Typography
                      sx={{ textDecoration: 'underline', marginTop: '10px' }}
                      variant="h6"
                    >
                      Contract Period & Rental Value
                    </Typography>
                  </TableCell>
                  <TableCell align="right" colSpan={3}>
                    <Typography sx={{ textDecoration: 'underline' }} variant="h6">
                      مدة العقد وقيمة الإيجار
                    </Typography>
                  </TableCell>
                </StyledRowResult>
                <StyledRowResult>
                  <TableCell width={140}>
                    <Typography variant="body2">Valid for period of</Typography>
                  </TableCell>
                  <TableCell
                    colSpan={4}
                    align="left"
                    sx={{
                      typography: 'subtitle1',
                      borderBottom: 'dotted 1px',
                    }}
                  >
                    <Typography>{tenantData?.Months} Months</Typography>
                  </TableCell>
                  <TableCell align="right" width={140}>
                    <Typography variant="body2">تسري هذه الاتفاقية لمدة </Typography>
                  </TableCell>
                </StyledRowResult>
                <StyledRowResult>
                  <TableCell width={140}>
                    <Typography variant="body2">Contract Period </Typography>
                  </TableCell>
                  <TableCell
                    colSpan={4}
                    align="left"
                    sx={{
                      typography: 'subtitle1',
                      borderBottom: 'dotted 1px',
                    }}
                  >
                    <Typography>
                      {tenantData?.ContractFrom} - {tenantData?.ContractTo}
                    </Typography>
                  </TableCell>
                  <TableCell align="right" width={140}>
                    <Typography variant="body2">فترات العقد</Typography>
                  </TableCell>
                </StyledRowResult>
                <StyledRowResult>
                  <TableCell width={140}>
                    <Typography variant="body2">Monthly Rent: OMR</Typography>
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      typography: 'subtitle1',
                      borderBottom: 'dotted 1px',
                    }}
                  >
                    <Typography>{tenantData?.Rent}</Typography>
                  </TableCell>

                  <TableCell align="right" width={140}>
                    <Typography variant="body2"> رسوم الإيجار الشهري</Typography>
                  </TableCell>

                  <TableCell width={280}>
                    <Typography variant="body2">Total Contract Value: OMR</Typography>
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      typography: 'subtitle1',
                      borderBottom: 'dotted 1px',
                    }}
                  >
                    <Typography>{tenantData?.TotalRent}</Typography>
                  </TableCell>
                  <TableCell align="right" width={140}>
                    <Typography variant="body2"> إجمالي قيمة العقد</Typography>
                  </TableCell>
                </StyledRowResult>

                <StyledRowResult>
                  <TableCell width={280}>
                    <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                      Tenant Signature
                    </Typography>
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      typography: 'subtitle1',
                      borderBottom: 'dotted 1px',
                    }}
                  >
                    <Typography sx={{ fontWeight: 'bold' }}></Typography>
                  </TableCell>
                  <TableCell align="right" width={140}>
                    <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                      {' '}
                      توقيع المستأجر
                    </Typography>
                  </TableCell>
                  <TableCell width={280}>
                    <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                      Landlord Signature
                    </Typography>
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      typography: 'subtitle1',
                      borderBottom: 'dotted 1px',
                    }}
                  >
                    <Typography></Typography>
                  </TableCell>
                  <TableCell align="right" width={140}>
                    <Typography sx={{ fontWeight: 'bold' }} variant="body2">
                      {' '}
                      توقيع المالك
                    </Typography>
                  </TableCell>
                </StyledRowResult>
              </TableBody>
            </Table>
          </TableContainer>

          <div className="ql-editor" dangerouslySetInnerHTML={{ __html: data?.Bottom }} />
          <div className="ql-editor" dangerouslySetInnerHTML={{ __html: data?.Footer }} />
        </Card>
      </Popover>
    </>
  );
}
