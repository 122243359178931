import { useContext, useEffect, useState } from 'react';
import { CardPanel } from 'src/controls/cardpanel';
import { DropDownGREYERP } from 'src/controls/dropdown';
import { TextBoxGREYERP } from 'src/controls/textbox';
import { ItemsComponent } from 'src/controls/items/ItemsComponent';
import { SwithGREYERP } from 'src/controls/swithgrey';
import Picker from 'src/controls/picker';
import { FormContext } from 'src/layouts/dashboard/DashboardLayout';
import { GetAndSetList, GetAndSetValue, GetVoucherNumber, HandleOnChangeValue, getClientName } from 'src/data/scripts';
import { useQuery } from '@tanstack/react-query';
import { useFormData } from 'src/hooks/useQueryHooks';
import { useLocation } from 'react-router';
import { KanbanDetailsPrioritizes } from 'src/sections/@dashboard/kanban';

export default function JobCard() {
  let formID = window.location.pathname?.split('/')[2]?.replaceAll('-', '');
let ID = window.location.pathname?.split('/')[3]?.replaceAll('-', '') ?? popupEditId;
const {setFormData:setObject, formData:object} = useFormData();
const {data:objectEdit}= useQuery({ queryKey:[formID, ID], select:(res)=>res[0], enabled: (Boolean(formID) && !!ID) })

const {  setExistingItemsData, setFormObject,   formObject } = useContext(FormContext);
const { Series = '', Number = '',  BillOfMaterial,Item } = object || {};
const { Series: EditSeries = '', Number: EditNumber = '' } = objectEdit || {};
const { ItemList = [] } = formObject || {};
const { state } = useLocation();
const [assignee, setAssignee] = useState();

useEffect(() => {
  if (state) {
    HandleOnChangeValue(
      {
        ...state,
      },
      setObject
    );
  }
}, [state]);
useEffect(() => {
  if (isNaN(window.location.pathname.split('/')[3])) {
    GetVoucherNumber(getClientName(), setObject)
  }
}, []);


  return (
    <>

<div id="divFields" className="divFields">
      <CardPanel
        heading='Document Details'
        cardItem={
          <>
            <Picker type='date' name="Date" focus={true} date={new Date()} required={true}></Picker>
            <DropDownGREYERP items={`workorders/name`} name="WorkOrder"    />
            <DropDownGREYERP span={2} required={true} items={'items/name'}  name="Item" label="Item To Manufacture" />
            <TextBoxGREYERP name="Quantity" label="Quantity To Manufacture" type="Number" required={true} placeholder="1"  />
            <TextBoxGREYERP defaultValue={Series ? Series + Number : EditSeries + EditNumber} disabled required={true} name="Number" placeholder="Number" />
            <TextBoxGREYERP name="Reference" placeholder="Reference" />
            <Picker type='date' name="StartDate"  ></Picker>
            <Picker type='date' name="EndDate"  ></Picker>
            <TextBoxGREYERP  name="Duration" sufix='mins'  type="Number" step="any" />
            <DropDownGREYERP items={'clientusers/name'} span={2} name="Assignee" multiple={true} />
            <DropDownGREYERP items={`operations/name`} name="Operation"    />
            <DropDownGREYERP items={`workcenters/name`} name="WorkCenter"    />
            <DropDownGREYERP items={`warehouses/name`}  name="Warehouse" />
            <DropDownGREYERP items={`projects/name`}  name="Project" />
            <TextBoxGREYERP name="Description" multiline={true} placeholder="Description" span={2} />
            <TextBoxGREYERP name="Comments" multiline={true} placeholder="Internal Notes" span={2} />
          </>
        }
      />
    </div>
          <div id="divRawMaterials" className="divItems">
            <ItemsComponent
              heading={'Raw Materials'}
              tablename={'jobcardrawmaterials'}
              formType={'purchasable'}
              disableCalculation={true}
              columnsToShow={{
                Item: 0,
                Description: '',
                Quantity: 1,
                Warehouse: 0,
               
              }}
              columnsToAppend={{
                Item: 0,
                Description: '',
                Quantity: 1,
                Warehouse: 0,
              }}
            />
      </div>
       
    </>
  );
}
