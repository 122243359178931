import { memo, useContext, useEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  IconButton,
  ListItemIcon,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/CustomBreadcrumbs';
import { hideScrollbarY } from '../../../utils/cssStyles';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { FormContext, uppyImage } from 'src/layouts/dashboard/DashboardLayout';
import Iconify from 'src/components/iconify/Iconify';
import {
  FormatDate,
  GetAndSetList,
  GetVoucherNumber,
  HttpRequest,
  SelectPaymentMode,
  toTitleCase,
} from 'src/data/scripts';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { LoadingButton } from '@mui/lab';
import { SyncExternalProviderData } from 'src/pages/crm/syncexternalprovider';
import { useSnackbar } from 'notistack';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  excludedFieldsClone,
  printBtnForms,
  importForms,
  rowForms,
  voucherNumberGeneratorFormClone,
  addNewExcludedForms,
  FormPaymentMode,
} from 'src/data/data';
import cryptoJs from 'crypto-js';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useFormData } from 'src/hooks/useQueryHooks';
import { useTranslation } from 'react-i18next';
import { sentenceCase } from 'change-case';

function NavSectionHorizontal({ name }) {
  const {
    popupItems,
    submitForm,
    setOpenDrawer,
    gridTableInstance,
    popupEditId,
    firstRowInfo,
    activeEditRow,
    setTableName,
    loading,
    loadingNew,
    setLoading,
    setLoadingNew,
    setDataFetched,
    setItems,
    openExternalProviderPopup,
    setOpenImportPrint,
    firstRowVisibility,
    setFirstRowVisibility,
    setdisplayPrint,
    setPlusClicked,
    cloneObject,
    setCloneObject,
    setResetFields,
    setExistingItemsData,
    setImage,
    setSections,
    setListSections,
    setFormObject,
    printRef,
    clientId,
    altMode,
  } = useContext(FormContext);
  const { t } = useTranslation();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const navigate = useNavigate();

  let form = window.location.pathname?.split('/')[2]?.replaceAll('-', '');
  let ID = window.location.pathname?.split('/')[3]?.replaceAll('-', '') ?? popupEditId;

  let location = useLocation();
  const { resetFormData } = useFormData();
  useEffect(() => {
    return () => {
      resetFormData();
    };
  }, [location.pathname]);

  useEffect(() => {
    // resetFormData()
    setResetFields(true);
  }, [clientId]);

  const Action = window.location.pathname.split('/')[3]?.toLowerCase();
  const Form = window.location.pathname.split('/')[2]?.toLowerCase();
  const Module = window.location.pathname.split('/')[1]?.toLowerCase();
  let ecryptedRights = localStorage.getItem('userRights')
    ? JSON.parse(localStorage.getItem('userRights'))
    : '';
  const bytes = cryptoJs.AES?.decrypt(ecryptedRights, 'userRights');
  const rights = bytes?.toString(cryptoJs.enc.Utf8);

  //States
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentRecord, setCurrentRecord] = useState(0);
  const [popupForm, setPopupForm] = useState(false);
  const [normalForm, setNormalForm] = useState(false);
  const [createdDate, setCreatedDate] = useState();
  const [updatedDate, setUpdatedDate] = useState();
  const [open, setOpen] = useState(false);

  const anchorRef = useRef(null);

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  // const handlePrint = useReactToPrint({
  //   content: () => printRef.current,
  //   pageStyle: '@page { marginTop: 40px !important;marginBottom: 40px !important; }',
  // });

  const { data: objectEdit, error } = useQuery({
    queryKey: [`${form}`, `${ID}`],
    queryFn: async () => {
      if (!isNaN(ID) && ID != '' && ID !== null) {
        return await HttpRequest(`getRecordById/${form}/${ID}`);
      } else {
        return [{}];
      }
    },
    enabled: Boolean(!isNaN(ID) && ID != '' && ID !== null && Boolean(form)),
    select: (result) => result[0],
  });

  const { data: currentRecordData, error: currentRecordError } = useQuery({
    queryKey: [`getCurrentRecord`, `${form}`, `${ID}`],
    queryFn: async () =>
      isNaN(ID)
        ? [{}]
        : await HttpRequest(`getCurrentRecord/${form}/${localStorage.getItem('username')}/${ID}`),
    enabled: Boolean(!isNaN(ID) && ID !== null),
  });

  const { data: totalRecordData, error: totalRecordError } = useQuery({
    queryKey: [`gettotalRecords`, `${form}`, `${ID}`],
    queryFn: async () =>
      isNaN(ID)
        ? [{}]
        : await HttpRequest(`gettotalRecords/${form}/${localStorage.getItem('username')}`),
    enabled: Boolean(!isNaN(ID) && ID !== null),
  });

  const handleAfterDelete = () => {
    enqueueSnackbar('Record Deleted');
    setDataFetched(false);
    navigate(`/${Module}/${Form}`);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  async function GetData() {
    // if (cloneObject) {
    //   let objectCloneRes = await HttpRequestGet(
    //     `getRecordById/${window.location.pathname?.split('/')[2].replaceAll('-', '')}/${cloneObject}`
    //   );
    // // console.log("objectCloneRes",objectCloneRes);
    //   let objectClone = { ...objectCloneRes[0] };
    //   setObjectEdit({ ...objectClone });
    //   excludedFieldsClone.forEach((field) => {
    //     delete objectClone[field];
    //   });
    //   for (const key in objectClone) {
    //     if (!objectClone[key]) {
    //       delete objectClone[key];
    //     }
    //   }

    //   voucherNumberGeneratorFormClone.forEach(async (form) => {
    //     if (form.form == Form) {
    //       let VNRes = await HttpRequestGet(
    //         `getRecordById/${form.provider}s/${objectClone[form.field]}`
    //       );

    //       GetVoucherNumber(
    //         VNRes[0][form.column],
    //         setObject
    //       );
    //     }
    //   });

    //   setObject(prev=>{return{...prev, ...objectClone }});
    // }

    if (!isNaN(ID) && ID != '' && ID !== null) {
      totalRecordData && setTotalRecords(totalRecordData[0].count);
      currentRecordData && setCurrentRecord(currentRecordData[0].count);
    }
  }

  useEffect(() => {
    setPlusClicked(false);
    // resetFormData()
    GetData();

    if (popupItems?.includes(Form?.toLowerCase())) {
      setPopupForm(true);
      if (Action == 'new') {
        setOpenDrawer(true);
      }
    } else {
      setPopupForm(false);
    }

    return () => {
      setResetFields(true);
      setImage({});
      setItems({});
      setSections({});
      // setListSections([]);
      setExistingItemsData();
      setLoading(false);
      setLoadingNew(false);
      setFormObject({});
      setCloneObject(undefined);
      // resetFormData()
    };
  }, [Module, Form, Action, name, ID, cloneObject, currentRecordData, totalRecordData]);

  useEffect(() => {
    if (
      !rowForms.includes(Form?.toLowerCase()) &&
      !popupItems.includes(Form?.toLowerCase()) &&
      (Action == 'new' || (!isNaN(ID) && ID != '' && ID !== null))
    ) {
      setNormalForm(true);
    } else {
      setNormalForm(false);
    }
  }, [Action, Module, Form, ID]);

  function openPopup() {
    if (rowForms.includes(Form?.toLowerCase())) {
      gridTableInstance?.toggleAllPageRowsSelected(false);
      gridTableInstance?.setEditingRow(firstRowInfo);
      setFirstRowVisibility(true);
      setPlusClicked(true);
      setTableName(Form);
    } else if (popupItems.includes(Form?.toLowerCase())) {
      setOpenDrawer(true);
    } else {
      navigate(`/${Module?.toLowerCase()}/${Form?.toLowerCase()}/new`);
      /*   function myMessage() {
        window.location.href = `/${Module?.toLowerCase()}/${Form?.toLowerCase()}/new`;   
      }
      setTimeout(myMessage, 1000);*/
      // window.location.href = `/${Module?.toLowerCase()}/${Form?.toLowerCase()}/new`;
    }
  }

  useEffect(() => {
    if (objectEdit && Object.keys(objectEdit)?.length > 0) {
      FormatDate(objectEdit?.CreatedDate, setCreatedDate);
      FormatDate(objectEdit?.UpdatedDate, setUpdatedDate);

      if (FormPaymentMode.includes(Form?.toUpperCase())) {
        SelectPaymentMode(objectEdit['Mode'], setFormObject);
      }
    }
  }, [objectEdit]);
  const SaveForm = Action == 'new' && !popupForm && !rowForms.includes(Form?.toLowerCase());
  return (
    <Stack
      direction="row"
      spacing={1}
      justifyContent="space-between"
      alignItems="center"
      sx={{
        ...hideScrollbarY,
        width: '100%',
        textTransform: 'capitalize',
        zIndex: '5',
        displayPrint: 'none',
      }}
    >
      <CustomBreadcrumbs
        heading={
          (document.title = toTitleCase(
            Action
              ? Action === 'new'
                ? `${t('Add')} ${t(
                    sentenceCase(
                      window.location.pathname?.split('/')[2]?.slice(0, -1)?.replaceAll('-', ' ')
                    )
                  )}`
                : `${t('Edit')} ${t(
                    sentenceCase(
                      window.location.pathname?.split('/')[2]?.slice(0, -1)?.replaceAll('-', ' ')
                    )
                  )}`
              : Form
                ? Form?.replaceAll('-', ' ')
                : ''
          ))
        }
        links={[
          {
            name: `${toTitleCase(Module)}`,
            href: `/${Module}/dashboard`,
          },
          {
            name: `${toTitleCase(Form?.replaceAll('-', ' '))}`,
            href: `/${Module}/${Form}`,
            disabled: !(Action || Action === 'new') && true,
          },
          {
            name: `${Action ? (!isNaN(Action) ? 'Edit' : toTitleCase(Action)) : 'List'}`,
            disabled: true,
          },
        ]}
        sx={{
          mt: '16px !important',
          ml: '16px !important',
          mb: '16px !important',
        }}
      />

      {SaveForm || (!isNaN(ID) && ID != '' && ID !== null && !popupForm) ? (
        <Stack direction="row" alignItems="center">
          {!isNaN(ID) && ID != '' && ID !== null && !popupForm && (
            <>
              <Typography variant="caption" sx={{ color: 'text.secondary', mr: 1 }}>
                {objectEdit?.CreatedBy} {createdDate ?? ''}
              </Typography>
              <Typography variant="caption" sx={{ color: 'text.secondary', mr: 1 }}>
                {objectEdit?.UpdatedBy} {updatedDate ?? ''}
              </Typography>

              <IconButton
                onClick={async () => {
                  let response = await HttpRequest(
                    `getPreviousRecord/${window.location.pathname
                      ?.split('/')[2]
                      ?.replaceAll(' ', '')
                      .replaceAll('-', '')}/${localStorage.getItem('username')}/${ID}`
                  );
                  navigate(`/${Module}/${Form}/${response[0].ID}`);
                }}
              >
                <Iconify icon="eva:arrow-ios-back-fill" />
              </IconButton>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {currentRecord} of {totalRecords}
              </Typography>

              <IconButton
                sx={{ mr: 0 }}
                onClick={async () => {
                  let response = await HttpRequest(
                    `getNextRecord/${window.location.pathname
                      ?.split('/')[2]
                      ?.replaceAll(' ', '')
                      .replaceAll('-', '')}/${localStorage.getItem('username')}/${ID}`
                  );
                  navigate(`/${Module}/${Form}/${response[0].ID}`);
                }}
              >
                <Iconify icon="eva:arrow-ios-forward-fill" />
              </IconButton>
              <IconButton sx={{ mr: 2 }} ref={anchorRef} onClick={handleToggle}>
                <MoreVertIcon />
              </IconButton>

              <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                placement="bottom-start"
                transition
                sx={{
                  zIndex: 20,
                  '& .MuiPaper-root': {
                    p: '0px',
                  },
                  mt: '10px !important',
                  boxShadow:
                    '0 0 2px 0 rgba(145, 158, 171, 0.24), -20px 20px 40px -4px rgba(145, 158, 171, 0.24) !important',
                  borderRadius: '12px !important',
                }}
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom',
                    }}
                  >
                    <Paper sx={{ px: 1, py: 1 }}>
                      <ClickAwayListener onClickAway={handleClose}>
                        <MenuList
                          autoFocusItem={open}
                          id="composition-menu"
                          aria-labelledby="composition-button"
                          sx={{ py: '5px' }}
                        >
                          <MenuItem
                            key={'success'}
                            disabled={rights.includes(Form + '/delete') ? false : true}
                            onClick={(e) => {
                              enqueueSnackbar('Do you want to proceed?', {
                                variant: 'warning',
                                autoHideDuration: 2000,
                                action: (key) => (
                                  <>
                                    <Button
                                      color="error"
                                      variant="contained"
                                      sx={{ mr: 1 }}
                                      size="small"
                                      onClick={async () => {
                                        // Perform the delete operation
                                        closeSnackbar(key);
                                        await HttpRequest(
                                          `${Form?.replaceAll('-', '')}/${ID}`,
                                          'DELETE'
                                        ).then(handleAfterDelete);
                                      }}
                                    >
                                      Delete
                                    </Button>

                                    <Button
                                      color="inherit"
                                      variant="contained"
                                      sx={{ mr: 1 }}
                                      size="small"
                                      onClick={() => {
                                        closeSnackbar(key);
                                      }}
                                    >
                                      {t('Cancel')}
                                    </Button>
                                  </>
                                ),
                              });
                            }}
                          >
                            <ListItemIcon sx={{ m: 0, p: 0 }}>
                              <Iconify sx={{ color: '#FF5630' }} icon="eva:trash-2-outline" />
                            </ListItemIcon>
                            Delete
                          </MenuItem>
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </>
          )}

          <LoadingButton
            accessKey="s"
            variant="contained"
            type="submit"
            sx={{ textTransform: 'none', mr: 1, textUnderlineOffset: 2.4 }}
            onClick={submitForm}
            disabled={
              Action == 'new'
                ? rights.includes(Form + '/new')
                  ? loadingNew
                  : true
                : rights.includes(Form + '/edit')
                  ? loadingNew
                  : true
            }
            loading={loading}
          >
            {' '}
            {t('Save')}
          </LoadingButton>

          <LoadingButton
            accessKey="a"
            variant="contained"
            onClick={submitForm}
            sx={{
              mr: 1,
              textTransform: 'none',
              textUnderlineOffset: 2.5,
              display: { xs: 'none', md: 'block' },
            }}
            disabled={
              Action == 'new'
                ? rights.includes(Form + '/new')
                  ? loadingNew
                  : true
                : rights.includes(Form + '/edit')
                  ? loadingNew
                  : true
            }
          >
            {t('Save & Add')}
          </LoadingButton>
          <Button
            accessKey="c"
            variant="contained"
            color="inherit"
            sx={{ textTransform: 'none', textUnderlineOffset: 2.5 }}
            onClick={() => {
              setTableName(`/${Form}/${Action}`);
              navigate(`/${Module?.toLowerCase()}/${Form?.toLowerCase()}`);
            }}
          >
            {t('Cancel')}
          </Button>
        </Stack>
      ) : printBtnForms.includes(Form.toLocaleLowerCase()) ? (
        <Button
          sx={{
            displayPrint: 'none',
            mr: 1,
            textTransform: 'none',
            textUnderlineOffset: 2.5,
            display: { xs: 'none', md: 'block' },
          }}
          accessKey="p"
          variant="contained"
          // onClick={() => {

          //   setTimeout(() => {
          //     if(printRef?.current){
          //         handlePrint()
          //     }else{
          //       window.print();
          //     }
          //   }, 1000);

          //     setTimeout(() => {
          //       setdisplayPrint(false);
          //       //gridTableInstance?.toggleAllRowsExpanded(false);
          //     }, 5000);

          //  // gridTableInstance?.toggleAllRowsExpanded(true);
          //   setdisplayPrint(true);
          // }}
          onClick={() => {
            window.print();
          }}
        >
          <u style={{ textDecorationThickness: '1.3px' }}>P</u>rint
        </Button>
      ) : !(Module == 'utility') &&
        !(Module == 'reports') &&
        !addNewExcludedForms.includes(Form.toLocaleLowerCase()) &&
        normalForm == false &&
        !(SaveForm || (!isNaN(ID) && ID != '' && ID !== null)) ? (
        <>
          <ButtonGroup
            variant="contained"
            disabled={rights.includes(Form + '/new') ? false : true}
            color="primary"
            ref={anchorRef}
            aria-label="split button"
          >
            <Button
              accessKey="a"
              sx={{ textTransform: 'none', textUnderlineOffset: 2.5 }}
              disabled={firstRowVisibility || activeEditRow ? true : false}
              onClick={() => {
                openPopup();
              }}
            >
              {t('Add New')}
            </Button>

            {importForms.includes(Form?.toUpperCase()) && (
              <Button sx={{ p: 0 }} onClick={handleToggle}>
                <ArrowDropDownIcon />
              </Button>
            )}
          </ButtonGroup>
          <Popper
            sx={{
              zIndex: 1,
              '& .MuiPaper-root': {
                p: '0px',
              },
              mt: '10px !important',
              boxShadow:
                '0 0 2px 0 rgba(145, 158, 171, 0.24), -20px 20px 40px -4px rgba(145, 158, 171, 0.24) !important',
              borderRadius: '12px !important',
            }}
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            placement="bottom"
            transition
            disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList id="split-button-menu" autoFocusItem>
                      <MenuItem onClick={(event) => setOpenImportPrint(true)}>
                        {' '}
                        <ListItemIcon sx={{ m: 0 }}>
                          <FileUploadIcon />
                        </ListItemIcon>
                        Import Record
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </>
      ) : null}

      {openExternalProviderPopup && <SyncExternalProviderData />}
    </Stack>
  );
}

export default memo(NavSectionHorizontal);
