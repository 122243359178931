import { CardPanel } from 'src/controls/cardpanel';
import { TextBoxGREYERP } from 'src/controls/textbox';
import { ItemsComponent } from 'src/controls/items/ItemsComponent';

export default function Route() {
  return (
    <>

<div id="divFields" className="divFields">
      <CardPanel
        heading='Route Details'
        cardItem={
          <>
          
            <TextBoxGREYERP name="Name" required={true} placeholder="Route 1" />
            <TextBoxGREYERP name="Reference" placeholder="Reference" />
            <TextBoxGREYERP name="Description" multiline={true} placeholder="Description" span={2} />
            <TextBoxGREYERP name="Comments" multiline={true} placeholder="Internal Notes" span={2} />
          </>
        }
      />
    </div>
        <div id="divOperations" className="divItems">
        <ItemsComponent
          heading={'Operations'}
          tablename={'routedetails'}
          disableQuantity={true}
          disableCalculation={true}
          columnsToShow={{
            Operation: 0,
            WorkCenter: 0,
            Duration: 1,
            Description: '',
          }}
          columnsToAppend={{
            Operation: 0,
            WorkCenter: 0,
            Duration: 1,
            Description: '',
          }}
        />
      </div>
       
    </>
  );
}
