import PropTypes from 'prop-types';
// @mui
import { Box, Button } from '@mui/material';

//
import ShopProductCard from './ShopProductCard';

// ----------------------------------------------------------------------

ShopProductList.propTypes = {
  loading: PropTypes.bool,
  products: PropTypes.array,
};

export default function ShopProductList({
  products,
  setSelectedProducts,
  selectedProducts,
  loading,
  ...other
}) {
  function AddProduct(productId) {
   
    let existingProduct = selectedProducts.filter((item)=>item.id == productId);
    if(existingProduct.length > 0){
      
     let updatedSelectedProducts = selectedProducts.map((item)=>{
      if(item.id == productId){
        item['quantity'] +=1;
        item['subtotal'] = item.quantity * item.price;
        return item;
      }
      return item;
     });


      setSelectedProducts(updatedSelectedProducts);
    }else{
      let product = products.filter((item)=>item.id == productId);
      product[0]['quantity'] = 1;
      product[0]['subtotal'] = 1 * product[0].price;

      if(product[0].available >= product[0].quantity){
        setSelectedProducts(prev=>[...prev,product[0]]);
      }
     
    
    }
   
  }

  return (
    <Box
      gap={.6}
      display="grid"
      gridTemplateColumns={{
        xs: 'repeat(1, 1fr)',
        sm: 'repeat(2, 1fr)',
        md: 'repeat(3, 1fr)',
        lg: 'repeat(5, 1fr)',
      }}
   
      sx={{height:"calc(100vh - 280px)",overflowY:'auto'}}
    >
      {(loading ? [...Array(12)] : products).map((product, index) =>
        product ? (
          <Button onClick={(e) => AddProduct(product.id)} sx={{m:0,p:0}}>
            <ShopProductCard key={product.id} product={product} />
          </Button>
        ) : (
          <></>
        )
      )}
    </Box>
  );
}
