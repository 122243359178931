import { useEffect, useRef, useState } from 'react';
import { useNavigate, Outlet } from 'react-router-dom';
import { Box } from '@mui/material';
import { useSettingsContext } from '../../components/settings';
import Main from './Main';
import Header from './header';
import NavVertical from './nav/NavVertical';
import NavHorizontal from './nav/NavHorizontal';
import { createContext } from 'react';
import { useSnackbar } from 'notistack';
import { AddNewDropDown, MenuList, PopupItems, dbImagePath } from 'src/data/data';
import UppyFilePicker from 'src/controls/uppyFilePicker';
import { HttpRequest, HttpRequestImage, SelectPaymentMode } from 'src/data/scripts';
import { useFormData } from 'src/hooks/useQueryHooks';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { number } from 'prop-types';

export let uppyImage = [];
export const FormContext = createContext();

export default function DashboardLayout() {
  const { themeLayout } = useSettingsContext();
  const { enqueueSnackbar } = useSnackbar();
  const isNavHorizontal = themeLayout === 'horizontal';
  const navigate = useNavigate();
  let companies = JSON.parse(localStorage.getItem('companies') ?? []);
  const formRef = useRef();

  const urlList = window.location.pathname.split('/');
  const Module = urlList[1];
  const Form = urlList[2];

  //States
  const [openPrint, setOpenPrint] = useState(false);
  const [recordId, setRecordId] = useState();
  const [firstRowInfo, setFirstRowInfo] = useState();
  const [openExternalProviderPopup, setOpenExternalProviderPopup] = useState(false);
  const [plusClicked, setPlusClicked] = useState(false);
  const [cloneObject, setCloneObject] = useState();
  const [taskData, setTaskData] = useState({});
  const [open, setOpen] = useState(false);
  const [popupEditId, setPopupEditId] = useState();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [dataFetched, setDataFetched] = useState(false);
  const [mainFormRef, setMainFormRef] = useState();
  const [tbldata, setTbldata] = useState([]);
  const [tableName, setTableName] = useState('');
  const [Items, setItems] = useState({});
  const [firstRowVisibility, setFirstRowVisibility] = useState(false);
  const [activeEditRow, setActiveEditRow] = useState(false);
  const [gridTableInstance, setGridTableInstance] = useState();
  const [displayPrint, setdisplayPrint] = useState(false);
  const [clientId, setClientId] = useState(localStorage.getItem('client') ?? companies[0]?.ID);
  const [resetFields, setResetFields] = useState(false);
  const [helperText, setHelperText] = useState('');
  const [sections, setSections] = useState({});
  const [newPopup, setNewPopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingNew, setLoadingNew] = useState(false);
  const [image, setImage] = useState({});
  const [columnsGridImport, setColumnsGridImport] = useState();
  const [gridDataReserve, setGridDataReserve] = useState({});
  const [projectTasks, setProjectTasks] = useState({});
  const [openTaskPopup, setOpenTaskPopup] = useState(false);
  const [projectTasksExisting, setProjectTasksExisting] = useState([]);
  const [externalEmails, setExternalEmails] = useState({});
  const [calenderDate, setCalenderDate] = useState();
  const [openImportPrint, setOpenImportPrint] = useState(false);
  const [openHeader, setOpenHeader] = useState(false);
  const [editOnCell, setEditOnCell] = useState(false);
  const [popupItems, setPopupItems] = useState(PopupItems);
  const [dropDownPopUp, setDropDownPopUp] = useState(AddNewDropDown);
  const [swappedSections, setSwappedSections] = useState(false);
  const [listSections, setListSections] = useState([]);
  const [openNavPath, setOpenNavPath] = useState('');
  const [existingItemsData, setExistingItemsData] = useState([]);
  const [priceListItems, setpriceListItems] = useState([]);
  const [formObject, setFormObject] = useState({});
  const componentRef = useRef();
  const [printRef, setprintRef] = useState(componentRef);
  const [altMode, setAltMode] = useState(false);
  const [data, setData] = useState([])

  const { setFormData: setObject, resetFormData, updatedFormData } = useFormData();
  const object = updatedFormData();

  let form = window.location.pathname?.split('/')[2]?.replaceAll('-', '');
  let ID = window.location.pathname?.split('/')[3]?.replaceAll('-', '') ?? popupEditId;

  const { data: objectEdit } = useQuery({
    queryKey: [form, ID],
    select: (res) => res[0],
    enabled: Boolean(form),
  });

  const updateDB = async ({ FormName, URLParams, httpMethod, jsonBody }) => {
    return await HttpRequest(`${FormName}/${URLParams}`, httpMethod, jsonBody);
  };

  const queryClient = useQueryClient();

  let mutateData = useMutation({
    mutationFn: updateDB,
  });

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const renderNavVertical = <NavVertical openNav={open} onCloseNav={handleClose} />;
  let tasksForm = window.location?.pathname?.split('/')[2]?.toLowerCase() == 'tasks';

  const handleAfterSave = (ActionText, e, id) => {
    enqueueSnackbar(`Record Saved`);
    setResetFields(true);
    resetFormData;
    SelectPaymentMode('Cash', setObject);
    setDataFetched(false);
    setImage({});
    setHelperText('');
    setLoadingNew(false);
    setOpenDrawer(false);
    setLoading(false);
    setPopupEditId(null);
    setCloneObject(0);
    gridTableInstance?.toggleAllRowsExpanded(false);
    if (e?.target?.innerText.includes('&')) {
      navigate(`/${Module}/${Form}/New`);
    } else {
      navigate(`/${Module}/${Form}`);
    }
    //task can be written on tasks page
    if (tasksForm) {
      if (ActionText == 'Updated') {
        setTaskData((prev) => {
          let columns = prev.board.columns;
          let cards = prev.board.cards;
          let card = {
            id: id,
            name: object?.Name ?? objectEdit?.Name,
            description: object?.Description ?? objectEdit?.Description,
            assignee: [
              {
                id: '',
                avatar: '',
                name: object?.Assignee ?? objectEdit?.Assignee,
              },
            ],
            due: [object?.DueDateTime ?? objectEdit?.DueDateTime],
            attachments: [],
            comments: [],
            completed: object?.Status ?? objectEdit?.Status,
            Status: object?.Status ?? objectEdit?.Status,
            priority: object?.Priority ?? objectEdit?.Priority,
          };

          cards[`${id}`] = card;

          if (object?.Status) {
            for (const key in columns) {
              let cardIdsNewList = columns[key].cardIds.filter((idCard) => idCard != id);
              columns[key].cardIds = cardIdsNewList;
            }

            for (const key in columns) {
              if (columns[key].name == object['Status']) {
                columns[key].cardIds.push(id);
              }
            }
          }

          let user = localStorage.getItem('username');
          let colOrderinLS = JSON.parse(localStorage.getItem('tasksort'));
          colOrderinLS[user] = columns;
          localStorage.setItem('tasksort', JSON.stringify(colOrderinLS));
          return {
            ...prev,
            ['board']: {
              ['cards']: cards,
              ['columnOrder']: prev.board.columnOrder,
              ['columns']: columns,
            },
          };
        });
      } else {
        setTaskData((prev) => {
          let columns = prev.board.columns;
          let cards = prev.board.cards;
          let card = {
            id: id,
            name: object?.Name,
            description: object?.Description,
            assignee: [
              {
                id: '',
                avatar: '',
                name: object?.Assignee,
              },
            ],
            due: [object?.DueDateTime],
            attachments: [],
            comments: [],
            completed: object?.Status,
            Status: object?.Status,
            priority: object?.Priority,
          };

          cards[`${id}`] = card;
          for (const key in columns) {
            if (columns[key].name == object['Status']) {
              columns[key].cardIds.push(id);
            }
          }
          let user = localStorage.getItem('username');
          let colOrderinLS = JSON.parse(localStorage.getItem('tasksort'));
          colOrderinLS[user] = columns;
          localStorage.setItem('tasksort', JSON.stringify(colOrderinLS));
          return {
            ...prev,
            ['board']: {
              ['cards']: cards,
              ['columnOrder']: prev.board.columnOrder,
              ['columns']: columns,
            },
          };
        });
      }
    }
    return;
  };

  let imageForms = ['PRODUCTS'];

  // can be written on the jv form
  function JVFormCalculation(FormName, itemsGridExist) {
    let isValid = true;

    if (itemsGridExist && FormName == 'JOURNALVOUCHERS') {
      let journalVouchers = Items && Items[Object.keys(Items)[0]]?.items;

      if (journalVouchers) {
        let debit = journalVouchers
          .map((item) => parseFloat(item.Debit))
          .reduce((a, b) => a + b, 0);
        let credit = journalVouchers
          .map((item) => parseFloat(item.Credit))
          .reduce((a, b) => a + b, 0);
        if (parseFloat(debit) && parseFloat(credit)) {
          if (parseFloat(debit) != parseFloat(credit)) {
            setHelperText('Debit and Credit should be equal');
            return (isValid = false);
          }
        } else {
          setHelperText('Debit or Credit should have a value');
          return (isValid = false);
        }
      }
    }

    return isValid;
  }

  function SaveImages(id, FormName) {
    const updatedObject = updatedFormData();
    let objectKeys = Object.keys(updatedObject);
    let objHasFile = false;

    dbImagePath.forEach((item) => {
      if (objectKeys.includes(item)) {
        objHasFile = true;
      } else if (imageForms.includes(FormName)) {
        objHasFile = true;
      }
    });

    let imagesExist = (objHasFile || uppyImage.length > 0) && id;
    if (imagesExist) {
      let imagesDiffSourceList = [];
      Object.keys(image)?.length > 0 &&
        imagesDiffSourceList.push({
          type: 'image',
          body: Object.values(image),
        });
      uppyImage.length > 0 &&
        imagesDiffSourceList.push({
          type: 'uppy',
          body: uppyImage,
        });
      imagesDiffSourceList.forEach(async (source) => {
        source.body.forEach(async (img) => {
          const formData = new FormData();
          formData.set('uploadedImage', img);
          let response = await HttpRequestImage(
            `home/upload?table=${FormName}&id=${id}&user=${localStorage.getItem('username')}&type=${
              source.type
            }`,
            'POST',
            formData,
            `https://uploads.greyerp.com/`
          );
        });
      });
    }

    uppyImage = [];
  }

  async function SaveItemsGrid(id, FormName, itemsGridExist) {
    if (itemsGridExist && id) {
      let Action = window.location.pathname.split('/')[3];

      for (const key in Items) {
        Items[key]?.items?.forEach((item) => (item[FormName?.slice(0, -1)] = id));

        !isNaN(Action) && (await HttpRequest(`${key}/${Action}`, 'DELETE'));

        await HttpRequest(
          `${key}/${localStorage.getItem('client')}/${localStorage.getItem('username')}`,
          'POST',
          Items[key]
        );
      }
    }
  }

  function GetSectionOrder(object) {
    //Form Sections Related
    let formSectionsOrder = [];

    let formSections = document.getElementById('sectionorder')?.children;
    formSections = formSections ?? [];
    if (formSections.length > 0) {
      for (let i = 0; i < formSections.length; i++) {
        var classname = formSections[i].childNodes[0].className;
        if (classname.includes('div')) {
          formSectionsOrder.push(classname + i);
        }
      }
    }

    if (formSectionsOrder?.length > 0) {
      object['SectionsOrder'] = formSectionsOrder.join();
    }

    return formSectionsOrder;
  }

  async function SaveSections(id, FormName, formSections) {
    if (formSections?.length > 0 && id && sections) {
      let sectionsList = Object.values(sections);
      if (sections && sectionsList?.length > 0) {
        let Action = window.location.pathname.split('/')[3]?.toUpperCase();

        !isNaN(Action) &&
          (await HttpRequest(`${FormName?.slice(0, -1)}Sections/${Action}`), 'DELETE');

        let sectionObject = {};
        let formSections = document.getElementsByClassName('ql-editor');
        if (formSections.length > 0) {
          for (let i = 0; i < formSections.length; i++) {
            sectionObject[FormName?.slice(0, -1)] = id;
            sectionObject['Content'] = formSections[i].innerHTML.toString()?.replaceAll("'", '`');
            await HttpRequest(
              `${FormName?.slice(0, -1)}Sections/${localStorage.getItem(
                'client'
              )}/${localStorage.getItem('username')}`,
              'POST',
              sectionObject
            );
          }
        }
      }
    }
  }

  async function submitForm(e, rowValue, rowFormAction) {
    e && e.preventDefault();

    var formDataObject = {};
    var inputs = document.querySelectorAll('input, textarea');
    for (var key in inputs) {
      if (
        inputs[key].type === 'file' ||
        (inputs[key].value !== null &&
          inputs[key].name !== '' &&
          inputs[key].value !== undefined &&
          inputs[key].name.includes('[') === false)
      )
        if (
          inputs[key].type === 'textarea' ||
          inputs[key].type === 'url' ||
          inputs[key].type === 'email' ||
          inputs[key].type === 'number' ||
          inputs[key].type === 'color' ||
          (inputs[key].type === 'text' &&
            inputs[key].role === null &&
            inputs[key].placeholder !== 'DD/MM/YYYY')
        ) {
          if (inputs[key].name === 'Number') {
            const lastIndex = inputs[key].value.lastIndexOf('/');
            const Series = inputs[key].value.slice(0, lastIndex + 1);
            const Number = inputs[key].value.slice(lastIndex + 1);

            formDataObject['Series'] = Series;
            formDataObject['Number'] = Number;
          } else formDataObject[inputs[key].name] = inputs[key].value.replaceAll("'", '`');
        } else if (inputs[key].type === 'text' && inputs[key].placeholder === 'DD/MM/YYYY') {
          if (inputs[key].value !== '') {
            let [day, month, year] = inputs[key].value.split('/');
            formDataObject[inputs[key].name] = `${year}-${month}-${day}`;
          } else formDataObject[inputs[key].name] = '';
        } else if (inputs[key].type === 'file') {
          console.log(inputs[key].name);
          if (inputs[key].value !== '')
            formDataObject[inputs[key].name] = inputs[key].value.split('\\')[2];
          else if (inputs[key].attributes?.filename?.value)
            formDataObject[inputs[key].name] = inputs[key].attributes.filename.value;
        } else if (inputs[key].type === 'tel' && inputs[key].value != '+968')
          formDataObject[inputs[key].name] = inputs[key].value.replaceAll(' ', '');
        else if (inputs[key].type === 'text' && inputs[key].role === 'combobox') {
          formDataObject[inputs[key].name] = document
            .getElementsByName(inputs[key].name)[0]
            .parentElement.parentElement.parentElement.getAttribute('selectedValue');
          formDataObject[inputs[key].name] = document
            .getElementsByName(inputs[key].name)[0]
            .parentElement.parentElement.parentElement.getAttribute('selectedValue');
        } else if (inputs[key].type === 'checkbox' && inputs[key].value !== 'Print') {
          formDataObject[inputs[key].name] = document.getElementsByName(
            inputs[key].name
          )[0].checked;
        } else {
          console.log(inputs[key].name, inputs[key].type, inputs[key].value);
        }
    }
    inputs = document.getElementsByClassName('input-label');
    for (var key in inputs) {
      if (inputs[key].innerHTML !== undefined && inputs[key].id !== undefined)
        formDataObject[inputs[key].id] = inputs[key].innerHTML.replaceAll(',', '');
    }

    inputs = document.getElementsByClassName('ql-editor');
    for (var key in inputs) {
      if (!isNaN(key)) {
        if (inputs[key].innerHTML.replace(/<\/?[^>]+(>|$)/g, '') == '')
          formDataObject[inputs[key].parentElement.parentElement.id] = '';
        else {
          formDataObject[inputs[key].parentElement.parentElement.id] = inputs[
            key
          ].innerHTML.replaceAll("'", '`');
        }
      }
    }

    // console.log(formDataObject);
    if (openDrawer == true || formRef.current.reportValidity()) {
      if (!formDataObject) {
        navigate(`/${Module}/${Form}`);
        return;
      }
      e?.target?.innerText?.includes('&') ? setLoadingNew(true) : setLoading(true);
      let itemsGridExist = document.getElementById('itemsbox') != null;

      let Action = window.location.pathname.split('/')[3]?.toUpperCase();
      let FormName = window.location.pathname.split('/')[2]?.replaceAll('-', '')?.toUpperCase();
      let ID = window.location.pathname.split('/')[3];
      //JV Related
      let isValid = JVFormCalculation(FormName, itemsGridExist);

      if (!isValid) {
        setLoading(false);
        setLoadingNew(false);
        return;
      }

      let formSections = GetSectionOrder(object);
      let SAVE =
        (Action == 'NEW' && !newPopup) ||
        (openDrawer == true && !popupEditId) ||
        rowFormAction == 'SAVE';
      let httpMethod = SAVE ? 'POST' : 'PUT';

      let URLParams = SAVE
        ? `${localStorage.getItem('client')}/${localStorage.getItem('username')}`
        : `${popupEditId ? popupEditId : ID}/${localStorage.getItem(
            'username'
          )}/${localStorage.getItem('client')}/${window.location.pathname
            .split('/')[2]
            ?.replaceAll('-', '')}`;

      let jsonBody = rowFormAction ? rowValue : formDataObject;

      console.log(jsonBody);
      mutateData.mutate(
        { FormName, URLParams, httpMethod, jsonBody },
        {
          onSuccess: async (data, { FormName }) => {
            queryClient.invalidateQueries({
              queryKey: [
                FormName.toLowerCase(),
                localStorage.getItem('username'),
                localStorage.getItem('client'),
                0,
                50,
              ],
            });
            let id = data;
            console.log(id);

            if (!isNaN(id)) {
              SaveImages(id, FormName);
              SaveItemsGrid(id, FormName, itemsGridExist);
              await SaveSections(id, FormName, formSections);
            }
            let ActionText = SAVE ? 'Saved' : 'Updated';
            return !isNaN(id)
              ? handleAfterSave(ActionText, e, id)
              : (enqueueSnackbar(
                  id?.code == 'EREQUEST'
                    ? 'Something went wrong! Please try again'
                    : id?.length > 0 && id[0]?.Error
                      ? `${id[0]?.Error}`
                      : 'Something went wrong! Please try again',
                  { variant: 'error' }
                ),
                setLoading(false),
                setLoadingNew(false));
          },
        }
      );
    }
  }

  if (isNavHorizontal) {
    return (
      <>
        <FormContext.Provider
          value={{
            openNavPath,
            setOpenNavPath,
            object,
            Items,
            setItems,
            popupItems,
            setPopupItems,
            submitForm,
            popupEditId,
            setPopupEditId,
            objectEdit,
            openDrawer,
            setOpenDrawer,
            tableName,
            setTableName,
            dataFetched,
            setDataFetched,
            displayPrint,
            setdisplayPrint,
            clientId,
            setClientId,
            resetFields,
            setResetFields,
            firstRowVisibility,
            setFirstRowVisibility,
            openPrint,
            setOpenPrint,
            gridTableInstance,
            setGridTableInstance,
            firstRowInfo,
            setFirstRowInfo,
            recordId,
            setRecordId,
            activeEditRow,
            setActiveEditRow,
            plusClicked,
            setPlusClicked,
            helperText,
            setHelperText,
            sections,
            setSections,
            cloneObject,
            setCloneObject,
            newPopup,
            setNewPopup,
            loading,
            setLoading,
            loadingNew,
            setLoadingNew,
            image,
            setImage,
            columnsGridImport,
            setColumnsGridImport,
            data,
            setData,
            gridDataReserve,
            setGridDataReserve,
            projectTasks,
            setProjectTasks,
            openTaskPopup,
            setOpenTaskPopup,
            projectTasksExisting,
            setProjectTasksExisting,
            openExternalProviderPopup,
            setOpenExternalProviderPopup,
            externalEmails,
            setExternalEmails,
            calenderDate,
            setCalenderDate,
            openImportPrint,
            dropDownPopUp,
            setDropDownPopUp,
            taskData,
            setTaskData,
            setOpenImportPrint,
            setMainFormRef,
            openHeader,
            setOpenHeader,
            swappedSections,
            setSwappedSections,
            editOnCell,
            setEditOnCell,
            mainFormRef,
            tbldata,
            setTbldata,
            listSections,
            setListSections,
            existingItemsData,
            printRef,
            setExistingItemsData,
            priceListItems,
            setpriceListItems,
            formObject,
            setFormObject,
            altMode,
            setAltMode,
          }}
        >
          <Header onOpenNav={handleOpen} open={open} />
          {Module && <NavHorizontal />}
          <Main>
            <form id="frmMain" ref={formRef} encType={'multipart/form-data'}>
              <Outlet />
            </form>
          </Main>

          {(urlList[3]?.toUpperCase() == 'NEW' || !isNaN(urlList[3])) && <UppyFilePicker />}
          {renderNavVertical}
          <Box sx={{ height: '10px' }}></Box>
        </FormContext.Provider>
      </>
    );
  }
}
