import { alpha, styled } from '@mui/material/styles';
import {
  Box,
  Card,
  Grid,
  Table,
  Divider,
  TableRow,
  TableBody,
  TableHead,
  TableCell,
  Typography,
  TableContainer,
  Button,
  Popover,
  Stack,
  Tooltip,
  IconButton,
  Popper,
  Grow,
  ClickAwayListener,
  MenuList,
  MenuItem,
  ListItemIcon,
  Menu,
} from '@mui/material';

import { func } from 'prop-types';
import { useContext, useEffect, useStatem, useRef, useState } from 'react';

import { CardPanel } from 'src/controls/cardpanel';
import { DropDownGREYERP } from 'src/controls/dropdown';
import { TextBoxGREYERP } from 'src/controls/textbox';
import NavHorizontal from 'src/layouts/dashboard/nav/NavHorizontal';
import React, { Component } from 'react';
import { useTheme } from '@mui/material/styles';
import { DropDownENUM } from 'src/controls/dropdownenum';
import { ChequeStatus, Mode, PaymentMode } from 'src/data/data';
import Picker from 'src/controls/picker';
import ChequeDetails from 'src/controls/chequedetail';
import { FormContext } from 'src/layouts/dashboard/DashboardLayout';
import { HttpRequest, amountInWords, getTaxCategory, getVoucherNumber } from 'src/data/scripts';
import { default as DataGrid } from '../Grid';
import { useReactToPrint } from 'react-to-print';
import Iconify from 'src/components/iconify/Iconify';

const StyledRowResult = styled(TableRow)(({ theme }) => ({
  '& td': {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));
export default function PrintJournalVoucher() {
  const theme = useTheme();
  const [data, setData] = useState();
  const [dataDetail, setDataDetail] = useState();
  const [voucherNumber, setVoucherNumber] = useState('');
  const [accountSummaryData, setAccountSummaryData] = useState([]);
  const [pageHeader, setPageHeader] = useState();
  const [pageFooter, setPageFooter] = useState();
  const [pageHeading, setPageHeading] = useState();
  const [pageBottom, setPageBottom] = useState();
  const [pageColor, setPageColor] = useState();
  const [pageFont, setPageFont] = useState();
  const [pageFontSize, setpageFontSize] = useState();
  const [apiUrl, setapiUrl] = useState();
  const [totalParticulars, setTotalParticulars] = useState(0);
  const [debitAmount, setDebitAmount] = useState(0);
  const [creditAmount, setCreditAmont] = useState(0);

  const { object, setObject, objectEdit } = useContext(FormContext);
  const { openPrint, setOpenPrint, recordId, setRecordId } = useContext(FormContext);

  useEffect(() => {
    HttpRequest(
      `getItems/journalvouchersummary/name/${localStorage.getItem(
        'username'
      )}/${localStorage.getItem('client')}/${recordId}`
    )
      .then((response) => response)
      .then((json) => {
        // console.log(json)
        setData(json);
      });

    HttpRequest(
      `getItems/journalentrydetails/name/${localStorage.getItem('username')}/${localStorage.getItem(
        'client'
      )}/${recordId}`
    )
      .then((response) => response)
      .then((json) => {
        // console.log(json)
        setDataDetail(json);
        const debit = json.reduce((acc, obj) => acc + obj.Debit, 0);
        const credit = json.reduce((acc, obj) => acc + obj.Credit, 0);
        setTotalParticulars(json.length);
        setDebitAmount(debit);
        setCreditAmont(credit);
      });

    let documentlayoutsid = 0;

    HttpRequest(`getRecordById/clientdetails/${localStorage.getItem('client')}`)
      .then((response) => response)
      .then((json) => {
        documentlayoutsid = json[0].JournalVoucherLayout;

        HttpRequest(`getRecordById/documentlayouts/${documentlayoutsid}`)
          .then((response) => response)
          .then((json) => {
            setPageHeader(json[0].Header);
            setPageFooter(json[0].Footer);
            setPageHeading(json[0].Heading);
            setPageBottom(json[0].Bottom);
            setPageColor(json[0].color);
            setPageFont(json[0].font);
            setpageFontSize(json[0].fontSize);
          });
      });
  }, []);

  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <>
      <Popover
        sx={{ backgroundColor: alpha('#212B36', 0.4) }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={openPrint}
        // sx={{
        //   '.MuiPaper-root': { height: 'auto', top: '70px', right: '10px', borderRadius: '8px' },
        // }}
      >
        <Card ref={componentRef} sx={{ pt: 5, px: 3, width: '850px', height: '800px' }}>
          <Grid container alignItems={'center'}>
            <Grid item sx={{ ml: 'auto', displayPrint: 'none' }}>
              <Tooltip title="Print">
                <IconButton onClick={handlePrint}>
                  <Iconify icon="eva:printer-fill" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Close">
                <IconButton onClick={(e) => setOpenPrint(false)}>
                  <Iconify icon="material-symbols:close" />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
          <Grid container>
            <div
              className="ql-editor"
              style={{ width: '100%' }}
              dangerouslySetInnerHTML={{ __html: pageHeader }}
            />
          </Grid>

          <Grid container justifyContent={'center'}>
            <Typography variant="h4">
              {pageHeading &&
              (pageHeading == 'DOCUMENT NAME' || pageHeading == '<p>DOCUMENT NAME</p>') ? (
                'JOURNAL VOUCHER'
              ) : (
                <div
                  className="ql-editor"
                  style={{ width: '100%' }}
                  dangerouslySetInnerHTML={{ __html: pageHeading }}
                />
              )}
            </Typography>
          </Grid>
          <Grid container sx={{ pt: 3 }}>
            <Grid item xs={12} sm={6} sx={{}}>
              <Typography variant="body2" sx={{ fontWeight: 'bold', fontSize: '13px' }}>
                Voucher #{' '}
                <Typography variant="span" sx={{ fontWeight: '200' }}>
                  {data && data.Series != '' && data.Series}
                  {data && data.Number != '' && data.Number}
                </Typography>
              </Typography>
              {data && data.Reference != '' && data.Reference != null && (
                <Typography variant="body2" sx={{ fontWeight: 'bold', fontSize: '13px' }}>
                  Reference{' '}
                  <Typography variant="span" sx={{ fontWeight: '200' }}>
                    {data && data.Reference}
                  </Typography>
                </Typography>
              )}

              {/* {data && data.VATIN != '' && (
                <Typography variant="body2" sx={{ fontWeight: 'bold', fontSize: '13px' }}>
                VATIN{' '}
                  <Typography variant="span" sx={{ fontWeight: '200' }}>
                  {data && data.VATIN}
                  </Typography>
                </Typography>
                )}
               {data && data.TaxNumber != '' && (
                <Typography variant="body2" sx={{ fontWeight: 'bold', fontSize: '13px' }}>
                Tax Card #{' '}
                  <Typography variant="span" sx={{ fontWeight: '200' }}>
                  {data && data.TaxNumber}
                  </Typography>
                </Typography>
                )} */}
            </Grid>

            <Grid item xs={12} sm={6} sx={{ mb: 5 }}>
              <Typography variant="body2" sx={{ fontWeight: 'bold', fontSize: '13px' }}>
                Invoice Date{' '}
                <Typography variant="span" sx={{ fontWeight: '200' }}>
                  {data && data.Date}
                </Typography>
              </Typography>
            </Grid>
          </Grid>

          {/* //backgroundColor: `${pageColor}`            */}
          <TableContainer sx={{ overflow: 'unset', pt: 2 }}>
            {/* <Scrollbar> */}
            <Table sx={{ width: '100%' }}>
              <TableHead
                sx={{
                  borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
                  '& th': { backgroundColor: pageColor, color: 'white' },
                }}
              >
                <TableRow>
                  {/* {columns &&
                    columns.map((row, index) => ( */}
                  <TableCell align="left" sx={{ fontSize: '11px', pt: 0, pb: 0 }}>
                    Particulars
                    <br />
                    تفاصيل
                  </TableCell>
                  <TableCell align="right" sx={{ fontSize: '11px', pt: 0, pb: 0 }}>
                    Debit
                    <br />
                    مدين
                  </TableCell>
                  <TableCell align="right" sx={{ fontSize: '11px', pt: 0, pb: 0 }}>
                    Credit
                    <br />
                    ائتمان
                  </TableCell>
                  <TableCell align="right" sx={{ fontSize: '11px', pt: 0, pb: 0 }}>
                    Note
                    <br />
                    ملحوظة
                  </TableCell>
                  {/* ))} */}
                </TableRow>
              </TableHead>

              {
                <TableBody>
                  {dataDetail &&
                    dataDetail.map((row) => (
                      <StyledRowResult>
                        <TableCell
                          align="left"
                          sx={{ fontSize: '10px', border: '1px solid rgb(224, 224, 224)' }}
                        >
                          <Typography sx={{ fontSize: '10px' }}>{row.Account}</Typography>
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{ fontSize: '10px', border: '1px solid rgb(224, 224, 224)' }}
                        >
                          {parseFloat(row.Debit).toFixed(3)}
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{ fontSize: '10px', border: '1px solid rgb(224, 224, 224)' }}
                        >
                          {parseFloat(row.Credit).toFixed(3)}
                        </TableCell>

                        <TableCell
                          align="right"
                          sx={{ fontSize: '10px', border: '1px solid rgb(224, 224, 224)' }}
                        >
                          {row.Narration}
                        </TableCell>
                      </StyledRowResult>
                    ))}
                  <StyledRowResult>
                    <TableCell>Total Particulars: {totalParticulars}</TableCell>
                    <TableCell align="right" sx={{ typography: 'body1' }}>
                      <Box />
                      <Typography
                        variant="body2"
                        sx={{ fontWeight: 'bold', fontSize: '12px', pb: 0 }}
                      >
                        {parseFloat(debitAmount).toFixed(3)}
                      </Typography>
                    </TableCell>

                    <TableCell align="right" width={100} sx={{ typography: 'body1' }}>
                      <Box />
                      <Typography
                        variant="body2"
                        sx={{ fontWeight: '600', fontSize: '12px', pb: 0 }}
                      >
                        {parseFloat(creditAmount).toFixed(3)}
                      </Typography>
                    </TableCell>
                    <TableCell></TableCell>
                  </StyledRowResult>
                  {data && data.Comments && (
                    <StyledRowResult>
                      <TableCell colSpan={4}>Narration: {data.Comments}</TableCell>
                    </StyledRowResult>
                  )}
                </TableBody>
              }
            </Table>
            {/* </Scrollbar> */}
          </TableContainer>

          <Divider sx={{ mt: 5, mb: 5 }} />

          <div className="ql-editor" dangerouslySetInnerHTML={{ __html: pageBottom }} />
          <div className="ql-editor" dangerouslySetInnerHTML={{ __html: pageFooter }} />
        </Card>
      </Popover>
    </>
  );
}
