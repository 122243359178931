import { alpha, styled } from '@mui/material/styles';
import {
  Box,
  Card,
  Grid,
  Table,
  Divider,
  TableRow,
  TableBody,
  TableHead,
  TableCell,
  Typography,
  TableContainer,
  Button,
  Popover,
  Stack,
  Tooltip,
  IconButton,
  Popper,
  Grow,
  ClickAwayListener,
  MenuList,
  MenuItem,
  ListItemIcon,
  Menu,
} from '@mui/material';

import { func } from 'prop-types';
import { useContext, useEffect, useStatem, useRef, useState } from 'react';

import { CardPanel } from 'src/controls/cardpanel';
import { DropDownGREYERP } from 'src/controls/dropdown';
import { TextBoxGREYERP } from 'src/controls/textbox';
import NavHorizontal from 'src/layouts/dashboard/nav/NavHorizontal';
import React, { Component } from 'react';
import { useTheme } from '@mui/material/styles';
import { DropDownENUM } from 'src/controls/dropdownenum';
import { ChequeStatus, Mode, PaymentMode } from 'src/data/data';
import Picker from 'src/controls/picker';
import ChequeDetails from 'src/controls/chequedetail';
import { FormContext } from 'src/layouts/dashboard/DashboardLayout';
import { amountInWords, getTaxCategory, getVoucherNumber } from 'src/data/scripts';
import { default as DataGrid } from '../Grid';
import { useReactToPrint } from 'react-to-print';
import Iconify from 'src/components/iconify/Iconify';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';

const StyledRowResult = styled(TableRow)(({ theme }) => ({
  '& td': {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));
export default function PrintTenantInvoice() {
  const theme = useTheme();
  const [data, setData] = useState();
  const [dataDetail, setDataDetail] = useState();
  const [voucherNumber, setVoucherNumber] = useState('');
  const [accountSummaryData, setAccountSummaryData] = useState([]);
  const [pageHeader, setPageHeader] = useState();
  const [pageFooter, setPageFooter] = useState();
  const [pageHeading, setPageHeading] = useState();
  const [pageBottom, setPageBottom] = useState();
  const [pageColor, setPageColor] = useState();
  const [pageFont, setPageFont] = useState();
  const [pageFontSize, setpageFontSize] = useState();
  const [apiUrl, setapiUrl] = useState();

  const { object, setObject, objectEdit } = useContext(FormContext);
  const { openPrint, setOpenPrint, recordId, setRecordId } = useContext(FormContext);

  useEffect(() => {
    //   console.log(recordId.ID);
    //  // let id = '116635';
    //   //recordId
    HttpRequest(
      `getItems/tenantinvoicesummary/name/${localStorage.getItem(
        'username'
      )}/${localStorage.getItem('client')}/${recordId.ID}`
    )
      .then((response) => response)
      .then((json) => {
        setData(json);
        //     console.log(json.InvoiceNo);
        if (json.InvoiceNo !== null && json.InvoiceNo !== '') {
          setinvoiceStatus(true);
          setInvoiceeNumber(json.InvoiceNo);
        } else {
          setinvoiceStatus(false);
        }
      });

    HttpRequest(
      `getItems/tenantinvoicedetails/name/${localStorage.getItem(
        'username'
      )}/${localStorage.getItem('client')}/${recordId.ID}`
    )
      .then((response) => response)
      .then((json) => {
        setDataDetail(json);
      });

    let documentlayoutsid = 0;

    HttpRequest(`getRecordById/clientdetails/${localStorage.getItem('client')}`)
      .then((response) => response)
      .then((json) => {
        documentlayoutsid = json[0].TenantInvoiceLayout;

        HttpRequest(`getRecordById/documentlayouts/${documentlayoutsid}`)
          .then((response) => response)
          .then((json) => {
            setPageHeader(json[0].Header);
            setPageFooter(json[0].Footer);
            setPageHeading(json[0].Heading);
            setPageBottom(json[0].Bottom);
            setPageColor(json[0].color);
            setPageFont(json[0].font);
            setpageFontSize(json[0].fontSize);
          });
      });
  }, []);

  function PrintDialog({ open, onClose }) {
    const handleYes = () => {
      onClose(true);
    };

    const handleNo = () => {
      onClose(false);
    };

    return (
      <Dialog open={open} onClose={handleNo}>
        <DialogTitle>Generate Invoice</DialogTitle>
        <DialogContent>Do you want to generate invoice number?</DialogContent>
        <DialogActions>
          <Button onClick={handleYes} color="primary">
            Yes
          </Button>
          <Button onClick={handleNo} color="primary">
            No
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  const [openDialogBox, setOpenDialogBox] = useState(false);
  const [invoiceStatus, setinvoiceStatus] = useState(false);
  const [clickButton, setClickButton] = useState(false);
  const [invoiceeNumber, setInvoiceeNumber] = useState('');
  const componentRef = useRef();

  const handlePrint = () => {
    setClickButton(true);
    if (invoiceStatus == false) {
      setOpenDialogBox(true);
    } else {
      handlePrintDialogBox();
    }
  };

  const handlePrintDialogClose = (confirm) => {
    setOpenDialogBox(false);
    if (confirm) {
      // Perform print action here
      // window.print();

      const companyData = JSON.parse(localStorage.getItem('companies'));
      const filteredData = companyData.filter(
        (record) => record.ID == localStorage.getItem('client')
      );

      HttpRequest(
        `getVoucherNumber/invoice/${localStorage.getItem('username')}/${localStorage.getItem(
          'client'
        )}/${filteredData[0].Name}`
      )
        .then((response) => response)
        .then((json) => {
          setInvoiceeNumber(json[0].Series + '' + json[0].Number);
          setinvoiceStatus(true);

          var fields = {};
          fields['Series'] = json[0].Series;
          fields['Number'] = json[0].Number;
          fields['Date'] = new Date().toLocaleDateString();
          fields['Journal'] = recordId.ID;

          var response = HttpRequest(
            `tenantinvoices/${localStorage.getItem('client')}/${localStorage.getItem('username')}`,
            'POST',
            fields
          );

          ///  handlePrintDialogBox();
        });
    }
  };

  const handlePrintDialogBox = useReactToPrint({
    content: () => componentRef.current,
    onBeforePrint: () => {
      // do nothing
    },
  });

  useEffect(() => {
    if (invoiceeNumber !== '' && invoiceeNumber !== null && clickButton == true) {
      handlePrintDialogBox();
    }
  }, [invoiceeNumber, handlePrintDialogBox]);

  //console.log(invoiceStatus);
  return (
    <>
      <Popover
        sx={{ backgroundColor: alpha('#212B36', 0.4) }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={openPrint}
      >
        <Card ref={componentRef} sx={{ pt: 5, px: 3, width: '850px' }}>
          <Grid container alignItems={'center'}>
            <Grid item sx={{ ml: 'auto', displayPrint: 'none' }}>
              <Tooltip title="Print">
                <IconButton onClick={handlePrint}>
                  <Iconify icon="eva:printer-fill" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Close">
                <IconButton onClick={(e) => setOpenPrint(false)}>
                  <Iconify icon="material-symbols:close" />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
          <Grid container sx={{ '& img': { width: '100%' } }}>
            <div
              className="ql-editor"
              style={{ width: '100%' }}
              dangerouslySetInnerHTML={{ __html: pageHeader }}
            />
          </Grid>

          <Grid container justifyContent={'center'}>
            <Typography variant="h4">
              {pageHeading &&
              (pageHeading == 'DOCUMENT NAME' || pageHeading == '<p>DOCUMENT NAME</p>') ? (
                'TAX INVOICE'
              ) : (
                <div
                  className="ql-editor"
                  style={{ width: '100%' }}
                  dangerouslySetInnerHTML={{ __html: pageHeading }}
                />
              )}
            </Typography>
          </Grid>
          <Grid container sx={{ pt: 3 }}>
            <Grid item xs={12} sm={6} sx={{}}>
              <Typography variant="body2" sx={{ fontWeight: 'bold', fontSize: '13px' }}>
                Invoice #{' '}
                <Typography variant="span" sx={{ fontWeight: '200' }}>
                  {invoiceeNumber != '' && invoiceeNumber}
                </Typography>
              </Typography>

              <Typography variant="body2" sx={{ fontWeight: 'bold', fontSize: '13px' }}>
                Invoice Date{' '}
                <Typography variant="span" sx={{ fontWeight: '200' }}>
                  {data && data.Date}
                </Typography>
              </Typography>

              <Typography variant="body2" sx={{ fontWeight: 'bold', fontSize: '13px' }}>
                Contract From{' '}
                <Typography variant="span" sx={{ fontWeight: '200' }}>
                  {data && data.ContractFrom}
                </Typography>
              </Typography>

              <Typography variant="body2" sx={{ fontWeight: 'bold', fontSize: '13px' }}>
                Contract To{' '}
                <Typography variant="span" sx={{ fontWeight: '200' }}>
                  {data && data.ContractTo}
                </Typography>
              </Typography>

              <Typography variant="body2" sx={{ fontWeight: 'bold', fontSize: '13px' }}>
                Contract Period{' '}
                <Typography variant="span" sx={{ fontWeight: '200' }}>
                  {data && data.Months}
                </Typography>
              </Typography>
            </Grid>

            <Grid item xs={12} sm={6} sx={{ mb: 5 }}>
              <Typography variant="body2" sx={{ fontWeight: 'bold', fontSize: '13px' }}>
                Tenant Name{' '}
                <Typography variant="span" sx={{ fontWeight: '200' }}>
                  {data && data.Name}
                </Typography>
              </Typography>

              <Typography variant="body2" sx={{ fontWeight: 'bold', fontSize: '13px' }}>
                Unit No.{' '}
                <Typography variant="span" sx={{ fontWeight: '200' }}>
                  {data && data.Unit}
                </Typography>
              </Typography>

              <Typography variant="body2" sx={{ fontWeight: 'bold', fontSize: '13px' }}>
                Building{' '}
                <Typography variant="span" sx={{ fontWeight: '200' }}>
                  {data && data.Building}
                </Typography>
              </Typography>
              {data && data.PreviousAddress && (
                <Typography variant="body2" sx={{ fontWeight: 'bold', fontSize: '13px' }}>
                  Address{' '}
                  <Typography variant="span" sx={{ fontWeight: '200' }}>
                    {data && data.PreviousAddress}
                  </Typography>
                </Typography>
              )}

              {data && data.Phone != '0' && (
                <Typography variant="body2" sx={{ fontWeight: 'bold', fontSize: '13px' }}>
                  Phone{' '}
                  <Typography variant="span" sx={{ fontWeight: '200' }}>
                    {data && data.Phone}
                  </Typography>
                </Typography>
              )}
              {/* <Typography variant="body2" sx={{ fontWeight: 'bold', fontSize: '13px' }}>
                VATIN{' '}
                  <Typography variant="span" sx={{ fontWeight: '200' }}>
                  {data && data.PreviousAddress}
                  </Typography>
                </Typography> */}
              {data && data.TaxNumber && (
                <Typography variant="body2" sx={{ fontWeight: 'bold', fontSize: '13px' }}>
                  Tax Card #{' '}
                  <Typography variant="span" sx={{ fontWeight: '200' }}>
                    {data && data.TaxNumber}
                  </Typography>
                </Typography>
              )}
            </Grid>
          </Grid>

          {/* //backgroundColor: `${pageColor}`            */}
          <TableContainer sx={{ overflow: 'unset', pt: 5 }}>
            {/* <Scrollbar> */}
            <Table sx={{ width: '100%', fontFamily: pageFont, fontSize: pageFontSize }}>
              <TableHead
                sx={{
                  borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
                  '& th': {
                    backgroundColor: pageColor,
                    color: 'white',
                    fontFamily: pageFont,
                    fontSize: pageFontSize,
                  },
                }}
              >
                <TableRow>
                  {/* {columns &&
                    columns.map((row, index) => ( */}
                  <TableCell align="left" sx={{ pt: 0, pb: 0 }}>
                    Item Description
                    <br />
                    الوصف
                  </TableCell>
                  <TableCell align="right" sx={{ pt: 0, pb: 0 }}>
                    Rent
                    <br />
                    إيجار
                  </TableCell>
                  <TableCell align="right" sx={{ pt: 0, pb: 0 }}>
                    VAT Out (5%)
                    <br />
                    ضريبة
                  </TableCell>
                  <TableCell align="right" sx={{ pt: 0, pb: 0 }}>
                    Net Amount
                    <br />
                    المبلغ الصافي
                  </TableCell>
                  {/* ))} */}
                </TableRow>
              </TableHead>

              {
                <TableBody>
                  <StyledRowResult>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: pageFont,
                        fontSize: pageFontSize,
                        border: '1px solid rgb(224, 224, 224)',
                      }}
                    >
                      <Typography sx={{ fontFamily: pageFont, fontSize: pageFontSize }}>
                        Being charged Rent for the month of{' '}
                        {data && new Date(data.Date).toLocaleString('default', { month: 'long' })}{' '}
                        {data && new Date(data.Date).getFullYear()} along with VAT 5%.
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{
                        fontFamily: pageFont,
                        fontSize: pageFontSize,
                        border: '1px solid rgb(224, 224, 224)',
                      }}
                    >
                      {dataDetail && parseFloat(dataDetail.Debit).toFixed(3)}
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{
                        fontFamily: pageFont,
                        fontSize: pageFontSize,
                        border: '1px solid rgb(224, 224, 224)',
                      }}
                    >
                      {dataDetail && parseFloat(dataDetail.Taxes).toFixed(3)}
                    </TableCell>

                    <TableCell
                      align="right"
                      sx={{
                        fontFamily: pageFont,
                        fontSize: pageFontSize,
                        border: '1px solid rgb(224, 224, 224)',
                      }}
                    >
                      {dataDetail && parseFloat(dataDetail.TotalAmount).toFixed(3)}
                    </TableCell>
                  </StyledRowResult>

                  <StyledRowResult>
                    <TableCell colSpan={2}></TableCell>
                    <TableCell align="right" sx={{ py: '4px !important', typography: 'body1' }}>
                      <Typography
                        variant="body2"
                        sx={{ fontFamily: pageFont, fontSize: pageFontSize, fontWeight: 'bold' }}
                      >
                        Sub Total الإجمالي
                      </Typography>
                    </TableCell>

                    <TableCell align="right" sx={{ py: '4px !important', typography: 'body1' }}>
                      <Box />
                      <Typography
                        variant="body2"
                        sx={{ fontFamily: pageFont, fontSize: pageFontSize, fontWeight: 'bold' }}
                      >
                        {dataDetail && parseFloat(dataDetail.Debit).toFixed(3)}
                      </Typography>
                    </TableCell>
                  </StyledRowResult>

                  <StyledRowResult>
                    <TableCell colSpan={2} />

                    <TableCell align="right" sx={{ py: '4px !important', typography: 'body1' }}>
                      <Typography
                        variant="body2"
                        sx={{
                          fontFamily: pageFont,
                          fontSize: pageFontSize,
                          fontWeight: 'bold',
                          pb: 0,
                        }}
                      >
                        Total VAT الضريبة
                      </Typography>
                    </TableCell>

                    <TableCell align="right" sx={{ py: '4px !important', typography: 'body1' }}>
                      <Typography
                        variant="body2"
                        sx={{
                          fontFamily: pageFont,
                          fontSize: pageFontSize,
                          fontWeight: 'bold',
                          pb: 0,
                        }}
                      >
                        {dataDetail && parseFloat(dataDetail.Taxes).toFixed(3)}
                      </Typography>
                    </TableCell>
                  </StyledRowResult>

                  <StyledRowResult>
                    <TableCell colSpan={2} sx={{ py: '4px !important', pl: '0' }}>
                      <Typography
                        variant="body2"
                        sx={{ fontFamily: pageFont, fontSize: pageFontSize, fontWeight: 'bold' }}
                      >
                        In Words: {dataDetail && amountInWords(dataDetail.TotalAmount)} Only
                      </Typography>
                    </TableCell>

                    <TableCell align="right" sx={{ py: '4px !important', typography: 'h6' }}>
                      <Typography
                        variant="body2"
                        sx={{ fontFamily: pageFont, fontSize: pageFontSize, fontWeight: 'bold' }}
                      >
                        Net Amount المبلغ الصافي
                      </Typography>
                    </TableCell>

                    <TableCell
                      align="right"
                      width={100}
                      sx={{ py: '4px !important', typography: 'h6' }}
                    >
                      <Typography
                        variant="body2"
                        sx={{ fontFamily: pageFont, fontSize: pageFontSize, fontWeight: 'bold' }}
                      >
                        {dataDetail && parseFloat(dataDetail.TotalAmount).toFixed(3)}
                      </Typography>
                    </TableCell>
                  </StyledRowResult>
                </TableBody>
              }
            </Table>
            {/* </Scrollbar> */}
          </TableContainer>

          <Divider sx={{ mt: 5 }} />

          <div className="ql-editor" dangerouslySetInnerHTML={{ __html: pageBottom }} />
          <div className="ql-editor" dangerouslySetInnerHTML={{ __html: pageFooter }} />
        </Card>
      </Popover>

      <PrintDialog open={openDialogBox} onClose={handlePrintDialogClose} />
    </>
  );
}
