import { Box, Modal, Typography, Grid, Tooltip, IconButton } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { FormContext } from 'src/layouts/dashboard/DashboardLayout';
import Iconify from 'src/components/iconify/Iconify';
import { SyncExternalProviderDataMeeting } from './syncexternalprovidermeeting';
import { msalConfig, loginRequest, tokenRequest } from 'src/outlook/authConfig';
import { callMSGraph, updateUI, graphConfig } from 'src/outlook/graph';
import { getTokenPopup } from 'src/outlook/authPopup';
import SyncExternalEmailProvider from './syncExternalEmailProvider';
import { HttpRequest } from 'src/data/scripts';
export function SyncExternalProviderData() {
  const gapi = window.gapi;
  const google = window.google;

  const CLIENT_ID = '159614385395-fs71k5n5sibta0mg544ndq5oijpi9q5m.apps.googleusercontent.com';
  const API_KEY = 'AIzaSyAX5Qs8kvSvMrvrsdGvcDQ72Ve_aTuSTys';
  const DISCOVERY_DOC = 'https://people.googleapis.com/$discovery/rest';
  const SCOPES = 'https://www.googleapis.com/auth/contacts';

  const MEETING_CLIENT_ID =
    '13757048669-23fktos8qng7qblvfe34p0e5nomprf9a.apps.googleusercontent.com';
  const MEETING_API_KEY = 'AIzaSyBb8rWC3qZ5rY-RAWe7hox-vZ5Ti7wNP2w';
  const MEETING_DISCOVERY_DOC = 'https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest';
  const MEETING_SCOPES = 'https://www.googleapis.com/auth/calendar';

  const accessToken = null;
  const expiresIn = null;

  let gapiInited = false,
    gisInited = false,
    tokenClient;

  useEffect(() => {
    if (window.location.pathname.split('/')[2] == 'contacts') {
      gapiLoaded();
      gisLoaded();
    } else if (window.location.pathname.split('/')[2] == 'events') {
      gapiLoadedMeeting();
      gisLoadedMeeting();
    }
  }, []);

  function gapiLoaded() {
    gapi.load('client', initializeGapiClient);
  }

  async function initializeGapiClient() {
    await gapi.client.init({
      apiKey: API_KEY,
      discoveryDocs: [DISCOVERY_DOC],
    });
    gapiInited = true;

    // if (accessToken && expiresIn) {
    //   gapi.client.setToken({
    //     access_token: accessToken,
    //     expires_in: expiresIn,
    //   });
    //   fetchContacts();
    // }
  }

  function gisLoaded() {
    tokenClient = google.accounts.oauth2.initTokenClient({
      client_id: CLIENT_ID,
      scope: SCOPES,
      callback: '', // defined later
    });

    gisInited = true;
  }

  function gapiLoadedMeeting() {
    gapi.load('client', initializeGapiClientMeeting);
  }

  async function initializeGapiClientMeeting() {
    await gapi.client.init({
      apiKey: MEETING_API_KEY,
      discoveryDocs: [MEETING_DISCOVERY_DOC],
    });
    gapiInited = true;

    // if (accessToken && expiresIn) {
    //   gapi.client.setToken({
    //     access_token: accessToken,
    //     expires_in: expiresIn,
    //   });
    //   fetchContacts();
    // }
  }

  function gisLoadedMeeting() {
    tokenClient = google.accounts.oauth2.initTokenClient({
      client_id: MEETING_CLIENT_ID,
      scope: MEETING_SCOPES,
      callback: '', // defined later
    });

    gisInited = true;
  }
  //Enables user interaction after all libraries are loaded.

  async function handleGoogleAuthClick(e) {
    e.preventDefault();
    // console.log("hello")
    tokenClient.callback = async (resp) => {
      if (resp.error) {
        //  console.log("error");
        throw resp;
      }

      if (window.location.pathname.split('/')[2] == 'contacts') {
        await fetchContacts();
        await addNewContactsGoogle();
        // await DeleteContactsGoogle();
      } else if (window.location.pathname.split('/')[2] == 'events') {
        await listUpcomingEvents();
        await addNewUpcomingEvents();
      }
      //  console.log('reached')

      const { access_token, expires_in } = gapi.client.getToken();
      localStorage.setItem('access_token', access_token);
      localStorage.setItem('expires_in', expires_in);
      handleClose();
    };

    if (!(accessToken && expiresIn)) {
      // Prompt the user to select a Google Account and ask for consent to share their data
      // when establishing a new session.
      tokenClient.requestAccessToken({ prompt: 'consent' });
    } else {
      // Skip display of account chooser and consent dialog for an existing session.
      tokenClient.requestAccessToken({ prompt: '' });
    }
  }

  const [contacts, setContacts] = useState([]);
  async function fetchContacts() {
    const connections = await getAllContacts();
    //console.log(connections);
    const contacts = connections.map(function (connection) {
      return {
        //Name
        GoogleID: connection.resourceName.substring(7),
        FirstName:
          connection.names && connection.names[0].givenName
            ? connection.names[0].givenName.replaceAll("'", '`')
            : '',
        MiddleName:
          connection.names && connection.names[0].middleName
            ? connection.names[0].middleName.replaceAll("'", '`')
            : '',
        LastName:
          connection.names && connection.names[0].familyName
            ? connection.names[0].familyName.replaceAll("'", '`')
            : '',
        NickName: '', //connection.nicknames && connection.nicknames.replaceAll("'","`") ? connection.nicknames : '',
        ///organizations
        Organization:
          connection.organizations && connection.organizations[0].name
            ? connection.organizations[0].name.replaceAll("'", '`')
            : '',
        JobTitle:
          connection.organizations && connection.organizations[0].title
            ? connection.organizations[0].title.replaceAll("'", '`')
            : '',
        ///
        Phone:
          connection.phoneNumbers && connection.phoneNumbers[0].value
            ? connection.phoneNumbers[0].value.replaceAll("'", '`')
            : '',
        Email:
          connection.emailAddresses && connection.emailAddresses[0].value
            ? connection.emailAddresses[0].value.replaceAll("'", '`')
            : '',
        //Address
        Street1:
          connection.addresses && connection.addresses[0].streetAddress
            ? connection.addresses[0].streetAddress.replaceAll("'", '`')
            : '',
        Street2:
          connection.addresses && connection.addresses[0].extendedAddress
            ? connection.addresses[0].extendedAddress.replaceAll("'", '`')
            : '',
        City:
          connection.addresses && connection.addresses[0].city
            ? connection.addresses[0].city.replaceAll("'", '`')
            : '',
        PostalCode:
          connection.addresses && connection.addresses[0].postalCode
            ? connection.addresses[0].postalCode.replaceAll("'", '`')
            : '',
        Country:
          connection.addresses && connection.addresses[0].country
            ? connection.addresses[0].country.replaceAll("'", '`')
            : '',
        Image: connection.photos && connection.photos[0].url ? connection.photos[0].url : '',
      };
    });
    //   console.log(contacts);
    Promise.all(
      contacts.map(
        (contact, index) =>
          new Promise((resolve) => {
            setTimeout(() => {
              HttpRequest(
                `getItems/googlecontactsdetails/Name/${localStorage.getItem(
                  'username'
                )}/${localStorage.getItem('client')}/${contact.GoogleID}`
              )
                .then((response) => response)
                .then((json) => {
                  if (json.ID == '0') {
                    return HttpRequest(
                      `contacts/${localStorage.getItem('client')}/${localStorage.getItem(
                        'username'
                      )}`,
                      'POST',
                      contact
                    );
                  } else {
                    return HttpRequest(
                      `contacts/${json.ID}/${localStorage.getItem('username')}`,
                      'PUT',
                      contact
                    );
                  }
                })
                .then(resolve)
                .catch(console.error);
            }, index * 10); // delay by 1 second per contact index
          })
      )
    )
      .then((responses) => {
        //console.log(responses);
      })
      .catch(console.error);
  }

  async function getAllContacts(pageToken = null, allContacts = []) {
    const response = await gapi.client.people.people.connections.list({
      resourceName: 'people/me',
      pageSize: '1000',
      personFields:
        'names,nicknames,emailAddresses,phoneNumbers,addresses,locations,occupations,organizations,photos',
      pageToken,
    });

    const { connections, nextPageToken } = response.result;
    const contacts = [...allContacts, ...connections];

    if (nextPageToken) {
      return await getAllContacts(nextPageToken, contacts);
    } else {
      return contacts;
    }
  }

  async function listUpcomingEvents() {
    let response;
    try {
      const request = {
        calendarId: 'primary',
        //  'timeMin': (new Date()).toISOString(),
        showDeleted: false,
        singleEvents: true,
        maxResults: 1000,
        orderBy: 'startTime',
      };
      response = await gapi.client.calendar.events.list(request);
      var items = response.result.items;
      //console.log(items);
      const Meetings = items.map(function (Meeting) {
        return {
          //Name
          GoogleID: Meeting.id,
          title: Meeting.summary ? Meeting.summary.replaceAll("'", '`') : '',
          location: Meeting.location ? Meeting.location.replaceAll("'", '`') : '',
          startDateTime:
            Meeting.start && Meeting.start.dateTime
              ? new Date(Meeting.start.dateTime).toISOString()
              : '',
          endDateTime:
            Meeting.end && Meeting.end.dateTime ? new Date(Meeting.end.dateTime).toISOString() : '',
          description: Meeting.description ? Meeting.description.replaceAll("'", '`') : '',
        };
      });

      //  console.log(Meetings);
      Promise.all(
        Meetings.map(
          (Meeting, index) =>
            new Promise((resolve) => {
              setTimeout(() => {
                HttpRequest(
                  `getItems/googlemeetingsdetails/Name/${localStorage.getItem(
                    'username'
                  )}/${localStorage.getItem('client')}/${Meeting.GoogleID}`
                )
                  .then((response) => response)
                  .then((json) => {
                    if (json.ID == '0') {
                      return HttpRequest(
                        `events/${localStorage.getItem('client')}/${localStorage.getItem(
                          'username'
                        )}`,
                        'POST',
                        Meeting
                      );
                    } else {
                      return HttpRequest(
                        `events/${json.ID}/${localStorage.getItem('username')}`,
                        'PUT',
                        Meeting
                      );
                    }
                  })
                  .then(resolve)
                  .catch(console.error);
              }, index * 10); // delay by 1 second per contact index
            })
        )
      )
        .then((responses) => {
          //  console.log(responses);
        })
        .catch(console.error);
    } catch (err) {
      return;
    }
  }

  async function addNewUpcomingEvents() {
    HttpRequest(
      `getItems/syncgooglemeetingsdetails/name/${localStorage.getItem(
        'username'
      )}/${localStorage.getItem('client')}`
    )
      .then((response) => response)
      .then((json) => {
        //console.log(json)
        json.map((meeting) => {
          // console.log(meeting);

          var event = {
            kind: 'calendar#event',
            summary: meeting.title,
            location: meeting.location,
            description: meeting.description,
            start: {
              dateTime: meeting.startDateTime,
              timeZone: 'UTC',
            },
            end: {
              dateTime: meeting.endDateTime,
              timeZone: 'UTC',
            },
            recurrence: ['RRULE:FREQ=DAILY;COUNT=1'],
            reminders: {
              useDefault: true,
            },
          };

          var request = gapi.client.calendar.events.insert({
            calendarId: 'primary',
            resource: event,
            sendUpdates: 'all',
          });
          request.execute(
            (event) => {
              //  console.log(event)
              let googleid = event.id;
              //  console.log(googleid);

              var fields = {};
              fields['GoogleID'] = googleid;
              HttpRequest(
                `events/${meeting.ID}/${localStorage.getItem('username')}`,
                'PUT',
                fields
              );

              //window.open(event.htmlLink)
            },
            (error) => {
              // console.error(error);
            }
          );
        });
      });
  }

  async function addNewContactsGoogle() {
    HttpRequest(
      `getItems/syncgooglecontactsdetails/name/${localStorage.getItem(
        'username'
      )}/${localStorage.getItem('client')}`
    )
      .then((response) => response)
      .then((json) => {
        //     console.log(json);
        json.map((contact) => {
          // console.log(contact);
          gapi.client.people.people
            .createContact({
              resource: {
                names: [
                  {
                    familyName: contact.LastName,
                    givenName: contact.FirstName,
                    middleName: contact.MiddleName,
                  },
                ],
                nicknames: [
                  {
                    value: contact.NickName,
                  },
                ],
                organizations: [
                  {
                    name: contact.Organization,
                    title: contact.JobTitle,
                  },
                ],
                phoneNumbers: [
                  {
                    value: contact.Phone,
                  },
                ],
                emailAddresses: [
                  {
                    value: contact.Email,
                  },
                ],
                addresses: [
                  {
                    city: contact.City,
                    country: contact.Country,
                    streetAddress: contact.Street1,
                    extendedAddress: contact.Street2,
                    postalCode: contact.PostalCode,
                  },
                ],
              },
            })
            .then(
              function (response) {
                // console.log("Response", response);
                let googleid = response.result.resourceName.replace('people/', '');
                //  console.log(googleid);

                var fields = {};
                fields['GoogleID'] = googleid;
                HttpRequest(
                  `contacts/${contact.ID}/${localStorage.getItem('username')}`,

                  'PUT',
                  fields
                );
              },
              function (err) {
                console.error('Execute error', err);
              }
            );
        });
      });
  }

  async function DeleteContactsGoogle() {
    HttpRequest(
      `getItems/googlecontactsdeleted/name/${localStorage.getItem(
        'username'
      )}/${localStorage.getItem('client')}`
    )
      .then((response) => response)
      .then((json) => {
        //     console.log(json);
        json.map((contact) => {
          //    console.log(contact);
          gapi.client.people.people
            .deleteContact({
              resourceName: 'people/' + contact.GoogleID,
            })
            .then(
              function (response) {
                // console.log('Response', response);
              },
              function (err) {
                // console.error('Execute error', err);
              }
            );
        });
      });
  }

  const { openPrint, setOpenPrint, openExternalProviderPopup, setOpenExternalProviderPopup } =
    useContext(FormContext);
  const handleClose = () => {
    setOpenExternalProviderPopup(false);
  };
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    height: 'auto',
    bgcolor: 'background.paper',
    borderRadius: '10px',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

  const myMSALObj = new Msal.UserAgentApplication(msalConfig);

  async function handleOutlookAuthClick(e) {
    e.preventDefault();

    myMSALObj
      .loginPopup(loginRequest)
      .then((loginResponse) => {
        if (myMSALObj.getAccount()) {
          getTokenPopup(tokenRequest)
            .then((response) => {
              //   console.log(response)
              callMSGraph(graphConfig.graphMailEndpoint, response.accessToken, updateUI);
            })
            .catch((error) => {
              //   console.log(error);
            });
        }
      })
      .catch((error) => {
        //console.log(error);
      });
  }

  return (
    <Modal
      //open={true}
      onClose={handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box sx={{ ...style, width: 600 }}>
        <Grid container alignItems={'center'}>
          <Grid item sx={{ ml: 'auto', displayPrint: 'none' }}>
            <Tooltip title="Close">
              <IconButton onClick={handleClose}>
                <Iconify icon="material-symbols:close" />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
        <Typography sx={{ fontSize: '24px', fontWeight: '700' }}>
          Sync {window.location.pathname.split('/')[2].charAt(0).toUpperCase()}
          {window.location.pathname.split('/')[2].slice(1)}
        </Typography>
        {window.location.pathname.split('/')[2] == 'contacts' ? (
          <Grid container sx={{ pt: 3 }} alignItems={'center'}>
            <Grid item xs={12} sm={4} sx={{ textAlign: 'center' }}>
              <button
                type="button"
                id="authorize_button_Google"
                hidden={accessToken && expiresIn}
                onClick={handleGoogleAuthClick}
                style={{
                  backgroundImage: "url('https://uploads.greyerp.com/googlelogo.png')",
                  backgroundSize: 'cover',
                  border: 'none',
                  backgroundColor: 'transparent',
                  cursor: 'pointer',
                  width: '118px',
                  height: '118px',
                }}
              >
                {' '}
              </button>
            </Grid>
            <Grid item xs={12} sm={4} sx={{ textAlign: 'center' }}>
              <button
                type="button"
                id="authorize_button_Apple"
                style={{
                  backgroundImage: "url('https://uploads.greyerp.com/applylogo.png')",
                  backgroundSize: 'cover',
                  border: 'none',
                  backgroundColor: 'transparent',
                  width: '105px',
                  height: '118px',
                  cursor: 'pointer',
                }}
              >
                {' '}
              </button>
            </Grid>
            <Grid item xs={12} sm={4} sx={{ textAlign: 'center' }}>
              <button
                type="button"
                id="authorize_button_Outlook"
                onClick={handleOutlookAuthClick}
                style={{
                  backgroundImage: "url('https://uploads.greyerp.com/outlooklogo.png')",
                  backgroundSize: 'cover',
                  border: 'none',
                  backgroundColor: 'transparent',
                  width: '118px',
                  height: '108px',
                  cursor: 'pointer',
                }}
              >
                {' '}
              </button>
            </Grid>
          </Grid>
        ) : window.location.pathname.split('/')[2].toLowerCase() == 'mails' ? (
          <SyncExternalEmailProvider />
        ) : (
          <SyncExternalProviderDataMeeting />
        )}
      </Box>
    </Modal>
  );
}
