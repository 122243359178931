// form

import { useForm, useFormContext } from 'react-hook-form';
// @mui
import { Stack, Card, Button, Grid, Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../../components/iconify';
import { useSnackbar } from 'notistack';
import FormProvider, { RHFTextField } from '../../../../components/hook-form';
import { TextBoxGREYERP } from 'src/controls/textbox';
import { CardPanel } from 'src/controls/cardpanel';
import { FormContext } from 'src/layouts/dashboard/DashboardLayout';
import { useContext, useRef, useState } from 'react';

// ----------------------------------------------------------------------

export default function AccountChangePassword() {
  const { enqueueSnackbar } = useSnackbar();
  const { submitForm, formRef, object } = useContext(FormContext);

  const [errorText, setErrorText] = useState('');

  const defaultValues = {
    oldPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  };

  const onSubmit = async (data) => {
    try {
      await new Promise((resolve) => setTimeout(resolve, 500));
      reset();
      enqueueSnackbar('Update success!');
      //  console.log('DATA', data);
    } catch (error) {
      console.error(error);
    }
  };

  const formRefPass = useRef();
  return (
    <FormProvider onSubmit={onSubmit}>
      <Box sx={{}} component={'form'} ref={formRefPass}>
        <CardPanel
          heading={'Change Password'}
          cardItem={
            <>
              <TextBoxGREYERP
                name="oldPassword"
                required={true}
                type="password"
                label="Old Password"
              />

              <TextBoxGREYERP
                name="newPassword"
                type="password"
                required={true}
                label="New Password"
              />

              <TextBoxGREYERP
                name="confirmNewPassword"
                required
                type="password"
                label="Confirm New Password"
              />

              <Grid item>
                <Button
                  variant="contained"
                  onClick={(e) => {
                    //  console.log(object);
                    if (formRefPass.current.reportValidity()) {
                      if (object.newPassword != object.confirmNewPassword) {
                        enqueueSnackbar(`Password doesn't match`);
                        return;
                      } else if (
                        object.newPassword.length < 8 &&
                        object.confirmNewPassword.length < 8
                      ) {
                        enqueueSnackbar(`Password must be at least 8 characters`);
                        return;
                      } else {
                        HttpRequest(
                          `changepassword/${localStorage.getItem('username')}`,
                          'PUT',
                          object
                        )
                          .then((response) => response)
                          .then((json) => {
                            if (json.status) {
                              enqueueSnackbar(`Password Updated!!`);
                            } else {
                              enqueueSnackbar(`Incorrect Password. Try Again!`);
                            }
                          });
                      }
                    }
                  }}
                >
                  Change Password
                </Button>
              </Grid>
            </>
          }
        />
      </Box>
    </FormProvider>
  );
}
