import { useTheme } from '@mui/material/styles';
import {
  Grid,
  Box,
  Typography,
  Stack,
  TableContainer,
  Table,
  TableBody,
  Button,
} from '@mui/material';
import { bgGradient } from 'src/utils/cssStyles';
import Image from '../components/image';
import { CardPanel } from 'src/controls/cardpanel';
import { MotivationIllustration } from '../assets/illustrations';
import { styled } from '@mui/material/styles';
import { useContext, useEffect, useRef, useState } from 'react';
import Picker from 'src/controls/picker';
import { FormContext } from 'src/layouts/dashboard/DashboardLayout';
import ReactStickyNotes from '@react-latest-ui/react-sticky-notes';
import { HttpRequest } from 'src/data/scripts';
import { useTranslation } from 'react-i18next';

export default function mainDashboard() {
  const { t } = useTranslation();
  const theme = useTheme();
  const [firstDate, setFirstDate] = useState(localStorage.getItem('firstdate'));
  const [lastDate, setLastDate] = useState(localStorage.getItem('lastdate'));
  const [reset, setReset] = useState(1);
  const [remainingSubscriptionDays, setRemainingSubscriptionDays] = useState(0);
  const { setObject, setObjectEdit } = useContext(FormContext);

  const [notes, setNotes] = useState();

  let stickyNotesBtns = document.getElementsByClassName('rs-notes--header--button');
  let stickyHeaderBtns = document.getElementsByClassName('rs-notes--navbar__item--button');
  let stickyBtns = [...stickyNotesBtns, ...stickyHeaderBtns] ?? [];
  if (stickyBtns?.length > 0) {
    for (let index = 0; index < stickyBtns.length; index++) {
      stickyBtns[index].setAttribute('type', 'button');
    }
  }

  useEffect(() => {
    HttpRequest(`getRecords/notes/${localStorage.getItem('username')}`).then((json = []) => {
      if (json.length < 1) {
        let note = {
          color: 'hsl(90,50%, 50%)',
          text: '',
          position: '0,0',
        };
        HttpRequest(
          `notes/${localStorage.getItem('client')}/${localStorage.getItem('username')}`,
          'POST',
          note
        )
          .then((data) => data)
          .then((json) => {
            note['id'] = json;
            localStorage.setItem('react-sticky-notes', JSON.stringify([note]));
            setNotes((prev) => [...prev, note]);
            return;
          });
      }
      let notesList = json?.map((note, index) => {
        let positionArray = note?.position?.split(',');
        positionArray = positionArray?.map((position) => {
          return parseInt(position.trim());
        });

        note['position'] = {
          x: positionArray[0] ?? 0,
          y: positionArray[1] ?? 0,
          px: positionArray[2] ?? 0,
          py: positionArray[3] ?? 0,
        };
        if (index == 0) {
          note['selected'] = true;
        } else {
          note['selected'] = false;
        }

        return note;
      });
      localStorage.setItem('react-sticky-notes', JSON.stringify(notesList));
      setNotes(notesList);
    });
  }, []);

  const StyledRoot = styled('div')(({ theme }) => ({
    height: '100%',
    display: 'flex',
    overflow: 'hidden',
    position: 'relative',
    color: theme.palette.primary.darker,
    borderRadius: Number(theme.shape.borderRadius) * 2,
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  }));

  function onChangeFirstDate(e) {
    if (e) {
      setFirstDate(e);

      var fields = {};
      fields['FirstDate'] = e.toLocaleDateString();
      fields['LastDate'] = LastDate.toLocaleDateString();
      var response = HttpRequest(
        `mainclients/${localStorage.getItem('client')}/${localStorage.getItem('username')}`,
        'PUT',
        fields
      );
    }
  }

  function onChangeLastDate(e) {
    if (e) {
      setLastDate(e);

      var fields = {};
      fields['FirstDate'] = new Date(firstDate).toLocaleDateString();
      fields['LastDate'] = e.toLocaleDateString();

      var response = HttpRequest(
        `mainclients/${localStorage.getItem('client')}/${localStorage.getItem('username')}`,
        'PUT',
        fields
      );
    }
  }

  useEffect(() => {
    const bytes = CryptoJS.AES?.decrypt(`${localStorage.getItem('Expiry')}`, 'appsgreyerpcom');
    const decryptedStringDate = bytes?.toString(CryptoJS.enc.Utf8);
    let ExpiryDt = decryptedStringDate && new Date(decryptedStringDate);
    let currentDt = new Date();

    var Difference_In_Time = ExpiryDt.getTime() - currentDt.getTime();
    var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
    setRemainingSubscriptionDays(parseFloat(Difference_In_Days).toFixed(0));
  }, []);

  return (
    <>
      <Grid container direction="row" sx={{ marginTop: '-95px' }} columnSpacing={2} rowSpacing={2}>
        <Grid item md={2.5}>
          <CardPanel
            heading="Default Dates"
            cardItem={
              <>
                <Picker
                  type="date"
                  onChange={onChangeFirstDate}
                  name="FirstDate"
                  date={firstDate}
                  postBackDate={firstDate}
                  span={5}
                ></Picker>

                <Picker
                  type="date"
                  onChange={onChangeLastDate}
                  name="LastDate"
                  date={lastDate}
                  postBackDate={lastDate}
                  span={5}
                ></Picker>
              </>
            }
          />
        </Grid>
        <Grid item md={5}>
          <CardPanel
            cardItem={
              <>
                <StyledRoot>
                  <Stack
                    flexGrow={1}
                    justifyContent="center"
                    alignItems={{ xs: 'center', md: 'flex-start' }}
                    sx={{
                      pl: 5,
                      py: { xs: 5, md: 0 },
                      pr: { xs: 5, md: 0 },
                      textAlign: { xs: 'center', md: 'left' },
                    }}
                  >
                    <Typography paragraph variant="h4" sx={{ whiteSpace: 'pre-line' }}>
                      {t('Subscription Details')}
                    </Typography>

                    <Typography
                      variant="body2"
                      sx={{
                        opacity: 0.8,
                        mb: { xs: 3, xl: 5 },
                      }}
                    >
                      {t('Your subscription will end in ')}
                      <span style={{ fontWeight: 'bold' }}>{remainingSubscriptionDays}</span>{' '}
                      {t('days')}. {t('Upgrade your subscription to continue using Grey ERP')}
                    </Typography>

                    <Button variant="contained">{t('Upgrade Now')}</Button>
                  </Stack>
                  <MotivationIllustration
                    sx={{
                      p: 3,
                      width: 360,
                      margin: { xs: 'auto', md: 'inherit' },
                    }}
                  />
                </StyledRoot>
              </>
            }
          />
        </Grid>
        <Grid item md={4.5}>
          <Box>
            <Image
              disabledEffect
              alt="illustration-invite"
              src="/assets/illustrations/characters/character_11.png"
              sx={{
                left: 40,
                zIndex: 9,
                width: 140,
                position: 'relative',
                filter: 'drop-shadow(0 12px 24px rgba(0,0,0,0.24))',
              }}
            />

            <Box
              sx={{
                mt: -15,
                color: 'common.white',
                borderRadius: 2,
                p: theme.spacing(16, 5, 5, 5),
                ...bgGradient({
                  direction: '135deg',
                  startColor: theme.palette.primary.main,
                  endColor: theme.palette.primary.dark,
                }),
              }}
            >
              <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Typography variant="h4" sx={{ whiteSpace: 'pre-line' }}>
                  {t('Invite and earn')}
                </Typography>

                <Typography variant="h2"> $ </Typography>
              </Stack>

              <Typography variant="body2" sx={{ mt: 2 }}>
                {t('Invite your friends to use Grey ERP and earn a reward or equivalent')}
              </Typography>
            </Box>
          </Box>
        </Grid>

        <Grid
          item
          md={12}
          key={reset}
          sx={{
            '& .rs-notes--navbar__nav': {
              borderRadius: '5px',
              display: 'none',
            },
            '& .rs-notes--navbar__nav:first-of-type': {
              display: 'none',
            },
            '& .rs-notes--navbar__nav:nth-of-type(2)': {
              backgroundColor: '#078DEE',
            },

            '& .rs-notes--header--button__title': { p: '0px 10px !important' },
            '& .rs-notes--note': {
              position: 'initial !important',
              pr: '16px',
              boxShadow: 'white 0px 0px 0px !important',
              flexBasis: '20.833333%',
            },
            '& .rs-notes--note__body': {
              width: 'inherit !important',
            },
            '& .rs-notes': {
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              rowGap: '16px',
            },
            '& .rs-notes--header--button__hideshow': {
              verticalAlign: 'baseline !important',
              display: 'none',
            },
            '& .rs-notes--header--button': {
              p: 0,
            },

            '& .rs-notes--navbar': {
              display: 'none',
            },
            mb: 3,
          }}
        >
          <ReactStickyNotes
            notes={notes}
            onChange={async (action, noteData, notesList) => {
              //     console.log(action, noteData, notesList);

              let Add = action == 'add';
              let Update = action == 'update';
              let Delete = action == 'delete';

              let note = noteData.data;
              let noteId = note?.id;
              delete note['datetime'];
              delete note['selected'];
              delete note['menu'];

              if (note?.position) {
                note['position'] = Object.values(note?.position).join(',');
              }

              let url = Add
                ? `${localStorage.getItem('client')}/${localStorage.getItem('username')}`
                : Update
                  ? `${noteId}/${localStorage.getItem('username')}`
                  : Delete && `${noteId}`;

              let httpMethod = Add ? 'POST' : Update ? 'PUT' : Delete ? 'Delete' : null;
              delete note['id'];
              if (httpMethod && url) {
                let headers = {
                  method: httpMethod,
                  headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                  },
                };

                if (!Delete) {
                  headers['body'] = note;
                }

                let response = await HttpRequest(`notes/${url}`, headers);

                let json = await response.json();
                // console.log(json);
                if (Add) {
                  note['id'] = json;
                  localStorage.setItem('react-sticky-notes', JSON.stringify([...notes, note]));
                  setNotes((prev) => [...prev, note]);
                }
              }

              if (Delete) {
                let actualNotes = notes.filter((note) => note.id != noteId);
                setNotes(actualNotes);
                localStorage.setItem('react-sticky-notes', JSON.stringify(actualNotes));

                if (actualNotes.length < 1) {
                  let note = {
                    color: 'hsl(90,50%, 50%)',
                    text: '',
                    position: '0,0',
                  };
                  HttpRequest(
                    `notes/${localStorage.getItem('client')}/${localStorage.getItem('username')}`,
                    'POST',
                    note
                  )
                    .then((data) => data)
                    .then((json) => {
                      note['id'] = json;
                      localStorage.setItem('react-sticky-notes', JSON.stringify([note]));
                      setNotes((prev) => [...prev, note]);
                    });

                  setTimeout(() => {
                    setReset((prev) => prev + 1);
                  }, 2000);
                }
              }
            }}
          />
        </Grid>
      </Grid>
    </>
  );
}
