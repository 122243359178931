import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  InputAdornment,
  Paper,
  Typography,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';

// eslint-disable-next-line import/no-unresolved
import { CardPanel } from 'src/controls/cardpanel';
import { DropDownGREYERP } from 'src/controls/dropdown';
import { TextBoxGREYERP } from 'src/controls/textbox';
import NavHorizontal from 'src/layouts/dashboard/nav/NavHorizontal';
import Picker from 'src/controls/picker';
import { ChequeStatus, PaymentMode, PayrollFrequency } from 'src/data/data';
import { DropDownENUM } from 'src/controls/dropdownenum';
import { DropzoneGREY } from 'src/controls/dropzone';
import UppyFilePicker from 'src/controls/uppyFilePicker';
import { ItemsComponent } from 'src/controls/items/ItemsComponent';
import { FormContext } from 'src/layouts/dashboard/DashboardLayout';
import ChequeDetails from 'src/controls/chequedetail';
import { formatdecimals } from 'src/data/scripts';
export default function SalarySlip() {
  const { object, setObject, objectEdit } = useContext(FormContext);
  const [visibleAccount, setVisibleAccount] = useState(false);
  const [visibleChequeDetail, setVisibleChequeDetail] = useState(false);
  const [SelectedValuePaymentMode, setSelectedValuePaymentMode] = useState();
  const [SelectedValueTaxCategory, setSelectedValueTaxCategory] = useState();
  const [grossAmount, setGrossAmount] = useState(0);
  const [deductionsAmount, setDeductionsAmount] = useState(0);
  const [netPayAmount, setNetPayAmount] = useState(0);
  const [salaryStartDate, setSalaryStartDate] = useState();
  const [Employeesalarysummmary, setEmployeesalarysummmary] = useState([]);
  const [SelectedValue, setSelectedValue] = useState();

  const [salaryStructureEarning, setSalaryStructureEarning] = useState([]);
  const [salaryStructureDeduction, setSalaryStructureDeduction] = useState([]);
  function onChangePaymentMode(e, selectedValue) {
    if (selectedValue != null) {
      if (selectedValue?.Name == 'Cash' || selectedValue?.Name == 'Petty Cash') {
        setVisibleAccount(false);
        setVisibleChequeDetail(false);
      } else if (selectedValue?.Name == 'OnlineTransfer') {
        setVisibleAccount(true);
        setVisibleChequeDetail(false);
      } else if (selectedValue?.Name == 'Cheque') {
        setVisibleAccount(true);
        setVisibleChequeDetail(true);
      }
    }
    setSelectedValuePaymentMode(selectedValue?.ID);
    setObject((prev) => {
      return { ...prev, ['Mode']: selectedValue?.ID };
    });
  }
  function onChangeEarningAmount(event, values, tax, enter = false) {
    let TotalGrossAmount = 0;
    let NetPayAmount = 0;
    let TotalDeductionAmount = deductionsAmount;
    values?.items?.map((item) => {
      TotalGrossAmount += parseFloat(item.Amount);
      NetPayAmount += parseFloat(item.Amount);
    });

    if (enter) {
      salaryStructureDeduction?.map((item) => {
        TotalDeductionAmount += parseFloat(item.Amount);
      });
    }

    TotalGrossAmount = formatdecimals(
      (TotalGrossAmount / object.CalendarDays) * object.PaymentDays
    );

    //console.log((TotalGrossAmount / object.CalendarDays) * object.PaymentDays,'payment')
    NetPayAmount = formatdecimals(TotalGrossAmount - TotalDeductionAmount);
    //NetPayAmount = (NetPayAmount < 0) ? 0 : NetPayAmount;
    setGrossAmount(TotalGrossAmount);
    setNetPayAmount(NetPayAmount);
    // setTotalAmount(GrandTotal);
    setObject((prev) => {
      return {
        ...prev,
        ['Gross']: TotalGrossAmount,
        ['Deductions']: deductionsAmount,
        ['NetPay']: NetPayAmount,
        ['ProfessionalTax']: '0',
      };
    });
  }
  function onChangeDeductionsAmount(event, values, tax, enter = false) {
    let TotalDeductionAmount = 0;
    let NetPayAmount = 0;
    let TotalGrossAmount = grossAmount;
    values?.items?.map((item) => {
      TotalDeductionAmount += parseFloat(item.Amount);
      NetPayAmount += parseFloat(item.Amount);
    });

    if (enter) {
      //  console.log('in')
      salaryStructureEarning?.map((item) => {
        TotalGrossAmount += parseFloat(item.Amount);
      });
    }

    TotalGrossAmount = formatdecimals(
      (TotalGrossAmount / object.CalendarDays) * object.PaymentDays
    );
    //console.log(TotalGrossAmount,TotalDeductionAmount,object.CalendarDays, object.PaymentDays)
    TotalDeductionAmount = formatdecimals(TotalDeductionAmount);
    NetPayAmount = formatdecimals(TotalGrossAmount - TotalDeductionAmount);
    //NetPayAmount = (NetPayAmount < 0) ? 0 : NetPayAmount;
    setDeductionsAmount(TotalDeductionAmount);
    setNetPayAmount(NetPayAmount);
    setObject((prev) => {
      return { ...prev, ['Deductions']: TotalDeductionAmount, ['NetPay']: NetPayAmount };
    });
  }
  const registerDataChange = (e, selectedValue, name) => {
    if (selectedValue != null) {
      setSelectedValue(selectedValue?.ID);
      setObject((prev) => {
        return { ...prev, ['Employee']: selectedValue?.ID };
      });
      if (salaryStartDate) {
        var now = new Date(salaryStartDate);
        let salaryEndDate = new Date(now.getFullYear(), now.getMonth() + 1, 1);
        const d = new Date();
        HttpRequest(
          `getRecords/employeeworkingdays/${localStorage.getItem(
            'username'
          )}/${localStorage.getItem(
            'client'
          )}/${salaryStartDate.toISOString()}/${salaryEndDate.toISOString()}/${selectedValue?.ID}`
        )
          .then((response) => response)
          .then((json) => {
            setEmployeesalarysummmary(json);
            // console.log(json);
            setObject((prev) => {
              return {
                ...prev,
                ['CalendarDays']: json.CalendarDays,
                ['Holidays']: json.Holidays,
                ['WorkingDays']: json.WorkingDays,
                ['PresentDays']: json.PresentDays,
                ['LeavesDays']: json.LeavesDays,
                ['AbsentDays']: json.AbsentDays,
                ['PaymentDays']: json.PaymentDays,
                ['LOPDays']: '0',
                ['LateComeDays']: '0',
                ['EarlyLeftDays']: '0',
                ['OvertimeHours']: '0',
              };
            });
          });

        ///
        HttpRequest(`getRecordById/empsalarystructure/${selectedValue?.ID}`)
          .then((response) => response)
          .then((json) => {
            HttpRequest(`getRecordById/salarystructureearnings/${json[0].SalaryStructure}`)
              .then((response) => response)
              .then((json) => {
                setSalaryStructureEarning(json);
              });

            HttpRequest(`getRecordById/salarystructuredeductions/${json[0].SalaryStructure}`)
              .then((response) => response)
              .then((json) => {
                setSalaryStructureDeduction(json);
              });
          });
      }
    }
  };

  function onChangeStartDate(e) {
    setSalaryStartDate(e);
    var now = new Date(e.$d);
    let salaryEndDate = new Date(now.getFullYear(), now.getMonth() + 1, 0);
    setObject({ ...object, ['StartDate']: e.$d, ['EndDate']: salaryEndDate.toLocaleDateString() });
    // console.log(object);
  }

  //console.log(object);
  return (
    <>
      <CardPanel
        heading="Salary Slip Details"
        cardItem={
          <>
            <Picker type="date" name="Date" placeholder="Date" required focus={true}></Picker>
            <Picker
              type="date"
              name="StartDate"
              placeholder="Salary Month"
              postBackDate={salaryStartDate}
              onChange={onChangeStartDate}
              required
            ></Picker>
            <DropDownGREYERP
              items={`employees/name`}
              name="Employee"
              onChange={(e, selectedValue) => registerDataChange(e, selectedValue, 'Employee')}
              required
            />

            <TextBoxGREYERP
              placeholder="Number of Calendar Days"
              name="CalendarDays"
              type="number"
              disabled
              defaultValue={Employeesalarysummmary && Employeesalarysummmary.CalendarDays}
            />
            <TextBoxGREYERP
              placeholder="Number of Holidays"
              name="Holidays"
              type="number"
              disabled
              defaultValue={Employeesalarysummmary && Employeesalarysummmary.Holidays}
            />
            <TextBoxGREYERP
              placeholder="Number of Working Days"
              name="WorkingDays"
              type="number"
              disabled
              defaultValue={Employeesalarysummmary && Employeesalarysummmary.WorkingDays}
            />
            <TextBoxGREYERP
              placeholder="Number of Present Days"
              name="PresentDays"
              type="number"
              disabled
              defaultValue={Employeesalarysummmary && Employeesalarysummmary.PresentDays}
            />
            <TextBoxGREYERP
              placeholder="Number of Absent Days"
              name="AbsentDays"
              type="number"
              disabled
              defaultValue={Employeesalarysummmary && Employeesalarysummmary.AbsentDays}
            />
            <TextBoxGREYERP
              placeholder="Number of Leaves Days"
              name="LeavesDays"
              type="number"
              disabled
              defaultValue={Employeesalarysummmary && Employeesalarysummmary.LeavesDays}
            />
            <TextBoxGREYERP
              placeholder="Number of Payment Days"
              name="PaymentDays"
              type="number"
              disabled
              defaultValue={Employeesalarysummmary && Employeesalarysummmary.PaymentDays}
            />
          </>
        }
      />

      <ItemsComponent
        tablename="salaryslipearnings"
        heading={'Earnings'}
        disableCalculation={true}
        disableQuantity={true}
        existingItemsData={salaryStructureEarning}
        externalOnChange={onChangeEarningAmount}
        columnsToAppend={{ SalaryComponent: '', Amount: 0 }}
        columnsToShow={{ SalaryComponent: '', Amount: 0 }}
        editModeOnNew={true}
      />
      <ItemsComponent
        tablename="salaryslipdeductions"
        heading={'Deductions'}
        disableCalculation={true}
        disableQuantity={true}
        existingItemsData={salaryStructureDeduction}
        externalOnChange={onChangeDeductionsAmount}
        columnsToAppend={{ SalaryComponent: '', Amount: 0 }}
        columnsToShow={{ SalaryComponent: '', Amount: 0 }}
        editModeOnNew={true}
      />

      <CardPanel
        heading="Salary Details"
        cardItem={
          <>
            <TextBoxGREYERP
              placeholder="Gross"
              name="Gross"
              type="number"
              step="any"
              disabled
              defaultValue={grossAmount}
            />
            <TextBoxGREYERP
              placeholder="Deductions"
              name="Deductions"
              type="number"
              step="any"
              disabled
              defaultValue={deductionsAmount}
            />
            <TextBoxGREYERP
              placeholder="NetPay"
              name="NetPay"
              type="number"
              step="any"
              disabled
              defaultValue={netPayAmount}
            />
            <TextBoxGREYERP
              placeholder="Internal Notes"
              name="Comments"
              multiline={true}
              MultiLine={true}
            />

            <DropDownENUM
              items={PaymentMode}
              required
              name="Mode"
              onChange={onChangePaymentMode}
              postBackSelectedValue={SelectedValuePaymentMode}
            />
            {visibleAccount && (
              <DropDownGREYERP items={`bankaccounts/name`} name="FromAccount" required={true} />
            )}
          </>
        }
      />

      {visibleChequeDetail && <ChequeDetails />}
      <UppyFilePicker />
    </>
  );
}
