/* eslint-disable react/prop-types */
import { Button, FormControl, Grid, TextField } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { FormContext } from 'src/layouts/dashboard/DashboardLayout';
import Editor from 'src/components/editor';
import { sectionForms } from 'src/data/data';
import { useFormData } from 'src/hooks/useQueryHooks';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

export function TextEditorGrey({
  dir,
  onChange,
  placeholder,
  name,
  width,
  required,
  disabled,
  className,
  value,
  span,
  section,
  mr,
  height,
  mini = false,
  formatsExternal,
  defaultValue,
}) {
  const {
    // object,
    // setObject,
    // objectEdit,
    popupEditId,
    sections,
    setSections,
  } = useContext(FormContext);
  const [editorValue, setEditorValue] = useState(value ?? '');
  const { t } = useTranslation();

  let form = window.location.pathname?.split('/')[2]?.replaceAll('-', '');
  let ID = window.location.pathname?.split('/')[3]?.replaceAll('-', '') ?? popupEditId;

  const { setFormData: setObject, formData: object } = useFormData();

  const { data: objectEdit } = useQuery({
    queryKey: [form, ID],
    select: (res) => res[0],
    enabled: Boolean(form),
  });

  useEffect(() => {
    if (objectEdit && Object.keys(objectEdit).length > 0) {
      const objectPropertyNames = Object.keys(objectEdit);
      const valueArray = objectPropertyNames.filter(
        (item, index, object) => item?.toUpperCase() == name?.toUpperCase()
      );
      const valueEdit = objectEdit[valueArray[0]];
      valueEdit && setEditorValue(valueEdit);
    }
  }, [objectEdit, name]);

  useEffect(() => {
    if (defaultValue) {
      setEditorValue(defaultValue);
    }
  }, [defaultValue]);

  useEffect(() => {
    if (sectionForms.includes(window.location.pathname.split('/')[2].toUpperCase())) {
      if (sections[name]) {
        setEditorValue(sections[name]);
      }
    }
  }, []);

  function handleChange(newValue) {
    setEditorValue(newValue);
    if (section == 'section') {
      setSections((prevObject) => ({
        ...prevObject,
        [name]: newValue,
      }));
    } else {
      // setObject({ ...object, [name]: newValue });
      setObject({ [name]: newValue });
      if (onChange) {
        onChange(newValue, name);
      }
    }
  }

  return (
    <Grid
      md={span ?? 12}
      item
      sx={{
        '& .ql-formats': {
          mr: mr && '0px !important',
        },
        height: { height },
      }}
    >
      <FormControl>
        <Editor
          id={name}
          className={className}
          textAlign={dir ?? 'left'}
          required={required ?? false}
          size="small"
          margin="normal"
          variant="outlined"
          placeholder={placeholder}
          disabled={disabled ?? false}
          name={name}
          value={editorValue}
          onChange={handleChange}
          formatsExternal={formatsExternal}
          mini={mini}
        />
      </FormControl>
    </Grid>
  );
}
