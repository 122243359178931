import { CardPanel } from 'src/controls/cardpanel';
import Picker from 'src/controls/picker';
import { DropDownGREYERP } from 'src/controls/dropdown';
import { DropDownENUM } from 'src/controls/dropdownenum';
import { TextBoxGREYERP } from 'src/controls/textbox';
import { DepreciationMethod } from 'src/data/data';

export default function AssetRepair() {
  
  return (
    <>
    <CardPanel
      heading="Failure Details"
      cardItem={
        <>
                     <DropDownGREYERP items={`assets/name`}  name="Asset" required={true}/>
            <Picker type='date' name="FailureDate" focus={true} date={new Date()} required={true}></Picker>
            <TextBoxGREYERP  placeholder="Description" name="FailureDescription" span={2} multiline={true} />
          <TextBoxGREYERP  placeholder="Internal Notes" multiline={true}  span={2} name="Comments" />
        </>
      }
    />
      <CardPanel
      heading="Repair Details"
      cardItem={
        <>
              <Picker type='date' name="RepairDate" focus={true} date={new Date()} required={true}></Picker>
              <TextBoxGREYERP name="Cost" placeholder="500.000" type="number" step="any" />
              <DropDownGREYERP items="purchaseinvoices/name" name="PurchaseInvoice" />
            <TextBoxGREYERP  placeholder="Description" name="RepairDescription" span={2} multiline={true} />
        </>
      }
    />
   </>
  );
}
