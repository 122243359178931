import { Box, Modal, Typography, Grid, Tooltip, IconButton } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { FormContext } from 'src/layouts/dashboard/DashboardLayout';
import Iconify from 'src/components/iconify/Iconify';
import { SyncExternalProviderDataMeeting } from './syncexternalprovidermeeting';
import { msalConfig, loginRequest, tokenRequest } from 'src/outlook/authConfig';
import { callMSGraph, updateUI, graphConfig } from 'src/outlook/graph';
import { getTokenPopup } from 'src/outlook/authPopup';

function SyncExternalEmailProvider() {
  const {
    openPrint,
    setOpenPrint,
    openExternalProviderPopup,
    setOpenExternalProviderPopup,
    externalEmails,
    setExternalEmails,
  } = useContext(FormContext);
  const handleClose = () => {
    setOpenExternalProviderPopup(false);
  };
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    height: 'auto',
    bgcolor: 'background.paper',
    borderRadius: '10px',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

  const gapi = window.gapi;
  const google = window.google;
  const CLIENT_ID = '159614385395-fs71k5n5sibta0mg544ndq5oijpi9q5m.apps.googleusercontent.com';
  const API_KEY = 'AIzaSyAX5Qs8kvSvMrvrsdGvcDQ72Ve_aTuSTys';
  const DISCOVERY_DOC = 'https://people.googleapis.com/$discovery/rest';
  const SCOPES = 'https://mail.google.com/';

  const accessToken = null;
  const expiresIn = null;

  let gapiInited = false,
    gisInited = false,
    tokenClient;

  useEffect(() => {
    gapiLoaded();
    gisLoaded();
  }, []);

  function gapiLoaded() {
    gapi.load('client', initializeGapiClient);
  }

  async function initializeGapiClient() {
    gapi.client.init({
      apiKey: API_KEY,
      discoveryDocs: [DISCOVERY_DOC],
    });
    gapiInited = true;

    if (accessToken && expiresIn) {
      gapi.client.setToken({
        access_token: accessToken,
        expires_in: expiresIn,
      });
    }
  }

  function gisLoaded() {
    tokenClient = google.accounts.oauth2.initTokenClient({
      client_id: CLIENT_ID,
      scope: SCOPES,
      callback: '', // defined later
    });

    gisInited = true;
  }

  let labelMails = [
    {
      id: 'all',
      type: 'system',
      name: 'all mail',
      unreadCount: 0,
    },
    {
      id: 'inbox',
      type: 'system',
      name: 'inbox',
      unreadCount: 0,
    },
    {
      id: 'sent',
      type: 'system',
      name: 'sent',
      unreadCount: 0,
    },
    {
      id: 'drafts',
      type: 'system',
      name: 'drafts',
      unreadCount: 0,
    },
    {
      id: 'trash',
      type: 'system',
      name: 'trash',
      unreadCount: 0,
    },
    {
      id: 'spam',
      type: 'system',
      name: 'spam',
      unreadCount: 0,
    },
    {
      id: 'important',
      type: 'system',
      name: 'important',
      unreadCount: 0,
    },
    {
      id: 'starred',
      type: 'system',
      name: 'starred',
      unreadCount: 0,
    },
    {
      id: 'id_social',
      type: 'custom',
      name: 'social',
      unreadCount: 0,
      color: '#00AB55',
    },
    {
      id: 'id_promotions',
      type: 'custom',
      name: 'promotions',
      unreadCount: 0,
      color: '#FFC107',
    },
    {
      id: 'id_forums',
      type: 'custom',
      name: 'forums',
      unreadCount: 0,
      color: '#FF4842',
    },
  ];

  const mails = {
    allIds: [],
    byId: {},
  };

  async function handleGoogleAuthClick(e) {
    e.preventDefault();
    tokenClient.callback = async (resp) => {
      if (resp.error) {
        throw resp;
      }

      const { access_token, expires_in } = gapi.client.getToken();

      localStorage.setItem('access_token', access_token);
      localStorage.setItem('expires_in', expires_in);

      fetch(`https://gmail.googleapis.com/gmail/v1/users/me/messages`, {
        method: 'GET',
        headers: new Headers({ Authorization: `Bearer ${access_token}` }),
      })
        .then((response) => response.json())
        .then((json) => {
          json.messages.map((item) => {
            mails.allIds.push(item.id);
          });

          Array.from(json.messages).forEach((message) => {
            fetch(`https://gmail.googleapis.com/gmail/v1/users/me/messages/${message.id}`, {
              method: 'GET',
              headers: new Headers({ Authorization: `Bearer ${access_token}` }),
            })
              .then((response) => response.json())
              .then((mail) => {
                labelMails.map((label, index) => {
                  if (
                    mail.labelIds.includes(label.name.toUpperCase()) &&
                    mail.labelIds.includes('UNREAD')
                  ) {
                    labelMails[index].unreadCount += 1;
                  }
                });

                let mailStructure = {
                  id: mail.id,
                  message: mail.snippet,
                  attachments: [],
                  isUnread: true,
                  labelIds: labelMails,
                  from: {
                    name: '',
                    avatar: '',
                  },
                };

                mail.payload.headers.map((item) => {
                  if (item.name == 'Subject') {
                    mailStructure['subject'] = item.value;
                  } else if (item.name == 'To') {
                    mailStructure['to'] = item.value;
                  } else if (item.name == 'From') {
                    mailStructure.from.name = item.value;
                  } else if (item.name == 'Date') {
                    mailStructure['createdAt'] = item.value;
                  }
                });
                mails.byId[mail.id] = mailStructure;
              });
          });
          let emailsObj = {
            labels: labelMails,
            mails: mails,
          };
          setExternalEmails(emailsObj);
        });
    };

    //     const message =
    // "From: 1asmarafiq@gmail.com" +
    // "To: asmarafiq38@gmail.com" +
    // "Subject: As basic as it gets" +
    // "This is the plain text body of the message.  Note the blank line between the header information and the body of the message.";

    // // The body needs to be base64url encoded.
    // const encodedMessage = window.btoa(message)

    // const reallyEncodedMessage = encodedMessage.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '')

    // gapiLoaded();
    // gisLoaded();
    // console.log(gapi)
    // gapi.client.init({
    //   apiKey: API_KEY,
    //   discoveryDocs: [DISCOVERY_DOC],
    //   client_id: CLIENT_ID,
    //   scope: SCOPES,
    // }).then(res => {
    //   console.log('pop')
    //   return gapi.client.gmail.users.messages.send({
    //     userId: 'me',
    //     requestBody: {
    //       raw: reallyEncodedMessage
    //     }
    //   }).then(res => {
    //     console.log("done!", res)
    //   });
    // })

    if (!(accessToken && expiresIn)) {
      tokenClient.requestAccessToken({ prompt: 'consent' });
    } else {
      tokenClient.requestAccessToken({ prompt: '' });
    }
  }

  const myMSALObj = new Msal.UserAgentApplication(msalConfig);

  async function handleOutlookAuthClick(e) {
    e.preventDefault();

    myMSALObj
      .loginPopup(loginRequest)
      .then((loginResponse) => {
        // console.log("id_token acquired at: " + new Date().toString());
        // console.log(loginResponse);
        console.log(loginResponse);
        if (myMSALObj.getAccount()) {
          getTokenPopup(tokenRequest)
            .then((response) => {
              // console.log(response.accessToken);
              fetch(`https://graph.microsoft.com/v1.0/me/messages`, {
                method: 'GET',
                headers: new Headers({ Authorization: `Bearer ${response.accessToken}` }),
              })
                .then((response) => response.json())
                .then((json) => {
                  //      console.log(json)
                  Array.from(json.value).forEach((i) => {
                    fetch(`https://graph.microsoft.com/v1.0/me/messages/${i.id}`, {
                      method: 'GET',
                      headers: new Headers({ Authorization: `Bearer ${response.accessToken}` }),
                    })
                      .then((response) => response.json())
                      .then((mail) => {
                        //      console.log(mail);

                        mails.allIds.push(mail.id);

                        labelMails.map((label, index) => {
                          if (label.name == 'UNREAD' && mail.isRead == false) {
                            labelMails[index].unreadCount += 1;
                          }
                        });

                        let mailStructure = {
                          id: mail.id,
                          message: mail.bodyPreview,
                          attachments: [],
                          isUnread: mail.isRead ? false : true,
                          labelIds: labelMails,
                          from: {
                            name: mail.from.emailAddress.name,
                            avatar: '',
                          },
                          to: mail.toRecipients[0].name,
                          subject: mail.subject,
                          createdAt: mail.sentDateTime,
                        };

                        mails.byId[mail.id] = mailStructure;
                      });
                  });
                });
              let emailsObj = {
                labels: labelMails,
                mails: mails,
              };
              //    console.log(emailsObj)
              setExternalEmails(emailsObj);
              //callCalendarsGraph(graphConfig.graphCalendarsEndpoint, response.accessToken, updateUI);
              handleClose();
            })
            .catch((error) => {
              console.log(error);
            });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  return (
    <Modal
      open={openExternalProviderPopup}
      onClose={handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box sx={{ ...style, width: 600 }}>
        <Grid container alignItems={'center'}>
          <Grid item sx={{ ml: 'auto', displayPrint: 'none' }}>
            <Tooltip title="Close">
              <IconButton onClick={handleClose}>
                <Iconify icon="material-symbols:close" />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
        <Typography sx={{ fontSize: '24px', fontWeight: '700' }}>
          Sync {window.location.pathname.split('/')[2].charAt(0).toUpperCase()}
          {window.location.pathname.split('/')[2].slice(1)}
        </Typography>
        <Grid container sx={{ pt: 3 }} alignItems={'center'}>
          <Grid item xs={12} sm={4} sx={{ textAlign: 'center' }}>
            <button
              type="button"
              id="authorize_button_Google"
              hidden={accessToken && expiresIn}
              onClick={handleGoogleAuthClick}
              style={{
                backgroundImage: "url('https://uploads.greyerp.com/googlelogo.png')",
                backgroundSize: 'cover',
                border: 'none',
                backgroundColor: 'transparent',
                cursor: 'pointer',
                width: '118px',
                height: '118px',
              }}
            >
              {' '}
            </button>
          </Grid>

          <Grid item xs={12} sm={4} sx={{ textAlign: 'center' }}>
            <button
              type="button"
              id="authorize_button_Outlook"
              onClick={handleOutlookAuthClick}
              style={{
                backgroundImage: "url('https://uploads.greyerp.com/outlooklogo.png')",
                backgroundSize: 'cover',
                border: 'none',
                backgroundColor: 'transparent',
                width: '118px',
                height: '108px',
                cursor: 'pointer',
              }}
            >
              {' '}
            </button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}

export default SyncExternalEmailProvider;
