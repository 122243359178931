
import { CardPanel } from 'src/controls/cardpanel';
import { DropDownGREYERP } from 'src/controls/dropdown';
import { TextBoxGREYERP } from 'src/controls/textbox';
import { LeadSource, Titles, CompanyTypes, Access, Make, VehicleType, FuelLevel, FuelTypes, TransmissionTypes } from 'src/data/data';
import { DropDownENUM } from 'src/controls/dropdownenum';
import UploadGREY from 'src/controls/uploadgrey';
import Picker from 'src/controls/picker';
import { SwithGREYERP } from 'src/controls/swithgrey';
import { useContext, useEffect, useRef, useState } from 'react';
import { FormContext } from 'src/layouts/dashboard/DashboardLayout';
import { GetAndSetList, HandleOnChangeValue } from 'src/data/scripts';
import ImageContainer from 'src/controls/imagecontainer';
export default function Vehicle() {

  const { object, setObject, formObject,objectEdit, setFormObject } = useContext(FormContext);
  const { ModelList = [] } = formObject || {};
// let Make = "Acura";
  useEffect(() => {
     if (!isNaN(window.location.pathname.split('/')[3])) {
   
    GetAndSetList(`getItems/models/name/${localStorage.getItem('username')}/${localStorage.getItem('client')}/${objectEdit?.Make}`, setFormObject, 'ModelList' )
 } },[])
  return (
    <>
      <CardPanel
        heading="Vehicle Details"
        cardItem={
          <>
         <UploadGREY />
         <ImageContainer>
            <TextBoxGREYERP  placeholder="2603" type="Number" name="PlateNumber" required={true} focus={true} />
            <TextBoxGREYERP  placeholder="TB" name="Characters" required={true} />
            <TextBoxGREYERP  placeholder="Vehicle Key No." name="KeyNumber"   />
            <TextBoxGREYERP  placeholder="Vehicle Vin No." name="VINNumber" label="VIN Number"  />
            <DropDownENUM items={Make}
              onChange={(e, selectedValue) =>
                HandleOnChangeValue(
                  { Make: selectedValue?.ID },
                  setObject,
                  [
                  GetAndSetList(
                    `getItems/models/name/${localStorage.getItem('username')}/${localStorage.getItem(
                      'client'
                    )}/${selectedValue?.ID}`,
                    setFormObject,
                    'ModelList'
                  )
                    ]
                )
              }
            name="Make" postBackSelectedValue={Make}  required/>

     
            <DropDownGREYERP  postBackitems={ModelList} name="Model" required={true} />
            <TextBoxGREYERP  placeholder="Vehicle Year" name="Year" type="Number" required={true} />
            <TextBoxGREYERP  placeholder="Odometer" name="Mileage" required={true} />
            <DropDownENUM items={VehicleType} name="Type" required={true} />
            <DropDownGREYERP items="locations/name" name="Location" required={true} />
            <TextBoxGREYERP placeholder="Comments (Optional)" name="Comments" multiline={true} span={2} />
            <SwithGREYERP  name="Status" span={.5} />
            </ImageContainer>
          </>
        }
      />

<CardPanel
        heading="Vehicle Specifications"
        cardItem={
          <>
          <DropDownENUM items={TransmissionTypes} name="Transmission" />
          <TextBoxGREYERP  placeholder="1.6" name="EngineCapacity" type="Number" required={true} maxLen="4" step="any"/>
          <TextBoxGREYERP  placeholder="4" name="Cylinders" type="Number" required={true} maxLen="2" step="any"/>
          <DropDownENUM items={FuelTypes} name="FuelType" />
          <TextBoxGREYERP  placeholder="45" name="FuelTankCapacity" type="Number"  />
          <DropDownENUM items={FuelLevel} name="FuelLevel" required={true} />
          <TextBoxGREYERP  placeholder="5" name="Seats" type="Number"  />
          <TextBoxGREYERP  placeholder="4" name="Doors" type="Number"  maxLen="2" />
          <TextBoxGREYERP  placeholder="Black" name="InteriorColor"  />
          <TextBoxGREYERP  placeholder="White" name="ExteriorColor" />
          <SwithGREYERP  name="SpareTyre" span={.5} />
          <TextBoxGREYERP placeholder="Spare Tyre Note (Optional)" name="SpareTyreComments" label="Comments" multiline={true} span={2} />
          <SwithGREYERP  name="Tools" span={.5} />
          <TextBoxGREYERP placeholder="Tools Note (Optional)" name="ToolsComments" label="Comments" multiline={true} span={2} />
          </>
        }
      />


<CardPanel
        heading="Vehicle Rent"
        cardItem={
          <>
            <TextBoxGREYERP  placeholder="10.000"  name="DailyRent" type="Number" required={true} />
            <TextBoxGREYERP  placeholder="250"  name="DailyMileageLimit" label="Mileage Limit" type="Number"  sufix="Kms" required={true} />
            <TextBoxGREYERP  placeholder="0.050"  name="DailyExtraMileageCharge" label="Extra Mileage Charge" type="Number"  sufix=" / Km" required={true} />
            <TextBoxGREYERP  placeholder="50.000"  name="WeeklyRent" type="Number" required={true} />
            <TextBoxGREYERP  placeholder="2500"  name="WeeklyMileageLimit" label="Mileage Limit" type="Number"  sufix="Kms" required={true} />
            <TextBoxGREYERP  placeholder="0.050"  name="WeeklyExtraMileageCharge" label="Extra Mileage Charge" type="Number"  sufix=" / Km" required={true} />
            <TextBoxGREYERP  placeholder="150.000"  name="MonthlyRent" type="Number" required={true} />
            <TextBoxGREYERP  placeholder="12000"  name="MonthlyMileageLimit" label="Mileage Limit" type="Number"  sufix="Kms" required={true} />
            <TextBoxGREYERP  placeholder="0.050"  name="MonthlyExtraMileageCharge" label="Extra Mileage Charge" type="Number"  sufix=" / Km" required={true} />
          </>
        }
      />

<CardPanel
        heading="Registration Details"
        cardItem={
          <>
            <Picker type='date' name="RegistrationExpiry" placeholder="Expiry Date" required={true} ></Picker>
            <TextBoxGREYERP  placeholder="Insurance Policy No."  name="InsuranceNumber" required={true} />
            <Picker type='date' name="InsuranceExpiry" placeholder="Insurance Expiry" required={true} ></Picker>
            <Picker type='date' name="LastMaintenanceDate" placeholder="Last Maintenance Date" required={true} ></Picker>
            <TextBoxGREYERP  placeholder="Last Maintenance Mileage"  name="LastMaintenanceMileage" type="Number" required={true} />

          </>
        }
      />

{/* <CardPanel
        heading="Vehicle Features"
        cardItem={
          <>
 <CheckBoxGREYERP name="Blind_Spot" />
            <CheckBoxGREYERP name="Climate_Control" />
            <CheckBoxGREYERP name="Heated_Seats" />
            <CheckBoxGREYERP name="Leather_Seats" />
            <CheckBoxGREYERP name="Navigation_System" />
            <CheckBoxGREYERP name="Panorama_Sunroof" />
            <CheckBoxGREYERP name="Parking_Sensors" />
            <CheckBoxGREYERP name="Rearview_Camera" />
            <CheckBoxGREYERP name="Sunroof" />
            </>
        }
        />         */}
    
    </>
  );
}
