import { InputAdornment } from '@mui/material';

import { CardPanel } from 'src/controls/cardpanel';
import { DropDownGREYERP } from 'src/controls/dropdown';
import { TextBoxGREYERP } from 'src/controls/textbox';
import NavHorizontal from 'src/layouts/dashboard/nav/NavHorizontal';
import UppyFilePicker from 'src/controls/uppyFilePicker';
import Picker from 'src/controls/picker';
export default function Individual({showFilePicker=true}) {
  return (
    <>

      <CardPanel
        heading="Customer Details"
        cardItem={
          <>
           <UploadGREY />
         <ImageContainer>
            <TextBoxGREYERP label="Image" name="company" type="file" />
            <DropDownGREYERP optionsListAPI="" name="MR" label="Title" focus={true}/>
            <TextBoxGREYERP  placeholder="First Name" name="FirstName" />
            <TextBoxGREYERP  placeholder="Last Name" name="LastName" />
            <TextBoxGREYERP  placeholder="Customer Reference" name="Reference" />
            <DropDownGREYERP optionsListAPI="" name="Type" label="Group" />
            <DropDownGREYERP optionsListAPI="" name="Source" label="Currency" />
            <TextBoxGREYERP label="Current Balance" placeholder="Customer Reference" name="First Name" />
            <TextBoxGREYERP label="Profession" placeholder="Customer Reference" name="First Name" />
            <TextBoxGREYERP label="Date of Birth" type="date" name="First Name" />
            <DropDownGREYERP optionsListAPI="" name="Status" label="Source" />
            <DropDownGREYERP optionsListAPI="" name="Status" label="Access" />
            <TextBoxGREYERP label="Comments" name="Comments" multiline={true} />
            </ImageContainer>
          </>
        }
      />

      <CardPanel
        heading="Contact Details"
        cardItem={
          <>
            <TextBoxGREYERP label="Office" placeholder="2467XXXX" name="name" />
            <TextBoxGREYERP label="Ex" placeholder="ex" name="location" />
            <TextBoxGREYERP label="Phone" placeholder="09686372XXX" name="name" />
            <TextBoxGREYERP label="Phone 2" placeholder="09686372XXX" name="Comments" multiline={true} />
            <TextBoxGREYERP label="Email" placeholder="asma@gmail.com" name="Comments" multiline={true} />
            <TextBoxGREYERP label="Website" placeholder="www.google.com" name="Comments" multiline={true} />
            <TextBoxGREYERP label="Location" placeholder="Ghala, Muscat" name="Comments" multiline={true} />
            <TextBoxGREYERP label="Address" placeholder="Lead Address" name="Comments" multiline={true} />
          </>
        }
      />
            <CardPanel
        heading="License Details"
        cardItem={
          <>
           <TextBoxGREYERP name="License" type="file" value="" />
            <TextBoxGREYERP label="License Number" placeholder="100857418" name="LicenseNumber" />
            <Picker type='date' label="Expiry" placeholder="ex" name="LicenseExpiry" />
            <TextBoxGREYERP label="Issued At" placeholder="Muscat" name="LicenseIssue" />

          </>
        }
      />
    </>
  );
}
