
import AccordionGrid from 'src/controls/accordionGrid';
import PrintDocumentLayout from 'src/controls/printdocumentlayout';

export default function TrialBalance() {
  let TrialBalance = {
    hasDiffCols:false,
    showFooter:true
    
  };

  return (
    <PrintDocumentLayout>
       <AccordionGrid printLayout={TrialBalance}/>
    </PrintDocumentLayout>
  );
}
