import { DropDownENUM } from 'src/controls/dropdownenum';
import { TextBoxGREYERP } from 'src/controls/textbox';
import { SwithGREYERP } from 'src/controls/swithgrey';
import { CardPanel } from 'src/controls/cardpanel';
import Picker from 'src/controls/picker';
import { TextEditorGrey } from 'src/controls/texteditorgrey';
import { JobTypes } from 'src/data/data';
// import UppyFilePicker from 'src/controls/uppyFilePicker';
export default function Career() {
  const urlList = window.location.pathname.split('/');
  const CategoryName = (urlList[2].charAt(0).toUpperCase() + urlList[2].slice(1)).replaceAll(
    '-',
    ''
  );
    return (
        <>
        

          <CardPanel
        heading="Career Details"
        cardItem={
          <>
              

            <TextBoxGREYERP name="JobId" placeholder="SAL001"  focus={true} required={true} />
            <TextBoxGREYERP name="Title" placeholder="Job Title" required={true} />
            <TextBoxGREYERP name="TitleArabic" placeholder="Job Title in Arabic" required={true} dir="rtl"/>

            <DropDownENUM   items={JobTypes} name="Type"  />           
            <TextBoxGREYERP  name="Positions" placeholder="10"  type="Number"   />
            <TextBoxGREYERP name="Qualification" placeholder="Qualification" />
            <TextBoxGREYERP name="QualificationArabic" placeholder="Qualification in Arabic"   dir="rtl"/>

            <TextBoxGREYERP  name="Sort" placeholder="Sort" type="Number"  />
            <SwithGREYERP  name="Status" span={.5} />
          </>
        }
      />      



<CardPanel
        heading="Job Description"
        cardItem={
          <>
     
            <TextEditorGrey name="Description" span={2} multiline={true} placeholder="Description"/>
            <TextEditorGrey name="DescriptionArabic" placeholder="محتوى المدونة" dir="right"/>
           
          </>
        }
      />      

       <CardPanel
        heading="Other Details"
        cardItem={
          <>
        
            <TextBoxGREYERP name="Skills" placeholder="Skills" MultiLine={true} />
            <TextBoxGREYERP  name="Age" placeholder="29"  type="Number"  />
            <TextBoxGREYERP  name="Salary" placeholder="200"  type="Number"  />
            <TextBoxGREYERP  name="Workinghours" placeholder="Working hours"  type="Number"  />

            <TextBoxGREYERP name="Location" placeholder="Location" MultiLine={true}/>
            <TextBoxGREYERP name="LocationArabic" placeholder="Location Arabic" MultiLine={true}  dir="rtl"/>

            <TextBoxGREYERP name="Hyperlink" placeholder="Hyperlink" type="Url"/>
            <TextBoxGREYERP name="HyperlinkArabic" placeholder="Hyperlink - Arabic" type="Url"  />
           
          </>
        }
      />      





 </>
);
}