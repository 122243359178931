import { useEffect, useState } from 'react';
import { Tab, Card, Tabs, Container, Box } from '@mui/material';
import Iconify from '../../components/iconify';
import { useSettingsContext } from '../../components/settings';
import { ProfileCover } from 'src/sections/@dashboard/user/profile';
import { AccountChangePassword, AccountGeneral } from 'src/sections/@dashboard/user/account';
import Subscription from './subscription';
import UserSettings from './usersettings';
import { HttpRequest } from 'src/data/scripts';

export default function UserProfilePage() {
  const { themeStretch } = useSettingsContext();
  const [currentTab, setCurrentTab] = useState('profile');
  const [userDetails, setUserDetails] = useState({});
  const [reFetch, setReFetch] = useState(true);

  useEffect(() => {
    if (reFetch) {
      HttpRequest(`getRecordById/userdetails/${localStorage.getItem('username')}`)
        .then((response) => response)
        .then(async (json) => {
          setUserDetails((prev) => {
            return { ...prev, ...json[0] };
          });
          localStorage.setItem('clientpicture', json[0].Picture);
          setReFetch(false);

          let clientdetails = await HttpRequestGet(
            `getRecordById/clientdetails/${localStorage.getItem('client')}`
          );
          setUserDetails((prev) => {
            return { ...prev, ...clientdetails[0] };
          });
        });
    }
  }, [reFetch]);

  const TABS = [
    {
      value: 'profile',
      label: 'Profile',
      icon: <Iconify icon="ic:round-account-box" />,
      component: <AccountGeneral userInfo={userDetails} />,
    },
    {
      value: 'settings',
      label: 'Settings',
      icon: <Iconify icon="ant-design:setting-filled" />,
      component: <UserSettings userInfo={userDetails} />,
    },
    // {
    //   value: 'Integrations',
    //   label: 'Integrations',
    //   icon: <StorefrontIcon/>,
    //   component: <Integrations userInfo={userDetails}/>,
    // },
    {
      value: 'subscription',
      label: 'subscription',
      icon: <Iconify icon="eos-icons:subscriptions-created" />,
      component: <Subscription userInfo={userDetails} />,
    },
    {
      value: 'change password',
      label: 'change password',
      icon: <Iconify icon="material-symbols:key" />,
      component: <AccountChangePassword />,
    },
  ];

  return (
    <>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <Card
          sx={{
            mb: 3,
            height: '280px !important',
            position: 'relative',
          }}
        >
          <ProfileCover
            setReFetch={setReFetch}
            name={userDetails?.Username}
            role={userDetails?.Name}
            cover={`https://uploads.greyerp.com/${userDetails?.Picture}`}
            userPhoto={`https://uploads.greyerp.com/${userDetails?.Picture}`}
          />

          <Tabs
            value={currentTab}
            onChange={(event, newValue) => setCurrentTab(newValue)}
            sx={{
              width: 1,
              bottom: 0,
              zIndex: 9,
              position: 'absolute',
              bgcolor: 'background.paper',
              '& .MuiTabs-flexContainer': {
                pr: { md: 3 },
                justifyContent: {
                  sm: 'center',
                  md: 'flex-end',
                },
              },
            }}
          >
            {TABS.map((tab) => (
              <Tab key={tab.value} value={tab.value} icon={tab.icon} label={tab.label} />
            ))}
          </Tabs>
        </Card>

        {TABS.map(
          (tab) => tab.value === currentTab && <Box key={tab.value}> {tab.component} </Box>
        )}
      </Container>
    </>
  );
}
