import PropTypes from 'prop-types';
// @mui
import { Table, TableBody, TableContainer } from '@mui/material';
// components
import Scrollbar from '../../../../../components/scrollbar';
import { TableHeadCustom } from '../../../../../components/table';
//
import CheckoutCartProduct from './CheckoutCartProduct';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'product', label: 'Item' },
  { id: 'price', label: 'Price' },
  { id: 'quantity', label: 'Qty' },
  { id: 'totalPrice', label: 'Total', align: 'right' },
  { id: '' },
];

// -------  const theme = useTheme();---------------------------------------------------------------

CheckoutCartProductList.propTypes = {
  onDelete: PropTypes.func,
  products: PropTypes.array,
  onDecreaseQuantity: PropTypes.func,
  onIncreaseQuantity: PropTypes.func,
};

export default function CheckoutCartProductList({
  products,
 
  setOrderSummary,
  setSelectedProducts
}) {

  function onDelete(productId){
    let filteredProducts =[];
    setSelectedProducts(prev=>{
    filteredProducts = prev.filter((item)=>{
        if(item.id == productId){
          return false;
        }
        return true;
      });

      return filteredProducts;
    });

    if(filteredProducts.length > 0){
      setOrderSummary(prev=>{
        filteredProducts.map((item, index) => {
          prev.total += item.subtotal;
          prev.subtotal += item.subtotal;
        });
  
        return prev;
      })
    }else{
       let orderSumm = {
        total: 0.0,
        subtotal: 0.0,
        discount: 0,
      };
      setOrderSummary(orderSumm)
    }
   
  };

  function onIncreaseQuantity(productId){
    let filteredProducts =[];
    setSelectedProducts(prev=>{
      filteredProducts = prev.map((item)=>{
          if(item.id == productId){
            item.quantity +=1;
            item['subtotal'] = item.quantity * item.price;
            return item;
          }
          return item;
        });
  
        return filteredProducts;
      });

      setOrderSummary(prev=>{
        filteredProducts.map((item, index) => {
          prev.total += item.subtotal;
          prev.subtotal += item.subtotal;
        });
  
        return prev;
      })
  }

  function onDecreaseQuantity(productId){
    let filteredProducts =[];
    setSelectedProducts(prev=>{
     filteredProducts = prev.map((item)=>{
          if(item.id == productId){
            item.quantity -=1;
            item['subtotal'] = item.quantity * item.price;
            return item;
          }
          return item;
        });
  
        return filteredProducts;
      });

      setOrderSummary(prev=>{
     
        filteredProducts.map((item, index) => {
          prev.total += item.subtotal;
          prev.subtotal += item.subtotal;
        });
  
        return prev;
      })
  }
  


  return (
    <TableContainer sx={{ overflow: 'unset',overflowY:'auto',height:'inherit'}}>
      {/* <Scrollbar> */}
        <Table >
          <TableHeadCustom headLabel={TABLE_HEAD} />

          <TableBody>
            {products.map((row) => (
              <CheckoutCartProduct
                key={row.id}
                row={row}
                onDelete={() => onDelete(row.id)}
                onDecrease={() => onDecreaseQuantity(row.id)}
                onIncrease={() => onIncreaseQuantity(row.id)}
              />
            ))}
          </TableBody>
        </Table>
      {/* </Scrollbar> */}
    </TableContainer>
  );
}
