import { DropDownENUM } from 'src/controls/dropdownenum';
import { TextBoxGREYERP } from 'src/controls/textbox';
import { useTranslation } from 'react-i18next';

import PopUpPanel from 'src/controls/popuppanel';
import { EntryType } from 'src/data/data';
import { useEffect, useState } from 'react';
import { GroupedSelectAll } from 'src/controls/selectgrouped';
import { HttpRequest } from 'src/data/scripts';
export default function ChartOfAccountsPopup() {
  const [dataTbl, setDataTbl] = useState();
  const { t } = useTranslation();

  useEffect(() => {
    HttpRequest(
      `getrecords/accounts/${localStorage.getItem('username')}/${localStorage.getItem(
        'client'
      )}/${new Date(new Date()).toISOString()}/${new Date(new Date()).toISOString()}`
    )
      .then((response) => response)
      .then((data) => {
        const idMapping = data.reduce((acc, el, i) => {
          acc[el.ID] = i;
          return acc;
        }, {});

        let root;
        let header = [];
        let dataTbl1 = [];

        data.forEach((el) => {
          // Handle the root element
          if (el.ParentId == '0') {
            header.push(el.ID);
            return;
          }
        });

        data.forEach((el) => {
          if (el.ParentId == '0') {
            root = el;
            return;
          }
          const parentEl = data[idMapping[el.ParentId]];
          parentEl && (parentEl.subRows = [...(parentEl.subRows || []), el]);
          if (parentEl && header.includes(parentEl.ID)) {
            dataTbl1.push(parentEl);
          }
        });

        dataTbl1 = [...new Set(dataTbl1)];

        addingDepth(dataTbl1, 0);
        setDataTbl(dataTbl1);
      });
  }, []);

  function addingDepth(items, depth) {
    items.map((i) => {
      i['depth'] = depth;
      i.subRows && addingDepth(i.subRows, depth + 1);
    });
  }

  return (
    <>
      <PopUpPanel
        heading={'Add'}
        child={
          <>
            <GroupedSelectAll
              data={dataTbl}
              name={'ParentId'}
              label={'Parent Account'}
              required={true}
            />
            <TextBoxGREYERP span={2} name="Name" placeholder="Account Name" required={true} />
            <DropDownENUM span={2} items={EntryType} name="EntryType" required={true} />
            <TextBoxGREYERP
              span={2}
              name="OpeningBalance"
              placeholder="Opening Balance"
              required={true}
            />
          </>
        }
      />
    </>
  );
}
