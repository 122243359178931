
import { CardPanel } from 'src/controls/cardpanel';

import Picker from 'src/controls/picker';
import { DropDownGREYERP } from 'src/controls/dropdown';
import { TextBoxGREYERP } from 'src/controls/textbox';

export default function Downtime() {
 
  return (
    <>
      <CardPanel
        heading="Downtime Details"
        cardItem={
          <>
            <DropDownGREYERP items={`workcenters/name`} name="WorkCenter" required={true}   />
 <TextBoxGREYERP name="Machine" placeholder="Machine Name"/>
 <TextBoxGREYERP name="Part" placeholder="Part No. / Name"/>
            <DropDownGREYERP items={`downtimetypes/name`} name="Type" required={true}   />
            <DropDownGREYERP items={`employees/name`} name="Operator" placeholder="Select Operator" />
            <Picker type='time' name="StartDate"  required={true}></Picker>
 <Picker type='time' name="EndDate"  required={true}></Picker>
 <TextBoxGREYERP name="Description" multiline={true} span={2}/>
            <TextBoxGREYERP placeholder="Internal Notes" name="Comments" multiline={true}/>
          </>
        }
      />
    </>
  );
}

