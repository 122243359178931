import { useContext, useEffect, useState } from 'react';
import { CardPanel } from 'src/controls/cardpanel';
import { DropDownGREYERP } from 'src/controls/dropdown';
import { TextBoxGREYERP } from 'src/controls/textbox';
import { ItemsComponent } from 'src/controls/items/ItemsComponent';
import { SwithGREYERP } from 'src/controls/swithgrey';
import Picker from 'src/controls/picker';
import { GetAndSetList, GetAndSetValue, GetVoucherNumber, HandleOnChangeValue, getClientName } from 'src/data/scripts';
import { useFormData } from 'src/hooks/useQueryHooks';
import { useQuery } from '@tanstack/react-query';

export default function BillOfMaterial() {

  let form = window.location.pathname?.split('/')[2]?.replaceAll('-', '');
  let ID = window.location.pathname?.split('/')[3]?.replaceAll('-', '') ?? popupEditId;
  
  const { setFormData:setObject} = useFormData()
  const {data:object}= useQuery({
    queryKey:'form',
    })
  const {data:objectEdit}= useQuery({
      queryKey:[form, ID],
      select:(res)=>res[0],
      enabled: Boolean(form)
  })

const { Series = '', Number = '',  Unit,Item } = object || {};
const { Series: EditSeries = '', Number: EditNumber = '' } = objectEdit || {};

useEffect(() => {
  if (isNaN(window.location.pathname.split('/')[3])) {
    GetVoucherNumber(getClientName(), setObject)
  }
}, []);


  return (
    <>

<div id="divFields" className="divFields">
      <CardPanel
        heading='Document Details'
        cardItem={
          <>
            <Picker type='date' name="Date" focus={true} date={new Date()} required={true}></Picker>
            <DropDownGREYERP span={2} required={true} items={'items/name'} postBackSelectedValue={Item}
              onChange={(e, selectedValue) =>
                HandleOnChangeValue({ Item: selectedValue?.ID }, setObject, [
              GetAndSetValue( `getValue/items/baseunit/${selectedValue?.ID}`, setObject, 'Unit' ) 
            ]) }
              name="Item"
            />
            <DropDownGREYERP items={`baseunits/name`} postBackSelectedValue={Unit} name="Unit" required={true}   />
            <TextBoxGREYERP name="Quantity" type="Number" required={true} placeholder="1"  />
            <TextBoxGREYERP defaultValue={Series ? Series + Number : EditSeries + EditNumber} disabled required={true} name="Number" placeholder="Number" />
            <TextBoxGREYERP name="Reference" placeholder="Reference" />
            <DropDownGREYERP items={`routes/name`} name="Route"    />
            <DropDownGREYERP items={`routes/name`} name="Quality"    />
            <TextBoxGREYERP name="Description" multiline={true} placeholder="Description" span={2} />
            <TextBoxGREYERP name="Comments" multiline={true} placeholder="Internal Notes" span={2} />
          </>
        }
      />
    </div>
          <div id="divRawMaterial" className="divItems">
            <ItemsComponent
              heading={'Raw Material'}
              tablename={'billofmaterialrawmaterials'}
              formType={'purchasable'}
              disableQuantity={true}
              disableDiscount={true}
              disableTax={true}
              disableSubTotal={true}
              columnsToShow={{
                Item: 0,
                Description: '',
                Quantity: 1,
                Unit: 0,
                Price: 0,
                Total: 0.0,
              }}
              columnsToAppend={{
                Item: 0,
                Description: '',
                Quantity: 1,
                Unit: 0,
                Price: 0,
                Total: 0.0,
              }}
            />
          </div>
        <div id="divOperations" className="divItems">
        <ItemsComponent
          heading={'Operations'}
          tablename={'billofmaterialoperations'}
          disableCalculation={true}
          disableQuantity={true}
          columnsToShow={{
            Operation: 0,
            Description: '',
            Duration: 1,
            WorkCenter: 0,
          }}
          columnsToAppend={{
            Operation: 0,
            Description: '',
            Duration: 1,
            WorkCenter: 0,
          }}
        />
      </div>
       
    </>
  );
}
