import { useContext, useEffect, useState } from 'react';
import { CardPanel } from 'src/controls/cardpanel';
import { DropDownGREYERP } from 'src/controls/dropdown';
import { DropDownENUM } from 'src/controls/dropdownenum';
import { TextBoxGREYERP } from 'src/controls/textbox';
import { Priorities, Status } from 'src/data/data';
import Picker from 'src/controls/picker';
import React from 'react';
import { ItemsComponent } from 'src/controls/items/ItemsComponent';
import { FormContext } from 'src/layouts/dashboard/DashboardLayout';
import SwapSections from 'src/controls/SwapSections';

import { GetVoucherNumber, getClientName } from 'src/data/scripts';
import { useFormData } from 'src/hooks/useQueryHooks';
import { useQuery } from '@tanstack/react-query';

export default function PurchaseRequisition() {
  return (
    <>
      <SwapSections
        List={[
          <Fields />,
          <div id="divItems" className="divItems">
            <ItemsComponent
              formType="purchasable"
              displaySpecificCalc={true}
              disableCalculation={true}
              disableQuantity={true}
              disabledColumns={['TOTAL']}
              columnsToShow={{
                Item: 0,
                Unit: 0,
                EstimatedCost: 0,
                Quantity: 1,
                Note: ''
              }}
            />
          </div>,
        ]}
      />
    </>
  );
}

function Fields() {
  const {
    // object,
    // objectEdit,
    popupEditId,
  } = useContext(FormContext);

  let form = window.location.pathname?.split('/')[2]?.replaceAll('-', '');
  let ID = window.location.pathname?.split('/')[3]?.replaceAll('-', '') ?? popupEditId;

  const { setFormData: setObject } = useFormData();
  const { data: object } = useQuery({
    queryKey: 'form',
  });
  const { data: objectEdit } = useQuery({
    queryKey: [form, ID],
    select: (res) => res[0],
    enabled: Boolean(form),
  });

  const updateData = async () => {
    if (isNaN(window.location.pathname.split('/')[3])) {
      await GetVoucherNumber(getClientName(), setObject);
    }
  };

  useEffect(() => {
    updateData();
  }, []);

  const { Series = '', Number = '' } = object || {};
  const { Series: EditSeries = '', Number: EditNumber = '' } = objectEdit || {};

  return (
    <>
      <div id="divFields" className="divFields">
        <CardPanel
          heading="Purchase Requisition"
          cardItem={
            <>
              <Picker
                type="date"
                name="Date"
                required={true}
                date={new Date()}
                focus={true}
              ></Picker>
              <TextBoxGREYERP
                placeholder="Requisition Number"
                disabled
                name="Number"
                defaultValue={Series ? Series + Number : EditSeries + EditNumber}
                required={true}
              />
              <Picker type="date" name="DueDate" date={new Date()}></Picker>
              <DropDownGREYERP
                label="Suggest Vendor"
                items={`vendors/Name`}
                name="Vendor"
                multiple={true}
              />
              <TextBoxGREYERP placeholder="Purpose" name="Purpose" multiline={true} />
              <DropDownENUM items={Status} required={true} name="Status" />
              <DropDownENUM items={Priorities} required={true} name="Priority" />
              <TextBoxGREYERP placeholder="Document Subject" name="Subject" />
              <TextBoxGREYERP placeholder="Document Reference" name="Reference" />
              <TextBoxGREYERP
                placeholder="Internal Notes"
                span={2}
                name="Comments"
                multiline={true}
              />
            </>
          }
        />
      </div>
    </>
  );
}
