import PropTypes from 'prop-types';
import { useState, useEffect, useRef, useContext, useCallback } from 'react';
import { useLocation } from 'react-router-dom';

//
import { StyledPopover } from './styles';
import NavItem from './NavItem';
import { FormContext } from 'src/layouts/dashboard/DashboardLayout';
import useActiveLink from 'src/hooks/useActiveLink';

// ----------------------------------------------------------------------

NavList.propTypes = {
  data: PropTypes.object,
  depth: PropTypes.number,
  hasChild: PropTypes.bool,
};

export default function NavList({ data, depth, hasChild }) {
  const navRef = useRef(null);

  
  const { openHeader, setOpenHeader, openNavPath, setOpenNavPath,setObject,setObjectEdit } = useContext(FormContext);
  const [open, setOpen] = useState(false);


  const handleOpen = () => {
    if (openHeader && !data?.child) {
      setOpen(true);
      setOpenNavPath(data.path);
    }
  };


  const handleClose = () => {
    if (openHeader) {
    }
  };
  const handleOpenClick = useCallback(() => {
    setOpenHeader((prev) => {
      setOpen(true);
      setOpenNavPath(data.path);
      return !prev;
    });
  }, [setOpenHeader, setOpenNavPath, data.path]);
  

  const { ref, isComponentVisible } = useComponentVisible(true, setOpen);

  let headState = true;
  if(data?.children?.length > 0){
    headState = data.path.toUpperCase() == openNavPath?.toUpperCase() ? true : false;
  }
  return (
    <>
      <NavItem
        ref={navRef}
        //ref={ref}
        item={data}
        depth={depth}
        open={headState && open}
        //open={openPopover}
        active={
          data.path.toUpperCase() == `/${window.location.pathname.split('/')[1].toUpperCase()}`
        }
        isExternalLink={false}
        onMouseEnter={handleOpen}
        //onMouseLeave={handleClose}
        onClick={handleOpenClick}
      />

      {hasChild && (
        <StyledPopover
          open={headState && open}
          // ref={ref}
          anchorEl={navRef.current}
          anchorOrigin={
            depth === 1
              ? { vertical: 'bottom', horizontal: 'left' }
              : { vertical: 'center', horizontal: 'right' }
          }
          transformOrigin={
            depth === 1
              ? { vertical: 'top', horizontal: 'left' }
              : { vertical: 'center', horizontal: 'left' }
          }
          // anchorOrigin={{ vertical: 'center', horizontal: 'right' }}
          // transformOrigin={{ vertical: 'center', horizontal: 'left' }}
          // PaperProps={{
          //   onMouseEnter: handleOpen,
          //   onMouseLeave: handleClose,
          // }}
        >
          <NavSubList data={data.children} depth={depth} />
        </StyledPopover>
      )}
    </>
  );
}

// ----------------------------------------------------------------------

NavSubList.propTypes = {
  data: PropTypes.array,
  depth: PropTypes.number,
};

function NavSubList({ data, depth }) {
  return (
    <>
      {data.map((list) => (
        <NavList
          key={list.title + list.path}
          data={list}
          depth={depth + 1}
          hasChild={!!list.children}
        />
      ))}
    </>
  );
}

export function useComponentVisible(initialIsVisible, setOpen) {
  const [isComponentVisible, setIsComponentVisible] = useState(initialIsVisible);
  const ref = useRef(null);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsComponentVisible(false);
    }
    setOpen(false);
    setIsComponentVisible(true);
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);

    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  return { ref, isComponentVisible, setIsComponentVisible };
}
