import { useTheme } from '@mui/material/styles';
import { Stack, Divider } from '@mui/material';
import { useContext, useEffect } from 'react';
import { CardPanel } from "src/controls/cardpanel";

import { DropDownGREYERP } from "src/controls/dropdown";
import { DropDownENUM } from "src/controls/dropdownenum";
import { TextBoxGREYERP } from "src/controls/textbox";
import {  HolidayTypes, Status } from "src/data/data";
import { GetAndSetList, HandleOnChangeValue } from 'src/data/scripts';
import { FormContext } from 'src/layouts/dashboard/DashboardLayout';
import InvoiceAnalytic from 'src/sections/@dashboard/invoice/InvoiceAnalytic';

export default function LeaveApplication()
{
    const {object, setObject,objectEdit, formObject, setFormObject } = useContext(FormContext);
  const { LeaveTypeList,leaveSummary=[] } = formObject || {};
  const { Employee, Type } = object || {};


  useEffect(() => {
    if (!isNaN(window.location.pathname.split('/')[3])) {
        GetAndSetList(`getItems/employeeleavetypes/name/${localStorage.getItem('username')}/${localStorage.getItem( 'client' )}/${objectEdit["employee"]}`, setFormObject, 'LeaveTypeList' ) 
    }
  }, [window.location.pathname.split('/')[3]]);

  const theme = useTheme();

    return(
        <>
  <CardPanel heading="Application Details" cardItem={<>
 <Picker type='date' name="Date" focus={true} date={new Date()} required={true} ></Picker>
<DropDownGREYERP required={true} onChange={(e, selectedValue) =>
            HandleOnChangeValue( { Employee: selectedValue?.ID }, setObject, [ GetAndSetList(`getItems/employeeleavetypes/name/${localStorage.getItem('username')}/${localStorage.getItem( 'client' )}/${selectedValue?.ID}`, setFormObject, 'LeaveTypeList' ) ] ) } items={`employees/name`} name="Employee" postBackSelectedValue={Employee} />
            <DropDownGREYERP required={true} postBackitems={LeaveTypeList}
              onChange={(e, selectedValue) =>
            HandleOnChangeValue( { Type: selectedValue?.ID }, setObject, [GetAndSetList( `getItems/employeeleavetypedetails/Name/${localStorage.getItem( 'username' )}/${localStorage.getItem('client')}/${selectedValue?.ID}/${object.Employee}`, setFormObject, 'leaveSummary' ), ] ) } postBackSelectedValue={Type} name="Type" />

 <Picker type='date' name="StartDate"  required={true}></Picker>
 <Picker type='date' name="EndDate"  required={true}></Picker>
 <DropDownENUM items={HolidayTypes} name="Day"  required={true} />
 <DropDownENUM items={Status} name="Status"  required={true} />
 <TextBoxGREYERP placeholder="Reason" name="Reason" span={3} multiline={true}  />
 <TextBoxGREYERP placeholder="Comments" name="Comments" multiline={true} MultiLine={true} span={2}/>

</>} />

<CardPanel
          heading="Leave Summary"
          cardItem={
            <>
              <Stack direction="row" divider={<Divider orientation="vertical" flexItem sx={{ borderStyle: 'dashed' }} />} sx={{ py: 2 }}>
                <InvoiceAnalytic title="Allocated" price={leaveSummary[0]?.Allocated} suffix='Days' format={false} icon="fa6-solid:file-invoice-dollar" color={theme.palette.text.primary} />
                <InvoiceAnalytic title="Applied" price={leaveSummary[0]?.Applied} suffix='Days' format={false} icon="eva:clock-fill" color={theme.palette.warning.main} />
                <InvoiceAnalytic title="Rejected" price={leaveSummary[0]?.Rejected} suffix='Days' format={false} icon="clarity:list-line" color={theme.palette.error.main} />
                <InvoiceAnalytic title="Approved" price={leaveSummary[0]?.Approved} suffix='Days' format={false} icon="eva:checkmark-circle-2-fill" color={theme.palette.success.main} />
                <InvoiceAnalytic title="Available" price={leaveSummary[0]?.Available} suffix='Days' format={false} icon="eva:bell-fill" color={theme.palette.info.main} />
              </Stack>
            </>
          }
        />

   </>
    )
}
