import { CardPanel } from 'src/controls/cardpanel';
import { DropDownGREYERP } from 'src/controls/dropdown';
import { DropDownENUM } from 'src/controls/dropdownenum';
import { TextBoxGREYERP } from 'src/controls/textbox';
import { Access, CompanyTypes, PaymentTerms } from 'src/data/data';
import UploadGREY from 'src/controls/uploadgrey';
import ImageContainer from 'src/controls/imagecontainer';

export default function Client() {
  return (
    <>
      <CardPanel
        heading="Client Details"
        cardItem={
          <>
            <UploadGREY />
            <ImageContainer>
              <DropDownENUM items={CompanyTypes} name="Type" focus={true} />
              <TextBoxGREYERP
                span={2}
                label="Name"
                name="Company"
                placeholder="Client Name"
                required={true}
              />
              <TextBoxGREYERP label="Reference" name="Reference" placeholder="Client Reference" />
              <DropDownGREYERP items={`currencies/name`} name="Currency" required={true} />
              <TextBoxGREYERP
                label="Current Balance"
                name="Balance"
                placeholder="Opening Balance"
                required={true}
              />
              <TextBoxGREYERP name="CreditLimit" placeholder="Credit Limit" required={true} />
              <DropDownGREYERP items={`salespricelists/name`} name="PriceList" />
              <DropDownENUM items={PaymentTerms} name="PaymentTerms" />
              <TextBoxGREYERP label="VAT Number" name="TaxNumber" placeholder="VAT Number" />
              <DropDownENUM items={Access} name="Access" />
              <TextBoxGREYERP
                placeholder="Client Description"
                multiline={true}
                name="Description"
                span={2}
              />
              <TextBoxGREYERP
                placeholder="Internal Notes"
                multiline={true}
                span={2}
                name="Comments"
              />
            </ImageContainer>
          </>
        }
      />
      <CardPanel
        heading="Contact Details"
        cardItem={
          <>
            <TextBoxGREYERP
              label="Person Name"
              name="FirstName"
              placeholder="Smith"
              required={true}
            />
            <TextBoxGREYERP
              label="Office"
              name="Office"
              placeholder="009682465XXXX"
              type="Number"
            />
            <TextBoxGREYERP label="Ex" name="Extension" placeholder="ex" type="Number" />
            <TextBoxGREYERP name="Phone" placeholder="009689461XXXX" required={true} />
            <TextBoxGREYERP name="Phone2" placeholder="009689461XXXX" />
            <TextBoxGREYERP label="Email" name="Email" placeholder="smith@abc.com" type="Email" />
            <TextBoxGREYERP
              label="Website"
              name="Website"
              placeholder="www.google.com"
              type="Url"
            />
            <TextBoxGREYERP label="Location" name="Location" placeholder="Ghala, Muscat." />
            <TextBoxGREYERP
              label="Address"
              name="Address"
              multiline={true}
              span={2}
              placeholder="Rusayl Industrial Estate, Road No... "
            />
          </>
        }
      />
    </>
  );
}
