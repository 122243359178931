import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
// @mui
import { Container, Card, Stack } from '@mui/material';

// components
import { useSettingsContext } from '../../../components/settings';
// sections
import MailNav from './nav/MailNav';
import MailList from './list/MailList';
import MailHeader from './header/MailHeader';
import MailDetails from './details/MailDetails';
import MailComposePortal from './MailComposePortal';
import MailHeaderDetails from './header/MailHeaderDetails';

import { FormContext } from 'src/layouts/dashboard/DashboardLayout';
import useResponsive from 'src/hooks/useResponsive';

// ----------------------------------------------------------------------

export default function Mail() {
  const { themeStretch } = useSettingsContext();

 

  const params = useParams();

  const { mailId = '' } = params;

  

  const [dense, setDense] = useState(false);

  const [openNav, setOpenNav] = useState(false);

  const [openCompose, setOpenCompose] = useState(false);

  const [selectedMails, setSelectedMails] = useState([]);
  const [mails, setMails] = useState()
  const [mail, setMail] = useState()
  const [lables, setLables] = useState([])
  const [isLoading, setisLoading] = useState(false)

  const { externalEmails, setExternalEmails } =
  useContext(FormContext);


  useEffect(() => {
    if(externalEmails){
      setMails(externalEmails.mails);
      setLables(externalEmails.labels)
    }

  }, [externalEmails])



  useEffect(() => {
    if(mails){
     // console.log(mails.byId,mailId)
      setMail(mails.byId[mailId])
    }
 
  }, [mailId])
  
  

  // useEffect(() => {
  //   dispatch(getMails(params));
  // }, [dispatch, params]);

  // useEffect(() => {
  //   if (mailId) {
  //     dispatch(getMail(mailId));
  //   }
  // }, [dispatch, mailId]);

  // useEffect(() => {
  //   dispatch(getLabels());
  // }, [dispatch]);

  //console.log(mail,mailId)

  useEffect(() => {
    if (openCompose) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  }, [openCompose]);

  const handleToggleDense = () => {
    setDense(!dense);
  };

  const handleOpenNav = () => {
    setOpenNav(true);
  };

  const handleCloseNav = () => {
    setOpenNav(false);
  };

  const handleOpenCompose = () => {
    setOpenCompose(true);
  };

  const handleCloseCompose = () => {
    setOpenCompose(false);
  };

  const handleSelectMail = (selectedMailId) => {
    setSelectedMails((mailIds) => {
      if (!mailIds.includes(selectedMailId)) {
        return [...mailIds, selectedMailId];
      }
      return mailIds;
    });
  };

  const handleSelectAllMails = () => {
    setSelectedMails(mails.allIds.map((id) => id));
  };

  const handleDeselectMail = (selectedMailId) => {
    setSelectedMails((selected) => selected.filter((id) => id !== selectedMailId));
  };

  const handleDeselectAllMails = () => {
    setSelectedMails([]);
  };

  return (
    <>
     
        <Card
          sx={{
            height: { md: '72vh' },
            display: { md: 'flex' },
          }}
        >
          <MailNav
            items={lables}
            openNav={openNav}
            onCloseNav={handleCloseNav}
            onOpenCompose={handleOpenCompose}
          />

          <Stack flexGrow={1} sx={{ overflow: 'hidden' }}>
            {mail ? (
              <>
                <MailHeaderDetails
                  mailFrom={mail.from}
                  mailTo={mail.to}
                  createdAt={mail.createdAt}
                />
                <MailDetails
                  subject={mail.subject}
                  message={mail.message}
                  attachments={mail.attachments}
                />
              </>
            ) : (
              <>
                <MailHeader
                  onOpenNav={handleOpenNav}
                  mailsLength={mails?.allIds?.length}
                  selectedMailsLength={selectedMails?.length}
                  onSelectAllMails={handleSelectAllMails}
                  onDeselectAllMails={handleDeselectAllMails}
                  onToggleDense={handleToggleDense}
                />
                <MailList
                  dense={dense}
                  mails={mails}
                  labels={lables}
                  onSelectMail={(id) => handleSelectMail(id)}
                  onDeselectMail={(id) => handleDeselectMail(id)}
                  selectedMails={(id) => selectedMails.includes(id)}
                  isLoading={isLoading}
                  isEmpty={!mails?.allIds?.length && !isLoading}
                />
              </>
            )}
          </Stack>
        </Card>
     

      {openCompose && <MailComposePortal onCloseCompose={handleCloseCompose} />}
    </>
  );
}
