import React, { useEffect, useState } from 'react';
import OrganizationalChart from 'src/components/organizational-chart/OrganizationalChart';

function OrganizationalChartHR() {
  const [orgData, setOrgData] = useState();
  useEffect(() => {
    HttpRequest(
      `getrecords/organizationchart/${localStorage.getItem('username')}/${localStorage.getItem(
        'client'
      )}`
    )
      .then((response) => response)
      .then((data) => {
        //  console.log(data);
        const idMapping = data.reduce((acc, el, i) => {
          acc[el.ID] = i;
          return acc;
        }, {});

        let root;
        let header = [];
        let dataTbl1 = [];
        data.forEach((el) => {
          // Handle the root element
          if (!el.parentid) {
            header.push(el.ID);
            return;
          }
        });

        data.forEach((el) => {
          if (!el.parentid) {
            root = el;
            return;
          }
          const parentEl = data[idMapping[el.parentid]];
          parentEl && (parentEl.children = [...(parentEl.children || []), el]);
          if (parentEl && header.includes(parentEl.ID)) {
            dataTbl1.push(parentEl);
          }
        });

        dataTbl1 = [...new Set(dataTbl1)];

        let newDataTbl = dataTbl1[0].children?.map((item) => {
          return {
            name: item.group,
            avatar: '',
            role: '',
            ID: '',
            parentid: '',
            group: item.role,
            children: [item],
          };
        });

        dataTbl1[0].children = newDataTbl;
        //console.log(dataTbl1,newDataTbl);

        // ,dataTbl1.map((item)=>{
        //   createData(item.Employee,item.Department,item.Designation,item.Photo, item.children ?? [])
        //  })
        setOrgData(...dataTbl1);
      });
  }, []);

  return (
    <div style={{ marginTop: '30px', marginBottom: '30px' }}>
      {orgData && <OrganizationalChart data={orgData} variant="group" lineHeight="64px" />}
    </div>
  );
}
const createData = (name, group, role, avatar, children) => {
  return {
    name,
    group,
    role,
    avatar,
    // children:children.length > 0 && recursiveOrgHierarchy(children)
  };
};

export default OrganizationalChartHR;
