import { DropDownGREYERP } from 'src/controls/dropdown';
import { TextBoxGREYERP } from 'src/controls/textbox';
import PopUpPanel from 'src/controls/popuppanel';
import UploadGREY from 'src/controls/uploadgrey';
import { SwithGREYERP } from 'src/controls/swithgrey';
export default function OurClient() {
    return (
        <>
                      <PopUpPanel
        heading={'Add'}
        child={
          <>
<UploadGREY span={2} />

             
                  <TextBoxGREYERP  name="Name" placeholder="Client Name" required={true} focus={true} />

                  <TextBoxGREYERP  name="NameArabic" placeholder="اسم" dir="rtl"/>
                
                          
            <TextBoxGREYERP  name="Description" span={2} multiline={true} placeholder="Client Description"  MultiLine={true}/>
            
            <TextBoxGREYERP  name="DescriptionArabic" placeholder="وصف"  MultiLine={true} dir="rtl"/>
          
           
          
            <DropDownGREYERP firstItem="0,Main" name="Category" items={`ourclientcategories/name`} required={true} />
            <TextBoxGREYERP  name="Hyperlink" placeholder="Hyperlink" type="Url"/>
               
            

            <TextBoxGREYERP  placeholder="Internal Notes" name="Comments" multiline={true} MultiLine={true} row={3} span={2} />

           
            <TextBoxGREYERP  name="Sort" placeholder="Sort" type="Number" span={.5} />
            <SwithGREYERP  name="Status" span={.5}/>
           
    </>
   }
   />
 </>
);
}