
import React from 'react'

import { Mail } from 'src/sections/@dashboard/mail'


function Mails() {
  
  return (
    <>

    <Mail/>
    </>
  )
}

export default Mails