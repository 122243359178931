
import { useContext, useEffect, useState } from 'react';
import { CardPanel } from 'src/controls/cardpanel';
import { DropDownGREYERP } from 'src/controls/dropdown';
import { ItemsComponent } from 'src/controls/items/ItemsComponent';
import { FormContext } from 'src/layouts/dashboard/DashboardLayout';
import { Button, Grid } from '@mui/material';
import { TextBoxGREYERP } from 'src/controls/textbox';
import { useSnackbar } from 'notistack';
import Picker from 'src/controls/picker';

export default function Attendance() {
  const { object,setExistingItemsData } = useContext(FormContext);
  const [checkin, setCheckin] = useState();
  const [checkout, setCheckout] = useState();
  const [employees, setEmployees] = useState();

  const { enqueueSnackbar } = useSnackbar();

  function onCheckinChange(e) {
    setCheckin(e)
  }
  function onCheckoutChange(e) {
    setCheckout(e)
  }

  function onEmployeesChange(e, newValue) {
      setEmployees(newValue.ID)
  }

  function addAttendance() {
    if (employees && checkin && checkout && object['StartDate'] && object['EndDate']) {
      let fromDate = new Date(object['StartDate']);
      let toDate = new Date(object['EndDate']);
      let attendanceList =[];
      while (fromDate < toDate) {
          let attendance = {};
          attendance['CheckIn'] = checkin;
          attendance['CheckOut'] = checkout;
          attendance['Employee'] = employees;
          attendanceList.push(attendance);
        fromDate.setDate(fromDate.getDate() + 1)
      }
      setExistingItemsData((prev=[])=>[...prev,...attendanceList]);

     // console.log(attendanceList)
     }else
     {
       enqueueSnackbar('Plese Enter Start Date, End Date, Employees, CheckIn & CheckOut!');
     }
  }

  return (
    <>
      <CardPanel
        heading="Attendance Details"
        cardItem={
          <>
            <TextBoxGREYERP name="Name" placeholder="Name"  required={true} focus={true} />
            <Picker type='date' name="StartDate" required={true} ></Picker>
            <Picker type='date' name="EndDate"  required={true} ></Picker>
            <TextBoxGREYERP name="Comments" span={2} multiline={true} placeholder="Comments" />
          </>
        }
      />
      <CardPanel
        heading="Add Bulk"
        cardItem={
          <>
            <DropDownGREYERP span={2} items={`employees/name`} onChange={onEmployeesChange} postBackSelectedValue={employees} name="Employee" placeholder="Select Employee" />
            <Picker type='time' span={1.2} onChange={onCheckinChange} postBackDate={checkin} name="CheckIn"  ></Picker>
            <Picker type='time' span={1.2} onChange={onCheckoutChange} postBackDate={checkout} name="CheckOut" ></Picker>
            <Grid item>
          <Button variant='outlined' size="small" onClick={addAttendance}>Add</Button>
            </Grid>
          </>
        }
      />

      <ItemsComponent
        heading={'Attendance'}
        tablename={'attendancedetails'}
        disableCalculation={true}
        disableQuantity={true}
        editModeOnNew={true}
        columnsToShow={{Employee:'', CheckIn: '', CheckOut: '' }}
        columnsToAppend={{Employee:'', CheckIn: '', CheckOut: '' }}
      />
    </>
  );
}
