import { useEffect, useMemo } from 'react';

//
import StyledProgressBar from './styles';
import nProgress from 'nprogress';

// ----------------------------------------------------------------------

export default function ProgressBar() {
  nProgress.configure({ showSpinner: false });

  useMemo(() => {
    nProgress.start();
  }, []);

  useEffect(() => {
    nProgress.done();
  }, []);

  return <StyledProgressBar />;
}
