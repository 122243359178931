import { Box, Checkbox, FormControlLabel, FormGroup, Grid, InputAdornment, Paper, Typography } from "@mui/material";
import { func } from "prop-types";
import { useContext } from "react";


 import { FormContext } from "src/App";
import { CardPanel } from "src/controls/cardpanel";
import { DropDownGREYERP } from "src/controls/dropdown";
import { DropDownENUM } from "src/controls/dropdownenum";
import { TextBoxGREYERP } from "src/controls/textbox";
import UploadGREY from "src/controls/uploadgrey";
import { Against, CompanyTypes, GrievanceStatus, PaymentTerms } from "src/data/data";
import NavHorizontal from "src/layouts/dashboard/nav/NavHorizontal";
import Editor from 'src/components/editor';
import 'react-quill/dist/quill.snow.css';
import { DropzoneGREY } from "src/controls/dropzone";
import UppyFilePicker from 'src/controls/uppyFilePicker';


export default function Resignation()
{



    return(
        <>
 
      <CardPanel heading="Resignation Details" cardItem={<>

        <DropDownGREYERP items={`employees/name`} name="Employee" placeholder="Select Employee" required={true} />

 <Picker type='date' name="ResignationDate" placeholder="Resignation Date"  required={true}></Picker>
 <Picker type='date' name="LastWorkingDay" placeholder="Last Working Day"  required={true}></Picker>
 <TextBoxGREYERP placeholder="30" name="NoticePeriod"  type="number"  />
 <TextBoxGREYERP placeholder="Resignation Reason" name="ResignationReason" />
 <Picker type='date' name="RegistrationApprovalDate" placeholder="Approval Date"  required={true}></Picker>
 <TextBoxGREYERP placeholder="Internal Notes" name="Comments" multiline={true} MultiLine={true} span={2}/>

</>} />

    
   </>


  )


}
