import { useState } from 'react';
import { paramCase } from 'change-case';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import { useNavigate } from 'react-router-dom';
// @mui
import { Link, Typography, Autocomplete, InputAdornment, TextField } from '@mui/material';

// components
import Image from '../../../../components/image';
import Iconify from '../../../../components/iconify';
import SearchNotFound from '../../../../components/search-not-found';

// ----------------------------------------------------------------------

export default function ShopProductSearch({setFilteredProducts,POSproducts}) {
  const navigate = useNavigate();

  const [searchProducts, setSearchProducts] = useState('');

  const [searchResults, setSearchResults] = useState([]);

  const handleChangeSearch = async (event) => {
    try {
      let value = event.target.value;
      setSearchProducts(value);
      if (value) {
        let searchValue = value.toUpperCase();
        let filteredProducts=[];
        setFilteredProducts(prev=>{
          filteredProducts = prev.filter((item)=>{
            if(item.name.toUpperCase().includes(searchValue) || item.sku.toUpperCase().includes(searchValue)) return item;
          })

          return filteredProducts;
        })

        setSearchResults(filteredProducts);
      }else{
        setSearchResults(POSproducts);
        setFilteredProducts(POSproducts)
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleGotoProduct = (name) => {
    navigate(PATH_DASHBOARD.eCommerce.view(paramCase(name)));
  };

  const handleKeyUp = (event) => {
    if (event.key === 'Enter') {
      handleGotoProduct(searchProducts);
    }
  };

  return (
  
        <TextField
         // {...params}
          //width={300}
          placeholder="Search..."
          onKeyUp={handleKeyUp}
          size='small'
          onChange={handleChangeSearch}
          sx={{'& input':{
            width:'200px !important'
          }}}
          InputProps={{
           // ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <Iconify icon="eva:search-fill" sx={{ ml: 1, color: 'text.disabled' }} />
              </InputAdornment>
            ),
          }}
        />
    
      // renderOption={(props, product, { inputValue }) => {
      //   const { name, cover } = product;
      //   const matches = match(name, inputValue);
      //   const parts = parse(name, matches);

      //   return (
      //     <li {...props}>
      //       <Image
      //         alt={cover}
      //         src={cover}
      //         sx={{ width: 48, height: 48, borderRadius: 1, flexShrink: 0, mr: 1.5 }}
      //       />

      //       <Link underline="none" onClick={() => handleGotoProduct(name)}>
      //         {parts.map((part, index) => (
      //           <Typography
      //             key={index}
      //             component="span"
      //             variant="subtitle2"
      //             color={part.highlight ? 'primary' : 'textPrimary'}
      //           >
      //             {part.text}
      //           </Typography>
      //         ))}
      //       </Link>
      //     </li>
      //   );
      // }}
    
  );
}
