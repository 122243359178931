import PropTypes from 'prop-types';
import { paramCase } from 'change-case';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Box, Card, Link, Stack, Fab, Typography } from '@mui/material';

// utils
import { fCurrency } from '../../../../utils/formatNumber';

// components
import Iconify from '../../../../components/iconify';
import Label from '../../../../components/label';
import Image from '../../../../components/image';
import { ColorPreview } from '../../../../components/color-utils';

// ----------------------------------------------------------------------

ShopProductCard.propTypes = {
  product: PropTypes.object,
};

export default function ShopProductCard({ product }) {
  const { id, name, cover, price, colors, status, available, sizes, priceSale } = product;

  

  const linkTo = '';

  const handleAddCart = async () => {
    const newProduct = {
      id,
      name,
      cover,
      available,
      price,
      colors: [colors[0]],
      size: sizes[0],
      quantity: 1,
    };
    try {
      dispatch(addToCart(newProduct));
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Card
      sx={{
        '&:hover .add-cart-btn': {
          opacity: 1,
        },
        height:'200px',
        width:'200px',
        borderRadius: 1
      }}
    >
      <Stack  justifyContent="space-between" >
      <Box sx={{ position: 'relative', p: 0.5 }}>
        {status && (
          <Label
            variant="filled"
            color={(status === 'sale' && 'error') || 'info'}
            sx={{
              top: 10,
              right: 10,
              zIndex: 9,
              position: 'absolute',
              textTransform: 'uppercase',
            }}
          >
            {status}
          </Label>
        )}

      

        <Image alt={name} src={cover} ratio="1/1" sx={{ borderRadius: 1}} />
      </Box>

      <Stack flex={1} spacing={1.5} sx={{ pl: '5px' }}   justifyContent="space-between" >
        <Typography  color="inherit"  sx={{fontSize:'12px'}}>
          {name}
        </Typography>

        <Stack direction="row" justifyContent="center" flex={1}>
          {/* <ColorPreview colors={colors} /> */}

          <Stack direction="row"  sx={{ typography: 'subtitle1' }}>
            <Box component="span" sx={{fontSize:'14px',textAlign:'right' }}>{fCurrency(price)}</Box>
          </Stack>
        </Stack>
      </Stack>
      </Stack>
     
    </Card>
  );
}
