
import Login from '../sections/auth/Login';


export default function LoginPage() {
  return (
    <>
 
      <Login />
    </>
  );
}
