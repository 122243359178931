import PropTypes from 'prop-types';
import { useContext, useState } from 'react';
import { Droppable, Draggable } from '@hello-pangea/dnd';
// @mui
import { Paper, Stack, Button, Tooltip, IconButton } from '@mui/material';

// components
import Iconify from '../../../../components/iconify';
import { useSnackbar } from 'notistack';
//
import KanbanTaskAdd from '../KanbanTaskAdd';
import KanbanTaskCard from '../KanbanTaskCard';
import KanbanColumnToolBar from './KanbanColumnToolBar';
import { FormContext } from 'src/layouts/dashboard/DashboardLayout';
import { columnInfo } from 'src/pages/projects/KanbanPage';
import { HttpRequest } from 'src/data/scripts';

// ----------------------------------------------------------------------

KanbanColumn.propTypes = {
  cards: PropTypes.object,
  index: PropTypes.number,
  column: PropTypes.object,
};

export default function KanbanColumn({
  column,
  index,
  cards,
  setRefresh,
  setCardsState,
  cardsState,
  idCounter,
  setIDCounter,
}) {
  const { projectTasks, setProjectTasks, setTaskData } = useContext(FormContext);

  const { enqueueSnackbar } = useSnackbar();

  const [openAddTask, setOpenAddTask] = useState(false);
  const urlList = window.location.pathname.split('/');
  const handleToggleAddTask = () => {
    setOpenAddTask(!openAddTask);
  };

  const handleCloseAddTask = () => {
    setOpenAddTask(false);
  };

  const handleDeleteTask = (cardId) => {
    dispatch(
      deleteTask({
        cardId,
        columnId: column.id,
      })
    );
    enqueueSnackbar('Delete success!');
  };

  const handleUpdateColumn = async (newName) => {
    try {
      if (newName != column.name) {
        HttpRequest(`columns/${localStorage.getItem('username')}/update/${column.id}`, 'POST', {
          columnName: newName,
        })
          .then((response) => response)
          .then((json) => {
            //   console.log(json);
            enqueueSnackbar('Update success!');
            setRefresh(true);
            //setName('');
            //setRefresh(true)
          });
        // dispatch(
        //   updateColumn(column.id, {
        //     ...column,
        //     name: newName,
        //   })
        // );
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeleteColumn = async () => {
    try {
      await HttpRequest(`columns/${column.id}`, 'DELETE').then((json) => {
        enqueueSnackbar('Column Deleted.');
        setRefresh(true);
      });
      //dispatch(deleteColumn(column.id));
      //enqueueSnackbar('Delete success!');
    } catch (error) {
      console.error(error);
    }
  };

  const handleAddTask = (task) => {
    if (window.location.pathname.split('/')[2].toUpperCase() == 'PROJECTS') {
      let taskDB;
      taskDB = {
        column: column.id,
        name: task.name,
        description: '',
        assignee: null,
        dueDateTime: null,
        Priority: 'Low',
        relatedto: null,
        Status: column.Name,
        createdBy: localStorage.getItem('username'),
        createdDate: new Date(Date.now() + 4 * (60 * 60 * 1000)).toISOString(),
      };

      let kanbanTask = {
        id: idCounter,
        name: task.name,
        description: '',
        assignee: [
          {
            id: '',
            avatar: '',
            name: '',
          },
        ],
        due: [''],
        attachments: [],
        comments: [],
        completed: null,
        Status: column.id,
        priority: 'Low',
      };
      setCardsState((prev) => {
        return { ...prev, [idCounter]: kanbanTask };
      });
      setIDCounter((prev) => prev + 1);
      setRefresh(true);
    } else {
      HttpRequest(
        `tasks/${localStorage.getItem('client')}/${localStorage.getItem('username')}`,
        'POST',
        {
          name: task.name,
          description: '',
          assignee: 0,
          dueDateTime: '',
          Priority: 'Low',
          project: 0,
          relatedto: 0,
          Status: columnInfo[column.id]['Name'],
        }
      )
        .then((response) => response)
        .then((id) => {
          task?.setName('');
          setTaskData((prev) => {
            let columns = prev.board.columns;
            let cards = prev.board.cards;
            let card = {
              id: id,
              name: task.name,
              description: '',
              assignee: [
                {
                  id: '',
                  avatar: '',
                  name: 0,
                },
              ],
              due: [''],
              attachments: [],
              comments: [],
              completed: 0,
              Status: 0,
              priority: 'Low',
            };

            cards[`${id}`] = card;
            for (const key in columns) {
              if (columns[key].name == columnInfo[column.id]['Name']) {
                columns[key].cardIds.push(id);
              }
            }
            let user = localStorage.getItem('username');
            let colOrderinLS = JSON.parse(localStorage.getItem('tasksort'));
            colOrderinLS[user] = columns;
            localStorage.setItem('tasksort', JSON.stringify(colOrderinLS));
            return {
              ...prev,
              ['board']: {
                ['cards']: cards,
                ['columnOrder']: prev.board.columnOrder,
                ['columns']: columns,
              },
            };
          });

          //handleCloseAddTask();
          // setRefresh(true);
        });
    }

    // dispatch(createColumn({ name }));

    // dispatch(
    //   addTask({
    //     card: task,
    //     columnId: column.id,
    //   })
    //s);
  };

  return (
    <Draggable draggableId={`${column.id}`} index={index}>
      {(provided) => (
        <Paper
          variant="outlined"
          {...provided.draggableProps}
          ref={provided.innerRef}
          sx={{
            px: 2,
            // height: '100%',
            // display: 'flex',
            // flexDirection: 'column',
            borderRadius: 1,
            // height: 'calc(100% - 112px)',
            // width: '100%',
            // borderStyle: 'dashed',
            // '& > div:nth-of-type(2)': {
            //   minHeight: '50px !important',
            //   maxHeight: 'calc(100vh - 390px)',
            //   overflowY: 'auto',
            // },
            bgcolor: (theme) =>
              theme.palette.mode === 'light' ? 'grey.100' : 'background.default',
          }}
        >
          <KanbanColumnToolBar
            columnName={column.name}
            onDelete={handleDeleteColumn}
            onUpdate={handleUpdateColumn}
            setRefresh={setRefresh}
          />

          <Stack spacing={3} {...provided.dragHandleProps}>
            {/* <KanbanColumnToolBar
              columnName={column.name}
              onDelete={handleDeleteColumn}
              onUpdate={handleUpdateColumn}
              setRefresh={setRefresh}
            /> */}

            <Droppable droppableId={`${column.id}`} type="task">
              {(columnProvided) => (
                <Stack
                  ref={columnProvided.innerRef}
                  {...columnProvided.droppableProps}
                  spacing={1}
                  //sx={{ width: 280 }}
                >
                  {column.cardIds.map((cardId, cardIndex) => (
                    <KanbanTaskCard
                      key={cardId}
                      index={cardIndex}
                      onDeleteTask={handleDeleteTask}
                      card={cards[cardId]}
                      setCardsState={setCardsState}
                      setRefresh={setRefresh}
                      cardsState={cardsState}
                    />
                  ))}
                  {columnProvided.placeholder}
                </Stack>
              )}
            </Droppable>
          </Stack>

          <Stack
            spacing={2}
            sx={{
              mt: 1,
              bgcolor: (theme) =>
                theme.palette.mode === 'light' ? 'grey.100' : 'background.default',
            }}
          >
            {openAddTask && (
              <KanbanTaskAdd
                setRefresh={setRefresh}
                onAddTask={handleAddTask}
                onCloseAddTask={handleCloseAddTask}
                handleToggleAddTask={handleToggleAddTask}
              />
            )}

            <Stack justifyContent="flex-start" direction={'row'}>
              <Button
                fullWidth
                size="large"
                color="inherit"
                disabled={openAddTask}
                startIcon={<Iconify icon="eva:plus-fill" />}
                onClick={handleToggleAddTask}
                sx={{ fontSize: 14 }}
              >
                Add Task
              </Button>
              {openAddTask && (
                <IconButton
                  onClick={(e) => setOpenAddTask(false)}
                  sx={{ my: '5px', mr: '3px', width: '40px', height: '40px' }}
                >
                  <Iconify icon="material-symbols:close" />
                </IconButton>
              )}
            </Stack>
          </Stack>
        </Paper>
      )}
    </Draggable>
  );
}
