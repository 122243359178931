import { useContext, useEffect, useState } from 'react';
import { CardPanel } from 'src/controls/cardpanel';
import Picker from 'src/controls/picker';
import { DropDownGREYERP } from 'src/controls/dropdown';
import { DropDownENUM } from 'src/controls/dropdownenum';
import { TextBoxGREYERP } from 'src/controls/textbox';
import { Nationality, Purpose, TenantStatus } from 'src/data/data';
import {
  GetAndSetList,
  GetAndSetValue,
  HandleOnChangeValue,
  datediffMonths,
} from 'src/data/scripts';
import { FormContext } from 'src/layouts/dashboard/DashboardLayout';
import { useQuery } from '@tanstack/react-query';
import { useFormData } from 'src/hooks/useQueryHooks';

export default function Tenant() {
  let formID = window.location.pathname?.split('/')[2]?.replaceAll('-', '');
  let ID = window.location.pathname?.split('/')[3]?.replaceAll('-', '');
  const { setFormData: setObject } = useFormData();
  const { data: object } = useQuery({ queryKey: 'form' });
  const { data: objectEdit } = useQuery({
    queryKey: [formID, ID],
    select: (res) => res[0],
    enabled: Boolean(formID) && !!ID,
  });

  const { formObject, setFormObject } = useContext(FormContext);
  const {
    ContractTo,
    ContractFrom,
    Months,
    TotalRent,
    Rent,
    ManagementPercentage,
    ManagementFee,
    Unit,
    Building,
  } = object || {};
  const { UnitList = [] } = formObject || {};

  useEffect(() => {
    if (objectEdit && Object.keys(objectEdit).length > 0) {
      const {
        Building,
        Unit,
        Rent,
        ManagementFee,
        ManagementPercentage,
        Months,
        ContractFrom,
        ContractTo,
      } = objectEdit;

      setObject((prev) => {
        return {
          ...prev,
          Building,
          Unit,
          Rent,
          ManagementFee,
          ManagementPercentage,
          Months,
          ContractFrom: new Date(ContractFrom),
          ContractTo: new Date(ContractTo),
        };
      });
      if (!isNaN(window.location.pathname.split('/')[3])) {
        GetAndSetList(
          `getItems/unitstenantsedit/concat(UnitNo,' ',Type)/${localStorage.getItem(
            'username'
          )}/${localStorage.getItem('client')}/${objectEdit['Building']}/${
            window.location.pathname.split('/')[3]
          }`,
          setFormObject,
          'UnitList'
        );
      }
    }
  }, [objectEdit]);
  return (
    <>
      <CardPanel
        heading="Tenant Details"
        cardItem={
          <>
            <TextBoxGREYERP
              span={2}
              required={true}
              focus={true}
              placeholder="Tenant Name"
              name="Name"
            />
            <DropDownGREYERP
              required={true}
              onChange={(e, selectedValue) =>
                HandleOnChangeValue({ Building: selectedValue?.ID }, setObject, [
                  GetAndSetList(
                    !isNaN(window.location.pathname.split('/')[3])
                      ? `getItems/unitstenantsedit/name/${localStorage.getItem(
                          'username'
                        )}/${localStorage.getItem('client')}/${
                          window.location.pathname.split('/')[3]
                        }/${selectedValue?.ID}`
                      : `getItems/availableunits/name/${localStorage.getItem(
                          'username'
                        )}/${localStorage.getItem('client')}/${selectedValue?.ID}`,
                    setFormObject,
                    'UnitList'
                  ),
                ])
              }
              items={`buildings/name`}
              name="Building"
              postBackSelectedValue={Building}
            />
            <DropDownGREYERP
              required={true}
              postBackitems={UnitList}
              onChange={(e, selectedValue) =>
                HandleOnChangeValue({ Unit: selectedValue?.ID }, setObject, [
                  GetAndSetValue(
                    `getValue/units/rent/${selectedValue?.ID}`,
                    setFormObject,
                    'Rent',
                    setObject
                  ),
                ])
              }
              postBackSelectedValue={Unit}
              name="Unit"
              span={1.4}
            />
            <TextBoxGREYERP
              span={0.6}
              required={true}
              name="Rent"
              type="number"
              placeholder="250.000"
              step="any"
              min="0"
              defaultValue={Rent}
              onChange={(e) =>
                HandleOnChangeValue(
                  {
                    Rent: e.target.value,
                    ManagementFee:
                      (
                        e.target.value *
                        (parseFloat(ManagementPercentage) / parseFloat(100))
                      ).toFixed(3) || 0,
                    ...(ContractFrom &&
                      Months && {
                        ContractTo: (function () {
                          let To = new Date(ContractFrom);
                          To.setMonth(To.getMonth() + parseInt(Months));
                          To.setDate(To.getDate() - 1);
                          return To.toLocaleDateString();
                        })(),
                        TotalRent: e.target.value * Months,
                      }),
                  },
                  setObject
                )
              }
            />
            <DropDownENUM required={true} items={Purpose} name="Purpose" />
            <TextBoxGREYERP
              label="VAT Number"
              placeholder="Tax Number"
              type="number"
              name="TaxNumber"
            />
            <DropDownGREYERP
              required={true}
              items={`managements/name`}
              span={1.4}
              name="Management"
            />
            <TextBoxGREYERP
              span={0.6}
              required={true}
              placeholder="3"
              label="%"
              name="ManagementPercentage"
              type="number"
              onChange={(e) =>
                HandleOnChangeValue(
                  {
                    ManagementFee:
                      (Rent * (parseFloat(e.target.value) / parseFloat(100))).toFixed(3) || 0,
                    ManagementPercentage: e.target.value,
                  },
                  setObject
                )
              }
              step="any"
              maxLen="3"
              defaultValue={ManagementPercentage}
            />
            <TextBoxGREYERP
              required={true}
              placeholder="12.000"
              name="ManagementFee"
              type="number"
              step="any"
              onChange={(e) =>
                HandleOnChangeValue(
                  {
                    ManagementPercentage: (parseFloat(e.target.value) / (Rent || 0)) * 100 || 0,
                    ManagementFee: e.target.value,
                  },
                  setObject
                )
              }
              defaultValue={ManagementFee}
            />
            <TextBoxGREYERP label="ID Card No." placeholder="100283873" name="IDNo" type="number" />
            <TextBoxGREYERP label="CR No." placeholder="1209888" name="CRNo" type="number" />
            <TextBoxGREYERP placeholder="Tenant Sponsor Name" name="SponsorName" />
            <TextBoxGREYERP
              label="WaterUnits"
              placeholder="Current Water Units"
              name="CurrentWaterUnits"
              type="number"
            />
            <TextBoxGREYERP
              label="ElectricityUnits"
              placeholder="Electricity Units"
              name="CurrentElectricityUnits"
              type="number"
            />
            <TextBoxGREYERP required={true} placeholder="94635852" name="Phone" />
            <TextBoxGREYERP placeholder="87893285" name="Phone2" />
            <TextBoxGREYERP placeholder="abdullah@gmail.com" name="Email" type="email" />
            <DropDownENUM firstItemSelect={false} items={Nationality} name="Nationality" />
            <DropDownENUM items={TenantStatus} name="Status" />
            <TextBoxGREYERP placeholder="Tenant Reference" name="Reference" />

            <TextBoxGREYERP
              span={2}
              placeholder="Azaiba, way no: 57832"
              name="PreviousAddress"
              multiline={true}
            />

            <TextBoxGREYERP
              placeholder="Internal Notes"
              name="Comments"
              multiline={true}
              span={2}
            />
          </>
        }
      />

      <CardPanel
        heading="Contract Details"
        cardItem={
          <>
            <Picker
              type="date"
              placeholder="DD/MM/YYYY"
              name="ContractFrom"
              // type="date"
              required={true}
              postBackDate={ContractFrom}
              onChange={(e) =>
                HandleOnChangeValue(
                  {
                    ContractFrom: e,
                    ...(Months &&
                      ContractTo && {
                        Months: datediffMonths(e, new Date(ContractTo)),
                        TotalRent: Rent * datediffMonths(e, new Date(ContractTo)) || 0,
                      }),
                  },
                  setObject
                )
              }
            />
            <TextBoxGREYERP
              required={true}
              placeholder="12"
              name="Months"
              label="Period"
              onChange={(e) =>
                HandleOnChangeValue(
                  {
                    Months: e.target.value,
                    ...(ContractFrom && {
                      ContractTo: (function () {
                        let To = new Date(ContractFrom);
                        To.setMonth(To.getMonth() + parseInt(e.target.value));
                        To.setDate(To.getDate() - 1);
                        return To.toLocaleDateString();
                      })(),
                      TotalRent: e.target.value * Rent || 0,
                    }),
                  },
                  setObject
                )
              }
              min="1"
              defaultValue={Months}
              prefix="Months"
            />
            <Picker
              type="date"
              placeholder="DD/MM/YYYY"
              name="ContractTo"
              // type="date"
              required={true}
              postBackDate={ContractTo}
              onChange={(e) =>
                HandleOnChangeValue(
                  {
                    ContractTo: e,
                    ...(ContractFrom && {
                      Months: datediffMonths(new Date(ContractFrom), e),
                      TotalRent: Rent * datediffMonths(new Date(ContractFrom), e) || 0,
                    }),
                  },
                  setObject
                )
              }
            />
            <TextBoxGREYERP
              label="Previous Balance"
              placeholder="Opening Balance"
              name="Balance"
              type="number"
              step="any"
              required={true}
            />
            <TextBoxGREYERP
              placeholder="Total Rent"
              name="TotalRent"
              type="number"
              defaultValue={TotalRent}
              disabled
              required={true}
            />
          </>
        }
      />
    </>
  );
}
