/* eslint-disable react/prop-types */
import { FormControl, Grid, InputAdornment, Stack, TextField } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormContext } from 'src/layouts/dashboard/DashboardLayout';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import Link from '@mui/material/Link';
import { useTranslation } from 'react-i18next';
export function TextBoxItem({
  label,
  onChange,
  name,
  width,
  type,
  AdornMent,
  defaultValue = '',
  field,
  index,
  required,
  hidden = false,
  disabled = false,
  multiline,
  itemsMain = [],
  setReTrigger,
}) {
  const { t } = useTranslation();
  const { setValue, watch } = useFormContext();
  const values = watch();
  const handleFocus = (event) => event.target.select();
  const [imageLink, setImageLink] = useState('');
  useEffect(() => {
    const nameControl = name.split('.');

    values.items.map((item, index) => {
      for (const key in item) {
        if (key == nameControl[1] && field.name == `items[${index}].${nameControl[1]}`) {
          setReTrigger(true);
          field.onChange(item[key]);
        }

        if (key == 'Image' && field.name == `items[${index}].Image`) {
          setImageLink(item[key]);
        }
      }
    });
  }, [values.items[index], name]);

  let imageComp = imageLink ? (
    <Stack
      direction={'row'}
      justifyContent={'center'}
      alignItems={'center'}
      sx={{
        '& svg': {
          fill: '#078DEE !important',
        },
      }}
    >
      <AttachFileIcon fontSize="13px" />
      <Link
        target="_blank"
        sx={{ fontSize: '13px' }}
        href={`https://uploads.greyerp.com/${imageLink}`}
      >
        Attached File
      </Link>
    </Stack>
  ) : null;

  return (
    <Grid item sx={{ display: hidden && 'none' }}>
      <FormControl>
        <TextField
          name={name ? name : field.name}
          inputRef={field && field.ref}
          {...field}
          focused
          onFocus={handleFocus}
          required={
            name.split('.')[1] == 'Note' ? false : name.split('.')[1] == 'Image' ? false : true
          }
          size="small"
          margin="normal"
          variant="outlined"
          label={label ? t(label) : t(name?.match(/[A-Z][a-z]+|[0-9]+/g)?.join(' '))}
          onBlur={onChange}
          inputProps={{
            style: { fontSize: 13, fontWeight: 'light', backgroundColor: disabled && '#FBFCFD' },
          }}
          id="outlined-required"
          type={type}
          disabled={disabled}
          color="secondary"
          multiline={multiline}
          InputProps={{
            endAdornment: imageComp && <InputAdornment position="end">{imageComp}</InputAdornment>,
          }}
          //key={field.value}
          sx={{
            '& .MuiOutlinedInput-root.Mui-focused': {
              '& > fieldset': {
                borderColor: '#E5E8EB',
              },
            },
            '& .MuiFormLabel-root.Mui-focused': {
              color: 'text.primary',
            },
            input: {
              '&::placeholder': {
                // <----- Add this.
                opacity: 1,
                fontWeight: 'light',
                fontSize: 11,
              },
            },
            '& .Mui-disabled': {
              WebkitTextFillColor: 'black',
              color: 'black',
            },
          }}
        />
      </FormControl>
    </Grid>
  );
}
