import PropTypes from 'prop-types';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
// utils
import { bgBlur } from '../../../../utils/cssStyles';

// components
import Image from '../../../../components/image';
import { CustomAvatar } from '../../../../components/custom-avatar';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  '&:before': {
    ...bgBlur({
      color: theme.palette.primary.darker,
    }),
    top: 0,
    zIndex: 9,
    content: "''",
    width: '100%',
    height: '100%',
    position: 'absolute',
  },
}));

const StyledInfo = styled('div')(({ theme }) => ({
  left: 0,
  right: 0,
  zIndex: 99,
  position: 'absolute',
  marginTop: theme.spacing(5),
  [theme.breakpoints.up('md')]: {
    right: 'auto',
    display: 'flex',
    alignItems: 'center',
    left: theme.spacing(3),
    bottom: theme.spacing(3),
  },
}));

// ----------------------------------------------------------------------

ProfileCover.propTypes = {
  cover: PropTypes.string,
  name: PropTypes.string,
  role: PropTypes.string,
};

export default function ProfileCover({ name, role, cover, userPhoto, setReFetch }) {
  async function onSelectFile(e) {
    const formData = new FormData();
    formData.set('uploadedImage', e.target.files[0]);
    let response1 = await fetch(
      `https://uploads.greyerp.com/home/upload?table=${window.location.pathname
        .split('/')[2]
        ?.replaceAll('-', '')}&id=0&user=${localStorage.getItem('username')}&type=image`,
      {
        method: 'POST',
        body: formData,
      }
    );
    //let json1 = await response1.json();
    let response2 = await fetch(`changeprofileimage/0/${localStorage.getItem('username')}`, 'PUT', {
      profile: e.target.files[0].name,
    });

    let json2 = await response2.json();
    setReFetch(true);
  }

  return (
    <StyledRoot>
      <StyledInfo>
        <label for="getFile1">
          <input
            onChange={onSelectFile}
            type="file"
            tabindex="-1"
            id="getFile1"
            style={{ display: 'none' }}
          />
          <CustomAvatar
            src={userPhoto}
            name={'aas'}
            setReFetch={setReFetch}
            sx={{
              mx: 'auto',
              borderWidth: 2,
              borderStyle: 'solid',
              borderColor: 'common.white',
              width: { xs: 80, md: 128 },
              height: { xs: 80, md: 128 },
            }}
          />
        </label>

        <Box
          sx={{
            ml: { md: 3 },
            mt: { xs: 1, md: 0 },
            color: 'common.white',
            textAlign: { xs: 'center', md: 'left' },
          }}
        >
          <Typography variant="h4">{name}</Typography>

          <Typography sx={{ opacity: 0.72 }}>{role}</Typography>
        </Box>
      </StyledInfo>

      <Image
        alt="cover"
        src={cover}
        sx={{
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          position: 'absolute',
        }}
      />
    </StyledRoot>
  );
}
