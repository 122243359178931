import PropTypes from 'prop-types';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Stack, Radio, RadioGroup, FormControlLabel, Grid, Checkbox } from '@mui/material';
// components
import Iconify from '../../../../components/iconify';
import { FormContext } from 'src/layouts/dashboard/DashboardLayout';
import { useContext } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';

// ----------------------------------------------------------------------

export const PRIORITIZES_OPTIONS = [
  { value: 'low', label: 'Low', color: 'info' },
  { value: 'medium', label: 'Medium', color: 'warning' },
  { value: 'high', label: 'High', color: 'error' },
  { value: 'urgent', label: 'Urgent', color: 'black' },
];

KanbanDetailsPrioritizes.propTypes = {
  prioritize: PropTypes.string,
  onChangePrioritize: PropTypes.func,
};

export default function KanbanDetailsPrioritizes({
  prioritize,
  onChangePrioritize,
  priortizeOptionExternal,
  span,
  name=''
}) {
  const [prioritizeValue, setPrioritizeValue] = useState(false);
  const PRIORITIZES_OPTIONS_FINAL = priortizeOptionExternal
    ? priortizeOptionExternal
    : PRIORITIZES_OPTIONS;
    const { setObject, object,objectEdit } = useContext(FormContext);

    useEffect(() => {
      if (objectEdit && Object.keys(objectEdit)?.length > 0) {
      const objectPropertyNames = Object.keys(objectEdit);
      const value = objectPropertyNames.filter(
        (item, index, object) => item.toUpperCase() == name.toUpperCase()
      );
      
      const valueEdit = objectEdit[value[0]];
      valueEdit && setPrioritizeValue(valueEdit);
      }
    }, [objectEdit])

    const handleChangePrioritize = (event) => {
      setObject({ ...object, [name]: event.target.checked });
      setPrioritizeValue((prev) => !prev);
    };
  
  return (
    <Grid item md={span ?? 1} sx={{mt:1}}>
        <RadioGroup row value={prioritize} onChange={onChangePrioritize} name="Priority">
          {PRIORITIZES_OPTIONS_FINAL.map((option) => {
            const selected = option.value == prioritize;

            return (
              <Box key={option.value} sx={{ position: 'relative', mr: 1 }}>
                <Stack
                  spacing={0.5}
                  direction="row"
                  alignItems="center"
                  sx={{
                    pl: '4px',
                    pr: '10px',
                    mt: '4px',
                    fontWeight: 'fontWeightBold',
                    height: 28,
                    fontSize: 12,
                    borderRadius: 0.75,
                    border: (theme) => `solid 1px ${alpha(theme.palette.grey[500], 0.24)}`,
                    ...(selected && {
                      color: (theme) =>
                        option.color == 'black' ? option.color : theme.palette[option.color].main,
                      border: (theme) =>
                        `solid 1px ${
                          option.color == 'black' ? option.color : theme.palette[option.color].main
                        }`,
                      bgcolor: (theme) =>
                        option.color == 'black'
                          ? alpha('#000', 0.08)
                          : alpha(theme.palette[option.color].main, 0.08),
                    }),
                  }}
                >
                  <Stack
                    alignItems="center"
                    justifyContent="center"
                    sx={{ width: 16, height: 16, mr: 0.5 }}
                  >
                    {selected ? (
                      <Iconify icon="eva:checkmark-fill" width={16} />
                    ) : (
                      <Box
                        sx={{
                          width: 8,
                          height: 8,
                          borderRadius: '50%',
                          bgcolor: (theme) =>
                            option.color == 'black'
                              ? option.color
                              : theme.palette[option.color].main,
                        }}
                      />
                    )}
                  </Stack>
                  {option.label}
                </Stack>

                <FormControlLabel
                  value={option.value}
                  control={<Radio sx={{ display: 'none' }} />}
                  label=""
                  sx={{
                    m: 0,
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    position: 'absolute',
                  }}
                />
              </Box>
            );
          })}
        </RadioGroup>
    </Grid>
  );
}
