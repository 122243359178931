import { alpha, styled } from '@mui/material/styles';
import {
  Box,
  Card,
  Grid,
  Table,
  Divider,
  TableRow,
  TableBody,
  TableHead,
  TableCell,
  Typography,
  TableContainer,
  Button,
  Popover,
  Stack,
} from '@mui/material';
import { createContext, useContext, useEffect, useRef, useState } from 'react';

const StyledRowResult = styled(TableRow)(({ theme }) => ({
  '& td': {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));

export default function Subscription({ userDetails }) {
  const [clientDetails, setClientDetails] = useState();
  useEffect(() => {
    HttpRequest(`getRecordById/clientdetails/${localStorage.getItem('client')}`)
      .then((response) => response)
      .then((json) => {
        //  console.log(json)
        setClientDetails(json[0]);
      });
  }, []);
  return (
    <>
      <Card sx={{ pt: 5, px: 5 }}>
        <Grid item xs={12} sm={12} sx={{ mb: 5 }}>
          <Typography variant="subtitle1" sx={{ mb: 4 }}>
            Subsciption Details
          </Typography>
          <Grid container justifyContent={'space-around'}>
            <Grid item>
              <Typography paragraph variant="overline" sx={{ color: 'text.disabled' }}>
                Company ID
              </Typography>

              <Typography variant="body2">{localStorage.getItem('client')}</Typography>

              <Typography paragraph variant="overline" sx={{ color: 'text.disabled', mt: 3 }}>
                Registered Date
              </Typography>
              <Typography variant="body2">{clientDetails?.Date.substring(0, 10)}</Typography>
            </Grid>
            <Grid item>
              <Typography paragraph variant="overline" sx={{ color: 'text.disabled' }}>
                Modules
              </Typography>
              <Typography variant="body2"> {clientDetails?.Modules}</Typography>
              <Typography paragraph variant="overline" sx={{ color: 'text.disabled', mt: 3 }}>
                Users
              </Typography>
              <Typography variant="body2">{clientDetails?.Users}</Typography>
            </Grid>
            <Grid item>
              <Typography paragraph variant="overline" sx={{ color: 'text.disabled' }}>
                Expiry Date
              </Typography>
              <Typography variant="body2">{clientDetails?.Expiry.substring(0, 10)}</Typography>
              <Typography paragraph variant="overline" sx={{ color: 'text.disabled' }}></Typography>
              <Typography variant="body2"></Typography>
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </>
  );
}
