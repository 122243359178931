/* eslint-disable react/prop-types */
import { FormControl, Grid, TextField } from '@mui/material';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import * as React from 'react';
import { FormContext } from 'src/layouts/dashboard/DashboardLayout';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

export function DatePickerItem({
  placeholder,
  label,
  name,
  field,
  required,
  focus,
  index,
  onChange,
  span,
  disabled,
  setReTrigger,
}) {
  const [valueDate, setValueDate] = useState('');
  const { setValue, watch } = useFormContext();
  const values = watch();
  const { t } = useTranslation();

  const registerDataChange = (e, val) => {
    if (e.$d == 'Invalid Date') {
      return;
    } else {
      const nameControl = name.split('.');
      values.items.map((item, index) => {
        for (const key in item) {
          if (key == nameControl[1] && field.name == `items[${index}].${nameControl[1]}`) {
            setValueDate(dayjs(e.$d));
            item[key] = new Date(e.$d).toLocaleDateString();
            setReTrigger(true);
            field.onChange(item[key]);
          }
        }
      });
    }
  };

  useEffect(() => {
    const nameControl = name.split('.');
    values.items.map((item, index) => {
      for (const key in item) {
        if (key == nameControl[1] && field.name == `items[${index}].${nameControl[1]}`) {
          if (!isNaN(window.location.pathname.split('/')[3])) {
            if (values.items[index][key] != '1900-01-01T00:00:00.000Z') {
              let newdate = new Date(values.items[index][key]);
              setValueDate(dayjs(newdate));
            }
          } else {
            let newdate = values.items[index][key];
            setValueDate(dayjs(newdate));
          }
        }
      }
    });
  }, [values.items[index], name]);

  return (
    <Grid
      md={span ?? 1}
      lg={span ?? 1}
      item
      sx={{
        '& .MuiFormControl-root': {
          mt: '0px !important',
        },
      }}
    >
      <FormControl
        sx={{
          '& > div': {
            overflow: 'hidden',
          },
          '& > div > div': {
            flex: 1,
          },
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={['DesktopDatePicker']}>
            <DemoItem label="">
              <DesktopDatePicker
                disabled={disabled}
                label={label ? t(label) : t(name?.match(/[A-Z][a-z]+|[0-9]+/g)?.join(' '))}
                placeholder={placeholder}
                format="DD/MM/YYYY"
                value={valueDate ? valueDate : ''}
                id="outlined-required"
                name={name ? name : field.name}
                // slots={{
                //   textField: {
                //     onfocus:(e) =>{
                //       e.preventDefault()
                //       setFocused(true)
                //     }

                //   },
                // }}
                slotProps={{
                  textField: {
                    required: required,
                    autoFocus: focus,
                    focused: true,
                  },
                }}
                onChange={onChange ? onChange : registerDataChange}
                size="small"
                key={valueDate}
                margin="normal"
                sx={{
                  '& input': {
                    padding: '8.5px 14px !important',
                  },
                }}
              />
            </DemoItem>
          </DemoContainer>
        </LocalizationProvider>
      </FormControl>
    </Grid>
  );
}
