import PropTypes from 'prop-types';
// @mui
import { List, Stack } from '@mui/material';
// locales

//
import { StyledSubheader } from './styles';
import NavList from './NavList';

// ----------------------------------------------------------------------

NavSectionVertical.propTypes = {
  sx: PropTypes.object,
  data: PropTypes.array,
};

export default function NavSectionVertical({ data, sx,onCloseNav, ...other }) {


  return (
    <Stack sx={sx} {...other}>
       {/* <StyledSubheader disableSticky>{`Modules`}</StyledSubheader> */}
      {data.map((group) => {
        const key = group.subheader || group.items[0].title;

        return (
          <>
           
            <List key={key} disablePadding sx={{ px: 1 }}>
           
           {/* {group.subheader && (
             <StyledSubheader disableSticky>{`${translate(group.subheader)}`}</StyledSubheader>
           )} */}

           {group.items.map((list) => (
             <NavList 
               key={list.title + list.path}
               data={list}
               depth={1}
               hasChild={!!list.children}
               onCloseNav={onCloseNav}
             />
           ))}
         </List>
          </>
       
        );
      })}
    </Stack>
  );
}
