import { CardPanel } from 'src/controls/cardpanel';
import { DropDownGREYERP } from 'src/controls/dropdown';
import { TextBoxGREYERP } from 'src/controls/textbox';
import Picker from 'src/controls/picker';
import { DropDownENUM } from 'src/controls/dropdownenum';
import { MaintenanceStatus, PaymentMode, PaymentType, Priorities } from 'src/data/data';
import { useContext, useEffect, useState } from 'react';
import { FormContext } from 'src/layouts/dashboard/DashboardLayout';
import ChequeDetails from 'src/controls/chequedetail';
import { ItemsComponent } from 'src/controls/items/ItemsComponent';
import { GetAndSetList, HandleOnChangeValue, SelectPaymentMode } from 'src/data/scripts';
import { useQuery } from '@tanstack/react-query';
import { useFormData } from 'src/hooks/useQueryHooks';

export default function Maintenance() {
  let formID = window.location.pathname?.split('/')[2]?.replaceAll('-', '');
  let ID = window.location.pathname?.split('/')[3]?.replaceAll('-', '');
  const { setFormData: setObject } = useFormData();
  const { data: object } = useQuery({ queryKey: 'form' });
  const { data: objectEdit } = useQuery({
    queryKey: [formID, ID],
    select: (res) => res[0],
    enabled: Boolean(formID) && !!ID,
  });

  const { formObject, setFormObject } = useContext(FormContext);
  const { Building, Mode, Tax, SubTotal, Amount } = object || {};
  const { UnitList = [], showAccount, showChequeDetails } = formObject || {};

  useEffect(() => {
    if (objectEdit && Object.keys(objectEdit).length > 0) {
      GetAndSetList(
        `getItems/units/name/${localStorage.getItem('username')}/${localStorage.getItem(
          'client'
        )}/${objectEdit['Building']}/${window.location.pathname.split('/')[3]}`,
        setFormObject,
        'UnitList'
      );
    }
  }, [objectEdit]);

  return (
    <>
      <CardPanel
        heading="Maintenance Details"
        cardItem={
          <>
            <Picker type="date" focus={true} name="Date" required={true}></Picker>
            <DropDownGREYERP items={'maintenancecategories/name'} required={true} name="Category" />
            <TextBoxGREYERP placeholder="Title" name="Title" required={true} />
            <TextBoxGREYERP
              placeholder="Maintenance Description"
              name="Description"
              span={2}
              multiline={true}
            />
            <DropDownGREYERP
              required
              onChange={(e, selectedValue) =>
                HandleOnChangeValue({ Building: selectedValue?.ID }, setObject, [
                  GetAndSetList(
                    `getItems/units/name/${localStorage.getItem('username')}/${localStorage.getItem(
                      'client'
                    )}/${selectedValue?.ID}`,
                    setFormObject,
                    'UnitList'
                  ),
                ])
              }
              items={`buildings/name`}
              name="Building"
              postBackSelectedValue={Building}
            />
            <DropDownGREYERP postBackitems={UnitList} name="Unit" />
            <TextBoxGREYERP placeholder="Unit Availability" name="Availability" />
            <DropDownENUM items={Priorities} name="Priority" required={true} />
            <TextBoxGREYERP placeholder="Internal Notes" name="Comments" multiline={true} />
          </>
        }
      />
      <CardPanel
        heading="Assignee Details"
        cardItem={
          <>
            <DropDownGREYERP items={`clientusers/name`} name="Assignee" />
            <TextBoxGREYERP placeholder="2 hours" name="EstimatedTime" />
            <Picker type="date" name="DueDate"></Picker>
            <DropDownENUM
              items={MaintenanceStatus}
              name="RequestStatus"
              label="Status"
              required={true}
            />
          </>
        }
      />
      <div id="divItems" className="divItems">
        <ItemsComponent
          heading={'Material Details'}
          disableDiscount={true}
          appendCalculation={false}
          columnsToShow={{
            Name: '',
            Quantity: 1,
            Price: 0,
            Tax: 0,
            TaxAmount: 0,
            Total: 0.0,
          }}
          columnsToAppend={{
            Name: '',
            Quantity: 1,
            Price: 0,
            Tax: 0,
            TaxAmount: 0,
            Total: 0.0,
          }}
        />
      </div>
      ,
      <CardPanel
        heading="Payment Details"
        cardItem={
          <>
            <Picker type="date" name="PaymentDate"></Picker>
            <DropDownENUM items={PaymentType} name="PaymentType" />
            <DropDownGREYERP items={`tenants/name`} span={2} name="Tenant" />
            <DropDownENUM
              name="Mode"
              required
              items={PaymentMode}
              onChange={(e, selectedValue) => {
                SelectPaymentMode(selectedValue?.ID, setFormObject);
                HandleOnChangeValue(
                  {
                    Mode: selectedValue?.ID,
                    ...(selectedValue?.ID == 'Cash' && {
                      FromAccount: '1101',
                    }),
                  },
                  setObject
                );
              }}
              postBackSelectedValue={Mode}
            />
            {showAccount && (
              <DropDownGREYERP
                span={2}
                items={`bankaccounts/name`}
                name="Account"
                required={true}
              />
            )}
          </>
        }
      />
      {showChequeDetails && <ChequeDetails />}
    </>
  );
}
