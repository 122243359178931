import { CardPanel } from 'src/controls/cardpanel';
import Picker from 'src/controls/picker';
import { DropDownGREYERP } from 'src/controls/dropdown';
import { ItemsComponent } from 'src/controls/items/ItemsComponent';
import { TextBoxGREYERP } from 'src/controls/textbox';

export default function AssetMovement() {

  return (
    <>
      <CardPanel
        heading="Movement Details"
        cardItem={
          <>
            <Picker type='date' name="Date" focus={true} date={new Date()} required={true}></Picker>
            <DropDownGREYERP items={`assetmovementpurpose/name`}  name="Purpose" required={true}/>
              <TextBoxGREYERP name="Reference" />
              <TextBoxGREYERP  placeholder="Description" name="Description" span={2} multiline={true} />
            <TextBoxGREYERP  placeholder="Internal Notes" multiline={true}  span={2} name="Comments" />
          </>
        }
      />
           <div id="divAssetmovementdetails" className="divAssetmovementdetails">
           <ItemsComponent
           heading="Assets"
           tablename="assetmovementdetails"
             disableCalculation={true}
             disableQuantity={true}
             columnsToShow={{
               Asset: 0, 
               NewLocation: 0,
             ToEmployee:0
             }}
           />
         </div>
         </>
  );
}
