import { TextBoxGREYERP } from 'src/controls/textbox';
import PopUpPanel from 'src/controls/popuppanel';
import { SalaryTypes } from 'src/data/data';
import { DropDownENUM } from 'src/controls/dropdownenum';

export default function SalaryComponents() {
    return (
        <>
          <PopUpPanel heading={'Add'} child={
          <>
            <TextBoxGREYERP span={2} name="Name" placeholder="Basic" required={true} focus={true}/>
            <DropDownENUM span={2} items={SalaryTypes} name="Type" required={true} />
            <TextBoxGREYERP span={2} name="Description" multiline={true} placeholder="Description" MultiLine={true} />
            <TextBoxGREYERP  span={2} name="Comments" multiline={true} placeholder="Comments (Optional)"  MultiLine={true}/>
          </>
   }
   />
 </>
);
}