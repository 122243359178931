import { useContext, useEffect } from 'react';
import { CardPanel } from 'src/controls/cardpanel';
import { DropDownGREYERP } from 'src/controls/dropdown';
import { TextBoxGREYERP } from 'src/controls/textbox';
import { ItemsComponent } from 'src/controls/items/ItemsComponent';
import Picker from 'src/controls/picker';
import { FormContext } from 'src/layouts/dashboard/DashboardLayout';
import { GetAndSetList } from 'src/data/scripts';

export default function InventoryAdjustments() {

  const { setObject, setExistingItemsData, setFormObject,formObject } =
  useContext(FormContext);
const { Sections } = formObject || {};

  useEffect(() => {
    GetAndSetList(
      `getCustomPostBackItem/adjustments/${localStorage.getItem(
        'username'
      )}/${localStorage.getItem('client')}`,
      setExistingItemsData,
      null
    )
  }, []);

  return (
    <>
      <CardPanel
        heading="Fields"
        cardItem={
          <>
            <Picker type='date'
              required={true}
              date={new Date()}
              name="Date"
              type="date"
              focus={true}
            />
            <TextBoxGREYERP required={true} name="Name" placeholder="Adjustment Name" />

            <TextBoxGREYERP required={true} name="Reference" placeholder="Adjustment Reference" />

            <DropDownGREYERP
              required={true}
              onChange={(e, selectedValue) =>
                HandleOnChangeValue({ Warehouse: selectedValue?.ID }, setObject, [
                  GetAndSetList(
                    `getItems/warehousesections/name/${localStorage.getItem(
                      'username'
                    )}/${localStorage.getItem('client')}/${parseInt(selectedValue?.ID)}`,
                    setFormObject,
                    'Sections'
                  ),
                
                ])
              }
              items={`warehouses/name`}
              name="Warehouse"
            />

            <DropDownGREYERP postBackitems={Sections} name="Section" />

            <TextBoxGREYERP name="Comments" multiline={true} placeholder="Internal Notes" />
          </>
        }
      />

      <ItemsComponent
        formType="saleable"
        disableAddItem={true}
        disableCalculation={true}
        displayFooterCalc={true}
        disableQuantity={true}
        appendCTA={true}
        columnsToAppend={{
          ID: 0,
          Name: 0,
          OnHandQuantity: 0,
          CountedQuantity: 0,
          Difference: 0,
          Note: '',
        }}
        columnsToShow={{
          Name: 0,
          OnHandQuantity: 0,
          CountedQuantity: 0,
          Unit: 0,
          Difference: 0,
          Converted: 0,
          Note: '',
        }}
      />
    </>
  );
}
