import { useState, useEffect } from 'react';

// @mui
import { Container, Typography, Stack } from '@mui/material';
import POSProducts from 'src/sections/@dashboard/e-commerce/posproducts';
import { CheckoutCart } from 'src/sections/@dashboard/e-commerce/checkout';
import onScan from 'onscan.js';

export default function PointOfSale() {
  const [products, setProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);

  useEffect(() => {
    GetProducts();
  }, []);

  async function GetProducts() {
    let products = await HttpRequest(
      `getRecords/items/${localStorage.getItem('username')}/${localStorage.getItem('client')}`
    );

    if (products.length > 0) {
      let POSItems = products.map((item) => {
        const { ID, Created_Date, Category, Price, Name, Base_Unit, Image, Barcode } = item;
        let itemPOS = {
          id: ID,
          cover: `https://uploads.greyerp.com/${Image}`,
          images: [`https://uploads.greyerp.com/${Image}`],
          name: Name,
          code: '38BEE277',
          sku: 'WW75K5217YW/SV',
          price: Price,
          priceSale: null,

          status: '',
          inventoryType: 'low_stock',
          sizes: [Base_Unit],
          available: 2,
          description:
            '\n<p><strong><small> SPECIFICATION</small></strong></p>\n<p>Leather panels. Laces. Rounded toe. Rubber sole.\n<br /><br />\n<p><strong><small> MATERIAL AND WASHING INSTRUCTIONS</small></strong></p>\n<p>Shoeupper: 54% bovine leather,46% polyurethane. Lining: 65% polyester,35% cotton. Insole: 100% polyurethane. Sole: 100% thermoplastic. Fixing sole: 100% glued.</p>\n',
          sold: 947,
          createdAt: Created_Date,
          category: Category,
          gender: '',
          colors: [],
          barcode: Barcode,
        };

        return itemPOS;
      });
      setProducts(POSItems.reverse());
    }
  }

  let selectedProductsCopy = [];
  useEffect(() => {
    selectedProductsCopy = [...selectedProducts];
  }, [selectedProducts]);

  useEffect(() => {
    onScan.attachTo(document);
    document.addEventListener('scan', function (scan) {
      //  console.log(scan.detail)
      let scannedProduct = products.filter((item) => item.barcode == scan.detail.scanCode);
      if (scannedProduct?.length > 0) {
        scannedProduct = scannedProduct[0];
        //console.log(selectedProductsCopy);
        let existingProduct = selectedProductsCopy.filter((item) => item.id == scannedProduct.id);
        if (existingProduct.length > 0) {
          let updatedSelectedProducts = selectedProductsCopy.map((item) => {
            if (item.id == scannedProduct.id) {
              item['quantity'] += 1;
              item['subtotal'] = item.quantity * item.price;
              return item;
            }
            return item;
          });

          selectedProductsCopy = updatedSelectedProducts;
          setSelectedProducts(updatedSelectedProducts);
        } else {
          let product = products.filter((item) => item.id == scannedProduct.id);
          product[0]['quantity'] = 1;
          product[0]['subtotal'] = 1 * product[0].price;
          setSelectedProducts((prev) => [...prev, product[0]]);
          selectedProductsCopy = [...selectedProducts, product[0]];
        }
      }
    });
    return () => {
      onScan.detachFrom(document);
    };
  }, [products, selectedProducts]);

  return (
    <>
      <Stack direction="row" sx={{ height: '100%' }}>
        <POSProducts
          products={products}
          selectedProducts={selectedProducts}
          setSelectedProducts={setSelectedProducts}
        />
        <CheckoutCart
          selectedProducts={selectedProducts}
          setSelectedProducts={setSelectedProducts}
        />
      </Stack>
    </>
  );
}
