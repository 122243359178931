import { CardPanel } from 'src/controls/cardpanel';
import { CheckBoxGREYERP } from 'src/controls/checkbox';
import Picker from 'src/controls/picker';
import { DropDownGREYERP } from 'src/controls/dropdown';
import { DropDownENUM } from 'src/controls/dropdownenum';
import { TextBoxGREYERP } from 'src/controls/textbox';
import { UnitTypes } from 'src/data/data';

export default function Unit() {
  return (
    <>
      <CardPanel
        heading="Unit Details"
        cardItem={
          <>
            <Picker
              type="date"
              focus={true}
              name="Date"
              label="Built Date"
              required={true}
            ></Picker>
            <DropDownGREYERP items={`buildings/name`} name="Building" required={true} />
            <TextBoxGREYERP placeholder="24" name="UnitNo" required={true} />
            <DropDownENUM items={UnitTypes} name="Type" required={true} />
            <TextBoxGREYERP placeholder="250.00" name="Rent" type="number" required={true} />
            <TextBoxGREYERP placeholder="15.000" name="Association" type="number" required={true} />
            <DropDownGREYERP items={`Owners/name`} name="Owner" required={true} />
            <TextBoxGREYERP placeholder="2" name="FloorNo" />
            <TextBoxGREYERP placeholder="140" name="Size" type="number" sufix="m2" />
            <TextBoxGREYERP placeholder="2" name="Bathrooms" type="number" />
            <TextBoxGREYERP placeholder="85682" name="WaterMeter" label="Water Meter No." />
            <TextBoxGREYERP
              placeholder="37824"
              name="ElectricityMeter"
              label="Electricity Meter No."
            />
            <TextBoxGREYERP placeholder="D73874" name="WaterAccount" label="Water Account No." />
            <TextBoxGREYERP
              placeholder="A34355"
              name="ElectricityAccount"
              label="Electricity Account No."
            />
            <TextBoxGREYERP
              placeholder="Internal Notes"
              name="Comments"
              multiline={true}
              span={2}
            />
          </>
        }
      />

      <CardPanel
        heading="Unit Facilities"
        cardItem={
          <>
            <CheckBoxGREYERP name="Furnished" />
            <CheckBoxGREYERP name="Parking" />
            <CheckBoxGREYERP name="Balcony" />
            <CheckBoxGREYERP name="AirConditioners" />
            <CheckBoxGREYERP name="Wardrobes" />
            <CheckBoxGREYERP name="KitchenAppliances" />
          </>
        }
      />
    </>
  );
}
