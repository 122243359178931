import PropTypes from 'prop-types';
// @mui
import { Box, Card, Typography, LinearProgress, Stack, Button } from '@mui/material';
import { fData } from 'src/utils/formatNumber';
// utils

// ----------------------------------------------------------------------

FileGeneralWidget.propTypes = {
  sx: PropTypes.object,
  icon: PropTypes.string,
  title: PropTypes.string,
  total: PropTypes.number,
  value: PropTypes.number,
};

export default function FileGeneralWidget({ title, description, icon, sx, ...other }) {
  return (
    <Card sx={{ p: 3, ...sx }} {...other}>
      <Box component="img" src={`/assets/${icon}`} sx={{ width: 50, height: 50 }} />

      <Typography variant="h6" sx={{ mt: 3 }}>
        {title}
      </Typography>

      <Typography variant="body2" sx={{ mt: 3 }}>
        {description}
   
      </Typography>

      <Stack direction="row" spacing={0.5} justifyContent="space-between"  sx={{pt:3}}>
      <Typography variant="body2">
        Free
      </Typography>
        <Button  color="primary" size='small' variant='contained'>
          Install
        </Button>
      </Stack>
    </Card>
  );
}
