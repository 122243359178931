import React from 'react'

import { useState, useEffect } from 'react';
import orderBy from 'lodash/orderBy';
// form
import { useForm } from 'react-hook-form';
// @mui
import { Container, Typography, Stack } from '@mui/material';
import { useSettingsContext } from 'src/components/settings';
import { ShopFilterDrawer, ShopProductList, ShopProductSearch, ShopProductSort, ShopTagFiltered } from './shop';



export default function POSProducts({  setSelectedProducts,
    selectedProducts,products}) {
    const { themeStretch } = useSettingsContext();

    const [openFilter, setOpenFilter] = useState(false);
  
    const defaultValues = {
      gender: [],
      category: 'All',
      colors: [],
      priceRange: [0, 200],
      rating: '',
      sortBy: 'featured',
    };
  
    const methods = useForm({
      defaultValues,
    });
  
    const {
      reset,
      watch,
      formState: { dirtyFields },
    } = methods;
  
    const isDefault =
      (!dirtyFields.gender &&
        !dirtyFields.category &&
        !dirtyFields.colors &&
        !dirtyFields.priceRange &&
        !dirtyFields.rating) ||
      false;
  
    const values = watch();

    useEffect(() => {
      setFilteredProducts(products);
    }, [products])
    
  
    const dataFiltered = applyFilter(products, values);
    const [POSproducts, setPOSproducts] = useState(products);
    const [filteredProducts, setFilteredProducts] = useState([]);
  
    // useEffect(() => {
    //   dispatch(getProducts());
    // }, [dispatch]);
  
    const handleResetFilter = () => {
      reset();
    };
  
    const handleOpenFilter = () => {
      setOpenFilter(true);
    };
  
    const handleCloseFilter = () => {
      setOpenFilter(false);
    };
  
    return (
     
    
          <Container sx={{flexBasis:'50%',p:'0px !important',pr:'10px !important',m:0,height:'100%'}} >
         
  
            <Stack
              spacing={2}
              direction={{ xs: 'column', sm: 'row' }}
              alignItems={{ sm: 'center' }}
              justifyContent="space-between"
              sx={{ mb: 2 }}
            >
              <ShopProductSearch  setFilteredProducts={setFilteredProducts} POSproducts={POSproducts}/>
  
              <Stack direction="row" spacing={1} flexShrink={0} sx={{ my: 1 }}>
                <ShopFilterDrawer
                  isDefault={isDefault}
                  open={openFilter}
                  onOpen={handleOpenFilter}
                  onClose={handleCloseFilter}
                  onResetFilter={handleResetFilter}
                />
  
                <ShopProductSort />
              </Stack>
            </Stack>
  
            <Stack sx={{ mb: 1 }}>
              {!isDefault && (
                <>
                  <Typography variant="body2" gutterBottom>
                    <strong>{filteredProducts.length}</strong>
                    &nbsp;Products found
                  </Typography>
  
                  <ShopTagFiltered isFiltered={!isDefault} onResetFilter={handleResetFilter} />
                </>
              )}
            </Stack>
  
            <ShopProductList products={filteredProducts} loading={!filteredProducts?.length && isDefault} selectedProducts={selectedProducts} setSelectedProducts={setSelectedProducts}/>
  
          
          </Container>
      
    
    );
  }
  
  // ----------------------------------------------------------------------
  
  function applyFilter(products, filters) {
    const { gender, category, colors, priceRange, rating, sortBy } = filters;
  
    const min = priceRange[0];
  
    const max = priceRange[1];
  
    // SORT BY
    if (sortBy === 'featured') {
      products = orderBy(products, ['sold'], ['desc']);
    }
  
    if (sortBy === 'newest') {
      products = orderBy(products, ['createdAt'], ['desc']);
    }
  
    if (sortBy === 'priceDesc') {
      products = orderBy(products, ['price'], ['desc']);
    }
  
    if (sortBy === 'priceAsc') {
      products = orderBy(products, ['price'], ['asc']);
    }
  
    // FILTER PRODUCTS
    if (gender.length) {
      products = products.filter((product) => gender.includes(product.gender));
    }
  
    if (category !== 'All') {
      products = products.filter((product) => product.category === category);
    }
  
    if (colors.length) {
      products = products.filter((product) => product.colors.some((color) => colors.includes(color)));
    }
  
    if (min !== 0 || max !== 200) {
      products = products.filter((product) => product.price >= min && product.price <= max);
    }
  
    if (rating) {
      products = products.filter((product) => {
        const convertRating = (value) => {
          if (value === 'up4Star') return 4;
          if (value === 'up3Star') return 3;
          if (value === 'up2Star') return 2;
          return 1;
        };
        return product.totalRating > convertRating(rating);
      });
    }
  
    return products;
  }
  
  
  
//   let products = [
//     {
//         "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b8",
//         "cover": "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_8.jpg",
//         "images": [
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_1.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_2.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_3.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_4.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_5.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_6.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_7.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_8.jpg"
//         ],
//         "name": "Jordan Delta",
//         "code": "38BEE277",
//         "sku": "WW75K5217YW/SV",
//         "tags": [
//             "Dangal",
//             "The Sting",
//             "2001: A Space Odyssey",
//             "Singin' in the Rain"
//         ],
//         "price": 26.92,
//         "priceSale": null,
//         "totalRating": 5,
//         "totalReview": 5701,
//         "ratings": [
//             {
//                 "name": "1 Star",
//                 "starCount": 2371,
//                 "reviewCount": 498
//             },
//             {
//                 "name": "2 Star",
//                 "starCount": 931,
//                 "reviewCount": 4351
//             },
//             {
//                 "name": "3 Star",
//                 "starCount": 558,
//                 "reviewCount": 7128
//             },
//             {
//                 "name": "4 Star",
//                 "starCount": 1874,
//                 "reviewCount": 9223
//             },
//             {
//                 "name": "5 Star",
//                 "starCount": 5678,
//                 "reviewCount": 2127
//             }
//         ],
//         "reviews": [
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1",
//                 "name": "Jayvion Simon",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_1.jpg",
//                 "comment": "Assumenda nam repudiandae rerum fugiat vel maxime.",
//                 "rating": 2.5,
//                 "isPurchased": true,
//                 "helpful": 9540,
//                 "postedAt": "2023-06-01T12:40:08.057Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b2",
//                 "name": "Lucian Obrien",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_2.jpg",
//                 "comment": "Quis veniam aut saepe aliquid nulla.",
//                 "rating": 2,
//                 "isPurchased": true,
//                 "helpful": 1179,
//                 "postedAt": "2023-05-31T11:40:08.057Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b3",
//                 "name": "Deja Brady",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_3.jpg",
//                 "comment": "Reprehenderit ut voluptas sapiente ratione nostrum est.",
//                 "rating": 4.9,
//                 "isPurchased": true,
//                 "helpful": 89,
//                 "postedAt": "2023-05-30T10:40:08.057Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b4",
//                 "name": "Harrison Stein",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_4.jpg",
//                 "comment": "Error ut sit vel molestias velit.",
//                 "rating": 2,
//                 "isPurchased": false,
//                 "helpful": 5311,
//                 "postedAt": "2023-05-29T09:40:08.057Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5",
//                 "name": "Reece Chung",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_5.jpg",
//                 "comment": "Quo quia sit nihil nemo doloremque et.",
//                 "rating": 4,
//                 "isPurchased": false,
//                 "helpful": 4917,
//                 "postedAt": "2023-05-28T08:40:08.057Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b6",
//                 "name": "Lainey Davidson",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_6.jpg",
//                 "comment": "Autem doloribus harum vero laborum.",
//                 "rating": 5,
//                 "isPurchased": true,
//                 "helpful": 6808,
//                 "postedAt": "2023-05-27T07:40:08.057Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b7",
//                 "name": "Cristopher Cardenas",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_7.jpg",
//                 "comment": "Tempora officiis consequuntur architecto nostrum autem nam adipisci.",
//                 "rating": 4.9,
//                 "isPurchased": false,
//                 "helpful": 3611,
//                 "postedAt": "2023-05-26T06:40:08.057Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b8",
//                 "name": "Melanie Noble",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_8.jpg",
//                 "comment": "Voluptas sunt magni adipisci praesentium saepe.",
//                 "rating": 5,
//                 "isPurchased": false,
//                 "helpful": 9303,
//                 "postedAt": "2023-05-25T05:40:08.057Z"
//             }
//         ],
//         "status": "",
//         "inventoryType": "low_stock",
//         "sizes": [
//             "6",
//             "7",
//             "8",
//             "8.5",
//             "9",
//             "9.5",
//             "10",
//             "10.5",
//             "11",
//             "11.5",
//             "12",
//             "13"
//         ],
//         "available": 2,
//         "description": "\n<p><strong><small> SPECIFICATION</small></strong></p>\n<p>Leather panels. Laces. Rounded toe. Rubber sole.\n<br /><br />\n<p><strong><small> MATERIAL AND WASHING INSTRUCTIONS</small></strong></p>\n<p>Shoeupper: 54% bovine leather,46% polyurethane. Lining: 65% polyester,35% cotton. Insole: 100% polyurethane. Sole: 100% thermoplastic. Fixing sole: 100% glued.</p>\n",
//         "sold": 947,
//         "createdAt": "2023-05-25T05:40:08.057Z",
//         "category": "Apparel",
//         "gender": "Men",
//         "colors": [
//             "#FF4842",
//             "#1890FF"
//         ]
//     },
//     {
//         "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b20",
//         "cover": "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_20.jpg",
//         "images": [
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_1.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_2.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_3.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_4.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_5.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_6.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_7.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_8.jpg"
//         ],
//         "name": "Chuck 70 Hi Sneaker",
//         "code": "38BEE2719",
//         "sku": "WW75K52119YW/SV",
//         "tags": [
//             "Dangal",
//             "The Sting",
//             "2001: A Space Odyssey",
//             "Singin' in the Rain"
//         ],
//         "price": 72.8,
//         "priceSale": null,
//         "totalRating": 5,
//         "totalReview": 5923,
//         "ratings": [
//             {
//                 "name": "1 Star",
//                 "starCount": 3988,
//                 "reviewCount": 885
//             },
//             {
//                 "name": "2 Star",
//                 "starCount": 5901,
//                 "reviewCount": 6661
//             },
//             {
//                 "name": "3 Star",
//                 "starCount": 80,
//                 "reviewCount": 3807
//             },
//             {
//                 "name": "4 Star",
//                 "starCount": 5995,
//                 "reviewCount": 6092
//             },
//             {
//                 "name": "5 Star",
//                 "starCount": 6918,
//                 "reviewCount": 4086
//             }
//         ],
//         "reviews": [
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1",
//                 "name": "Jayvion Simon",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_1.jpg",
//                 "comment": "Assumenda nam repudiandae rerum fugiat vel maxime.",
//                 "rating": 2.5,
//                 "isPurchased": true,
//                 "helpful": 1944,
//                 "postedAt": "2023-06-01T12:40:08.058Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b2",
//                 "name": "Lucian Obrien",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_2.jpg",
//                 "comment": "Quis veniam aut saepe aliquid nulla.",
//                 "rating": 2,
//                 "isPurchased": true,
//                 "helpful": 3691,
//                 "postedAt": "2023-05-31T11:40:08.058Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b3",
//                 "name": "Deja Brady",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_3.jpg",
//                 "comment": "Reprehenderit ut voluptas sapiente ratione nostrum est.",
//                 "rating": 4.9,
//                 "isPurchased": true,
//                 "helpful": 838,
//                 "postedAt": "2023-05-30T10:40:08.058Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b4",
//                 "name": "Harrison Stein",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_4.jpg",
//                 "comment": "Error ut sit vel molestias velit.",
//                 "rating": 2,
//                 "isPurchased": false,
//                 "helpful": 5720,
//                 "postedAt": "2023-05-29T09:40:08.058Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5",
//                 "name": "Reece Chung",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_5.jpg",
//                 "comment": "Quo quia sit nihil nemo doloremque et.",
//                 "rating": 4,
//                 "isPurchased": false,
//                 "helpful": 3444,
//                 "postedAt": "2023-05-28T08:40:08.058Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b6",
//                 "name": "Lainey Davidson",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_6.jpg",
//                 "comment": "Autem doloribus harum vero laborum.",
//                 "rating": 5,
//                 "isPurchased": true,
//                 "helpful": 8378,
//                 "postedAt": "2023-05-27T07:40:08.058Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b7",
//                 "name": "Cristopher Cardenas",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_7.jpg",
//                 "comment": "Tempora officiis consequuntur architecto nostrum autem nam adipisci.",
//                 "rating": 4.9,
//                 "isPurchased": false,
//                 "helpful": 7750,
//                 "postedAt": "2023-05-26T06:40:08.058Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b8",
//                 "name": "Melanie Noble",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_8.jpg",
//                 "comment": "Voluptas sunt magni adipisci praesentium saepe.",
//                 "rating": 5,
//                 "isPurchased": false,
//                 "helpful": 5515,
//                 "postedAt": "2023-05-25T05:40:08.058Z"
//             }
//         ],
//         "status": "",
//         "inventoryType": "out_of_stock",
//         "sizes": [
//             "6",
//             "7",
//             "8",
//             "8.5",
//             "9",
//             "9.5",
//             "10",
//             "10.5",
//             "11",
//             "11.5",
//             "12",
//             "13"
//         ],
//         "available": 2,
//         "description": "\n<p><strong><small> SPECIFICATION</small></strong></p>\n<p>Leather panels. Laces. Rounded toe. Rubber sole.\n<br /><br />\n<p><strong><small> MATERIAL AND WASHING INSTRUCTIONS</small></strong></p>\n<p>Shoeupper: 54% bovine leather,46% polyurethane. Lining: 65% polyester,35% cotton. Insole: 100% polyurethane. Sole: 100% thermoplastic. Fixing sole: 100% glued.</p>\n",
//         "sold": 944,
//         "createdAt": "2023-05-12T17:40:08.058Z",
//         "category": "Shose",
//         "gender": "Women",
//         "colors": [
//             "#FF4842",
//             "#1890FF"
//         ]
//     },
//     {
//         "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b3",
//         "cover": "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_3.jpg",
//         "images": [
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_1.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_2.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_3.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_4.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_5.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_6.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_7.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_8.jpg"
//         ],
//         "name": "Nike Air Zoom Pegasus 37 A.I.R. Chaz Bear",
//         "code": "38BEE272",
//         "sku": "WW75K5212YW/SV",
//         "tags": [
//             "Dangal",
//             "The Sting",
//             "2001: A Space Odyssey",
//             "Singin' in the Rain"
//         ],
//         "price": 34.3,
//         "priceSale": null,
//         "totalRating": 4.9,
//         "totalReview": 4304,
//         "ratings": [
//             {
//                 "name": "1 Star",
//                 "starCount": 8254,
//                 "reviewCount": 2634
//             },
//             {
//                 "name": "2 Star",
//                 "starCount": 2083,
//                 "reviewCount": 4030
//             },
//             {
//                 "name": "3 Star",
//                 "starCount": 4209,
//                 "reviewCount": 2308
//             },
//             {
//                 "name": "4 Star",
//                 "starCount": 4776,
//                 "reviewCount": 374
//             },
//             {
//                 "name": "5 Star",
//                 "starCount": 9833,
//                 "reviewCount": 6419
//             }
//         ],
//         "reviews": [
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1",
//                 "name": "Jayvion Simon",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_1.jpg",
//                 "comment": "Assumenda nam repudiandae rerum fugiat vel maxime.",
//                 "rating": 2.5,
//                 "isPurchased": true,
//                 "helpful": 6576,
//                 "postedAt": "2023-06-01T12:40:08.056Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b2",
//                 "name": "Lucian Obrien",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_2.jpg",
//                 "comment": "Quis veniam aut saepe aliquid nulla.",
//                 "rating": 2,
//                 "isPurchased": true,
//                 "helpful": 3759,
//                 "postedAt": "2023-05-31T11:40:08.056Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b3",
//                 "name": "Deja Brady",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_3.jpg",
//                 "comment": "Reprehenderit ut voluptas sapiente ratione nostrum est.",
//                 "rating": 4.9,
//                 "isPurchased": true,
//                 "helpful": 502,
//                 "postedAt": "2023-05-30T10:40:08.056Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b4",
//                 "name": "Harrison Stein",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_4.jpg",
//                 "comment": "Error ut sit vel molestias velit.",
//                 "rating": 2,
//                 "isPurchased": false,
//                 "helpful": 1707,
//                 "postedAt": "2023-05-29T09:40:08.056Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5",
//                 "name": "Reece Chung",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_5.jpg",
//                 "comment": "Quo quia sit nihil nemo doloremque et.",
//                 "rating": 4,
//                 "isPurchased": false,
//                 "helpful": 4653,
//                 "postedAt": "2023-05-28T08:40:08.056Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b6",
//                 "name": "Lainey Davidson",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_6.jpg",
//                 "comment": "Autem doloribus harum vero laborum.",
//                 "rating": 5,
//                 "isPurchased": true,
//                 "helpful": 6000,
//                 "postedAt": "2023-05-27T07:40:08.056Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b7",
//                 "name": "Cristopher Cardenas",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_7.jpg",
//                 "comment": "Tempora officiis consequuntur architecto nostrum autem nam adipisci.",
//                 "rating": 4.9,
//                 "isPurchased": false,
//                 "helpful": 4668,
//                 "postedAt": "2023-05-26T06:40:08.056Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b8",
//                 "name": "Melanie Noble",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_8.jpg",
//                 "comment": "Voluptas sunt magni adipisci praesentium saepe.",
//                 "rating": 5,
//                 "isPurchased": false,
//                 "helpful": 4987,
//                 "postedAt": "2023-05-25T05:40:08.056Z"
//             }
//         ],
//         "status": "",
//         "inventoryType": "in_stock",
//         "sizes": [
//             "6",
//             "7",
//             "8",
//             "8.5",
//             "9",
//             "9.5",
//             "10",
//             "10.5",
//             "11",
//             "11.5",
//             "12",
//             "13"
//         ],
//         "available": 2,
//         "description": "\n<p><strong><small> SPECIFICATION</small></strong></p>\n<p>Leather panels. Laces. Rounded toe. Rubber sole.\n<br /><br />\n<p><strong><small> MATERIAL AND WASHING INSTRUCTIONS</small></strong></p>\n<p>Shoeupper: 54% bovine leather,46% polyurethane. Lining: 65% polyester,35% cotton. Insole: 100% polyurethane. Sole: 100% thermoplastic. Fixing sole: 100% glued.</p>\n",
//         "sold": 782,
//         "createdAt": "2023-05-30T10:40:08.056Z",
//         "category": "Shose",
//         "gender": "Kids",
//         "colors": [
//             "#FFFFFF",
//             "#FFC0CB"
//         ]
//     },
//     {
//         "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b16",
//         "cover": "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_16.jpg",
//         "images": [
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_1.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_2.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_3.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_4.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_5.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_6.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_7.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_8.jpg"
//         ],
//         "name": "Ultraboost 21 sneakers",
//         "code": "38BEE2715",
//         "sku": "WW75K52115YW/SV",
//         "tags": [
//             "Dangal",
//             "The Sting",
//             "2001: A Space Odyssey",
//             "Singin' in the Rain"
//         ],
//         "price": 94.75,
//         "priceSale": 94.75,
//         "totalRating": 3.7,
//         "totalReview": 6304,
//         "ratings": [
//             {
//                 "name": "1 Star",
//                 "starCount": 2685,
//                 "reviewCount": 5259
//             },
//             {
//                 "name": "2 Star",
//                 "starCount": 3883,
//                 "reviewCount": 8804
//             },
//             {
//                 "name": "3 Star",
//                 "starCount": 270,
//                 "reviewCount": 2062
//             },
//             {
//                 "name": "4 Star",
//                 "starCount": 1306,
//                 "reviewCount": 6859
//             },
//             {
//                 "name": "5 Star",
//                 "starCount": 6663,
//                 "reviewCount": 3007
//             }
//         ],
//         "reviews": [
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1",
//                 "name": "Jayvion Simon",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_1.jpg",
//                 "comment": "Assumenda nam repudiandae rerum fugiat vel maxime.",
//                 "rating": 2.5,
//                 "isPurchased": true,
//                 "helpful": 6118,
//                 "postedAt": "2023-06-01T12:40:08.057Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b2",
//                 "name": "Lucian Obrien",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_2.jpg",
//                 "comment": "Quis veniam aut saepe aliquid nulla.",
//                 "rating": 2,
//                 "isPurchased": true,
//                 "helpful": 1978,
//                 "postedAt": "2023-05-31T11:40:08.057Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b3",
//                 "name": "Deja Brady",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_3.jpg",
//                 "comment": "Reprehenderit ut voluptas sapiente ratione nostrum est.",
//                 "rating": 4.9,
//                 "isPurchased": true,
//                 "helpful": 1243,
//                 "postedAt": "2023-05-30T10:40:08.057Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b4",
//                 "name": "Harrison Stein",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_4.jpg",
//                 "comment": "Error ut sit vel molestias velit.",
//                 "rating": 2,
//                 "isPurchased": false,
//                 "helpful": 1827,
//                 "postedAt": "2023-05-29T09:40:08.058Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5",
//                 "name": "Reece Chung",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_5.jpg",
//                 "comment": "Quo quia sit nihil nemo doloremque et.",
//                 "rating": 4,
//                 "isPurchased": false,
//                 "helpful": 9296,
//                 "postedAt": "2023-05-28T08:40:08.058Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b6",
//                 "name": "Lainey Davidson",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_6.jpg",
//                 "comment": "Autem doloribus harum vero laborum.",
//                 "rating": 5,
//                 "isPurchased": true,
//                 "helpful": 9191,
//                 "postedAt": "2023-05-27T07:40:08.058Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b7",
//                 "name": "Cristopher Cardenas",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_7.jpg",
//                 "comment": "Tempora officiis consequuntur architecto nostrum autem nam adipisci.",
//                 "rating": 4.9,
//                 "isPurchased": false,
//                 "helpful": 4002,
//                 "postedAt": "2023-05-26T06:40:08.058Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b8",
//                 "name": "Melanie Noble",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_8.jpg",
//                 "comment": "Voluptas sunt magni adipisci praesentium saepe.",
//                 "rating": 5,
//                 "isPurchased": false,
//                 "helpful": 4328,
//                 "postedAt": "2023-05-25T05:40:08.058Z"
//             }
//         ],
//         "status": "sale",
//         "inventoryType": "in_stock",
//         "sizes": [
//             "6",
//             "7",
//             "8",
//             "8.5",
//             "9",
//             "9.5",
//             "10",
//             "10.5",
//             "11",
//             "11.5",
//             "12",
//             "13"
//         ],
//         "available": 44,
//         "description": "\n<p><strong><small> SPECIFICATION</small></strong></p>\n<p>Leather panels. Laces. Rounded toe. Rubber sole.\n<br /><br />\n<p><strong><small> MATERIAL AND WASHING INSTRUCTIONS</small></strong></p>\n<p>Shoeupper: 54% bovine leather,46% polyurethane. Lining: 65% polyester,35% cotton. Insole: 100% polyurethane. Sole: 100% thermoplastic. Fixing sole: 100% glued.</p>\n",
//         "sold": 757,
//         "createdAt": "2023-05-16T21:40:08.058Z",
//         "category": "Shose",
//         "gender": "Men",
//         "colors": [
//             "#1890FF",
//             "#94D82D",
//             "#FFC107"
//         ]
//     },
//     {
//         "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b22",
//         "cover": "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_22.jpg",
//         "images": [
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_1.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_2.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_3.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_4.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_5.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_6.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_7.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_8.jpg"
//         ],
//         "name": "Superturf Adventure X Atmos",
//         "code": "38BEE2721",
//         "sku": "WW75K52121YW/SV",
//         "tags": [
//             "Dangal",
//             "The Sting",
//             "2001: A Space Odyssey",
//             "Singin' in the Rain"
//         ],
//         "price": 85.02,
//         "priceSale": 85.02,
//         "totalRating": 3.9,
//         "totalReview": 7698,
//         "ratings": [
//             {
//                 "name": "1 Star",
//                 "starCount": 6093,
//                 "reviewCount": 4667
//             },
//             {
//                 "name": "2 Star",
//                 "starCount": 4837,
//                 "reviewCount": 6822
//             },
//             {
//                 "name": "3 Star",
//                 "starCount": 9787,
//                 "reviewCount": 1940
//             },
//             {
//                 "name": "4 Star",
//                 "starCount": 8185,
//                 "reviewCount": 2249
//             },
//             {
//                 "name": "5 Star",
//                 "starCount": 6709,
//                 "reviewCount": 7710
//             }
//         ],
//         "reviews": [
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1",
//                 "name": "Jayvion Simon",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_1.jpg",
//                 "comment": "Assumenda nam repudiandae rerum fugiat vel maxime.",
//                 "rating": 2.5,
//                 "isPurchased": true,
//                 "helpful": 7121,
//                 "postedAt": "2023-06-01T12:40:08.058Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b2",
//                 "name": "Lucian Obrien",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_2.jpg",
//                 "comment": "Quis veniam aut saepe aliquid nulla.",
//                 "rating": 2,
//                 "isPurchased": true,
//                 "helpful": 8945,
//                 "postedAt": "2023-05-31T11:40:08.058Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b3",
//                 "name": "Deja Brady",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_3.jpg",
//                 "comment": "Reprehenderit ut voluptas sapiente ratione nostrum est.",
//                 "rating": 4.9,
//                 "isPurchased": true,
//                 "helpful": 4626,
//                 "postedAt": "2023-05-30T10:40:08.058Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b4",
//                 "name": "Harrison Stein",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_4.jpg",
//                 "comment": "Error ut sit vel molestias velit.",
//                 "rating": 2,
//                 "isPurchased": false,
//                 "helpful": 3292,
//                 "postedAt": "2023-05-29T09:40:08.058Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5",
//                 "name": "Reece Chung",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_5.jpg",
//                 "comment": "Quo quia sit nihil nemo doloremque et.",
//                 "rating": 4,
//                 "isPurchased": false,
//                 "helpful": 3788,
//                 "postedAt": "2023-05-28T08:40:08.058Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b6",
//                 "name": "Lainey Davidson",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_6.jpg",
//                 "comment": "Autem doloribus harum vero laborum.",
//                 "rating": 5,
//                 "isPurchased": true,
//                 "helpful": 2524,
//                 "postedAt": "2023-05-27T07:40:08.058Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b7",
//                 "name": "Cristopher Cardenas",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_7.jpg",
//                 "comment": "Tempora officiis consequuntur architecto nostrum autem nam adipisci.",
//                 "rating": 4.9,
//                 "isPurchased": false,
//                 "helpful": 9070,
//                 "postedAt": "2023-05-26T06:40:08.058Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b8",
//                 "name": "Melanie Noble",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_8.jpg",
//                 "comment": "Voluptas sunt magni adipisci praesentium saepe.",
//                 "rating": 5,
//                 "isPurchased": false,
//                 "helpful": 1122,
//                 "postedAt": "2023-05-25T05:40:08.058Z"
//             }
//         ],
//         "status": "sale",
//         "inventoryType": "out_of_stock",
//         "sizes": [
//             "6",
//             "7",
//             "8",
//             "8.5",
//             "9",
//             "9.5",
//             "10",
//             "10.5",
//             "11",
//             "11.5",
//             "12",
//             "13"
//         ],
//         "available": 95,
//         "description": "\n<p><strong><small> SPECIFICATION</small></strong></p>\n<p>Leather panels. Laces. Rounded toe. Rubber sole.\n<br /><br />\n<p><strong><small> MATERIAL AND WASHING INSTRUCTIONS</small></strong></p>\n<p>Shoeupper: 54% bovine leather,46% polyurethane. Lining: 65% polyester,35% cotton. Insole: 100% polyurethane. Sole: 100% thermoplastic. Fixing sole: 100% glued.</p>\n",
//         "sold": 753,
//         "createdAt": "2023-05-10T15:40:08.058Z",
//         "category": "Apparel",
//         "gender": "Men",
//         "colors": [
//             "#1890FF"
//         ]
//     },
//     {
//         "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b19",
//         "cover": "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_19.jpg",
//         "images": [
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_1.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_2.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_3.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_4.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_5.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_6.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_7.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_8.jpg"
//         ],
//         "name": "Madrid Big Buckle Sandal",
//         "code": "38BEE2718",
//         "sku": "WW75K52118YW/SV",
//         "tags": [
//             "Dangal",
//             "The Sting",
//             "2001: A Space Odyssey",
//             "Singin' in the Rain"
//         ],
//         "price": 52.84,
//         "priceSale": 52.84,
//         "totalRating": 1.8,
//         "totalReview": 1393,
//         "ratings": [
//             {
//                 "name": "1 Star",
//                 "starCount": 4298,
//                 "reviewCount": 4572
//             },
//             {
//                 "name": "2 Star",
//                 "starCount": 122,
//                 "reviewCount": 3952
//             },
//             {
//                 "name": "3 Star",
//                 "starCount": 5325,
//                 "reviewCount": 2247
//             },
//             {
//                 "name": "4 Star",
//                 "starCount": 9620,
//                 "reviewCount": 8564
//             },
//             {
//                 "name": "5 Star",
//                 "starCount": 4566,
//                 "reviewCount": 2506
//             }
//         ],
//         "reviews": [
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1",
//                 "name": "Jayvion Simon",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_1.jpg",
//                 "comment": "Assumenda nam repudiandae rerum fugiat vel maxime.",
//                 "rating": 2.5,
//                 "isPurchased": true,
//                 "helpful": 2610,
//                 "postedAt": "2023-06-01T12:40:08.058Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b2",
//                 "name": "Lucian Obrien",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_2.jpg",
//                 "comment": "Quis veniam aut saepe aliquid nulla.",
//                 "rating": 2,
//                 "isPurchased": true,
//                 "helpful": 4321,
//                 "postedAt": "2023-05-31T11:40:08.058Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b3",
//                 "name": "Deja Brady",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_3.jpg",
//                 "comment": "Reprehenderit ut voluptas sapiente ratione nostrum est.",
//                 "rating": 4.9,
//                 "isPurchased": true,
//                 "helpful": 2804,
//                 "postedAt": "2023-05-30T10:40:08.058Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b4",
//                 "name": "Harrison Stein",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_4.jpg",
//                 "comment": "Error ut sit vel molestias velit.",
//                 "rating": 2,
//                 "isPurchased": false,
//                 "helpful": 4629,
//                 "postedAt": "2023-05-29T09:40:08.058Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5",
//                 "name": "Reece Chung",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_5.jpg",
//                 "comment": "Quo quia sit nihil nemo doloremque et.",
//                 "rating": 4,
//                 "isPurchased": false,
//                 "helpful": 5921,
//                 "postedAt": "2023-05-28T08:40:08.058Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b6",
//                 "name": "Lainey Davidson",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_6.jpg",
//                 "comment": "Autem doloribus harum vero laborum.",
//                 "rating": 5,
//                 "isPurchased": true,
//                 "helpful": 402,
//                 "postedAt": "2023-05-27T07:40:08.058Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b7",
//                 "name": "Cristopher Cardenas",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_7.jpg",
//                 "comment": "Tempora officiis consequuntur architecto nostrum autem nam adipisci.",
//                 "rating": 4.9,
//                 "isPurchased": false,
//                 "helpful": 2215,
//                 "postedAt": "2023-05-26T06:40:08.058Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b8",
//                 "name": "Melanie Noble",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_8.jpg",
//                 "comment": "Voluptas sunt magni adipisci praesentium saepe.",
//                 "rating": 5,
//                 "isPurchased": false,
//                 "helpful": 7675,
//                 "postedAt": "2023-05-25T05:40:08.058Z"
//             }
//         ],
//         "status": "sale",
//         "inventoryType": "out_of_stock",
//         "sizes": [
//             "6",
//             "7",
//             "8",
//             "8.5",
//             "9",
//             "9.5",
//             "10",
//             "10.5",
//             "11",
//             "11.5",
//             "12",
//             "13"
//         ],
//         "available": 53,
//         "description": "\n<p><strong><small> SPECIFICATION</small></strong></p>\n<p>Leather panels. Laces. Rounded toe. Rubber sole.\n<br /><br />\n<p><strong><small> MATERIAL AND WASHING INSTRUCTIONS</small></strong></p>\n<p>Shoeupper: 54% bovine leather,46% polyurethane. Lining: 65% polyester,35% cotton. Insole: 100% polyurethane. Sole: 100% thermoplastic. Fixing sole: 100% glued.</p>\n",
//         "sold": 698,
//         "createdAt": "2023-05-13T18:40:08.058Z",
//         "category": "Accessories",
//         "gender": "Men",
//         "colors": [
//             "#00AB55",
//             "#000000"
//         ]
//     },
//     {
//         "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b23",
//         "cover": "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_23.jpg",
//         "images": [
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_1.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_2.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_3.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_4.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_5.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_6.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_7.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_8.jpg"
//         ],
//         "name": "Chuck Taylor All Star Lift Sneaker",
//         "code": "38BEE2722",
//         "sku": "WW75K52122YW/SV",
//         "tags": [
//             "Dangal",
//             "The Sting",
//             "2001: A Space Odyssey",
//             "Singin' in the Rain"
//         ],
//         "price": 69.22,
//         "priceSale": null,
//         "totalRating": 3.9,
//         "totalReview": 4198,
//         "ratings": [
//             {
//                 "name": "1 Star",
//                 "starCount": 1696,
//                 "reviewCount": 9727
//             },
//             {
//                 "name": "2 Star",
//                 "starCount": 8386,
//                 "reviewCount": 5944
//             },
//             {
//                 "name": "3 Star",
//                 "starCount": 2857,
//                 "reviewCount": 168
//             },
//             {
//                 "name": "4 Star",
//                 "starCount": 2026,
//                 "reviewCount": 8589
//             },
//             {
//                 "name": "5 Star",
//                 "starCount": 8919,
//                 "reviewCount": 7489
//             }
//         ],
//         "reviews": [
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1",
//                 "name": "Jayvion Simon",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_1.jpg",
//                 "comment": "Assumenda nam repudiandae rerum fugiat vel maxime.",
//                 "rating": 2.5,
//                 "isPurchased": true,
//                 "helpful": 4954,
//                 "postedAt": "2023-06-01T12:40:08.058Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b2",
//                 "name": "Lucian Obrien",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_2.jpg",
//                 "comment": "Quis veniam aut saepe aliquid nulla.",
//                 "rating": 2,
//                 "isPurchased": true,
//                 "helpful": 3676,
//                 "postedAt": "2023-05-31T11:40:08.058Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b3",
//                 "name": "Deja Brady",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_3.jpg",
//                 "comment": "Reprehenderit ut voluptas sapiente ratione nostrum est.",
//                 "rating": 4.9,
//                 "isPurchased": true,
//                 "helpful": 7658,
//                 "postedAt": "2023-05-30T10:40:08.058Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b4",
//                 "name": "Harrison Stein",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_4.jpg",
//                 "comment": "Error ut sit vel molestias velit.",
//                 "rating": 2,
//                 "isPurchased": false,
//                 "helpful": 504,
//                 "postedAt": "2023-05-29T09:40:08.058Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5",
//                 "name": "Reece Chung",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_5.jpg",
//                 "comment": "Quo quia sit nihil nemo doloremque et.",
//                 "rating": 4,
//                 "isPurchased": false,
//                 "helpful": 6257,
//                 "postedAt": "2023-05-28T08:40:08.058Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b6",
//                 "name": "Lainey Davidson",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_6.jpg",
//                 "comment": "Autem doloribus harum vero laborum.",
//                 "rating": 5,
//                 "isPurchased": true,
//                 "helpful": 1217,
//                 "postedAt": "2023-05-27T07:40:08.058Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b7",
//                 "name": "Cristopher Cardenas",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_7.jpg",
//                 "comment": "Tempora officiis consequuntur architecto nostrum autem nam adipisci.",
//                 "rating": 4.9,
//                 "isPurchased": false,
//                 "helpful": 187,
//                 "postedAt": "2023-05-26T06:40:08.058Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b8",
//                 "name": "Melanie Noble",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_8.jpg",
//                 "comment": "Voluptas sunt magni adipisci praesentium saepe.",
//                 "rating": 5,
//                 "isPurchased": false,
//                 "helpful": 8503,
//                 "postedAt": "2023-05-25T05:40:08.058Z"
//             }
//         ],
//         "status": "",
//         "inventoryType": "low_stock",
//         "sizes": [
//             "6",
//             "7",
//             "8",
//             "8.5",
//             "9",
//             "9.5",
//             "10",
//             "10.5",
//             "11",
//             "11.5",
//             "12",
//             "13"
//         ],
//         "available": 2,
//         "description": "\n<p><strong><small> SPECIFICATION</small></strong></p>\n<p>Leather panels. Laces. Rounded toe. Rubber sole.\n<br /><br />\n<p><strong><small> MATERIAL AND WASHING INSTRUCTIONS</small></strong></p>\n<p>Shoeupper: 54% bovine leather,46% polyurethane. Lining: 65% polyester,35% cotton. Insole: 100% polyurethane. Sole: 100% thermoplastic. Fixing sole: 100% glued.</p>\n",
//         "sold": 668,
//         "createdAt": "2023-05-09T14:40:08.058Z",
//         "category": "Accessories",
//         "gender": "Men",
//         "colors": [
//             "#1890FF",
//             "#94D82D",
//             "#FFC107"
//         ]
//     },
//     {
//         "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b2",
//         "cover": "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_2.jpg",
//         "images": [
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_1.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_2.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_3.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_4.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_5.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_6.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_7.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_8.jpg"
//         ],
//         "name": "Foundations Matte Flip Flop",
//         "code": "38BEE271",
//         "sku": "WW75K5211YW/SV",
//         "tags": [
//             "Dangal",
//             "The Sting",
//             "2001: A Space Odyssey",
//             "Singin' in the Rain"
//         ],
//         "price": 35.71,
//         "priceSale": null,
//         "totalRating": 2,
//         "totalReview": 6796,
//         "ratings": [
//             {
//                 "name": "1 Star",
//                 "starCount": 3919,
//                 "reviewCount": 9683
//             },
//             {
//                 "name": "2 Star",
//                 "starCount": 556,
//                 "reviewCount": 8118
//             },
//             {
//                 "name": "3 Star",
//                 "starCount": 571,
//                 "reviewCount": 2401
//             },
//             {
//                 "name": "4 Star",
//                 "starCount": 6284,
//                 "reviewCount": 7800
//             },
//             {
//                 "name": "5 Star",
//                 "starCount": 9620,
//                 "reviewCount": 2926
//             }
//         ],
//         "reviews": [
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1",
//                 "name": "Jayvion Simon",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_1.jpg",
//                 "comment": "Assumenda nam repudiandae rerum fugiat vel maxime.",
//                 "rating": 2.5,
//                 "isPurchased": true,
//                 "helpful": 7355,
//                 "postedAt": "2023-06-01T12:40:08.056Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b2",
//                 "name": "Lucian Obrien",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_2.jpg",
//                 "comment": "Quis veniam aut saepe aliquid nulla.",
//                 "rating": 2,
//                 "isPurchased": true,
//                 "helpful": 9316,
//                 "postedAt": "2023-05-31T11:40:08.056Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b3",
//                 "name": "Deja Brady",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_3.jpg",
//                 "comment": "Reprehenderit ut voluptas sapiente ratione nostrum est.",
//                 "rating": 4.9,
//                 "isPurchased": true,
//                 "helpful": 8347,
//                 "postedAt": "2023-05-30T10:40:08.056Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b4",
//                 "name": "Harrison Stein",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_4.jpg",
//                 "comment": "Error ut sit vel molestias velit.",
//                 "rating": 2,
//                 "isPurchased": false,
//                 "helpful": 8083,
//                 "postedAt": "2023-05-29T09:40:08.056Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5",
//                 "name": "Reece Chung",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_5.jpg",
//                 "comment": "Quo quia sit nihil nemo doloremque et.",
//                 "rating": 4,
//                 "isPurchased": false,
//                 "helpful": 7954,
//                 "postedAt": "2023-05-28T08:40:08.056Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b6",
//                 "name": "Lainey Davidson",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_6.jpg",
//                 "comment": "Autem doloribus harum vero laborum.",
//                 "rating": 5,
//                 "isPurchased": true,
//                 "helpful": 8421,
//                 "postedAt": "2023-05-27T07:40:08.056Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b7",
//                 "name": "Cristopher Cardenas",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_7.jpg",
//                 "comment": "Tempora officiis consequuntur architecto nostrum autem nam adipisci.",
//                 "rating": 4.9,
//                 "isPurchased": false,
//                 "helpful": 8349,
//                 "postedAt": "2023-05-26T06:40:08.056Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b8",
//                 "name": "Melanie Noble",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_8.jpg",
//                 "comment": "Voluptas sunt magni adipisci praesentium saepe.",
//                 "rating": 5,
//                 "isPurchased": false,
//                 "helpful": 9138,
//                 "postedAt": "2023-05-25T05:40:08.056Z"
//             }
//         ],
//         "status": "",
//         "inventoryType": "in_stock",
//         "sizes": [
//             "6",
//             "7",
//             "8",
//             "8.5",
//             "9",
//             "9.5",
//             "10",
//             "10.5",
//             "11",
//             "11.5",
//             "12",
//             "13"
//         ],
//         "available": 2,
//         "description": "\n<p><strong><small> SPECIFICATION</small></strong></p>\n<p>Leather panels. Laces. Rounded toe. Rubber sole.\n<br /><br />\n<p><strong><small> MATERIAL AND WASHING INSTRUCTIONS</small></strong></p>\n<p>Shoeupper: 54% bovine leather,46% polyurethane. Lining: 65% polyester,35% cotton. Insole: 100% polyurethane. Sole: 100% thermoplastic. Fixing sole: 100% glued.</p>\n",
//         "sold": 615,
//         "createdAt": "2023-05-31T11:40:08.056Z",
//         "category": "Accessories",
//         "gender": "Men",
//         "colors": [
//             "#000000",
//             "#FFFFFF"
//         ]
//     },
//     {
//         "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b7",
//         "cover": "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_7.jpg",
//         "images": [
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_1.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_2.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_3.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_4.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_5.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_6.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_7.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_8.jpg"
//         ],
//         "name": "Gazelle Vintage low-top sneakers",
//         "code": "38BEE276",
//         "sku": "WW75K5216YW/SV",
//         "tags": [
//             "Dangal",
//             "The Sting",
//             "2001: A Space Odyssey",
//             "Singin' in the Rain"
//         ],
//         "price": 44.39,
//         "priceSale": 44.39,
//         "totalRating": 4.9,
//         "totalReview": 1026,
//         "ratings": [
//             {
//                 "name": "1 Star",
//                 "starCount": 1024,
//                 "reviewCount": 5408
//             },
//             {
//                 "name": "2 Star",
//                 "starCount": 8715,
//                 "reviewCount": 856
//             },
//             {
//                 "name": "3 Star",
//                 "starCount": 2172,
//                 "reviewCount": 5348
//             },
//             {
//                 "name": "4 Star",
//                 "starCount": 1101,
//                 "reviewCount": 8243
//             },
//             {
//                 "name": "5 Star",
//                 "starCount": 3886,
//                 "reviewCount": 6865
//             }
//         ],
//         "reviews": [
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1",
//                 "name": "Jayvion Simon",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_1.jpg",
//                 "comment": "Assumenda nam repudiandae rerum fugiat vel maxime.",
//                 "rating": 2.5,
//                 "isPurchased": true,
//                 "helpful": 9159,
//                 "postedAt": "2023-06-01T12:40:08.057Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b2",
//                 "name": "Lucian Obrien",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_2.jpg",
//                 "comment": "Quis veniam aut saepe aliquid nulla.",
//                 "rating": 2,
//                 "isPurchased": true,
//                 "helpful": 5505,
//                 "postedAt": "2023-05-31T11:40:08.057Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b3",
//                 "name": "Deja Brady",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_3.jpg",
//                 "comment": "Reprehenderit ut voluptas sapiente ratione nostrum est.",
//                 "rating": 4.9,
//                 "isPurchased": true,
//                 "helpful": 8375,
//                 "postedAt": "2023-05-30T10:40:08.057Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b4",
//                 "name": "Harrison Stein",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_4.jpg",
//                 "comment": "Error ut sit vel molestias velit.",
//                 "rating": 2,
//                 "isPurchased": false,
//                 "helpful": 446,
//                 "postedAt": "2023-05-29T09:40:08.057Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5",
//                 "name": "Reece Chung",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_5.jpg",
//                 "comment": "Quo quia sit nihil nemo doloremque et.",
//                 "rating": 4,
//                 "isPurchased": false,
//                 "helpful": 8565,
//                 "postedAt": "2023-05-28T08:40:08.057Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b6",
//                 "name": "Lainey Davidson",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_6.jpg",
//                 "comment": "Autem doloribus harum vero laborum.",
//                 "rating": 5,
//                 "isPurchased": true,
//                 "helpful": 3609,
//                 "postedAt": "2023-05-27T07:40:08.057Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b7",
//                 "name": "Cristopher Cardenas",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_7.jpg",
//                 "comment": "Tempora officiis consequuntur architecto nostrum autem nam adipisci.",
//                 "rating": 4.9,
//                 "isPurchased": false,
//                 "helpful": 1806,
//                 "postedAt": "2023-05-26T06:40:08.057Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b8",
//                 "name": "Melanie Noble",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_8.jpg",
//                 "comment": "Voluptas sunt magni adipisci praesentium saepe.",
//                 "rating": 5,
//                 "isPurchased": false,
//                 "helpful": 3043,
//                 "postedAt": "2023-05-25T05:40:08.057Z"
//             }
//         ],
//         "status": "sale",
//         "inventoryType": "in_stock",
//         "sizes": [
//             "6",
//             "7",
//             "8",
//             "8.5",
//             "9",
//             "9.5",
//             "10",
//             "10.5",
//             "11",
//             "11.5",
//             "12",
//             "13"
//         ],
//         "available": 81,
//         "description": "\n<p><strong><small> SPECIFICATION</small></strong></p>\n<p>Leather panels. Laces. Rounded toe. Rubber sole.\n<br /><br />\n<p><strong><small> MATERIAL AND WASHING INSTRUCTIONS</small></strong></p>\n<p>Shoeupper: 54% bovine leather,46% polyurethane. Lining: 65% polyester,35% cotton. Insole: 100% polyurethane. Sole: 100% thermoplastic. Fixing sole: 100% glued.</p>\n",
//         "sold": 604,
//         "createdAt": "2023-05-26T06:40:08.057Z",
//         "category": "Shose",
//         "gender": "Men",
//         "colors": [
//             "#00AB55",
//             "#000000"
//         ]
//     },
//     {
//         "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b18",
//         "cover": "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_18.jpg",
//         "images": [
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_1.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_2.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_3.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_4.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_5.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_6.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_7.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_8.jpg"
//         ],
//         "name": "ZX 9000 A-ZX Series sneakers",
//         "code": "38BEE2717",
//         "sku": "WW75K52117YW/SV",
//         "tags": [
//             "Dangal",
//             "The Sting",
//             "2001: A Space Odyssey",
//             "Singin' in the Rain"
//         ],
//         "price": 39.6,
//         "priceSale": null,
//         "totalRating": 2.4,
//         "totalReview": 6067,
//         "ratings": [
//             {
//                 "name": "1 Star",
//                 "starCount": 272,
//                 "reviewCount": 8003
//             },
//             {
//                 "name": "2 Star",
//                 "starCount": 3230,
//                 "reviewCount": 7386
//             },
//             {
//                 "name": "3 Star",
//                 "starCount": 5093,
//                 "reviewCount": 6434
//             },
//             {
//                 "name": "4 Star",
//                 "starCount": 4099,
//                 "reviewCount": 1208
//             },
//             {
//                 "name": "5 Star",
//                 "starCount": 115,
//                 "reviewCount": 7745
//             }
//         ],
//         "reviews": [
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1",
//                 "name": "Jayvion Simon",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_1.jpg",
//                 "comment": "Assumenda nam repudiandae rerum fugiat vel maxime.",
//                 "rating": 2.5,
//                 "isPurchased": true,
//                 "helpful": 8082,
//                 "postedAt": "2023-06-01T12:40:08.058Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b2",
//                 "name": "Lucian Obrien",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_2.jpg",
//                 "comment": "Quis veniam aut saepe aliquid nulla.",
//                 "rating": 2,
//                 "isPurchased": true,
//                 "helpful": 1950,
//                 "postedAt": "2023-05-31T11:40:08.058Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b3",
//                 "name": "Deja Brady",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_3.jpg",
//                 "comment": "Reprehenderit ut voluptas sapiente ratione nostrum est.",
//                 "rating": 4.9,
//                 "isPurchased": true,
//                 "helpful": 1329,
//                 "postedAt": "2023-05-30T10:40:08.058Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b4",
//                 "name": "Harrison Stein",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_4.jpg",
//                 "comment": "Error ut sit vel molestias velit.",
//                 "rating": 2,
//                 "isPurchased": false,
//                 "helpful": 8286,
//                 "postedAt": "2023-05-29T09:40:08.058Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5",
//                 "name": "Reece Chung",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_5.jpg",
//                 "comment": "Quo quia sit nihil nemo doloremque et.",
//                 "rating": 4,
//                 "isPurchased": false,
//                 "helpful": 1968,
//                 "postedAt": "2023-05-28T08:40:08.058Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b6",
//                 "name": "Lainey Davidson",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_6.jpg",
//                 "comment": "Autem doloribus harum vero laborum.",
//                 "rating": 5,
//                 "isPurchased": true,
//                 "helpful": 9278,
//                 "postedAt": "2023-05-27T07:40:08.058Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b7",
//                 "name": "Cristopher Cardenas",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_7.jpg",
//                 "comment": "Tempora officiis consequuntur architecto nostrum autem nam adipisci.",
//                 "rating": 4.9,
//                 "isPurchased": false,
//                 "helpful": 3502,
//                 "postedAt": "2023-05-26T06:40:08.058Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b8",
//                 "name": "Melanie Noble",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_8.jpg",
//                 "comment": "Voluptas sunt magni adipisci praesentium saepe.",
//                 "rating": 5,
//                 "isPurchased": false,
//                 "helpful": 4280,
//                 "postedAt": "2023-05-25T05:40:08.058Z"
//             }
//         ],
//         "status": "",
//         "inventoryType": "out_of_stock",
//         "sizes": [
//             "6",
//             "7",
//             "8",
//             "8.5",
//             "9",
//             "9.5",
//             "10",
//             "10.5",
//             "11",
//             "11.5",
//             "12",
//             "13"
//         ],
//         "available": 2,
//         "description": "\n<p><strong><small> SPECIFICATION</small></strong></p>\n<p>Leather panels. Laces. Rounded toe. Rubber sole.\n<br /><br />\n<p><strong><small> MATERIAL AND WASHING INSTRUCTIONS</small></strong></p>\n<p>Shoeupper: 54% bovine leather,46% polyurethane. Lining: 65% polyester,35% cotton. Insole: 100% polyurethane. Sole: 100% thermoplastic. Fixing sole: 100% glued.</p>\n",
//         "sold": 578,
//         "createdAt": "2023-05-14T19:40:08.058Z",
//         "category": "Shose",
//         "gender": "Women",
//         "colors": [
//             "#1890FF"
//         ]
//     },
//     {
//         "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b6",
//         "cover": "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_6.jpg",
//         "images": [
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_1.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_2.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_3.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_4.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_5.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_6.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_7.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_8.jpg"
//         ],
//         "name": "Zoom Freak 2",
//         "code": "38BEE275",
//         "sku": "WW75K5215YW/SV",
//         "tags": [
//             "Dangal",
//             "The Sting",
//             "2001: A Space Odyssey",
//             "Singin' in the Rain"
//         ],
//         "price": 89.09,
//         "priceSale": null,
//         "totalRating": 5,
//         "totalReview": 5390,
//         "ratings": [
//             {
//                 "name": "1 Star",
//                 "starCount": 8169,
//                 "reviewCount": 5644
//             },
//             {
//                 "name": "2 Star",
//                 "starCount": 179,
//                 "reviewCount": 7053
//             },
//             {
//                 "name": "3 Star",
//                 "starCount": 26,
//                 "reviewCount": 8441
//             },
//             {
//                 "name": "4 Star",
//                 "starCount": 6874,
//                 "reviewCount": 6599
//             },
//             {
//                 "name": "5 Star",
//                 "starCount": 3929,
//                 "reviewCount": 7324
//             }
//         ],
//         "reviews": [
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1",
//                 "name": "Jayvion Simon",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_1.jpg",
//                 "comment": "Assumenda nam repudiandae rerum fugiat vel maxime.",
//                 "rating": 2.5,
//                 "isPurchased": true,
//                 "helpful": 944,
//                 "postedAt": "2023-06-01T12:40:08.056Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b2",
//                 "name": "Lucian Obrien",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_2.jpg",
//                 "comment": "Quis veniam aut saepe aliquid nulla.",
//                 "rating": 2,
//                 "isPurchased": true,
//                 "helpful": 9744,
//                 "postedAt": "2023-05-31T11:40:08.056Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b3",
//                 "name": "Deja Brady",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_3.jpg",
//                 "comment": "Reprehenderit ut voluptas sapiente ratione nostrum est.",
//                 "rating": 4.9,
//                 "isPurchased": true,
//                 "helpful": 9072,
//                 "postedAt": "2023-05-30T10:40:08.056Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b4",
//                 "name": "Harrison Stein",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_4.jpg",
//                 "comment": "Error ut sit vel molestias velit.",
//                 "rating": 2,
//                 "isPurchased": false,
//                 "helpful": 8105,
//                 "postedAt": "2023-05-29T09:40:08.056Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5",
//                 "name": "Reece Chung",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_5.jpg",
//                 "comment": "Quo quia sit nihil nemo doloremque et.",
//                 "rating": 4,
//                 "isPurchased": false,
//                 "helpful": 3634,
//                 "postedAt": "2023-05-28T08:40:08.056Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b6",
//                 "name": "Lainey Davidson",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_6.jpg",
//                 "comment": "Autem doloribus harum vero laborum.",
//                 "rating": 5,
//                 "isPurchased": true,
//                 "helpful": 1428,
//                 "postedAt": "2023-05-27T07:40:08.056Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b7",
//                 "name": "Cristopher Cardenas",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_7.jpg",
//                 "comment": "Tempora officiis consequuntur architecto nostrum autem nam adipisci.",
//                 "rating": 4.9,
//                 "isPurchased": false,
//                 "helpful": 9027,
//                 "postedAt": "2023-05-26T06:40:08.056Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b8",
//                 "name": "Melanie Noble",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_8.jpg",
//                 "comment": "Voluptas sunt magni adipisci praesentium saepe.",
//                 "rating": 5,
//                 "isPurchased": false,
//                 "helpful": 9392,
//                 "postedAt": "2023-05-25T05:40:08.056Z"
//             }
//         ],
//         "status": "",
//         "inventoryType": "low_stock",
//         "sizes": [
//             "6",
//             "7",
//             "8",
//             "8.5",
//             "9",
//             "9.5",
//             "10",
//             "10.5",
//             "11",
//             "11.5",
//             "12",
//             "13"
//         ],
//         "available": 2,
//         "description": "\n<p><strong><small> SPECIFICATION</small></strong></p>\n<p>Leather panels. Laces. Rounded toe. Rubber sole.\n<br /><br />\n<p><strong><small> MATERIAL AND WASHING INSTRUCTIONS</small></strong></p>\n<p>Shoeupper: 54% bovine leather,46% polyurethane. Lining: 65% polyester,35% cotton. Insole: 100% polyurethane. Sole: 100% thermoplastic. Fixing sole: 100% glued.</p>\n",
//         "sold": 550,
//         "createdAt": "2023-05-27T07:40:08.056Z",
//         "category": "Apparel",
//         "gender": "Kids",
//         "colors": [
//             "#1890FF"
//         ]
//     },
//     {
//         "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b17",
//         "cover": "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_17.jpg",
//         "images": [
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_1.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_2.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_3.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_4.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_5.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_6.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_7.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_8.jpg"
//         ],
//         "name": "2750 Cotu Classic Sneaker",
//         "code": "38BEE2716",
//         "sku": "WW75K52116YW/SV",
//         "tags": [
//             "Dangal",
//             "The Sting",
//             "2001: A Space Odyssey",
//             "Singin' in the Rain"
//         ],
//         "price": 75.78,
//         "priceSale": null,
//         "totalRating": 1.4,
//         "totalReview": 8537,
//         "ratings": [
//             {
//                 "name": "1 Star",
//                 "starCount": 8977,
//                 "reviewCount": 548
//             },
//             {
//                 "name": "2 Star",
//                 "starCount": 431,
//                 "reviewCount": 6302
//             },
//             {
//                 "name": "3 Star",
//                 "starCount": 68,
//                 "reviewCount": 9491
//             },
//             {
//                 "name": "4 Star",
//                 "starCount": 4618,
//                 "reviewCount": 7975
//             },
//             {
//                 "name": "5 Star",
//                 "starCount": 6033,
//                 "reviewCount": 6308
//             }
//         ],
//         "reviews": [
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1",
//                 "name": "Jayvion Simon",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_1.jpg",
//                 "comment": "Assumenda nam repudiandae rerum fugiat vel maxime.",
//                 "rating": 2.5,
//                 "isPurchased": true,
//                 "helpful": 6679,
//                 "postedAt": "2023-06-01T12:40:08.058Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b2",
//                 "name": "Lucian Obrien",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_2.jpg",
//                 "comment": "Quis veniam aut saepe aliquid nulla.",
//                 "rating": 2,
//                 "isPurchased": true,
//                 "helpful": 3247,
//                 "postedAt": "2023-05-31T11:40:08.058Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b3",
//                 "name": "Deja Brady",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_3.jpg",
//                 "comment": "Reprehenderit ut voluptas sapiente ratione nostrum est.",
//                 "rating": 4.9,
//                 "isPurchased": true,
//                 "helpful": 7921,
//                 "postedAt": "2023-05-30T10:40:08.058Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b4",
//                 "name": "Harrison Stein",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_4.jpg",
//                 "comment": "Error ut sit vel molestias velit.",
//                 "rating": 2,
//                 "isPurchased": false,
//                 "helpful": 4097,
//                 "postedAt": "2023-05-29T09:40:08.058Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5",
//                 "name": "Reece Chung",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_5.jpg",
//                 "comment": "Quo quia sit nihil nemo doloremque et.",
//                 "rating": 4,
//                 "isPurchased": false,
//                 "helpful": 7937,
//                 "postedAt": "2023-05-28T08:40:08.058Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b6",
//                 "name": "Lainey Davidson",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_6.jpg",
//                 "comment": "Autem doloribus harum vero laborum.",
//                 "rating": 5,
//                 "isPurchased": true,
//                 "helpful": 2742,
//                 "postedAt": "2023-05-27T07:40:08.058Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b7",
//                 "name": "Cristopher Cardenas",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_7.jpg",
//                 "comment": "Tempora officiis consequuntur architecto nostrum autem nam adipisci.",
//                 "rating": 4.9,
//                 "isPurchased": false,
//                 "helpful": 4684,
//                 "postedAt": "2023-05-26T06:40:08.058Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b8",
//                 "name": "Melanie Noble",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_8.jpg",
//                 "comment": "Voluptas sunt magni adipisci praesentium saepe.",
//                 "rating": 5,
//                 "isPurchased": false,
//                 "helpful": 5958,
//                 "postedAt": "2023-05-25T05:40:08.058Z"
//             }
//         ],
//         "status": "",
//         "inventoryType": "in_stock",
//         "sizes": [
//             "6",
//             "7",
//             "8",
//             "8.5",
//             "9",
//             "9.5",
//             "10",
//             "10.5",
//             "11",
//             "11.5",
//             "12",
//             "13"
//         ],
//         "available": 2,
//         "description": "\n<p><strong><small> SPECIFICATION</small></strong></p>\n<p>Leather panels. Laces. Rounded toe. Rubber sole.\n<br /><br />\n<p><strong><small> MATERIAL AND WASHING INSTRUCTIONS</small></strong></p>\n<p>Shoeupper: 54% bovine leather,46% polyurethane. Lining: 65% polyester,35% cotton. Insole: 100% polyurethane. Sole: 100% thermoplastic. Fixing sole: 100% glued.</p>\n",
//         "sold": 540,
//         "createdAt": "2023-05-15T20:40:08.058Z",
//         "category": "Apparel",
//         "gender": "Men",
//         "colors": [
//             "#FF4842",
//             "#1890FF"
//         ]
//     },
//     {
//         "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5",
//         "cover": "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_5.jpg",
//         "images": [
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_1.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_2.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_3.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_4.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_5.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_6.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_7.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_8.jpg"
//         ],
//         "name": "Boston Soft Footbed Sandal",
//         "code": "38BEE274",
//         "sku": "WW75K5214YW/SV",
//         "tags": [
//             "Dangal",
//             "The Sting",
//             "2001: A Space Odyssey",
//             "Singin' in the Rain"
//         ],
//         "price": 55.47,
//         "priceSale": null,
//         "totalRating": 4,
//         "totalReview": 1897,
//         "ratings": [
//             {
//                 "name": "1 Star",
//                 "starCount": 7405,
//                 "reviewCount": 9564
//             },
//             {
//                 "name": "2 Star",
//                 "starCount": 7201,
//                 "reviewCount": 5845
//             },
//             {
//                 "name": "3 Star",
//                 "starCount": 8408,
//                 "reviewCount": 2468
//             },
//             {
//                 "name": "4 Star",
//                 "starCount": 1307,
//                 "reviewCount": 7137
//             },
//             {
//                 "name": "5 Star",
//                 "starCount": 9375,
//                 "reviewCount": 4262
//             }
//         ],
//         "reviews": [
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1",
//                 "name": "Jayvion Simon",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_1.jpg",
//                 "comment": "Assumenda nam repudiandae rerum fugiat vel maxime.",
//                 "rating": 2.5,
//                 "isPurchased": true,
//                 "helpful": 5554,
//                 "postedAt": "2023-06-01T12:40:08.056Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b2",
//                 "name": "Lucian Obrien",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_2.jpg",
//                 "comment": "Quis veniam aut saepe aliquid nulla.",
//                 "rating": 2,
//                 "isPurchased": true,
//                 "helpful": 818,
//                 "postedAt": "2023-05-31T11:40:08.056Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b3",
//                 "name": "Deja Brady",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_3.jpg",
//                 "comment": "Reprehenderit ut voluptas sapiente ratione nostrum est.",
//                 "rating": 4.9,
//                 "isPurchased": true,
//                 "helpful": 7284,
//                 "postedAt": "2023-05-30T10:40:08.056Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b4",
//                 "name": "Harrison Stein",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_4.jpg",
//                 "comment": "Error ut sit vel molestias velit.",
//                 "rating": 2,
//                 "isPurchased": false,
//                 "helpful": 519,
//                 "postedAt": "2023-05-29T09:40:08.056Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5",
//                 "name": "Reece Chung",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_5.jpg",
//                 "comment": "Quo quia sit nihil nemo doloremque et.",
//                 "rating": 4,
//                 "isPurchased": false,
//                 "helpful": 7352,
//                 "postedAt": "2023-05-28T08:40:08.056Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b6",
//                 "name": "Lainey Davidson",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_6.jpg",
//                 "comment": "Autem doloribus harum vero laborum.",
//                 "rating": 5,
//                 "isPurchased": true,
//                 "helpful": 1550,
//                 "postedAt": "2023-05-27T07:40:08.056Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b7",
//                 "name": "Cristopher Cardenas",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_7.jpg",
//                 "comment": "Tempora officiis consequuntur architecto nostrum autem nam adipisci.",
//                 "rating": 4.9,
//                 "isPurchased": false,
//                 "helpful": 82,
//                 "postedAt": "2023-05-26T06:40:08.056Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b8",
//                 "name": "Melanie Noble",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_8.jpg",
//                 "comment": "Voluptas sunt magni adipisci praesentium saepe.",
//                 "rating": 5,
//                 "isPurchased": false,
//                 "helpful": 7451,
//                 "postedAt": "2023-05-25T05:40:08.056Z"
//             }
//         ],
//         "status": "new",
//         "inventoryType": "low_stock",
//         "sizes": [
//             "6",
//             "7",
//             "8",
//             "8.5",
//             "9",
//             "9.5",
//             "10",
//             "10.5",
//             "11",
//             "11.5",
//             "12",
//             "13"
//         ],
//         "available": 2,
//         "description": "\n<p><strong><small> SPECIFICATION</small></strong></p>\n<p>Leather panels. Laces. Rounded toe. Rubber sole.\n<br /><br />\n<p><strong><small> MATERIAL AND WASHING INSTRUCTIONS</small></strong></p>\n<p>Shoeupper: 54% bovine leather,46% polyurethane. Lining: 65% polyester,35% cotton. Insole: 100% polyurethane. Sole: 100% thermoplastic. Fixing sole: 100% glued.</p>\n",
//         "sold": 422,
//         "createdAt": "2023-05-28T08:40:08.056Z",
//         "category": "Shose",
//         "gender": "Men",
//         "colors": [
//             "#FF4842",
//             "#1890FF"
//         ]
//     },
//     {
//         "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b9",
//         "cover": "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_9.jpg",
//         "images": [
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_1.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_2.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_3.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_4.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_5.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_6.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_7.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_8.jpg"
//         ],
//         "name": "Air Jordan XXXV PF",
//         "code": "38BEE278",
//         "sku": "WW75K5218YW/SV",
//         "tags": [
//             "Dangal",
//             "The Sting",
//             "2001: A Space Odyssey",
//             "Singin' in the Rain"
//         ],
//         "price": 45.35,
//         "priceSale": null,
//         "totalRating": 3.7,
//         "totalReview": 4756,
//         "ratings": [
//             {
//                 "name": "1 Star",
//                 "starCount": 3872,
//                 "reviewCount": 3704
//             },
//             {
//                 "name": "2 Star",
//                 "starCount": 479,
//                 "reviewCount": 5915
//             },
//             {
//                 "name": "3 Star",
//                 "starCount": 8343,
//                 "reviewCount": 9123
//             },
//             {
//                 "name": "4 Star",
//                 "starCount": 8748,
//                 "reviewCount": 1862
//             },
//             {
//                 "name": "5 Star",
//                 "starCount": 5709,
//                 "reviewCount": 7688
//             }
//         ],
//         "reviews": [
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1",
//                 "name": "Jayvion Simon",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_1.jpg",
//                 "comment": "Assumenda nam repudiandae rerum fugiat vel maxime.",
//                 "rating": 2.5,
//                 "isPurchased": true,
//                 "helpful": 9372,
//                 "postedAt": "2023-06-01T12:40:08.057Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b2",
//                 "name": "Lucian Obrien",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_2.jpg",
//                 "comment": "Quis veniam aut saepe aliquid nulla.",
//                 "rating": 2,
//                 "isPurchased": true,
//                 "helpful": 726,
//                 "postedAt": "2023-05-31T11:40:08.057Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b3",
//                 "name": "Deja Brady",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_3.jpg",
//                 "comment": "Reprehenderit ut voluptas sapiente ratione nostrum est.",
//                 "rating": 4.9,
//                 "isPurchased": true,
//                 "helpful": 429,
//                 "postedAt": "2023-05-30T10:40:08.057Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b4",
//                 "name": "Harrison Stein",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_4.jpg",
//                 "comment": "Error ut sit vel molestias velit.",
//                 "rating": 2,
//                 "isPurchased": false,
//                 "helpful": 6189,
//                 "postedAt": "2023-05-29T09:40:08.057Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5",
//                 "name": "Reece Chung",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_5.jpg",
//                 "comment": "Quo quia sit nihil nemo doloremque et.",
//                 "rating": 4,
//                 "isPurchased": false,
//                 "helpful": 6311,
//                 "postedAt": "2023-05-28T08:40:08.057Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b6",
//                 "name": "Lainey Davidson",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_6.jpg",
//                 "comment": "Autem doloribus harum vero laborum.",
//                 "rating": 5,
//                 "isPurchased": true,
//                 "helpful": 9067,
//                 "postedAt": "2023-05-27T07:40:08.057Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b7",
//                 "name": "Cristopher Cardenas",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_7.jpg",
//                 "comment": "Tempora officiis consequuntur architecto nostrum autem nam adipisci.",
//                 "rating": 4.9,
//                 "isPurchased": false,
//                 "helpful": 7004,
//                 "postedAt": "2023-05-26T06:40:08.057Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b8",
//                 "name": "Melanie Noble",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_8.jpg",
//                 "comment": "Voluptas sunt magni adipisci praesentium saepe.",
//                 "rating": 5,
//                 "isPurchased": false,
//                 "helpful": 7021,
//                 "postedAt": "2023-05-25T05:40:08.057Z"
//             }
//         ],
//         "status": "",
//         "inventoryType": "in_stock",
//         "sizes": [
//             "6",
//             "7",
//             "8",
//             "8.5",
//             "9",
//             "9.5",
//             "10",
//             "10.5",
//             "11",
//             "11.5",
//             "12",
//             "13"
//         ],
//         "available": 2,
//         "description": "\n<p><strong><small> SPECIFICATION</small></strong></p>\n<p>Leather panels. Laces. Rounded toe. Rubber sole.\n<br /><br />\n<p><strong><small> MATERIAL AND WASHING INSTRUCTIONS</small></strong></p>\n<p>Shoeupper: 54% bovine leather,46% polyurethane. Lining: 65% polyester,35% cotton. Insole: 100% polyurethane. Sole: 100% thermoplastic. Fixing sole: 100% glued.</p>\n",
//         "sold": 306,
//         "createdAt": "2023-05-24T04:40:08.057Z",
//         "category": "Accessories",
//         "gender": "Women",
//         "colors": [
//             "#FFFFFF",
//             "#FFC0CB"
//         ]
//     },
//     {
//         "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b4",
//         "cover": "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_4.jpg",
//         "images": [
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_1.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_2.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_3.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_4.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_5.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_6.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_7.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_8.jpg"
//         ],
//         "name": "Arizona Soft Footbed Sandal",
//         "code": "38BEE273",
//         "sku": "WW75K5213YW/SV",
//         "tags": [
//             "Dangal",
//             "The Sting",
//             "2001: A Space Odyssey",
//             "Singin' in the Rain"
//         ],
//         "price": 93.1,
//         "priceSale": 93.1,
//         "totalRating": 2,
//         "totalReview": 3025,
//         "ratings": [
//             {
//                 "name": "1 Star",
//                 "starCount": 8493,
//                 "reviewCount": 270
//             },
//             {
//                 "name": "2 Star",
//                 "starCount": 7633,
//                 "reviewCount": 6216
//             },
//             {
//                 "name": "3 Star",
//                 "starCount": 9840,
//                 "reviewCount": 3050
//             },
//             {
//                 "name": "4 Star",
//                 "starCount": 3173,
//                 "reviewCount": 1829
//             },
//             {
//                 "name": "5 Star",
//                 "starCount": 6489,
//                 "reviewCount": 360
//             }
//         ],
//         "reviews": [
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1",
//                 "name": "Jayvion Simon",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_1.jpg",
//                 "comment": "Assumenda nam repudiandae rerum fugiat vel maxime.",
//                 "rating": 2.5,
//                 "isPurchased": true,
//                 "helpful": 5334,
//                 "postedAt": "2023-06-01T12:40:08.056Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b2",
//                 "name": "Lucian Obrien",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_2.jpg",
//                 "comment": "Quis veniam aut saepe aliquid nulla.",
//                 "rating": 2,
//                 "isPurchased": true,
//                 "helpful": 4787,
//                 "postedAt": "2023-05-31T11:40:08.056Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b3",
//                 "name": "Deja Brady",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_3.jpg",
//                 "comment": "Reprehenderit ut voluptas sapiente ratione nostrum est.",
//                 "rating": 4.9,
//                 "isPurchased": true,
//                 "helpful": 5410,
//                 "postedAt": "2023-05-30T10:40:08.056Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b4",
//                 "name": "Harrison Stein",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_4.jpg",
//                 "comment": "Error ut sit vel molestias velit.",
//                 "rating": 2,
//                 "isPurchased": false,
//                 "helpful": 3801,
//                 "postedAt": "2023-05-29T09:40:08.056Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5",
//                 "name": "Reece Chung",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_5.jpg",
//                 "comment": "Quo quia sit nihil nemo doloremque et.",
//                 "rating": 4,
//                 "isPurchased": false,
//                 "helpful": 8013,
//                 "postedAt": "2023-05-28T08:40:08.056Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b6",
//                 "name": "Lainey Davidson",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_6.jpg",
//                 "comment": "Autem doloribus harum vero laborum.",
//                 "rating": 5,
//                 "isPurchased": true,
//                 "helpful": 504,
//                 "postedAt": "2023-05-27T07:40:08.056Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b7",
//                 "name": "Cristopher Cardenas",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_7.jpg",
//                 "comment": "Tempora officiis consequuntur architecto nostrum autem nam adipisci.",
//                 "rating": 4.9,
//                 "isPurchased": false,
//                 "helpful": 5005,
//                 "postedAt": "2023-05-26T06:40:08.056Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b8",
//                 "name": "Melanie Noble",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_8.jpg",
//                 "comment": "Voluptas sunt magni adipisci praesentium saepe.",
//                 "rating": 5,
//                 "isPurchased": false,
//                 "helpful": 103,
//                 "postedAt": "2023-05-25T05:40:08.056Z"
//             }
//         ],
//         "status": "sale",
//         "inventoryType": "low_stock",
//         "sizes": [
//             "6",
//             "7",
//             "8",
//             "8.5",
//             "9",
//             "9.5",
//             "10",
//             "10.5",
//             "11",
//             "11.5",
//             "12",
//             "13"
//         ],
//         "available": 63,
//         "description": "\n<p><strong><small> SPECIFICATION</small></strong></p>\n<p>Leather panels. Laces. Rounded toe. Rubber sole.\n<br /><br />\n<p><strong><small> MATERIAL AND WASHING INSTRUCTIONS</small></strong></p>\n<p>Shoeupper: 54% bovine leather,46% polyurethane. Lining: 65% polyester,35% cotton. Insole: 100% polyurethane. Sole: 100% thermoplastic. Fixing sole: 100% glued.</p>\n",
//         "sold": 235,
//         "createdAt": "2023-05-29T09:40:08.056Z",
//         "category": "Shose",
//         "gender": "Kids",
//         "colors": [
//             "#FFC0CB",
//             "#FF4842",
//             "#1890FF"
//         ]
//     },
//     {
//         "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b10",
//         "cover": "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_10.jpg",
//         "images": [
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_1.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_2.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_3.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_4.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_5.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_6.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_7.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_8.jpg"
//         ],
//         "name": "Rod Laver low-top sneakers",
//         "code": "38BEE279",
//         "sku": "WW75K5219YW/SV",
//         "tags": [
//             "Dangal",
//             "The Sting",
//             "2001: A Space Odyssey",
//             "Singin' in the Rain"
//         ],
//         "price": 26.96,
//         "priceSale": 26.96,
//         "totalRating": 2.5,
//         "totalReview": 7096,
//         "ratings": [
//             {
//                 "name": "1 Star",
//                 "starCount": 8312,
//                 "reviewCount": 8089
//             },
//             {
//                 "name": "2 Star",
//                 "starCount": 6830,
//                 "reviewCount": 9223
//             },
//             {
//                 "name": "3 Star",
//                 "starCount": 613,
//                 "reviewCount": 9477
//             },
//             {
//                 "name": "4 Star",
//                 "starCount": 6285,
//                 "reviewCount": 2740
//             },
//             {
//                 "name": "5 Star",
//                 "starCount": 5631,
//                 "reviewCount": 5246
//             }
//         ],
//         "reviews": [
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1",
//                 "name": "Jayvion Simon",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_1.jpg",
//                 "comment": "Assumenda nam repudiandae rerum fugiat vel maxime.",
//                 "rating": 2.5,
//                 "isPurchased": true,
//                 "helpful": 4318,
//                 "postedAt": "2023-06-01T12:40:08.057Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b2",
//                 "name": "Lucian Obrien",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_2.jpg",
//                 "comment": "Quis veniam aut saepe aliquid nulla.",
//                 "rating": 2,
//                 "isPurchased": true,
//                 "helpful": 7833,
//                 "postedAt": "2023-05-31T11:40:08.057Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b3",
//                 "name": "Deja Brady",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_3.jpg",
//                 "comment": "Reprehenderit ut voluptas sapiente ratione nostrum est.",
//                 "rating": 4.9,
//                 "isPurchased": true,
//                 "helpful": 2804,
//                 "postedAt": "2023-05-30T10:40:08.057Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b4",
//                 "name": "Harrison Stein",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_4.jpg",
//                 "comment": "Error ut sit vel molestias velit.",
//                 "rating": 2,
//                 "isPurchased": false,
//                 "helpful": 2350,
//                 "postedAt": "2023-05-29T09:40:08.057Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5",
//                 "name": "Reece Chung",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_5.jpg",
//                 "comment": "Quo quia sit nihil nemo doloremque et.",
//                 "rating": 4,
//                 "isPurchased": false,
//                 "helpful": 2594,
//                 "postedAt": "2023-05-28T08:40:08.057Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b6",
//                 "name": "Lainey Davidson",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_6.jpg",
//                 "comment": "Autem doloribus harum vero laborum.",
//                 "rating": 5,
//                 "isPurchased": true,
//                 "helpful": 7164,
//                 "postedAt": "2023-05-27T07:40:08.057Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b7",
//                 "name": "Cristopher Cardenas",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_7.jpg",
//                 "comment": "Tempora officiis consequuntur architecto nostrum autem nam adipisci.",
//                 "rating": 4.9,
//                 "isPurchased": false,
//                 "helpful": 8463,
//                 "postedAt": "2023-05-26T06:40:08.057Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b8",
//                 "name": "Melanie Noble",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_8.jpg",
//                 "comment": "Voluptas sunt magni adipisci praesentium saepe.",
//                 "rating": 5,
//                 "isPurchased": false,
//                 "helpful": 9210,
//                 "postedAt": "2023-05-25T05:40:08.057Z"
//             }
//         ],
//         "status": "sale",
//         "inventoryType": "in_stock",
//         "sizes": [
//             "6",
//             "7",
//             "8",
//             "8.5",
//             "9",
//             "9.5",
//             "10",
//             "10.5",
//             "11",
//             "11.5",
//             "12",
//             "13"
//         ],
//         "available": 43,
//         "description": "\n<p><strong><small> SPECIFICATION</small></strong></p>\n<p>Leather panels. Laces. Rounded toe. Rubber sole.\n<br /><br />\n<p><strong><small> MATERIAL AND WASHING INSTRUCTIONS</small></strong></p>\n<p>Shoeupper: 54% bovine leather,46% polyurethane. Lining: 65% polyester,35% cotton. Insole: 100% polyurethane. Sole: 100% thermoplastic. Fixing sole: 100% glued.</p>\n",
//         "sold": 190,
//         "createdAt": "2023-05-23T03:40:08.057Z",
//         "category": "Shose",
//         "gender": "Women",
//         "colors": [
//             "#FFFFFF",
//             "#FFC0CB",
//             "#FF4842",
//             "#1890FF"
//         ]
//     },
//     {
//         "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b13",
//         "cover": "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_13.jpg",
//         "images": [
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_1.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_2.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_3.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_4.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_5.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_6.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_7.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_8.jpg"
//         ],
//         "name": "Nike Blazer Low 77 Vintage",
//         "code": "38BEE2712",
//         "sku": "WW75K52112YW/SV",
//         "tags": [
//             "Dangal",
//             "The Sting",
//             "2001: A Space Odyssey",
//             "Singin' in the Rain"
//         ],
//         "price": 90.69,
//         "priceSale": 90.69,
//         "totalRating": 4.8,
//         "totalReview": 8913,
//         "ratings": [
//             {
//                 "name": "1 Star",
//                 "starCount": 265,
//                 "reviewCount": 7290
//             },
//             {
//                 "name": "2 Star",
//                 "starCount": 4905,
//                 "reviewCount": 8201
//             },
//             {
//                 "name": "3 Star",
//                 "starCount": 504,
//                 "reviewCount": 6544
//             },
//             {
//                 "name": "4 Star",
//                 "starCount": 1386,
//                 "reviewCount": 5460
//             },
//             {
//                 "name": "5 Star",
//                 "starCount": 7976,
//                 "reviewCount": 813
//             }
//         ],
//         "reviews": [
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1",
//                 "name": "Jayvion Simon",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_1.jpg",
//                 "comment": "Assumenda nam repudiandae rerum fugiat vel maxime.",
//                 "rating": 2.5,
//                 "isPurchased": true,
//                 "helpful": 3514,
//                 "postedAt": "2023-06-01T12:40:08.057Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b2",
//                 "name": "Lucian Obrien",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_2.jpg",
//                 "comment": "Quis veniam aut saepe aliquid nulla.",
//                 "rating": 2,
//                 "isPurchased": true,
//                 "helpful": 1537,
//                 "postedAt": "2023-05-31T11:40:08.057Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b3",
//                 "name": "Deja Brady",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_3.jpg",
//                 "comment": "Reprehenderit ut voluptas sapiente ratione nostrum est.",
//                 "rating": 4.9,
//                 "isPurchased": true,
//                 "helpful": 5944,
//                 "postedAt": "2023-05-30T10:40:08.057Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b4",
//                 "name": "Harrison Stein",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_4.jpg",
//                 "comment": "Error ut sit vel molestias velit.",
//                 "rating": 2,
//                 "isPurchased": false,
//                 "helpful": 2321,
//                 "postedAt": "2023-05-29T09:40:08.057Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5",
//                 "name": "Reece Chung",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_5.jpg",
//                 "comment": "Quo quia sit nihil nemo doloremque et.",
//                 "rating": 4,
//                 "isPurchased": false,
//                 "helpful": 6914,
//                 "postedAt": "2023-05-28T08:40:08.057Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b6",
//                 "name": "Lainey Davidson",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_6.jpg",
//                 "comment": "Autem doloribus harum vero laborum.",
//                 "rating": 5,
//                 "isPurchased": true,
//                 "helpful": 8009,
//                 "postedAt": "2023-05-27T07:40:08.057Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b7",
//                 "name": "Cristopher Cardenas",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_7.jpg",
//                 "comment": "Tempora officiis consequuntur architecto nostrum autem nam adipisci.",
//                 "rating": 4.9,
//                 "isPurchased": false,
//                 "helpful": 3184,
//                 "postedAt": "2023-05-26T06:40:08.057Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b8",
//                 "name": "Melanie Noble",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_8.jpg",
//                 "comment": "Voluptas sunt magni adipisci praesentium saepe.",
//                 "rating": 5,
//                 "isPurchased": false,
//                 "helpful": 2866,
//                 "postedAt": "2023-05-25T05:40:08.057Z"
//             }
//         ],
//         "status": "sale",
//         "inventoryType": "out_of_stock",
//         "sizes": [
//             "6",
//             "7",
//             "8",
//             "8.5",
//             "9",
//             "9.5",
//             "10",
//             "10.5",
//             "11",
//             "11.5",
//             "12",
//             "13"
//         ],
//         "available": 99,
//         "description": "\n<p><strong><small> SPECIFICATION</small></strong></p>\n<p>Leather panels. Laces. Rounded toe. Rubber sole.\n<br /><br />\n<p><strong><small> MATERIAL AND WASHING INSTRUCTIONS</small></strong></p>\n<p>Shoeupper: 54% bovine leather,46% polyurethane. Lining: 65% polyester,35% cotton. Insole: 100% polyurethane. Sole: 100% thermoplastic. Fixing sole: 100% glued.</p>\n",
//         "sold": 187,
//         "createdAt": "2023-05-20T00:40:08.057Z",
//         "category": "Accessories",
//         "gender": "Kids",
//         "colors": [
//             "#FFFFFF",
//             "#FFC0CB",
//             "#FF4842",
//             "#1890FF",
//             "#94D82D"
//         ]
//     },
//     {
//         "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b11",
//         "cover": "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_11.jpg",
//         "images": [
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_1.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_2.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_3.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_4.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_5.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_6.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_7.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_8.jpg"
//         ],
//         "name": "Kyrie 7 EP Sisterhood",
//         "code": "38BEE2710",
//         "sku": "WW75K52110YW/SV",
//         "tags": [
//             "Dangal",
//             "The Sting",
//             "2001: A Space Odyssey",
//             "Singin' in the Rain"
//         ],
//         "price": 78.22,
//         "priceSale": null,
//         "totalRating": 2,
//         "totalReview": 9717,
//         "ratings": [
//             {
//                 "name": "1 Star",
//                 "starCount": 2602,
//                 "reviewCount": 6839
//             },
//             {
//                 "name": "2 Star",
//                 "starCount": 1259,
//                 "reviewCount": 7894
//             },
//             {
//                 "name": "3 Star",
//                 "starCount": 4519,
//                 "reviewCount": 6195
//             },
//             {
//                 "name": "4 Star",
//                 "starCount": 3831,
//                 "reviewCount": 1082
//             },
//             {
//                 "name": "5 Star",
//                 "starCount": 223,
//                 "reviewCount": 2144
//             }
//         ],
//         "reviews": [
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1",
//                 "name": "Jayvion Simon",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_1.jpg",
//                 "comment": "Assumenda nam repudiandae rerum fugiat vel maxime.",
//                 "rating": 2.5,
//                 "isPurchased": true,
//                 "helpful": 5362,
//                 "postedAt": "2023-06-01T12:40:08.057Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b2",
//                 "name": "Lucian Obrien",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_2.jpg",
//                 "comment": "Quis veniam aut saepe aliquid nulla.",
//                 "rating": 2,
//                 "isPurchased": true,
//                 "helpful": 3010,
//                 "postedAt": "2023-05-31T11:40:08.057Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b3",
//                 "name": "Deja Brady",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_3.jpg",
//                 "comment": "Reprehenderit ut voluptas sapiente ratione nostrum est.",
//                 "rating": 4.9,
//                 "isPurchased": true,
//                 "helpful": 8150,
//                 "postedAt": "2023-05-30T10:40:08.057Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b4",
//                 "name": "Harrison Stein",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_4.jpg",
//                 "comment": "Error ut sit vel molestias velit.",
//                 "rating": 2,
//                 "isPurchased": false,
//                 "helpful": 9122,
//                 "postedAt": "2023-05-29T09:40:08.057Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5",
//                 "name": "Reece Chung",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_5.jpg",
//                 "comment": "Quo quia sit nihil nemo doloremque et.",
//                 "rating": 4,
//                 "isPurchased": false,
//                 "helpful": 5906,
//                 "postedAt": "2023-05-28T08:40:08.057Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b6",
//                 "name": "Lainey Davidson",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_6.jpg",
//                 "comment": "Autem doloribus harum vero laborum.",
//                 "rating": 5,
//                 "isPurchased": true,
//                 "helpful": 4050,
//                 "postedAt": "2023-05-27T07:40:08.057Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b7",
//                 "name": "Cristopher Cardenas",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_7.jpg",
//                 "comment": "Tempora officiis consequuntur architecto nostrum autem nam adipisci.",
//                 "rating": 4.9,
//                 "isPurchased": false,
//                 "helpful": 5891,
//                 "postedAt": "2023-05-26T06:40:08.057Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b8",
//                 "name": "Melanie Noble",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_8.jpg",
//                 "comment": "Voluptas sunt magni adipisci praesentium saepe.",
//                 "rating": 5,
//                 "isPurchased": false,
//                 "helpful": 9995,
//                 "postedAt": "2023-05-25T05:40:08.057Z"
//             }
//         ],
//         "status": "",
//         "inventoryType": "low_stock",
//         "sizes": [
//             "6",
//             "7",
//             "8",
//             "8.5",
//             "9",
//             "9.5",
//             "10",
//             "10.5",
//             "11",
//             "11.5",
//             "12",
//             "13"
//         ],
//         "available": 2,
//         "description": "\n<p><strong><small> SPECIFICATION</small></strong></p>\n<p>Leather panels. Laces. Rounded toe. Rubber sole.\n<br /><br />\n<p><strong><small> MATERIAL AND WASHING INSTRUCTIONS</small></strong></p>\n<p>Shoeupper: 54% bovine leather,46% polyurethane. Lining: 65% polyester,35% cotton. Insole: 100% polyurethane. Sole: 100% thermoplastic. Fixing sole: 100% glued.</p>\n",
//         "sold": 182,
//         "createdAt": "2023-05-22T02:40:08.057Z",
//         "category": "Accessories",
//         "gender": "Kids",
//         "colors": [
//             "#FFC0CB",
//             "#FF4842",
//             "#1890FF"
//         ]
//     },
//     {
//         "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b24",
//         "cover": "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_24.jpg",
//         "images": [
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_1.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_2.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_3.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_4.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_5.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_6.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_7.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_8.jpg"
//         ],
//         "name": "Run Star Hike Platform Sneaker",
//         "code": "38BEE2723",
//         "sku": "WW75K52123YW/SV",
//         "tags": [
//             "Dangal",
//             "The Sting",
//             "2001: A Space Odyssey",
//             "Singin' in the Rain"
//         ],
//         "price": 60.96,
//         "priceSale": null,
//         "totalRating": 1.8,
//         "totalReview": 8786,
//         "ratings": [
//             {
//                 "name": "1 Star",
//                 "starCount": 8031,
//                 "reviewCount": 6534
//             },
//             {
//                 "name": "2 Star",
//                 "starCount": 5017,
//                 "reviewCount": 5824
//             },
//             {
//                 "name": "3 Star",
//                 "starCount": 9309,
//                 "reviewCount": 1241
//             },
//             {
//                 "name": "4 Star",
//                 "starCount": 5485,
//                 "reviewCount": 6697
//             },
//             {
//                 "name": "5 Star",
//                 "starCount": 7503,
//                 "reviewCount": 6977
//             }
//         ],
//         "reviews": [
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1",
//                 "name": "Jayvion Simon",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_1.jpg",
//                 "comment": "Assumenda nam repudiandae rerum fugiat vel maxime.",
//                 "rating": 2.5,
//                 "isPurchased": true,
//                 "helpful": 8193,
//                 "postedAt": "2023-06-01T12:40:08.058Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b2",
//                 "name": "Lucian Obrien",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_2.jpg",
//                 "comment": "Quis veniam aut saepe aliquid nulla.",
//                 "rating": 2,
//                 "isPurchased": true,
//                 "helpful": 5522,
//                 "postedAt": "2023-05-31T11:40:08.058Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b3",
//                 "name": "Deja Brady",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_3.jpg",
//                 "comment": "Reprehenderit ut voluptas sapiente ratione nostrum est.",
//                 "rating": 4.9,
//                 "isPurchased": true,
//                 "helpful": 8903,
//                 "postedAt": "2023-05-30T10:40:08.058Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b4",
//                 "name": "Harrison Stein",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_4.jpg",
//                 "comment": "Error ut sit vel molestias velit.",
//                 "rating": 2,
//                 "isPurchased": false,
//                 "helpful": 7618,
//                 "postedAt": "2023-05-29T09:40:08.058Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5",
//                 "name": "Reece Chung",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_5.jpg",
//                 "comment": "Quo quia sit nihil nemo doloremque et.",
//                 "rating": 4,
//                 "isPurchased": false,
//                 "helpful": 2577,
//                 "postedAt": "2023-05-28T08:40:08.059Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b6",
//                 "name": "Lainey Davidson",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_6.jpg",
//                 "comment": "Autem doloribus harum vero laborum.",
//                 "rating": 5,
//                 "isPurchased": true,
//                 "helpful": 673,
//                 "postedAt": "2023-05-27T07:40:08.059Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b7",
//                 "name": "Cristopher Cardenas",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_7.jpg",
//                 "comment": "Tempora officiis consequuntur architecto nostrum autem nam adipisci.",
//                 "rating": 4.9,
//                 "isPurchased": false,
//                 "helpful": 8630,
//                 "postedAt": "2023-05-26T06:40:08.059Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b8",
//                 "name": "Melanie Noble",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_8.jpg",
//                 "comment": "Voluptas sunt magni adipisci praesentium saepe.",
//                 "rating": 5,
//                 "isPurchased": false,
//                 "helpful": 6426,
//                 "postedAt": "2023-05-25T05:40:08.059Z"
//             }
//         ],
//         "status": "",
//         "inventoryType": "low_stock",
//         "sizes": [
//             "6",
//             "7",
//             "8",
//             "8.5",
//             "9",
//             "9.5",
//             "10",
//             "10.5",
//             "11",
//             "11.5",
//             "12",
//             "13"
//         ],
//         "available": 2,
//         "description": "\n<p><strong><small> SPECIFICATION</small></strong></p>\n<p>Leather panels. Laces. Rounded toe. Rubber sole.\n<br /><br />\n<p><strong><small> MATERIAL AND WASHING INSTRUCTIONS</small></strong></p>\n<p>Shoeupper: 54% bovine leather,46% polyurethane. Lining: 65% polyester,35% cotton. Insole: 100% polyurethane. Sole: 100% thermoplastic. Fixing sole: 100% glued.</p>\n",
//         "sold": 160,
//         "createdAt": "2023-05-08T13:40:08.059Z",
//         "category": "Shose",
//         "gender": "Men",
//         "colors": [
//             "#FF4842",
//             "#1890FF"
//         ]
//     },
//     {
//         "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1",
//         "cover": "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_1.jpg",
//         "images": [
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_1.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_2.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_3.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_4.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_5.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_6.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_7.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_8.jpg"
//         ],
//         "name": "Nike Air Force 1 NDESTRUKT",
//         "code": "38BEE270",
//         "sku": "WW75K5210YW/SV",
//         "tags": [
//             "Dangal",
//             "The Sting",
//             "2001: A Space Odyssey",
//             "Singin' in the Rain"
//         ],
//         "price": 16.19,
//         "priceSale": 16.19,
//         "totalRating": 2.5,
//         "totalReview": 3276,
//         "ratings": [
//             {
//                 "name": "1 Star",
//                 "starCount": 1879,
//                 "reviewCount": 4256
//             },
//             {
//                 "name": "2 Star",
//                 "starCount": 2945,
//                 "reviewCount": 1806
//             },
//             {
//                 "name": "3 Star",
//                 "starCount": 4798,
//                 "reviewCount": 5200
//             },
//             {
//                 "name": "4 Star",
//                 "starCount": 7529,
//                 "reviewCount": 5904
//             },
//             {
//                 "name": "5 Star",
//                 "starCount": 1092,
//                 "reviewCount": 1384
//             }
//         ],
//         "reviews": [
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1",
//                 "name": "Jayvion Simon",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_1.jpg",
//                 "comment": "Assumenda nam repudiandae rerum fugiat vel maxime.",
//                 "rating": 2.5,
//                 "isPurchased": true,
//                 "helpful": 445,
//                 "postedAt": "2023-06-01T12:40:08.056Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b2",
//                 "name": "Lucian Obrien",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_2.jpg",
//                 "comment": "Quis veniam aut saepe aliquid nulla.",
//                 "rating": 2,
//                 "isPurchased": true,
//                 "helpful": 1139,
//                 "postedAt": "2023-05-31T11:40:08.056Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b3",
//                 "name": "Deja Brady",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_3.jpg",
//                 "comment": "Reprehenderit ut voluptas sapiente ratione nostrum est.",
//                 "rating": 4.9,
//                 "isPurchased": true,
//                 "helpful": 6204,
//                 "postedAt": "2023-05-30T10:40:08.056Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b4",
//                 "name": "Harrison Stein",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_4.jpg",
//                 "comment": "Error ut sit vel molestias velit.",
//                 "rating": 2,
//                 "isPurchased": false,
//                 "helpful": 3975,
//                 "postedAt": "2023-05-29T09:40:08.056Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5",
//                 "name": "Reece Chung",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_5.jpg",
//                 "comment": "Quo quia sit nihil nemo doloremque et.",
//                 "rating": 4,
//                 "isPurchased": false,
//                 "helpful": 302,
//                 "postedAt": "2023-05-28T08:40:08.056Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b6",
//                 "name": "Lainey Davidson",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_6.jpg",
//                 "comment": "Autem doloribus harum vero laborum.",
//                 "rating": 5,
//                 "isPurchased": true,
//                 "helpful": 6055,
//                 "postedAt": "2023-05-27T07:40:08.056Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b7",
//                 "name": "Cristopher Cardenas",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_7.jpg",
//                 "comment": "Tempora officiis consequuntur architecto nostrum autem nam adipisci.",
//                 "rating": 4.9,
//                 "isPurchased": false,
//                 "helpful": 9566,
//                 "postedAt": "2023-05-26T06:40:08.056Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b8",
//                 "name": "Melanie Noble",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_8.jpg",
//                 "comment": "Voluptas sunt magni adipisci praesentium saepe.",
//                 "rating": 5,
//                 "isPurchased": false,
//                 "helpful": 9498,
//                 "postedAt": "2023-05-25T05:40:08.056Z"
//             }
//         ],
//         "status": "sale",
//         "inventoryType": "low_stock",
//         "sizes": [
//             "6",
//             "7",
//             "8",
//             "8.5",
//             "9",
//             "9.5",
//             "10",
//             "10.5",
//             "11",
//             "11.5",
//             "12",
//             "13"
//         ],
//         "available": 19,
//         "description": "\n<p><strong><small> SPECIFICATION</small></strong></p>\n<p>Leather panels. Laces. Rounded toe. Rubber sole.\n<br /><br />\n<p><strong><small> MATERIAL AND WASHING INSTRUCTIONS</small></strong></p>\n<p>Shoeupper: 54% bovine leather,46% polyurethane. Lining: 65% polyester,35% cotton. Insole: 100% polyurethane. Sole: 100% thermoplastic. Fixing sole: 100% glued.</p>\n",
//         "sold": 141,
//         "createdAt": "2023-06-01T12:40:08.056Z",
//         "category": "Apparel",
//         "gender": "Women",
//         "colors": [
//             "#00AB55",
//             "#000000"
//         ]
//     },
//     {
//         "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b21",
//         "cover": "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_21.jpg",
//         "images": [
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_1.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_2.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_3.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_4.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_5.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_6.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_7.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_8.jpg"
//         ],
//         "name": "Relaxed Adjustable Strap Slingback Sandal",
//         "code": "38BEE2720",
//         "sku": "WW75K52120YW/SV",
//         "tags": [
//             "Dangal",
//             "The Sting",
//             "2001: A Space Odyssey",
//             "Singin' in the Rain"
//         ],
//         "price": 83.08,
//         "priceSale": null,
//         "totalRating": 2.9,
//         "totalReview": 5861,
//         "ratings": [
//             {
//                 "name": "1 Star",
//                 "starCount": 6234,
//                 "reviewCount": 6148
//             },
//             {
//                 "name": "2 Star",
//                 "starCount": 4860,
//                 "reviewCount": 9382
//             },
//             {
//                 "name": "3 Star",
//                 "starCount": 4373,
//                 "reviewCount": 1710
//             },
//             {
//                 "name": "4 Star",
//                 "starCount": 6036,
//                 "reviewCount": 5013
//             },
//             {
//                 "name": "5 Star",
//                 "starCount": 8215,
//                 "reviewCount": 4997
//             }
//         ],
//         "reviews": [
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1",
//                 "name": "Jayvion Simon",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_1.jpg",
//                 "comment": "Assumenda nam repudiandae rerum fugiat vel maxime.",
//                 "rating": 2.5,
//                 "isPurchased": true,
//                 "helpful": 6616,
//                 "postedAt": "2023-06-01T12:40:08.058Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b2",
//                 "name": "Lucian Obrien",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_2.jpg",
//                 "comment": "Quis veniam aut saepe aliquid nulla.",
//                 "rating": 2,
//                 "isPurchased": true,
//                 "helpful": 8021,
//                 "postedAt": "2023-05-31T11:40:08.058Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b3",
//                 "name": "Deja Brady",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_3.jpg",
//                 "comment": "Reprehenderit ut voluptas sapiente ratione nostrum est.",
//                 "rating": 4.9,
//                 "isPurchased": true,
//                 "helpful": 6586,
//                 "postedAt": "2023-05-30T10:40:08.058Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b4",
//                 "name": "Harrison Stein",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_4.jpg",
//                 "comment": "Error ut sit vel molestias velit.",
//                 "rating": 2,
//                 "isPurchased": false,
//                 "helpful": 6790,
//                 "postedAt": "2023-05-29T09:40:08.058Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5",
//                 "name": "Reece Chung",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_5.jpg",
//                 "comment": "Quo quia sit nihil nemo doloremque et.",
//                 "rating": 4,
//                 "isPurchased": false,
//                 "helpful": 9417,
//                 "postedAt": "2023-05-28T08:40:08.058Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b6",
//                 "name": "Lainey Davidson",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_6.jpg",
//                 "comment": "Autem doloribus harum vero laborum.",
//                 "rating": 5,
//                 "isPurchased": true,
//                 "helpful": 6447,
//                 "postedAt": "2023-05-27T07:40:08.058Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b7",
//                 "name": "Cristopher Cardenas",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_7.jpg",
//                 "comment": "Tempora officiis consequuntur architecto nostrum autem nam adipisci.",
//                 "rating": 4.9,
//                 "isPurchased": false,
//                 "helpful": 4370,
//                 "postedAt": "2023-05-26T06:40:08.058Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b8",
//                 "name": "Melanie Noble",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_8.jpg",
//                 "comment": "Voluptas sunt magni adipisci praesentium saepe.",
//                 "rating": 5,
//                 "isPurchased": false,
//                 "helpful": 6996,
//                 "postedAt": "2023-05-25T05:40:08.058Z"
//             }
//         ],
//         "status": "",
//         "inventoryType": "out_of_stock",
//         "sizes": [
//             "6",
//             "7",
//             "8",
//             "8.5",
//             "9",
//             "9.5",
//             "10",
//             "10.5",
//             "11",
//             "11.5",
//             "12",
//             "13"
//         ],
//         "available": 2,
//         "description": "\n<p><strong><small> SPECIFICATION</small></strong></p>\n<p>Leather panels. Laces. Rounded toe. Rubber sole.\n<br /><br />\n<p><strong><small> MATERIAL AND WASHING INSTRUCTIONS</small></strong></p>\n<p>Shoeupper: 54% bovine leather,46% polyurethane. Lining: 65% polyester,35% cotton. Insole: 100% polyurethane. Sole: 100% thermoplastic. Fixing sole: 100% glued.</p>\n",
//         "sold": 113,
//         "createdAt": "2023-05-11T16:40:08.058Z",
//         "category": "Shose",
//         "gender": "Men",
//         "colors": [
//             "#FF4842",
//             "#1890FF"
//         ]
//     },
//     {
//         "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b14",
//         "cover": "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_14.jpg",
//         "images": [
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_1.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_2.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_3.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_4.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_5.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_6.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_7.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_8.jpg"
//         ],
//         "name": "ASMC Winter Boot Cold.Rdy",
//         "code": "38BEE2713",
//         "sku": "WW75K52113YW/SV",
//         "tags": [
//             "Dangal",
//             "The Sting",
//             "2001: A Space Odyssey",
//             "Singin' in the Rain"
//         ],
//         "price": 63.61,
//         "priceSale": null,
//         "totalRating": 4,
//         "totalReview": 4207,
//         "ratings": [
//             {
//                 "name": "1 Star",
//                 "starCount": 8800,
//                 "reviewCount": 3323
//             },
//             {
//                 "name": "2 Star",
//                 "starCount": 3798,
//                 "reviewCount": 8916
//             },
//             {
//                 "name": "3 Star",
//                 "starCount": 5979,
//                 "reviewCount": 2425
//             },
//             {
//                 "name": "4 Star",
//                 "starCount": 4381,
//                 "reviewCount": 1271
//             },
//             {
//                 "name": "5 Star",
//                 "starCount": 654,
//                 "reviewCount": 1566
//             }
//         ],
//         "reviews": [
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1",
//                 "name": "Jayvion Simon",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_1.jpg",
//                 "comment": "Assumenda nam repudiandae rerum fugiat vel maxime.",
//                 "rating": 2.5,
//                 "isPurchased": true,
//                 "helpful": 2805,
//                 "postedAt": "2023-06-01T12:40:08.057Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b2",
//                 "name": "Lucian Obrien",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_2.jpg",
//                 "comment": "Quis veniam aut saepe aliquid nulla.",
//                 "rating": 2,
//                 "isPurchased": true,
//                 "helpful": 2339,
//                 "postedAt": "2023-05-31T11:40:08.057Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b3",
//                 "name": "Deja Brady",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_3.jpg",
//                 "comment": "Reprehenderit ut voluptas sapiente ratione nostrum est.",
//                 "rating": 4.9,
//                 "isPurchased": true,
//                 "helpful": 7741,
//                 "postedAt": "2023-05-30T10:40:08.057Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b4",
//                 "name": "Harrison Stein",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_4.jpg",
//                 "comment": "Error ut sit vel molestias velit.",
//                 "rating": 2,
//                 "isPurchased": false,
//                 "helpful": 2079,
//                 "postedAt": "2023-05-29T09:40:08.057Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5",
//                 "name": "Reece Chung",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_5.jpg",
//                 "comment": "Quo quia sit nihil nemo doloremque et.",
//                 "rating": 4,
//                 "isPurchased": false,
//                 "helpful": 3341,
//                 "postedAt": "2023-05-28T08:40:08.057Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b6",
//                 "name": "Lainey Davidson",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_6.jpg",
//                 "comment": "Autem doloribus harum vero laborum.",
//                 "rating": 5,
//                 "isPurchased": true,
//                 "helpful": 2765,
//                 "postedAt": "2023-05-27T07:40:08.057Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b7",
//                 "name": "Cristopher Cardenas",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_7.jpg",
//                 "comment": "Tempora officiis consequuntur architecto nostrum autem nam adipisci.",
//                 "rating": 4.9,
//                 "isPurchased": false,
//                 "helpful": 9404,
//                 "postedAt": "2023-05-26T06:40:08.057Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b8",
//                 "name": "Melanie Noble",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_8.jpg",
//                 "comment": "Voluptas sunt magni adipisci praesentium saepe.",
//                 "rating": 5,
//                 "isPurchased": false,
//                 "helpful": 696,
//                 "postedAt": "2023-05-25T05:40:08.057Z"
//             }
//         ],
//         "status": "",
//         "inventoryType": "low_stock",
//         "sizes": [
//             "6",
//             "7",
//             "8",
//             "8.5",
//             "9",
//             "9.5",
//             "10",
//             "10.5",
//             "11",
//             "11.5",
//             "12",
//             "13"
//         ],
//         "available": 2,
//         "description": "\n<p><strong><small> SPECIFICATION</small></strong></p>\n<p>Leather panels. Laces. Rounded toe. Rubber sole.\n<br /><br />\n<p><strong><small> MATERIAL AND WASHING INSTRUCTIONS</small></strong></p>\n<p>Shoeupper: 54% bovine leather,46% polyurethane. Lining: 65% polyester,35% cotton. Insole: 100% polyurethane. Sole: 100% thermoplastic. Fixing sole: 100% glued.</p>\n",
//         "sold": 96,
//         "createdAt": "2023-05-18T23:40:08.057Z",
//         "category": "Apparel",
//         "gender": "Kids",
//         "colors": [
//             "#FF4842",
//             "#1890FF",
//             "#94D82D"
//         ]
//     },
//     {
//         "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b15",
//         "cover": "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_15.jpg",
//         "images": [
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_1.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_2.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_3.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_4.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_5.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_6.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_7.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_8.jpg"
//         ],
//         "name": "ZX 8000 Lego sneakers",
//         "code": "38BEE2714",
//         "sku": "WW75K52114YW/SV",
//         "tags": [
//             "Dangal",
//             "The Sting",
//             "2001: A Space Odyssey",
//             "Singin' in the Rain"
//         ],
//         "price": 67.55,
//         "priceSale": null,
//         "totalRating": 2,
//         "totalReview": 361,
//         "ratings": [
//             {
//                 "name": "1 Star",
//                 "starCount": 6702,
//                 "reviewCount": 7560
//             },
//             {
//                 "name": "2 Star",
//                 "starCount": 6780,
//                 "reviewCount": 1442
//             },
//             {
//                 "name": "3 Star",
//                 "starCount": 3917,
//                 "reviewCount": 7151
//             },
//             {
//                 "name": "4 Star",
//                 "starCount": 501,
//                 "reviewCount": 2424
//             },
//             {
//                 "name": "5 Star",
//                 "starCount": 5005,
//                 "reviewCount": 4574
//             }
//         ],
//         "reviews": [
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1",
//                 "name": "Jayvion Simon",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_1.jpg",
//                 "comment": "Assumenda nam repudiandae rerum fugiat vel maxime.",
//                 "rating": 2.5,
//                 "isPurchased": true,
//                 "helpful": 6700,
//                 "postedAt": "2023-06-01T12:40:08.057Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b2",
//                 "name": "Lucian Obrien",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_2.jpg",
//                 "comment": "Quis veniam aut saepe aliquid nulla.",
//                 "rating": 2,
//                 "isPurchased": true,
//                 "helpful": 9060,
//                 "postedAt": "2023-05-31T11:40:08.057Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b3",
//                 "name": "Deja Brady",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_3.jpg",
//                 "comment": "Reprehenderit ut voluptas sapiente ratione nostrum est.",
//                 "rating": 4.9,
//                 "isPurchased": true,
//                 "helpful": 6181,
//                 "postedAt": "2023-05-30T10:40:08.057Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b4",
//                 "name": "Harrison Stein",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_4.jpg",
//                 "comment": "Error ut sit vel molestias velit.",
//                 "rating": 2,
//                 "isPurchased": false,
//                 "helpful": 3315,
//                 "postedAt": "2023-05-29T09:40:08.057Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5",
//                 "name": "Reece Chung",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_5.jpg",
//                 "comment": "Quo quia sit nihil nemo doloremque et.",
//                 "rating": 4,
//                 "isPurchased": false,
//                 "helpful": 7009,
//                 "postedAt": "2023-05-28T08:40:08.057Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b6",
//                 "name": "Lainey Davidson",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_6.jpg",
//                 "comment": "Autem doloribus harum vero laborum.",
//                 "rating": 5,
//                 "isPurchased": true,
//                 "helpful": 4999,
//                 "postedAt": "2023-05-27T07:40:08.057Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b7",
//                 "name": "Cristopher Cardenas",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_7.jpg",
//                 "comment": "Tempora officiis consequuntur architecto nostrum autem nam adipisci.",
//                 "rating": 4.9,
//                 "isPurchased": false,
//                 "helpful": 9238,
//                 "postedAt": "2023-05-26T06:40:08.057Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b8",
//                 "name": "Melanie Noble",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_8.jpg",
//                 "comment": "Voluptas sunt magni adipisci praesentium saepe.",
//                 "rating": 5,
//                 "isPurchased": false,
//                 "helpful": 135,
//                 "postedAt": "2023-05-25T05:40:08.057Z"
//             }
//         ],
//         "status": "new",
//         "inventoryType": "out_of_stock",
//         "sizes": [
//             "6",
//             "7",
//             "8",
//             "8.5",
//             "9",
//             "9.5",
//             "10",
//             "10.5",
//             "11",
//             "11.5",
//             "12",
//             "13"
//         ],
//         "available": 2,
//         "description": "\n<p><strong><small> SPECIFICATION</small></strong></p>\n<p>Leather panels. Laces. Rounded toe. Rubber sole.\n<br /><br />\n<p><strong><small> MATERIAL AND WASHING INSTRUCTIONS</small></strong></p>\n<p>Shoeupper: 54% bovine leather,46% polyurethane. Lining: 65% polyester,35% cotton. Insole: 100% polyurethane. Sole: 100% thermoplastic. Fixing sole: 100% glued.</p>\n",
//         "sold": 37,
//         "createdAt": "2023-05-17T22:40:08.057Z",
//         "category": "Apparel",
//         "gender": "Women",
//         "colors": [
//             "#00AB55",
//             "#000000"
//         ]
//     },
//     {
//         "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b12",
//         "cover": "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_12.jpg",
//         "images": [
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_1.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_2.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_3.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_4.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_5.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_6.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_7.jpg",
//             "https://api-dev-minimal-v4.vercel.app/assets/images/products/product_8.jpg"
//         ],
//         "name": "Pharrell Williams Human Race NMD sneakers",
//         "code": "38BEE2711",
//         "sku": "WW75K52111YW/SV",
//         "tags": [
//             "Dangal",
//             "The Sting",
//             "2001: A Space Odyssey",
//             "Singin' in the Rain"
//         ],
//         "price": 35.54,
//         "priceSale": null,
//         "totalRating": 4.9,
//         "totalReview": 7036,
//         "ratings": [
//             {
//                 "name": "1 Star",
//                 "starCount": 3946,
//                 "reviewCount": 2388
//             },
//             {
//                 "name": "2 Star",
//                 "starCount": 3536,
//                 "reviewCount": 1373
//             },
//             {
//                 "name": "3 Star",
//                 "starCount": 4354,
//                 "reviewCount": 6054
//             },
//             {
//                 "name": "4 Star",
//                 "starCount": 6151,
//                 "reviewCount": 6188
//             },
//             {
//                 "name": "5 Star",
//                 "starCount": 7093,
//                 "reviewCount": 5024
//             }
//         ],
//         "reviews": [
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1",
//                 "name": "Jayvion Simon",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_1.jpg",
//                 "comment": "Assumenda nam repudiandae rerum fugiat vel maxime.",
//                 "rating": 2.5,
//                 "isPurchased": true,
//                 "helpful": 3512,
//                 "postedAt": "2023-06-01T12:40:08.057Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b2",
//                 "name": "Lucian Obrien",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_2.jpg",
//                 "comment": "Quis veniam aut saepe aliquid nulla.",
//                 "rating": 2,
//                 "isPurchased": true,
//                 "helpful": 5649,
//                 "postedAt": "2023-05-31T11:40:08.057Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b3",
//                 "name": "Deja Brady",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_3.jpg",
//                 "comment": "Reprehenderit ut voluptas sapiente ratione nostrum est.",
//                 "rating": 4.9,
//                 "isPurchased": true,
//                 "helpful": 3432,
//                 "postedAt": "2023-05-30T10:40:08.057Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b4",
//                 "name": "Harrison Stein",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_4.jpg",
//                 "comment": "Error ut sit vel molestias velit.",
//                 "rating": 2,
//                 "isPurchased": false,
//                 "helpful": 709,
//                 "postedAt": "2023-05-29T09:40:08.057Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5",
//                 "name": "Reece Chung",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_5.jpg",
//                 "comment": "Quo quia sit nihil nemo doloremque et.",
//                 "rating": 4,
//                 "isPurchased": false,
//                 "helpful": 1415,
//                 "postedAt": "2023-05-28T08:40:08.057Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b6",
//                 "name": "Lainey Davidson",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_6.jpg",
//                 "comment": "Autem doloribus harum vero laborum.",
//                 "rating": 5,
//                 "isPurchased": true,
//                 "helpful": 2744,
//                 "postedAt": "2023-05-27T07:40:08.057Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b7",
//                 "name": "Cristopher Cardenas",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_7.jpg",
//                 "comment": "Tempora officiis consequuntur architecto nostrum autem nam adipisci.",
//                 "rating": 4.9,
//                 "isPurchased": false,
//                 "helpful": 9351,
//                 "postedAt": "2023-05-26T06:40:08.057Z"
//             },
//             {
//                 "id": "e99f09a7-dd88-49d5-b1c8-1daf80c2d7b8",
//                 "name": "Melanie Noble",
//                 "avatarUrl": "https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_8.jpg",
//                 "comment": "Voluptas sunt magni adipisci praesentium saepe.",
//                 "rating": 5,
//                 "isPurchased": false,
//                 "helpful": 2107,
//                 "postedAt": "2023-05-25T05:40:08.057Z"
//             }
//         ],
//         "status": "",
//         "inventoryType": "out_of_stock",
//         "sizes": [
//             "6",
//             "7",
//             "8",
//             "8.5",
//             "9",
//             "9.5",
//             "10",
//             "10.5",
//             "11",
//             "11.5",
//             "12",
//             "13"
//         ],
//         "available": 2,
//         "description": "\n<p><strong><small> SPECIFICATION</small></strong></p>\n<p>Leather panels. Laces. Rounded toe. Rubber sole.\n<br /><br />\n<p><strong><small> MATERIAL AND WASHING INSTRUCTIONS</small></strong></p>\n<p>Shoeupper: 54% bovine leather,46% polyurethane. Lining: 65% polyester,35% cotton. Insole: 100% polyurethane. Sole: 100% thermoplastic. Fixing sole: 100% glued.</p>\n",
//         "sold": 27,
//         "createdAt": "2023-05-21T01:40:08.057Z",
//         "category": "Accessories",
//         "gender": "Men",
//         "colors": [
//             "#FFFFFF",
//             "#FFC0CB",
//             "#FF4842",
//             "#1890FF"
//         ]
//     }
//   ]
  