
// eslint-disable-next-line import/no-unresolved
import { CardPanel } from "src/controls/cardpanel";
import { DropDownGREYERP } from "src/controls/dropdown";
import { TextBoxGREYERP } from "src/controls/textbox";

import UppyFilePicker from 'src/controls/uppyFilePicker';

export default function Incentive()
{ 
    return(
        <>
   
      <CardPanel heading="Incentive Details" cardItem={<>
      
<Picker type='date' name="Date" required={true} focus={true}></Picker>
                        
<DropDownGREYERP items={`employees/name`} name="Employee" required={true} />
<DropDownGREYERP items={`salarycomponents/name`} name="SalaryComponent" required={true} />

<TextBoxGREYERP placeholder="20" name="Amount" required={true} type="number" step="any"  />
<TextBoxGREYERP placeholder="Internal Notes" name="Comments" multiline={true} MultiLine={true} />                 

</>} />
<UppyFilePicker/> 
   </>


  )

 
}
