/* eslint-disable import/no-unresolved */
import { Navigate, useRoutes } from 'react-router-dom';
import CompactLayout from '../layouts/compact';
import DashboardLayout from '../layouts/dashboard';
import UserProfilePage from 'src/pages/profile/profile';
import Dashboard from 'src/pages/mainDashboard';
import LinkPage from 'src/pages/linkPage';
import LoginPage from 'src/pages/LoginPage';
import Page404 from 'src/pages/Page404';
import MarketPlace from 'src/pages/profile/marketplace';
import { routesERP } from 'src/data/data';
import Users from 'src/pages/utility/users';
import Test from 'src/pages/test/test';

// eslint-disable-next-line import/no-unresolved

// ----------------------------------------------------------------------

export default function Router({ rights }) {
  //const {isAuthenticated = false } = useSelector((store)=>store.loginReducer);
  const isAuthenticated = localStorage.getItem('isAuthenticated');

  const modulesWithRights = localStorage.getItem('navConfigERP')
    ? JSON.parse(localStorage.getItem('navConfigERP'))
    : [];

  let hasAccess = false;
  let module = window.location.pathname.split('/')[1]
    ? window.location.pathname.split('/')[1]?.toLowerCase()
    : '';
  let form = window.location.pathname.split('/')[2]
    ? window.location.pathname.split('/')[2]?.toLowerCase()
    : '';

  let action = window.location.pathname.split('/')[3]
    ? !isNaN(window.location.pathname.split('/')[3])
      ? '/edit'
      : `/${window.location.pathname.split('/')[3]?.toLowerCase()}`
    : '';

  if (window.location.pathname.split('/')[1] != 'login' && !isAuthenticated) {
    localStorage.setItem('requestedPath', `${module}/${form}`);
  }

  let modulesWithRightsExist = modulesWithRights?.filter(
    (moduleRight) => moduleRight.subheader?.toUpperCase() == module.toUpperCase()
  );

  if (modulesWithRightsExist.length > 0) {
    modulesWithRightsExist[0].items[0].children.forEach((routeObj) => {
      //console.log(routeObj.path.split('/')[2],'routeObj.path.')
      if (routeObj.path.split('/')[2].toUpperCase() == form.toUpperCase()) {
        hasAccess = true;
      }
    });
  }

  const PrivateRoute = (Component) => (hasAccess ? Component : <Navigate to={'*'} />);

  const UserAuthenticated = () =>
    isAuthenticated ? <DashboardLayout /> : <Navigate to={'/login'} />;
  if (rights.split(',').includes(`/${module}/${form}${action}`)) {
    hasAccess = true;
  }

  routesERP.forEach((routeObj) => {
    routeObj['element'] = UserAuthenticated();
    routeObj.children.forEach((child) => {
      child['element'] = PrivateRoute(child.component);
    });
  });

  return useRoutes([
    {
      path: '/',
      element: isAuthenticated ? <DashboardLayout /> : <Navigate to="/login" />,
      children: [{ path: '', element: <Dashboard /> }],
    },

    {
      path: '/login',
      element: isAuthenticated ? <Navigate to={'/'} /> : <LoginPage />,
    },
    ...routesERP,

    {
      path: '/utility',
      element: isAuthenticated ? <DashboardLayout /> : <Navigate to={'/login'} />,
      children: [
        { path: 'Settings', element: <UserProfilePage /> },
        { path: 'Marketplace', element: <MarketPlace /> },
      ],
    },
    {
      path: '/test/test',
      element: isAuthenticated ? <Test /> : <Navigate to={'/login'} />,
      // children: [
      //   { path: 'Settings', element: <UserProfilePage /> },
      //   { path: 'Marketplace', element: <MarketPlace /> },
      // ],
    },
    {
      element: <CompactLayout />,
      children: [
        { path: '404', element: <Page404 /> },
        { path: 'link/:id', element: <LinkPage /> },
      ],
    },
    { path: 'link/:id', element: <LinkPage /> },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
