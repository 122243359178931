import { default as DataGrid } from '../Grid';
import { useState } from 'react';
import { Grid, Stack } from '@mui/material';
import { Months, Years } from 'src/data/data';
import { DropDownENUM } from 'src/controls/dropdownenum';
import { useEffect } from 'react';

export default function MonthlyAttendanceReport() {
  const [apiUrl, setapiUrl] = useState();
  const [month, setMonth] = useState('09');
  const [year, setYear] = useState('2023');

  useEffect(() => {
    setapiUrl(`getrecords/monthlyattendancereport/${localStorage.getItem('username')}/${localStorage.getItem('client')}/${year}/${month}`);
  }, [year,month])

  function monthChanged(e,selectedValue)
  {
 setMonth(selectedValue.ID)
  }

  function yearChanged(e,selectedValue)
  {
 setYear(selectedValue.ID)
  }
  return (
    <>
        <DataGrid
          name="AccountStatement"
          apiExternalUrl={apiUrl}
          openPrintTable={false}
          TopToolbarCustomActions={
            <Grid container columnSpacing={2} sx={{ m: 0, p: 0,  width: '40%',displayPrint:'none' }}>
              <DropDownENUM items={Years} name="Year" required={true} selectedItemIndex={1} span={4} postBackSelectedValue={year} onChange={yearChanged} />
              <DropDownENUM items={Months} selectedItemIndex={8} name="Month" required={true} focus={true} span={5} postBackSelectedValue={month} onChange={monthChanged} />
            </Grid>
          }
        />
    </>
  );
}
