import { DropDownGREYERP } from 'src/controls/dropdown';
import { TextBoxGREYERP } from 'src/controls/textbox';
import PopUpPanel from 'src/controls/popuppanel';
import UploadGREY from 'src/controls/uploadgrey';
import { SwithGREYERP } from 'src/controls/swithgrey';
import Picker from 'src/controls/picker';
export default function Milestone() {
  const urlList = window.location.pathname.split('/');
  const CategoryName = (urlList[2].charAt(0).toUpperCase() + urlList[2].slice(1)).replaceAll( '-', '' );
  return (
    <>
      <PopUpPanel
        heading={'Add'}
        child={
          <>
            <UploadGREY span={2} />
            <TextBoxGREYERP name="Name" placeholder="Category Name" required={true} focus={true} />
            <TextBoxGREYERP name="NameArabic" placeholder="اسم التصنيف" dir="rtl" />
            <TextBoxGREYERP name="Description" span={2} multiline={true} placeholder="Description" MultiLine={true} />
            <Picker type='dateTime' label="Start" name="StartDateTime" />
            <Picker type='dateTime' label="End" name="EndDateTime" />

            <TextBoxGREYERP rows={3} name="Comments" multiline={true} placeholder="Internal Notes" span={2} />
          </>
        }
      />
    </>
  );
}
