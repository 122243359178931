import Maintenance from 'src/pages/properties/maintenance';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { Navigate, useRoutes } from 'react-router-dom';
import PropertiesDashboard from 'src/pages/properties/dashboard';
import Unit from 'src/pages/properties/unit';
import Tenant from 'src/pages/properties/tenant';
import Lead from 'src/pages/crm/lead';
import Individual from 'src/pages/crm/individual';
import Corporate from 'src/pages/crm/corporate';
import CalendarPage from 'src/pages/crm/calendar/CalendarPage';
import Grid from 'src/pages/Grid';
import KanbanPage from 'src/pages/projects/KanbanPage';
import Client from 'src/pages/sales/client';
import Call from 'src/pages/crm/call';
import Bankaccount from 'src/pages/accounts/bankaccount';
import MeetingForm from 'src/pages/crm/meetingform';
import Transfer from 'src/pages/accounts/transfer';
import Order from 'src/pages/order';
import Warehouse from 'src/pages/masters/warehouse';
import Category from 'src/pages/masters/category';
import MastersRow from 'src/pages/masters/mastersrow';
import Incentive from 'src/pages/payroll/incentive';
import Retention from 'src/pages/payroll/retention';
import Gratuity from 'src/pages/payroll/gratuity';
import Loan from 'src/pages/payroll/loan';
import SalaryStructure from 'src/pages/payroll/salarystructure';
import SalarySlip from 'src/pages/payroll/salaryslip';
import Return from 'src/pages/return';
import Invoice from 'src/pages/invoice';
import PurchaseRequisition from 'src/pages/purchase/purchaserequisition';
import RequestforQuotation from 'src/pages/purchase/requestforquotation';
import TrialBalance from 'src/pages/accounts/trialbalance';
import StockTransfer from 'src/pages/inventory/stocktransfer';
import GoodsReceivedNotes from 'src/pages/inventory/receives';
import DeliveryNotes from 'src/pages/inventory/deliveries';
import InventoryAdjustments from 'src/pages/inventory/adjustments';
import ProfitandLoss from 'src/pages/accounts/profitandloss';
import BalanceSheet from 'src/pages/accounts/balancesheet';
import InvoicePrint from 'src/controls/printpoppanel';
import JournalVoucher from 'src/pages/accounts/journalvoucher';
import Expense from 'src/pages/accounts/expense';
import Receipt from 'src/pages/accounts/receipt';
import ChartOfAccounts from 'src/pages/accounts/chartofaccount';
import ChartGREYERP from 'src/controls/chart';
import { TableGREYERP } from 'src/controls/table';
import Project from 'src/pages/projects/project';
import Fine from 'src/pages/fleet/fine';
import UserProfilePage from 'src/pages/profile/profile';
import AccountStatement from 'src/pages/accounts/accountstatement';
import Item from 'src/pages/inventory/item';
import JobApplication from 'src/pages/HR/jobapplication';
import JobPost from 'src/pages/HR/jobpost';
import Banner from 'src/pages/CMS/banner';
import TeamMember from 'src/pages/CMS/teammember';
import Career from 'src/pages/CMS/career';
import Blog from 'src/pages/CMS/blog';
import OurClient from 'src/pages/CMS/ourclient';
import Faqs from 'src/pages/CMS/faqs';
import Coupons from 'src/pages/CMS/coupon';
import DocumentLayout from 'src/pages/masters/documentlayout';
import Vendor from 'src/pages/purchase/vendor';
import Product from 'src/pages/CMS/product';
import Service from 'src/pages/CMS/service';
import Video from 'src/pages/CMS/video';
import Picture from 'src/pages/CMS/picture';
import Contact from 'src/pages/crm/contact';
import ContactGoogle from 'src/pages/crm/contactgoogle';
import Employee from 'src/pages/HR/employee';
import LeaveList from 'src/pages/HR/leavelist';
import LeaveType from 'src/pages/HR/leavetype';
import Grievance from 'src/pages/HR/grievance';
import Resignation from 'src/pages/HR/resignation';
import LeaveApplication from 'src/pages/HR/leaveapplication';
import LeaveBlockList from 'src/pages/HR/leaveblocklist';
import WorkingHours from 'src/pages/HR/workinghours';
import Attendance from 'src/pages/HR/attendance';
import Mails from 'src/pages/crm/mails';
import Vehicle from 'src/pages/fleet/vehicle';
import Damage from 'src/pages/fleet/damage';
import Booking from 'src/pages/fleet/booking';
import LinkPage from 'src/pages/linkPage';
import OrganizationalChartHR from 'src/pages/HR/organizationchart';
import HolidayLists from 'src/pages/HR/holidaylists';
import Departments from 'src/pages/masters/departments';
import Building from 'src/pages/properties/building';
import VendorQuotation from 'src/pages/purchase/vendorquotation';
import WarehouseSections from 'src/pages/masters/warehousesection';
import MarketPlace from 'src/pages/profile/marketplace';
import PointOfSale from 'src/pages/sales/pointofsale';
import PriceList from 'src/pages/pricelist';
import Dashboard from 'src/pages/dashboard';
import { Typography } from '@mui/material';
import CustomReportDesigner from 'src/controls/customreportdesigner';
import Users from 'src/pages/utility/users';
import ApprovalType from 'src/pages/masters/approvaltype';
import SalaryComponents from 'src/pages/masters/salarycomponents';
import Addon from 'src/pages/fleet/addon';
import CostCenters from 'src/pages/accounts/costcenters';
import BillOfMaterial from 'src/pages/manufacturing/billofmaterial';
import WorkCenter from 'src/pages/masters/workcenter';
import Operation from 'src/pages/masters/operation';
import TimeSheet from 'src/pages/projects/timesheet';
import Milestone from 'src/pages/projects/milestone';
import MonthlyAttendanceReport from 'src/pages/HR/monthlyattendancereport';
import AssetType from 'src/pages/assets/assettype';
import Asset from 'src/pages/assets/asset';
import AssetMovement from 'src/pages/assets/assetmovement';
import AssetMaintenance from 'src/pages/assets/assetmaintenance';
import AssetMaintenanceLog from 'src/pages/assets/assetmaintenancelog';
import Route from 'src/pages/manufacturing/route';
import Downtime from 'src/pages/manufacturing/downtime';
import ProductionPlan from 'src/pages/manufacturing/productionplan';
import WorkOrder from 'src/pages/manufacturing/workorder';
import JobCard from 'src/pages/manufacturing/jobcard';
import AssetRepair from 'src/pages/assets/assetrepair';
import AssetCapitalization from 'src/pages/assets/assetcapitalization';
import { TreeItem, treeItemClasses } from '@mui/x-tree-view';

export const LeaveTypes = [
  { ID: 'Paid', Name: 'Paid' },
  { ID: 'Partial', Name: 'Partial Paid' },
  { ID: 'LOP', Name: 'LOP' },
];

export const ApprovalStages = [
  { ID: 'Draft', Name: 'Draft' },
  { ID: 'Submitted', Name: 'Submitted' },
  { ID: 'Approved', Name: 'Approved' },
  { ID: 'Rejected', Name: 'Rejected' },
];

export const SplitMethods = [
  { ID: 'Equal', Name: 'Equal' },
  { ID: 'By_Quantity', Name: 'Partial Paid' },
  { ID: 'By_Price', Name: 'By Price' },
  { ID: 'By_Weight', Name: 'By Weight' },
  { ID: 'By_Volume', Name: 'By Volume' },
];

export const SalaryTypes = [
  { ID: 'Earning', Name: 'Earning' },
  { ID: 'Deduction', Name: 'Deduction' },
];

export const Purpose = [
  { ID: 'Commercial', Name: 'Commercial' },
  { ID: 'Residential', Name: 'Residential' },
];

export const CompanyTypes = [
  { ID: 'Company', Name: 'Company' },
  { ID: 'Individual', Name: 'Individual' },
];

export const Access = [
  { ID: 'Demo', Name: 'Only Me' },
  { ID: 'Everyone', Name: 'Everyone' },
];

export const PaymentMode = [
  { ID: 'Cash', Name: 'Cash' },
  { ID: 'Cheque', Name: 'Cheque' },
  { ID: 'OnlineTransfer', Name: 'OnlineTransfer' },
];

export const Mode = [
  { ID: 'Rent', Name: 'Rent' },
  { ID: 'Advance', Name: 'Advance' },
  { ID: 'Discount', Name: 'Discount' },
];

export const DepreciationMethod = [
  { ID: 'Straight_Line', Name: 'Straight Line' },
  { ID: 'Declining', Name: 'Declining' },
  { ID: 'Manual', Name: 'Manual' },
];

export const PaymentTerms = [
  { ID: '0', Name: 'Ready Cash' },
  { ID: '15', Name: '15 Days' },
  { ID: '30', Name: '30 Days' },
  { ID: '45', Name: '45 Days' },
  { ID: '60', Name: '60 Days' },
  { ID: '75', Name: '75 Days' },
  { ID: '90', Name: '90 Days' },
  { ID: '120', Name: '120 Days' },
];

export const Titles = [
  { ID: 'Mr', Name: 'Mr' },
  { ID: 'Mrs', Name: 'Mrs' },
  { ID: 'Ms', Name: 'Ms' },
  { ID: 'Dr', Name: 'Dr' },
  { ID: 'Prof', Name: 'Prof' },
];

export const Days = [
  { ID: '0', Name: 'Sunday' },
  { ID: '1', Name: 'Monday' },
  { ID: '2', Name: 'Tuesday' },
  { ID: '3', Name: 'Wednesday' },
  { ID: '4', Name: 'Thursday' },
  { ID: '5', Name: 'Friday' },
  { ID: '6', Name: 'Saturday' },
];

export const Banks = [
  { ID: 'Ahli_Bank', Name: 'Ahli Bank' },
  { ID: 'Alizz_Islamic_Bank', Name: 'Alizz Islamic Bank' },
  { ID: 'Bank_Dhofar', Name: 'Bank Dhofar' },
  { ID: 'Bank_Melli_Iran', Name: 'Bank Melli Iran' },
  { ID: 'Bank_Nizwa', Name: 'Bank Nizwa' },
  { ID: 'Bank_Of_Baroda', Name: 'Bank Of Baroda' },
  { ID: 'Bank_Sederat_Iran', Name: 'Bank Sederat Iran' },
  { ID: 'Bank_Sohar', Name: 'Bank Sohar' },
  { ID: 'Bank_Sohar_Islamic', Name: 'Bank Sohar Islamic' },
  { ID: 'Bank_Muscat', Name: 'Bank Muscat' },
  { ID: 'Central_Bank_Of_Oman', Name: 'Central Bank Of Oman' },
  { ID: 'Gulf_Investment_Services', Name: 'Gulf Investment Services' },
  { ID: 'Habib_Bank_Oman', Name: 'Habib Bank Oman' },
  { ID: 'HSBC_Bank', Name: 'HSBC Bank' },
  { ID: 'Ministry_Of_Finance', Name: 'Ministry Of Finance' },
  { ID: 'Muzn_Islamic_Bank', Name: 'Muzn Islamic Bank' },
  { ID: 'National_Bank_Of_Oman', Name: 'National Bank Of Oman' },
  { ID: 'Oman_Arab_Bank', Name: 'Oman Arab Bank' },
  { ID: 'Oman_Banking_Corporation', Name: 'Oman Banking Corporationt' },
  { ID: 'Oman_International_Bank', Name: 'Oman International Bank' },
  { ID: 'Oman_Investment_Services', Name: 'Oman Investment Services' },
  { ID: 'Qatar_National_Bank', Name: 'Qatar National Bank' },
  { ID: 'Standard_Chartered_Bank', Name: 'Standard Chartered Bank' },
  { ID: 'State_Bank_Of_India', Name: 'State Bank Of India' },
  { ID: 'Vision_Investment_Services', Name: 'Vision Investment Services' },
  { ID: 'Vision_Securities_Company', Name: 'Vision Securities Company' },
];

export const Areas = [
  { ID: 'Adam', Name: 'Adam' },
  { ID: 'As_Sib', Name: 'As Sib' },
  { ID: 'Al_Ashkharah', Name: 'Al Ashkharah' },
  { ID: 'Al_Buraimi', Name: 'Al Buraimi' },
  { ID: 'Al_Hamra', Name: 'Al Hamra' },
  { ID: 'Al_Jazer', Name: 'Al Jazer' },
  { ID: 'Al_Madina', Name: 'Al Madina' },
  { ID: 'Al_Suwaiq', Name: 'Al Suwaiq' },
  { ID: 'Azaiba', Name: 'Azaiba' },
  { ID: 'Bahla', Name: 'Bahla' },
  { ID: 'Barka', Name: 'Barka' },
  { ID: 'Bidbid', Name: 'Bidbid' },
  { ID: 'Bidiya', Name: 'Bidiya' },
  { ID: 'Duqm', Name: 'Duqm' },
  { ID: 'Ghobra', Name: 'Ghobra' },
  { ID: 'Haima', Name: 'Haima' },
  { ID: 'Ibra', Name: 'Ibra' },
  { ID: 'Ibri', Name: 'Ibri' },
  { ID: 'Izki', Name: 'Izki' },
  { ID: 'Jabrin', Name: 'Jabrin' },
  { ID: 'Jalan', Name: 'Jalans' },
  { ID: 'Khasab', Name: 'Khasab' },
  { ID: 'Mabellah', Name: 'Mabellah' },
  { ID: 'Mahooth', Name: 'Mahooth' },
  { ID: 'Manah', Name: 'Manah' },
  { ID: 'Masirah', Name: 'Masirah' },
  { ID: 'Matrah', Name: 'Matrah' },
  { ID: 'Mudhaybi', Name: 'Mudhaybi' },
  { ID: 'Mudhaireb', Name: 'Mudhaireb' },
  { ID: 'Muscat', Name: 'Muscat' },
  { ID: 'Musannah', Name: 'Musannah' },
  { ID: 'Nizwa', Name: 'Nizwa' },
  { ID: 'Quriyat', Name: 'Quriyat' },
  { ID: 'Raysut', Name: 'Raysut' },
  { ID: 'Rustaq', Name: 'Rustaq' },
  { ID: 'Ruwi', Name: 'Ruwi' },
  { ID: 'Rusayl', Name: 'Rusayl' },
  { ID: 'Saham', Name: 'Saham' },
  { ID: 'Shinas', Name: 'Shinas' },
  { ID: 'Saiq', Name: 'Saiq' },
  { ID: 'Salalah', Name: 'Salalah' },
  { ID: 'Samail', Name: 'Samail' },
  { ID: 'Sohar', Name: 'Sohar' },
  { ID: 'Sur', Name: 'Sur' },
  { ID: 'Tanam', Name: 'Tanam' },
  { ID: 'Thumrait', Name: 'Thumrait' },
  { ID: 'Wadi_Kabir', Name: 'Wadi Kabir' },
];

export const UnitTypes = [
  { ID: 'Room', Name: 'Room' },
  { ID: 'Studio_Flat', Name: 'Studio Flat' },
  { ID: 'Single_Bedroom_Flat', Name: 'Single Bedroom Flat' },
  { ID: 'Double_Bedroom_Flat', Name: 'Double Bedroom Flat' },
  { ID: 'Triple_Bedroom_Flat', Name: 'Triple Bedroom Flat' },
  { ID: 'Four_Bedroom_Flat', Name: 'Four Bedroom Flat' },
  { ID: 'Five_Bedroom_Flat', Name: 'Five Bedroom Flat' },
  { ID: 'Villa', Name: 'Villa' },
  { ID: 'Duplex', Name: 'Duplex' },
  { ID: 'Triplex', Name: 'Triplex' },
  { ID: 'Basement', Name: 'Basement' },
  { ID: 'Shop', Name: 'Shop' },
  { ID: 'Office', Name: 'Office' },
  { ID: 'Store', Name: 'Store' },
  { ID: 'Plot', Name: 'Plot' },
  { ID: 'Warehouse', Name: 'Warehouse' },
  { ID: 'Multi_Unit', Name: 'Multi Unit' },
];

export const Duration = [
  { ID: '15', Name: '15 mins' },
  { ID: '30', Name: '30 mins' },
  { ID: '45', Name: '45 mins' },
  { ID: '60', Name: '1 hour' },
  { ID: '75', Name: '1 hour 15 mins' },
  { ID: '90', Name: '1 hour 30 mins' },
  { ID: '105', Name: '1 hour 45 mins' },
  { ID: '120', Name: '2 hours' },
  { ID: '135', Name: '2 hours 15 mins' },
  { ID: '150', Name: '2 hours 30 mins' },
  { ID: '165', Name: '2 hours 45 mins' },
  { ID: '180', Name: '3 hours' },
  { ID: '195', Name: '3 hours 15 mins' },
  { ID: '210', Name: '3 hours 30 mins' },
  { ID: '225', Name: '3 hours 45 mins' },
  { ID: '240', Name: '4 hours' },
  { ID: '255', Name: '4 hours 15 mins' },
  { ID: '270', Name: '4 hours 30 mins' },
  { ID: '285', Name: '4 hours 45 mins' },
  { ID: '300', Name: '5 hours' },
  { ID: '315', Name: '5 hours 15 mins' },
  { ID: '330', Name: '5 hours 30 mins' },
  { ID: '345', Name: '5 hours 45 mins' },
  { ID: '360', Name: '6 hours' },
];

export const Charts = [
  {
    Type: 'grid',
    Component: function Component(chart) {
      return (
        chart &&
        chart.Data.length > 0 && (
          <TableGREYERP externalData={chart.Data} externalHeader={Object.keys(chart.Data?.pop())} />
        )
      );
    },
  },
  {
    Type: 'column',
    Component: function Component(chart) {
      return (
        chart &&
        chart.Data.length > 0 && <ChartGREYERP data={chart.Data} type={'bar'} type2={true} />
      );
    },
  },
  {
    Type: 'line',
    Component: function Component(chart) {
      return (
        chart &&
        chart.Data.length > 0 && <ChartGREYERP data={chart.Data} type={'line'} type2={true} />
      );
    },
  },
  {
    Type: 'pie',
    Component: function Component(chart) {
      return chart && chart.Data.length > 0 && <ChartGREYERP data={chart.Data} type={'pie'} />;
    },
  },
  {
    Type: 'donut',
    Component: function Component(chart) {
      return chart && chart.Data.length > 0 && <ChartGREYERP data={chart.Data} type={'donut'} />;
    },
  },
  {
    Type: 'bar',
    Component: function Component(chart) {
      return chart && chart.Data.length > 0 && <ChartGREYERP data={chart.Data} type={'bar'} />;
    },
  },
];

export const JobTypes = [
  { ID: 'Full_Time', Name: 'Full Time' },
  { ID: 'Part_Time', Name: 'Part Time' },
  { ID: 'Contract', Name: 'Contract' },
  { ID: 'Temporary', Name: 'Temporary' },
  { ID: 'Remote', Name: 'Remote' },
  { ID: 'Internship', Name: 'Internship' },
  { ID: 'Volenteer', Name: 'Volenteer' },
];

export const CouponTypes = [
  { ID: 'Percentage', Name: 'Percentage' },
  { ID: 'Amount', Name: 'Amount' },
];

export const EntryType = [
  { ID: 'Debit', Name: 'Debit' },
  { ID: 'Credit', Name: 'Credit' },
];

export const PayrollFrequency = [
  { ID: 'Hourly', Name: 'Hourly' },
  { ID: 'Daily', Name: 'Daily' },
  { ID: 'Weekly', Name: 'Weekly' },
  { ID: 'Monthly', Name: 'Monthly' },
  { ID: 'Yearly', Name: 'Yearly' },
];

export const ChequeStatus = [
  { ID: 'Pending', Name: 'Pending' },
  { ID: 'Cleared', Name: 'Cleared' },
  { ID: 'Bounced', Name: 'Bounced' },
];

export const GrievanceStatus = [
  { ID: 'Open', Name: 'Open' },
  { ID: 'Investigated', Name: 'Investigated' },
  { ID: 'Resolved', Name: 'Resolved' },
  { ID: 'Invalid', Name: 'Invalid' },
];

export const Status = [
  { ID: 'Open', Name: 'Open' },
  { ID: 'Approved', Name: 'Approved' },
  { ID: 'Rejected', Name: 'Rejected' },
  { ID: 'Cancelled', Name: 'Cancelled' },
];

export const Priorities = [
  { ID: 'Low', Name: 'Low' },
  { ID: 'Medium', Name: 'Medium' },
  { ID: 'High', Name: 'High' },
  { ID: 'Urgent', Name: 'Urgent' },
];

export const TaxType = [
  { ID: 'Percentage', Name: 'Percentage' },
  { ID: 'Amount', Name: 'Amount' },
];

export const UserRole = [
  { ID: 'User', Name: 'User' },
  { ID: 'Admin', Name: 'Admin' },
];

export const ProjectStatus = [
  { ID: 'Open', Name: 'Open' },
  { ID: 'Approved', Name: 'Approved' },
  { ID: 'Rejected', Name: 'Rejected' },
];

export const MaintenanceStatus = [
  { ID: 'Registered', Name: 'Registered' },
  { ID: 'Progress', Name: 'Progress' },
  { ID: 'Resolved', Name: 'Resolved' },
  { ID: 'Cancelled', Name: 'Cancelled' },
  { ID: 'Dispute', Name: 'Dispute' },
];

export const PaymentType = [
  { ID: 'Expense', Name: 'Company Expense' },
  { ID: 'Invoice', Name: 'Tenant Invoice' },
];

export const ItemTypes = [
  { ID: 'Product', Name: 'Product' },
  { ID: 'Service', Name: 'Service' },
  { ID: 'Inventory', Name: 'Inventory Product' },
];

export const ValuationMethod = [
  { ID: 'FIFO', Name: 'FIFO' },
  { ID: 'LIFO', Name: 'LIFO' },
  { ID: 'WAC', Name: 'WAC' },
];

export const FontName = [
  { Name: 'Arial, Sans-serif', ID: 'Arial' },
  { Name: 'Courier New, Monospace', ID: 'Courier' },
  { Name: 'Garamond, serif', ID: 'Garamond' },
  { Name: 'Georgia, serif', ID: 'Georgia' },
  { Name: 'Helvetica, Sans-serif', ID: 'Helvetica' },
  { Name: 'Lucida Console, Monospace', ID: 'Lucida' },
  { Name: 'Monaco, Monospace', ID: 'Monaco' },
  { Name: 'Times New Roman, serif', ID: 'Times' },
  { Name: 'Verdana, Sans-serif', ID: 'Verdana' },
  { Name: 'Calibri, Sans-serif', ID: 'Calibri' },
];

export const Gender = [
  { ID: 'Male', Name: 'Male' },
  { ID: 'Female', Name: 'Female' },
];

export const BloodGroup = [
  { ID: 'A+ve', Name: 'A+ve' },
  { ID: 'A-ve', Name: 'A-ve' },
  { ID: 'B+ve', Name: 'B+ve' },
  { ID: 'B-ve', Name: 'B-ve' },
  { ID: 'O+ve', Name: 'O+ve' },
  { ID: 'O-ve', Name: 'O-ve' },
  { ID: 'AB+ve', Name: 'AB+ve' },
  { ID: 'AB-ve', Name: 'AB-ve' },
];

export const Religion = [
  { ID: 'Muslim', Name: 'Muslim' },
  { ID: 'Islam', Name: 'Islam' },
  { ID: 'Christianity', Name: 'Christianity' },
  { ID: 'Hinduism', Name: 'Hinduism' },
  { ID: 'Buddhism', Name: 'Buddhism' },
  { ID: 'Judaism', Name: 'Judaism' },
  { ID: 'Jewish', Name: 'Jewish' },
  { ID: 'Others', Name: 'Others' },
];

export const Months = [
  { ID: '01', Name: 'January' },
  { ID: '02', Name: 'February' },
  { ID: '03', Name: 'March' },
  { ID: '04', Name: 'April' },
  { ID: '05', Name: 'May' },
  { ID: '06', Name: 'June' },
  { ID: '07', Name: 'July' },
  { ID: '08', Name: 'August' },
  { ID: '09', Name: 'September' },
  { ID: '10', Name: 'October' },
  { ID: '11', Name: 'November' },
  { ID: '12', Name: 'December' },
];

export const Years = [
  { ID: '2022', Name: '2022' },
  { ID: '2023', Name: '2023' },
];

export const MaritalStatus = [
  { ID: 'Single', Name: 'Single' },
  { ID: 'Married', Name: 'Married' },
  { ID: 'Widowed', Name: 'Widowed' },
  { ID: 'Divorced', Name: 'Divorced' },
  // { ID: 'Buddhism', Name: 'Buddhism' },
  // { ID: 'Judaism', Name: 'Judaism' },
  // { ID: 'Jewish', Name: 'Jewish' },
  { ID: 'Others', Name: 'Others' },
];

export const TenantStatus = [
  { ID: 'Active', Name: 'Active' },
  { ID: 'Renew', Name: 'Renew' },
  { ID: 'Cancel', Name: 'Cancel' },
];
export const Nationality = [
  { ID: 'Afghan', Name: 'Afghan' },
  { ID: 'Albanian', Name: 'Albanian' },
  { ID: 'Algerian', Name: 'Algerian' },
  { ID: 'Andorran', Name: 'Andorran' },
  { ID: 'Angolan', Name: 'Angolan' },
  { ID: 'Antiguan or Barbudan', Name: 'Antiguan or Barbudan' },
  { ID: 'Argentine', Name: 'Argentine' },
  { ID: 'Armenian', Name: 'Armenian' },
  { ID: 'Australian', Name: 'Australian' },
  { ID: 'Austrian', Name: 'Austrian' },
  { ID: 'Azerbaijani, Azeri', Name: 'Azerbaijani, Azeri' },
  { ID: 'Bahamian', Name: 'Bahamian' },
  { ID: 'Bahraini', Name: 'Bahraini' },
  { ID: 'Bengali', Name: 'Bengali' },
  { ID: 'Barbadian', Name: 'Barbadian' },
  { ID: 'Belarusian', Name: 'Belarusian' },
  { ID: 'Belgian', Name: 'Belgian' },
  { ID: 'Belizean', Name: 'Belizean' },
  { ID: 'Beninese, Beninois', Name: 'Beninese, Beninois' },
  { ID: 'Bhutanese', Name: 'Bhutanese' },
  { ID: 'Bolivian', Name: 'Bolivian' },
  { ID: 'Bosnian or Herzegovinian', Name: 'Bosnian or Herzegovinian' },
  { ID: 'Motswana, Botswanan', Name: 'Motswana, Botswanan' },
  { ID: 'Brazilian', Name: 'Brazilian' },
  { ID: 'Bruneian', Name: 'Bruneian' },
  { ID: 'Bulgarian', Name: 'Bulgarian' },
  { ID: 'Burkinabé', Name: 'Burkinabé' },
  { ID: 'Burmese', Name: 'Burmese' },
  { ID: 'Burundian', Name: 'Burundian' },
  { ID: 'Cabo Verdean', Name: 'Cabo Verdean' },
  { ID: 'Cambodian', Name: 'Cambodian' },
  { ID: 'Cameroonian', Name: 'Cameroonian' },
  { ID: 'Canadian', Name: 'Canadian' },
  { ID: 'Central African', Name: 'Central African' },
  { ID: 'Chadian', Name: 'Chadian' },
  { ID: 'Chilean', Name: 'Chilean' },
  { ID: 'Chinese', Name: 'Chinese' },
  { ID: 'Colombian', Name: 'Colombian' },
  { ID: 'Comoran, Comorian', Name: 'Comoran, Comorian' },
  { ID: 'Congolese', Name: 'Congolese' },
  { ID: 'Congolese', Name: 'Congolese' },
  { ID: 'Costa Rican', Name: 'Costa Rican' },
  { ID: 'Ivorian', Name: 'Ivorian' },
  { ID: 'Croatian', Name: 'Croatian' },
  { ID: 'Cuban', Name: 'Cuban' },
  { ID: 'Cypriot', Name: 'Cypriot' },
  { ID: 'Czech', Name: 'Czech' },
  { ID: 'Danish', Name: 'Danish' },
  { ID: 'Djiboutian', Name: 'Djiboutian' },
  { ID: 'Dominican', Name: 'Dominican' },
  { ID: 'Dominican', Name: 'Dominican' },
  { ID: 'Timorese', Name: 'Timorese' },
  { ID: 'Ecuadorian', Name: 'Ecuadorian' },
  { ID: 'Egyptian', Name: 'Egyptian' },
  { ID: 'Salvadoran', Name: 'Salvadoran' },
  { ID: 'Equatorial Guinean, Equatoguinean', Name: 'Equatorial Guinean, Equatoguinean' },
  { ID: 'Eritrean', Name: 'Eritrean' },
  { ID: 'Estonian', Name: 'Estonian' },
  { ID: 'Ethiopian', Name: 'Ethiopian' },
  { ID: 'Fijian', Name: 'Fijian' },
  { ID: 'Finnish', Name: 'Finnish' },
  { ID: 'French', Name: 'French' },
  { ID: 'Gabonese', Name: 'Gabonese' },
  { ID: 'Gambian', Name: 'Gambian' },
  { ID: 'Georgian', Name: 'Georgian' },
  { ID: 'German', Name: 'German' },
  { ID: 'Ghanaian', Name: 'Ghanaian' },
  { ID: 'Gibraltar', Name: 'Gibraltar' },
  { ID: 'Greek, Hellenic', Name: 'Greek, Hellenic' },
  { ID: 'Grenadian', Name: 'Grenadian' },
  { ID: 'Guatemalan', Name: 'Guatemalan' },
  { ID: 'Guinean', Name: 'Guinean' },
  { ID: 'Bissau-Guinean', Name: 'Bissau-Guinean' },
  { ID: 'Guyanese', Name: 'Guyanese' },
  { ID: 'Haitian', Name: 'Haitian' },
  { ID: 'Honduran', Name: 'Honduran' },
  { ID: 'Hungarian, Magyar', Name: 'Hungarian, Magyar' },
  { ID: 'Icelandic', Name: 'Icelandic' },
  { ID: 'Indian', Name: 'Indian' },
  { ID: 'Indonesian', Name: 'Indonesian' },
  { ID: 'Iranian, Persian', Name: 'Iranian, Persian' },
  { ID: 'Iraqi', Name: 'Iraqi' },
  { ID: 'Irish', Name: 'Irish' },
  { ID: 'Israeli', Name: 'Israeli' },
  { ID: 'Italian', Name: 'Italian' },
  { ID: 'Ivorian', Name: 'Ivorian' },
  { ID: 'Jamaican', Name: 'Jamaican' },
  { ID: 'Japanese', Name: 'Japanese' },
  { ID: 'Jordanian', Name: 'Jordanian' },
  { ID: 'Kazakhstani, Kazakh', Name: 'Kazakhstani, Kazakh' },
  { ID: 'Kenyan', Name: 'Kenyan' },
  { ID: 'I-Kiribati', Name: 'I-Kiribati' },
  { ID: 'North Korean', Name: 'North Korean' },
  { ID: 'South Korean', Name: 'South Korean' },
  { ID: 'Kuwaiti', Name: 'Kuwaiti' },
  { ID: 'Kyrgyzstani, Kyrgyz, Kirgiz, Kirghiz', Name: 'Kyrgyzstani, Kyrgyz, Kirgiz, Kirghiz' },
  { ID: 'Lao, Laotian', Name: 'Lao, Laotian' },
  { ID: 'Latvian, Lettish', Name: 'Latvian, Lettish' },
  { ID: 'Lebanese', Name: 'Lebanese' },
  { ID: 'Basotho', Name: 'Basotho' },
  { ID: 'Liberian', Name: 'Liberian' },
  { ID: 'Libyan', Name: 'Libyan' },
  { ID: 'Liechtensteiner', Name: 'Liechtensteiner' },
  { ID: 'Lithuanian', Name: 'Lithuanian' },
  { ID: 'Luxembourg, Luxembourgish', Name: 'Luxembourg, Luxembourgish' },
  { ID: 'Macedonian', Name: 'Macedonian' },
  { ID: 'Malagasy', Name: 'Malagasy' },
  { ID: 'Malawian', Name: 'Malawian' },
  { ID: 'Malaysian', Name: 'Malaysian' },
  { ID: 'Maldivian', Name: 'Maldivian' },
  { ID: 'Malian, Malinese', Name: 'Malian, Malinese' },
  { ID: 'Maltese', Name: 'Maltese' },
  { ID: 'Marshallese', Name: 'Marshallese' },
  { ID: 'Martiniquais, Martinican', Name: 'Martiniquais, Martinican' },
  { ID: 'Mauritanian', Name: 'Mauritanian' },
  { ID: 'Mauritian', Name: 'Mauritian' },
  { ID: 'Mexican', Name: 'Mexican' },
  { ID: 'Micronesian', Name: 'Micronesian' },
  { ID: 'Moldovan', Name: 'Moldovan' },
  { ID: 'Monégasque, Monacan', Name: 'Monégasque, Monacan' },
  { ID: 'Mongolian', Name: 'Mongolian' },
  { ID: 'Montenegrin', Name: 'Montenegrin' },
  { ID: 'Moroccan', Name: 'Moroccan' },
  { ID: 'Mozambican', Name: 'Mozambican' },
  { ID: 'Namibian', Name: 'Namibian' },
  { ID: 'Nauruan', Name: 'Nauruan' },
  { ID: 'Nepali, Nepalese', Name: 'Nepali, Nepalese' },
  { ID: 'Dutch, Netherlandic', Name: 'Dutch, Netherlandic' },
  { ID: 'New Zealand, NZ, Zelanian', Name: 'New Zealand, NZ, Zelanian' },
  { ID: 'Nicaraguan', Name: 'Nicaraguan' },
  { ID: 'Nigerien', Name: 'Nigerien' },
  { ID: 'Nigerian', Name: 'Nigerian' },
  { ID: 'Northern Marianan', Name: 'Northern Marianan' },
  { ID: 'Norwegian', Name: 'Norwegian' },
  { ID: 'Omani', Name: 'Omani' },
  { ID: 'Pakistani', Name: 'Pakistani' },
  { ID: 'Palauan', Name: 'Palauan' },
  { ID: 'Palestinian', Name: 'Palestinian' },
  { ID: 'Panamanian', Name: 'Panamanian' },
  { ID: 'Papua New Guinean, Papuan', Name: 'Papua New Guinean, Papuan' },
  { ID: 'Paraguayan', Name: 'Paraguayan' },
  { ID: 'Peruvian', Name: 'Peruvian' },
  { ID: 'Filipino, Philippine', Name: 'Filipino, Philippine' },
  { ID: 'Polish', Name: 'Polish' },
  { ID: 'Portuguese', Name: 'Portuguese' },
  { ID: 'Puerto Rican', Name: 'Puerto Rican' },
  { ID: 'Qatari', Name: 'Qatari' },
  { ID: 'Romanian', Name: 'Romanian' },
  { ID: 'Russian', Name: 'Russian' },
  { ID: 'Rwandan', Name: 'Rwandan' },
  { ID: 'Kittitian or Nevisian', Name: 'Kittitian or Nevisian' },
  { ID: 'Saint Lucian', Name: 'Saint Lucian' },
  { ID: 'Saint Vincentian, Vincentian', Name: 'Saint Vincentian, Vincentian' },
  { ID: 'Samoan', Name: 'Samoan' },
  { ID: 'Sammarinese', Name: 'Sammarinese' },
  { ID: 'São Toméan', Name: 'São Toméan' },
  { ID: 'Saudi, Saudi Arabian', Name: 'Saudi, Saudi Arabian' },
  { ID: 'Senegalese', Name: 'Senegalese' },
  { ID: 'Serbian', Name: 'Serbian' },
  { ID: 'Seychellois', Name: 'Seychellois' },
  { ID: 'Sierra Leonean', Name: 'Sierra Leonean' },
  { ID: 'Singapore, Singaporean', Name: 'Singapore, Singaporean' },
  { ID: 'Slovak', Name: 'Slovak' },
  { ID: 'Slovenian, Slovene', Name: 'Slovenian, Slovene' },
  { ID: 'Solomon Island', Name: 'Solomon Island' },
  { ID: 'Somali', Name: 'Somali' },
  { ID: 'South African', Name: 'South African' },
  { ID: 'South Sudanese', Name: 'South Sudanese' },
  { ID: 'Spanish', Name: 'Spanish' },
  { ID: 'Sri Lankan', Name: 'Sri Lankan' },
  { ID: 'Sudanese', Name: 'Sudanese' },
  { ID: 'Surinamese', Name: 'Surinamese' },
  { ID: 'Swazi', Name: 'Swazi' },
  { ID: 'Swedish', Name: 'Swedish' },
  { ID: 'Swiss', Name: 'Swiss' },
  { ID: 'Syrian', Name: 'Syrian' },
  { ID: 'Tajikistani', Name: 'Tajikistani' },
  { ID: 'Tanzanian', Name: 'Tanzanian' },
  { ID: 'Thai', Name: 'Thai' },
  { ID: 'Timorese', Name: 'Timorese' },
  { ID: 'Togolese', Name: 'Togolese' },
  { ID: 'Tokelauan', Name: 'Tokelauan' },
  { ID: 'Tongan', Name: 'Tongan' },
  { ID: 'Trinidadian or Tobagonian', Name: 'Trinidadian or Tobagonian' },
  { ID: 'Tunisian', Name: 'Tunisian' },
  { ID: 'Turkish', Name: 'Turkish' },
  { ID: 'Turkmen', Name: 'Turkmen' },
  { ID: 'Tuvaluan', Name: 'Tuvaluan' },
  { ID: 'Ugandan', Name: 'Ugandan' },
  { ID: 'Ukrainian', Name: 'Ukrainian' },
  { ID: 'Emirati, Emirian, Emiri', Name: 'Emirati, Emirian, Emiri' },
  { ID: 'UK, British', Name: 'UK, British' },
  { ID: 'United States, U.S., American', Name: 'United States, U.S., American' },
  { ID: 'Uruguayan', Name: 'Uruguayan' },
  { ID: 'Uzbekistani, Uzbek', Name: 'Uzbekistani, Uzbek' },
  { ID: 'Ni-Vanuatu, Vanuatuan', Name: 'Ni-Vanuatu, Vanuatuan' },
  { ID: 'Vatican', Name: 'Vatican' },
  { ID: 'Venezuelan', Name: 'Venezuelan' },
  { ID: 'Vietnamese', Name: 'Vietnamese' },
  { ID: 'Yemeni', Name: 'Yemeni' },
  { ID: 'Zambian', Name: 'Zambian' },
];

export const ApplicationStatus = [
  { ID: 'Active', Name: 'Active' },
  { ID: 'Inactive', Name: 'Inactive' },
];

export const MotherTongue = [
  { ID: 'Arabic', Name: 'Arabic' },
  { ID: 'English', Name: 'English' },
  { ID: 'Baluchi', Name: 'Baluchi' },
  { ID: 'Hindi', Name: 'Hindi' },
  { ID: 'Gujarathi', Name: 'Gujarathi' },
  { ID: 'Urdu', Name: 'Urdu' },
  { ID: 'Portuguese', Name: 'Portuguese' },
  { ID: 'Somali', Name: 'Somali' },
  { ID: 'Others', Name: 'Others' },
];

export const Against = [
  { ID: 'Company', Name: 'Company' },
  { ID: 'Employee', Name: 'Employee' },
  { ID: 'Attendance', Name: 'Attendance' },
  { ID: 'Salary_Slip', Name: 'Salary Slip' },
  { ID: 'Appraisal', Name: 'Appraisal' },
  { ID: 'Loan', Name: 'Loan' },
  { ID: 'Incentive', Name: 'Incentive' },
  { ID: 'Department', Name: 'Department' },
  { ID: 'Group', Name: 'Group' },
  { ID: 'Grade', Name: 'Grade' },
  { ID: 'Location', Name: 'Location' },
];

export const HolidayTypes = [
  { ID: 'Full_Day', Name: 'Full Day' },
  { ID: 'First_Half', Name: 'First Half' },
  { ID: 'Second_Half', Name: 'Second Half' },
];

export const Make = [
  { ID: 'Acura', Name: 'Acura' },
  { ID: 'Audi', Name: 'Audi' },
  { ID: 'BMW', Name: 'BMW' },
  { ID: 'Chery', Name: 'Chery' },
  { ID: 'Chevrolet', Name: 'Chevrolet' },
  { ID: 'Dodge', Name: 'Dodge' },
  { ID: 'Ferrari', Name: 'Ferrari' },
  { ID: 'Fiat', Name: 'Fiat' },
  { ID: 'Ford', Name: 'Ford' },
  { ID: 'GMC', Name: 'GMC' },
  { ID: 'Honda', Name: 'Honda' },
  { ID: 'Hyundai', Name: 'Hyundai' },
  { ID: 'Infinity', Name: 'Infinity' },
  { ID: 'Jaguar', Name: 'Jaguar' },
  { ID: 'Jeep', Name: 'Jeep' },
  { ID: 'Kia', Name: 'Kia' },
  { ID: 'Land_Rover', Name: 'Land_Rover' },
  { ID: 'Lexus', Name: 'Lexus' },
  { ID: 'Mazda', Name: 'Mazda' },
  { ID: 'Mercedes', Name: 'Mercedes' },
  { ID: 'MG', Name: 'MG' },
  { ID: 'Mini', Name: 'Mini' },
  { ID: 'Mitsubishi', Name: 'Mitsubishi' },
  { ID: 'Nissan', Name: 'Nissan' },
  { ID: 'Ram', Name: 'Ram' },
  { ID: 'Renault', Name: 'Renault' },
  { ID: 'Subaru', Name: 'Subaru' },
  { ID: 'Suzuki', Name: 'Suzuki' },
  { ID: 'Toyota', Name: 'Toyota' },
  { ID: 'Volkswagen', Name: 'Volkswagen' },
  { ID: 'Volvo', Name: 'Volvo' },
];

export const VehicleType = [
  { ID: 'Compact', Name: 'Compact' },
  { ID: 'Hathback', Name: 'Hathback' },
  { ID: 'Sedan', Name: 'Sedan' },
  { ID: 'Coupe', Name: 'Coupe' },
  { ID: 'SUV', Name: 'SUV' },
  { ID: 'Sports', Name: 'Sports' },
  { ID: 'Convertible', Name: 'Convertible' },
  { ID: 'Van', Name: 'Van' },
  { ID: 'Pickup', Name: 'Pickup' },
];

export const TransmissionTypes = [
  { ID: 'Automatic2WD', Name: 'Automatic2WD' },
  { ID: 'Automatic4WD', Name: 'Automatic4WD' },
  { ID: 'Manual2WD', Name: 'Manual2WD' },
  { ID: 'Manual4WD', Name: 'Manual4WD' },
];

export const FuelTypes = [
  { ID: 'Petrol', Name: 'Petrol' },
  { ID: 'Diesel', Name: 'Diesel' },
];

export const FuelLevel = [
  { ID: '1', Name: '1' },
  { ID: '2', Name: '2' },
  { ID: '3', Name: '3' },
  { ID: '4', Name: '4' },
  { ID: '5', Name: '5' },
  { ID: '6', Name: '6' },
  { ID: '7', Name: '7' },
  { ID: '8', Name: '8' },
];

export const AddonsTypes = [
  { ID: 'Nos', Name: 'Nos' },
  { ID: 'Days', Name: 'Days' },
  { ID: 'Nights', Name: 'Nights' },
  { ID: 'Weeks', Name: 'Weeks' },
  { ID: 'Months', Name: 'Months' },
  { ID: 'Liters', Name: 'Liters' },
  { ID: 'Kilometers', Name: 'Kilometers' },
  { ID: 'Percentage', Name: 'Percentage' },
];

export const PaymentStatus = [
  { ID: 'Unpaid', Name: 'Unpaid' },
  { ID: 'Paid', Name: 'Paid' },
  { ID: 'Partial', Name: 'Partial' },
  { ID: 'Due', Name: 'Due' },
];

export const PaymentStatusColor = [
  { ID: 'Unpaid', Name: 'Unpaid', color: 'warning' },
  { ID: 'Paid', Name: 'Paid', color: 'success' },
  { ID: 'Partial', Name: 'Partial', color: 'warning' },
  { ID: 'Due', Name: 'Due', color: 'error' },
  { ID: 'Vacant', Name: 'Vacant', color: 'error' },
  { ID: 'Occupied', Name: 'Occupied', color: 'success' },
  { ID: 'Expired', Name: 'Expired', color: 'error' },
  { ID: 'Expiring', Name: 'Expiring', color: 'warning' },
  { ID: 'Active', Name: 'Active', color: 'success' },
  { ID: 'Approved', Name: 'Approved', color: 'success' },
  { ID: 'Draft', Name: 'Draft', color: 'warning' },
  { ID: 'Rejected', Name: 'Rejected', color: 'error' },
];

export const EventDuration = [
  { ID: '15', Name: '15 mins' },
  { ID: '30', Name: '30 mins' },
  { ID: '45', Name: '45 mins' },
  { ID: '60', Name: '1 hour' },
  { ID: '75', Name: '1 hour 15 mins' },
  { ID: '90', Name: '1 hour 30 mins' },
  { ID: '105', Name: '1 hour 45 mins' },
  { ID: '120', Name: '2 hours' },
  { ID: '135', Name: '2 hours 15 mins' },
  { ID: '150', Name: '2 hours 30 mins' },
  { ID: '165', Name: '2 hours 45 mins' },
  { ID: '180', Name: '3 hours' },
  { ID: '195', Name: '3 hours 15 mins' },
  { ID: '210', Name: '3 hours 30 mins' },
  { ID: '225', Name: '3 hours 45 mins' },
  { ID: '240', Name: '4 hours' },
  { ID: '255', Name: '4 hours 15 mins' },
  { ID: '270', Name: '4 hours 30 mins' },
  { ID: '285', Name: '4 hours 45 mins' },
  { ID: '300', Name: '5 hours' },
  { ID: '315', Name: '5 hours 15 mins' },
  { ID: '330', Name: '5 hours 30 mins' },
  { ID: '345', Name: '5 hours 45 mins' },
  { ID: '360', Name: '6 hours' },
];

export const docLayoutForms = [
  { ID: 'QuotationLayout', Name: 'Quotation' },
  { ID: 'BookingLayout', Name: 'Booking' },
  { ID: 'ReceiptLayout', Name: 'Receipt Voucher' },
  { ID: 'PaymentLayout', Name: 'Payment Voucher' },
  { ID: 'SalesOrderLayout', Name: 'Sales Order' },
  { ID: 'SalesInvoiceLayout', Name: 'Sales Invoice' },
  { ID: 'SalesReturnLayout', Name: 'Sales Return' },
  { ID: 'PurchaseRequisitionLayout', Name: 'Purchase Requisition' },
  { ID: 'PurchaseOrderLayout', Name: 'Purchase Order' },
  { ID: 'PurchaseInvoiceLayout', Name: 'Purchase Invoice' },
  { ID: 'PurchaseReturnLayout', Name: 'Purchase Return' },
  { ID: 'RequestForQuotationLayout', Name: 'Request For Quotation' },
  { ID: 'AccountStatementLayout', Name: 'Account Statement Report' },
  { ID: 'ProfitAndLossLayout', Name: 'Profit And Loss Report' },
  { ID: 'TrialBalanceLayout', Name: 'Trial Balance Report' },
  { ID: 'BalanceSheetLayout', Name: 'Balance Sheet Report' },
];

export const approvalForms = [
  { ID: 'Quotation', Name: 'Quotation' },
  { ID: 'SalesOrder', Name: 'Sales Order' },
  { ID: 'SalesInvoice', Name: 'Sales Invoice' },
  { ID: 'SalesReturn', Name: 'Sales Return' },
  { ID: 'PurchaseRequisition', Name: 'Purchase Requisition' },
  { ID: 'RequestForQuotation', Name: 'Request For Quotation' },
  { ID: 'VendorQuotation', Name: 'Vendor Quotation' },
  { ID: 'PurchaseOrder', Name: 'Purchase Order' },
  { ID: 'PurchaseInvoice', Name: 'Purchase Invoice' },
  { ID: 'PurchaseReturn', Name: 'Purchase Return' },
  { ID: 'GoodsReceivedNote', Name: 'Goods Received Note' },
  { ID: 'DeliveryNote', Name: 'Delivery Note' },
  { ID: 'PurchaseReturn', Name: 'Purchase Return' },
];

export const NoofEmployees = [
  { ID: '1', Name: '1-10' },
  { ID: '2', Name: '11-50' },
  { ID: '3', Name: '51-200' },
  { ID: '4', Name: '201-500' },
  { ID: '5', Name: '500+' },
];

export const importForms = [
  'BUILDINGS',
  'TENANTS',
  'UNITS',
  'RECEIPTS',
  'PAYMENTS',
  'CLIENTS',
  'LEADS',
  'VENDORS',
  'ACCOUNT-STATEMENT-REPORT',
  'TRIAL-BALANCE-REPORT',
  'PROFIT-AND-LOSS-REPORT',
  'BALANCE-SHEET-REPORT',
];

export const printBtnForms = ['organization-chart', 'dashboard'];

export const rowForms = [
  'managements',
  'asset-status',
  'asset-conditions',
  'asset-movement-purpose',
  'unit-of-measurements',
  'tax-categories',
  'contact-groups',
  'lead-types',
  'lead-status',
  'downtime-types',
  'project-types',
  'task-types',
  'project-status',
  'lead-source',
  'call-status',
  'skills',
  'designations',
  'employee-groups',
  'employee-grades',
  'locations',
  'Models',
  'grievance-types',
  'job-application-source',
  'job-application-status',
  'vendor-groups',
  'industries',
  'territories',
];

export const excludedFieldsClone = [
  'CreatedDate',
  'UpdatedBy',
  'CreatedBy',
  'createdby',
  'createddate',
  'UpdatedDate',
  'DocumentTo',
  'ID',
  'Date',
  'Series',
  'Number',
  'Id',
  'id',
  'Client',
  //  'UnitNo',
  'WaterMeterNo',
  'ElectricityMeterNo',
  'WaterAccountNo',
  'ElectricityAccountNo',
];

export let voucherNumberGeneratorFormClone = [
  {
    form: 'sales-invoices',
    column: 'Company',
    provider: 'Lead',
    field: 'DocumentTo',
  },
  {
    form: 'QUOTATIONS',
    column: 'Company',
    provider: 'Lead',
  },
];

export let voucherNumberGeneratorFormCloneDirect = ['PURCHASEREQUISITIONS'];

export const PaymentModeRP = [
  { ID: 'OnlineTransfer', Name: 'Online Transfer' },
  { ID: 'Cheque', Name: 'Cheque' },
];

export const formConnectedToExternalProvider = ['CONTACTS', 'EVENTS'];

export const numberCols = [
  'Balance',
  'Grand_Total',
  'Sub_Total',
  'Amount',
  'Total',
  'Rent',
  'Total_Rent',
  'Opening',
  'Receivable_Rent',
  'Closing',
  'Tax_Amount',
  'Discount',
  'Paid',
  'Pending',
  'Outstanding',
  'Invoiced',
  'Till_Date',
  'Due',
  'Advance',
  'Debit',
  'Credit',
  'Tax',
  'Price',
  'Cost',
  'On_Hand',
  'Available',
  'Reserved',
  'In_Purchase',
  'Qty_In',
  'Qty_Out',
  '0-30',
  '30-45',
  '30-60',
  '45-60',
  '45-90',
  '60-75',
  '60-90',
  '90-120',
  '75-90',
  '90-120',
  '120-150',
  '150-180',
  '120_Above',
  '180_Above',
];

export const totalCols = [
  'Balance',
  'Grand_Total',
  'Sub_Total',
  'Amount',
  'Total',
  'Rent',
  'Total_Rent',
  'Receivable_Rent',
  'Tax_Amount',
  'Discount',
  'Paid',
  'Pending',
  'Outstanding',
  'Invoiced',
  'Till_Date',
  'Due',
  'Advance',
  'Debit',
  'Credit',
  'Tax',
  'Price',
  'Cost',
  'On_Hand',
  'Available',
  'Reserved',
  'In_Purchase',
  'Qty_In',
  'Qty_Out',
  '0-30',
  '30-45',
  '30-60',
  '45-60',
  '45-90',
  '60-75',
  '60-90',
  '90-120',
  '75-90',
  '90-120',
  '120-150',
  '150-180',
  '120_Above',
  '180_Above',
  'Opening',
  'Closing',
];

export const integerFormattingCols = ['Quantity', 'Items'];

export let priceListsForms = [
  'SALESINVOICES',
  'SALESORDERS',
  'QUOTATIONS',
  'PURCHASEORDERS',
  'PURCHASEINVOICES',
  'CLIENTS',
  'VENDORS',
];

export let addNewExcludedForms = [
  'point-of-sale',
  'organization-chart',
  'account-statement-report',
  'accounts-receivable-report',
  'accounts-payable-report',
  'profit-and-loss-report',
  'balance-sheet-report',
  'trial-balance-report',
  'general-ledger-report',
];

export const StyledTreeItemRoot = styled(TreeItem)(({ theme }) => ({
  color: theme.palette.text.secondary,
  [`& .${treeItemClasses.content}`]: {
    color: theme.palette.text.secondary,
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    '&.Mui-expanded': {
      fontWeight: theme.typography.fontWeightRegular,
    },
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
    '&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused': {
      backgroundColor: `var(--tree-view-bg-color, ${theme.palette.action.selected})`,
      color: 'var(--tree-view-color)',
    },
    [`& .${treeItemClasses.label}`]: {
      fontWeight: 'inherit',
      color: 'inherit',
    },
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 0,
    [`& .${treeItemClasses.content}`]: {
      paddingLeft: theme.spacing(2),
    },
  },
}));

export const secret = 'appsgreyerpcom';

export const MenuList = [
  {
    name: '/Accounts',
    checked: false,
  },
  {
    name: '/CRM',
    checked: false,
  },
  {
    name: '/Sales',
    checked: false,
  },
  {
    name: '/Purchase',
    checked: false,
  },
  {
    name: '/HR',
    checked: false,
  },
  {
    name: '/Inventory',
    checked: false,
  },
  {
    name: '/Manufacturing',
    checked: false,
  },
  {
    name: '/Quality',
    checked: false,
  },
  {
    name: '/Payroll',
    checked: false,
  },
  {
    name: '/Properties',
    checked: false,
  },
  {
    name: '/Fleet',
    checked: false,
  },
  {
    name: '/CMS',
    checked: false,
  },
  {
    name: '/Reports',
    checked: false,
  },
  {
    name: '/Masters',
    checked: false,
  },
];

export const PopupItems = [
  'calls',
  'events',
  'tasks',
  'milestones',
  'bank-accounts',
  'owners',
  'transfers',
  'our-clients',
  'coupons',
  'banners',
  'item-categories',
  'banner-categories',
  'maintenance-categories',
  'blog-categories',
  'faq-categories',
  'our-client-categories',
  'product-categories',
  'service-categories',
  'video-categories',
  'photo-categories',
  'salary-components',
  'work-centers',
  'operations',
  'colours',
  'damage-types',
  'sizes',
  'addons',
  'accounts',
  'cost-centers',
  'faqs',
  'team-members',
  'videos',
  'photos',
  'employeegroup',
  'departments',
  'warehouses',
  'warehouse-sections',
];

export const modules = [
  {
    module: 'Accounts',
    child: [
      'Accounts-Dashboard',
      'Accounts',
      'Cost-Centers',
      'Bank-Accounts',
      'Transfers',
      'Receipts',
      'Payments',
      'Expenses',
      'Account-Statement-Report',
      'accounts-receivable-report',
      'accounts-payable-report',
      'Journal-Vouchers',
      'General-Ledger-Report',
      'Trial-Balance-Report',
      'Profit-and-Loss-Report',
      'Balance-Sheet-Report',
    ],
  },
  {
    module: 'Assets',
    child: [
      'Assets-Dashboard',
      'asset-types',
      'Assets',
      'Asset-Movements',
      'Asset-Maintenances',
      'Asset-Maintenance-Logs',
      'Asset-Repairs',
      'Asset-Capitalizations',
    ],
  },
  {
    module: 'CRM',
    child: [
      'Crm-Dashboard',
      'Contacts',
      'Leads',
      'Individuals',
      'Corporates',
      'Calls',
      'Events',
      'Quotations',
    ],
  },
  {
    module: 'Projects',
    child: ['Projects-Dashboard', 'Projects', 'Tasks', 'Milestones', 'Time-Sheets'],
  },
  {
    module: 'Sales',
    child: [
      'Sales-Dashboard',
      'Clients',
      'Sales-Price-Lists',
      'Sales-Orders',
      'Sales-Invoices',
      'Point-of-Sale',
      'Sales-Returns',
    ],
  },
  {
    module: 'Purchase',
    child: [
      'Purchase-Dashboard',
      'Vendors',
      'Purchase-Price-Lists',
      'Purchase-Requisitions',
      'Request-For-Quotations',
      'Vendor-Quotations',
      'Purchase-Orders',
      'Purchase-Invoices',
      'Purchase-Returns',
    ],
  },
  {
    module: 'Inventory',
    child: [
      'inventory-dashboard',
      'Items',
      'Goods-Received-Notes',
      'Delivery-Notes',
      'Inventory-Adjustments',
      'Stock-Transfers',
      'Stock-Ledger-Report',
    ],
  },
  {
    module: 'Manufacturing',
    child: [
      'manufacturing-dashboard',
      'Work-Centers',
      'Operations',
      'Routes',
      'Bill-Of-Materials',
      'Production-Plans',
      'Work-Orders',
      'Job-Cards',
      'Downtimes',
    ],
  },
  {
    module: 'Quality',
    child: ['quality-dashboard', 'quality-checks'],
  },
  {
    module: 'HR',
    child: [
      'Hr-dashboard',
      'ORGANIZATION-CHART',
      'EMPLOYEES',
      'ATTENDANCES',
      'LEAVE-TYPES',
      'LEAVE-LISTS',
      'LEAVE-APPLICATIONS',
      'HOLIDAYS-LISTS',
      'WORKING-HOURS',
      'RESIGNATIONS',
      'GRIEVANCES',
      'JOB-POSTS',
      'JOB-APPLICATIONS',
      'HOLIDAY-LISTS',
    ],
  },
  {
    module: 'Payroll',
    child: [
      'Payroll-dashboard',
      'Salary-Structures',
      'Salary-Slips',
      'Incentives',
      'Retentions',
      'Gratuities',
      'Loans',
    ],
  },
  {
    module: 'Properties',
    child: ['Properties-Dashboard', 'Buildings', 'Units', 'Tenants', 'Maintenances'],
  },
  {
    module: 'Fleet',
    child: [
      'Fleet-Dashboard',
      'vehicles',
      'addons',
      'bookings',
      'damages',
      'repairs',
      'vehicle-maintenances',
      'fines',
    ],
  },
  {
    module: 'CMS',
    child: [
      'BANNERS',
      'Team-Members',
      'PRODUCTS',
      'SERVICES',
      'PHOTOS',
      'VIDEOS',
      'CAREERS',
      'Blogs',
      'Our-Clients',
      'FAQs',
      'coupons',
    ],
  },
  {
    module: 'Reports',
    child: [
      'VAT-Output-Report',
      'VAT-Input-Report',
      'VAT-Payable-Report',
      'Asset-Depreciation-Ledger-Report',
      'Asset-Maintenance-Report',
      'Asset-Activity-Report',
      'Expense-Report',
      'leave-Report',
      'Leads-Activities-Report',
      'Sales-Report',
      'Purchase-Report',
      'Inventory-Report',
      'Management-Report',
      'Maintenance-Report',
      'Vacant-Report',
      'Occupancy-Report',
      'Cancellation-Report',
      'Rent-Received-Report',
      'rent-outstanding-report',
      'cheque-report',
      'voucher-report',
      'monthly-attendance-report',
      'employee-salary-report',
    ],
  },
  {
    module: 'Masters',
    child: [
      'Asset-Status',
      'Asset-Conditions',
      'Asset-Movement-Purpose',
      'Owners',
      'Managements',
      'Item-Categories',
      'Warehouses',
      'Warehouse-Sections',
      'Banner-Categories',
      'Maintenance-Categories',
      'Blog-Categories',
      'FAQ-Categories',
      'Our-Client-Categories',
      'Product-Categories',
      'Service-Categories',
      'Video-Categories',
      'Photo-Categories',
      'Unit-Of-Measurements',
      'Tax-Categories',
      'Contact-Groups',
      'Lead-Types',
      'Colours',
      'Damage-Types',
      'Downtime-Types',
      'Sizes',
      'Lead-Status',
      'Lead-Source',
      'Project-Status',
      'Project-Types',
      'Task-Types',
      'Call-Status',
      'Skills',
      'Designations',
      'Employee-Groups',
      'Employee-Grades',
      'Locations',
      'Models',
      'Grievance-Types',
      'Job-Application-Source',
      'Job-Application-Status',
      'Document-Layouts',
      'Departments',
      'Salary-Components',
      'Vendor-Groups',
      'Industries',
      'Territories',
      'Users',
      'Approval-Types',
    ],
  },
  {
    module: 'Utility',
    child: ['Profile'],
  },
];

export const AddNewDropDown = {
  employeegroup: <MastersRow />,
  leadsource: <MastersRow />,
  leadtype: <MastersRow />,
  contactgroups: <MastersRow />,
};

export const mastersForm = [
  'leadtypes',
  'projecttypes',
  'tasktypes',
  'projectstatus',
  'colours',
  'sizes',
  'leadstatus',
  'leadsource',
  'callstatus',
  'grievancetypes',
  'locations',
  'models',
  'skills',
  'designations',
  'employeegroups',
  'contactgroups',
  'employeegrades',
  'jobapplicationsource',
  'jobapplicationstatus',
  'damagetypes',
  'vendorgroups',
  'industries',
  'territories',
];

export const accVouchersUrl = new Map([
  ['EXPENSE', <Expense />],
  ['PAYMENT', <Receipt isReceipt={false} showSummary={false} />],
  ['TRANSFER', '/accounts/expense'],
  ['SALESINVOICE', <Invoice />],
  ['PURCHASEINVOICE', <Invoice />],
  ['RECEIPT', <Receipt isReceipt={true} showSummary={false} />],
  ['JOURNALENTRIE', <JournalVoucher />],
  ['BANKACCOUNT', <Bankaccount />],
  ['TENANT', <Invoice />],
  ['MAINTENANCE', <Maintenance />],
]);

export const FormPaymentMode = ['MAINTENANCES', 'PAYMENTS', 'RECEIPTS', 'EXPENSES'];
export const invoicePages = ['salesinvoices', 'quotations', 'salesorders', 'purchaseorders'];
export const dbImagePath = [
  'Image',
  'Cheque',
  'Cover',
  'Passport',
  'Visa',
  'License',
  'IDCard',
  'CV',
  'Photo',
];
export const sectionForms = [
  'SALES-INVOICES',
  'PURCHASE-INVOICES',
  'QUOTATIONS',
  'PURCHASE-ORDERS',
  'SALES-ORDERS',
  'PURCHASE-REQUISITIONS',
  'REQUEST-FOR-QUOTATIONS',
  'VENDOR-QUOTATIONS',
  'GOODS-RECEIVED-NOTES',
  'DELIVERY-NOTES',
];

export const formsWithoutAdd = ['organization chart', 'asset-maintenance-logs'];

export const StockInDocuments = [
  { ID: 'purchaseinvoice', Name: 'Purchase Invoice' },
  { ID: 'goodsreceivednote', Name: 'Goods Received Note' },
];

export const StockOutDocuments = [
  { ID: 'salesinvoice', Name: 'Sales Invoice' },
  { ID: 'deliverynote', Name: 'Delivery Note' },
];

export const formsWithVN = [
  'bookings',
  'sales-invoices',
  'quotations',
  'bill-of-materials',
  'production-plans',
  'work-orders',
  'job-cards',
  'purchase-orders',
  'sales-orders',
  'sales-returns',
  'purchase-requisitions',
  'request-for-quotations',
  'vendor-quotations',
  'purchase-invoices',
  'purchase-returns',
  'expenses',
  'receipts',
  'payments',
  'goods-received-notes',
  'delivery-notes',
];

export function StyledTreeItem(props) {
  const { bgColor, color, labelIcon: LabelIcon, labelInfo, labelText, ...other } = props;

  return (
    <StyledTreeItemRoot
      label={
        <Box sx={{ display: 'flex', alignItems: 'center', p: 0.5, pr: 0 }}>
          <Box component={LabelIcon} color="inherit" sx={{ mr: 1 }} />
          <Typography variant="body2">{labelText}</Typography>
          <Typography variant="caption" color="inherit">
            {labelInfo}
          </Typography>
        </Box>
      }
      style={{
        '--tree-view-color': color,
        '--tree-view-bg-color': bgColor,
      }}
      {...other}
    />
  );
}

export const routesERP = [
  {
    path: '/accounts',
    element: null,
    children: [
      { path: 'dashboard', component: <Dashboard /> },
      { path: 'accounts', component: <ChartOfAccounts /> },
      { path: 'accounts/new', component: <ChartOfAccounts /> },
      {
        path: 'cost-centers',
        component: <Grid name="CostCenters" PopupComponent={<CostCenters />} />,
      },
      { path: 'cost-centers/new', component: <CostCenters /> },
      {
        path: 'bank-accounts',
        component: <Grid name="BankAccounts" PopupComponent={<Bankaccount />} />,
      },
      {
        path: 'bank-accounts/new',
        component: <Grid name="BankAccounts" PopupComponent={<Bankaccount />} />,
      },
      { path: 'transfers', component: <Grid name="Transfers" PopupComponent={<Transfer />} /> },
      { path: 'transfers/new', component: <Grid name="Transfers" PopupComponent={<Transfer />} /> },
      { path: 'receipts', component: <Grid name="Receipts" /> },
      { path: 'receipts/new', component: <Receipt /> },
      { path: 'receipts/:id', component: <Receipt /> },
      { path: 'payments', component: <Grid name="Payments" /> },
      { path: 'payments/new', component: <Receipt /> },
      { path: 'payments/:id', component: <Receipt /> },
      { path: 'expenses', component: <Grid name="Expenses" /> },
      { path: 'expenses/new', component: <Expense /> },
      { path: 'expenses/:id', component: <Expense /> },
      { path: 'journal-vouchers', component: <Grid name="JournalVouchers" /> },
      { path: 'journal-vouchers/new', component: <JournalVoucher /> },
      { path: 'journal-vouchers/:id', component: <JournalVoucher /> },
      { path: 'account-statement-report', component: <AccountStatement /> },
      { path: 'accounts-receivable-report', component: <Grid name="AccountsReceivableReport" /> },
      { path: 'accounts-payable-report', component: <Grid name="AccountsPayableReport" /> },
      { path: 'general-ledger-report', component: <Grid name="GeneralLedger" /> },
      { path: 'trial-balance-report', component: <TrialBalance /> },
      { path: 'profit-and-loss-report', component: <ProfitandLoss /> },
      { path: 'balance-sheet-report', component: <BalanceSheet /> },
    ],
  },
  {
    path: '/assets',
    element: null,
    children: [
      { path: 'dashboard', component: <Dashboard /> },
      { path: 'asset-types', component: <Grid name="AssetTypes" /> },
      { path: 'asset-types/new', component: <AssetType /> },
      { path: 'asset-types/:id', component: <AssetType /> },
      { path: 'assets', component: <Grid name="Assets" /> },
      { path: 'assets/new', component: <Asset /> },
      { path: 'assets/:id', component: <Asset /> },
      { path: 'asset-movements', component: <Grid name="AssetMovements" /> },
      { path: 'asset-movements/new', component: <AssetMovement /> },
      { path: 'asset-movements/:id', component: <AssetMovement /> },
      { path: 'asset-maintenances', component: <Grid name="AssetMaintenances" /> },
      { path: 'asset-maintenances/new', component: <AssetMaintenance /> },
      { path: 'asset-maintenances/:id', component: <AssetMaintenance /> },
      { path: 'asset-maintenance-logs', component: <Grid name="AssetMaintenanceLogs" /> },
      { path: 'asset-maintenance-logs/:id', component: <AssetMaintenanceLog /> },
      { path: 'asset-capitalizations', component: <Grid name="Assetcapitalizations" /> },
      { path: 'asset-capitalizations/new', component: <AssetCapitalization /> },
      { path: 'asset-capitalizations/:id', component: <AssetCapitalization /> },
      { path: 'asset-repairs', component: <Grid name="AssetRepairs" /> },
      { path: 'asset-repairs/new', component: <AssetRepair /> },
      { path: 'asset-repairs/:id', component: <AssetRepair /> },
    ],
  },
  {
    path: '/CRM',
    component: null,
    children: [
      { component: <Navigate replace />, index: true },
      { path: 'dashboard', component: <Dashboard /> },
      { path: 'contacts', component: <Grid name="contacts" FormComponent={<Contact />} /> },
      // { path: 'contacts', component: <ContactGoogle /> },
      { path: 'contacts/new', component: <Contact /> },
      { path: 'contacts/:id', component: <Contact /> },
      { path: 'leads', component: <Grid name="leads" FormComponent={<Lead />} /> },
      { path: 'leads/new', component: <Lead /> },
      { path: 'leads/:id', component: <Lead /> },
      { path: 'individuals', component: <Grid name="individuals" FormComponent={<Individual />} />,},
      { path: 'individuals/new', component: <Lead /> },
      { path: 'individuals/:id', component: <Lead /> },
      { path: 'corporates', component: <Grid name="corporates" FormComponent={<Corporate />} />, exact: true, },
      { path: 'corporates/new', component: <Lead /> },
      { path: 'corporates/:id', component: <Lead /> },
      { path: 'quotations', component: <Grid name="quotations" /> },
      { path: 'quotations/new', component: <Invoice /> },
      { path: 'quotations/:id', component: <Invoice /> },
      { path: 'calls', component: <Grid name="calls" PopupComponent={<Call />} /> },
      { path: 'calls/new', component: <Grid name="calls" PopupComponent={<Call />} /> },
      { path: 'events', component: <CalendarPage PopupComponent={<MeetingForm />} /> },
      { path: 'events/new', component: <MeetingForm /> },
      { path: 'mails', component: <Mails /> },
      { path: 'label/:customLabel', component: <Mails /> },
      { path: 'label/:customLabel/:mailId', component: <Mails /> },
      { path: ':systemLabel', component: <Mails /> },
      { path: ':systemLabel/:mailId', component: <Mails /> },
    ],
  },
  {
    path: '/projects',
    component: null,
    children: [
      { component: <Navigate replace />, index: true },
      { path: 'dashboard', component: <Dashboard /> },
      { path: 'projects', component: <Grid name="Projects" /> },
      { path: 'projects/new', component: <Project /> },
      { path: 'projects/:id', component: <Project /> },
      { path: 'tasks', component: <KanbanPage /> },
      { path: 'tasks/new', component: <KanbanPage /> },
      { path: 'milestones', component: <Grid name="Milestones" PopupComponent={<Milestone />} /> },
      {
        path: 'milestones/new',
        component: <Grid name="Milestones" PopupComponent={<Milestone />} />,
      },
      { path: 'time-sheets', component: <Grid name="TimeSheets" /> },
      { path: 'time-sheets/new', component: <TimeSheet /> },
      { path: 'time-sheets/:id', component: <TimeSheet /> },
    ],
  },
  {
    path: '/sales',
    component: null,
    children: [
      { component: <Navigate replace />, index: true },
      { path: 'dashboard', component: <Dashboard /> },
      {
        path: 'clients',
        component: <Grid name="Clients" FormComponent={<Client showFilePicker={false} />} />,
      },
      { path: 'clients/new', component: <Client /> },
      { path: 'clients/:id', component: <Client /> },
      { path: 'sales-price-lists', component: <Grid name="SalesPriceLists" /> },
      { path: 'sales-price-lists/new', component: <PriceList /> },
      { path: 'sales-price-lists/:id', component: <PriceList /> },
      { path: 'sales-orders', component: <Grid name="SaleOrders" /> },
      { path: 'sales-orders/new', component: <Order /> },
      { path: 'sales-orders/:id', component: <Order /> },
      { path: 'point-of-sale', component: <PointOfSale /> },
      { path: 'sales-returns', component: <Grid name="SalesReturns" /> },
      { path: 'sales-returns/new', component: <Return /> },
      { path: 'sales-returns/:id', component: <Return /> },
      { path: 'sales-invoices', component: <Grid name="Invoices" /> },
      { path: 'sales-invoices/new', component: <Invoice /> },
      { path: 'sales-invoices/:id', component: <Invoice /> },
    ],
  },
  {
    path: '/purchase',
    component: null,
    children: [
      { component: <Navigate replace />, index: true },
      { path: 'dashboard', component: <Dashboard /> },
      {
        path: 'vendors',
        component: <Grid name="vendors" FormComponent={<Vendor showFilePicker={false} />} />,
      },
      { path: 'vendors/new', component: <Vendor /> },
      { path: 'vendors/:id', component: <Vendor /> },
      { path: 'purchase-price-lists', component: <Grid name="PurchasePriceLists" /> },
      { path: 'purchase-price-lists/new', component: <PriceList /> },
      { path: 'purchase-price-lists/:id', component: <PriceList /> },
      { path: 'purchase-requisitions', component: <Grid name="Requisitions" /> },
      { path: 'purchase-requisitions/new', component: <PurchaseRequisition /> },
      { path: 'purchase-requisitions/:id', component: <PurchaseRequisition /> },
      { path: 'request-for-quotations', component: <Grid name="Quotations" /> },
      { path: 'request-for-quotations/new', component: <RequestforQuotation /> },
      { path: 'request-for-quotations/:id', component: <RequestforQuotation /> },
      { path: 'vendor-quotations', component: <Grid name="VendorQuotations" /> },
      { path: 'vendor-quotations/new', component: <VendorQuotation /> },
      { path: 'vendor-quotations/:id', component: <VendorQuotation /> },
      { path: 'purchase-orders', component: <Grid name="PurchaseOrders" /> },
      { path: 'purchase-orders/new', component: <Order /> },
      { path: 'purchase-orders/:id', component: <Order /> },
      { path: 'purchase-invoices', component: <Grid name="PurchaseInvoices" /> },
      { path: 'purchase-invoices/new', component: <Invoice /> },
      { path: 'purchase-invoices/:id', component: <Invoice /> },
      { path: 'purchase-returns', component: <Grid name="PurchaseReturns" /> },
      { path: 'purchase-returns/new', component: <Return /> },
      { path: 'purchase-returns/:id', component: <Return /> },
    ],
  },
  {
    path: '/inventory',
    component: null,
    children: [
      { component: <Navigate replace />, index: true },
      { path: 'dashboard', component: <Dashboard /> },
      {
        path: 'items',
        component: <Grid name="Items" FormComponent={<Item showFilePicker={false} />} />,
      },
      { path: 'items/new', component: <Item /> },
      { path: 'items/:id', component: <Item /> },
      { path: 'goods-received-notes', component: <Grid name="Receives" /> },
      { path: 'delivery-notes', component: <Grid name="Deliveries" /> },
      { path: 'inventory-adjustments', component: <Grid name="Adjustments" /> },
      { path: 'stock-transfers', component: <Grid name="Transfer" /> },
      { path: 'stock-ledger-report', component: <Grid name="StockLedger" /> },
      { path: 'goods-received-notes/new', component: <GoodsReceivedNotes /> },
      { path: 'Delivery-Notes/new', component: <DeliveryNotes /> },
      { path: 'Inventory-Adjustments/new', component: <InventoryAdjustments /> },
      { path: 'stock-transfers/new', component: <StockTransfer /> },
      { path: 'Delivery-Notes/:id', component: <DeliveryNotes /> },
      { path: 'Inventory-Adjustments/:id', component: <InventoryAdjustments /> },
      { path: 'stock-transfers/:id', component: <StockTransfer /> },
      { path: 'goods-received-notes/:id', component: <GoodsReceivedNotes /> },
      { path: 'printpopuppanel', component: <InvoicePrint /> },
    ],
  },
  {
    path: '/manufacturing',
    component: null,
    children: [
      { component: <Navigate replace />, index: true },
      { path: 'dashboard', component: <Dashboard /> },
      {
        path: 'work-centers',
        component: <Grid name="WorkCenters" PopupComponent={<WorkCenter />} />,
      },
      {
        path: 'work-centers/new',
        component: <Grid name="WorkCenters" PopupComponent={<WorkCenter />} />,
      },
      { path: 'operations', component: <Grid name="Operations" PopupComponent={<Operation />} /> },
      {
        path: 'operations/new',
        component: <Grid name="Operations" PopupComponent={<Operation />} />,
      },
      { path: 'routes', component: <Grid name="routes" /> },
      { path: 'routes/new', component: <Route /> },
      { path: 'routes/:id', component: <Route /> },
      { path: 'bill-of-materials', component: <Grid name="billofmaterials" /> },
      { path: 'bill-of-materials/new', component: <BillOfMaterial /> },
      { path: 'bill-of-materials/:id', component: <BillOfMaterial /> },
      { path: 'production-plans', component: <Grid name="productionplans" /> },
      { path: 'production-plans/new', component: <ProductionPlan /> },
      { path: 'production-plans/:id', component: <ProductionPlan /> },
      { path: 'work-orders', component: <Grid name="workorders" /> },
      { path: 'work-orders/new', component: <WorkOrder /> },
      { path: 'work-orders/:id', component: <WorkOrder /> },
      { path: 'job-cards', component: <Grid name="jobcards" /> },
      { path: 'job-cards/new', component: <JobCard /> },
      { path: 'job-cards/:id', component: <JobCard /> },
      { path: 'downtimes', component: <Grid name="downtimes" /> },
      { path: 'downtimes/new', component: <Downtime /> },
      { path: 'downtimes/:id', component: <Downtime /> },
    ],
  },
  {
    path: '/quality',
    component: null,
    children: [
      { component: <Navigate replace />, index: true },
      { path: 'dashboard', component: <Dashboard /> },
      { path: 'quality-checks', component: <Grid name="qualitychecks" /> },
      { path: 'quality-checks/new', component: <Route /> },
      { path: 'quality-checks/:id', component: <Route /> },
    ],
  },
  {
    path: '/properties',
    component: null,
    children: [
      { component: <Navigate replace />, index: true },
      { path: 'dashboard', component: <PropertiesDashboard /> },
      {
        path: 'buildings',
        component: <Grid name="Buildings" FormComponent={<Building showFilePicker={false} />} />,
      },
      { path: 'buildings/new', component: <Building /> },
      { path: 'buildings/:id', component: <Building /> },
      {
        path: 'units',
        component: <Grid name="Units" FormComponent={<Unit showFilePicker={false} />} />,
        exact: true,
      },
      { path: 'units/new', component: <Unit /> },
      { path: 'units/:id', component: <Unit /> },
      {
        path: 'tenants',
        component: <Grid name="Tenants" FormComponent={<Tenant showFilePicker={false} />} />,
      },
      { path: 'tenants/new', component: <Tenant /> },
      { path: 'tenants/:id', component: <Tenant /> },
      {
        path: 'maintenances',
        component: (
          <Grid name="Maintenances" FormComponent={<Maintenance showFilePicker={false} />} />
        ),
      },
      { path: 'maintenances/new', component: <Maintenance /> },
      { path: 'maintenances/:id', component: <Maintenance /> },
    ],
  },
  {
    path: '/fleet',
    component: null,
    children: [
      { component: <Navigate replace />, index: true },
      { path: 'dashboard', component: <Dashboard /> },
      { path: 'vehicles', component: <Grid name="Vehicles" /> },
      { path: 'vehicles/new', component: <Vehicle /> },
      { path: 'vehicles/:id', component: <Vehicle /> },
      { path: 'addons', component: <Grid name="Addons" PopupComponent={<Addon />} /> },
      { path: 'addons/new', component: <Grid name="Addons" PopupComponent={<Addon />} /> },
      { path: 'Bookings', component: <Grid name="Bookings" /> },
      { path: 'Bookings/new', component: <Booking /> },
      { path: 'Bookings/:id', component: <Booking /> },
      { path: 'damages', component: <Grid name="Damages" /> },
      { path: 'damages/new', component: <Damage /> },
      { path: 'damages/:id', component: <Damage /> },
      { path: 'fines', component: <Grid name="Fines" /> },
      { path: 'fines/new', component: <Fine showFilePicker={false} /> },
    ],
  },
  {
    path: '/cms',
    component: null,
    children: [
      { component: <Navigate replace />, index: true },
      { path: 'banners', component: <Grid name="Banners" PopupComponent={<Banner />} /> },
      { path: 'banners/new', component: <Grid name="Banners" PopupComponent={<Banner />} /> },
      {
        path: 'team-members',
        component: <Grid name="TeamMembers" PopupComponent={<TeamMember />} />,
      },
      {
        path: 'team-members/new',
        component: <Grid name="TeamMembers" PopupComponent={<TeamMember />} />,
      },
      { path: 'products', component: <Grid name="Products" /> },
      { path: 'products/new', component: <Product /> },
      { path: 'products/:id', component: <Product /> },
      { path: 'services', component: <Grid name="Services" /> },
      { path: 'services/new', component: <Service /> },
      { path: 'services/:id', component: <Service /> },
      { path: 'videos', component: <Grid name="Videos" PopupComponent={<Video />} /> },
      { path: 'videos/new', component: <Grid name="Videos" PopupComponent={<Video />} /> },
      { path: 'photos', component: <Grid name="Photos" PopupComponent={<Picture />} /> },
      { path: 'photos/new', component: <Grid name="Photos" PopupComponent={<Picture />} /> },
      { path: 'careers', component: <Grid name="Careers" /> },
      { path: 'careers/new', component: <Career /> },
      { path: 'careers/:id', component: <Career /> },
      { path: 'blogs', component: <Grid name="Blogs" /> },
      { path: 'blogs/new', component: <Blog /> },
      { path: 'blogs/:id', component: <Blog /> },
      { path: 'our-clients', component: <Grid name="OurClients" PopupComponent={<OurClient />} /> },
      {
        path: 'our-clients/new',
        component: <Grid name="OurClients" PopupComponent={<OurClient />} />,
      },
      { path: 'faqs', component: <Grid name="FAQs" PopupComponent={<Faqs />} /> },
      { path: 'faqs/new', component: <Grid name="FAQs" PopupComponent={<Faqs />} /> },
      { path: 'coupons', component: <Grid name="Coupons" PopupComponent={<Coupons />} /> },
      { path: 'coupons/new', component: <Grid name="Coupons" PopupComponent={<Coupons />} /> },
    ],
  },
  {
    path: '/hr',
    component: null,
    children: [
      { component: <Navigate replace />, index: true },
      { path: 'dashboard', component: <Dashboard /> },
      { path: 'employees', component: <Grid name="Employees" /> },
      { path: 'employees/new', component: <Employee /> },
      { path: 'employees/:id', component: <Employee /> },
      { path: 'attendances', component: <Grid name="Attendances" /> },
      { path: 'attendances/new', component: <Attendance /> },
      { path: 'attendances/:id', component: <Attendance /> },
      { path: 'leave-types', component: <Grid name="LeavesTypes" /> },
      { path: 'leave-types/new', component: <LeaveType /> },
      { path: 'leave-types/:id', component: <LeaveType /> },
      { path: 'leave-lists', component: <Grid name="LeavesLists" /> },
      { path: 'leave-lists/new', component: <LeaveList /> },
      { path: 'leave-lists/:id', component: <LeaveList /> },
      // { path: 'leave-block-lists', component: <Grid name="Leaves Blocks" /> },
      // { path: 'leave-block-lists/new', component: <LeaveBlockList /> },
      // { path: 'leave-block-lists/:id', component: <LeaveBlockList /> },
      { path: 'leave-applications', component: <Grid name="LeaveApplications" /> },
      { path: 'leave-applications/new', component: <LeaveApplication /> },
      { path: 'leave-applications/:id', component: <LeaveApplication /> },
      { path: 'holiday-lists', component: <Grid name="HolidaysLists" /> },
      { path: 'working-hours', component: <Grid name="WorkingHours" /> },
      { path: 'working-hours/new', component: <WorkingHours /> },
      { path: 'working-hours/:id', component: <WorkingHours /> },
      { path: 'resignations', component: <Grid name="Resignations" /> },
      { path: 'resignations/new', component: <Resignation /> },
      { path: 'resignations/:id', component: <Resignation /> },
      { path: 'grievances', component: <Grid name="Grievances" /> },
      { path: 'grievances/new', component: <Grievance /> },
      { path: 'grievances/:id', component: <Grievance /> },
      { path: 'job-posts', component: <Grid name="Job Posts" /> },
      { path: 'job-posts/new', component: <JobPost /> },
      { path: 'job-posts/:id', component: <JobPost /> },
      { path: 'job-applications', component: <Grid name="JobApplications" /> },
      { path: 'job-applications/new', component: <JobApplication /> },
      { path: 'job-applications/:id', component: <JobApplication /> },
      { path: 'organization-chart', component: <OrganizationalChartHR /> },
      { path: 'organization-chart/new', component: <OrganizationalChartHR /> },
      { path: 'holiday-lists', component: <Grid name="holidaylists" /> },
      { path: 'holiday-lists/new', component: <HolidayLists /> },
      { path: 'holiday-lists/:id', component: <HolidayLists /> },
    ],
  },
  {
    path: '/payroll',
    component: null,
    children: [
      { component: <Navigate replace />, index: true },
      { path: 'dashboard', component: <Dashboard /> },
      { path: 'salary-structures', component: <Grid name="SalaryStructures" /> },
      { path: 'salary-structures/new', component: <SalaryStructure /> },
      { path: 'salary-structures/:id', component: <SalaryStructure /> },
      { path: 'salary-slips', component: <Grid name="SalarySlips" /> },
      { path: 'salary-slips/new', component: <SalarySlip /> },
      { path: 'salary-slips/:id', component: <SalarySlip /> },
      { path: 'incentives', component: <Grid name="Incentives" /> },
      { path: 'incentives/new', component: <Incentive /> },
      { path: 'incentives/:id', component: <Incentive /> },
      { path: 'retentions', component: <Grid name="Retentions" /> },
      { path: 'retentions/new', component: <Retention /> },
      { path: 'retentions/:id', component: <Retention /> },
      { path: 'gratuities', component: <Grid name="Gratuities" /> },
      { path: 'gratuities/new', component: <Gratuity /> },
      { path: 'gratuities/:id', component: <Gratuity /> },
      { path: 'promotions', component: <Grid name="Promotions" /> },
      { path: 'promotions/new' },
      { path: 'appraisals', component: <Grid name="Appraisals" /> },
      { path: 'appraisals/new' },
      { path: 'loans', component: <Grid name="Loans" /> },
      { path: 'loans/new', component: <Loan /> },
      { path: 'loans/:id', component: <Loan /> },
    ],
  },
  {
    path: '/reports',
    component: null,
    children: [
      { component: <Navigate replace />, index: true },
      { path: 'report-designer', component: <CustomReportDesigner /> },
      { path: 'vat-output-report', component: <Grid name="TaxReceived" /> },
      { path: 'vat-input-report', component: <Grid name="TaxPaid" /> },
      { path: 'vat-payable-report', component: <Grid name="TaxReturn" /> },
      {
        path: 'asset-depreciation-ledger-report',
        component: <Grid name="AssetDepreciationLedgerReport" />,
      },
      { path: 'asset-maintenance-report', component: <Grid name="AssetMaintenanceReport" /> },
      { path: 'asset-activity-report', component: <Grid name="AssetActivityReport" /> },
      { path: 'expense-report', component: <Grid name="ExpenseReport" /> },
      { path: 'leave-report', component: <Grid name="LeaveReport" /> },
      { path: 'leads-activities-report', component: <Grid name="LeadsReport" /> },
      { path: 'sales-report', component: <Grid name="SalesReport" /> },
      { path: 'purchase-report', component: <Grid name="PurchaseReport" /> },
      { path: 'inventory-report', component: <Grid name="InventoryReport" /> },
      { path: 'management-report', component: <Grid name="ManagementReport" /> },
      { path: 'maintenance-report', component: <Grid name="MaintenanceReport" /> },
      { path: 'vacant-report', component: <Grid name="VacantReport" /> },
      { path: 'occupancy-report', component: <Grid name="OccupancyReport" /> },
      { path: 'cancellation-report', component: <Grid name="CancellationReport" /> },
      { path: 'rent-Received-Report', component: <Grid name="Rent ReceivedReport" /> },
      { path: 'rent-outstanding-report', component: <Grid name="OutstandingReport" /> },
      { path: 'cheque-report', component: <Grid name="ChequeReport" /> },
      { path: 'voucher-report', component: <Grid name="VoucherReport" /> },
      { path: 'monthly-attendance-report', component: <MonthlyAttendanceReport /> },
      { path: 'employee-salary-report', component: <Grid name="SalaryReport" /> },
    ],
  },
  {
    path: '/masters',
    component: null,
    children: [
      { component: <Navigate replace />, index: true },
      { path: 'asset-status', component: <Grid name="AssetStatus" /> },
      { path: 'asset-status/new', component: <Grid name="AssetStatus" /> },
      { path: 'asset-movement-purpose', component: <Grid name="AssetMovementPurpose" /> },
      { path: 'asset-movement-purpose/new', component: <Grid name="AssetMovementPurpose" /> },
      { path: 'asset-conditions', component: <Grid name="assetconditions" /> },
      { path: 'asset-conditions/new', component: <Grid name="assetconditions" /> },
      { path: 'owners', component: <Grid name="Owners" PopupComponent={<Category />} /> },
      { path: 'owners/new', component: <Grid name="Owners" PopupComponent={<Category />} /> },
      
      { path: 'managements', component: <Grid name="Managements" /> },
      { path: 'managements/new', component: <Grid name="Managements" /> },
      {
        path: 'item-categories',
        component: <Grid name="ItemCategories" PopupComponent={<Category />} />,
      },
      {
        path: 'item-categories/new',
        component: <Grid name="ItemCategories" PopupComponent={<Category />} />,
      },
      { path: 'warehouses', component: <Grid name="Warehouses" PopupComponent={<Warehouse />} /> },
      {
        path: 'warehouses/new',
        component: <Grid name="Warehouses" PopupComponent={<Warehouse />} />,
      },
      {
        path: 'warehouse-sections',
        component: <Grid name="warehousesections" PopupComponent={<WarehouseSections />} />,
      },
      {
        path: 'warehouse-sections/new',
        component: <Grid name="warehousesections" PopupComponent={<WarehouseSections />} />,
      },
      {
        path: 'banner-categories',
        component: <Grid name="BannerCategories" PopupComponent={<Category />} />,
      },
      {
        path: 'banner-categories/new',
        component: <Grid name="BannerCategories" PopupComponent={<Category />} />,
      },
      {
        path: 'maintenance-categories',
        component: <Grid name="MaintenanceCategories" PopupComponent={<Category />} />,
      },
      {
        path: 'maintenance-categories/new',
        component: <Grid name="MaintenanceCategories" PopupComponent={<Category />} />,
      },
      {
        path: 'blog-categories',
        component: <Grid name="BlogCategories" PopupComponent={<Category />} />,
      },
      {
        path: 'blog-categories/new',
        component: <Grid name="BlogCategories" PopupComponent={<Category />} />,
      },
      {
        path: 'faq-categories',
        component: <Grid name="FAQCategories" PopupComponent={<Category />} />,
      },
      {
        path: 'faq-categories/new',
        component: <Grid name="FAQCategories" PopupComponent={<Category />} />,
      },
      {
        path: 'our-client-categories',
        component: <Grid name="OurClientCategories" PopupComponent={<Category />} />,
      },
      {
        path: 'our-client-categories/new',
        component: <Grid name="OurClientCategories" PopupComponent={<Category />} />,
      },
      {
        path: 'product-categories',
        component: <Grid name="ProductCategories" PopupComponent={<Category />} />,
      },
      {
        path: 'product-categories/new',
        component: <Grid name="ProductCategories" PopupComponent={<Category />} />,
      },
      {
        path: 'service-categories',
        component: <Grid name="ServiceCategories" PopupComponent={<Category />} />,
      },
      {
        path: 'service-categories/new',
        component: <Grid name="ServiceCategories" PopupComponent={<Category />} />,
      },
      {
        path: 'video-categories',
        component: <Grid name="VideoCategories" PopupComponent={<Category />} />,
      },
      {
        path: 'video-categories/new',
        component: <Grid name="VideoCategories" PopupComponent={<Category />} />,
      },
      {
        path: 'photo-categories',
        component: <Grid name="PhotoCategories" PopupComponent={<Category />} />,
      },
      {
        path: 'photo-categories/new',
        component: <Grid name="PhotoCategories" PopupComponent={<Category />} />,
      },
      { path: 'unit-of-measurements', component: <Grid name="UOM" /> },
      { path: 'unit-of-measurements/new', component: <Grid name="UOM" /> },
      // { path: 'tax-categories', component: <Grid name="TaxCategories" PopupComponent={<MastersRow />} />, },
      // { path: 'tax-categories/new', component: <Grid name="TaxCategories" PopupComponent={<MastersRow />} />, },
      {
        path: 'contact-groups',
        component: <Grid name="ContactGroups" PopupComponent={<MastersRow />} />,
      },
      { path: 'contact-groups/new', component: <MastersRow /> },
      { path: 'lead-types', component: <Grid name="LeadTypes" /> },
      { path: 'lead-types/new', component: <Grid name="LeadTypes" /> },
      { path: 'project-types', component: <Grid name="ProjectTypes" /> },
      { path: 'project-types/new', component: <Grid name="ProjectTypes" /> },
      { path: 'task-types', component: <Grid name="TaskTypes" /> },
      { path: 'task-types/new', component: <Grid name="TaskTypes" /> },
      { path: 'project-status', component: <Grid name="ProjectStatus" /> },
      { path: 'project-status/new', component: <Grid name="ProjectStatus" /> },
      { path: 'colours', component: <Grid name="Colours" PopupComponent={<MastersRow />} /> },
      { path: 'colours/new', component: <MastersRow /> },
      {
        path: 'damage-types',
        component: <Grid name="DamageTypes" PopupComponent={<MastersRow />} />,
      },
      { path: 'damage-types/new', component: <MastersRow /> },
      {
        path: 'downtime-types',
        component: <Grid name="downtimeTypes" PopupComponent={<MastersRow />} />,
      },
      { path: 'downtime-types/new', component: <MastersRow /> },
      { path: 'sizes', component: <Grid name="Sizes" PopupComponent={<MastersRow />} /> },
      { path: 'sizes/new', component: <MastersRow /> },
      { path: 'lead-status', component: <Grid name="LeadStatus" /> },
      { path: 'lead-status/new', component: <Grid name="LeadStatus" /> },
      { path: 'lead-source', component: <Grid name="LeadSource" /> },
      { path: 'lead-source/new', component: <Grid name="LeadSource" /> },
      { path: 'call-status', component: <Grid name="Call Status" /> },
      { path: 'call-status/new', component: <Grid name="Call Status" /> },
      { path: 'skills', component: <Grid name="Skills" PopupComponent={<MastersRow />} /> },
      { path: 'skills/new', component: <MastersRow /> },
      { path: 'designations', component: <Grid name="Designations" /> },
      { path: 'designations/new', component: <Grid name="Designations" /> },
      { path: 'employee-groups', component: <Grid name="EmployeeGroups" /> },
      { path: 'employee-groups/new', component: <Grid name="EmployeeGroups" /> },
      { path: 'employee-grades', component: <Grid name="EmployeeGrades" /> },
      { path: 'employee-grades/new', component: <Grid name="EmployeeGrades" /> },
      { path: 'locations', component: <Grid name="Locations" PopupComponent={<MastersRow />} /> },
      { path: 'locations/new', component: <MastersRow /> },
      { path: 'Models', component: <Grid name="Models" /> },
      { path: 'grievance-types', component: <Grid name="GrievanceTypes" /> },
      { path: 'grievance-types/new', component: <Grid name="GrievanceTypes" /> },
      {
        path: 'job-application-source',
        component: <Grid name="JobApplicationSource" PopupComponent={<MastersRow />} />,
      },
      { path: 'job-application-source/new', component: <MastersRow /> },
      {
        path: 'job-application-status',
        component: <Grid name="JobApplicationStatus" PopupComponent={<MastersRow />} />,
      },
      { path: 'job-application-status/new', component: <MastersRow /> },
      { path: 'document-layouts', component: <Grid name="DocumentLayouts" /> },
      { path: 'document-layouts/new', component: <DocumentLayout /> },
      { path: 'document-layouts/:id', component: <DocumentLayout /> },
      {
        path: 'departments',
        component: <Grid name="Departments" PopupComponent={<Departments />} />,
      },
      {
        path: 'departments/new',
        component: <Grid name="Departments" PopupComponent={<Departments />} />,
      },
      {
        path: 'salary-components',
        component: <Grid name="Salary Components" PopupComponent={<SalaryComponents />} />,
      },
      {
        path: 'salary-components/new',
        component: <Grid name="Salary Components" PopupComponent={<SalaryComponents />} />,
      },
      { path: 'vendor-groups', component: <Grid name="Vendor Groups" /> },
      { path: 'vendor-groups/new', component: <Grid name="Vendor Groups" /> },
      { path: 'industries', component: <Grid name="Industries" /> },
      {
        path: 'industries/new',
        component: <Grid name="Industries" PopupComponent={<MastersRow />} />,
      },
      {
        path: 'territories',
        component: <Grid name="Territories" PopupComponent={<MastersRow />} />,
      },
      {
        path: 'territories/new',
        component: <Grid name="Territories" PopupComponent={<MastersRow />} />,
      },
      { path: 'Users', component: <Grid name="Users" /> },
      { path: 'Users/new', component: <Users /> },
      { path: 'Users/:id', component: <Users /> },
      { path: 'approval-types', component: <Grid name="ApprovalTypes" /> },
      { path: 'approval-types/new', component: <ApprovalType /> },
      { path: 'approval-types/:id', component: <ApprovalType /> },
    ],
  },
];
