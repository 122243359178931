import { Stack, Typography } from '@mui/material';
import {
  MaterialReactTable,
  MRT_ShowHideColumnsButton,
  MRT_ToggleFiltersButton,
} from 'material-react-table';
import 'jspdf-autotable';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Download, PictureAsPdf, Article } from '@mui/icons-material';
import {
  Box,
  Button,
  ListItemIcon,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  IconButton,
  Paper,
  Popper,
  Link,
  Tooltip,
} from '@mui/material';
import { FormContext } from 'src/layouts/dashboard/DashboardLayout';
import PopupPrint from './printpoppanel';
import { useSnackbar } from 'notistack';
import { TableGREYERP } from './table';
import { HttpRequest, formatdecimals } from 'src/data/scripts';
import Picker from './picker';
import { useTranslation } from 'react-i18next';

export default function AccordionGrid({
  name,
  tableName,
  font,
  fontSize,
  color,
  printLayout,
  setDates,
}) {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [showProgressBars, setShowProgressBars] = useState(true);
  const [firstDate, setFirstDate] = useState(localStorage.getItem('firstdate'));
  const [lastDate, setLastDate] = useState(localStorage.getItem('lastdate'));
  const [open, setOpen] = React.useState(false);

  const { setGridTableInstance, clientId, displayPrint, setRecordId, setOpenPrint, openPrint } =
    useContext(FormContext);

  const tableInstanceRef = useRef(null);
  const anchorRef = React.useRef(null);
  const { enqueueSnackbar } = useSnackbar();

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    GetData();
  }, [clientId, showProgressBars]);

  async function GetData() {
    const url = window.location.pathname.split('/');
    if (tableInstanceRef.current) {
      setGridTableInstance(tableInstanceRef.current);
    }

    const tblName = url[2].replaceAll('-', '');
    if (firstDate && lastDate) {
      let data = await HttpRequest(
        `getrecords/${tblName}/${localStorage.getItem('username')}/${localStorage.getItem(
          'client'
        )}/${firstDate}/${lastDate}`
      );
      console.log(data);
      const idMapping = data.reduce((acc, el, i) => {
        acc[el.ID] = i;
        return acc;
      }, {});

      let root;
      let header = [];
      let columns = [];
      let dataTbl = [];

      const calculation = {
        opening: 0,
        debit: 0,
        credit: 0,
        closing: 0,
        balance: 0,
      };

      const calc = {
        opening: {
          debit: 0,
          credit: 0,
        },
        closing: {
          debit: 0,
          credit: 0,
        },
      };

      console.log(data);
      //Get Parent Elements Only id
      data.forEach((el) => {
        // Handle the root element
        if (el.parentid == '0') {
          header.push(el.ID);
          for (const col in el) {
            if (
              col.toUpperCase() != 'ID'.toUpperCase() &&
              col.toUpperCase() != 'parentid'.toUpperCase() &&
              col.toUpperCase() != 'Name'.toUpperCase()
            ) {
              calculation[col] += parseFloat(el[col]);

              //   if (!(col == 'opening' || col == 'closing')) {
              //     calculation[col] += parseFloat(
              //       el[col]
              //     );
              //   } else if (col == 'opening' || col == 'closing') {
              //     if (col == 'debit') {
              //       calc[col].debit += parseFloat( el[col]);
              //     } else if (col == 'credit'){
              //       calc[col].credit += parseFloat( el[col] );
              //     }
              //   }
            }
          }

          if (window.location.pathname.split('/')[2]?.toUpperCase() == 'BALANCE-SHEET-REPORT') {
            calculation.balance += parseFloat(
              el.balance
                ?.toString()
                .replaceAll('Dr', '')
                .replaceAll('Cr', '')
                .replaceAll(',', '')
                .replaceAll(' ', '')
            );
          }
          return;
        }
      });

      // calculation['opening'] = calc.opening.debit - calc.opening.credit;
      // calculation['closing'] = calc.closing.debit - calc.closing.credit;
      if (window.location.pathname.split('/')[2]?.toUpperCase() == 'PROFIT-AND-LOSS-REPORT') {
        calculation['balance'] = data[0]?.balance - data[1]?.balance;
      }

      for (const key in data[0]) {
        if (
          key.toUpperCase() == 'ID'.toUpperCase() ||
          key.toUpperCase() == 'parentid'.toUpperCase()
        ) {
        } else if (key.toUpperCase() == 'Name'.toUpperCase()) {
          columns.push({
            accessorKey: key,
            header: key.charAt(0).toUpperCase() + key.toLowerCase().slice(1),
          });
        } else {
          let includeBalance = ['opening', 'closing'];

          let label = key.charAt(0).toUpperCase() + key.toLowerCase().slice(1);
          label = label.concat('', includeBalance.includes(key) ? ' Balance' : '');

          columns.push({
            accessorKey: key,
            header: label,

            Cell: ({ cell }) => {
              return (
                <>
                  {cell.getValue() != 0 ? (
                    <Link
                      target="_blank"
                      rel="noreferrer"
                      onClick={() => {
                        setRecordId({
                          id: cell.row.original.ID,
                          firstDate: firstDate,
                          lastDate: lastDate,
                          name: cell.row.original.Name,
                        });
                        setOpenPrint(true);
                      }}
                      style={{ cursor: 'pointer', color: '#078dee' }}
                    >
                      {' '}
                      {cell.getValue() && formatdecimals(cell.getValue())}{' '}
                    </Link>
                  ) : (
                    ''
                  )}
                </>
              );
            },
            Footer: () => (
              <Stack>
                <Box color="black">{calculation[key] && formatdecimals(calculation[key])}</Box>
              </Stack>
            ),
          });
        }
      }

      data.forEach((el) => {
        if (el.parentid == '0') {
          root = el;
          return;
        }
        const parentEl = data[idMapping[el.parentid]];

        parentEl && (parentEl.subRows = [...(parentEl.subRows || []), el]);
        if (parentEl && header.includes(parentEl.ID)) {
          dataTbl.push(parentEl);
        }
      });
      dataTbl = [...new Set(dataTbl)];
      setData(dataTbl);
      setColumns(columns);
      setShowProgressBars(false);
    }
  }

  function onChangeFirstDate(e) {
    if (e) {
      setFirstDate(e);
      setDates((prev) => {
        return { ...prev, FirstDate: e };
      });
    } else {
      setFirstDate('');
    }
  }

  function onChangelastDate(e) {
    if (e) {
      setLastDate(e);
      setDates((prev) => {
        return { ...prev, LastDate: e };
      });
    } else {
      setLastDate('');
    }
  }

  function generateReport() {
    if (!firstDate || !lastDate) {
      enqueueSnackbar('Incorrect Date Format');
    } else if (firstDate > lastDate) {
      enqueueSnackbar('End date should be greater than start date');
    } else {
      setShowProgressBars(true);
    }
  }
  return (
    <>
      <MaterialReactTable
        columns={columns}
        data={data}
        enableExpanding
        showProgressBars
        tableInstanceRef={tableInstanceRef}
        enableGrouping={false}
        enableColumnFilterModes
        initialState={{ density: 'compact' }}
        enableDensityToggle={false}
        enablePagination={false}
        enableHiding={false}
        enableFullScreenToggle={false}
        enableBottomToolbar={false}
        muiTableFooterCellProps={{
          sx: {
            borderTop: '1px solid rgb(224, 224, 224)',
            borderBottom: '1px solid rgb(224, 224, 224)',
          },
        }}
        renderToolbarInternalActions={({ table }) => (
          <Box sx={{ displayPrint: 'none' }}>
            <MRT_ToggleFiltersButton table={table} />
            <MRT_ShowHideColumnsButton table={table} />
            <Tooltip arrow title="Export">
              <IconButton ref={anchorRef} onClick={handleToggle}>
                <Download />
              </IconButton>
            </Tooltip>
            <Popper
              open={open}
              anchorEl={anchorRef.current}
              role={undefined}
              placement="bottom-start"
              transition
              sx={{ zIndex: 20 }}
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom',
                  }}
                >
                  <Paper sx={{ px: 1, py: 2 }}>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList
                        autoFocusItem={open}
                        id="composition-menu"
                        aria-labelledby="composition-button"
                      >
                        {/* <MenuItem key={'success'} onClick={handleExportData}> */}
                        <MenuItem key={'success'}>
                          <ListItemIcon sx={{ m: 0 }}>
                            <Article color="success" />
                          </ListItemIcon>
                          {t('Export to CSV')}
                        </MenuItem>
                        <MenuItem key={'export'}>
                          <ListItemIcon sx={{ m: 0 }}>
                            <PictureAsPdf color="pdf" />
                          </ListItemIcon>
                          {t('Export to PDF')}
                        </MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </Box>
        )}
        renderTopToolbarCustomActions={({ table }) => {
          return (
            <Stack
              direction="row"
              sx={{ mt: 1, mb: 1, displayPrint: 'none' }}
              spacing={2}
              justifyContent="center"
              alignItems="center"
            >
              <Picker
                type="date"
                onChange={onChangeFirstDate}
                name="FirstDate"
                date={firstDate}
                postBackDate={firstDate}
              ></Picker>
              <Picker
                type="date"
                onChange={onChangelastDate}
                name="LastDate"
                date={lastDate}
                postBackDate={lastDate}
              ></Picker>

              <Button
                variant="contained"
                size="small"
                sx={{ displayPrint: 'none' }}
                onClick={generateReport}
                name="generatereport"
              >
                {t('Generate')}
              </Button>
            </Stack>
          );
        }}
        state={{
          showProgressBars,
        }}
        muiTableContainerProps={{
          sx: {
            height: '100%',
            '& .MuiTableCell-root': {
              borderBottom: '1px solid rgb(224, 224, 224)',
            },
            overflow: 'hidden',
          },
        }}
        muiTableBodyCellProps={({ cell, column, row, table }) => {
          return {
            sx: {
              pl:
                cell?.id?.toUpperCase().includes('NAME') && row.depth == 1
                  ? '25px'
                  : cell?.id?.toUpperCase().includes('NAME') && row.depth == 2
                    ? '35px'
                    : cell?.id?.toUpperCase().includes('NAME') && row.depth == 3
                      ? '45px'
                      : '15px',
              '& .MuiButtonBase-root': {
                display: row.subRows.length < 1 && 'none',
                displayPrint: 'none',
              },
            },
          };
        }}
        getSubRows={(originalRow) => originalRow.subRows}
        enableExpandAll
      />

      {openPrint && <PopupPrint />}
    </>
    //   )}
    // </>
  );
}
