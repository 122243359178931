import { CardPanel } from 'src/controls/cardpanel';
import { CheckBoxGREYERP } from 'src/controls/checkbox';
import { TextBoxGREYERP } from 'src/controls/textbox';

export default function Building() {
  return (
    <>
      <CardPanel
        heading="Building Details"
        cardItem={
          <>
            <TextBoxGREYERP placeholder="Building Name" name="Name" required={true} focus={true} />
            <TextBoxGREYERP placeholder="533" name="Building" label="Building #" />
            <TextBoxGREYERP placeholder="3104" name="Way" label="Way #" />
            <TextBoxGREYERP placeholder="Mazoon Street" name="Street" />
            <TextBoxGREYERP placeholder="Ghala" name="Location" />
            <TextBoxGREYERP
              span={2}
              placeholder="PC 133, Muscat, Sultanate Of Oman."
              name="Address"
            />
            <TextBoxGREYERP
              placeholder="Internal Notes"
              name="Comments"
              multiline={true}
              span={2}
            />
          </>
        }
      />

      <CardPanel
        heading="Contact Details"
        cardItem={
          <>
            <TextBoxGREYERP placeholder="Abdul Raheem" span={2} name="Person" />
            <TextBoxGREYERP placeholder="Care Taker" label="Designation" name="Designation2" />
            <TextBoxGREYERP placeholder="94635852" name="Phone" type="number" />
            <TextBoxGREYERP placeholder="someone@something.com" name="Email" type="email" />
            <TextBoxGREYERP placeholder="Ahmed Khan" span={2} name="Person2" />
            <TextBoxGREYERP placeholder="Care Taker 2" label="Designation" name="Designation2" />
            <TextBoxGREYERP placeholder="94635852" name="Phone2" label="Phone" type="number" />
            <TextBoxGREYERP
              placeholder="someone@something.com"
              name="Email2"
              label="Email"
              type="email"
            />
            <TextBoxGREYERP placeholder="Arshad Pasha" span={2} name="Person3" />
            <TextBoxGREYERP placeholder="Supervisor" label="Designation" name="Designation3" />
            <TextBoxGREYERP placeholder="79543285" name="Phone3" label="Phone" type="number" />
            <TextBoxGREYERP
              placeholder="someone@something.com"
              name="Email3"
              label="Email"
              type="email"
            />
            <TextBoxGREYERP placeholder="Ali Kareem" span={2} name="Person4" />
            <TextBoxGREYERP placeholder="Manager" label="Designation" name="Designation4" />
            <TextBoxGREYERP placeholder="78651285" name="Phone4" label="Phone" type="number" />
            <TextBoxGREYERP
              placeholder="someone@something.com"
              name="Email4"
              label="Email"
              type="email"
            />
          </>
        }
      />

      <CardPanel
        heading="Utility Details"
        cardItem={
          <>
            <TextBoxGREYERP placeholder="Corridor" name="Area" />
            <TextBoxGREYERP placeholder="I15JA323203" label="Water Meter No." name="WaterMeter" />
            <TextBoxGREYERP
              placeholder="57953"
              label="Electricity Meter No."
              name="ElectricityMeter"
            />
            <TextBoxGREYERP label="Water Account No." placeholder="D53788" name="WaterAccount" />
            <TextBoxGREYERP
              placeholder="Q69759"
              label="Electricity Account No."
              name="ElectricityAccount"
            />
            <TextBoxGREYERP placeholder="lawn" name="Area2" />
            <TextBoxGREYERP placeholder="I15JA269203" label="Water Meter No." name="WaterMeter2" />
            <TextBoxGREYERP
              placeholder="76953"
              label="Electricity Meter No."
              name="ElectricityMeter2"
            />
            <TextBoxGREYERP placeholder="D53675" label="Water Account No." name="WaterAccount2" />
            <TextBoxGREYERP
              placeholder="Q69098"
              label="Electricity Account No."
              name="ElectricityAccount2"
            />
            <TextBoxGREYERP placeholder="Car Parking" name="Area3" />
            <TextBoxGREYERP placeholder="I15JA269203" label="Water Meter No." name="WaterMeter3" />
            <TextBoxGREYERP
              placeholder="57685"
              label="Electricity Meter No."
              name="ElectricityMeter3"
            />
            <TextBoxGREYERP placeholder="D53234" label="Water Account No." name="WaterAccount3" />
            <TextBoxGREYERP
              placeholder="Q69134"
              label="Electricity Account No."
              name="ElectricityAccount3"
            />
            <TextBoxGREYERP placeholder="Car Parking" name="Area4" />
            <TextBoxGREYERP placeholder="I15JA269234" label="Water Meter No." name="WaterMeter4" />
            <TextBoxGREYERP
              placeholder="57685"
              label="Electricity Meter No."
              name="ElectricityMeter4"
            />
            <TextBoxGREYERP placeholder="D53222" label="Water Account No." name="WaterAccount4" />
            <TextBoxGREYERP
              placeholder="Q691309"
              label="Electricity Account No."
              name="ElectricityAccount4"
            />
          </>
        }
      />

      <CardPanel
        heading="Buildings Amenities"
        cardItem={
          <>
            <TextBoxGREYERP placeholder="2" name="Lifts" type="number" />
            <TextBoxGREYERP placeholder="2" name="Stairs" type="number" />
            <CheckBoxGREYERP name="SwimmingPool" />
            <CheckBoxGREYERP label="Gymnasium" name="gymnasium" />
            <CheckBoxGREYERP label="CCTV" name="CCTV" />
            <CheckBoxGREYERP name="Intercom" />
            <CheckBoxGREYERP name="PrepaidMeter" />
            <CheckBoxGREYERP name="AccessControl" />
          </>
        }
      />
    </>
  );
}
